<template>
  <!-- add set to collection dialog -->
  <transition name="fade">
    <AddSetDialogMobile v-if="addSetDialogActive" :set_data="set_data" @close="addSetDialogActive = false" />
  </transition>

  <div class="background-image-container">
    <img class="background-image" :src="headerImage" loading="lazy">
  </div>
  <section>
    <v-container class="pa-0">
      <v-row no-gutters class="row-search mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>

      <v-row no-gutters>
        <ul class="breadcrumb">
          <li><router-link class="link" :to="{ path: '/themes' }">Themenwelt</router-link></li>
          <li v-if="set_data.theme !== '-'"><router-link class="link" :to="{ path: '/themes/' + convertThemeToPath(set_data.theme)}">{{ set_data.theme }}</router-link></li>
          <li v-if="set_data.subtheme !== '-'"><router-link class="link" :to="{ name: '' }">{{ set_data.subtheme }}</router-link></li>
        </ul>
      </v-row>
      <v-row no-gutters>
        <span class="setname">{{ set_data.set.split('-')[0] }} {{ set_data.name }}</span>
      </v-row>
      <v-row no-gutters class="image-actions-row">
        <div class="image-container">
          <ZoomMainImage galleryID="main_image" :images="mainImage" />
        </div>
        <v-btn @click="openAddSetDialog()" class="secondary-icon-button" plain rounded="pill" size="large"><i class="fas fa-boxes"></i></v-btn>
      </v-row>

      <v-row no-gutters>
        <div class="card card_minifigure_prices">
          <div class="card-header">
            <p class="header-text with_tooltip header_mobile">Aktueller Angebotspreis</p>
            <Popper class="tooltip" placement="bottom" arrow="true" offsetDistance="2" content="Die Preise beziehen sich auf aktuelle Angebotspreise für den Zustand neu und gebraucht. Die Preise beziehen sich auf aktuelle Angebotspreise für den Zustand neu und gebraucht. Es handelt sich dabei nicht um einen Endkundenpreis.
                                                                                                    Hier werden unterschiedliche Preise summiert, ob diese inkl. MwSt. und ggf. zzgl. Versandkosten sind, kann nicht nachvollzogen werden.
                                                                                                    Aktuelle Preise können zwischenzeitlich höher sein.">
              <i class="pl-1 fas fa-question-circle info_tooltip"></i>
            </Popper>
          </div>
          <div class="card-body">
            <v-table density="compact" class="ml-6 table">
              <tr>
                <td class="info-list-header text_mobile">Neu</td>
                <td class="info-list-header text_mobile">Gebraucht</td>
              </tr>
              <tr>
                <td>
                  <v-table density="compact" class="table">
                    <tr>
                      <td class="info-list-data text_mobile">Min. Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_new')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                    <tr>
                      <td class="info-list-data text_mobile">Max. Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_new')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                    <tr>
                      <td class="info-list-data text_mobile">Ø Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_new')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                  </v-table>
                </td>
                <td>
                  <v-table density="compact" class="table">
                    <tr>
                      <td class="info-list-data text_mobile">Min. Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_used')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                    <tr>
                      <td class="info-list-data text_mobile">Max. Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_used')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                    <tr>
                      <td class="info-list-data text_mobile">Ø Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_used')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                  </v-table>
                </td>
              </tr>
            </v-table>

            <div class="card-header">
              <p class="header-text with_tooltip header_mobile">Verkaufspreis</p>
              <Popper class="tooltip" placement="top" arrow="true" offsetDistance="2" content="Verkaufspreise der letzten sechs Monate für den Zustand neu und gebraucht. Es handelt sich dabei nicht um einen Endkundenpreis.
                                                                                                    Hier werden unterschiedliche Preise summiert, ob diese inkl. MwSt. und ggf. zzgl. Versandkosten sind, kann nicht nachvollzogen werden.
                                                                                                    Aktuelle Preise können zwischenzeitlich höher sein.">
                <i class="pl-1 fas fa-question-circle info_tooltip"></i>
              </Popper>
            </div>
            <v-table density="compact" class="ml-6 table">
              <tr>
                <td class="info-list-header text_mobile">Neu</td>
                <td class="info-list-header text_mobile">Gebraucht</td>
              </tr>
              <tr>
                <td>
                  <v-table density="compact" class="table">
                    <tr>
                      <td class="info-list-data text_mobile">Min. Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_new')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                    <tr>
                      <td class="info-list-data text_mobile">Max. Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_new')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                    <tr>
                      <td class="info-list-data text_mobile">Ø Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_new')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                  </v-table>
                </td>
                <td>
                  <v-table density="compact" class="table">
                    <tr>
                      <td class="info-list-data text_mobile">Min. Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_used')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                    <tr>
                      <td class="info-list-data text_mobile">Max. Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_used')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                    <tr>
                      <td class="info-list-data text_mobile">Ø Preis</td>
                      <td class="info-list-data text_mobile"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_used')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                    </tr>
                  </v-table>
                </td>
              </tr>
            </v-table>
          </div>
        </div>
      </v-row>

      <v-row no-gutters>
        <div class="card card-price">
          <div>
            <div class="card-header">
              <span class="header-text header_mobile">Preisvergleich</span>
              <v-spacer></v-spacer>
              <Toggle v-model="withShipping" class="mr-1"/>
              <label class="mr-6 label-toggle-shipping">inkl. Versand</label>
            </div>
            <v-row no-gutters class="retailer-list-header">
              <div>
                <template v-if="set_data.retail_price !== '-'">
                  <span class="retail-price">{{ set_data.retail_price }} <span>{{ ' ' + this.mapCurrencyUnit(localCurrency) }}</span></span>
                </template>
                <template v-else>
                  <span class="retail-price">-</span>
                </template>
              </div>
            </v-row>

          </div>
          <div class="card-body">
            <RetailerList :set_data="set_data" :retailerList="retailerList" :withShipping="withShipping" />
          </div>
        </div>
      </v-row>

      <v-row no-gutters>
        <div class="card card-set-info">
          <div class="card-header">
            <span class="header-text header_mobile">Set Informationen</span>
          </div>
          <div class="card-body">
            <div class="info-list">
              <SetInformationTable :set_data="set_data" />
            </div>
            <div class="card-header">
              <span class="header-text header_mobile">Community</span>
            </div>
            <div class="card-body">
              <div class="info-list">
                <table>
                  <tr>
                    <td class="info-list-header text_mobile">In Collections</td>
                    <td class="info-list-data text_mobile">{{ set_data.in_collections || '0' }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </v-row>

      <v-row no-gutters>
        <div class="card card_minifigures">
          <div class="card-header">
            <span class="mb-2 header-text header_mobile">Minifiguren</span>
            <v-spacer></v-spacer>
          </div>
          <div class="card-body minifigures">
            <v-row no-gutters class="ml-6 mb-6">
              <template v-if="set_data.minifigures.length > 0">
                <div class="minifigure_container" v-for="minifig in set_data.minifigures" :key="minifig.minifigures_number?.number?.key">
                  <router-link id="minifigure_link" :to="{ path: '/items/minifigures/' + minifig.minifigures_number?.number }">
                    <div class="minifigure_amount_container">
                      <p class="minifigure_amount_text">{{ minifig.amount ?? 'amount' }}x</p>
                    </div>
                    <div v-if="true" class="image_container_minifigures">
                      <img v-if="getMinifigureImage(minifig?.minifigures_number?.images[0]?.directus_files_id)" class="image minifigures" :src="getMinifigureImage(minifig?.minifigures_number?.images[0]?.directus_files_id)" loading="lazy">
                      <v-icon v-else id="small_image_alt" icon="mdi-account-circle" ></v-icon>
                    </div>
                    <div class="minifigures_info_card">
                      <span class="minifigure_name"> {{ minifig.minifigures_number?.name ?? '-' }}</span>
                      <span class="minifigure_bricklink">{{ minifig.minifigures_number?.number ?? '-' }}</span>
                      <span v-if="minifig.minifigures_number?.sets?.length" class="minifigure_bricklink">in {{ minifig.minifigures_number?.sets?.length ?? '' }} Sets</span>
                    </div>
                  </router-link>
                </div>
              </template>
              <template v-else>
                <span class="hint_text">Keine Minifiguren in {{ set_data.set }} enthalten oder noch nicht angelegt.</span>
              </template>
            </v-row>
          </div>
        </div>
      </v-row>

      <v-row no-gutters>
        <div class="card card-media">
          <div class="card-header">
            <span class="header-text header_mobile">Media</span>
          </div>
          <div class="card-body">
            <v-row no-gutters class="ml-6">
              <ImageGallery galleryID="image_gallery" :images="galleryImages" />
            </v-row>
          </div>
        </div>
      </v-row>

      <v-row v-if="relatedSets.length > 0" no-gutters>
        <div class="card card_related_sets">
          <div class="card-header">
            <span class="header-text header_mobile">Dazu passende {{ set_data?.theme ?? '' }} <span v-if="set_data.subtheme">{{ ' oder ' + set_data.subtheme }}</span> Sets</span>
          </div>
          <v-row class="mt-4 my-2" no-gutters>
            <div v-for="setItem in relatedSets" :key="setItem.key">
              <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
                <SetCard
                :set="`${setItem.set}`"
                :name="`${setItem.name[0]?.name ?? ''}`"
                :retailPrice="setItem?.retail_price ?? ''"
                :year="getReleaseDate(setItem.release_date)"
                :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                :imageContainerWidth="'70px'"
                :imageContainerHeight="'70px'"
                :textContainerWidth="'90px'"
                :textContainerHeight="'100px'"
                :textContainerBackground="'none'"
                :cardSetFontsize="'10px'"
                :cardNameFontsize="'12px'"
                :cardNameMinMaxHeight="'1.65rem'"
                :cardPriceFontsize="'12px'"
                :cardPriceColor="'#60ffe4'"
                :cardYearFontsize="'10px'"
                :borderRadius="'15px'"
                />
              </router-link>
            </div>
          </v-row>
        </div>
      </v-row>

    </v-container>
  </section>

</template>

<script>
import SearchMobile from '../../components/SearchMobile.vue';
import Toggle from '../../components/Toggle.vue';
import RetailerList from '../SetDetail/RetailerList.vue';
import SetInformationTable from '../SetDetail/SetInformationTable.vue';
import AddSetDialogMobile from '../../components/Dialogs/AddSetDialogMobile.vue';
import ImageGallery from '../../components/Dialogs/ImageGallery.vue';
import ZoomMainImage from '../../components/Dialogs/ZoomMainImage.vue';
import SetCard from '../../components/SetCard.vue';

export default {
  name: 'SetDetailMobile',
  components: {
    SearchMobile,
    Toggle,
    SetInformationTable,
    RetailerList,
    AddSetDialogMobile,
    ImageGallery,
    ZoomMainImage,
    SetCard
  },
  props: [ 'headerImage', 'mainImage', 'galleryImages', 'set_data', 'retailerList', 'relatedSets' ],
  inject: [
    'localCurrency'
  ],
  data() {
    return {
      withShipping: false,
      zoomImageDialogActive: false,
      addSetDialogActive: false
    }
  },
  watch: {
    withShipping(active) {
      if (active) {
        this.currentRetailerList.sort((a, b) => (parseFloat(a.priceWithShipping) > parseFloat(b.priceWithShipping)) ? 1 : -1);
      }
      else {
        this.currentRetailerList.sort((a, b) => (parseFloat(a.currentPrice) > parseFloat(b.currentPrice)) ? 1 : -1);
      }
    }
  },
  computed: {
    currentRetailerList() {
      return this.retailerList;
    },
  },
  methods: {
    convertThemeToPath(theme) {
      if (theme) {
        const themeNoSpecialChars = (((theme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
        const routeTheme = themeNoSpecialChars.toLowerCase();
        return routeTheme;
      }
      else return '';
    },
    openZoomImage(image) {
      if (this.zoomImageDialogActive === false) {
        if (image.id != null) {
          this.zoomImageDialogActive = true;
          this.imageCopyright = image.copyright;
          this.zoomedImage = process.env.VUE_APP_API_IMG_PATH + image.id + '?height=1200';
        }
      }
    },
    openAddSetDialog() {
      if (this.addSetDialogActive === false) {
        this.addSetDialogActive = true;
      }
    },
    closeZoomImageDialog() {
      this.zoomImageDialogActive = false;
    },
    closeAddSetDialog() {
      this.addSetDialogActive = false;
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    getMinifigureImage(minifigureImageId) {
      try {
        if (minifigureImageId) { return process.env.VUE_APP_API_IMG_PATH + minifigureImageId + '?height=200' ?? ''; }
        return '';
      }
      catch(err) { return ''; }
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
  }
}
</script>

<style scoped>
.background-image-container {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 20rem;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px -2px 20px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  z-index: 1;
}

.background-image {
  width: 100%;
  margin-top: 60px;
  opacity: 0.3;
  transform: scale(1.7);
  transition: 0.5s ease transform;
}

section {
  position: relative;
  min-height: 1080px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.row-search.mobile {
  position: relative;
  width: 100%;
  padding-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

ul.breadcrumb {
  margin-left: 1.5rem;
  list-style: none;
  line-height: 1.1;
  margin-bottom: 0.5rem;
  z-index: 2;
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

ul.breadcrumb li+li:before {
  padding-left: 8px;
  padding-right: 4px;
  font-size: 12px;
  color: #ffffff;
  content: ">\00a0";
}

ul.breadcrumb li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: .5px;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #60ffe4
}

.setname {
  position: relative;
  overflow: hidden;
  min-height: 3.25rem;
  max-height: 3.25rem;
  width: 90%;
  color: #ffffff;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: 800;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  z-index: 2;
}

.image-actions-row {
  align-items: flex-start;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 110px;
  height: 110px;
  margin-left: 1.5rem;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  transition: 0.5s ease transform;
  z-index: 2;
}

.image {
  display: flex;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: 0.5s ease transform;
}

.secondary-icon-button {
  width: 5rem;
  margin: 1.5rem 0 0 1.5rem;
  color: rgb(96, 255, 228);
  background: linear-gradient(90deg, rgba(96, 255, 228, 0.2) 25%, rgba(96, 255, 228, 0.4) 100%);
  border: 1px solid rgba(96, 255, 228, 0.6);
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.card {
  position: relative;
  width: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transition: 0.5s ease transform;
}

.card.blank {
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.2) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.5s ease all;
}

.card-price,
.card-set-info,
.card-media,
.card_minifigures,
.card_related_sets,
.card_minifigure_prices {
  height: auto;
  padding-bottom: 15px;
}

.card-set-info,
.card-price,
.card-media,
.card_minifigures,
.card_related_sets,
.card_minifigure_prices {
  margin: 1.5rem 1.5rem 0 1.5rem;
}

.card_related_sets {
  margin-bottom: 1rem;
}

.toggle-shipping-cost {
  margin-top: 0.5rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.label-toggle-shipping {
  color: #ffffff;
  font-size: 12px;
}

.retailer-list-header {
  display: flex;
  align-items: center;
  margin: 0 1.5rem 0 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.retail-price {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.info-list {
  margin-left: 1.5rem;
}

.media-image-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0.5rem 1rem 0.5rem 0;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  cursor: zoom-in;
  transition: 0.5s ease all;
}

  .media-image-container:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 5px 20px 0 rgba(0,0,0,0.7);
    -moz-box-shadow: 0 5px 20px 0 rgba(0,0,0,0.7);
  }

.media-image {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.5s ease all;
}

.info-list-header {
  min-width: 120px;
}

.table {
  background: none;
}

.header_mobile {
  font-size: 14px;
}

.text_mobile {
  font-size: 12px;
}

.card-body.minifigures {
  display: flex;
}

.minifigure_container {
  display: flex;
  flex-direction: column;
  width: 80px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  align-items: center;
}

.image_container_minifigures {
  position: relative;
  width: 50px;
  height: 70px;
  margin: 0 auto;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  transition: 0.5s ease transform;
}

.minifigures_info_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
}

.minifigure_name {
  height: 2.35rem;
  max-height: 2.35rem;
  font-size: 12px;
  margin: 0 0.5rem 0.5rem 0.5rem;
  color: #312659;
  text-shadow: 1px 1px 3px rgb(255 255 255 / 30%);
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.minifigure_bricklink {
  font-size: 12px;
  color: #ffffff;
  line-height: 1;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.minifigure_amount_container {
  position: absolute;
  display: grid;
  width: 35px;
  height: 25px;
  justify-content: center;
  align-content: center;
  background: rgb(187, 255, 244);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  z-index: 2;
  transform: translateX(55px) translateY(-18px);
}

.minifigure_amount_text {
  color: #09c5a6;
  font-size: 14px;
  font-weight: 700;
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.2);
}

</style>
