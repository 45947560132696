<template>
  <CollectionPortfolioMobile v-if="isMobile" />
  <CollectionPortfolioDesktop v-else />
</template>

<script>
import CollectionPortfolioMobile from './CollectionPortfolioMobile.vue';
import CollectionPortfolioDesktop from './CollectionPortfolioDesktop.vue';

export default {
  name: "CollectionPortfolioInit",
  components: {
    CollectionPortfolioMobile,
    CollectionPortfolioDesktop
  },
  inject: [
    'isMobile'
  ],
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>
