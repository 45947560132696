<template>
  <div class="dialog-overlay">
      <div class="dialog-card">
        <div class="dialog-header">
          <span class="header-text">Collection importieren</span>
          <v-spacer></v-spacer>
          <i class="fal fa-times icon close" @click="close()"></i>
        </div>
        <div class="dialog-body">
          <v-row no-gutters>
            <span class="dialog-text">Importiere Deine Sammlung ganz einfach über unsere wunderbare Importfunktion.
              <br> Dazu kannst Du eine der folgenden Alternativen wählen:</span>
          </v-row>

          <div class="tab" no-gutters>
            <v-tabs v-model="tab" class="button_tab">
              <v-tab value="csv">CSV Datei</v-tab>
              <v-tab value="brickset">Brickset</v-tab>
              <v-tab value="brickeconomy">BrickEconomy</v-tab>
            </v-tabs>
            <v-card-text>
              <v-window v-model="tab">

                <v-window-item value="csv">
                  <span class="dialog-text">Verwende diese Option, wenn Du Deine Datensätze in Microsoft Excel oder Apple Numbers gespeichert hast. <br>
                  Die Datei muss eine benutzerdefinierte CSV-Datei (kommagetrennte Werte) im von brickdex erwarteten Format sein.</span><br>
                  <h3>Anleitung:</h3>
                  <ol>
                    <li><span class="dialog-text">Erstelle eine ordnungsgemäß formatierte CSV-Datei.</span></li>
                    <li><span class="dialog-text">Als Übung: Erzeuge eine neue Textdatei und kopiere folgendes Beispiel und speichere diese als CSV-Datei:</span></li>
                    <div class="codeblock">
                      <code class="text_codeblock">Number,Qty owned used,Qty owned new <br>
                      21310-1,1,2</code>
                    </div>

                    <li><span class="dialog-text">Die erste Spalte muss die Setnummer enthalten (Beispiel 75192-1).<br>
                      Du kannst eine Setnummer-Variante einfügen, z.B. 75192-1. Ist keine Variante gepflegt, wird -1 verwendet.</span></li>
                    <li><span class="dialog-text">Die zweite Spalte muss die Menge des Sets (gebraucht) enthalten.</span></li>
                    <li><span class="dialog-text">Die dritte Spalte muss die Menge des Sets (neu) enthalten.</span></li>
                    <li><span class="dialog-text">Lade diese Datei nun hier unter <i id="icon_file_in_text" class="far fa-file-import"></i> hoch.</span></li>
                    <li><span class="dialog-text">Klicke auf <span class="text_bold">IMPORTIEREN</span> um Deine Brickset Sammlung hoch zu laden.<br>
                      Hinweis: Wenn Du bereis Sets in deiner Sammlung hier angelegt hast, werden die importierten Sets angehangen.</span></li>
                  </ol>
                </v-window-item>

                <v-window-item value="brickset">
                  <span class="dialog-text">Du hast Deine Sammlung bei Brickset erfasst? Kein Problem.<br>
                  Du kannst Deine Daten bequem in brickdex importieren und zukünftig die Reise mit uns weiter gehen.</span><br>
                  <h3>Anleitung:</h3>
                  <ol>
                    <li><span class="dialog-text">Geh auf <a target="blank_" href="https://brickset.com/" class="text-link">brickset.com</a> und melde Dich an.</span></li>
                    <li><span class="dialog-text">Klicke auf den Button <span class="text_bold">MY SETS</span> oben auf der Seite.</span></li>
                    <li><span class="dialog-text">Nun findest Du unter <span class="text_bold">SETS OWNED</span> den Button <span class="text_bold">EXPORT</span>.</span></li>
                    <li><span class="dialog-text">Gehe mit der Maus über den Button <span class="text_bold">EXPORT</span> und klicke auf <span class="text_bold">As CSV</span>.</span></li>
                    <li><span class="dialog-text">Speichere die Datei <span class="text_bold">Brickset-MySets-owned.csv</span> auf deinem Rechner.</span></li>
                    <li><span class="dialog-text">Lade diese Datei nun hier unter <i id="icon_file_in_text" class="far fa-file-import"></i> hoch.</span></li>
                    <li><span class="dialog-text">Klicke auf <span class="text_bold">IMPORTIEREN</span> um Deine Brickset Sammlung hoch zu laden.<br>
                      Hinweis: Wenn Du bereis Sets in deiner Sammlung hier angelegt hast, werden die importierten Sets angehangen.</span></li>
                  </ol>
                </v-window-item>

                <v-window-item value="brickeconomy">
                  <span class="dialog-text">Du hast Deine Sammlung bei BrickEconomy erfasst? Kein Problem.<br>
                  Du kannst Deine Daten bequem in brickdex importieren und zukünftig die Reise mit uns weiter gehen.</span><br>
                  <h3>Anleitung:</h3>
                  <ol>
                    <li><span class="dialog-text">Geh auf <a target="blank_" href="https://www.brickeconomy.com/" class="text-link">brickeconomy.com</a> und melde Dich an.</span></li>
                    <li><span class="dialog-text">Klicke auf den Button <span class="text_bold">My Collection</span> oben auf der Seite.</span></li>
                    <li><span class="dialog-text">Klicke auf <span class="text_bold">My Owned Sets</span> in der Liste.</span></li>
                    <li><span class="dialog-text">Nun findest Du unter <span class="text_bold">My Owned Sets</span> den Button <span class="text_bold">Import/Export</span> auf der rechten Seite.</span></li>
                    <li><span class="dialog-text">Unter dem Punkt <span class="text_bold">Export Sets</span> klicke auf <span class="text_bold">To a CSV file</span>.</span></li>
                    <li><span class="dialog-text">Wähle bei 1. <span class="text_bold">CSV file</span> und bei 2. <span class="text_bold">By sets</span> aus.</span></li>
                    <li><span class="dialog-text">Klicke anschließend auf <span class="text_bold">EXPORT</span> um Deine Sammlung zu speichern. <br>
                      Eine Datei mit dem Namen <span class="text_bold">BrickEconomy-Sets</span> wurde erzeugt.</span></li>
                    <li><span class="dialog-text">Lade diese Datei nun hier unter <i id="icon_file_in_text" class="far fa-file-import"></i> hoch.</span></li>
                    <li><span class="dialog-text">Klicke auf <span class="text_bold">IMPORTIEREN</span> um Deine BrickEconomy Sammlung hoch zu laden.<br>
                      Hinweis: Wenn Du bereis Sets in deiner Sammlung hier angelegt hast, werden die importierten Sets angehangen.</span></li>
                  </ol>
                </v-window-item>

              </v-window>
            </v-card-text>
          </div>

          <v-row class="row_input_file" no-gutters>
            <label class="custom_input_file">
              <input type="file" name="input_file" accept="text/csv" @change="setFile" required/>
              <i id="icon_file" class="far fa-file-import"></i>
            </label>
            <span class="text_input_file"> {{ defaultFileName }} </span>
          </v-row>
          <v-row no-gutters class="message-container">
            <span class="message">{{ message }}</span>
          </v-row>
        </div>
        <div class="dialog-action">
          <PrimaryButton @click="close()" class="cancel-button" buttonText="Abbrechen" />
          <PrimaryButton @click="importCollection()" buttonText="Importieren" type="submit"/>
        </div>
      </div>
  </div>
</template>

<script>
import PrimaryButton from '../PrimaryButton.vue';

export default {
  name: 'ImportCollectionDialogDesktop',
  components: {
    PrimaryButton
  },
  props: ['setPortfolio', 'id'],
  inject: [
    'api'
  ],
  data() {
    return {
      defaultFileName: "Datei wählen ...",
      inputFile: null,
      tab: null,
      message: '',
      loading: true,
    }
  },
  watch: {
  },
  created() {
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    close() {
      this.$emit('close');
    },
    setFile(event) {
      this.defaultFileName = event.target.files[0].name ? event.target.files[0].name : undefined
      this.inputFile = event.target.files[0] ? event.target.files[0] : undefined
    },
    async importCollection() {
      if (this.inputFile) {
        const formData = new FormData();

        formData.append('title', 'collection-import');
        formData.append('file', this.inputFile);

        const response = await this.api.transport.post('/import/collection', formData);
        if(response.status === 204 || response.status === 200) {
          setTimeout(() => window.location.reload(), 1000);
        } else {
          this.message = 'Etwas ist schief gelaufen.';
          setTimeout(() => (this.message = ""), 3000);
        }
      }
      else {
        this.message = "Bitte lade eine CSV Datei hoch."
        setTimeout(() => (this.message = ""), 3000);
      }
    }
  }
}
</script>

<style scoped>

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog-card {
  position: relative;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
}

.dialog-body {
  margin: 1rem 1.5rem;
}

.dialog-text {
  color: #ffffff;
}

.dialog-action {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 1rem;
  color: #5C5DD8 !important;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%) !important;
}

.row_input_file {
  align-items: center;
}

.custom_input_file {
  display: flex;
  color: #ffffff;
  width: 80px;
  height: 44px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg,hsla(0,0%,100%,.1) 30%,hsla(0,0%,100%,.3));
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid hsla(0,0%,100%,.8);
  border-radius: 999px;
  cursor: pointer;
  transition: 0.5s ease all;
}

.custom_input_file:hover {
  background: linear-gradient(135deg,hsla(0,0%,100%,.1) 30%,hsla(0,0%,100%,.4));
  box-shadow: 0px 2px 4px rgba(28, 8, 77, 0.3);
}

#icon_file {
  font-size: 16px;
  color: #ffffff;
}

#icon_file_in_text {
  margin-right: 0.25rem;
}

.text_input_file {
  margin-left: 1rem;
  color: #ffffff;
}

.tab {
  max-width: 800px;
  margin-top: 1rem;
}

.button_tab {
  color: #60ffe4;
}

ol {
  margin: 0 0 1rem 1rem;
  color: #ffffff;
}

ol li {
  padding: 0.25rem 0;
}

.text-link {
  font-size: 14px;
}

.message-container {
  min-height: 24px;
}

.message {
  font-size: 16px;
  color: #ffd6f6;
  margin: 0 auto;
  transition: 0.5s ease all;
}

.codeblock {
  display: flex;
  width: 95%;
  flex-direction: column;
  padding: 0.5rem 1rem;
  background: rgba(92, 93, 216, 0.2);
  border-radius: 10px;
}

.text_codeblock {
  color: #60ffe4;
  padding: 0.15rem 0;
}

.v-tabs--density-default {
  height: 44px;
}

</style>
