<template>
  <div id="chart">
    <div class="chart_toolbar">
      <button
        id="one_month"
        @click="updateData('one_month')"
        :class="{ active: selection === 'one_month' }"
      >
        1M
      </button>

      <button
        id="six_months"
        @click="updateData('six_months')"
        :class="{ active: selection === 'six_months' }"
      >
        6M
      </button>

      <button
        id="one_year"
        @click="updateData('one_year')"
        :class="{ active: selection === 'one_year' }"
      >
        1Y
      </button>

      <button
        id="ytd"
        @click="updateData('ytd')"
        :class="{ active: selection === 'ytd' }"
      >
        YTD
      </button>

      <button
        id="all"
        @click="updateData('all')"
        :class="{ active: selection === 'all' }"
      >
        ALL
      </button>
    </div>

    <div id="chart-timeline">
      <apexchart
        type="area"
        ref="chart"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AreaChartWithDatetime',
  props: ['chartData', 'firstChartEntryDatetime'],
  data() {
    return {
      series: [{
        name: "Kosten der Sammlung",
        data: this.chartData
      }],
      chartOptions: {
        chart: {
          id: 'area-datetime',
          type: 'area',
          // TODO: localize
          defaultLocale: 'en',
          locales: [{
            name: 'en',
            options: {
              months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
              shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              toolbar: {
                download: 'Download SVG',
                selection: 'Selection',
                selectionZoom: 'Selection Zoom',
                zoomIn: 'Zoom In',
                zoomOut: 'Zoom Out',
                pan: 'Panning',
                reset: 'Reset Zoom',
              }
            }
          }],
          fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
          foreColor: '#2e2e2e',
          // sparkline: {
          //   enabled: true
          // },
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: true | '<i class="fa fa-undo"></i>',
              customIcons: []
            },
          },
          zoom: {
            autoScaleYaxis: false
          }
        },
        colors: ['#60ffe4'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        grid: {
          show: false,
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          tickAmount: undefined,
          tickPlacement: 'on',
          min: undefined,
          max: Date.now(),
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: 'bottom',
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: '#ffffff',
              fontSize: '9px',
              fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: 0,
            offsetY: -5,
            format: undefined,
            formatter: undefined,
            datetimeUTC: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM. yy",
              day: 'dd.MM.yy',
              hour: 'HH:mm',
            },
          },
          axisBorder: {
            show: true,
            color: '#ffffff',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#ffffff',
            height: 2,
            offsetX: 0,
            offsetY: 0
          },
          tooltip: {
            enabled: true,
            style: {
              fontSize: '9px',
              fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
            },
          },
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          custom: function({ series, seriesIndex, dataPointIndex }) {
            return (
              '<div class="tooltip_box">' +
              '<span class="tooltip_value">' +
              series[seriesIndex][dataPointIndex].toFixed(2).replace('.', ',') +
              " €" +
              "</span>" +
              '<span class="tooltip_date">' +
              // new Date(w.globals.labels[dataPointIndex]).toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}) +
              'Aktueller Wert' +
              "</span>" +
              "</div>"
            );
          },
          style: {
            fontSize: '10px',
            fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
          },
          x: {
            show: true,
            format: 'dd. MMM. yyyy',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.8,
            opacityTo: 0,
            stops: [0, 100]
          }
        },
      },
      noData: {
        text: 'Keine Daten verfügbar!',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#ffffff',
          fontSize: '12px',
          fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
        }
      },
      selection: 'one_year',
    }
  },
  methods: {
    updateData(timeline) {
      this.selection = timeline

      var datetime = new Date();

      switch (timeline) {
        case 'one_month':
          this.$refs.chart.zoomX(
            datetime.setMonth(datetime.getMonth() - 1),
            Date.now()
          )
          break
        case 'six_months':
          this.$refs.chart.zoomX(
            datetime.setMonth(datetime.getMonth() - 3),
            Date.now()
          )
          break
        case 'one_year':
          this.$refs.chart.zoomX(
            datetime.setFullYear(datetime.getFullYear() - 1),
            Date.now()
          )
          break
        case 'ytd':
          this.$refs.chart.zoomX(
            datetime.setFullYear(datetime.getFullYear() - 1),
            Date.now()
          )
          break
        case 'all':
          this.$refs.chart.zoomX(
            this.firstChartEntryDatetime,
            Date.now()
          )
          break
        default:
      }
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
  }
}
</script>

<style>

#chart {
  margin-right: 0.75rem;
}

#one_month,
#six_months,
#one_year,
#ytd,
#all {
  width: 32px;
  height: 22px;
  margin: 0 0.25rem;
  color: #312659;
  background: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  font-weight: 500;
  border-radius: 999px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease all;
}

#one_month:hover,
#six_months:hover,
#one_year:hover,
#ytd:hover,
#all:hover {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.chart_toolbar {
  display: flex;
  justify-content: right;
  /* margin-right: 0.75rem; */
}

.tooltip_box {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
}

.tooltip_value {
  color: #60ffe4;
  text-shadow: 1px 2px 0px rgb(0, 0, 0, 30%);
  font-size: 12px;
  font-weight: 700;
  margin: 0 auto;
}

.tooltip_date {
  font-size: 10px;
  margin: 0 auto;
}

.apexcharts-xaxistooltip {
  padding: 9px 0px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
}

.apexcharts-xaxistooltip.apexcharts-theme-dark {
  background: rgba(30, 30, 30, 0.8);
  color: #fff;
}

</style>
