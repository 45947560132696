import { createApp } from 'vue';
import App from './App.vue';

import vuetify from './plugins/vuetify';
import router from './plugins/router';
import VueApexCharts from "vue3-apexcharts";
import Popper from "vue3-popper";
import {Directus} from "@directus/sdk";

const api = getApiInstance();
let refreshPromise = api.auth.token;

const app = createApp(
  App,
  {
    outerApi: api,
  });
app.use(vuetify);
app.use(router);
app.use(VueApexCharts);
app.use(Popper);

refreshPromise
  .catch(() => {
    api.auth.logout();
  })
  .finally(() => {
    app.mount('#app');
    refreshPromise = null
  });

function getApiInstance() {
  const api = new Directus(process.env.VUE_APP_API_DOMAIN, {
    auth: {
      mode: 'json'
    }
  });

  api.auth.resetPassword = async function(token, password) {
    return await this._transport.post('/auth/password/reset', {token: token, password: password}, {headers: {Authorization: null}});
  }.bind(api.auth);

  return api;
}
