<template>
  <section>

    <div class="logo-container">
      <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4052 724.6">
        <g>
          <polygon points="1046.37 261.6 1046.37 493.1 1046.37 724.6 1277.87 724.6 1277.87 493.1 1277.87 261.6 1046.37 261.6"/>
          <path d="M1299.11,948c0-127.43-103.31-230.73-230.74-230.73V948Z" transform="translate(-22 -717.25)"/>
        </g>
        <g>
          <rect x="555.6" y="493.1" width="231.5" height="231.5"/>
          <path d="M808.33,1210.35c0-127.43-103.3-230.74-230.73-230.74v230.74Z" transform="translate(-22 -717.25)"/>
          <path d="M775.91,978.85c0,127.43,103.3,230.73,230.73,230.73V978.85Z" transform="translate(-22 -717.25)"/>
        </g>
        <g>
          <rect x="2713.16" y="33.19" width="231.5" height="231.5"/>
          <polygon points="2713.16 261.6 2713.16 493.1 2713.16 724.6 2944.66 724.6 2944.66 493.1 2944.66 261.6 2713.16 261.6"/>
          <path d="M2473.56,1210.35c0,127.43,103.3,230.73,230.73,230.73V1210.35Z" transform="translate(-22 -717.25)"/>
          <path d="M2704.29,948.75c-127.43,0-230.73,103.3-230.73,230.73h230.73Z" transform="translate(-22 -717.25)"/>
        </g>
        <g>
          <rect x="1895.2" y="33.19" width="231.5" height="231.5"/>
          <polygon points="1895.2 261.6 1895.2 493.1 1895.2 724.6 2126.7 724.6 2126.7 493.1 2126.7 261.6 1895.2 261.6"/>
          <path d="M2179.56,1178.72c127.44,0,230.74-103.31,230.74-230.74H2179.56Z" transform="translate(-22 -717.25)"/>
          <path d="M2410.3,1441.84c0-127.43-103.3-230.73-230.74-230.73v230.73Z" transform="translate(-22 -717.25)"/>
        </g>
        <g>
          <path d="M1593.1,948.75c-127.43,0-230.73,103.3-230.73,230.73H1593.1Z" transform="translate(-22 -717.25)"/>
          <path d="M1624,1178.72c127.43,0,230.73-103.31,230.73-230.74H1624Z" transform="translate(-22 -717.25)"/>
          <path d="M1854.7,1441.84c0-127.43-103.3-230.73-230.73-230.73v230.73Z" transform="translate(-22 -717.25)"/>
          <path d="M1362.37,1210.35c0,127.43,103.3,230.73,230.73,230.73V1210.35Z" transform="translate(-22 -717.25)"/>
        </g>
        <g>
          <path d="M3581.67,948c0,127.43,103.3,230.74,230.73,230.74V948Z" transform="translate(-22 -717.25)"/>
          <path d="M3843.27,1178.72C3970.7,1178.72,4074,1075.41,4074,948H3843.27Z" transform="translate(-22 -717.25)"/>
          <path d="M3812.4,1211.11c-127.43,0-230.73,103.3-230.73,230.73H3812.4Z" transform="translate(-22 -717.25)"/>
          <path d="M4074,1441.84c0-127.43-103.3-230.73-230.73-230.73v230.73Z" transform="translate(-22 -717.25)"/>
        </g>
        <g>
          <path d="M3259.89,948.75c-127.43,0-230.73,103.3-230.73,230.73h230.73Z" transform="translate(-22 -717.25)"/>
          <path d="M3521.49,1179.48c0-127.43-103.3-230.73-230.73-230.73v230.73Z" transform="translate(-22 -717.25)"/>
          <path d="M3521.49,1441.84c0-127.43-103.3-230.73-230.73-230.73v230.73Z" transform="translate(-22 -717.25)"/>
          <path d="M3029.16,1210.35c0,127.43,103.3,230.73,230.73,230.73V1210.35Z" transform="translate(-22 -717.25)"/>
        </g>
        <g>
          <rect y="33.19" width="231.5" height="231.5"/>
          <polygon points="0 261.6 0 493.1 0 724.6 231.5 724.6 231.5 493.1 231.5 261.6 0 261.6"/>
          <path d="M515.1,1179.48c0-127.43-103.3-230.73-230.74-230.73v230.73Z" transform="translate(-22 -717.25)"/>
          <path d="M284.36,1441.08c127.44,0,230.74-103.3,230.74-230.73H284.36Z" transform="translate(-22 -717.25)"/>
        </g>
      </svg>
    </div>

    <v-container class="impressum-container">
      <span class="header">Impressum</span>
      <span class="impressum-data">brickdex GmbH</span>
      <span class="impressum-data">Anton-Saefkow-Straße 2</span>
      <span class="impressum-data">18069 Rostock</span>

      <span class="impressum-header">Geschäftsführer</span>
      <span class="impressum-data">Michael Friedrichs</span>
      <span class="impressum-data">Dennis Gabler</span>

      <span class="impressum-header">Kontakt</span>
      <span class="impressum-data">Telefon: +49 177 2454999</span>
      <span class="impressum-data">E-Mail: hello@brickdex.io</span>

      <span class="impressum-header">Register</span>
      <span class="impressum-data">Registergericht: Amtsgericht Rostock</span>
      <span class="impressum-data">Registernummer: HRB 15694</span>

      <span class="impressum-header">Umsatzsteuer-ID</span>
      <span class="impressum-data">DE352113328</span>

      <span class="impressum-header">Haftung für Inhalte</span>
      <p class="impressum-text">
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
        überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
        werden wir diese Inhalte umgehend entfernen.
      </p>

      <span class="impressum-header">Haftung für Links</span>
      <p class="impressum-text">
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
        fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
        der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        <br>
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
        Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>

      <span class="impressum-header">Urheberrecht</span>
      <p class="impressum-text">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        <br>
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
        Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>

    </v-container>
  </section>
</template>

<script>

export default {
  name: 'Impressum',
  components: {

  },
  inject: [
    'api'
  ],
  data() {
    return {

    }
  },
  created() {

  },
  unmounted() {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  height: 100%;
  background: rgb(255, 247, 245);
  padding-bottom: 4rem;
}

.header {
  font-size: 44px;
  font-weight: 800;
  color: rgb(0, 0, 0);
  margin-bottom: 2rem;
}

.logo-container {
  width: 22rem;
  margin: 0 auto;
}

#logo {
  margin: 8rem 0 4rem
}

.impressum-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
}

.impressum-header {
  font-size: 24px;
  font-weight: 700;
  margin: 1.5rem 0 0.5rem;
}

.impressum-data,
.impressum-text {
  font-size: 18px;
}

.impressum-text {
  width: 70%;
}

</style>
