<template>
  <section :style="'min-height:' + windowHeight">
    <v-container class="card-container">
      <div class="card">
        <div class="dialog-body">
          <v-row>
            <span class="header">Account erstellen</span>
          </v-row>
          <v-row>
            <div class="form-container">
              <form @submit.prevent="register">
                <label class="label-input" for="email">E-Mail *</label>
                <v-row no-gutters class="input-container">
                  <input id="email" v-model="email" class="input-field" placeholder="jemand@beispiel.de" type="email" required>
                  <i class="fal fa-user input-icon"></i>
                </v-row>
                <label class="label-input" for="nickname">Benutzername *</label>
                <v-row no-gutters class="input-container">
                  <input id="nickname" v-model="nickname" class="input-field" placeholder="Nickname" required>
                  <i class="fal fa-tag input-icon"></i>
                </v-row>
                <label class="label-input" for="password">Passwort *</label>
                <v-row no-gutters class="input-container">
                  <input id="password" v-model="password" class="input-field" placeholder="Passwort" type="password" required>
                  <i class="fal fa-key input-icon"></i>
                </v-row>
                <div class="policy-container">
                  <input class="checkbox" type="checkbox" required>
                  <span class="policy-text">Ich habe die
                    <router-link class="text-link policy" to="/privacy-policy">Datenschutzerklärung</router-link>
                    von brickdex gelesen und akzeptiert.
                  </span>
                </div>
                <v-row>
                  <PrimaryButton id="register-button" buttonText="Account anlegen" type="submit" />
                </v-row>
                <v-row>
                  <router-link class="text-link login" :to="{ name: 'Login' }">Du hast bereits einen Account?</router-link>
                </v-row>
              </form>
            </div>
            <v-row>
              <span class="register-message">{{ message }}</span>
            </v-row>
          </v-row>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import PrimaryButton from '../../../components/PrimaryButton.vue';
import router from "../../../plugins/router";

export default {
  name: "RegistrationInit",
  components: {
    PrimaryButton
  },
  inject: [
    'api',
    'loadUser',
  ],
  data() {
    return {
      windowHeight: '',
      email: '',
      nickname: '',
      password: '',
      message: '',
    }
  },
  created() {
    this.windowHeight = window.innerHeight -220 + 'px';
  },
  methods: {
    register() {
      this.api.transport.post('users', {
        email: this.email,
        nickname: this.nickname,
        password: this.password,
      }).then(() => {
        this.message = this.email + ' erfolgreich angelegt.';
        this.login();
        this.password = '';
        setTimeout(() => {
          router.push('/');
        }, 2000);
      }).catch((err) => {
        this.message = err.message;
      });
    },
    login() {
      this.api.auth.login({
        email: this.email,
        password: this.password,
      });
    },
  }
}
</script>

<style scoped>
section {
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

#clouds {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.card-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6rem;
}

.card {
  width: 90%;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.7) 45%, rgba(92, 89, 226, 0.5) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 12px 0 rgb(0, 0, 0, 0.3);
  border-radius: 25px;
}

.v-row {
  margin: 5px;
  padding-bottom: 1rem;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 32px;
  font-weight: 800;
  color: rgb(255, 255, 255);
  z-index: 5;
}

.form-container {
  width: 100%;
}

label {
  position: relative;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.input-field {
  border: 1px solid rgba(255, 255, 255, 0.25);
}

#register-button {
  margin: 2rem 0 0.25rem 0;
  width: 100%;
}

.text-link.login {
  font-size: 14px;
  margin-left: 1rem;
  transform: translateY(-1rem);
  z-index: 2;
}

.policy-container {
  display: flex;
}

.checkbox {
  cursor: pointer;
  margin: 0.25rem 0.5rem 0 1rem;
}

.text-link.policy {
  font-size: 14px;
}

.policy-text {
  font-size: 14px;
  color: #ffffff;
}

.register-message {
  font-size: 14px;
  color: #d5d9ff;
  transition: 0.5s ease all;
}


</style>
