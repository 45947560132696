<template>
  <section :style="'min-height:' + windowHeight">
    <template v-if="reset_token">
      <v-container class="header-container">
        <span class="header">Neues Passwort setzen</span>
      </v-container>
      <v-container class="text-container">
      </v-container>
      <v-container class="form-container">
        <form @submit.prevent="reset">
          <label class="label-input" for="password">Passwort *</label>
          <v-row no-gutters class="input-container">
            <input id="password" v-model="password" class="input-field" placeholder="Passwort" type="password" required>
            <i class="fal fa-key input-icon"></i>
          </v-row>
          <v-row>
            <PrimaryButton id="reset-button" buttonText="Absenden" type="submit" />
          </v-row>
          <v-row>
            <span class="reset-message">{{ message }}</span>
          </v-row>
        </form>
      </v-container>
    </template>
    <template v-else>
      <v-container class="header-container">
        <span class="header">Passwort zurücksetzen</span>
      </v-container>
      <v-container class="text-container">
        <p class="text">Gebe die E-Mail Adresse ein, mit der du dich bei brickdex registriert hast.</p>
      </v-container>
      <v-container class="form-container">
        <form ref="form" @submit.prevent="resetPassword">
          <label class="label-input" for="email">E-Mail</label>
          <v-row no-gutters class="input-container">
            <input id="username" @keyup.enter="resetPassword" v-model="email" class="input-field" placeholder="E-Mail" type="email" required>
            <i class="fal fa-at input-icon"></i>
          </v-row>
          <v-row>
            <PrimaryButton id="reset-button" buttonText="Passwort zurücksetzen" type="submit"/>
          </v-row>
        </form>
        <v-row>
          <span class="reset-message" v-if="requestSent && !resetSuccess">Wird gesendet ...</span>
          <span class="reset-message" v-if="resetSuccess">Erfolgreich gesendet.</span>
        </v-row>
      </v-container>
    </template>

  </section>
</template>

<script>
import PrimaryButton from '../../components/PrimaryButton.vue';
import router from "../../plugins/router";

export default {
  name: 'PasswordReset',
  components: {
    PrimaryButton,
  },
  inject: [
    'api'
  ],

  data() {
    return {
      email: '',
      requestSent: false,
      resetSuccess: false,
      windowHeight: '40rem',
      message: '',
      password: '',
      reset_token: ''
    }
  },
  created() {
    this.windowHeight = window.innerHeight-220 + 'px';
    this.checkForResetToken()
  },
  methods: {
    async resetPassword() {
      if (this.email !== '') {
        this.requestSent = true;
        const reset = await this.api.transport.post('auth/password/request', {email: this.email, reset_url: process.env.VUE_APP_API_PW_RESET_URL});

        if (reset.status === 200) {
          this.resetSuccess = true;
        }
      }
    },
    reset() {
      this.api.auth.resetPassword(this.reset_token, this.password).then(() => {
        this.message = 'Passwort wurde gesetzt';
        setTimeout(() => {
          router.replace('/reset-password');
          router.push('/login');
        }, 1000);
      }).catch(() => {
        this.message = "Es ist etwas schief gelaufen."
      });
    },
    checkForResetToken () {
      this.reset_token = router.currentRoute.value.query.token || false;
    }
  },
}
</script>

<style scoped>
section {
  min-height: 600px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 6rem 0 2rem;
}

.header {
  font-size: 44px;
  font-weight: 800;
  color: rgb(255, 255, 255);
  z-index: 5;
}

.text-container {
  display: flex;
  justify-content: center;
  width: 70%;
}

.text {
  font-size: 18px;
  color: rgb(255, 255, 255);
}


.form-container {
  height: 100%;
  width: 70%;
  padding-top: 5rem;
}

label {
  position: relative;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.input-field {
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.v-row {
  margin: 5px;
  padding-bottom: 1rem;
}

#reset-button {
  margin: 2rem 0 0.25rem 0;
  width: 100%;
}

.text-link.login {
  font-size: 14px;
  margin-left: 1rem;
  transform: translateY(-1rem);
}

.reset-message {
  font-size: 14px;
  color: #d5d9ff;
  margin: 0 auto;
  transition: 0.5s ease all;
}


/* MEDIA QUERIES */

@media (min-width: 1280px) {
  .form-container {
    width: 40%;
  }

}
</style>
