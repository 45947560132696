<template>
  <section :style="'min-height:' + windowHeight">
    <v-container class="header-container">
      <span class="header">Kontaktiere uns</span>
    </v-container>
    <v-container class="text-container">
      <p class="text">
        Du hast Anregungen, Wünsche oder eine andere Form von Feedback für uns? Wir freuen uns sehr darauf, von dir zu hören.
        Dazu nutzt du einfach unser Kontaktformular und wir antworten dir so schnell wie nur irgendwie möglich. <br>

      </p>
    </v-container>
    <v-container class="contact-form-container">
      <form @submit.prevent="send">
        <label class="label-input" for="name">Name *</label>
        <v-row no-gutters class="input-container">
          <input id="name" v-model="name" class="input-field" placeholder="Name" type="text" required>
          <i class="fal fa-user input-icon"></i>
        </v-row>
        <label class="label-input" for="email">E-Mail *</label>
        <v-row no-gutters class="input-container">
          <input id="email" type="email" v-model="email" class="input-field" placeholder="E-Mail" required>
          <i class="fal fa-at input-icon"></i>
        </v-row>
        <label class="label-input" for="subject">Betreff *</label>
        <v-row no-gutters class="input-container">
          <input id="subject" type="text" v-model="subject" class="input-field" placeholder="Dein Anliegen" required>
          <i class="fal fa-bullhorn input-icon"></i>
        </v-row>
        <label class="label-input" for="message">Nachricht an uns *</label>
        <v-row no-gutters class="input-container">
          <textarea id="text-field" v-model="message" class="input-field" placeholder="Schreib uns was nettes..." type="text" required></textarea>
        </v-row>
        <label class="label-input" for="message">DSGVO-Einverständnis *</label>
        <v-row no-gutters class="input-container">
          <input id="dsgvo" type="checkbox" v-model="dsgvo" required aria-invalid="true">
          <label class="label-input">Ich willige ein, dass diese Website meine übermittelten Informationen speichert, sodass meine Anfrage beantwortet werden kann.</label>
        </v-row>
        <v-row>
          <PrimaryButton id="contact-button" buttonText="Nachricht schicken" type="submit"/>
        </v-row>
      </form>
      <v-row>
        <span class="contact-message">{{ requestMessage }}</span>
      </v-row>
    </v-container>

  </section>


</template>

<script>
import PrimaryButton from '../components/PrimaryButton.vue';
// import emailjs from 'emailjs-com';

export default {
  name: "Registration",
  components: {
    PrimaryButton
  },
  inject: [
    'api'
  ],
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      dsgvo: false,
      requestMessage: '',
      windowHeight: '40rem',
    }
  },
  created() {
    this.windowHeight = window.innerHeight-220 + 'px';
  },
  methods: {
    checkForm() {
      if (this.firstname !== '' &&
          this.email !== '' &&
          this.subject !== '' &&
          this.message !== '' &&
          this.dsgvo) {
        this.send();
      }
    },
    // send() {
    //   emailjs.send('service_9oowbqg','template_wog06ub',{
    //     name: this.name,
    //     email: this.email,
    //     subject: this.subject,
    //     message: this.message,
    //   }, 'user_R3xm9pU9HLNXEg6go8mtF')
    //   .then(() => {
    //     this.requestMessage = 'Nachricht erfolgreich verschickt.';
    //     setTimeout(() => {
    //       this.requestMessage = '';
    //     }, 3000);
    //   })
    //   .catch(() => {
    //     this.requestMessage = 'Etwas ist schief gelaufen!';
    //   });
    // }
  }
}
</script>

<style scoped>
section {
  height: 100%;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 6rem 0 2rem;
}

.text-container {
  display: flex;
  justify-content: center;
  width: 70%;
}

.text {
  font-size: 18px;
  color: rgb(255, 255, 255);
}

.header {
  font-size: 44px;
  font-weight: 800;
  color: rgb(255, 255, 255);
}

.contact-form-container {
  height: 100%;
  width: 70%;
  padding-top: 2rem;
}

label {
  position: relative;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.input-field {
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.v-row {
  margin: 5px;
  padding-bottom: 1rem;
}

.contact {
  background: linear-gradient(90deg, rgba(255,255,255,0.35) 25%, rgba(255,255,255,0.2) 100%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  transition: 0.5s ease all;
}

.contact:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

i {
  z-index: 10;
}

#text-field {
  height: 10rem;
  min-height: 5rem;
  max-height: 20rem;
  padding-top: 0.7rem;
  margin-right: 1rem;
  border-radius: 25px;
}

#dsgvo {
  margin-left: 0.6rem;
}

#contact-button {
  margin: 2rem 0 0.25rem 0;
  width: 100%;
}

.text-link.login {
  font-size: 14px;
}

.contact-message {
  font-size: 14px;
  color: #d5d9ff;
  margin: 0 auto;
  transition: 0.5s ease all;
}


/* MEDIA QUERIES */

@media (min-width: 1280px) {
  .contact-form-container {
    width: 40%;
  }

}
</style>
