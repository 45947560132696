<template>
  <div class="app">
    <Navigation class="main-navigation" />
    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue';
import FooterComponent from './components/FooterComponent.vue';
import {computed} from 'vue';

export default {
  name: 'App',
  components: {
    Navigation,
    FooterComponent
  },
  props: [
    'outerApi'
  ],

  data() {
    return {
      user: null,
      isMobile: this.checkMobile(),
      api: this.outerApi,
    }
  },
  created() {
    if (this.loggedIn) {
      this.loadUser();
    }
  },
  methods: {
    async loadUser() {
      if (this.loggedIn) {
        const response = await this.api.transport.get('/users/me');
        this.user = response.data;
      }
    },
    clearUser() {
      this.user = null;
    },
    checkMobile() {
      if (('ontouchstart' in window) ||
        ("ontouchstart" in document.documentElement) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0) ||
        (window.matchMedia("(pointer: coarse)").matches)) {
          return true;
        }
    },
  },
  provide() {
    return {
      api: this.api,
      user: computed(() => this.user),
      loadUser: this.loadUser,
      clearUser: this.clearUser,
      loggedIn: computed(() => this.loggedIn),
      isMobile: this.isMobile,
      localLanguage: 'de-DE',
      localCurrency: 'eur'
    }
  },

  computed: {
    loggedIn() {
      return !!this.api.auth.storage.auth_token;
    },
  },
}
</script>

<style>

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

a {
  font-size: 16px;
  text-decoration: none;
}

.main-navigation {
  z-index: 1000;
}

/* CONTAINER */
.input-container {
  width: 100%;
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: center;
}
/* END CONTAINER */

.row-search {
  position: relative;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 40;
}

/* HEADER */
h1 {
  color: #ffffff;
  font-size: 50px;
  font-weight: 800;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
}

h2 {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 600;
}

h3 {
  color: #ffffff;
  margin-top: 1rem;
}

.set_minifig_name {
  min-height: 4.8rem;
  max-height: 4.8rem;
  margin-left: 2.5rem;
  color: #ffffff;
  font-size: 34px;
  font-weight: 800;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  line-height: 1.05;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  transform: translateY(-0.5rem);
}

.header-text {
  margin-left: 1.5rem;
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

.dialog-header {
  display: flex;
  align-items: center;
  height: 2.75rem;
}

.header.mobile {
  font-size: 2rem;
}

.row_header {
  justify-content: center;
}

.table_header {
  font-size: 14px;
  font-weight: 700 !important;
  color: #312659 !important;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
  transition: 0.5s ease all;
}

.info-list-header {
  font-size: 14px;
  font-weight: 700;
  color: #312659;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
  vertical-align: top;
}

.info-list-data {
  font-size: 14px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}
/* END HEADER */

/* LABEL */
.label-input {
  color: #ffffff;
  font-size: 12px;
  padding-left: 1rem;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  transition: 0.5s ease all;
}

.input-field {
  align-content: center;
  height: 44px;
  width: 100%;
  font-size: 12px;
  color: #ffffff;
  padding-left: 1rem;
  padding-right: 2rem;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.3) 100%);
  border-radius: 9999px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.5s ease all;
  z-index: 2;
}

.input-field::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.input-field:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.input-field:focus {
  outline: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.input-unit,
.input-icon {
  width: 15px;
  color: rgba(255, 255, 255, 1);
  transform: translateX(-25px);
  transition: 0.5s ease all;
}

.input-unit {
  font-size: 12px;
}

.input-icon {
  font-size: 10px;
}

.input-field.input-date {
  padding-right: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  transform: translateX(-0.5rem);
}

input[type="file"] {
  display: none;
}
/* END LABEL */

/* LINKS */
.text-link {
  display: inline-block;
  font-size: 14px;
  color: #60ffe4;
  cursor: pointer;
  text-decoration: none;
}

.text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #60ffe4;
  transition: width .3s;
}

.text-link:hover::after {
  width: 100%;
}
/* END LINKS */


/* ICON */
.icon {
  color: #ffffff;
  font-size: 24px;
  margin: 0 1rem;
  cursor: pointer;
  transition: 0.5s ease all;
}

.icon:hover {
  color: #60ffe4;
  text-shadow: 2px 2px 4px rgba(28, 8, 77, 0.3);
}

.icon.info {
  font-size: 18px;
}

.icon.undo {
  font-size: 18px;
}

.icon.save,
.icon.import,
.icon.delete_all {
  font-size: 20px;
}

.icon.close {
  font-size: 26px;
}

.icon.delete-set,
.icon.edit-set {
  font-size: 16px;
  margin: 0 0.5rem;
}

.icon.close:hover,
.icon.delete-set:hover,
.icon.delete_all:hover {
  color: rgb(252, 179, 236);
}

.icon_onsite_link {
  font-size: 10px;
  margin-left: 0.35rem;
}

/* END ICON */

/* TEXT */
.normal_text {
  color: #ffffff;
  font-size: 14px;
}

.content_text {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.text_bold {
  font-weight: 700;
  font-style: italic;
}

.text_bold.mobile {
  font-size: 12px;
}

/* END TEXT */


/* CARD */
.card_main {
  position: relative;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
  transition: 0.5s ease transform;
}

.card_main.blank {
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.2) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.5s ease all;
}

.card-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 44px;
}

.card_main_header {
  height: 2.75rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
/* END CARD */

/* BUTTONS */
  .button_tab {
  color: #60ffe4;
}

.v-field__input {
  font-size: 14px !important;
}

.input_field {
  color: #ffffff !important;
}

.v-text-field__prefix {
  color: #ffffff !important;
}

.v-field--focused .v-text-field__prefix {
  color: #60ffe4 !important;
}

/* END BUTTONS */


/* TOOLTIP */
:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 25px;
  --popper-theme-padding: 16px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.75);
}

.tooltip {
  font-size: 12px;
  z-index: 999;
}

.info_tooltip {
  color: rgba(49, 38, 89, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.5s ease all;
}

.info_tooltip:hover {
  color: rgba(49, 38, 89, 0.8);
}
/* END TOOLTIP */

ul.breadcrumb {
  margin-left: 2.5rem;
  list-style: none;
  transform: translateY(-0.5rem);
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

ul.breadcrumb li+li:before {
  padding: 8px;
  color: #ffffff;
  content: ">\00a0";
}

ul.breadcrumb li a {
  position: relative;
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: .5px;
  z-index: 2;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #60ffe4
}


::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* ::-webkit-calendar-picker-indicator, */
input[type="date"]::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}




/* SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(31, 31, 31, 0.6);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(28, 28, 28, 0.8);
}
/* END SCROLLBAR */

/* ANIMATIONS */
.fade-enter-active,
.fade-leave-active  {
  transition: 0.5s ease all;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-fast-enter-active,
.fade-fast-leave-active  {
  transition: 0.2s ease all;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
  opacity: 0;
}

.fade-fast-enter-to {
  opacity: 1;
}

.fade-rotate-enter-active,
.fade-rotate-leave-active {
  transition: .5s ease all;
}

.fade-rotate-enter-from,
.fade-rotate-leave-to {
  transform: rotate(180deg);
  opacity: 0;
}

.fade-rotate-enter-to {
  opacity: 1;
}

.fade-left-enter-active,
.fade-left-leave-active  {
  transition: 0.8s ease all;
}

.fade-left-enter-from,
.fade-left-leave-to {
  transform: translateX(-50px);
  opacity: 0;
}

.fade-left-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.fade-right-enter-active,
.fade-right-leave-active  {
  transition: 0.8s ease all;
}

.fade-right-enter-from,
.fade-right-leave-to {
  transform: translateX(30px);
  opacity: 0;
}

.fade-right-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.loader {
  color: #60ffe4;
}


/* MEDIA QUERIES */
@media (min-width: 600px) {

}

@media (min-width: 1280px) {
  .header-text {
    font-size: 18px;
  }

  .input-icon,
  .label-input {
    font-size: 14px;
  }

  .input-field,
  .input-unit,
  .label {
    font-size: 14px;
  }

  .icon_onsite_link {
    font-size: 12px;
  }

}

</style>
