<template>
  <v-row no-gutters class="input-container">
    <select class="combobox" name="filter" v-model="value" :style="comboboxStyle">
      <option class="option" value="" selected hidden>{{ placeholderText }}</option>
      <option v-for="op in options" :key="op" class="option">{{ op.text }}</option>
    </select>
    <i class="fal fa-chevron-down input-icon"></i>
  </v-row>
</template>

<script>
export default {
  name: 'Combobox',
  props: ['placeholderText', 'options', 'modelValue', 'comboboxWidth', 'density'],
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        if (this.modelValue !== undefined) return this.modelValue;
        return '';
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data() {
    return {
      comboboxStyle: {
        width: this.comboboxWidth,
        height: this.density
      }
    }
  },
  methods: {

  }
}



</script>

<style scoped>

.input-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}


select {
  width: 100%;
  height: 44px;
  color: #ffffff;
  padding-left: 1rem;
  padding-right: 2rem;
  font-size: 12px;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.25) 100%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 9999px;
  transition: 0.5s ease all;
  outline: none;
  cursor: pointer;
}

select:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

select.combobox option.option {
  color: cornflowerblue;
  border-radius: 20px;
}

.input-icon {
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  transform: translateX(-25px);
  cursor: pointer;
}

@media (min-width: 1280px) {
  select {
    font-size: 14px;
  }
}

</style>
