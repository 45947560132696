<template>

  <template v-if="isMobile">
    <table>
      <tr>
        <td class="info-list-header text_mobile">Set</td>
        <td class="info-list-data text_mobile">{{ set_data.set }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Name</td>
        <td class="info-list-data text_mobile">{{ set_data.name }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Themenwelt</td>
        <template v-if="set_data.theme !== '-' && set_data.theme !== '' && set_data.theme !== null">
          <td>
            <a :href="getThemeLink(set_data.theme)" class="table_text_link link">
              <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ set_data.theme }}</v-chip>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="info-list-data text_mobile">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Unterthema</td>
        <template v-if="set_data.subtheme !== '-' && set_data.subtheme !== '' && set_data.subtheme !== null">
          <td>
            <a :href="getSubthemeLink(set_data.subtheme)" class="table_text_link link">
              <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ set_data.subtheme }}</v-chip>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="info-list-data text_mobile">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Release</td>
        <template v-if="convertDateString(set_data.release_date) !== '-' && convertDateString(set_data.release_date) !== '' && convertDateString(set_data.release_date) !== null">
          <td>
            <a :href="getReleaseDateLink(set_data.release_date)" class="table_text_link link">
              <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ convertDateString(set_data.release_date) }}</v-chip>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="info-list-data text_mobile">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Teile</td>
        <td class="info-list-data text_mobile">{{ set_data.pieces }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Minifiguren</td>
        <template v-if="displayMinifigCount() !== '-' && displayMinifigCount() !== '' && displayMinifigCount() !== null">
          <td>
            <router-link :to="{ path: '/items/sets-overview', query: { minifigures: set_data.minifig_count.count }}">
              <v-chip v-if="displayMinifigCount()" id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ displayMinifigCount() }}</v-chip>
              <span class="info-list-data text_mobile" v-else>-</span>
            </router-link>
          </td>
        </template>
      </tr>
      <template v-if="((set_data.legacy_retail_price !== set_data.retail_price) && (set_data.legacy_retail_price !== '-' && set_data.legacy_retail_price !== '' && set_data.legacy_retail_price !== null))">
        <tr>
          <td class="info-list-header text_mobile">urspr. UVP
            <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die UVP am Erscheinungsdatum.">
              <i class="fas fa-question-circle info_tooltip"></i>
            </Popper>
          </td>
          <template v-if="set_data.legacy_retail_price !== '-' && set_data.legacy_retail_price !== '' && set_data.legacy_retail_price !== null">
            <td class="info-list-data">
              <a :href="getPriceLink(set_data.legacy_retail_price)" class="table_text_link link">
                <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ set_data.legacy_retail_price }}<span>{{ '&nbsp;' + this.mapCurrencyUnit(localCurrency) }}</span></v-chip>
              </a>
            </td>
          </template>
          <template v-else>
            <td class="info-list-data text_mobile">-</td>
          </template>
        </tr>
        <tr>
          <td class="info-list-header text_mobile">aktuelle UVP</td>
          <template v-if="set_data.retail_price !== '-' && set_data.retail_price !== '' && set_data.retail_price !== null">
            <td class="info-list-data">
              <a :href="getPriceLink(set_data.retail_price)" class="table_text_link link">
                <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ set_data.retail_price }}<span>{{ '&nbsp;' + this.mapCurrencyUnit(localCurrency) }}</span></v-chip>
              </a>
            </td>
          </template>
          <template v-else>
            <td class="info-list-data text_mobile">-</td>
          </template>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td class="info-list-header text_mobile">UVP</td>
          <template v-if="set_data.retail_price !== '-' && set_data.retail_price !== '' && set_data.retail_price !== null">
            <td class="info-list-data">
              <a :href="getPriceLink(set_data.retail_price)" class="table_text_link link">
                <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ set_data.retail_price }}<span>{{ '&nbsp;' + this.mapCurrencyUnit(localCurrency) }}</span></v-chip>
              </a>
            </td>
          </template>
          <template v-else>
            <td class="info-list-data text_mobile">-</td>
          </template>
        </tr>
      </template>
      <tr>
        <td class="info-list-header text_mobile">Preis pro
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Preis pro Teil | Preis pro Gewicht in Gramm">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <template v-if="(set_data.price_per_piece !== '-' && set_data.price_per_piece !== '' && set_data.price_per_piece !== null) && (set_data.price_per_weight !== '-' && set_data.price_per_weight !== '' && set_data.price_per_weight !== null)">
          <td class="info-list-data text_mobile">{{ set_data.price_per_piece }} ct/Teil | {{ set_data.price_per_weight }} ct/g</td>
        </template>
        <template v-else-if="(set_data.price_per_piece !== '-' && set_data.price_per_piece !== '' && set_data.price_per_piece !== null) && (set_data.price_per_weight === '-' || set_data.price_per_weight === '' || set_data.price_per_weight === null)">
          <td class="info-list-data text_mobile">{{ set_data.price_per_piece }} ct/Teil</td>
        </template>
        <template v-else-if="(set_data.price_per_piece === '-' || set_data.price_per_piece === '' || set_data.price_per_piece === null) && (set_data.price_per_weight !== '-' && set_data.price_per_weight !== '' && set_data.price_per_weight !== null)">
          <td class="info-list-data text_mobile">{{ set_data.price_per_weight }} ct/g</td>
        </template>
        <template v-else>
          <td class="info-list-data text_mobile">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Verfügbarkeit</td>
        <td v-if="set_data.availability" class="info-list-data">
          <a :href="getAvailabiliyLink(set_data.availability)" class="table_text_link link">
            <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ set_data.availability }}</v-chip>
          </a>
        </td>
        <td v-else class="info-list-data text_mobile">-</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">EOL</td>
        <template v-if="convertDateString(set_data.eol_date) !== '-' && convertDateString(set_data.eol_date) !== '' && convertDateString(set_data.eol_date) !== null">
          <td class="info-list-data">
            <a :href="getEolDateLink(set_data.eol_date)" class="table_text_link link">
              <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ convertDateString(set_data.eol_date) }}</v-chip>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="info-list-data text_mobile">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Designer</td>
        <td v-if="set_data.designer" class="info-list-data">
          <template v-for="designer in set_data.designer" :key="designer.key">
            <a :href="getDesignerLink(designer)" class="table_text_link link">
              <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data text_mobile">{{ designer }}</v-chip>
            </a>
          </template>
        </td>
        <td v-else class="info-list-data text_mobile">-</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Alter</td>
        <td class="info-list-data text_mobile">{{ set_data.recommended_age || '-' }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">VE
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Verpackungseinheit: Anzahl an Exemplaren je LEGO-Umkarton.">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td class="info-list-data text_mobile">{{ set_data.amount_per_sales_unit || '-' }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Verpackung</td>
        <td class="info-list-data text_mobile">{{ set_data.packaging || '-' }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Maße (Verp.)
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Maße der Verpackung in (Höhe x Breite x Tiefe)">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td class="info-list-data text_mobile">{{ this.formatPackageSize(set_data.package_size_height, set_data.package_size_width, set_data.package_size_depth) }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Maße (Set)
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Maße des gebauten Sets in (Höhe x Breite x Tiefe)">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td class="info-list-data text_mobile">{{ this.formatPackageSize(set_data.dimension_height, set_data.dimension_width, set_data.dimension_depth) }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Gewicht (Verp.)
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Gewicht des originalverpackten Sets in Gramm.">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td v-if="set_data.package_weight" class="info-list-data text_mobile">{{ set_data.package_weight }} g</td>
        <td v-else class="info-list-data text_mobile">-</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Gewicht (Set)
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Gewicht der Teile inkl. Anleitung in Gramm.">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td v-if="set_data.dimensions_weight" class="info-list-data text_mobile">{{ set_data.dimensions_weight }} g</td>
        <td v-else class="info-list-data text_mobile">-</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">EAN</td>
        <td class="info-list-data text_mobile">{{ set_data.ean || '-'}}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Tags</td>
        <td class="info-list-data text_mobile">{{ set_data.tags || '-'}}</td>
      </tr>
      <tr v-if="set_data.note">
        <td class="info-list-header text_mobile">Bemerkung</td>
        <td class="info-list-data text_mobile">{{ set_data.note || '-'}}</td>
      </tr>
    </table>
  </template>

  <template v-else>
    <table>
      <tr>
        <td class="info-list-header">Set</td>
        <td class="info-list-data">{{ set_data.set }}</td>
      </tr>
      <tr>
        <td class="info-list-header">Name</td>
        <td class="info-list-data">{{ set_data.name }}</td>
      </tr>
      <tr>
        <td class="info-list-header">Themenwelt</td>
        <template v-if="set_data.theme !== '-' && set_data.theme !== '' && set_data.theme !== null">
          <td>
            <a :href="getThemeLink(set_data.theme)" class="table_text_link link">
              <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ set_data.theme }}</v-chip>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="info-list-data">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header">Unterthema</td>
        <template v-if="set_data.subtheme !== '-' && set_data.subtheme !== '' && set_data.subtheme !== null">
          <td>
            <a :href="getSubthemeLink(set_data.subtheme)" class="table_text_link link">
              <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ set_data.subtheme }}</v-chip>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="info-list-data">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header">Release</td>
        <template v-if="convertDateString(set_data.release_date) !== '-' && convertDateString(set_data.release_date) !== '' && convertDateString(set_data.release_date) !== null">
          <td>
            <a :href="getReleaseDateLink(set_data.release_date)" class="table_text_link link">
              <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ convertDateString(set_data.release_date) }}</v-chip>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="info-list-data">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header">Teile</td>
        <td class="info-list-data">{{ set_data.pieces }}</td>
      </tr>
      <tr>
        <td class="info-list-header">Minifiguren</td>
        <template v-if="displayMinifigCount() !== '-' && displayMinifigCount() !== '' && displayMinifigCount() !== null">
          <td>
            <router-link :to="{ path: '/items/sets-overview', query: { minifigures: set_data.minifig_count.count }}">
              <v-chip v-if="displayMinifigCount()" id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ displayMinifigCount() }}</v-chip>
              <span class="info-list-data" v-else>-</span>
            </router-link>
          </td>
        </template>
      </tr>
      <template v-if="((set_data.legacy_retail_price !== set_data.retail_price) && (set_data.legacy_retail_price !== '-' && set_data.legacy_retail_price !== '' && set_data.legacy_retail_price !== null))">
        <tr>
          <td class="info-list-header">urspr. UVP
            <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die UVP am Erscheinungsdatum.">
              <i class="fas fa-question-circle info_tooltip"></i>
            </Popper>
          </td>
          <template v-if="set_data.legacy_retail_price !== '-' && set_data.legacy_retail_price !== '' && set_data.legacy_retail_price !== null">
            <td class="info-list-data">
              <a :href="getPriceLink(set_data.legacy_retail_price)" class="table_text_link link">
                <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ set_data.legacy_retail_price }}<span>{{ '&nbsp;' + this.mapCurrencyUnit(localCurrency) }}</span></v-chip>
              </a>
            </td>
          </template>
          <template v-else>
            <td class="info-list-data">-</td>
          </template>
        </tr>
        <tr>
          <td class="info-list-header">aktuelle UVP</td>
          <template v-if="set_data.retail_price !== '-' && set_data.retail_price !== '' && set_data.retail_price !== null">
            <td class="info-list-data">
              <a :href="getPriceLink(set_data.retail_price)" class="table_text_link link">
                <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ set_data.retail_price }}<span>{{ '&nbsp;' + this.mapCurrencyUnit(localCurrency) }}</span></v-chip>
              </a>
            </td>
          </template>
          <template v-else>
            <td class="info-list-data">-</td>
          </template>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td class="info-list-header">UVP</td>
          <template v-if="set_data.retail_price !== '-' && set_data.retail_price !== '' && set_data.retail_price !== null">
            <td class="info-list-data">
              <a :href="getPriceLink(set_data.retail_price)" class="table_text_link link">
                <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ set_data.retail_price }}<span>{{ '&nbsp;' + this.mapCurrencyUnit(localCurrency) }}</span></v-chip>
              </a>
            </td>
          </template>
          <template v-else>
            <td class="info-list-data">-</td>
          </template>
        </tr>
      </template>
      <tr>
        <td class="info-list-header">Preis pro
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Preis pro Teil | Preis pro Gewicht in Gramm">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <template v-if="(set_data.price_per_piece !== '-' && set_data.price_per_piece !== '' && set_data.price_per_piece !== null) && (set_data.price_per_weight !== '-' && set_data.price_per_weight !== '' && set_data.price_per_weight !== null)">
          <td class="info-list-data">{{ set_data.price_per_piece }} ct/Teil | {{ set_data.price_per_weight }} ct/g</td>
        </template>
        <template v-else-if="(set_data.price_per_piece !== '-' && set_data.price_per_piece !== '' && set_data.price_per_piece !== null) && (set_data.price_per_weight === '-' || set_data.price_per_weight === '' || set_data.price_per_weight === null)">
          <td class="info-list-data">{{ set_data.price_per_piece }} ct/Teil</td>
        </template>
        <template v-else-if="(set_data.price_per_piece === '-' || set_data.price_per_piece === '' || set_data.price_per_piece === null) && (set_data.price_per_weight !== '-' && set_data.price_per_weight !== '' && set_data.price_per_weight !== null)">
          <td class="info-list-data">{{ set_data.price_per_weight }} ct/g</td>
        </template>
        <template v-else>
          <td class="info-list-data">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header">Verfügbarkeit</td>
        <td v-if="set_data.availability" class="info-list-data">
          <a :href="getAvailabiliyLink(set_data.availability)" class="table_text_link link">
            <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ set_data.availability }}</v-chip>
          </a>
        </td>
        <td v-else class="info-list-data">-</td>
      </tr>
      <tr>
        <td class="info-list-header">EOL</td>
        <template v-if="convertDateString(set_data.eol_date) !== '-' && convertDateString(set_data.eol_date) !== '' && convertDateString(set_data.eol_date) !== null">
          <td class="info-list-data">
            <a :href="getEolDateLink(set_data.eol_date)" class="table_text_link link">
              <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ convertDateString(set_data.eol_date) }}</v-chip>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="info-list-data">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header">Designer</td>
        <td v-if="set_data.designer" class="info-list-data">
          <template v-for="designer in set_data.designer" :key="designer.key">
            <a :href="getDesignerLink(designer)" class="table_text_link link">
              <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ designer }}</v-chip>
            </a>
          </template>
        </td>
        <td v-else class="info-list-data">-</td>
      </tr>
      <tr>
        <td class="info-list-header">Alter</td>
        <td class="info-list-data">{{ set_data.recommended_age || '-' }}</td>
      </tr>
      <tr>
        <td class="info-list-header">VE
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Verpackungseinheit: Anzahl an Exemplaren je LEGO-Umkarton.">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td class="info-list-data">{{ set_data.amount_per_sales_unit || '-' }}</td>
      </tr>
      <tr>
        <td class="info-list-header">Verpackung</td>
        <td class="info-list-data">{{ set_data.packaging || '-' }}</td>
      </tr>
      <tr>
        <td class="info-list-header">Maße (Verp.)
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Maße der Verpackung in (Höhe x Breite x Tiefe)">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td class="info-list-data">{{ this.formatPackageSize(set_data.package_size_height, set_data.package_size_width, set_data.package_size_depth) }}</td>
      </tr>
      <tr>
        <td class="info-list-header">Maße (Set)
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Maße des gebauten Sets in (Höhe x Breite x Tiefe)">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td class="info-list-data">{{ this.formatPackageSize(set_data.dimension_height, set_data.dimension_width, set_data.dimension_depth) }}</td>
      </tr>
      <tr>
        <td class="info-list-header">Gewicht (Verp.)
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Gewicht des originalverpackten Sets in Gramm.">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td v-if="set_data.package_weight" class="info-list-data">{{ set_data.package_weight }} g</td>
        <td v-else class="info-list-data">-</td>
      </tr>
      <tr>
        <td class="info-list-header">Gewicht (Set)
          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Gewicht der Teile inkl. Anleitung in Gramm.">
            <i class="fas fa-question-circle info_tooltip"></i>
          </Popper>
        </td>
        <td v-if="set_data.dimensions_weight" class="info-list-data">{{ set_data.dimensions_weight }} g</td>
        <td v-else class="info-list-data">-</td>
      </tr>
      <tr>
        <td class="info-list-header">EAN</td>
        <td class="info-list-data">{{ set_data.ean || '-'}}</td>
      </tr>
      <tr>
        <td class="info-list-header">Tags</td>
        <td class="info-list-data">{{ set_data.tags || '-'}}</td>
      </tr>
      <tr v-if="set_data.note">
        <td class="info-list-header">Bemerkung</td>
        <td class="info-list-data">{{ set_data.note || '-'}}</td>
      </tr>
    </table>
  </template>

</template>

<script>
export default {
  name: 'SetInformationTable',
  props: [ 'set_data' ],
  inject: [
    'isMobile',
    'localCurrency',
    'localLanguage',
    'refMinifigures',
  ],
  methods: {
    displayMinifigCount() {
      if (this.set_data.minifig_count) {
        if (this.set_data.minifig_count.count > 0) {
          return this.set_data.minifig_count.count + ' | ' + this.set_data.minifig_count.exclusive + ' excl.' || '';
        }
      }
      return 0;
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    convertDateString(dateString) {
      if (dateString) {
        try {
          let convertedDateString = (new Date(dateString))?.toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'}) ?? '-';
          if (convertedDateString.includes('Invalid')) { convertedDateString = '-'; }
          return convertedDateString;
        }
        catch(err) {
          return '-';
        }
      }
      else {
        return '-';
      }
    },
    formatPackageSize(height, width, depth) {
      if (height || width || depth) {
        let returnHeight = null;
        let returnWidth = null;
        let returnDepth = null;
        if (height) { returnHeight = (height / 10).toFixed(1); }
        if (width) { returnWidth = (width / 10).toFixed(1); }
        if (depth) { returnDepth = (depth / 10).toFixed(1); }
        return returnHeight + ' x ' + returnWidth + ' x ' + returnDepth + ' cm';
      }
      else return '-';
    },
    getThemeLink(theme) {
      if (theme) {
        const themeNoSpecialChars = (((theme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
        const routeTheme = themeNoSpecialChars.toLowerCase();
        return '/themes/' + routeTheme;
      }
    },
    getSubthemeLink(subtheme) {
      if (subtheme) {
        // const subthemeNoSpecialChars = (((subtheme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
        // const routeSubtheme = subthemeNoSpecialChars.toLowerCase();
        return '/items/sets-overview?subtheme=' + subtheme;
      }
    },
    getReleaseDateLink(release_date) {
      if (release_date) {
        try {
          let converted_release_year = (new Date(release_date))?.toLocaleDateString(this.localLanguage, {year: 'numeric'}) ?? '-';
          if (converted_release_year.includes('Invalid')) { converted_release_year = '-'; }
          return '/items/sets-overview?yearRelease=' + converted_release_year;
        }
        catch(err) {
          return '/items/sets-overview';
        }
      }
      else {
        return '/items/sets-overview';
      }
    },
    getEolDateLink(eol_date) {
      if (eol_date) {
        try {
          let converted_eol_date = (new Date(eol_date))?.toLocaleDateString(this.localLanguage, {year: 'numeric'}) ?? '-';
          if (converted_eol_date.includes('Invalid')) { converted_eol_date = '-'; }
          return '/items/sets-overview?yearEol=' + converted_eol_date;
        }
        catch(err) {
          return '/items/sets-overview';
        }
      }
      else {
        return '/items/sets-overview';
      }
    },
    getPriceLink(price) {
      return '/items/sets-overview?retailPriceStart=' + price + '&retailPriceEnd=' + price;
    },
    getAvailabiliyLink(availability) {
      if (availability) {
        let availabilities = [
          { text: "LEGO Exklusiv" , value: "lego_exclusive" },
          { text: "Fachhandel" , value: "limited_retail" },
          { text: "Überall" , value: "retail" },
        ];
        const currentAvailability = availabilities.find(el => el.text === availability).value;
        return '/items/sets-overview?availability=' + currentAvailability;
      }
    },
    getDesignerLink(designer) {
      if (designer) {
        return '/items/sets-overview?designer=' + designer;
      }
      else {
        return '/items/sets-overview';
      }
    },
}
}

</script>

<style scoped>
.info-list-header {
  min-width: 120px;
}

.info-list-data {
  font-size: 14px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.link {
  cursor: pointer;
}

  .link:hover {
    color: #60ffe4;
    transition: 0.5s ease all;
  }

.table_text_link {
  font-size: 14px;
  color: #ffffff;
}

#chip_link {
  font-size: 14px;
  white-space: normal;
  height: 100%;
  padding: 4px 10px;
  cursor: pointer;
  transition: 0.5s ease all;
}

  #chip_link:hover {
    color: #60ffe4;
  }

#chip_link_mobile {
  font-size: 12px;
  white-space: normal;
  height: 100%;
  padding: 4px 10px;
  cursor: pointer;
  transition: 0.5s ease all;
}

  #chip_link_mobile:hover {
    color: #60ffe4;
  }

.text_mobile {
  font-size: 12px;
}

@media (min-width: 960px) {
  .info-list-header {
    min-width: 150px;
  }
}

@media (min-width: 1280px) {
  .info-list-header {
    min-width: 140px;
  }
}

</style>
