<template>
  <!-- add set to collection dialog for desktop -->
  <transition name="fade">
    <AddSetDialogWithSearchDesktop v-if="addSetDialogActive" @close="addSetDialogActive = false" />
  </transition>

  <!-- import collection dialog for desktop -->
  <transition name="fade">
    <ImportCollectionDialogDesktop v-if="importCollectionDialogActive" @close="importCollectionDialogActive = false" />
  </transition>

  <!-- clear collection dialog for desktop -->
  <transition name="fade">
    <ClearCollectionDialogDesktop v-if="clearCollectionDialogActive" :collection="collection" @close="clearCollectionDialogActive = false" />
  </transition>

  <!-- themes allocation dialog for all devices -->
  <transition name="fade">
    <AllocationThemesDialogAllDevices v-if="themeAllocationDialogActive" :collection="collection" @close="themeAllocationDialogActive = false" />
  </transition>

  <template v-if="loggedIn.value">
    <section>
      <v-container>
        <v-row no-gutters class="row-search">
          <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
        </v-row>
      </v-container>

      <v-container>
        <v-row no-gutters class="mb-6 row-collection">
          <v-col cols="9">
            <div class="card card-collection">
              <div class="card-header collection">
                <span class="header-text">Meine Collection</span>
                <v-spacer></v-spacer>
                <i @click="openAddSetDialog()" class="fal fa-plus icon plus"></i>
                <i @click="openImportCollectionDialog()" class="far fa-file-import icon import"></i>
                <i @click="openClearCollectionDialog()" class="fal fa-trash-alt icon delete_all"></i>
              </div>
              <template v-if="loadingActive">
                <div class="card_body_loader">
                  <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
                </div>
              </template>
              <template v-else>
                <div class="card-body-collection">
                  <template v-if="collection.length > 0">
                    <table class="collection-table">
                      <thead>
                        <tr>
                          <th v-for="th in tableHeader" :key="th">
                            <div class="collection-header">
                              <span>{{ th.text }}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="td in collection" :key="td" @click="activateSetDetails(td)" class="table-row">
                          <td id="td-image" class="collection-data">
                            <div class="image-container small">
                              <v-img v-if="getSetHeaderImage(td.set?.header_image)" class="image" :src="getSetHeaderImage(td.set.header_image)" :lazy-src="getSetHeaderImage(td.set.header_image)"></v-img>
                              <v-img v-else-if="getSetFallbackImage(td.set?.images)" class="image" :src="getSetFallbackImage(td.set.images)" :lazy-src="getSetFallbackImage(td.set.images)"></v-img>
                              <v-icon v-else id="small_image_alt" icon="mdi-package" ></v-icon>
                            </div>
                          </td>
                          <td id="td-amount" class="collection-data">{{ td.collection.length }}x</td>
                          <td id="td-set" class="collection-data">{{ td.set?.set ?? '-' }}</td>
                          <td class="collection-data">{{ mapNameToLocal(td.set?.name, localLanguage) || '-' }}</td>
                          <td class="collection-data">{{ td.set?.theme?.name ?? '' }}</td>
                          <td v-if="td.set?.release_date" class="collection-data">{{ (new Date(td.set?.release_date)).toLocaleDateString('de-DE', {year: 'numeric'}) ?? '-' }}</td>
                          <td v-else class="collection-data">-</td>
                          <td class="collection-data">{{ mapRetailPriceToLocal(td.set?.retail_price, localCurrency) }}</td>
                          <td v-if="td.set?.eol_date" class="collection-data">{{ (new Date(td.set?.eol_date)).toLocaleDateString('de-DE', {year: 'numeric'}) ?? '-' }}</td>
                          <td v-else class="collection-data">-</td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template v-else>
                    <v-row no-gutters id="add_item_hint">
                      <span class="hint-text">
                        Du hast noch keinen Eintrag in deiner Collection. <br>
                        Klicke entweder auf das <i @click="openAddSetDialog()" class="fal fa-plus icon plus"></i>
                        um etwas manuell hinzuzufügen oder importiere über <i @click="openImportCollectionDialog()" class="far fa-file-import icon import"></i>
                        deine Sammlung als CSV Datei.
                      </span>
                    </v-row>
                  </template>
                </div>
              </template>
            </div>
          </v-col>
          <v-col cols="3" class="pl-6">
            <div class="card card-collection-performance">
              <div class="card-header collection">
                <span class="header-text">Übersicht Lager</span>
                <v-spacer></v-spacer>
              </div>
              <div class="card-body">
                <div class="label-container">
                  <AreaChartWithDatetime v-if="chartDataValue.length > 0" :chartData="chartDataValue" :firstChartEntryDatetime="firstChartEntryDatetime" />
                  <tbody>
                    <tr>
                      <td><span class="label">Marktwert (aktuell)</span></td>
                      <td><span class="label value">{{ sumCollectionCurrentValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Marktwert (prog.)
                        <Popper class="tooltip" placement="top" arrow=true offsetDistance="8" content="Der prognostizierte Marktwert ist ein hochgradig spekulativer Wert welcher in 1-3 Jahren erzielt werden kann, basierend auf unserem eigenen Algorithmus. Natürlich alles ohne Gewähr.">
                        <i class="fas fa-question-circle info_tooltip"></i>
                      </Popper></span></td>
                      <td><span class="label value">{{ sumCollectionFutureValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Summe UVP</span></td>
                      <td><span class="label value">{{ sumCollectionRetailValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Summe EK</span></td>
                      <td><span class="label value">{{ sumCollectionPricePaidValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Performance</span></td>
                      <template v-if="diffPerformance >= 0.00"><td><span class="label value balance_positive">{{ diffPerformance }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                      <template v-else><td><span class="label value balance_negative">{{ diffPerformance }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                    </tr>
                    <tr>
                      <td><span class="label">Performance in %</span></td>
                      <template v-if="diffPerformancePercent >= 0.00"><td><span class="label value balance_positive">{{ diffPerformancePercent }} %</span></td></template>
                      <template v-else><td><span class="label value balance_negative">{{ diffPerformancePercent }} <span v-show="diffPerformancePercent != '-'">%</span></span></td></template>
                    </tr>
                    <tr>
                      <td><span class="label">Ersparnis zur UVP</span></td>
                      <template v-if="diffCollectionSums >= 0.00"><td><span class="label value balance_positive">{{ diffCollectionSums }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                      <template v-else><td><span class="label value balance_negative">{{ diffCollectionSums }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                    </tr>
                    <tr class="border_bottom">
                      <td><span class="label">Ersparnis in %</span></td>
                      <template v-if="diffCollectionSumsPercent >= 0.00"><td><span class="label value balance_positive">{{ diffCollectionSumsPercent }} %</span></td></template>
                      <template v-else><td><span class="label value balance_negative">{{ diffCollectionSumsPercent }} <span v-show="diffCollectionSumsPercent != '-'">%</span></span></td></template>
                    </tr>
                  </tbody>
                </div>
              </div>
            </div>
            <div class="card card-collection-details">
              <div class="card-header collection">
                <span class="header-text">Details Lager</span>
                <v-spacer></v-spacer>
              </div>
              <div class="card-body">
                <div class="label-container">
                  <tbody>
                    <tr>
                      <td><span class="label">Sets im Besitz</span></td>
                      <td><span class="label value">{{ sumCollectionSets }}</span></td>
                    </tr>
                    <tr>
                      <td>&emsp;<span class="label">davon neu</span></td>
                      <td><span class="label value">{{ sumCollectionSetsNew }}</span></td>
                    </tr>
                    <tr>
                      <td>&emsp;<span class="label">davon gebraucht</span></td>
                      <td><span class="label value">{{ sumCollectionSetsUsed }}</span></td>
                    </tr>
                    <tr>
                      <td>&emsp;<span class="label">Unterschiedlich
                      <Popper class="tooltip" placement="top" arrow=true offsetDistance="8" content="Die Anzahl an unterschiedlichen Einträgen. (zb. Für Sets, welche mehrfach vorhanden sind, wird nur eins addiert).">
                        <i class="fas fa-question-circle info_tooltip"></i>
                      </Popper></span></td>
                      <td><span class="label value">{{ sumCollectionItems }} </span></td>
                    </tr>
                    <tr>
                      <td><span class="label link" @click="openThemeAllocationDialog()">Themenwelten <i class="fa fa-chart-pie icon_onsite_link"></i></span></td>
                      <td><span class="label value">{{ sumThemes }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Teile</span></td>
                      <td><span class="label value">{{ sumCollectionParts }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Minifiguren</span></td>
                      <td><span class="label value">{{ sumCollectionMinifigs }}</span></td>
                    </tr>
                  </tbody>
                  <router-link id="more_analysis_link" to='/usercollection/analysis'>
                    <v-chip id="chip_link" size="small" class="ml-1 mt-2">Mehr Analysen</v-chip>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="mt-6 card card-collection-performance">
              <div class="card-header collection">
                <span class="header-text">Übersicht Verkauft</span>
                <v-spacer></v-spacer>
              </div>
              <div class="card-body">
                <div class="label-container">
                  <tbody>
                    <tr>
                      <td><span class="label">Sets verkauft</span></td>
                      <td><span class="label value">{{ sumCollectionSalesSetAmount }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Einnahmen</span></td>
                      <td><span class="label value">{{ sumCollectionSalesValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Ausgaben</span></td>
                      <td><span class="label value">{{ sumCollectionSalesCosts }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                    </tr>
                    <tr>
                      <td><span class="label">Ergebnis</span></td>
                      <template v-if="diffPerformanceSales >= 0.00"><td><span class="label value balance_positive">{{ diffPerformanceSales }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                      <template v-else><td><span class="label value balance_negative">{{ diffPerformanceSales }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                    </tr>
                  </tbody>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </template>

  <template v-else>
    <section>
      <v-container>
        <v-row no-gutters class="row-search">
          <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
        </v-row>
        <v-row no-gutters>
          <div class="hint-card">
            <i class="fal fa-info icon-hint"></i>
          </div>
        </v-row>
        <v-row no-gutters>
          <span class="hint-text">
            Um Deine Collection anlegen und verwalten zu können, musst Du
            <router-link class="text-link" to="/registration">registriert</router-link>
            sein.
          </span>
          <span class="hint-text">
            Hast Du bereits einen Account, dann
            <router-link class="text-link" to="/login">melde</router-link>
            Dich einfach an.
          </span>
        </v-row>
      </v-container>
    </section>
  </template>
</template>

<script>
import Search from '../../components/Search.vue';
import AddSetDialogWithSearchDesktop from '../../components/Dialogs/AddSetDialogWithSearchDesktop.vue';
import ImportCollectionDialogDesktop from '../../components/Dialogs/ImportCollectionDialogDesktop.vue';
import ClearCollectionDialogDesktop from '../../components/Dialogs/ClearCollectionDialogDesktop.vue';
import AllocationThemesDialogAllDevices from '../../components/Dialogs/AllocationThemesDialogAllDevices.vue';
import AreaChartWithDatetime from '../../components/AreaChartWithDatetime.vue';
import moment from 'moment/min/moment-with-locales';

export default {
  name: 'Collection',
  components: {
    AddSetDialogWithSearchDesktop,
    ImportCollectionDialogDesktop,
    ClearCollectionDialogDesktop,
    AllocationThemesDialogAllDevices,
    AreaChartWithDatetime,
    Search,
  },
  inject: [
    'api',
    'loggedIn',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      mobile: null,
      addSetDialogActive: false,
      importCollectionDialogActive: false,
      clearCollectionDialogActive: false,
      themeAllocationDialogActive: false,
      responseMessage: '',
      tableHeader: [
        { name: 'image', text: '' },
        { name: 'amount', text: '' },
        { name: 'setnumber', text: 'Set' },
        { name: 'name', text: 'Name' },
        { name: 'theme', text: 'Themenwelt' },
        { name: 'release', text: 'Release' },
        { name: 'retailorPrice', text: 'UVP' },
        { name: 'eol', text: 'EOL' },
        { name: 'link', text: '' },
      ],
      collection: [],
      newCollectionItem: {
        set: null,
        amount: null,
        condition: '',
        purchase_date: null,
        retailer: null,
        price_payed: null,
        discount: null,
        storage: null
      },
      search_result_active: false,
      search_result: '',
      search_result_set: '',
      search_result_name: '',
      search_result_image: '',
      search_value: '',
      closeIcon: false,
      suggestionsActive: false,
      sets: [],
      // minifigures: [],
      themes: [],
      mainImage: '',
      suggestionWidth: null,
      debounce: null,
      chartDataBuy: [],
      chartDataValue: [],
      firstChartEntryDatetime: null,
      loadingActive: false,
    }
  },
  watch: {
    price_payed() {
      if (this.search_result.retail_price) {
        this.discount = parseFloat(((1-(this.price_payed / this.search_result.retail_price[0]?.price))*100).toFixed(2));
      }
    },
  },
  computed: {
    sumCollectionSets() {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (!(item.sales_date || item.sales_price)) { result++; }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionSetsNew() {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('new')))) { result++; }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionSetsUsed() {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('used')))) { result++; }
          });
        });
        return result
      }
      return 0;
    },
    sumThemes() {
      if (this.collection) {
        let result = 0;
        let themes = [];
        this.collection.forEach(item => {
          if (item.set?.theme?.name) {
            if (!(themes.includes(item.set?.theme?.name))) {
              themes.push(item.set?.theme?.name ?? undefined);
            }
          }
        });
        result = themes.length;
        return result;
      }
      return 0;
    },
    sumCollectionItems() {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          for (let item of set.collection) {
            if (!(item.sales_date || item.sales_price)) {
              result++;
              break;
            }
          }
        });
        return result
      }
      return 0;
    },
    sumCollectionParts() {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (!(item.sales_date || item.sales_price)) {
              result += item?.set?.pieces ?? 0;
            }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionMinifigs() {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (!(item.sales_date || item.sales_price)) {
              result += item?.set?.minifig_count?.count ?? 0;
            }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionRetailValue() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(set => {
          if (set.set?.retail_price) {
            const localRetailPrice = this.mapRetailPriceToLocal(set.set.retail_price, this.localCurrency);
            set.collection.forEach(item => {
              if (!(item.sales_date || item.sales_price)) {
                result = parseFloat(result + localRetailPrice);
              }
            });
          }
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    sumCollectionCurrentValue() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item.condition) {
              let currentSetValue = 0.00;
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('new')))) { currentSetValue =  item.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.avg_price ?? 0.00 }
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('used')))) { currentSetValue =  item.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.avg_price ?? 0.00 }
              result = parseFloat(result + currentSetValue);
            }
          });
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    sumCollectionFutureValue() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item.condition) {
              let currentSetValue = 0.00;
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('new')))) { currentSetValue =  item.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.max_price ?? 0.00 }
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('used')))) { currentSetValue =  item.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.max_price ?? 0.00 }
              result = parseFloat(result + currentSetValue);
              let randPercent = Math.floor(Math.random() * 3);
              result = result * ((100 - randPercent) / 100);
            }
          });
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    sumCollectionPricePaidValue() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(item => {
          item.collection.forEach(collectionItem => {
            if (!(collectionItem.sales_date || collectionItem.sales_price)) {
              result += parseFloat((collectionItem?.price_payed || 0).toFixed(2));
            }
          });
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    sumCollectionSalesSetAmount() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(item => {
          item.collection.forEach(collectionItem => {
            if (collectionItem.sales_date || collectionItem.sales_price) { result++; }
          });
        });
        return result;
      }
      return '';
    },
    sumCollectionSalesValue() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(item => {
          item.collection.forEach(collectionItem => {
            if (collectionItem.sales_date || collectionItem.sales_price) {
              result += parseFloat((collectionItem?.sales_price || 0).toFixed(2));
            }
          });
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    sumCollectionSalesCosts() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(item => {
          item.collection.forEach(collectionItem => {
            if (collectionItem.sales_date || collectionItem.sales_price) {
              let price_payed = collectionItem?.price_payed;
              let fees = collectionItem?.fees;
              let shipping = collectionItem?.shipping;
              result += parseFloat((price_payed + fees + shipping ?? 0).toFixed(2));
            }
          });
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    diffPerformance() {
      return (this.sumCollectionCurrentValue - this.sumCollectionPricePaidValue).toFixed(2) || 0.00;
    },
    diffPerformanceSales() {
      return (this.sumCollectionSalesValue - this.sumCollectionSalesCosts).toFixed(2) || 0.00;
    },
    diffCollectionSums() {
      return (this.sumCollectionRetailValue - this.sumCollectionPricePaidValue).toFixed(2) || 0.00;
    },
    diffPerformancePercent() {
      let result = 0;
      let diff = (this.sumCollectionCurrentValue - this.sumCollectionPricePaidValue).toFixed(2);
      let calc = ((diff / this.sumCollectionPricePaidValue) * 100).toFixed(2);
      if (isNaN(calc)) {
        return result.toFixed(2);
      }
      if (this.sumCollectionCurrentValue == 0.00 || this.sumCollectionCurrentValue == '') { return '-'; }
      return calc;
    },
    diffCollectionSumsPercent() {
      let result = 0;
      let diff = (this.sumCollectionRetailValue - this.sumCollectionPricePaidValue).toFixed(2);
      let calc = ((diff / this.sumCollectionRetailValue) * 100).toFixed(2);
      if (isNaN(calc)) {
        return result.toFixed(2);
      }
      if (this.sumCollectionRetailValue == 0.00 || this.sumCollectionRetailValue == '') { return '-'; }
      return calc;
    }
  },
  created() {
    this.conditions = [
      { text: "Neu, versiegelt" , value: "new_sealed" },
      { text: "Neu, leichte Schäden" , value: "new_minor_damages" },
      { text: "Neu, starke Schäden" , value: "new_major_damages" },
      { text: "Gebraucht, Tüten verschlossen" , value: "used_bags_closed" },
      { text: "Gebraucht, gebaut, nicht bespielt" , value: "used_build_not_played" },
      { text: "Gebraucht, bespielt" , value: "used_played" },
      { text: "Gebraucht, Karton fehlt" , value: "used_box_missing" },
      { text: "Gebraucht, nicht komplett" , value: "used_incomplete" }
      ];

    this.getCollection();
  },
  mounted() {

  },
  unmounted() {

  },
  methods: {
    debounceSearch(searchValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchSets(searchValue);
      }, 500)
    },
    checkInput() {
      if (this.search_value.length > 0) {
        this.closeIcon = true;
        if (this.search_value.length > 2) {
          this.debounceSearch(this.inputValue);
          return;
        }
        return;
        }
      this.closeIcon = false;
      this.suggestionsActive = false;
      return;
    },
    clearInput() {
      this.search_value = '';
      this.closeIcon = false;
      this.suggestionsActive = false;
    },
    async getCollection() {
      this.loadingActive = true;
      const response = await this.api.items('collections?filter[user_created][_eq]=$CURRENT_USER&fields=*.*,set.name.*,set.theme.name,set.retail_price.*,set.header_image.*,set.images.directus_files_id.*,set.bricklink_sales.*&limit=-1').readByQuery();
      if (response) {
        this.loadingActive = false;
        this.collection = response.data;
        if (this.collection.length > 0) {
          this.setChartDataBuy();
          this.setChartDataValue();
        }
      }
    },
    async searchSets() {
      this.sets = [];
      this.minifigures = [];
      this.suggestionsActive = true;
      const { raw } = await this.api.transport.get('search?search=' + this.search_value + '&fields=*.*,images.directus_files_id.*');
      const setsResults = raw.sets;

      setsResults.forEach(set => {
        let result = {
          setnumber: set?.set ?? '-',
          name: set.name[0]?.name ?? '-',
          image: this.getSearchImage(set.images)
        };
        this.sets.push(result);
      });
    },
    getSetHeaderImage(header_image) {
      if (header_image) {
        if (header_image.id) { return process.env.VUE_APP_API_IMG_PATH + header_image?.id + '?height=300' ?? ''; }
      }
      return '';
    },
    getSetFallbackImage(images) {
      if (images) {
        if (images[1]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[1]?.directus_files_id.id + '?height=300' ?? ''; }
        if (images[0]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[0]?.directus_files_id.id + '?height=300' ?? ''; }
      }
      return '';
    },
    getSearchImage(images) {
      if (images) {
        try {
          return process.env.VUE_APP_API_IMG_PATH + images
          .find(img => img.directus_files_id.filename_download
          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=100';
        }
        catch(err) {
          if (images[0]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[0]?.directus_files_id?.id + '?height=100' ?? ''; }
          if (images[1]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[1]?.directus_files_id?.id + '?height=100' ?? ''; }
          return '';
        }
      }
      return '';
    },
    activateSetDetails(currentSet) {
      this.$router.push({ path: '/usercollection/portfolio/' + currentSet.set.set });
    },
    openAddSetDialog() {
      if (this.addSetDialogActive === false) {
        this.addSetDialogActive = true;
      }
    },
    openImportCollectionDialog() {
      if (this.importCollectionDialogActive === false) {
        this.importCollectionDialogActive = true;
      }
    },
    openClearCollectionDialog() {
      if (this.clearCollectionDialogActive === false) {
        this.clearCollectionDialogActive = true;
      }
    },
    openThemeAllocationDialog() {
      if (this.themeAllocationDialogActive === false) {
        this.themeAllocationDialogActive = true;
      }
    },
    setToAdd(set) {
      this.search_result = set || '';
      this.search_result_set = set.setnumber || '';
      this.search_result_name = set.name || '';
      this.search_result_image = set.image || '';
      this.suggestionsActive = false;
      this.search_result_active = true;
    },
    removeSet() {
      this.search_value = '';
      this.closeIcon = false;
      this.search_result_set = '';
      this.search_result_name = '';
      this.search_result_image = '';
      this.search_result_active = false;
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    convertGermanDateString(germanDateString) {
      if (germanDateString) {
        let day = germanDateString.split('.')[0];
        let month = germanDateString.split('.')[1];
        let year = germanDateString.split('.')[2];

        if (day.length == 2 && month.length == 2 && year.length == 4) {
          let m = moment(germanDateString + '12:00', 'DD MM YYYY HH:mm Z', 'de');
          return m.toISOString();
        }
        else return '';
      }
      else return '';
    },
    setChartDataBuy() {
      let newChartDataBuy = [];
      let currentPaidPrice = 0;
      this.collection.forEach(collectionItem => {
        if (collectionItem.collection.length > 1) {
          collectionItem.collection.forEach(entry => {
            let newDataBuy = [
              new Date(entry.purchase_date || entry.date_created).valueOf(),
              entry.price_payed
            ];
            newChartDataBuy.push(newDataBuy);
          });
        }
        else {
          let newDataBuy = [
              new Date(collectionItem.collection[0].purchase_date || collectionItem.collection[0].date_created).valueOf(),
              collectionItem.collection[0].price_payed
            ];
            newChartDataBuy.push(newDataBuy);
        }
      });
      newChartDataBuy.sort();
      newChartDataBuy.forEach(element => {
        let newPrice = currentPaidPrice + element[1];
        let newDataBuy = [
          element[0], // date
          newPrice.toFixed(2) // sum price_paid
        ];
        currentPaidPrice = newPrice;
        this.chartDataBuy.push(newDataBuy);
      });
      let autoLastEntryToday = [
        Date.now(),
        currentPaidPrice.toFixed(2)
      ];
      this.chartDataBuy.push(autoLastEntryToday);
      this.firstChartEntryDatetime = newChartDataBuy[0][0];
    },
    setChartDataValue() {
      let newChartDataValue= [];
      let currentValue = 0.00;
      this.collection.forEach(collectionItem => {
        if (collectionItem.collection.length > 1) {
          collectionItem.collection.forEach(entry => {
            let currentSetValue = 0.00;
            if (!(entry.sales_date || entry.sales_price) && (entry.condition && (entry.condition.includes('new')))) { currentSetValue =  entry.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.avg_price ?? 0.00 }
            if (!(entry.sales_date || entry.sales_price) && (entry.condition && (entry.condition.includes('used')))) { currentSetValue =  entry.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.avg_price ?? 0.00 }
            let newDataValue = [
              new Date(entry.purchase_date || entry.date_created).valueOf(),
              currentSetValue.toFixed(2)
            ];
            newChartDataValue.push(newDataValue);
          });
        }
        else {
          let currentSetValue = 0.00;
          if (!(collectionItem.collection[0].sales_date || collectionItem.collection[0].sales_price) && (collectionItem.collection[0].condition && (collectionItem.collection[0].condition.includes('new')))) { currentSetValue =  collectionItem.collection[0].set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.avg_price ?? 0.00 }
          if (!(collectionItem.collection[0].sales_date || collectionItem.collection[0].sales_price) && (collectionItem.collection[0].condition && (collectionItem.collection[0].condition.includes('used')))) { currentSetValue =  collectionItem.collection[0].set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.avg_price ?? 0.00 }
          let newDataValue = [
              new Date(collectionItem.collection[0].purchase_date || collectionItem.collection[0].date_created).valueOf(),
              currentSetValue.toFixed(2)
            ];
          newChartDataValue.push(newDataValue);
        }
      });
      newChartDataValue.sort();
      newChartDataValue.forEach(element => {
        let newValue = currentValue + parseFloat(element[1]);
        let newDataValue = [
          element[0], // date
          newValue.toFixed(2) // sum value
        ];
        currentValue = newValue;
        this.chartDataValue.push(newDataValue);
      });
      let autoLastEntryToday = [
        Date.now(),
        currentValue.toFixed(2)
      ];
      this.chartDataValue.push(autoLastEntryToday);
      this.firstChartEntryDatetime = newChartDataValue[0][0];
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1200px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog-card {
  position: relative;
  width: 80%;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.dialog-body {
  margin: 1rem 0;
}

.dialog-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin: 0 auto;
}

.dialog-action {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 1rem;
  color: #5C5DD8;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%);
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}

.row-collection {
  position: relative;
  justify-content: center;
  z-index: 2;
}

.row-set-header {
  margin-bottom: 1.5rem;
}

.row-set-details {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 95%;
}

.row-side-informations {
  flex-wrap: nowrap;
  flex-direction: column;
}

.card {
  position: relative;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transition: 0.5s ease all;
  z-index: 3;
}

.card-header {
  width: 100%;
}

.card-body-collection {
  min-height: 900px;
  overflow: auto;
}

.card-body-collection.active-set-info {
  padding-left: 10.5rem;
}

.card-collection-header {
  width: 100%;
  height: 90px;
}

.card-collection,
.card-collection-performance,
.card-collection-details {
  width: 100%;
  padding-bottom: 15px;
}

.card-collection-details {
  margin-top: 1.5rem;
}

.collection-table {
  width: 100%;
  border-spacing: 0;
}

.card-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 44px;
}

.card-header-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 11.5rem;
}

th {
  text-align: start;
}

.active-set {
  color: #60ffe4;
  margin-left: 10.5rem;
  transition: 0.5s ease all;
}

.table-row:hover {
  background: rgba(0, 0, 0, 0.2);
}

.table-row {
  cursor: pointer;
}

.collection-header {
  font-size: 12px;
  font-weight: 700;
  color: #312659;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
  transition: 0.5s ease all;
}

.collection-data {
  font-size: 14px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.collection-data.checkbox {
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.collection-data.set {
  font-size: 12px;
  padding: 0.5rem 0;
  transition: 0.5s ease all;
}

.card_body_loader {
  display: flex;
  height: 850px;
  align-items: center;
}

.loader {
  width: 50%;
}

#td-amount {
  width: 40px;
  color: #60ffe4;
  font-size: 14px;
  font-weight: 700;
  text-shadow: 1px 1px 0px #0e545a;
}

#td-image {
  width: 90px;
}

#td-set {
  width: 80px;
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  overflow: hidden;
}

.image-container.small {
  width: 44px;
  height: 44px;
  margin: 0.3rem 0 0.3rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}

.image-container.medium {
  position: absolute;
  width: 120px;
  height: 120px;
  margin: 0.3rem 0 0.3rem 1.5rem;
  border-radius: 20px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  transform: translateY(-55px);
}

.image {
  height: 100%;
  transition: 0.5s ease transform;
}

.link {
  cursor: pointer;
}

.link:hover {
  color: #60ffe4;
}

.text-link {
  display: inline-block;
  font-size: 16px;
}

.label {
  color: #ffffff;
  font-size: 12px;
  padding: 0 1.5rem 0 0.5rem;
  transition: 0.5s ease all;
}

.label.value {
  color: #ffffff;
  padding: 0 0 0 0.5rem;
  font-weight: 700;
}

.performance-data {
  text-align: end;
}

.table-performance {
  margin-left: 1.3rem;
  margin-bottom: 1rem;
}

::-webkit-scrollbar-track {
  margin: 1.5rem;
}

.toggle-sold {
  margin: 0 1.3rem 0 0.5rem;
}

.hint {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.hidden-container {
  position: absolute;
}

.coming-soon {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin: 2rem 0
}

.header-text.blank {
  opacity: 0.2;
}

.label-container {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
}

.input-search-container {
  display: flex;
  align-content: center;
}

.search-icon {
  margin-top: 10px;
  transform: translateX(40px);
  z-index: 2;
}

.close-icon {
  width: 20px;
  margin-top: 10px;
  transform: translateX(-30px);
  z-index: 3;
}

.search-icon i,
.close-icon i {
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
}

.suggestions-container {
  position: absolute;
  transform: translateY(50px);
  z-index: 80;
}

.card-add-search {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border-radius: 25px;
  transition: 0.5s ease all;
}

.suggestion-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.suggestion-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.suggestion-item-search {
  display: flex;
  align-items: center;
}

.sets-suggestion-list:last-of-type,
.minifig-suggestion-list:last-of-type {
  padding-bottom: 1rem;
}

.item-label{
  display: flex;
  flex-direction: column;
}

.image-container-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 44px;
  height: 44px;
  margin: 0.3rem 0 0.3rem 1.5rem;
  border-radius: 10px;
}

.image {
  display: flex;
  margin: 0 auto;
  height: 100%;
  cursor: pointer;
  transition: 0.5s ease all;
}

.image-container-result {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 44px;
  height: 44px;
  border-radius: 10px;
}

.header-text.suggestion {
  color: #60ffe4;
  margin: 0.5rem 0;
  padding-left: 1.5rem;
}

.label {
  color: #ffffff;
  font-size: 12px;
  padding-left: 1rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: 0.5s ease all;
}

::-webkit-scrollbar-track {
  margin: 1.5rem;
}

.hint-card {
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-content: center;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.3) 100%);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.icon-hint {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  font-size: 42px;
  color: rgb(255, 255, 255);
}

.hint-text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.row-response-message {
  min-height: 2rem;
  padding: 0.75rem 0 1.5rem 2.2rem;
  transition: 0.5s ease all;
}

.response-message {
  font-size: 14px;
  color: #d5d9ff;
  transition: 0.5s ease all;
}

.icon_onsite_link {
  margin-left: 0.2rem;
}

#more_analysis_link {
  margin-top: 1rem;
}

#add_item_hint {
  margin-top: 3rem;
}

.label.value.balance_positive {
  color: #60ffe4;
}

.label.value.balance_negative {
  color: rgb(252, 179, 236);
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.2);
  text-shadow: none;
}

#chip_link {
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s ease all;
}

  #chip_link:hover {
    color: #60ffe4;
  }

.info_tooltip {
  text-shadow: none;
}

tbody >>> tr >>> .border_bottom {
  border-bottom: 1px solid white;
}

@media (min-width: 600px) {
  .input-search {
    font-size: 18px;
  }

}

@media (min-width: 1280px) {
  .input-field,
  .input-unit,
  .label,
  .collection-header,
  .collection-data.set {
    font-size: 14px;
  }

  .active-set {
    font-size: 20px;
  }
}

</style>
