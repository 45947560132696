<template>
  <!-- delete set dialog -->
  <transition name="fade">
    <DeleteCollectionEntryMobile v-if="deleteDialog" :setPortfolio="setPortfolio" :id="id" @close="deleteDialog = false" />
  </transition>

  <!-- add set to collection dialog -->
  <transition name="fade">
    <AddSetDialogMobile v-if="addSetDialogActive" :set_data="set_data" @close="addSetDialogActive = false" />
  </transition>

  <section>
    <section>
    <v-container class="pa-0">
      <v-row no-gutters class="row-search mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>

      <v-row no-gutters class="row-set-header">
        <transition name="fade-right">
          <div class="card card-portfolio">
            <div @click="pushRouteToSet(set_data?.set)" class="image-container">
              <img v-if="getSetOverviewImage" class="image" :src="getSetOverviewImage" loading="lazy">
            </div>
            <v-row no-gutters class="card-header-portfolio">
              <v-spacer></v-spacer>
              <div class="card-header-icons">
                <i @click="openAddSetDialog()" class="fal fa-plus icon plus"></i>
                <i @click="closePortfolioOverview()" class="fal fa-times icon close"></i>
              </div>
            </v-row>
            <v-row no-gutters>
              <router-link :to="{ path: '/items/sets/' + set_data.set }">
                <span class="header-text setnumber">{{ set_data?.set ?? '-' }}</span>
              </router-link>
            </v-row>
            <v-row no-gutters>
              <router-link class="mb-3" :to="{ path: '/items/sets/' + set_data.set }">
                <p class="header-text titel">{{ set_data?.name ?? '' }}</p>
              </router-link>
            </v-row>
          </div>
        </transition>
      </v-row>

      <v-row no-gutters class="row-portfolio">
        <transition name="fade-right">
          <div class="card">
            <div class="card-header">
              <span class="header-text header_mobile">Portfolio</span>
            </div>
            <div class="card-body portfolio">
              <table class="collection-table set">
                <thead>
                  <tr>
                    <th style="padding-left: 1.4rem;"><span class="collection-header">EK-Datum</span></th>
                    <th><span class="collection-header">EK-Preis</span></th>
                    <th><span class="collection-header">Zustand</span></th>
                    <th><span class="collection-header"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="td in setPortfolio" :key="td" class="table-row set">
                    <td style="padding-left: 1.4rem;" class="collection-data set" :class="{selected: rowSelected}" @click="activatePortfolioEntry(td)">{{ convertDateTime(td.purchase_date) }}</td>
                    <td class="collection-data set" @click="activatePortfolioEntry(td)">{{ td.price_payed }}</td>
                    <td class="collection-data set" @click="activatePortfolioEntry(td)">{{ convertCondition('toText', td.condition) }}</td>
                    <td class="collection-data set" @click="openDeleteDialog(td)"><i class="fal fa-trash-alt icon delete-set"></i></td>
                    <template v-if="td.sales_price || td.sales_date"><td class="collection-data set" @click="activatePortfolioEntry(td)"><i class="fal fa-shopping-cart icon-sold"></i></td></template>
                    <template v-else><td class="collection-data set" @click="activatePortfolioEntry(td)"><i class="fal fa-shopping-cart icon-not-sold"></i></td></template>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </transition>
      </v-row>

      <v-row no-gutters>
        <template v-if="!portfolioSetActive">
          <v-row no-gutters class="row-side-informations">
            <transition name="fade-right">
              <div class="card blank">
                <span class="hint">Klicke auf ein Eintrag in deinem Portfolio.</span>
              </div>
            </transition>
          </v-row>
        </template>
        <template v-if="portfolioSetActive">
          <v-row no-gutters class="row-side-informations">
            <transition name="fade-right">
              <div class="card card-purchase-informations">
                <div class="card-header">
                  <span class="header-text header_mobile">Einkauf</span>
                  <v-spacer></v-spacer>
                  <div class="card-header-icons">
                    <i @click="saveActiveSetChanges()" class="fal fa-save icon save"></i>
                  </div>
                </div>
                <div class="card-body">
                  <v-col no-gutters class="pb-2 px-6">
                    <v-text-field v-model="purchase_date" clearable class="input_field" color="#60ffe4" label="Kaufdatum" type="date" density="compact"></v-text-field>
                    <v-combobox v-model="retailer" :items="retailerSuggestionList" clearable hide-details class="mb-3 input_field mobile" color="#60ffe4" append-inner-icon="mdi-storefront-outline" density="compact" label="Händler" placeholder="LEGO" theme="dark"></v-combobox>
                    <v-text-field v-model="price_payed" clearable class="input_field" color="#60ffe4" label="Kaufpreis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                    <v-text-field v-model.number="discountPercent" readonly class="input_field discount" color="#60ffe4" label="Rabatt" append-inner-icon="mdi-percent-outline" type="number" placeholder="0,00" density="compact"></v-text-field>
                    <v-select v-model="condition" :items="conditions" hide-details class="mb-3 input_field" color="#60ffe4" label="Zustand" append-inner-icon="mdi-gift-open" density="compact" theme="dark"></v-select>
                    <v-text-field v-model="storage" class="input_field" color="#60ffe4" label="Lagerplatz" append-inner-icon="mdi-library-shelves" type="text" placeholder="Regal X, Fach Y" density="compact"></v-text-field>
                  </v-col>

                  <v-row v-if="false" no-gutters class="pb-4 pl-5">
                    <!-- KOMMT NACH DEM LAUNCH -->
                    <v-col class="pr-2" cols="4">
                      <label class="label-input" for="">Wunsch VK-Preis</label>
                      <v-row no-gutters class="input-container">
                        <input v-model.number="wishSalesPrice" class="input-field" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00">
                        <span class="input-unit">{{ mapCurrencyUnit(localCurrency) }}</span>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </transition>
          </v-row>
        </template>
      </v-row>

      <v-row no-gutters>
        <template v-if="!portfolioSetActive">
          <v-row no-gutters class="row-side-informations">
            <transition name="fade-right">
              <div class="card blank">
                <span class="hint">Klicke auf ein Eintrag in deinem Portfolio.</span>
              </div>
            </transition>
          </v-row>
        </template>
        <template v-if="portfolioSetActive">
          <v-row no-gutters class="row-side-informations">
            <transition name="fade-right">
              <div class="card card-sales-informations">
                <div class="card-header">
                  <span class="header-text header_mobile">Verkauf</span>
                  <v-spacer></v-spacer>
                  <span class="label-input">Set verkauft?</span>
                  <Toggle v-model="sold" class="toggle-sold"/>
                  <div class="card-header-icons">
                    <i @click="saveActiveSetChanges()" class="fal fa-save icon save"></i>
                  </div>
                </div>
                <div class="card-body">
                  <v-col no-gutters class="pb-2 px-6">
                    <v-text-field v-model="sales_date" clearable class="input_field" color="#60ffe4" label="Verkaufsdatum" type="date" density="compact"></v-text-field>
                    <v-text-field v-model.number="sales_price" clearable class="input_field" color="#60ffe4" label="Verkaufspreis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                    <v-text-field v-model.number="fees" clearable class="input_field" color="#60ffe4" label="Gebühren" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                    <v-text-field v-model.number="shipping" clearable class="input_field" color="#60ffe4" label="Versand" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                    <v-text-field v-model.number="earnings" clearable class="input_field" color="#60ffe4" label="Erlös" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                    <v-text-field v-model.number="result" clearable class="input_field" color="#60ffe4" label="Ergebnis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                    <v-text-field v-model.number="roi" readonly class="input_field discount" color="#60ffe4" label="ROI" append-inner-icon="mdi-percent-outline" type="number" placeholder="0,00" density="compact"></v-text-field>
                  </v-col>
                </div>
              </div>
            </transition>
          </v-row>
        </template>
        <template v-if="portfolioSetActive">
          <v-row no-gutters class="row-side-informations">
            <transition name="fade-right">
              <div class="card card-sales-informations">
                <div class="card-header">
                  <span class="mr-1 header-text header_mobile">Performance</span>
                  <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content='Die Performance wird basierend auf dem aktuellen Marktpreis und deinem Einkaufspreis berechnet. Hierbei wird der angegebene Zustand herangezogen. Ist der Zustand nicht angegeben, wird die Performance nicht berechet. Bitte stell den Zustand ein.
                                                                                                      Die Daten aus einem Verkauf werden hier nicht berücksichtigt! Diese Berechnungen findest du in dem Bereich "VERKAUF".'>
                    <i class="fas fa-question-circle info_tooltip"></i>
                  </Popper>
                  <v-spacer></v-spacer>
                </div>
                <div class="card-body">
                  <v-row no-gutters class="pb-4 pl-5">
                    <tbody>
                      <tr>
                        <td v-if="this.condition?.includes('Neu') ?? ''"><span class="label">Aktueller Wert (neu)</span></td>
                        <td v-else-if="this.condition?.includes('Gebraucht') ?? ''"><span class="label">Aktueller Wert (gebr.)</span></td>
                        <td v-else><span class="label">Aktueller Wert</span></td>
                        <td><span class="label value">{{ getBricklinkSales() || '-' }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                      </tr>
                      <tr>
                        <td><span class="label">Performance</span></td>
                        <template v-if="diffPerformance >= 0.00"><td><span class="label value balance_positive">{{ diffPerformance }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                        <template v-else><td><span class="label value balance_negative">{{ diffPerformance }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                      </tr>
                      <tr>
                        <td><span class="label">Performance in %</span></td>
                        <template v-if="diffPerformancePercent == 'Infinity'"><td><span class="label value balance_positive">Awesome ;)</span></td></template>
                        <template v-else-if="diffPerformancePercent >= 0.00"><td><span class="label value balance_positive">{{ diffPerformancePercent }} %</span></td></template>
                        <template v-else><td><span class="label value">{{ diffPerformancePercent }} <span v-show="diffPerformancePercent != '-'">%</span></span></td></template>
                      </tr>
                    </tbody>
                  </v-row>
                </div>
              </div>
            </transition>
          </v-row>
        </template>
      </v-row>

    </v-container>
  </section>

  </section>
</template>

<script>
import SearchMobile from '../../components/SearchMobile.vue';
import Toggle from '../../components/Toggle.vue';
import AddSetDialogMobile from '../../components/Dialogs/AddSetDialogMobile.vue';
import DeleteCollectionEntryMobile from '../../components/Dialogs/DeleteCollectionEntryMobile.vue';
import moment from 'moment/min/moment-with-locales';

export default {
  name: 'Collection',
  components: {
    SearchMobile,
    Toggle,
    AddSetDialogMobile,
    DeleteCollectionEntryMobile
  },
  inject: [
    'api',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      portfolioSetActive: false,
      addSetDialogActive: false,
      deleteDialog: false,
      rowSelected: false,
      responseMessage: '',

      setPortfolio: [],
      activeSet: {},

      set_data: {
        eol_date: '',
        header_image: '',
        images: '',
        name: '',
        release_date: '',
        retail_price: '',
        set: '',
        theme: '',
      },

      lifecycle: '',
      retailerSuggestionList: ['Alternate','Alza','Amazon','DisneyShop','Ebay','Galaxus','Galeria','JB Spielwaren','Kaufland','Kleinanzeigen','LEGO','Lucky Bricks','Media Markt','Müller','Otto','Proshop','Saturn','Spielemax','Taschengelddieb','Thalia','Toys For Fun','myToys'],

      condition: null,
      discountPercent: '',
      earnings: '',
      fees: '',
      id: '',
      price_payed: '',
      purchase_date: '',
      result: '',
      retailer: '',
      roi: '',
      sales_date: '',
      sales_price: '',
      shipping: '',
      sold: false,
      storage: '',
      wishSalesPrice: '',

      menu: false,
    }
  },
  watch: {
    price_payed() {
      this.discountPercent = this.calcDiscount();
      if (this.sold) { this.result = (parseFloat(this.earnings - this.price_payed)).toFixed(2) ?? null; }
    },
    sold() {
      if (!this.sold) { this.clearSalesData(); }
    },
    sales_price() { if (this.sold) { this.earnings = (parseFloat(this.sales_price - this.fees - this.shipping)).toFixed(2) ?? null; } },
    fees() { if (this.sold) { this.earnings = (parseFloat(this.sales_price - this.fees - this.shipping)).toFixed(2) ?? null; } },
    shipping() { if (this.sold) { this.earnings = (parseFloat(this.sales_price - this.fees - this.shipping)).toFixed(2) ?? null; } },
    earnings() { if (this.sold) { this.result = parseFloat(this.earnings - this.price_payed).toFixed(2) ?? null; } },
    result() {
      if (this.sold) {
        this.roi = (parseFloat((this.result / this.price_payed) * 100)).toFixed(2) ?? null;
      }
    }
  },
  computed: {
    diffPerformance() {
      const currentSetValue = this.getBricklinkSales()
      return (currentSetValue - this.price_payed).toFixed(2) || 0.00;
    },
    diffPerformancePercent() {
      let result = 0.00;
      const currentSetValue = this.getBricklinkSales()
      let diff = (currentSetValue - this.price_payed).toFixed(2);
      let calc = ((diff / this.price_payed) * 100).toFixed(2);
      if (isNaN(calc)) {
        return result.toFixed(2);
      }
      if (currentSetValue == 0.00 || currentSetValue == '') { return '-'; }
      return calc;
    },
  },
  created() {
    this.conditions = [
      { title: "Neu, versiegelt" , value: "new_sealed" },
      { title: "Neu, leichte Schäden" , value: "new_minor_damages" },
      { title: "Neu, starke Schäden" , value: "new_major_damages" },
      { title: "Gebraucht, Tüten verschlossen" , value: "used_bags_closed" },
      { title: "Gebraucht, gebaut, nicht bespielt" , value: "used_build_not_played" },
      { title: "Gebraucht, bespielt" , value: "used_played" },
      { title: "Gebraucht, Karton fehlt" , value: "used_box_missing" },
      { title: "Gebraucht, nicht komplett" , value: "used_incomplete" }
      ];

    this.getCollection();
  },
  methods: {
    async getCollection() {
      const response = await this.api.items(
        'collections?filter[user_created][_eq]=$CURRENT_USER&fields=*.*,set.name.*,set.theme.name,set.retail_price.*,set.header_image.*,set.images.directus_files_id.*,set.bricklink_sales.*&limit=-1&filter[set][_eq]='
        + this.$route.params.setnumber).readByQuery();
      if (response) {
        this.collectionSetItem = response.data[0];
        if (this.collectionSetItem) {
          this.set_data.set = this.collectionSetItem?.set?.set ?? '';
          this.set_data.name = this.mapNameToLocal(this.collectionSetItem?.set?.name, this.localLanguage) ?? '';
          this.set_data.theme = this.collectionSetItem?.set?.theme?.name ?? '';
          if (this.collectionSetItem?.set?.release_date) { this.set_data.release_date = new Date(this.collectionSetItem?.set?.release_date ) ?? ''; }
          else { this.set_data.release_date = '-'}
          this.set_data.retail_price = this.mapRetailPriceToLocal(this.collectionSetItem?.set?.retail_price, this.localCurrency);
          if (this.collectionSetItem?.set?.eol_date) { this.set_data.eol_date = new Date(this.collectionSetItem?.set?.eol_date) ?? '-'; }
          else { this.set_data.eol_date = '-'}
          this.lifecycle = this.returnLifecycle(this.set_data.release_date, this.set_data.eol_date) || '';
          this.set_data.image = this.getSetOverviewImage(this.collectionSetItem?.set?.header_image, this.collectionSetItem?.set?.images) ?? '';
          this.setPortfolio = this.collectionSetItem?.collection ?? '';
          this.set_data.bricklink_sales = this.collectionSetItem?.set?.bricklink_sales ?? '';
        }
      }
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=300';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=300';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=300' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=300';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=300' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    activatePortfolioEntry(currentEntry) {
      const tempConditions = [
        { title: "Neu, versiegelt" , value: "new_sealed" },
        { title: "Neu, leichte Schäden" , value: "new_minor_damages" },
        { title: "Neu, starke Schäden" , value: "new_major_damages" },
        { title: "Gebraucht, Tüten verschlossen" , value: "used_bags_closed" },
        { title: "Gebraucht, gebaut, nicht bespielt" , value: "used_build_not_played" },
        { title: "Gebraucht, bespielt" , value: "used_played" },
        { title: "Gebraucht, Karton fehlt" , value: "used_box_missing" },
        { title: "Gebraucht, nicht komplett" , value: "used_incomplete" }
      ];

      this.id = currentEntry.id;
      this.condition = tempConditions.find(el => el.value === currentEntry.condition)?.value ?? null;
      if (currentEntry.discount) { this.discountPercent = currentEntry?.discount?.toFixed(2) ?? ''; }
      else { this.discountPercent = this.calcDiscount(); }
      this.earnings = currentEntry?.earnings?.toFixed(2) ?? '';
      this.fees = currentEntry.fees?.toFixed(2) ?? '';
      this.price_payed = currentEntry?.price_payed?.toFixed(2) ?? '';
      this.purchase_date = currentEntry?.purchase_date?.substring(0, 10) ?? '';
      this.result = currentEntry?.result?.toFixed(2) ?? '';
      this.retailer = currentEntry.retailer;
      this.roi = currentEntry?.roi?.toFixed(2) ?? '';
      this.sales_date = currentEntry?.sales_date?.substring(0, 10) ?? '';
      this.sales_price = currentEntry?.sales_price?.toFixed(2) ?? '';
      this.shipping = currentEntry?.shipping?.toFixed(2) ?? '';
      if (currentEntry.sales_price || currentEntry.sales_date) {
        this.sold = true;
      }
      else this.sold = false;
      this.storage = currentEntry.storage;
      this.portfolioSetActive = true;
    },
    async deleteCopy() {
      await this.api.items('collections').deleteOne(this.id);
      if (this.setPortfolio.length == 1) { this.$router.push({ path: '/usercollection/'}); }
      else { this.$router.go(); }
    },
    async saveActiveSetChanges() {
      if (this.portfolioSetActive) {
        let discount = null;
        if (this.discountPercent && this.discountPercent !== 0) { discount = parseFloat(this.discountPercent)?.toFixed(2) ?? null }
        let earnings = null;
        if (this.earnings && this.earnings !== 0) { earnings = parseFloat(this.earnings)?.toFixed(2) ?? null }
        let fees = null;
        if (this.fees && this.fees !== 0) { fees = parseFloat(this.fees)?.toFixed(2) ?? null }
        let price_payed = null;
        if (this.price_payed && this.price_payed !== 0) { price_payed = parseFloat(this.price_payed)?.toFixed(2) ?? null }
        let result = null;
        if (this.result && this.result !== 0) { result = parseFloat(this.result)?.toFixed(2) ?? null }
        let roi = null;
        if (this.roi && this.roi !== 0) { roi = parseFloat(this.roi)?.toFixed(2) ?? null }
        let sales_price = null;
        if (this.sales_price && this.sales_price !== 0) { sales_price = parseFloat(this.sales_price)?.toFixed(2) ?? null }
        let shipping = null;
        if (this.shipping && this.shipping !== 0) { shipping = parseFloat(this.shipping)?.toFixed(2) ?? null }

        let data = {
          condition: this.condition,
          discount: discount,
          earnings: earnings,
          fees: fees,
          price_payed: price_payed,
          purchase_date: this.convertGermanDateString(this.purchase_date),
          result: result,
          retailer: this.retailer || null,
          roi: roi,
          sales_date: this.convertGermanDateString(this.sales_date),
          sales_price: sales_price,
          shipping: shipping,
          storage: this.storage || null
        }
        await this.api.items('collections').updateOne(this.id, data);
        this.$router.go();
      }
    },
    closePortfolioOverview() {
      this.portfolioSetActive = false;
      this.$router.push({ path: '/usercollection/'});
    },
    clearSalesData() {
      this.sold = false;
      this.sales_date = '';
      this.sales_price = '';
      this.fees = '';
      this.shipping = '';
      this.earnings = '';
      this.result = '';
      this.roi = '';
    },
    openAddSetDialog() {
      if (this.addSetDialogActive === false) {
        this.addSetDialogActive = true;
      }
    },
    openDeleteDialog(currentEntry) {
      if (this.deleteDialog === false) {
        this.deleteDialog = true;
        this.id = currentEntry.id;
      }
    },
    returnLifecycle(release, eol) {
      if (release == '-' && eol == '-') return '-';
      else if (release && eol !== '-') return release + ' - ' + eol;
      else if (release && eol === null) return release + ' - ';
      else if (release && eol === '-') return release + ' - ';
      return '-';
    },
    convertCondition(direction, conditionValue) {
      try {
        if (conditionValue) {
          if (direction === 'toText') return this.conditions.find(el => el.value === conditionValue).title;
          if (direction === 'toValue') return this.conditions.find(el => el.title === conditionValue).value;
        }
        else {
          return null;
        }
        return null;
      }
      catch { return null; }
    },
    convertDateTime(dateValue) {
      if (dateValue) { return (new Date(dateValue))?.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}) ?? ''; }
      else { return ''; }
    },
    calcDiscount() {
      if (this.set_data.retail_price && this.set_data.retail_price !== '-' && this.set_data.retail_price !== '0.00' && this.price_payed) {
        try {
          return parseFloat(((1-(this.price_payed / this.set_data.retail_price))*100).toFixed(2));
        }
        catch { return null }
      }
      else return null;
    },
    pushRouteToSet(set) {
      this.$router.push({ path: '/items/sets/' + set });
    },
    toTimestamp(stringDate) {
      var date = Date.parse(stringDate);
      return date / 1000;
    },
    convertGermanDateString(dateString) {
      if (dateString) {
        let day = dateString.split('-')[2];
        let month = dateString.split('-')[1];
        let year = dateString.split('-')[0];

        if (day.length == 2 && month.length == 2 && year.length == 4) {
          let m = moment(day + '.' + month + '.' + year + '12:00', 'DD MM YYYY HH:mm Z', 'de');
          return m.toISOString();
        }
        else return null;
      }
      else return null;
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    getBricklinkSales() {
      let result = 0.00;
      if (this.condition?.includes('new') ?? '') {
        result = this.set_data.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.avg_price ?? 0.00;
        return result.toFixed(2);
      }
      if (this.condition?.includes('used') ?? '') {
        result = this.set_data.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.avg_price ?? 0.00;
        return result.toFixed(2);
      }
      return result;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  min-height: 1200px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.row-search.mobile {
  position: relative;
  width: 100%;
  padding-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.row-set-header,
.row-portfolio,
.row-side-informations {
  margin-bottom: 1rem;
}

.card {
  position: relative;
  width: 100%;
  margin: 0 1rem;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transition: 0.5s ease all;
  z-index: 3;
}

.card.blank {
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.2) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.5s ease all;
}

.card-header,
.card-header-icons {
  display: flex;
  height: 44px;
  align-items: center;
  z-index: 3;
}

.setnumber,
.titel {
  position: relative;
  font-size: 22px;
  line-height: 1;
  text-transform: none;
  z-index: 3;
}

.card-body.portfolio {
  height: 250px;
  overflow: auto;
}

.card-body.active-set-info {
  margin-bottom: 1rem;
}

.card-portfolio {
  min-height: 100px
}

.image-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  opacity: 0.3;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.image {
  height: 100%;
  transform: scale(3);
  transition: 0.5s ease transform;
}

.collection-table {
  width: 100%;
  border-spacing: 0;
}

th {
  text-align: start;
}

.icon-not-sold {
  margin-right: 1rem;
  color: rgb(82 95 191);
  font-weight: 500;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
}

.icon-sold {
  margin-right: 1rem;
  color: #60ffe4;
  font-weight: 500;
}

.collection-header {
  font-size: 12px;
  font-weight: 700;
  color: #312659;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
  transition: 0.5s ease all;
}

.collection-data {
  font-size: 14px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.collection-data.set {
  font-size: 12px;
  padding: 0.5rem 0;
  transition: 0.5s ease all;
}

.card.blank {
  height: 200px;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.2) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.5s ease all;
}

.hint {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.label {
  color: #ffffff;
  font-size: 12px;
  padding: 0 1.5rem 0 0.5rem;
  transition: 0.5s ease all;
}

.label.value {
  color: #ffffff;
  padding: 0 0 0 0.5rem;
  font-weight: 700;
}

.label.value.balance_positive {
  color: #60ffe4;
}

.label.value.balance_negative {
  color: rgb(252, 179, 236);
}

.toggle-sold {
  margin: 0 1rem 0 0.5rem;
}

.header_mobile {
  font-size: 14px;
}

.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none;
  }

.input_field {
  margin-bottom: -8px;
}

</style>
