<template>
  <svg id="clouds" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2616.32 604.12">
    <path d="M1613.34,19.72a17,17,0,0,1,4.43.59,19.72,19.72,0,0,1,21.65-11.76,19.74,19.74,0,0,1,34.46,3.55,19.72,19.72,0,0,1,25.67,18.81H1597.47A16.86,16.86,0,0,1,1613.34,19.72Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M402,377.67a114.55,114.55,0,0,0-20.66,1.87c-13.93-22.35-46.65-38-84.81-38a136.72,136.72,0,0,0-16.18,1c-16.56-16.33-44.35-27-75.78-27-38.2,0-70.95,15.72-84.86,38.11A133.69,133.69,0,0,0,92,350.78c-50.81,0-92,27.81-92,62.13H476C465.17,392.37,436.15,377.67,402,377.67Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M2565.32,236.91a54.35,54.35,0,0,0-14.24,1.9A63.36,63.36,0,0,0,2481.45,201a63.43,63.43,0,0,0-110.77,11.4,63.48,63.48,0,0,0-82.54,60.52h328.18A54.16,54.16,0,0,0,2565.32,236.91Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M1920.89,604.12c0-59.12,97.85-88,154.44-79.59C2094,463,2192.58,431,2248.53,462.8c19.44,11,34.62,26.46,43.74,44.43,64.81-16.86,133.88,12.89,154.28,66.43a85.53,85.53,0,0,1,5.68,30.46" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M647.29,175.84a122.46,122.46,0,0,0-19-5.88,60.5,60.5,0,0,0-30.09.86,73.83,73.83,0,0,0-27.65,14.87c-13,11.22-23.55,27.22-29.33,46.76a46.67,46.67,0,0,0-17-3.27c-28.14,0-52.08,25.76-61,61.73H738C733.56,274.68,711.81,201.69,647.29,175.84Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
  </svg>

  <section v-resize="onResize" :style="'height:' + windowHeight">
    <template v-if="loggedIn.value">
      <v-container class="logged-in-container">
        <v-row>
          <span class="header">Fertig für heute?</span>
        </v-row>
        <v-row>
          <PrimaryButton id="logout-button" @click="logout" buttonText="Abmelden" />
        </v-row>
        <v-row>
          <span class="login-message">{{ message }}</span>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <div class="card-container">
        <div class="card">
          <div class="dialog-body">
            <v-row>
              <span class="header">Willkommen zurück!</span>
            </v-row>
            <v-row>
              <v-col v-show="!smallWindow" v-resize="onResize" class="image-column">
                <div class="image-container">
                  <svg id="login" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 417.88 353.76">
                    <defs>
                      <clipPath id="a" transform="translate(-85.92 -289.96)">
                        <rect x="334.68" y="624.27" width="40.86" height="19.45" style="fill: none"/>
                      </clipPath>
                      <clipPath id="b" transform="translate(-85.92 -289.96)">
                        <rect x="429.56" y="607" width="39.35" height="19.32" style="fill: none"/>
                      </clipPath>
                      <clipPath id="c" transform="translate(-85.92 -289.96)">
                        <rect x="445.07" y="570.61" width="44.89" height="20.5" style="fill: none"/>
                      </clipPath>
                    </defs>
                    <g style="isolation: isolate">
                      <g>
                        <polygon points="303.02 308.79 266.71 319.62 307.57 328.24 303.02 308.79" style="opacity: 0.3"/>
                        <polygon points="84.31 334.06 48.01 344.89 88.87 353.51 84.31 334.06" style="opacity: 0.3"/>
                        <polygon points="36.3 312.93 0 323.76 44.89 333.43 36.3 312.93" style="mix-blend-mode: multiply;opacity: 0.3"/>
                        <g style="mix-blend-mode: multiply">
                          <g style="clip-path: url(#a)">
                            <polygon points="285.06 334.31 248.76 345.14 289.62 353.76 285.06 334.31" style="opacity: 0.3"/>
                          </g>
                        </g>
                        <polygon points="306.61 272.59 306.59 278.52 172.53 320.22 172.55 314.31 306.61 272.59" style="fill: #4c4186"/>
                        <polygon points="306.61 278.8 306.59 284.74 172.53 326.44 172.55 320.53 306.61 278.8" style="fill: #4c4186"/>
                        <g style="mix-blend-mode: multiply">
                          <g style="clip-path: url(#b)">
                            <polygon points="379.94 317.04 343.64 327.87 382.99 336.36 379.94 317.04" style="opacity: 0.3"/>
                          </g>
                        </g>
                        <g style="mix-blend-mode: multiply">
                          <g style="clip-path: url(#c)">
                            <polygon points="395.45 280.65 359.14 291.48 404.03 301.15 395.45 280.65" style="opacity: 0.3"/>
                          </g>
                        </g>
                        <polygon points="172.53 326.44 69.54 294.3 81.83 287.59 104.26 294.31 115.51 290.88 79.41 279.55 37.8 294.21 70.41 304.4 48.15 311.04 172.53 326.44" style="mix-blend-mode: multiply;opacity: 0.3"/>
                        <line x1="306.59" y1="278.61" x2="172.37" y2="320.32" style="fill: none;stroke: #615eb2;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                        <line x1="306.59" y1="272.47" x2="172.37" y2="314.19" style="fill: none;stroke: #615eb2;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                        <path d="M141.46,367.15a9.33,9.33,0,0,1,2.44.33A10.81,10.81,0,0,1,155.78,361a10.83,10.83,0,0,1,18.92,2,10.68,10.68,0,0,1,3.26-.5,10.82,10.82,0,0,1,10.83,10.82h-56a9.25,9.25,0,0,1,8.7-6.14" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <path d="M428.24,304a12,12,0,0,0-3.14.42,14,14,0,0,0-12.9-8.56,13.43,13.43,0,0,0-2.47.22,14,14,0,0,0-24.44,2.51,14,14,0,0,0-18.21,13.35h72.41A11.94,11.94,0,0,0,428.24,304" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <polygon points="220.29 133.1 215.56 131.66 215.56 96.7 220.29 98.15 220.29 133.1" style="fill: #9beada"/>
                        <polygon points="271.23 113.42 220.29 133.1 220.29 98.15 271.23 78.47 271.23 113.42" style="fill: #c2fff3"/>
                        <polygon points="271.23 78.47 220.29 98.15 215.56 96.66 266.51 76.98 271.23 78.47" style="fill: #bbf9ed"/>
                        <polygon points="343.65 259.13 338.93 257.68 338.93 222.73 343.65 224.17 343.65 259.13" style="fill: #d33d24"/>
                        <polygon points="394.6 239.44 343.65 259.13 343.65 224.17 394.6 204.49 394.6 239.44" style="fill: #f47458"/>
                        <polygon points="394.6 204.49 343.65 224.17 338.93 222.68 389.87 203 394.6 204.49" style="fill: #ef5c48"/>
                        <polygon points="163.89 246.94 165.63 251.93 119.43 238.06 108.25 230.02 163.89 246.94" style="fill: #4e72b3"/>
                        <polygon points="176.14 243.1 163.89 246.94 108.25 230.02 120.5 226.17 176.14 243.1" style="fill: #b7d1eb"/>
                        <path d="M214.64,520.6l.09-.11a.35.35,0,0,1,.05-.08.5.5,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19V517a.63.63,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1-.05.13l-.06.07a.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.28.13-.13.06-.49.17c-.19.06-.42.11-.63.16l-.48.13-.65.1c-.18,0-.35.07-.54.09l-.7.07-.56.05-.75,0h-.56l-.75,0h-.55L206,519l-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54V520c0,.56.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.26,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12s0,0,.05,0l.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M228.54,524.83l.09-.11a.35.35,0,0,1,.05-.08l.06-.12s0,0,0-.07a.63.63,0,0,0,0-.19V521.2a.58.58,0,0,1,0,.19s0,.05,0,.07l-.06.13-.05.07-.09.12-.08.07-.11.11a.91.91,0,0,1-.16.11l-.12.09-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56,0c-.25,0-.5,0-.75,0H222c-.26,0-.51,0-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28l.38.06.81.13.5,0c.26,0,.51.05.77.06s.37,0,.55,0l.76,0,.56,0c.24,0,.49,0,.74,0l.57,0,.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.18.14-.06.28-.12.13-.08a1.05,1.05,0,0,0,.21-.12.5.5,0,0,0,.12-.08l.16-.12.05,0,.06-.07.08-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <polygon points="119.43 238.06 125.8 239.98 55.6 265.5 55.59 260.15 119.43 238.06" style="fill: #8cb0da"/>
                        <path d="M226.74,519.67c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M242.46,529.08l.09-.12,0-.07.06-.13a.14.14,0,0,0,0-.07.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13,0,.07-.09.12-.08.08-.11.1-.16.12-.12.08-.2.12-.14.08-.28.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17s-.32.09-.49.12l-.65.11-.54.09c-.22,0-.46,0-.69.06l-.57.06-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.76-.07l-.51,0-.81-.14c-.13,0-.26,0-.38-.06A8.52,8.52,0,0,1,231,527c-1.36-.42-2.05-1-2.05-1.53v3c0,.56.69,1.12,2.05,1.54q.51.15,1.08.27l.38.06c.27,0,.53.1.81.13l.5,0,.77.07h.55l.76,0h.56c.25,0,.5,0,.74,0l.57-.05.69-.07.54-.08.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.14-.07.2-.12.13-.08.15-.12,0,0a.2.2,0,0,0,.06-.07l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M240.66,523.91c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <polygon points="176.14 243.16 176.11 255.44 163.86 259.22 163.89 246.94 176.14 243.16" style="fill: #a0bce1"/>
                        <path d="M256.36,533.31a.94.94,0,0,0,.09-.12l.05-.07a.75.75,0,0,0,.06-.13l0-.06a1.36,1.36,0,0,0,0-.2v-3.06a1.36,1.36,0,0,1,0,.2l0,.06a.52.52,0,0,1-.06.13.35.35,0,0,1-.05.08l-.09.11-.07.08a1.27,1.27,0,0,1-.12.1l-.15.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17s-.42.11-.63.17-.32.09-.49.12l-.65.11-.54.09-.69.07-.57,0-.74,0-.56,0-.75,0-.56,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.14c-.12,0-.26,0-.38-.06a8,8,0,0,1-1.07-.27c-1.37-.42-2.06-1-2.06-1.53v3.06c0,.55.69,1.11,2.06,1.53.33.1.69.19,1.07.27l.38.06c.27,0,.53.1.82.13l.5,0,.76.07H249c.25,0,.5,0,.75,0h.56c.25,0,.5,0,.74,0l.57-.05.7-.06.54-.09.64-.11.49-.13.54-.13.09,0c.17,0,.33-.11.48-.17l.14-.06.28-.13.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M254.56,528.14c2.7.83,2.74,2.2.09,3a19,19,0,0,1-9.71,0c-2.71-.83-2.75-2.19-.09-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M227.82,574.27a.44.44,0,0,0,.08-.11l0-.07A.52.52,0,0,0,228,574a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l0,.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06l.79.13.49,0,.74.07.54,0,.73,0,.54,0,.73,0,.55-.06.68-.06c.18,0,.34-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M226.07,569.25c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <line x1="119.62" y1="237.99" x2="166.63" y2="252.39" style="fill: none;stroke: #b7d1eb;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                        <path d="M241.32,578.38l.09-.11.05-.07a.5.5,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07a1.31,1.31,0,0,1-.27.12l-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13c-.12,0-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.53,0,.74,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M239.58,573.36c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="157.44 249.47 157.57 253.89 119.73 242.15 119.63 238.07 157.44 249.47" style="fill: #4e72b3"/>
                        <path d="M241.24,570.18l.09-.12,0-.07a.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12L240,568l-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.54-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49,0,.75.06l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M239.49,565.16c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M254.74,574.29l.09-.11a.35.35,0,0,0,0-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.35.35,0,0,1,0,.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0c-.24,0-.49,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0s-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.37-.05q-.56-.12-1.05-.27c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08c.21,0,.43-.06.63-.11s.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M253,569.27c2.62.81,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="88.47 270.31 88.48 275.66 56.11 265.61 56.13 260.61 88.47 270.31" style="fill: #4e72b3"/>
                        <path d="M199.09,577.46c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M212.6,581.57c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M214.26,578.38l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0-.72,0-.55,0-.73,0h-.53c-.26,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.11-.08.16-.11a.1.1,0,0,1,0,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M212.51,573.36c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M227.76,582.49a.31.31,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09l-.67.07-.55,0c-.24,0-.49,0-.73,0l-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.79-.13-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07.54,0h1.27c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08s.11-.07.15-.11l0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M226,577.47c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M281.9,557.6a.76.76,0,0,0,.08-.11l0-.07a1.39,1.39,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l0,.07a.76.76,0,0,1-.08.11l-.08.08-.1.09-.16.12a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1L278,556c-.22,0-.46,0-.68.06l-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1.05.27l.36.06.79.13.49,0,.75.07h.53l.73,0h.55l.72,0,.55-.05L278,559l.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12,0,0a.34.34,0,0,0,.06-.06l.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M280.15,552.58c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M295.4,561.71l.09-.11.05-.07a1.12,1.12,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0H289l-.73,0h-.54l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.17-1.05-.26-1.33-.41-2-.95-2-1.5v3c0,.54.66,1.09,2,1.49a10,10,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55-.05.68-.06L292,563l.62-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M293.66,556.69c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.8-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M295.32,553.5l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.09-.2.11-.14.07a1.31,1.31,0,0,1-.27.12l-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0-.8-.13c-.12,0-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.8.13.48,0,.75.07.53,0,.74,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06l.52-.08.63-.11.48-.12.52-.13.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M293.58,548.48c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M308.83,557.61a.44.44,0,0,0,.08-.11l0-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07l-.06.12,0,.07a.47.47,0,0,1-.09.12l-.07.07-.11.1a.58.58,0,0,1-.15.11l-.12.09-.2.11-.14.07a1.69,1.69,0,0,1-.27.13l-.13,0c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.68-.07.52-.08.63-.11.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.05.27-.13.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M307.08,552.59c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M254.87,566l.09-.11,0-.07a.38.38,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55,0c-.24,0-.48,0-.72,0l-.55,0-.73,0-.53,0-.75-.07-.49,0-.79-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1,.26l.36.06.8.13.48,0,.75.07h.53l.74,0H249l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17-.05.36-.08.52-.14l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M253.12,561c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M268.37,570.15a.48.48,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,1,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.54,0c-.24,0-.49,0-.73,0l-.54,0-.73,0-.54,0-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08s.11-.07.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M266.63,565.13c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M268.29,562a.94.94,0,0,0,.09-.12l.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26,0,.52.1.8.13l.48,0,.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0c.17-.05.32-.11.47-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M266.55,556.93c2.62.8,2.65,2.13.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M281.8,566.06l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.19.19,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.08.07-.11.1-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49,0,.74.06l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.14,0,.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M280.05,561c2.62.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M253.57,582.36l.08-.12,0-.07a.52.52,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l0,.07-.08.12-.08.07-.1.1a.91.91,0,0,1-.16.11l-.11.09-.21.11-.13.07-.27.13-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.75.07l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11.48-.12c.17,0,.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.13.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11l.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M251.82,577.33c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.6,18.6,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M267.07,586.46a.48.48,0,0,0,.09-.11l0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.11-.12.09-.2.11-.14.07a1.69,1.69,0,0,1-.27.13l-.13.05-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.25,0,.36.06.27,0,.53.1.8.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11.48-.12c.17,0,.36-.08.52-.13l.09,0c.17,0,.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.09.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M265.33,581.45c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M267,578.26l.09-.11.05-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.15s-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.08-.68.07-.55,0c-.24,0-.48,0-.72,0l-.54,0-.74,0-.53,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26,0,.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.52-.08c.22,0,.43-.06.63-.11s.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M265.25,573.24c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M280.5,582.37l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.53.09-.67.06-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0a4.87,4.87,0,0,1-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49,0c.25,0,.49.05.74.06s.36,0,.54,0l.73,0,.54,0c.25,0,.49,0,.73,0l.55-.05.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.07.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M278.75,577.35c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M224.84,585.54c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M238.35,589.65c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M240,586.46l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07a1.31,1.31,0,0,1-.27.12l-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0-.75-.07-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07.54,0h1.27l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11,0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M238.27,581.44c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M253.52,590.57a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07c-.09.05-.18.08-.27.12l-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09l-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13c-.12,0-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26l.37.06.79.13.49,0,.74.07.54,0,.73,0,.54,0,.73,0,.55-.06c.22,0,.46,0,.68-.06s.34-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11,0,0a.34.34,0,0,0,.06-.06l.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M251.77,585.55c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M307.65,565.68l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,0-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0H300l-.75-.07-.49,0L298,564l-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.14-.07.2-.12.11-.08.16-.12,0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M305.9,560.66c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M321.15,569.79l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.54,0c-.24,0-.49,0-.73,0l-.54,0c-.25,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.5.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07H314l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.14.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08L321,570l0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M319.41,564.77c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M321.07,561.59a.94.94,0,0,0,.09-.12l0-.07a.61.61,0,0,0,.05-.12l0-.07a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.11-.12.09-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06c.27,0,.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0c.17,0,.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M319.33,556.57c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M334.58,565.7l.08-.12,0-.07a.52.52,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.19.19,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.08.07-.1.1-.16.12-.12.08-.2.11-.13.08-.27.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.75.07l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.21-.11.11-.08.16-.12,0,0,.07-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M332.83,560.68c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M280.62,574.12l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17l-.6.16c-.16,0-.31.09-.48.12l-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0-.8-.13c-.12,0-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07.53,0,.74,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M278.87,569.1c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M294.13,578.23a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1a.83.83,0,0,1-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09l-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06.79.13.49,0,.74.07.54,0c.24,0,.49,0,.73,0h.54l.73,0,.55-.06c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12.12-.08a1,1,0,0,0,.15-.11l.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M292.38,573.21c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M294,570a.47.47,0,0,0,.09-.12l.05-.07.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.54,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.8-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06L294,570" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M292.3,565c2.62.81,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M307.55,574.14l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.37,1.37,0,0,1,0,.19l0,.06a.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.83.83,0,0,1-.08.12l-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M305.8,569.12c2.63.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M279.42,590.36l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.07.08-.11.1a.91.91,0,0,1-.16.11l-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.53,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.13l.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M277.67,585.34c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M291.18,589.45c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M292.84,586.27l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.27.05.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M291.1,581.25c2.62.81,2.65,2.13.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M304.6,585.36c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M250.69,593.55c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M264.2,597.66c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M265.86,594.47l.09-.11.05-.07a1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.49,0,.74.07h.54l.73,0H260c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M264.12,589.45c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M277.62,593.56c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M333.5,573.69l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a.63.63,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27c.11,0,.24,0,.36.06s.52.09.8.12l.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09.63-.1.48-.13.52-.13.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M331.75,568.67c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.8-2.66-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M347,577.8a.44.44,0,0,0,.09-.11.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07c-.16,0-.33,0-.49,0s-.53-.08-.79-.12L337,576q-.56-.12-1.05-.27c-1.32-.4-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09.63-.1.47-.13.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0a.2.2,0,0,0,.06-.07l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M345.26,572.78c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M346.92,569.59a.48.48,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,1,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.49,0,.74.07.54,0c.24,0,.48,0,.73,0h.54c.24,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M345.18,564.57c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M360.43,573.7a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.07.08-.11.1a.91.91,0,0,1-.16.11l-.11.08-.21.12-.13.07a1.59,1.59,0,0,1-.27.12l-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.79.13.49,0,.75.07.53,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11.1.1,0,0,1,.05,0,.34.34,0,0,1,.06-.06l.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M358.68,568.68c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M306.47,582.13l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M304.73,577.11c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M318.23,581.22c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M319.89,578a.47.47,0,0,0,.09-.12.18.18,0,0,0,0-.07l.06-.12s0-.05,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18s0,0,0,.07l-.06.12a.18.18,0,0,1,0,.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06a.46.46,0,0,0,.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M318.15,573c2.62.8,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M331.65,577.13c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="119.58 233.82 67.23 250.31 54.44 246.58 107.12 230.02 119.58 233.82" style="fill: #b7d1eb"/>
                        <polygon points="67.23 250.31 67.19 262.24 54.41 258.51 54.44 246.58 67.23 250.31" style="fill: #4e72b3"/>
                        <path d="M185.78,528.42a.44.44,0,0,0,.09-.11.35.35,0,0,0,0-.08l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13,0,.07a.44.44,0,0,1-.09.11l-.07.08-.11.09-.15.12-.12.08-.2.12L185,526l-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07c-.16,0-.33,0-.49,0s-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09.63-.1c.16,0,.31-.09.47-.12l.53-.14.08,0,.47-.17.13-.06L185,529l.13-.07.2-.12.12-.08.15-.12.05,0a.2.2,0,0,0,.06-.07l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M184,523.4c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M158.76,536.86a.76.76,0,0,0,.08-.11l0-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13,0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55,0c-.24,0-.48,0-.73,0l-.54,0-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.14.08,0c.17,0,.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.15-.11.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M157,531.84c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <polygon points="119.58 233.82 119.59 238.12 67.21 256.28 67.23 250.31 119.58 233.82" style="fill: #8cb0da"/>
                        <path d="M172.18,532.77l.09-.12,0-.07a.31.31,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0a2.24,2.24,0,0,0,.27-.13l.13-.07.21-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M170.43,527.75c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M199.26,523.94l.08-.11a.35.35,0,0,0,0-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1,0,.08l-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0s-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.36-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.05c.26,0,.52.1.79.13l.49,0c.25,0,.49,0,.74.06l.54,0,.73,0,.55,0c.24,0,.48,0,.72,0l.55,0,.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.07.08-.07" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M197.51,518.92c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <polygon points="96.71 267.38 97.24 272.57 41.05 255.46 41.06 250.46 96.71 267.38" style="fill: #4e72b3"/>
                        <polygon points="108.96 263.54 96.71 267.38 41.06 250.46 53.31 246.61 108.96 263.54" style="fill: #b7d1eb"/>
                        <path d="M147.46,541l.09-.11a.35.35,0,0,1,0-.08.5.5,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3.06a.58.58,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1-.05.13l0,.07-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.28.13-.13.06-.49.17c-.19.06-.42.11-.63.16l-.48.13-.65.11-.54.08-.7.07-.56,0-.75,0h-.56l-.75,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.26,0,.51.05.77.06l.55,0,.76,0,.55,0,.75,0,.56-.05.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.13-.06.28-.12.14-.08.21-.11.12-.09.16-.12a.1.1,0,0,0,0,0l.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M145.66,535.88c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3.05a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M161.36,545.27l.09-.11a.35.35,0,0,1,.05-.08l.06-.12s0-.05,0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13-.05.07-.09.12-.08.07a1.14,1.14,0,0,1-.11.11l-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06a4.65,4.65,0,0,1-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56,0-.75,0-.55,0c-.26,0-.51,0-.76,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.54q.51.15,1.08.27l.38.06.81.13.5,0c.26,0,.51,0,.77.06s.37,0,.55,0l.76,0,.56,0c.24,0,.49,0,.74,0l.57,0,.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.18.14-.06.28-.12.13-.08a1,1,0,0,0,.21-.11l.12-.09.16-.12.05,0,.06-.07.08-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M159.56,540.11c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M175.28,549.52l.09-.12,0-.07.06-.13a.14.14,0,0,0,0-.07.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13,0,.07-.09.12-.08.08-.11.1-.16.12a.36.36,0,0,1-.12.08l-.2.12-.14.08-.28.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17s-.32.09-.49.12l-.65.11-.54.09c-.22,0-.46,0-.69.06L170,548l-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.76-.07l-.51,0-.81-.14c-.13,0-.26,0-.38-.06a8.52,8.52,0,0,1-1.08-.27c-1.36-.42-2-1-2-1.53v3.05c0,.56.69,1.12,2,1.54q.51.15,1.08.27l.38.06c.27.05.53.1.82.13l.49,0,.77.07h.55l.76,0h.56c.25,0,.5,0,.74,0l.57,0,.69-.07.54-.08.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.14-.07.2-.12.13-.08.15-.12,0,0,.07-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M173.48,544.35c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <polygon points="108.96 263.61 108.93 275.88 96.68 279.66 96.71 267.38 108.96 263.61" style="fill: #a0bce1"/>
                        <path d="M189.18,553.75a.94.94,0,0,0,.09-.12l0-.07a.75.75,0,0,0,.06-.13l0-.06a1.36,1.36,0,0,0,0-.2v-3a1.23,1.23,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13.35.35,0,0,1,0,.08l-.09.11-.07.08a1.27,1.27,0,0,1-.12.1l-.15.12-.12.08-.21.12-.14.08-.28.12-.14.06-.48.18-.63.16c-.16,0-.32.09-.49.12l-.65.11c-.18,0-.35.07-.54.09l-.69.07-.57.05-.74,0-.56,0-.75,0-.56,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.13c-.12,0-.26,0-.38-.06a9.78,9.78,0,0,1-1.07-.28c-1.37-.42-2.06-1-2.06-1.53v3.06c0,.55.69,1.11,2.06,1.53.33.1.69.19,1.07.27.12,0,.26,0,.38.06s.53.1.82.13l.5.05.76.07.56,0,.75,0,.56,0c.25,0,.5,0,.74,0l.57,0,.7-.06.54-.09.64-.11.49-.12.54-.14.09,0c.17,0,.33-.11.48-.17l.14-.06.28-.13.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M187.38,548.58c2.7.83,2.74,2.2.09,3a18.91,18.91,0,0,1-9.71,0c-2.71-.83-2.75-2.19-.09-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M227.79,561.9l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11a.46.46,0,0,1-.07.07l-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.74,0h.54l.72,0,.55,0,.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M226,556.88c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M241.29,566l.09-.11a.35.35,0,0,0,.05-.08l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11a.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.47.11c-.21,0-.42.08-.63.11l-.53.09-.67.06-.55,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49.05c.24,0,.49,0,.74.06a4.87,4.87,0,0,0,.54,0l.73,0,.54,0c.25,0,.49,0,.73,0l.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0s0,0,.06-.07l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M239.55,561c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M241.21,557.8a.48.48,0,0,0,.09-.11l0-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07a.48.48,0,0,1-.09.11l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0l-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.68.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M239.47,552.78c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M254.72,561.91l.09-.11,0-.07a.38.38,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13l0,.07a.44.44,0,0,1-.08.11l-.07.08a.52.52,0,0,0-.11.1l-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.49,0-.79-.13-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.68.18,1,.26l.36.06.79.13.49,0,.75.07.53,0c.25,0,.49,0,.73,0h.55l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M253,556.89c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M200.81,570.1l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12a.46.46,0,0,0-.07.07l-.11.1-.15.11-.12.08-.2.12-.14.07a2.24,2.24,0,0,1-.27.13l-.13,0c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.24,0,.36.06l.8.13.48,0,.75.07.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07c.18,0,.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.13l.08,0c.17,0,.33-.11.48-.17l.13-.06a1.31,1.31,0,0,0,.27-.12l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M199.07,565.09c2.62.8,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M214.32,574.21a.44.44,0,0,0,.08-.11l0-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.06l-.06.13,0,.07a.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.07-.2.12-.14.07a1.11,1.11,0,0,1-.27.12.52.52,0,0,1-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06c.26,0,.52.1.79.13l.49,0,.74.07.54,0,.73,0,.54,0,.73,0,.55-.06.68-.06c.18,0,.34-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l.05,0a.34.34,0,0,0,.06-.06l.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M212.57,569.19c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M214.23,566a.47.47,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16a6.06,6.06,0,0,1-.61.16c-.16,0-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06q-.56-.12-1.05-.27c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M212.49,561c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M227.74,570.12l.09-.11a.35.35,0,0,1,0-.08.61.61,0,0,0,.05-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12l0,.08-.09.11-.07.07-.11.1-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.08-.68.07-.55,0c-.24,0-.48,0-.72,0l-.55,0-.73,0c-.18,0-.36,0-.53,0s-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08c.21,0,.43-.06.63-.11s.32-.08.48-.12.36-.08.52-.13l.08,0,.47-.16.14-.06.27-.12.14-.08.2-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M226,565.1c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M187.21,574.45a.76.76,0,0,0,.08-.11.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.44.44,0,0,1-.09.11l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07c-.16,0-.33,0-.49,0s-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05.68-.06.52-.09.63-.1.47-.13.53-.13.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.15-.12,0,0a.2.2,0,0,0,.06-.07l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M185.46,569.43c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M200.71,578.56l.09-.11a.35.35,0,0,1,0-.08.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.25,1.25,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17-.6.16-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1,.27c.12,0,.24,0,.36.06s.52.09.8.12l.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M199,573.54c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M281.87,545.23l.09-.12L282,545a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.24,0,.36.06s.52.1.8.13l.48,0,.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0,.47-.17.13-.05.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M280.13,540.21c2.62.81,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M295.38,549.34l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.25.25,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.26.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.87,9.87,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.05.27-.12.13-.08.2-.11.12-.08.15-.12.05,0,.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M293.63,544.32c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M295.29,541.13l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.11a6.17,6.17,0,0,1-.62.11l-.53.09c-.22,0-.45,0-.67.06l-.55,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07c-.16,0-.33,0-.49-.05s-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49,0,.74.07h.54l.73,0,.54,0c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09c.21,0,.42-.06.63-.11s.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0s0,0,.06-.07l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M293.55,536.11c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M308.8,545.24l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.07-.11.1-.16.12-.11.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0-.72,0h-.55l-.73,0h-.53l-.75-.07c-.16,0-.33,0-.48-.05s-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48,0,.75.07h.53l.74,0H303l.72,0,.55-.05.68-.06.52-.09.63-.1.48-.13c.17,0,.36-.08.52-.13l.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.11-.08.16-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M307.05,540.22c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M254.84,553.67l.09-.11,0-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.8-.13l-.36-.05q-.56-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36,0c.27.05.53.1.8.13l.48.05.75.06.54,0,.73,0,.54,0c.24,0,.49,0,.72,0l.55,0,.68-.07.53-.08c.21,0,.42-.06.62-.11s.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M253.1,548.65c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M268.35,557.78l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07-.1.1-.16.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.08-.68.07-.55,0c-.24,0-.48,0-.73,0l-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08c.21,0,.43-.06.63-.11s.31-.08.47-.12.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12,0,0,.07-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M266.6,552.76c2.63.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M268.27,549.57a.76.76,0,0,0,.08-.11l0-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13,0,.07a.44.44,0,0,1-.09.11l-.07.08-.11.1-.15.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.68.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.15-.12.05,0a.34.34,0,0,0,.06-.06l.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M266.52,544.55c2.62.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.8-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M281.77,553.68l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M280,548.66c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M253.54,570l.09-.11.05-.08a.61.61,0,0,0,0-.12l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0-.72,0h-.54l-.74,0h-.53l-.74-.07-.49,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27c.12,0,.25,0,.36.06.27,0,.52.09.8.12l.48,0,.75.07h.54l.73,0h.54l.72,0,.55-.05.68-.06.53-.09.62-.1.48-.13c.17,0,.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M251.8,565c2.62.81,2.65,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.8-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M267.05,574.09a.76.76,0,0,0,.08-.11.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.46,0-.68.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.36-.08-.72-.17-1-.27-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36.06c.26,0,.52.09.79.12l.49,0,.74.07h.54l.73,0h.55l.72,0,.55-.05.68-.06.52-.09.63-.1.47-.13.53-.13.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12,0,0a.2.2,0,0,0,.06-.07l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M265.3,569.07c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M267,565.88a.31.31,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09l-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.79-.13-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07.54,0c.24,0,.49,0,.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12.12-.08a1,1,0,0,0,.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M265.22,560.86c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M280.47,570l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1a.91.91,0,0,1-.16.11l-.11.08-.2.12-.14.07a1.31,1.31,0,0,1-.27.12l-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.53,0,.74,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.13l.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,0,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M278.72,565c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M226.56,578.19a.94.94,0,0,0,.09-.12l0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06c.27,0,.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11.48-.12.52-.13.09,0c.17-.05.32-.11.47-.17l.13,0a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.09.15-.11.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M224.82,573.17c2.62.8,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M240.07,582.3a.83.83,0,0,0,.08-.12l0-.07a.52.52,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.19.19,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.08.07-.1.1a.91.91,0,0,1-.16.11l-.12.09-.2.11-.13.08L239,580l-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.75.07l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.21-.12.11-.07.16-.12,0,0,.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M238.32,577.28c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M240,574.09l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.53.08-.67.07-.55.05c-.24,0-.48,0-.73,0l-.54,0-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.13l-.37,0c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.68-.07.52-.08c.21,0,.43-.06.63-.11s.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0,.34.34,0,0,0,.06-.06l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M238.24,569.07c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M253.49,578.2l.09-.11a.35.35,0,0,1,0-.08.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27c.12,0,.24,0,.36.06s.52.09.8.12l.48,0,.75.06c.18,0,.36,0,.53,0l.74,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M251.75,573.18c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M213,582.53a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.1.09-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.46,0-.68.06l-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06q-.56-.12-1-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0,.55,0,.68-.06.52-.09.63-.1.47-.13c.18,0,.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0,.07-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M211.21,577.51c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M226.46,586.64l.09-.11,0-.07a1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13l0,.07-.09.11a.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12L226,584l-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06q-.56-.12-1-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55,0,.68-.06.52-.09.63-.1.48-.13.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M224.72,581.62c2.62.81,2.65,2.14.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.8-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M307.62,553.31a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.12-.05.07a.94.94,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M305.88,548.29c2.62.81,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M321.13,557.42a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.33.33,0,0,1,0-.06,1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.08.07-.1.1-.16.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M319.38,552.4c2.63.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M321.05,549.21a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.09.11l-.07.07a.52.52,0,0,1-.11.1l-.15.12a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.46,0-.68.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.36-.08-.72-.17-1-.27-1.33-.4-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49,0,.74.07h.54l.73,0h.55l.72,0,.55-.05.68-.06.52-.09.63-.1.47-.13.53-.13.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.15-.12.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M319.3,544.19c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M334.55,553.32l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1.05.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09.63-.1.48-.13.52-.13.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M332.81,548.3c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M280.59,561.75l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11a.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.11a6.17,6.17,0,0,1-.62.11l-.53.09-.67.06-.55,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06q-.56-.12-1.05-.27c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37,0c.26,0,.52.1.79.13l.49,0c.24,0,.49.05.74.06s.36,0,.54,0l.73,0,.54,0c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09c.21,0,.42-.06.63-.11s.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M278.85,556.73c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M294.1,565.86l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12l0,.08-.09.11L294,563l-.11.1-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0l-.55,0-.73,0a4.61,4.61,0,0,1-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.05c.26,0,.52.1.8.13l.48,0c.25,0,.49.05.75.06s.35,0,.53,0l.74,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.47-.17.14-.06.27-.12.14-.08.2-.11.11-.08.16-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M292.35,560.84c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M294,557.65a.76.76,0,0,0,.08-.11l0-.07a.75.75,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l0,.07a.44.44,0,0,1-.08.11.6.6,0,0,1-.08.08l-.1.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.46,0-.68.06l-.55,0c-.24,0-.48,0-.72,0l-.55,0-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.79.13.49,0,.75.07h.53l.73,0h.55l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M292.27,552.63c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M307.52,561.76l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54c-.25,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55,0c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.14.09,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M305.78,556.74c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M279.39,578l.09-.11,0-.08.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13l0,.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.11a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.23,0-.48,0-.72,0H273l-.73,0h-.54l-.74-.07-.49-.05c-.28,0-.53-.08-.79-.12l-.37-.06q-.55-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27c.12,0,.25,0,.37.06s.52.09.79.12l.49,0c.24,0,.49.05.74.06s.36,0,.54,0l.73,0,.54,0c.24,0,.49,0,.72,0l.56-.05.67-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M277.65,573c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M292.9,582.1,293,582a.35.35,0,0,0,0-.08.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1,0,.08l-.08.11-.07.07-.11.1-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0l-.55,0-.73,0c-.18,0-.36,0-.53,0s-.5,0-.75-.06l-.49,0c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1.05.27l.36.05c.26,0,.52.1.79.13l.49,0c.25,0,.49.05.75.06l.53,0,.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.29,4.29,0,0,0,.47-.16l.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12,0,0a.2.2,0,0,1,.06-.07l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M291.15,577.08c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M292.82,573.89a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55,0c-.24,0-.48,0-.73,0h-.54c-.24,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.68.18,1,.26l.36.06.79.13.49,0,.74.07h.54l.73,0H287l.72,0,.55,0c.22,0,.46,0,.68-.06s.34-.06.52-.09l.63-.1.47-.12.53-.14.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11s0,0,0,0l.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M291.07,568.87c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M306.32,578l.09-.11,0-.07a1.12,1.12,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0-.72,0-.54,0c-.25,0-.49,0-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1,.26l.36.06.8.13.48,0,.75.07h.54l.73,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M304.58,573c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M252.41,586.19a.48.48,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07a1.69,1.69,0,0,1-.27.12l-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09l-.68.07-.54.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.79-.13c-.12,0-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06.79.13.49,0,.74.07.54,0,.73,0,.54,0,.73,0,.55-.06c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M250.67,581.17c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M265.92,590.3l.09-.11,0-.07a.38.38,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08a.52.52,0,0,0-.11.1.91.91,0,0,1-.16.11l-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.49,0-.79-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.53,0c.25,0,.49,0,.73,0h.55l.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M264.17,585.28c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M265.84,582.1l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.08.07-.1.1-.16.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.37,0c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.05.75.06l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0a.34.34,0,0,0,.06-.06l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M264.09,577.08c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M279.34,586.21l.09-.11.05-.08a.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12.35.35,0,0,0-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16a5.25,5.25,0,0,1-.6.16c-.16,0-.31.09-.48.12s-.42.07-.63.11l-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0-.75-.06-.48,0c-.28,0-.54-.08-.8-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26,0,.52.1.8.13l.48,0,.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0,.47-.17.13-.05.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M277.6,581.19c2.62.81,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M238.81,590.54l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.07.07-.11.1-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16s-.3.08-.47.12l-.63.1-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0h-.55l-.73,0h-.53l-.75-.07-.49,0c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.06c.26,0,.52.09.79.12l.49.05.75.07h.53l.73,0H233l.72,0,.55,0,.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.29,4.29,0,0,0,.47-.16l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0a.2.2,0,0,1,.06-.07l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M237.06,585.52c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M252.31,594.65l.09-.11,0-.08.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13l0,.07-.09.11a.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1L252,592l-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.11a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.28,0-.53-.08-.8-.12l-.36-.06q-.56-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27c.12,0,.25,0,.36.06.27,0,.53.09.8.12l.49.05c.24,0,.49,0,.74.06s.36,0,.54,0l.73,0,.54,0c.24,0,.49,0,.72,0l.56,0,.67-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M250.57,589.63c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M333.47,561.32a.47.47,0,0,0,.09-.12l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18s0,0,0,.07l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.62.11-.53.08-.68.07-.54.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13,0a1.69,1.69,0,0,0,.27-.13.61.61,0,0,0,.14-.07l.2-.11.12-.08.15-.12,0,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M331.73,556.3c2.62.8,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M347,565.43l.09-.12.05-.07a.5.5,0,0,0,.05-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12l0,.07-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.11.11-.09a.91.91,0,0,0,.16-.11l.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M345.23,560.41c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M346.9,557.22l.08-.11a.35.35,0,0,0,0-.08.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1,0,.08l-.08.11-.08.07-.1.1-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0s-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1.05.27l.36.05c.26,0,.52.1.79.13l.49,0c.25,0,.49.05.75.06l.53,0,.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.29,4.29,0,0,0,.47-.16l.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12,0,0,.07-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M345.15,552.2c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M360.4,561.33l.09-.11.05-.08a.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13l-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.11a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.23,0-.48,0-.72,0H354l-.73,0h-.54l-.74-.07-.49,0c-.28,0-.53-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1.05.27c.12,0,.25,0,.36.06.27,0,.53.09.8.12l.48,0,.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55-.05.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M358.66,556.31c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M306.45,569.76l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16a5.43,5.43,0,0,1-.61.16c-.16,0-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.14,0,.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M304.7,564.74c2.62.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M320,573.87l.09-.12,0-.07a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0,0,.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16a5.25,5.25,0,0,1-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13l-.36,0q-.56-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.24,0,.36.06s.52.1.8.13l.48,0,.75.06.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M318.2,568.85c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M319.87,565.66l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.55l-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06.8.13.48,0,.75.07h.53l.74,0H314l.72,0,.55,0L316,567l.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.14-.07.2-.12.11-.08.16-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M318.12,560.64c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M333.37,569.77a.44.44,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0H327l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06q-.56-.12-1.05-.27c-1.32-.4-2-.94-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09.63-.1.47-.13.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M331.63,564.75c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.8-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M305.25,586.21l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.08,1.08,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17l-.6.16-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.54,0c-.25,0-.49,0-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.53,0h1.28l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.13l.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M303.5,581.19c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M318.67,582.12a.94.94,0,0,0,.09-.12l.05-.07.06-.12a.25.25,0,0,1,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.28,0-.53-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M316.93,577.1c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M278.27,594.42l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12.35.35,0,0,0-.05.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.16a5.25,5.25,0,0,1-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05c-.24,0-.48,0-.72,0l-.54,0-.74,0c-.18,0-.35,0-.53,0s-.5,0-.75-.06l-.48,0c-.28,0-.54-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1.05.27l.36,0c.26.05.52.1.8.13l.48.05.75.06.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M276.52,589.4c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M291.69,590.32a.44.44,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.54.05c-.24,0-.49,0-.73,0h-.54l-.73,0H284l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M290,585.3c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M264.66,598.76a.31.31,0,0,0,.09-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09l-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07.54,0h1.27c.25,0,.49,0,.73,0l.55,0,.68-.06c.18,0,.34-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.06.26-.12.14-.07.2-.12a.5.5,0,0,0,.12-.08,1,1,0,0,0,.15-.11l0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M262.92,593.74c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M359.33,569.54l.09-.11.05-.08a.61.61,0,0,0,0-.12l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13l-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0h-.54l-.73,0h-.54l-.75-.07-.48,0-.8-.13-.36-.06q-.55-.12-1-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1,.27l.36.06c.27,0,.52.09.8.12l.48.05.75.06a4.87,4.87,0,0,0,.54,0l.73,0h.54c.24,0,.49,0,.72,0l.55,0,.68-.06.52-.09.63-.1.48-.13.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M357.59,564.52c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M372.84,573.65a.76.76,0,0,0,.08-.11.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.76.76,0,0,1-.08.11l-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.28.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07c-.16,0-.33,0-.49-.05s-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.4-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.67.19,1.05.27l.36.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0H367c.25,0,.49,0,.73,0l.55,0,.68-.06.52-.09.63-.1.47-.13c.18,0,.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0a.2.2,0,0,0,.06-.07l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M371.09,568.63c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M372.76,565.44a.44.44,0,0,0,.08-.11l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12L372,563l-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09l-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.79-.13-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07.54,0c.24,0,.49,0,.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06L372,566l.13-.07.2-.12.12-.08a1,1,0,0,0,.15-.11l.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M371,560.42c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M332.3,578l.09-.11.05-.07a1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06l.53-.09.62-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M330.56,573c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.8-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M345.81,582.09a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.1.1-.16.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.46,0-.68.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.5.33.1.68.18,1.05.26l.36.06.79.13.49,0,.75.07h.53l.73,0H340l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12.53-.14.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12,0,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M344.06,577.07c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M345.73,573.88a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07l-.06.12-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1a.58.58,0,0,1-.15.11l-.12.09-.2.11-.14.07a1.69,1.69,0,0,1-.27.13l-.13,0c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55,0,.68-.07.52-.08.63-.11.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14,0,.27-.13.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l0,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M344,568.87c2.62.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M357.49,573c2.62.8,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="229.15 242.08 95.09 283.81 69.56 275.89 203.94 234.09 229.15 242.08" style="fill: #615eb2"/>
                        <path d="M227.88,549.53a.83.83,0,0,0,.08-.12l0-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l0,.07-.08.12-.08.07-.11.1-.15.12-.11.08-.21.11-.13.08-.27.12-.14,0c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.87,9.87,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11.47-.12c.18,0,.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.21-.11.11-.09a.91.91,0,0,0,.16-.11l0,0,.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M226.13,544.51c2.62.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M241.38,553.64l.09-.12.05-.07a.5.5,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12L238,552l-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.27.05.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11.48-.12.52-.13.09,0,.47-.17.13,0a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M239.76,547.69c2.62.81,2.53,3.06,0,3.88a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3s6.93-1.76,9.56-.95" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M241.3,545.43l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a1.48,1.48,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07-.11.1-.16.12-.11.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.19.06-.4.1-.6.15s-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0l-.54,0-.74,0c-.18,0-.36,0-.53,0s-.5,0-.75-.06l-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36,0c.26.05.52.1.8.13l.48.05c.25,0,.49,0,.75.06l.53,0,.74,0,.54,0c.24,0,.48,0,.72,0l.55,0,.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M239.55,540.41c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M254.8,549.54l.09-.11a.35.35,0,0,0,.05-.08l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.15a4.05,4.05,0,0,1-.47.12c-.21,0-.42.08-.63.11l-.53.09-.67.06-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0a4.87,4.87,0,0,1-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49.05c.24,0,.49,0,.74.06a4.87,4.87,0,0,0,.54,0l.73,0,.54,0c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.28-.12.13-.08.2-.11.12-.08.15-.12,0,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M253.06,544.52c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M200.9,557.73a.76.76,0,0,0,.08-.11.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12l0-.06a1.23,1.23,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11.27.27,0,0,1-.08.07l-.1.1-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.46,0-.68.06l-.55,0-.72,0h-.55l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06q-.56-.12-1-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.06c.26,0,.52.09.79.12l.49,0,.75.07h.53l.73,0h.55l.72,0,.55-.05.68-.06.52-.09.63-.1.48-.13c.17,0,.36-.08.52-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M199.15,552.71c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M214.4,561.84l.09-.11,0-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l0,.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0H208l-.73,0h-.54l-.74-.07c-.17,0-.33,0-.49,0s-.53-.08-.8-.12l-.36-.06a10,10,0,0,1-1-.27c-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.06c.27,0,.53.09.8.12l.48,0,.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55-.05.68-.06.53-.09.62-.1.48-.13c.17,0,.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M212.66,556.82c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M214.32,553.63l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1L214,551l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.53,0c.25,0,.49,0,.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11s0,0,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M212.58,548.61c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M227.83,557.74a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26l.37.06.79.13.49,0,.74.07.54,0,.73,0,.54,0,.73,0,.55-.06.68-.06c.18,0,.34-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M226.08,552.72c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M173.87,566.17l.09-.11a.18.18,0,0,1,0-.07,1.12,1.12,0,0,0,.05-.13l0-.06a1.25,1.25,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1,.26l.36.06.8.13.48,0,.75.07h.53l.74,0H168l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.11-.08a1,1,0,0,0,.16-.12l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M172.12,561.15c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M187.37,570.28a.31.31,0,0,0,.09-.11l0-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13,0,.07a.48.48,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.54,0c-.24,0-.49,0-.73,0l-.54,0c-.25,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.14.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M185.63,565.26c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M187.29,562.08a.94.94,0,0,0,.09-.12l.05-.07a.61.61,0,0,0,0-.12l0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.27,0,.52.1.8.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M185.55,557.06c2.62.8,2.65,2.13.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M200.8,566.19l.08-.12.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.08.07-.11.1-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.31-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.2-.11.12-.08.16-.12,0,0,.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M199.05,561.17c2.62.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M282,532.85l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0-.72,0h-.55l-.73,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M280.21,527.83c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M295.46,537a.48.48,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,1,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.5.5,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0l-.54,0c-.25,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.72,0l.56-.05c.22,0,.45,0,.67-.06l.53-.09.62-.1.48-.12.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M293.72,531.94c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M295.38,528.76l.09-.12,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26,0,.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11.48-.12.52-.13.09,0,.47-.17.13,0a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.11.12-.09.15-.11.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M293.64,523.74c2.62.8,2.65,2.13.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M308.89,532.87l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.25.25,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.08.07-.11.1-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.05.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M307.14,527.85c2.62.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M254.93,541.29l.09-.11,0-.07a.69.69,0,0,0,.05-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1,.26l.36.06.8.13.48,0,.75.07.53,0h1.28l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.11-.08a.91.91,0,0,0,.16-.11.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M253.18,536.27c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M268.43,545.4a.31.31,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1a.83.83,0,0,1-.15.11l-.12.08-.2.12-.14.07a1.69,1.69,0,0,1-.27.12l-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.79-.13c-.12,0-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06.79.13.49,0,.74.07.54,0,.73,0,.54,0,.73,0,.55-.06c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M266.69,540.38c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M268.35,537.2a.94.94,0,0,0,.09-.12l.05-.07.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.27,0,.53.1.8.13l.49,0c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M266.61,532.18c2.62.81,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M281.86,541.31l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0a.34.34,0,0,1,.06-.06l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M280.11,536.29c2.63.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="254.9 250.16 120.84 291.89 95.31 283.97 229.69 242.17 254.9 250.16" style="fill: #615eb2"/>
                        <path d="M253.63,557.61l.09-.12a.43.43,0,0,0,0-.07.34.34,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M251.88,552.59c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M267.13,561.72a.94.94,0,0,0,.09-.12.18.18,0,0,0,0-.07l.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M265.39,556.7c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M267.05,553.51l.09-.11.05-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13l-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.11a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55,0-.72,0h-.54l-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1.05.27l.36.06c.27,0,.52.09.8.12l.48.05.75.06a4.87,4.87,0,0,0,.54,0l.73,0,.54,0c.24,0,.49,0,.72,0l.55,0,.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M265.31,548.49c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M280.56,557.62l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09-.68.06-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07c-.16,0-.33,0-.49-.05s-.53-.08-.79-.12l-.37-.06c-.36-.08-.72-.17-1-.27-1.33-.4-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.79.12l.49,0,.74.07h.54l.73,0h.55l.72,0,.55-.05.68-.06.52-.09.63-.1.47-.13c.18,0,.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0a.2.2,0,0,0,.06-.07l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M278.81,552.6c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M226.65,565.81l.09-.11a.18.18,0,0,1,0-.07,1.12,1.12,0,0,0,.05-.13l0-.06a1.25,1.25,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.08-.11.09-.16.12-.11.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0H219l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1,.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.11-.08.16-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M224.9,560.79c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M240.15,569.92a.44.44,0,0,0,.09-.11l0-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13,0,.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.17-1-.26-1.32-.41-2-.95-2-1.5v3c0,.55.67,1.09,2,1.49a9.25,9.25,0,0,0,1,.27l.37.06.79.13.49,0,.74.07H233l.73,0h.54c.25,0,.49,0,.73,0l.55,0,.67-.06c.18,0,.35-.06.53-.09l.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M238.41,564.9c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.8-2.66-2.13-.07-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M240.07,561.71a.48.48,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,1,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.09-.2.11-.14.07a1.11,1.11,0,0,1-.27.12l-.13.06-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.28,0-.53-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.49,0,.74.07.54,0,.73,0,.54,0,.72,0,.56-.06c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06a1.11,1.11,0,0,0,.27-.12l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M238.33,556.69c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M253.58,565.82a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l-.05.07-.08.12-.08.07-.1.1a.91.91,0,0,1-.16.11l-.11.09-.21.11-.13.07-.27.13-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.75.07l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11l0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M251.83,560.8c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M199.62,574.25l.09-.11,0-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0-.72,0-.54,0-.74,0-.53,0-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1,.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17-.05.36-.08.52-.14l.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M197.88,569.23c2.62.81,2.65,2.14.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M213.13,578.36a.44.44,0,0,0,.08-.11l0-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13,0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09l-.67.07-.55,0c-.24,0-.48,0-.73,0l-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07H206l.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12.12-.08a1,1,0,0,0,.15-.11l.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M211.38,573.34c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M213,570.16a.47.47,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0-.05,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07a.94.94,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.54,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M211.3,565.14c2.62.8,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M226.55,574.27l.09-.12a.43.43,0,0,0,0-.07.34.34,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.83.83,0,0,1-.08.12l-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.12.13-.08.21-.11.11-.08.16-.12,0,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M224.8,569.25c2.63.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M307.71,540.93l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.08,1.08,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17l-.6.16-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0-.55,0-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M306,535.91c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M321.21,545a.44.44,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09l-.67.07-.55,0c-.24,0-.49,0-.73,0l-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.79-.13-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M319.47,540c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M321.13,536.84a.94.94,0,0,0,.09-.12l0-.07.06-.12a.19.19,0,0,1,0-.07.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05.27-.12.14-.08.2-.11.12-.08L321,537l.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M319.39,531.82c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M334.64,541l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M332.89,535.93c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M280.68,549.37l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.11-.12.09-.2.11-.14.07a2.24,2.24,0,0,1-.27.13l-.13,0c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.25,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26,0,.52.1.8.13l.48,0c.25,0,.5.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11.48-.12.52-.13.08,0c.17,0,.33-.11.48-.17l.13-.05.27-.13.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M278.94,544.35c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M294.19,553.48a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07l-.06.12-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1a.58.58,0,0,1-.15.11l-.12.09-.2.11-.13.07-.27.13-.14,0c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.68-.07.52-.08.63-.11.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.05.27-.13.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M292.44,548.46c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M294.1,545.28l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11a.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08L293,543l-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.47.12-.42.08-.63.11l-.53.08-.67.07-.55,0c-.24,0-.49,0-.73,0l-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.37,0c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16L293,546l.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06a.46.46,0,0,0,.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M292.36,540.26c2.62.81,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M307.61,549.39l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12l0,.08-.09.11-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.14.06-.47.16a5.43,5.43,0,0,1-.61.16c-.15,0-.3.09-.47.12s-.42.07-.63.11l-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49,0,.75.06l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M305.86,544.37c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="280.75 258.17 146.69 299.9 121.16 291.98 255.54 250.18 280.75 258.17" style="fill: #615eb2"/>
                        <path d="M279.48,565.61l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1a.91.91,0,0,1-.16.11L279,563l-.2.11-.14.07a2.24,2.24,0,0,1-.27.13l-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11.48-.12c.17,0,.36-.08.52-.13l.08,0c.17,0,.33-.11.48-.17l.13-.05a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M277.73,560.59c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M293,569.72a.31.31,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18s0,0,0,.07l-.06.12-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1a.83.83,0,0,1-.15.11l-.12.08-.2.12-.14.07a1.69,1.69,0,0,1-.27.13l-.13,0c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06l.79.13.49,0,.74.07.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07c.18,0,.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06a1.11,1.11,0,0,0,.27-.12l.14-.07.2-.12.12-.08a.58.58,0,0,0,.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M291.24,564.71c2.62.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M292.9,561.52l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.08-.09.11a.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16a6.06,6.06,0,0,1-.61.16c-.16,0-.31.09-.48.12a6.17,6.17,0,0,1-.62.11l-.53.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.37,0q-.56-.12-1.05-.27c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06a.46.46,0,0,0,.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M291.16,556.5c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M306.41,565.63l.09-.11,0-.08a.34.34,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.07.07-.11.1-.16.12L306,563l-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12L303,564l-.52.09-.68.06-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.73,0,.55,0c.24,0,.48,0,.72,0l.55,0,.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.13-.08L306,566l.11-.08.16-.12,0,0a.2.2,0,0,1,.06-.07l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M304.66,560.61c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M252.5,573.82l.09-.11a.35.35,0,0,1,0-.08.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.62.62,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.18.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06q-.55-.12-1-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1,.27c.11,0,.24,0,.36.06s.52.09.8.12l.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M250.76,568.8c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M266,577.93l.09-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07L266,575l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.11c-.21.05-.42.08-.63.11l-.53.09-.67.06-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.14-.06.26-.12.14-.07.2-.12.12-.08.15-.12.05,0s0-.05.06-.07l.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M264.26,572.91c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M265.92,569.72a.48.48,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0l-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M264.18,564.7c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M279.43,573.83l.09-.11,0-.07a.38.38,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.07.08a.52.52,0,0,0-.11.1.91.91,0,0,1-.16.11l-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.49,0-.79-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.53,0c.25,0,.49,0,.73,0h.55l.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0,.34.34,0,0,1,.06-.06l.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M277.68,568.81c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M225.47,582.26l.09-.11.05-.07a1.12,1.12,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.11-.6.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1,.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M223.73,577.24c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="280.75 258.17 280.71 270.1 146.68 305.81 146.69 299.9 280.75 258.17" style="fill: #4c4186"/>
                        <path d="M239,586.37a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.11.1-.15.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.68.18,1.05.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0,.55-.05c.22,0,.46,0,.68-.06s.34-.06.52-.09l.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11,0,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M237.23,581.35c2.62.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.8-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M238.89,578.17a.47.47,0,0,0,.09-.12L239,578l.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18s0,0,0,.06L239,575l-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06c.26.05.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06a1.39,1.39,0,0,0,.28-.12l.13-.07.2-.11.12-.09a.58.58,0,0,0,.15-.11l0,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M237.15,573.14c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M252.4,582.27l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1a.91.91,0,0,1-.16.11l-.11.09-.2.11-.14.07a2.24,2.24,0,0,1-.27.13l-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11.48-.12.52-.13.08,0c.17,0,.33-.11.48-.17l.13,0a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M250.65,577.26c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M333.56,548.94l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M331.82,543.92c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M347.07,553.05a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.44.44,0,0,1-.09.11l-.07.08-.11.09-.15.12a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.15-.12.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M345.32,548c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M347,544.84a.31.31,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18s0,0,0,.07l-.06.12-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1a.83.83,0,0,1-.15.11l-.12.08-.2.12-.14.07a1.69,1.69,0,0,1-.27.13l-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06l.79.13.49,0,.74.07.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07c.18,0,.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06a1.11,1.11,0,0,0,.27-.12l.14-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M345.24,539.83c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M360.49,549l.09-.12.05-.07a.5.5,0,0,0,.05-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12l0,.07-.09.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11.48-.12c.17,0,.36-.08.52-.13l.08,0c.17-.05.33-.11.48-.17l.13-.05a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.12.11-.08a.91.91,0,0,0,.16-.11l.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M358.74,543.93c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M306.53,557.38a.48.48,0,0,0,.09-.11l.05-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0l-.54,0c-.25,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07.54,0h1.27c.24,0,.49,0,.72,0l.55,0c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.09,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11,0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M304.79,552.36c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M320,561.49a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08a.94.94,0,0,0-.1.1l-.16.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.79.13.49,0,.75.07.53,0c.25,0,.49,0,.73,0h.55l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11,0,0,.07-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M318.29,556.47c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M320,553.29l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.19.19,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.14,0,.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M318.21,548.27c2.62.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M333.46,557.4l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49,0,.75.06l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0c.17,0,.33-.11.48-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M331.71,552.38c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="306.61 266.4 172.55 308.13 147.01 300.21 281.4 258.4 306.61 266.4" style="fill: #615eb2"/>
                        <polygon points="172.55 308.13 172.53 314.04 147 305.94 147.01 300.2 172.55 308.13" style="fill: #342678"/>
                        <path d="M305.34,573.84l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07-.1.1-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0s-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.79.12l.49,0c.25,0,.49.05.74.06l.54,0,.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.07-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M303.59,568.82c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M318.84,578l.09-.11.05-.08a.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05-.72,0h-.54l-.73,0h-.54l-.75-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27c.12,0,.24,0,.36.06s.52.09.8.12l.48,0,.75.06a4.87,4.87,0,0,0,.54,0l.73,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M317.1,572.93c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M318.76,569.74l.09-.11,0-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.08,1.08,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17l-.6.16L316,568l-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0-.72,0-.54,0c-.25,0-.49,0-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1,.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M317,564.72c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M332.26,573.85a.31.31,0,0,0,.09-.11l0-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13,0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55,0c-.24,0-.49,0-.73,0l-.54,0-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.14.08,0c.17,0,.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.15-.11.05,0a.34.34,0,0,0,.06-.06l.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M330.52,568.83c2.62.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M278.36,582a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.1.1-.16.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26.11,0,.24,0,.36.06l.79.13.49,0,.75.07.53,0c.25,0,.49,0,.73,0h.55l.72,0,.55,0,.68-.07c.18,0,.35-.06.52-.09l.63-.1.47-.12c.18-.05.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11l0,0,.07-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M276.61,577c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M291.86,586.15A.48.48,0,0,0,292,586L292,586a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07a1.11,1.11,0,0,1-.27.12l-.13.06-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.28,0-.53-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.54,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.09,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M290.12,581.13c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M291.78,578l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16a5.25,5.25,0,0,1-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0-.75-.06-.48,0c-.28,0-.54-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26,0,.52.1.8.13l.48,0,.75.06.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M290,572.93c2.62.81,2.65,2.13.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M305.29,582.06l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.12a.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.28.12-.13.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.37-.05c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55,0,.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M303.54,577c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M251.33,590.49l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.14-.08.2-.11.11-.08.16-.12,0,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M249.58,585.47c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="306.61 266.4 306.59 272.33 172.53 314.04 172.55 308.13 306.61 266.4" style="fill: #4c4186"/>
                        <path d="M264.83,594.6a.47.47,0,0,0,.09-.12l.05-.07.06-.13s0,0,0-.06a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.15.15,0,0,1,0,.07.61.61,0,0,1,0,.12l-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.54,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M263.09,589.58c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M264.75,586.39l.09-.11.05-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11a.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12L264,584l-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0-.72,0h-.54l-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.53-.08-.8-.12l-.36-.06q-.55-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1,.27l.36.06c.27,0,.53.09.8.12l.48.05.75.06c.18,0,.36,0,.54,0l.73,0,.54,0c.24,0,.49,0,.72,0l.55,0,.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06L264,587l.14-.08.2-.11.12-.08.15-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M263,581.37c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M278.26,590.5l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09-.68.06-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.37-.06c-.36-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27l.36.06c.26,0,.52.09.79.12l.49,0,.74.07h.54l.73,0h.55l.72,0,.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0a.2.2,0,0,0,.06-.07l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M276.51,585.48c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M359.42,557.17a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.08.07-.1.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.12.13-.08.21-.12.11-.07.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M357.67,552.15c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M372.92,561.28a.94.94,0,0,0,.09-.12l.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.27.05.53.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0c.17,0,.32-.11.47-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M371.18,556.26c2.62.8,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3a18.53,18.53,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M372.84,553.07l.09-.11.05-.08a.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27c.12,0,.24,0,.36.06s.52.09.8.12l.48,0,.75.06a4.78,4.78,0,0,0,.53,0l.74,0,.54,0c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M371.1,548.05c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M386.35,557.18l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.53.09-.67.06-.55.05c-.24,0-.48,0-.73,0H380l-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49.05c.25,0,.49,0,.74.06s.36,0,.54,0l.73,0,.54,0c.25,0,.49,0,.73,0l.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.07.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M384.6,552.16c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M332.39,565.61l.09-.12,0-.07a.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13l-.36,0q-.55-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.11-.08.16-.12.05,0,.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M330.64,560.59c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M345.89,569.72a.47.47,0,0,0,.09-.12.18.18,0,0,0,0-.07l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.18.18,0,0,1,0,.07.94.94,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.37,0q-.55-.12-1-.27c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M344.15,564.7c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M345.81,561.51l.09-.11.05-.07.06-.13a.14.14,0,0,1,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.06.8.13.49,0,.74.07h.54l.73,0H340c.24,0,.49,0,.72,0l.56,0,.67-.06.53-.09.62-.1.48-.13.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M344.07,556.49c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.8-2.66-2.13-.08-3a18.42,18.42,0,0,1,9.44,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M359.32,565.62a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.1.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.46,0-.68.06l-.55,0-.72,0h-.55l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.5.33.1.68.18,1,.26l.36.06.79.13.49,0,.75.07h.53l.73,0h.55l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M357.57,560.6c2.63.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.8-2.67-2.13-.08-3a18.38,18.38,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M161.4,545.35a.47.47,0,0,0,.09-.12l.06-.07a.69.69,0,0,0,0-.13l0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13.35.35,0,0,1,0,.08.44.44,0,0,1-.09.11l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.13.06-.49.18-.63.16c-.16,0-.31.09-.49.12s-.43.08-.64.11l-.54.09-.7.07-.56.05-.75,0-.56,0-.75,0-.55,0c-.26,0-.52,0-.77-.07l-.5,0-.82-.13c-.12,0-.25,0-.37-.06a10.62,10.62,0,0,1-1.08-.28c-1.37-.42-2-1-2-1.53v3.06c0,.55.68,1.11,2,1.53q.51.15,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0,.77.07.55,0,.75,0,.56,0,.75,0,.56-.06c.24,0,.47,0,.7-.06l.54-.09.65-.11.49-.12.54-.14.08,0,.49-.17.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M159.61,540.18c2.7.83,2.73,2.2.08,3.05a18.91,18.91,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M228.54,524.82l.09-.12a.18.18,0,0,1,.05-.07l.06-.13s0,0,0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06a4.65,4.65,0,0,1-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56,0-.75,0-.55,0c-.26,0-.51,0-.76,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.54q.51.15,1.08.27l.38.06c.26,0,.53.1.81.13l.5,0c.26,0,.51.05.77.06a5.06,5.06,0,0,0,.55,0l.76,0h.56l.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.17.14-.07.28-.12.13-.07.21-.12.12-.09.16-.12.05,0,.06-.07.08-.07" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M226.74,519.65c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3.05a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <path d="M212.84,515.43c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <polygon points="199.3 305.66 199.03 311.78 252.76 295.06 252.99 289.01 199.3 305.66" style="fill: #2f2173"/>
                        <path d="M371,510.87a8,8,0,0,1-7.16-4.76c-2.42-4.94-7.45-13.29-13-9.05s-4.64,12.65-3.53,16.31,7.94,12,11.59,13.06,12.25-.91,12.25-.91l0-2.27a6,6,0,0,1-4.58-6.61c.38-5.59,4.5-3.47,4.5-3.47Z" transform="translate(-85.92 -289.96)" style="fill: #202a3b"/>
                        <path d="M371,513.17s-3.83-2.31-4.5,3.47a5.64,5.64,0,0,0,4.58,6.61Z" transform="translate(-85.92 -289.96)" style="fill: #c89c60"/>
                        <path d="M371.61,525.63c-5.28-.93-7.85-4.49-7.07-9.77.36-2.37,1.28-4,2.76-4.75a5.41,5.41,0,0,1,4.49,0l-1,2a3.25,3.25,0,0,0-2.5,0c-.8.44-1.34,1.48-1.58,3.11-.61,4.09,1.15,6.53,5.24,7.24Z" transform="translate(-85.92 -289.96)" style="fill: #384351"/>
                        <path d="M386.16,521.62s-1.68-2.57-6.81-1.61l-6.44.16-.37-11.92a9.29,9.29,0,0,1,7.38-2.65c4.81.3,6.76,3.18,7.27,5.44s-1,10.58-1,10.58" transform="translate(-85.92 -289.96)" style="fill: #c89c60"/>
                        <path d="M370.74,509.52l.13,16.09s5.1,3,8.5-3l-.07-8.08s-6.72.42-8.56-5" transform="translate(-85.92 -289.96)" style="fill: #f2e0b9"/>
                        <rect x="386.12" y="511.04" width="2.23" height="10.57" transform="translate(-90.31 -286.64) rotate(-0.49)" style="fill: #f2e0b9"/>
                        <path d="M376.78,514.82a6.85,6.85,0,0,1-4.19-1.16,4.93,4.93,0,0,1-1.85-4.14,4.38,4.38,0,0,1,2.52-3.93,10.88,10.88,0,0,1,5-1.13c4.82,0,10,2.25,10,6.57l-2.23,0c0-2.77-4-4.4-7.76-4.36a8.66,8.66,0,0,0-4,.86c-1.37.75-1.37,1.66-1.36,1.95a2.77,2.77,0,0,0,1,2.38c1.4,1.08,4.13.68,5.07.43l.29,2.24a11.84,11.84,0,0,1-2.52.27" transform="translate(-85.92 -289.96)" style="fill: #ebcb8e"/>
                        <polygon points="275.71 288.47 292.92 282.11 292.71 257.65 275.5 264.5 275.71 288.47" style="fill: #82cac6"/>
                        <polygon points="289.94 278.87 278.8 273.1 278.75 267.55 289.81 263.15 289.94 278.87" style="fill: #344d58"/>
                        <polygon points="278.88 282.96 278.8 273.1 289.94 278.87 278.88 282.96" style="fill: #8dd2c5"/>
                        <polygon points="269.79 261.71 275.5 264.5 292.71 257.65 287.31 255.04 269.79 261.71" style="fill: #8dd2c5"/>
                        <polygon points="269.85 268.88 260.67 264.24 276.87 257.78 278.94 258.23 269.79 261.71 269.85 268.88" style="fill: #496472"/>
                        <path d="M346.59,554.2a14.91,14.91,0,0,0-.67-2.67l15.43-5.9a4.26,4.26,0,0,1,1.44,2.11Z" transform="translate(-85.92 -289.96)" style="fill: #648592"/>
                        <path d="M361.63,578.42l-.21-24-5.71-2.78.06,7.17-9.18-4.64s-.84-7.65-7.39-9.12-6.71,7.53-6.55,9.9,2.25,9.46,10.41,13.45,18.57,10,18.57,10" transform="translate(-85.92 -289.96)" style="fill: #344d58"/>
                        <polygon points="255.69 296.62 272.9 290.26 272.69 265.8 255.48 272.65 255.69 296.62" style="fill: #82cac6"/>
                        <polygon points="269.92 287.03 258.78 281.26 258.73 275.71 269.79 271.31 269.92 287.03" style="fill: #344d58"/>
                        <polygon points="258.86 291.12 258.78 281.26 269.92 287.03 258.86 291.12" style="fill: #8dd2c5"/>
                        <polygon points="249.77 269.87 255.48 272.65 272.69 265.8 267.29 263.19 249.77 269.87" style="fill: #8dd2c5"/>
                        <polygon points="249.83 277.04 240.65 272.4 256.85 265.94 258.93 266.38 249.77 269.87 249.83 277.04" style="fill: #496472"/>
                        <path d="M326.57,562.36a14.05,14.05,0,0,0-.67-2.68l15.43-5.9a4.26,4.26,0,0,1,1.44,2.12Z" transform="translate(-85.92 -289.96)" style="fill: #648592"/>
                        <polygon points="226.83 256.7 226.88 262.78 241.33 270.68 241.26 263.47 226.83 256.7" style="fill: #648592"/>
                        <polygon points="241.26 263.47 275.47 251.23 275.52 257.46 241.33 270.68 241.26 263.47" style="fill: #82cac6"/>
                        <polygon points="224.93 255.8 229.96 213.01 246.38 219.63 240.99 263.24 224.93 255.8" style="fill: #2a3544"/>
                        <polygon points="246.38 219.63 273.9 210.07 278.75 249.8 240.99 263.24 246.38 219.63" style="fill: #384351"/>
                        <polygon points="229.96 213.01 259.41 203.46 273.9 210.07 246.38 219.63 229.96 213.01" style="fill: #495460"/>
                        <path d="M326.44,498.71l0,2.55s.77,3.17,10.17,3.09,10.44-3.27,10.44-3.27l0-4.12Z" transform="translate(-85.92 -289.96)" style="fill: #ebcb8e"/>
                        <path d="M319.56,486.84l.06,6.79s1.25,7.22,17,7S353,495.12,353,493.35s-.15-17.5-.15-17.5l-32.76.28Z" transform="translate(-85.92 -289.96)" style="fill: #f2e0b9"/>
                        <path d="M341.61,586.58l-.21-24-5.71-2.78.06,7.16-9.18-4.63s-.84-7.66-7.39-9.13c-5.66-1.26-6.54,5.06-6.58,8.44h0l0,1.66c0,1.52.85,6.87,8.33,11.93a12.82,12.82,0,0,0,2.1,1.33l.14.06a6.64,6.64,0,0,0,.75.38c8,4,17.68,9.55,17.68,9.55" transform="translate(-85.92 -289.96)" style="fill: #344d58"/>
                        <path d="M335.91,523.37a8.05,8.05,0,0,1-7.16-4.76c-2.42-4.94-7.45-13.29-13-9s-4.64,12.65-3.53,16.31,7.95,12,11.59,13S336,538,336,538l0-2.27a6,6,0,0,1-4.59-6.61c.38-5.59,4.5-3.47,4.5-3.47Z" transform="translate(-85.92 -289.96)" style="fill: #202a3b"/>
                        <path d="M335.93,525.67s-3.83-2.31-4.5,3.47a5.65,5.65,0,0,0,4.59,6.61Z" transform="translate(-85.92 -289.96)" style="fill: #c89c60"/>
                        <path d="M336.52,538.13c-5.28-.93-7.85-4.49-7.06-9.77.35-2.37,1.28-4,2.75-4.75a5.41,5.41,0,0,1,4.49,0l-.95,2a3.25,3.25,0,0,0-2.5,0c-.81.44-1.35,1.49-1.59,3.11-.61,4.09,1.16,6.53,5.24,7.24Z" transform="translate(-85.92 -289.96)" style="fill: #384351"/>
                        <path d="M351.07,534.12s-1.67-2.57-6.81-1.61l-6.43.16-.38-11.92a9.29,9.29,0,0,1,7.38-2.65c4.81.31,6.76,3.18,7.27,5.44s-1,10.58-1,10.58" transform="translate(-85.92 -289.96)" style="fill: #c89c60"/>
                        <path d="M335.65,522l.14,16.09s5.09,3,8.49-3l-.07-8.08s-6.72.42-8.56-5" transform="translate(-85.92 -289.96)" style="fill: #f2e0b9"/>
                        <rect x="351.03" y="523.54" width="2.23" height="10.57" transform="matrix(1, -0.01, 0.01, 1, -90.63, -286.79)" style="fill: #f2e0b9"/>
                        <path d="M341.69,527.32a6.85,6.85,0,0,1-4.19-1.16,5,5,0,0,1-1.85-4.14,4.38,4.38,0,0,1,2.52-3.93,10.88,10.88,0,0,1,5-1.13c4.82,0,10,2.25,10,6.57l-2.23,0c0-2.77-4-4.39-7.75-4.36a8.67,8.67,0,0,0-4,.86c-1.37.75-1.36,1.66-1.36,2a2.8,2.8,0,0,0,1,2.38c1.4,1.08,4.14.68,5.07.43l.3,2.24a11.37,11.37,0,0,1-2.53.27" transform="translate(-85.92 -289.96)" style="fill: #ebcb8e"/>
                        <path d="M352.84,480.37s-4.79,4.46-13.4,3.09S334,484,334,489.4s-1.29,7.48-.31,8.66,4.85,2.89,14.26,1a36.11,36.11,0,0,1-20.83.52c-11.36-3.15-6.7-19.68-6.7-19.68l10-8.92s22.95-.81,22.45,9.39" transform="translate(-85.92 -289.96)" style="fill: #dcb579"/>
                        <path d="M352.84,479.65a21.33,21.33,0,0,1-17.55.93c-4.62-1.7-4.18,5.15-3.92,7.68.32,3.15-3.25,3.9-3.24,1.14,0-2.23-2.55-1-2.65,2.05-.13,4.09-.55,6.39-2.05,6.59-2.62.36-6.63-4.79-7.26-10.64s-.62-13.69.68-17.11c2.07-5.41,6.77-10.51,22.4-10.6,5.07,0,10.22,1.64,13.51,5.67s3.56,10.33.08,14.29" transform="translate(-85.92 -289.96)" style="fill: #8e4438"/>
                        <path d="M346,486.08Z" transform="translate(-85.92 -289.96)" style="fill: #112025"/>
                        <path d="M341.86,486.78a2.07,2.07,0,1,1-2.15-2,2.06,2.06,0,0,1,2.15,2" transform="translate(-85.92 -289.96)" style="fill: #112025"/>
                        <path d="M340.5,486.22a.68.68,0,0,1-.61.73.65.65,0,0,1-.64-.67.63.63,0,1,1,1.25-.06" transform="translate(-85.92 -289.96)" style="fill: #fdf8f5"/>
                        <path d="M350.76,485.12c0,1-.69,2.25-1.57,2.28s-1.15-.71-1.19-1.76c-.05-1.42.74-2.28,1.61-2.31s1.12.74,1.15,1.79" transform="translate(-85.92 -289.96)" style="fill: #112025"/>
                        <path d="M349.91,484.84a.57.57,0,0,1-.45.65c-.27,0-.49-.26-.5-.61a.57.57,0,0,1,.45-.65c.27,0,.49.27.5.61" transform="translate(-85.92 -289.96)" style="fill: #fdf8f5"/>
                        <polygon points="404.06 276.75 404.02 301.31 376.2 292.86 376.24 268.3 404.06 276.75" style="fill: #9beada"/>
                        <polygon points="417.88 272.56 404.06 276.75 376.24 268.3 390.06 264.11 417.88 272.56" style="fill: #bbf9ed"/>
                        <path d="M482.63,558.88l.09-.11a.35.35,0,0,1,0-.08.5.5,0,0,0,.05-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1-.05.13l-.06.07-.08.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.27.13-.14.06-.49.17c-.19.06-.41.11-.63.16l-.48.13-.65.1c-.18,0-.35.07-.54.09l-.7.07-.56.05-.75,0H476l-.75,0a5.06,5.06,0,0,1-.55,0c-.26,0-.51,0-.77-.06l-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12a.1.1,0,0,0,.05,0l.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #9beada"/>
                        <path d="M480.83,553.72c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #bbf9ed"/>
                        <polygon points="417.88 272.56 417.85 297.12 404.02 301.31 404.06 276.75 417.88 272.56" style="fill: #c2fff3"/>
                        <path d="M496.53,563.11l.09-.11a.35.35,0,0,1,.05-.08l.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3.06a.58.58,0,0,1,0,.19s0,.05,0,.07l-.06.13-.05.07-.09.12-.08.07-.11.11-.16.12-.12.08-.21.12-.13.07-.28.13-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56.05-.75,0H490l-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28l.38.06.81.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57,0,.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.33-.08.49-.12.37-.08.54-.14l.08,0,.49-.18.14-.06.28-.12.14-.08a1.32,1.32,0,0,0,.2-.12.5.5,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,.05,0l.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #9beada"/>
                        <path d="M494.73,558c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #bbf9ed"/>
                        <polygon points="58.35 305.5 58.32 329.36 44.89 333.43 44.92 309.57 58.35 305.5" style="fill: #8cb0da"/>
                        <polygon points="44.92 309.57 44.89 333.43 31.36 329.33 31.4 305.48 44.92 309.57" style="fill: #4e72b3"/>
                        <polygon points="58.35 305.5 44.92 309.57 31.4 305.48 44.83 301.4 58.35 305.5" style="fill: #b7d1eb"/>
                        <path d="M137.2,596a.31.31,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07a1.69,1.69,0,0,1-.27.12l-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09l-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06.79.13.49,0,.74.07.54,0h1.27c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l0,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #4e72b3"/>
                        <path d="M135.46,591c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3a18.49,18.49,0,0,1,9.43,0" transform="translate(-85.92 -289.96)" style="fill: #c6dbf0"/>
                        <polygon points="352.85 57.25 331.25 68.67 319.47 49.63 352.85 57.25" style="fill: #f47458"/>
                        <polygon points="344.96 70.84 344.96 61.42 324.3 52.35 341.16 63.23 338.57 64.8 344.96 70.84" style="fill: #d33d24"/>
                        <path d="M436.44,363.56s.52.43,1.4,1.24" transform="translate(-85.92 -289.96)" style="fill: none;stroke: #f47458;stroke-miterlimit: 10.0000009536743"/>
                        <path d="M440.49,367.39c7.32,7.53,21.75,25.54,18.81,48.07-3.91,30-30.83,33.11-34.72,22.63S455,420,456.07,456.27c.91,30-28,46.11-38,50.83" transform="translate(-85.92 -289.96)" style="fill: none;stroke: #f47458;stroke-miterlimit: 10.0000009536743;stroke-dasharray: 4.800000190734863,4.800000190734863"/>
                        <path d="M415.29,507.91c-1.17.52-1.84.77-1.84.77" transform="translate(-85.92 -289.96)" style="fill: none;stroke: #f47458;stroke-miterlimit: 10.0000009536743"/>
                        <polygon points="216.08 29.43 234.09 45.56 249.95 30.1 216.08 29.43" style="fill: #c2fff3"/>
                        <polygon points="220.4 44.37 222.66 35.32 244.67 31.56 225.88 37.97 227.99 40.09 220.4 44.37" style="fill: #9beada"/>
                        <path d="M448.61,525.14c0,1.68-1,3.05-2.3,3.05s-2.3-1.37-2.3-3.05,1-3,2.3-3,2.3,1.37,2.3,3" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <path d="M458.35,521.4c0,1.68-1,3.05-2.3,3.05s-2.3-1.37-2.3-3.05,1-3,2.3-3,2.3,1.37,2.3,3" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <path d="M468.08,517.71c0,1.69-1,3-2.3,3s-2.3-1.36-2.3-3,1-3,2.3-3,2.3,1.36,2.3,3" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <path d="M326.6,399.46c0,1.68-1,3-2.3,3s-2.3-1.36-2.3-3,1-3,2.3-3,2.3,1.36,2.3,3" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <path d="M336.34,395.72c0,1.68-1,3-2.3,3s-2.3-1.36-2.3-3,1-3.05,2.3-3.05,2.3,1.36,2.3,3.05" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <path d="M346.07,392c0,1.68-1,3.05-2.3,3.05s-2.3-1.37-2.3-3.05,1-3,2.3-3,2.3,1.37,2.3,3" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <polygon points="124.36 180.81 108.25 186.13 84.77 179.38 81.89 176.44 75.49 176.73 74.13 174.19 66.48 174.11 65.72 171.67 57.04 171.39 57.04 169.14 73.62 166.2 124.36 180.81" style="fill: #d87d19"/>
                        <polygon points="124.36 178.56 108.25 183.88 84.77 177.13 81.89 174.19 75.49 174.48 74.13 171.94 66.48 171.86 65.72 169.42 57.04 169.14 60.88 165.11 73.62 163.95 124.36 178.56" style="fill: #ffc973"/>
                        <polygon points="124.36 178.56 124.36 181.22 108.22 186.66 108.25 183.88 124.36 178.56" style="fill: #d04b73"/>
                        <path d="M232.34,484s2.51-2.38,10.5-2.08,9.31,2.08,9.31,2.08l2.35-2.09-2.84-4.26-13.8-.28-9.17,4.09Z" transform="translate(-85.92 -289.96)" style="fill: #d87d19"/>
                        <path d="M256.86,470.26c-16.14-5-42.07-4.89-57.92.16s-15.65,13.18.49,18.16,42,4.9,57.91-.17,15.63-13.19-.48-18.15m-7,14.36a29.65,29.65,0,0,1-15.14,0c-4.22-1.3-4.27-3.42-.13-4.75a29.69,29.69,0,0,1,15.15,0C254,481.17,254,483.3,249.87,484.62Z" transform="translate(-85.92 -289.96)" style="fill: #ffc973"/>
                        <polygon points="113.02 180.46 60.88 165.11 63.58 164.87 114.73 179.95 113.02 180.46" style="fill: #d87d19"/>
                        <path d="M265.05,478.5h0c0-4.31-15.19-8.91-36.32-8.91s-36.32,4.6-36.32,8.91c0-6.25,18.47-10,36.32-10s36.32,3.74,36.32,10" transform="translate(-85.92 -289.96)" style="fill: #d87d19"/>
                        <path d="M303.12,328.71s-110,32.82-96,92c1.4,5.95,8.63,23,24.21,28.59,14.79,5.32,46.16,6.85,47.78-1.83,3.3-17.7-73.69-8.08-38.55,34.23,14.54,17.5,47.12,25.12,68.2,29" transform="translate(-85.92 -289.96)" style="fill: none;stroke: #c2fff3;stroke-miterlimit: 10.0000009536743;stroke-dasharray: 4.800000190734863,4.800000190734863"/>
                        <polygon points="156.46 195.86 161.99 195.86 165.93 198.72 166.27 200.36 160.03 201.42 156.46 195.86" style="fill: #ffc973"/>
                        <polygon points="268.96 270.56 315.07 255.21 325.86 217.33 324.21 216.14 278.1 231.49 268.96 270.56" style="fill: #474747"/>
                        <polygon points="270.61 271.75 316.72 256.39 325.86 217.32 279.75 232.68 270.61 271.75" style="fill: #1f2020"/>
                        <polygon points="270.61 271.75 247.38 261.5 247.38 260.1 275.57 250.52 270.61 271.75" style="fill: #474747"/>
                        <polygon points="273.48 251.23 269.35 269.59 247.38 260.1 273.48 251.23" style="fill: #777"/>
                        <path d="M387.47,533.61c-.26,1.38-1.2,2.36-2.11,2.19s-1.43-1.42-1.18-2.8,1.2-2.35,2.1-2.18,1.44,1.42,1.19,2.79" transform="translate(-85.92 -289.96)" style="fill: #474747"/>
                        <path d="M267.58,486.39a6.78,6.78,0,0,0,.53-.7,4.27,4.27,0,0,0,.3-.44,4.81,4.81,0,0,0,.35-.77c.05-.13.12-.25.16-.38a4.57,4.57,0,0,0,.17-1.15v-3.54a4.16,4.16,0,0,1-.17,1.15c0,.13-.1.25-.15.38a5.25,5.25,0,0,1-.35.77c-.09.15-.2.29-.3.44a6.58,6.58,0,0,1-.54.7c-.13.15-.29.3-.44.45s-.43.41-.67.61-.61.48-.94.71-.46.33-.72.5-.81.47-1.24.71-.54.3-.84.45c-.51.25-1.08.5-1.65.74l-.83.36c-.9.35-1.86.7-2.9,1s-2.47.66-3.73,1c-1,.25-1.89.53-2.91.74-1.24.26-2.57.44-3.86.65-1.08.17-2.11.38-3.22.52-1.35.18-2.77.28-4.16.4-1.13.1-2.23.24-3.37.31-1.47.09-3,.11-4.45.15-1.11,0-2.21.1-3.32.1-1.51,0-3-.07-4.5-.12-1.1,0-2.21,0-3.29-.1-1.56-.09-3.06-.26-4.58-.41-1-.1-2-.15-3-.28-1.69-.21-3.28-.5-4.88-.78-.73-.13-1.52-.22-2.23-.37-2.27-.46-4.43-1-6.43-1.61-8.16-2.52-12.25-5.84-12.25-9.15V483c0,3.32,4.09,6.63,12.25,9.15,2,.62,4.16,1.15,6.43,1.62.72.14,1.5.23,2.24.36,1.6.28,3.19.58,4.87.79,1,.12,2,.17,3,.27,1.52.15,3,.32,4.58.41,1.09.06,2.19.06,3.29.1,1.5.05,3,.12,4.5.12,1.12,0,2.22-.07,3.33-.1,1.49,0,3-.05,4.44-.14,1.15-.08,2.25-.21,3.38-.32,1.39-.12,2.8-.22,4.16-.39,1.11-.15,2.14-.36,3.22-.53,1.29-.21,2.62-.39,3.85-.65,1-.21,1.94-.49,2.92-.74s2.22-.5,3.23-.81l.5-.16c1-.33,2-.67,2.89-1,.3-.12.55-.24.83-.36.57-.24,1.15-.49,1.66-.74l.83-.45c.43-.23.86-.47,1.24-.71s.49-.33.72-.5.66-.47,1-.71c.09-.08.21-.15.3-.23s.24-.25.36-.37l.45-.46" transform="translate(-85.92 -289.96)" style="fill: #d87d19"/>
                        <path d="M334,475.85s7.84,5.61,16.8,3a80.42,80.42,0,0,1-16.8-3" transform="translate(-85.92 -289.96)" style="fill: #bb5851"/>
                        <path d="M341.4,474.84s6.12,2.79,10.8,1a68,68,0,0,1-10.8-1" transform="translate(-85.92 -289.96)" style="fill: #bb5851"/>
                        <path d="M341.87,493.64a19.78,19.78,0,0,0,3.23-.39,17.3,17.3,0,0,0,2.87-.91,3.43,3.43,0,0,1-2.94,3.12c-2.79.43-3.16-1.82-3.16-1.82" transform="translate(-85.92 -289.96)" style="fill: #fff"/>
                        <path d="M341.87,493.64a19.78,19.78,0,0,0,3.23-.39,17.3,17.3,0,0,0,2.87-.91,3.43,3.43,0,0,1-2.94,3.12C342.24,495.89,341.87,493.64,341.87,493.64Z" transform="translate(-85.92 -289.96)" style="fill: none;stroke: #112025;stroke-linecap: round;stroke-linejoin: round;stroke-width: 0.75px"/>
                        <polygon points="289.72 347.91 289.69 353.76 261.81 345.58 261.85 339.73 289.72 347.91" style="fill: #a33113"/>
                        <polygon points="317.37 339.5 289.72 347.91 261.85 339.73 289.49 331.32 317.37 339.5" style="fill: #f47458"/>
                        <path d="M368.29,630l.09-.11a.35.35,0,0,0,.05-.08l.06-.13,0-.06a1.23,1.23,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19.19.19,0,0,1,0,.07l-.06.13-.05.07-.09.12-.07.07-.12.1a1.11,1.11,0,0,1-.16.12l-.11.09-.21.12-.14.07-.28.13-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.1c-.18,0-.35.07-.54.09l-.69.07-.57,0c-.24,0-.49,0-.74,0h-.56l-.75,0a5.24,5.24,0,0,1-.56,0c-.26,0-.51,0-.76-.06l-.5-.05c-.29,0-.55-.08-.82-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.06,1.53a8,8,0,0,0,1.07.27c.12,0,.25,0,.38.06l.82.14.5,0c.25,0,.5.06.76.07l.56,0,.75,0,.56,0,.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09a6.29,6.29,0,0,0,.65-.11c.17,0,.33-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.28-.12.14-.08.21-.12.12-.08.15-.12a.1.1,0,0,0,0,0l.07-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #a33113"/>
                        <path d="M366.49,624.88c2.7.83,2.74,2.19.09,3a19,19,0,0,1-9.71,0c-2.71-.84-2.75-2.2-.09-3.05a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #e58b76"/>
                        <polygon points="317.37 339.5 317.33 345.35 289.69 353.76 289.72 347.91 317.37 339.5" style="fill: #ca4a2c"/>
                        <path d="M382.19,634.27l.09-.11a.35.35,0,0,0,.05-.08.31.31,0,0,0,.06-.13l0-.06a.63.63,0,0,0,0-.19v-3.06a1.08,1.08,0,0,1,0,.19l0,.07a1.39,1.39,0,0,1-.06.13l-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1a.83.83,0,0,1-.16.12l-.12.09-.21.12-.14.07-.28.13L381,632l-.48.17-.63.16-.49.13-.64.1-.54.09-.7.07-.57,0c-.24,0-.49,0-.74,0h-.56l-.75,0h-.56l-.76-.07-.5-.05c-.29,0-.55-.08-.82-.13l-.37-.06q-.59-.12-1.08-.27c-1.37-.42-2.06-1-2.06-1.54v3.06c0,.56.69,1.11,2.06,1.53a10,10,0,0,0,1.08.28c.12,0,.25,0,.37.06l.82.13.5,0c.25,0,.51.06.77.07l.55,0,.75,0,.56,0,.74,0,.57,0,.7-.07.54-.09c.21,0,.44-.06.64-.11s.33-.08.49-.12.38-.08.54-.14l.09,0L381,635l.14-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #a33113"/>
                        <path d="M380.4,629.11c2.7.83,2.73,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #e58b76"/>
                        <path d="M382.11,625.83l.09-.12.05-.07.06-.13a.25.25,0,0,0,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.2.12-.14.07-.28.13-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09-.69.06-.57,0-.74,0-.56,0c-.26,0-.51,0-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.81-.13s-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3c0,.56.69,1.12,2.05,1.54q.51.15,1.08.27l.38.06c.27,0,.53.1.82.13l.49,0,.77.07h.55l.76,0h.56l.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.08,0,.49-.17.14-.06.28-.13.14-.07.2-.12.12-.09.16-.12.05,0,.06-.07.08-.07" transform="translate(-85.92 -289.96)" style="fill: #a33113"/>
                        <path d="M380.31,620.66c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3a18.91,18.91,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #e58b76"/>
                        <path d="M396,630.06l.09-.12,0-.07a1.39,1.39,0,0,0,.06-.13l0-.07a1.23,1.23,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13,0,.07L396,627l-.07.08-.12.1-.15.12-.13.08-.2.12-.14.08-.28.12-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09c-.22,0-.46,0-.69.06l-.57.06-.74,0-.56,0-.76,0-.55,0-.76-.07c-.17,0-.34,0-.5-.05s-.55-.08-.82-.13-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3.05c0,.56.69,1.12,2.06,1.54.33.1.69.19,1.07.27l.38.06c.27,0,.53.1.82.13l.5,0,.76.07h.56l.75,0H390c.25,0,.5,0,.74,0l.57-.05.69-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.09,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.09a.88.88,0,0,0,.15-.12l.05,0,.07-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #a33113"/>
                        <path d="M394.21,624.89c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3a18.91,18.91,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #e58b76"/>
                        <polygon points="383.12 330.64 383.08 336.49 355.21 328.31 355.24 322.46 383.12 330.64" style="fill: #342678"/>
                        <polygon points="410.76 322.23 383.12 330.64 355.24 322.46 382.89 314.05 410.76 322.23" style="fill: #615eb2"/>
                        <path d="M461.68,612.77a.44.44,0,0,0,.09-.11l.06-.08a.5.5,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a.49.49,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.28.13-.14.06-.48.17-.63.16-.49.13-.64.1-.54.09-.7.07-.56,0-.75,0h-.56l-.75,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06c-.38-.08-.75-.17-1.08-.27-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.25,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.48-.12l.55-.14.08,0,.49-.18.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M459.89,607.61c2.7.83,2.73,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="410.76 322.23 410.73 328.08 383.08 336.49 383.12 330.64 410.76 322.23" style="fill: #4c4186"/>
                        <path d="M475.59,617l.09-.11a.35.35,0,0,1,.05-.08.5.5,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3.06a.58.58,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1,0,.13l-.05.07-.09.12-.08.07a.52.52,0,0,1-.11.1,1,1,0,0,1-.16.12l-.12.09-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06-.49.17c-.19.06-.41.11-.63.16l-.48.13-.65.11-.54.08-.7.07-.56.05-.75,0H469c-.25,0-.5,0-.75,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.54a10.68,10.68,0,0,0,1.08.26l.37.07.82.13.5.05c.26,0,.51,0,.77.06l.55,0,.76,0,.56,0,.74,0,.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12.37-.08.54-.14l.08,0,.49-.18.14-.06.27-.12.14-.08a1,1,0,0,0,.21-.11l.12-.09.16-.12a.1.1,0,0,0,.05,0l.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M473.79,611.84c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M475.5,608.56a.94.94,0,0,0,.09-.12l.05-.07a.49.49,0,0,0,.06-.13l0-.07a.63.63,0,0,0,0-.19v-3.06a1.38,1.38,0,0,1,0,.2l0,.06a.38.38,0,0,1-.06.13l-.05.07a.94.94,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.07-.28.13-.14.06c-.15.06-.31.12-.48.17l-.63.17-.49.12-.65.11-.53.09-.7.06-.57,0c-.24,0-.49,0-.74,0l-.56,0-.75,0h-.55l-.77-.07-.5-.05c-.29,0-.55-.08-.82-.13-.12,0-.25,0-.37-.06-.38-.08-.75-.17-1.08-.27-1.37-.42-2.06-1-2.06-1.53V608c0,.56.69,1.11,2.06,1.54.33.1.7.19,1.08.27l.37.06c.27,0,.54.1.82.13l.5,0,.77.07h.55l.75,0h.56c.25,0,.5,0,.74,0l.57-.05.7-.07.54-.09.64-.1.49-.13.54-.13.09,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.09.16-.12.05,0,.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M473.71,603.39c2.7.83,2.73,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.75-2.2-.08-3a18.91,18.91,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M489.41,612.79l.09-.12.05-.07a1.12,1.12,0,0,0,.05-.13l0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.38.38,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.13.06-.49.17c-.19.07-.42.11-.63.17l-.48.12-.65.11-.54.09c-.23,0-.46,0-.7.06l-.56.06-.75,0-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a10.65,10.65,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.05c0,.56.68,1.12,2.05,1.54q.51.15,1.08.27l.37.06c.27,0,.54.1.82.13l.5,0,.77.07h.55l.75,0h.56l.75,0,.56-.05.7-.07.54-.08.65-.11.49-.13.54-.13.08,0,.49-.17.13-.06.28-.13.14-.07.21-.12.12-.08.16-.12s0,0,0,0l.06-.07.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M487.61,607.62c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="88.9 347.66 88.87 353.51 60.99 345.33 61.03 339.48 88.9 347.66" style="fill: #342678"/>
                        <polygon points="116.55 339.25 88.9 347.66 61.03 339.48 88.67 331.07 116.55 339.25" style="fill: #615eb2"/>
                        <path d="M167.47,629.8l.09-.12.05-.07.06-.13a.19.19,0,0,0,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.2.12-.14.08-.28.12-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09c-.22,0-.46,0-.69.06l-.57,0-.74,0-.56,0-.76,0-.55,0-.77-.07c-.16,0-.33,0-.5-.05s-.54-.08-.81-.13-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2-1-2-1.53v3.05c0,.56.69,1.12,2,1.54q.51.15,1.08.27l.38.06c.27,0,.53.1.82.13l.49,0,.77.07h.55l.76,0h.56c.25,0,.5,0,.74,0l.57-.05.69-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.08,0,.49-.17.14-.06.28-.13.14-.07.2-.12.12-.08.16-.13.05,0s0-.05.06-.07l.08-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M165.67,624.63c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="116.55 339.25 116.51 345.11 88.87 353.51 88.9 347.66 116.55 339.25" style="fill: #4c4186"/>
                        <path d="M181.37,634a.94.94,0,0,0,.09-.12l0-.07a1.39,1.39,0,0,0,.06-.13l0-.07a1.25,1.25,0,0,0,0-.19v-3.06a1.36,1.36,0,0,1,0,.2l0,.06a.75.75,0,0,1-.06.13l0,.07a.94.94,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17s-.42.11-.63.17l-.49.12-.65.11-.53.09c-.23,0-.47,0-.7.06l-.57.06-.74,0-.56,0-.75,0-.56,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.14c-.12,0-.26,0-.38-.06a9.82,9.82,0,0,1-1.07-.27c-1.37-.42-2.06-1-2.06-1.53v3.05c0,.56.69,1.12,2.06,1.54q.5.15,1.08.27l.37.06c.27,0,.53.1.82.13l.5,0,.76.07H174l.75,0h.56c.25,0,.5,0,.74,0l.57,0,.7-.07.54-.08.64-.11.49-.13.54-.13.09,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,.05,0l.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M179.57,628.86c2.71.83,2.74,2.2.09,3a19,19,0,0,1-9.71,0c-2.71-.83-2.75-2.19-.08-3a19,19,0,0,1,9.7,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M181.29,625.58l.09-.12.05-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13a.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12a.5.5,0,0,1-.12.08l-.21.12-.14.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56,0-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.13c-.12,0-.25,0-.37-.06a10.62,10.62,0,0,1-1.08-.28c-1.37-.42-2.05-1-2.05-1.53V625c0,.55.69,1.11,2.05,1.53q.51.15,1.08.27c.12,0,.25,0,.38.06s.53.1.81.13l.5,0,.77.07.55,0,.76,0,.56,0,.74,0,.57-.05.69-.06.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.13-.07.21-.12.12-.08.16-.12.05,0a.34.34,0,0,0,.06-.06l.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M179.49,620.41c2.7.83,2.74,2.2.08,3.05a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M195.19,629.81l.09-.12.05-.07.06-.13a.14.14,0,0,0,0-.06.69.69,0,0,0,0-.2v-3.05a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.08-.09.11-.07.08-.12.1a1.11,1.11,0,0,1-.16.12.36.36,0,0,1-.12.08l-.2.12-.14.08-.28.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.69.07-.57,0-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.13-.38-.06a10.62,10.62,0,0,1-1.08-.28c-1.36-.42-2-1-2-1.53v3.06c0,.55.69,1.11,2,1.53q.51.15,1.08.27c.12,0,.25,0,.38.06l.82.14.5,0,.76.07.55,0,.76,0,.56,0,.74,0,.57-.06c.23,0,.47,0,.69-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.12.14-.08.2-.12.13-.08L195,630l.05,0,.07-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M193.39,624.64c2.7.83,2.74,2.2.08,3.05a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="335.8 313.63 335.76 319.49 307.89 311.3 307.93 305.45 335.8 313.63" style="fill: #342678"/>
                        <polygon points="363.44 305.23 335.8 313.63 307.93 305.45 335.57 297.04 363.44 305.23" style="fill: #615eb2"/>
                        <path d="M414.37,595.77l.09-.12.05-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06a.5.5,0,0,1-.05.13.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1L414,593l-.12.08-.21.12-.14.08-.27.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17s-.32.09-.49.12l-.65.11-.54.09-.7.07-.56,0-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.69,1.11,2.05,1.53q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5,0,.77.07H407c.25,0,.5,0,.76,0h.56l.74,0,.56,0,.7-.07.54-.08.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.13-.07.21-.12.12-.08.16-.12.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M412.57,590.6c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="363.44 305.23 363.41 311.08 335.76 319.49 335.8 313.63 363.44 305.23" style="fill: #4c4186"/>
                        <path d="M428.27,600l.09-.12.05-.07.06-.13a.14.14,0,0,0,0-.06.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13a.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12a.5.5,0,0,1-.12.08l-.2.12-.14.08-.28.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.69.07-.57.05-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.81-.13-.38-.06a10.62,10.62,0,0,1-1.08-.28c-1.36-.42-2-1-2-1.53v3.06c0,.55.69,1.11,2,1.53q.51.15,1.08.27c.12,0,.25,0,.38.06l.82.14.49,0,.77.07.55,0,.76,0,.56,0,.74,0,.57-.06c.23,0,.47,0,.69-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.12.14-.08.2-.12a.62.62,0,0,0,.12-.08,1.11,1.11,0,0,0,.16-.12l.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M426.47,594.83c2.7.83,2.74,2.2.08,3a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M428.19,591.55l.09-.11a.35.35,0,0,1,.05-.08.5.5,0,0,0,.05-.13l0-.06a.69.69,0,0,0,0-.2v-3.05a.63.63,0,0,1,0,.19l0,.07a.34.34,0,0,1-.06.12.35.35,0,0,1-.05.08.31.31,0,0,1-.09.11.27.27,0,0,1-.07.08.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.11-.14.08-.28.12-.13.06-.49.18c-.19.06-.42.11-.63.16s-.31.09-.48.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56,0c-.25,0-.5,0-.75,0l-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.06l-.5-.05-.82-.13-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54V591c0,.55.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.26,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56-.06c.24,0,.47,0,.7-.06l.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0,.49-.17.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M426.39,586.38c2.7.84,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3.05a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M442.09,595.78l.09-.11a.35.35,0,0,1,.05-.08l.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1,0,.13l-.05.07-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.27.13-.14.06-.49.17c-.19.06-.41.11-.63.16l-.48.13-.65.1c-.18,0-.35.07-.54.09l-.7.07-.56,0-.75,0h-.55l-.76,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.57-.05.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.27-.12.14-.08.21-.12a.5.5,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,.05,0l.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M440.29,590.62c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="307.61 322.38 307.57 328.24 279.7 320.05 279.73 314.2 307.61 322.38" style="fill: #342678"/>
                        <polygon points="335.25 313.98 307.61 322.38 279.73 314.2 307.38 305.79 335.25 313.98" style="fill: #615eb2"/>
                        <path d="M386.17,604.52a.47.47,0,0,0,.09-.12l.05-.07a.38.38,0,0,0,.06-.13l0-.07a.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.38.38,0,0,1-.06.13.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17s-.42.11-.63.17-.31.09-.49.12l-.64.11-.54.09c-.23,0-.47,0-.7.06l-.57.06-.74,0-.56,0-.75,0-.56,0c-.25,0-.51,0-.76-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a8.11,8.11,0,0,1-1.08-.27c-1.37-.42-2.06-1-2.06-1.53v3.05c0,.56.69,1.12,2.06,1.54.33.1.7.19,1.08.27l.37.06c.27.05.54.1.82.13l.5,0,.77.07h.55l.75,0h.56c.25,0,.5,0,.75,0l.56,0,.7-.07.54-.08.64-.11.49-.13c.18,0,.38-.08.54-.13l.09,0c.17-.05.33-.11.48-.17l.14-.06.28-.13.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,.05,0l.06-.07.07-.07" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M384.38,599.35c2.7.83,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="335.25 313.98 335.21 319.83 307.57 328.24 307.61 322.38 335.25 313.98" style="fill: #4c4186"/>
                        <path d="M400.08,608.75l.09-.12.05-.07a.69.69,0,0,0,0-.13l0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.06a.5.5,0,0,1,0,.13.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.13.06-.49.18c-.19.06-.41.11-.63.16s-.31.09-.48.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56.05-.75,0-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.13-.37-.07a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2-1-2-1.53v3.06c0,.55.68,1.11,2,1.53q.51.15,1.08.27c.12,0,.25,0,.37.06s.54.1.82.13l.5.05.77.07.55,0,.75,0,.56,0,.75,0,.56,0,.7-.07.54-.08.65-.11.49-.12.54-.14.08,0,.49-.17.13-.06a1.82,1.82,0,0,0,.28-.13l.14-.07.21-.12.12-.08.16-.12.05,0,.06-.06.08-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M398.28,603.58c2.7.83,2.74,2.2.08,3a18.91,18.91,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M400,600.3a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07.75.75,0,0,0,.06-.13l0-.06a1.38,1.38,0,0,0,0-.2v-3.05a1.23,1.23,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13.35.35,0,0,1-.05.08.48.48,0,0,1-.09.11l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06-.48.18-.63.16c-.16,0-.32.09-.49.12a6.29,6.29,0,0,1-.65.11c-.17,0-.35.07-.53.09l-.7.07-.57,0-.74,0-.56,0-.75,0-.56,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.13-.38-.06a9.78,9.78,0,0,1-1.07-.28c-1.37-.42-2.06-1-2.06-1.53v3.06c0,.55.69,1.11,2.06,1.53q.49.15,1.08.27c.11,0,.25,0,.37.06l.82.14.5,0,.76.07.56,0,.75,0,.56,0,.74,0,.57-.06c.23,0,.47,0,.7-.06l.54-.09.64-.11.49-.12.54-.14.09,0c.17-.05.33-.11.48-.17l.14-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M398.2,595.13c2.7.84,2.73,2.2.08,3a18.91,18.91,0,0,1-9.71,0c-2.71-.83-2.75-2.19-.08-3a19,19,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <path d="M413.89,604.53l.09-.11a.35.35,0,0,0,0-.08.31.31,0,0,0,.06-.13l0-.06a.68.68,0,0,0,0-.19V600.9a.63.63,0,0,1,0,.19l0,.07a.49.49,0,0,1-.06.13l0,.07a.47.47,0,0,1-.09.12l-.07.07-.11.1-.16.12-.12.09-.21.12-.14.07-.28.13-.14.06-.48.17-.63.16c-.16,0-.31.09-.49.12s-.43.08-.64.11l-.54.09-.7.07-.56,0-.75,0h-.56l-.75,0a5,5,0,0,1-.55,0c-.26,0-.52,0-.77-.06l-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06c-.38-.08-.75-.17-1.08-.27-1.37-.43-2.05-1-2.05-1.54V604c0,.56.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.25,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56-.05.7-.07.54-.09.64-.11c.18,0,.33-.08.49-.12l.54-.14.09,0,.49-.18.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06.07-.08" transform="translate(-85.92 -289.96)" style="fill: #342678"/>
                        <path d="M412.1,599.36c2.7.84,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3.05a19.13,19.13,0,0,1,9.71,0" transform="translate(-85.92 -289.96)" style="fill: #6e6abb"/>
                        <polygon points="146.69 299.9 146.68 305.81 121.14 298 121.16 291.98 146.69 299.9" style="fill: #342678"/>
                        <polygon points="120.84 291.89 120.83 297.8 95.29 289.99 95.31 283.97 120.84 291.89" style="fill: #342678"/>
                        <polygon points="95.09 283.81 95.08 289.72 69.54 281.91 69.56 275.89 95.09 283.81" style="fill: #342678"/>
                        <line x1="69.54" y1="281.91" x2="172.54" y2="314.04" style="fill: none;stroke: #615eb2;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                        <polygon points="172.55 314.32 172.53 320.23 147 312.14 147.01 306.4 172.55 314.32" style="fill: #342678"/>
                        <polygon points="172.55 320.52 172.53 326.43 147 318.33 147.01 312.6 172.55 320.52" style="fill: #342678"/>
                        <polygon points="146.69 306.1 146.68 312.01 121.14 304.19 121.16 298.18 146.69 306.1" style="fill: #342678"/>
                        <polygon points="146.69 312.3 146.68 318.21 121.14 310.39 121.16 304.38 146.69 312.3" style="fill: #342678"/>
                        <polygon points="120.84 298.09 120.83 304 95.29 296.19 95.31 290.17 120.84 298.09" style="fill: #342678"/>
                        <polygon points="120.84 304.29 120.83 310.2 95.29 302.38 95.31 296.37 120.84 304.29" style="fill: #342678"/>
                        <polygon points="95.09 290.01 95.08 295.92 69.54 288.11 69.56 282.09 95.09 290.01" style="fill: #342678"/>
                        <polygon points="95.09 296.21 95.08 302.12 69.54 294.3 69.56 288.29 95.09 296.21" style="fill: #342678"/>
                        <line x1="69.54" y1="288.11" x2="172.54" y2="320.23" style="fill: none;stroke: #615eb2;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                        <line x1="69.54" y1="294.3" x2="172.54" y2="326.43" style="fill: none;stroke: #615eb2;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                        <polygon points="95.41 289.48 95.39 289.48 95.41 283.9 95.09 283.81 95.08 289.72 95.09 289.73 95.08 302.58 95.39 302.65 95.41 289.48" style="fill: #4c4186;fill-rule: evenodd"/>
                        <polygon points="121.16 297.48 121.14 297.47 121.16 291.89 120.84 291.81 120.83 297.72 120.84 297.73 120.83 310.57 121.14 310.65 121.16 297.48" style="fill: #4c4186;fill-rule: evenodd"/>
                        <polygon points="147.01 305.54 147 305.77 147.01 300.19 146.7 299.91 146.68 305.78 146.7 305.79 146.68 318.63 147 318.71 147.01 305.54" style="fill: #4c4186;fill-rule: evenodd"/>
                        <line x1="306.59" y1="284.74" x2="172.37" y2="326.46" style="fill: none;stroke: #615eb2;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                        <polygon points="55.59 260.15 55.6 265.5 41.05 260.86 41.06 255.86 55.59 260.15" style="fill: #4e72b3"/>
                        <line x1="41.27" y1="255.67" x2="88.28" y2="270.07" style="fill: none;stroke: #b7d1eb;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                        <line x1="119.73" y1="237.97" x2="75.69" y2="253.2" style="fill: none;stroke: #4e72b3;stroke-miterlimit: 22.9255847930908;stroke-width: 0.566900014877319px"/>
                      </g>
                    </g>
                  </svg>
                </div>
              </v-col>
              <v-col>
                <div class="form-container">
                  <form @submit.prevent="login">
                    <label class="label-input" for="email">E-Mail *</label>
                    <v-row no-gutters class="input-container">
                      <input id="email" v-model="email" class="input-field" placeholder="jemand@beispiel.de" type="email" required>
                      <i class="fal fa-user input-icon"></i>
                    </v-row>
                    <label class="label-input" for="password">Passwort *</label>
                    <v-row no-gutters class="input-container">
                      <input id="password" v-model="password" class="input-field" placeholder="Passwort" type="password" required>
                      <i class="fal fa-key input-icon"></i>
                    </v-row>
                    <v-row>
                      <router-link class="text-link forgot-password" to="/reset-password">Passwort vergessen?</router-link>
                    </v-row>
                    <v-row>
                      <PrimaryButton id="login-button" buttonText="Anmelden" type="submit" />
                    </v-row>
                    <v-row>
                      <span class="register-text">Du möchtest einen Account?</span><router-link class="text-link register" to="/registration">Registrieren</router-link>
                    </v-row>
                  </form>
                </div>
                <v-row>
                  <span class="login-message">{{ message }}</span>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import PrimaryButton from '../../../components/PrimaryButton.vue';
import router from "../../../plugins/router";

export default {
  name: "LoginDesktop",
  components: {
    PrimaryButton
  },
  inject: [
    'api',
    'loadUser',
    'clearUser',
    'loggedIn',
  ],
  data() {
    return {
      smallWindow: false,
      windowHeight: '',
      email: '',
      password: '',
      message: ''
    }
  },
  mounted() {
    this.onResize()
  },
  methods: {
    login() {
      this.api.auth.login({
        email: this.email,
        password: this.password,
      }).then(() => {
        this.message = 'Login erfolgreich.';
        this.password = '';
        this.loadUser();
        setTimeout(() => {
          router.push('/');
        }, 2000);
      }).catch(() => {
        this.message = 'Login fehlgeschlagen. E-Mail oder Passwort falsch!';
      });
    },
    logout() {
      this.api.auth.logout().then(() => {
        this.clearUser();
        this.message = 'Logout erfolgreich';
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }).catch(() => {
        this.message = "Es ist etwas schief gelaufen."
      });
    },
    onResize () {
      this.smallWindow = window.innerWidth < 970;
      this.windowHeight = window.innerHeight - 220 + 'px';
    }
  }
}
</script>

<style scoped>
section {
  min-height: 650px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

#clouds {
  position: absolute;
  top: 15rem;
  right: 0;
  bottom: 0;
  left: -8rem;
  height: 50%;
  width: 100%;
  z-index: 1;
}

.logged-in-container {
  padding-top: 10rem;
}

.card-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-10%);
  z-index: 2;
}

.card {
  width: 900px;
  margin: 0 2rem;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.7) 45%, rgba(92, 89, 226, 0.5) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 12px 0 rgb(0, 0, 0, 0.3);
  border-radius: 25px;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 30px;
  font-weight: 800;
  color: rgb(255, 255, 255);
  z-index: 5;
}

.image-column {
  display: flex;
  justify-content: flex-end;
}

.image-container {
  width: 400px;
  height: 350px;
  margin-right: 3rem;
}

#login {
  position: relative;
  z-index: 2;
}

label {
  position: relative;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.input-field {
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.v-row {
  margin: 5px;
  padding-bottom: 1rem;
}

#register-button {
  margin: 2rem 0 0.25rem 0;
  width: 100%;
}

.text-link.login {
  font-size: 14px;
  margin-left: 1rem;
  transform: translateY(-1rem);
  z-index: 2;
}

.register-message {
  font-size: 14px;
  color: #d5d9ff;
  transition: 0.5s ease all;
}

#login-button {
  width: 100%;
}

#logout-button {
  padding: 0 5rem;
  margin: 0 auto;
}

label {
  position: relative;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.text-link.register,
.register-text,
.forgot-password {
  font-size: 14px;
}

.forgot-password {
  margin-left: 1rem;
  transform: translateY(-0.75rem);
}

.text-link.register {
  transform: translateY(-0.75rem);
}

.register-text {
  color: #ffffff;
  margin: 0 0.5rem 0 1rem;
  transform: translateY(-0.75rem);
}

.login-message {
  min-height: 21px;
  font-size: 14px;
  color: #d5d9ff;
  margin: 0 auto;
  transition: 0.5s ease all;
}

/* MEDIA QUERIES */
@media (min-width: 970px) {
  .header {
    font-size: 44px;
    font-weight: 800;
  }
}

</style>
