<template>
  <div class="footer">
    <v-container>
      <v-row no-gutters class="row-social-media">
        <a class="social-media-icon" target="_blank" href="https://www.youtube.com/channel/UCUeqSCvi8sNQOyySGue6KaQ"><i class="fab fa-youtube"></i></a>
        <a class="social-media-icon" target="_blank" href="https://t.me/lego_angebote"><i class="fab fa-telegram-plane"></i></a>
        <a class="social-media-icon" target="_blank" href="https://www.instagram.com/brickdex/"><i class="fab fa-instagram"></i></a>
        <a class="social-media-icon" target="_blank" href="https://discord.gg/ydQYy8tv63"><i class="fab fa-discord"></i></a>
      </v-row>
      <v-row no-gutters class="row-footer-links">
        <router-link class="footer-link" :to="{ name: 'About' }">Über brickdex</router-link>
        <router-link class="footer-link" :to="{ name: 'Imprint' }">Impressum</router-link>
        <router-link class="footer-link" :to="{ name: 'Policy' }">Datenschutz</router-link>
        <router-link class="footer-link" :to="{ name: 'Terms' }">Nutzungsbedingungen</router-link>
        <router-link class="footer-link" :to="{ name: 'Contact' }">Kontakt</router-link>
      </v-row>
      <v-row no-gutters class="row-disclaimer">
        <span class="disclaimer">* = Preise inkl. MwSt. und ggf. zzgl. Versandkosten. Der aktuelle Preis kann zwischenzeitlich höher sein.
          LEGO&reg; ist eine Marke der LEGO Gruppe, durch die die vorliegende Webseite weder gesponsert noch autorisiert oder unterstützt wird.</span>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
  components: {

  },
  data() {
    return {

    }
  },
}



</script>

<style scoped>

.footer {
  position: relative;
  height: 100%;
  background: rgba( 0, 0, 0, 0.80 );
  bottom: 0;
  width: 100%;
  z-index: 90;
}

.row-social-media,
.row-footer-links,
.row-disclaimer {
  align-content: center;
  justify-content: center;
}

.row-social-media {
  height: 100px;
}

.row-disclaimer {
  padding: 1rem 0;
}

.social-media-icon {
  color: rgb(252, 179, 236);
  font-size: 32px;
  margin: 15px;
  transition: 0.5s ease all;
  z-index: 99;
}

  .social-media-icon:hover {
    color: rgb(255, 150, 232);
    text-shadow: 2px 2px 4px rgba(28, 8, 77, 0.3);
    transform: translateY(-5px);
  }

.footer-link {
  color: rgb(223, 223, 223);
  font-size: 12px;
  margin: 12px;
  letter-spacing: 0.7px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}

  .footer-link:hover {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }

.disclaimer {
  color: rgb(136, 136, 136);
  font-size: 9px;
  letter-spacing: 0.7px;
}

</style>
