<template>
  <div class="dialog-overlay">
    <div class="dialog-card">
      <div class="dialog-header">
        <span class="header-text header_mobile">Zur Collection hinzufügen</span>
        <v-spacer></v-spacer>
        <i class="fal fa-times icon close" @click="close()"></i>
      </div>
      <template v-if="loggedIn.value">
        <v-row no-gutters class="mb-4">
          <span class="subheader-text">Gib im Suchfeld die Setnummer oder den Namen des Sets ein.</span>
        </v-row>
        <form @submit.prevent="addToCollection()">
          <div class="pb-2 px-6 dialog-body">
            <v-row class="pb-2" no-gutters >
              <v-col cols="8">
                <div class="input-search-container">
                  <v-text-field v-model="searchValue" ref="input" clearable class="input_field" color="#60ffe4" label="Suche dein Set" @input="checkInput" type="search" placeholder="10255-1 oder Stadtleben" hint="Gib mind. drei Zeichen ein" density="compact" required></v-text-field>
                  <div v-show="suggestionsActive" class="suggestions-container">
                    <div class="card-add-search">
                      <span v-show="searchedSets.length > 0" :style="'color:' + suggestionLabelColor" class="header-text suggestion">Sets</span>
                      <div v-for="set in searchedSets" :key="set" class="sets-suggestion-list">
                        <div @click="setToAdd(set)" class="suggestion-item">
                          <div class="image-container-search">
                            <v-img class="image" :src="set.image"></v-img>
                          </div>
                          <div class="item-label">
                            <span class="label number">{{ set.setnumber }}</span>
                            <span class="label">{{ set.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="pl-4 suggestion-item-search">
                  <div class="image-container-result">
                    <img @click="removeSet()" class="image" :src="searchResultImage">
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-2" ></v-divider>
            <v-text-field v-model.number="amount" clearable class="input_field" color="#60ffe4" label="Anzahl" append-inner-icon="mdi-counter" type="number" min="1" pattern="[0-9]" step="1" placeholder="1" density="compact" required></v-text-field>
            <v-combobox v-model="condition" :items="conditions" clearable hide-details class="mb-4 input_field" color="#60ffe4" label="Zustand" append-inner-icon="mdi-gift-open" placeholder="muss auswählt werden" density="compact" theme="dark" required></v-combobox>
            <v-text-field v-model="purchase_date" clearable class="input_field" color="#60ffe4" label="Kaufdatum" type="date" density="compact"></v-text-field>
            <v-text-field v-model="retailer" clearable class="input_field" color="#60ffe4" append-inner-icon="mdi-storefront-outline" label="Händler" placeholder="Amazon" density="compact"></v-text-field>
            <v-text-field v-model="price_payed" clearable class="input_field" color="#60ffe4" label="Kaufpreis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
            <v-text-field v-model="storage" clearable class="input_field" color="#60ffe4" append-inner-icon="mdi-library-shelves" label="Lagerplatz" placeholder="Regal 1 Fach 2" density="compact"></v-text-field>
            <div class="dialog-action">
              <PrimaryButton @click="close()" class="cancel-button" buttonText="Abbrechen" />
              <PrimaryButton faIconClass="far fa-plus" type="submit" />
            </div>
          </div>
        </form>
      </template>
      <template v-else>
        <v-container>
          <v-row no-gutters>
            <div class="hint-card">
              <i class="fal fa-info icon-hint"></i>
            </div>
          </v-row>
          <v-row no-gutters>
            <span class="hint-text">
              Um Deine Collection anlegen und verwalten zu können, musst Du
              <router-link class="text-link" to="/registration">registriert</router-link>
              sein.
            </span>
            <span class="hint-text">
              Hast Du bereits einen Account, dann
              <router-link class="text-link" to="/login">melde</router-link>
              Dich einfach an.
            </span>
          </v-row>
        </v-container>
      </template>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../PrimaryButton.vue';
import moment from 'moment/min/moment-with-locales';

export default {
  name: 'AddSetDialogMobile',
  components: {
    PrimaryButton,
  },
  props: ['set_data'],
  inject: [
    'api',
    'loggedIn',
    'localCurrency'
  ],
  data() {
    return {
      searchValue: '',
      searchedSets: [],
      suggestionsActive: false,
      suggestionWidth: null,
      searchResult: null,
      searchResultSet: '',
      searchResultName: '',
      searchResultImage: '',

      amount: null,
      condition: null,
      purchase_date: null,
      retailer: null,
      price_payed: null,
      discount: null,
      storage: null
    }
  },
  watch: {
    searchValue(value) {
      if (!value) {
        this.closeIcon = false;
        this.searchResultSet = '';
        this.searchResultName = '';
        this.searchResultImage = '';
        this.suggestionsActive = false;
      }
    }
  },
  created() {
    this.conditions = [
      { title: "Neu, versiegelt" , value: "new_sealed" },
      { title: "Neu, leichte Schäden" , value: "new_minor_damages" },
      { title: "Neu, starke Schäden" , value: "new_major_damages" },
      { title: "Gebraucht, Tüten verschlossen" , value: "used_bags_closed" },
      { title: "Gebraucht, gebaut, nicht bespielt" , value: "used_build_not_played" },
      { title: "Gebraucht, bespielt" , value: "used_played" },
      { title: "Gebraucht, Karton fehlt" , value: "used_box_missing" },
      { title: "Gebraucht, nicht komplett" , value: "used_incomplete" }
    ];
  },
  mounted() {
    this.checkWidth();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async searchSets() {
      this.searchedSets = [];
      this.minifigures = [];
      this.suggestionsActive = true;

      const { raw } = await this.api.transport.get('search?search=' + this.searchValue + '&fields=*.*,images.directus_files_id.*');

      raw.sets.forEach(set => {
        let result = {
          setnumber: set?.set ?? '-',
          name: this.mapNameToLocal(set.name, 'de-DE') || '-',
          retail_price: this.mapRetailPriceToLocal(set.retail_price, 'eur') || '',
          image: this.getMainImage(set.header_image, set.images)
        };
        this.searchedSets.push(result);
      });
    },
    async addToCollection() {
      let newCollection = [];
      const convertedDate = this.convertGermanDateString(this.purchase_date);

      try {
        if (this.amount > 1) {
        for (let i = 0; i < this.amount; i++ ) {
          let newItem = {
            condition: this.conditions.find(el => el.text === this.condition)?.value ?? '',
            price_payed: this.price_payed?.toString()?.replace(',', '.') ?? '',
            purchase_date: convertedDate,
            retailer: this.retailer,
            discount: this.calcDiscount(),
            storage: this.storage,
            set: this.searchResultSet
          }
          newCollection.push(newItem);
        }
        await this.api.items('collections').createMany(newCollection);
      }
      else {
        await this.api.items('collections').createOne({
          condition: this.conditions.find(el => el.text === this.condition)?.value ?? '',
          price_payed: this.price_payed?.toString()?.replace(',', '.') ?? '',
          purchase_date: convertedDate,
          retailer: this.retailer,
          discount: this.calcDiscount(),
          storage: this.storage,
          set: this.searchResultSet
        });
      }
      this.close();
      window.location.reload();
      }
      catch {
        console.error(this.searchValue + ' konnte nicht hinzugefügt werden! Es konnte leider nicht in der Datenbank gefunden werden.');
      }
    },
    debounceSearch(searchValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchSets(searchValue);
      }, 500)
    },
    checkInput() {
      if (this.searchValue.length > 0) {
        this.closeIcon = true;
        this.suggestionsActive = true;
        if (this.searchValue.length > 2) {
          this.debounceSearch(this.searchValue);
          return;
        }
        return;
      }
      this.closeIcon = false;
      this.suggestionsActive = false;
      return;
    },
    clearInput() {
      this.searchValue = '';
      this.closeIcon = false;
      this.suggestionsActive = false;
      this.searchResultSet = '';
      this.searchResultName = '';
      this.searchResultImage = '';
    },
    setToAdd(set) {
      this.searchValue = set.setnumber + ': ' + set.name;
      this.searchResult = set || '';
      this.searchResultSet = set.setnumber || '';
      this.searchResultName = set.name || '';
      this.searchResultImage = set.image || '';
      this.suggestionsActive = false;
    },
    removeSet() {
      this.searchValue = '';
      this.closeIcon = false;
      this.suggestionsActive = false;
      this.searchResultSet = '';
      this.searchResultName = '';
      this.searchResultImage = '';
    },
    checkWidth() {
      let width = this.$refs.input.clientWidth;
      this.suggestionWidth = width + 'px';
    },
    getMainImage(header_image, images) {
      if (header_image?.id) { return process.env.VUE_APP_API_IMG_PATH + header_image?.id + '?height=200' ?? ''; }
      if (images) {
        if (images[1]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[1]?.directus_files_id.id + '?height=200' ?? ''; }
        if (images[0]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[0]?.directus_files_id.id + '?height=200' ?? ''; }
      }
      return '';
    },
    convertGermanDateString(dateString) {
      if (dateString) {
        let day = dateString.split('-')[2];
        let month = dateString.split('-')[1];
        let year = dateString.split('-')[0];

        if (day.length == 2 && month.length == 2 && year.length == 4) {
          let m = moment(day + '.' + month + '.' + year + '12:00', 'DD MM YYYY HH:mm Z', 'de');
          return m.toISOString();
        }
        else return null;
      }
      else return null;
    },
    calcDiscount() {
      if (this.searchResult.retail_price && this.searchResult.retail_price !== '-' && this.searchResult.retail_price !== '0.00' && this.price_payed) {
        try {
          return parseFloat(((1-(this.price_payed / this.searchResult.retail_price))*100).toFixed(2));
        }
        catch { return null }
      }
      else return null;
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    }
  }
}
</script>

<style scoped>

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog-card {
  position: relative;
  width: 90%;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.dialog-header-text {
  margin-left: 1.5rem;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.subheader-text {
  margin-left: 1.5rem;
  font-size: 12px;
  color: #ffffff;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}

.hint-card {
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-content: center;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.3) 100%);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.icon-hint {
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;
  font-size: 32px;
  color: rgb(255, 255, 255);
}

.hint-text {
  width: 80%;
  font-size: 14px;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.text-link {
  font-size: 14px;
}

.dialog-action {
  margin: 0 auto;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 0.5rem;
  color: #5C5DD8;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%);
}

.input-search-container {
  display: flex;
  align-content: center;
  width: 100%;
}

.close-icon {
  width: 20px;
  margin-top: 10px;
  transform: translateX(-30px);
  z-index: 3;
}

.close-icon i {
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
}

.suggestions-container {
  position: absolute;
  transform: translateY(50px);
  z-index: 80;
}

.card-add-search {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border-radius: 25px;
  transition: 0.5s ease all;
}

.suggestion-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.suggestion-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.suggestion-item-search {
  display: flex;
  align-items: center;
}

.sets-suggestion-list:last-of-type,
.minifig-suggestion-list:last-of-type {
  padding-bottom: 1rem;
}

.item-label{
  display: flex;
  flex-direction: column;
}

.image-container-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 44px;
  height: 44px;
  margin: 0.3rem 0 0.3rem 1.5rem;
  border-radius: 10px;
}

.image {
  display: flex;
  margin: 0 auto;
  height: 100%;
  cursor: pointer;
  transform: scale(3);
  transition: 0.5s ease all;
}

.image-container-result {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 150px;
  height: 44px;
  border-radius: 10px;
}

.header-text.suggestion {
  color: #60ffe4;
  margin: 0.5rem 0;
  padding-left: 1.5rem;
}

.label {
  color: #ffffff;
  font-size: 12px;
  padding-left: 1rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: 0.5s ease all;
}

.number {
  font-weight: 800
}

::-webkit-scrollbar-track {
  margin: 1.5rem;
}

.input_field {
  margin-bottom: -8px;
}

.header_mobile {
  font-size: 14px;
}

</style>
