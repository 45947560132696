<template>
    <div class="ml-4 mr-2 card_set_card">
      <div class="image_container_set_card" :style="imageContainerStyleObject">
        <template v-if="image">
          <v-img v-if="imageCover" class="image_set_card" cover :src="image" :lazy-src="image"></v-img>
          <v-img v-else class="image_set_card" :src="image" :lazy-src="image"></v-img>
        </template>
        <template v-else>
          <template v-if="isItemMinifigure">
            <v-icon id="small_image_alt" icon="mdi-account-circle"></v-icon>
          </template>
          <template v-else-if="isItemSet">
            <v-icon id="small_image_alt" icon="mdi-package"></v-icon>
          </template>
          <template v-else>
            <v-icon id="small_image_alt" icon="mdi-cloud-question"></v-icon>
          </template>
        </template>
      </div>

      <div class="text_container_set_card" :style="textContainerStyleObject">
        <div class="card_main_set_card">
          <span class="card_set_set_card" :style="cardSetStyleObject">{{ set }}</span>
          <span class="card_name_set_card" :style="cardNameStyleObject">{{ name }}</span>
          <template v-if="typeof retailPrice === 'object' && retailPrice !== null">
            <span class="card_price_set_card" :style="cardPriceStyleObject">{{ formatRetailPrice(retailPrice) }} </span>
          </template>
          <template v-else>
            <span v-if="retailPrice" class="card_price_set_card" :style="cardPriceStyleObject">{{ retailPrice }} {{ mapCurrencyUnit(localCurrency) }}</span>
          </template>
        </div>
        <div class="card_footer_set_card">
          <span class="card_year_set_card" :style="cardYearStyleObject">{{ year }}</span>
          <span class="card_theme_set_card">{{ theme }}</span>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'SetCard',
  props: ['image', 'theme', 'year', 'set', 'name', 'retailPrice',
  'priceActive', 'textContainerWidth', 'textContainerHeight', 'textContainerBackground',
  'imageContainerWidth', 'imageContainerHeight', 'imageCover', 'cardSetFontsize', 'cardNameMinMaxHeight', 'cardNameFontsize', 'cardPriceFontsize', 'cardPriceColor',
  'cardYearFontsize', 'borderRadius', 'transformTextContainer', 'lineClamp', 'isItemMinifigure', 'isItemSet'],
  inject: [
    'localCurrency',
    'localLanguage'
  ],
  computed: {
  },
  components: {

  },
  data() {
    return {
      imageContainerStyleObject: {
        width: this.imageContainerWidth,
        height: this.imageContainerHeight,
        borderRadius: this.borderRadius
      },
      textContainerStyleObject: {
        width: this.textContainerWidth,
        height: this.textContainerHeight,
        borderRadius: this.borderRadius,
        background: this.textContainerBackground,
        transform: this.transformTextContainer
      },
      cardSetStyleObject: {
        fontSize: this.cardSetFontsize
      },
      cardNameStyleObject: {
        fontSize: this.cardNameFontsize,
        minHeight: this.cardNameMinMaxHeight,
        maxHeight: this.cardNameMinMaxHeight,
        webkitLineClamp: this.lineClamp
      },
      cardPriceStyleObject: {
        fontSize: this.cardPriceFontsize,
        color: this.cardPriceColor
      },
      cardYearStyleObject: {
        fontSize: this.cardYearFontsize,
      }
    }
  },
  mounted() {

  },
  methods: {
    formatRetailPrice(priceObject) {
      try {
        if (priceObject) {
          let localRetailPrice = priceObject?.filter(el => el.price_code === this.localCurrency)[0]?.price ?? null;
          if (localRetailPrice) {
            localRetailPrice = localRetailPrice.toFixed(2);
            return localRetailPrice + ' ' + this.mapCurrencyUnit(this.localCurrency);
          }
        }
        return '-';
      }
      catch(err) { console.warn(err, 'Formatting of the retail price not possible!'); }
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
  }
}

</script>

<style scoped>

.card_set_card {
  cursor: pointer;
  display: grid;
  justify-content: center;
  transition: 0.5s ease transform;
}

.card_set_card:hover .image_set_card {
  transform: scale(1.2);
}

.image_container_set_card {
  position: relative;
  display: flex;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 20px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  z-index: 1;
  transition: 0.5s ease transform;
}

.image_set_card {
  height: 100%;
  transition: 0.5s ease transform;
}

.text_container_set_card {
  position: relative;
  height: 200px;
  width: 200px;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.6) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transform: translateY(-20px);
}

.card_main_set_card {
  position: relative;
  display: block;
  top: 20%;
  left: 10%;
  right: 10%;
  height: 67%;
  width: 85%;
  overflow: hidden;
  transition: .5s ease all;
}

.card_set_set_card {
  position: relative;
  display: block;
  font-size: 14px;
  color: #ffffff;
  font-weight: 300;
}

.card_name_set_card {
  position: relative;
  display: block;
  min-height: 2.8rem;
  max-height: 2.8rem;
  font-size: 14px;
  color: #ffffff;
  overflow: hidden;
  font-weight: 800;
  line-height: 1.05;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.card_price_set_card {
  position: absolute;
  display: block;
  width: 90%;
  bottom: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 800;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
}

.card_footer_set_card {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0;
  height: 15%;
  width: 100%;
}

.card_year_set_card {
  position: absolute;
  left: 10%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
}

.card_theme_set_card {
  position: absolute;
  right: 10%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.2);
}

</style>
