<template>
  <section :style="'min-height:' + windowHeight">
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row_search_mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Dein gesuchtes Set nicht dabei?" />
      </v-row>
    </v-container>

    <v-container>
      <template v-if="isMobile">
        <v-row justify="center" no-gutters>
          <h3 class="mt-n2 mb-4">Top 100 Suchergebnisse für: <span class="search-string">{{ routeSearchString }}</span></h3>
        </v-row>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title>Filter anzeigen</v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row no-gutters class="dialog-body">
                <v-combobox v-model="queryTheme" :items="themes" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" append-inner-icon="mdi-shape" label="Themenwelt" theme="dark"></v-combobox>
                <v-combobox v-model="queryRelease" :items="years" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="Release" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
                <template v-if="queryTheme || queryRelease || queryEol || queryRetailPriceStart || queryRetailPriceEnd || querySubtheme || queryMinifigures || queryDesigner || queryAvailability">
                  <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                </template>
                <template v-else>
                  <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                </template>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-else>
        <v-row justify="center" no-gutters>
          <h2 class="mb-8">Top 100 Suchergebnisse für: <span class="search-string">{{ routeSearchString }}</span></h2>
        </v-row>
        <v-row no-gutters class="mb-4 row_filters">
          <v-col cols="4" class="mx-2">
            <v-combobox v-model="queryTheme" :items="themes" clearable hide-details class="input_field" color="#60ffe4" append-inner-icon="mdi-shape" label="Themenwelt" theme="dark"></v-combobox>
          </v-col>
          <v-col cols="3" class="mx-2">
            <v-combobox v-model="queryRelease" :items="years" clearable hide-details class="input_field" color="#60ffe4" label="Release" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
          </v-col>
          <v-col cols="3" class="mx-2">
            <template v-if="queryTheme || queryRelease">
              <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
            </template>
            <template v-else>
              <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-container>

    <template v-if="loadingActive">
      <div class="card_body_loader">
        <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
      </div>
    </template>
    <template v-else>
      <v-container class="results-container" fluid>
        <v-row no-gutters class="row-results">

          <template v-if="sets.length < 1 && minifigures.length < 1">
            <span class="hint-text">Leider konnte ich so, nichts zu Deiner Suchanfrage
              <span class="search-string">{{ routeSearchString }}</span>
              <template v-if="queryFilterTheme"> in <span class="search-string">{{ queryFilterTheme }}</span></template>
              <template v-if="queryFilterYearRelease"> aus <span class="search-string">{{ queryFilterYearRelease }}</span></template>
              finden.
            </span>
          </template>

          <template v-if="sets.length > 0">
            <div v-for="item in sets" :key="item.key" class="results">
              <template v-if="isMobile">
                <router-link class="link" :to="{ path: '/items/sets/' + item.set }">
                  <SetCard
                  :set="`${item?.set ?? '-'}`"
                  :name="`${item?.name[0]?.name ?? '-'}`"
                  :year="convertReleaseDate(item.release_date)"
                  :image="getSetOverviewImage(item?.header_image, item?.images)"
                  :imageContainerWidth="'70px'"
                  :imageContainerHeight="'70px'"
                  :textContainerWidth="'90px'"
                  :textContainerHeight="'80px'"
                  :cardSetFontsize="'10px'"
                  :cardNameFontsize="'12px'"
                  :cardYearFontsize="'10px'"
                  :cardNameMinMaxHeight="'1.65rem'"
                  :cardPriceFontsize="'12px'"
                  :cardPriceColor="'#60ffe4'"
                  :borderRadius="'15px'"
                  :transformTextContainer="'translateY(-10px)'"
                  :isItemSet="true"
                  :lineClamp="2"/>
                </router-link>
              </template>
              <template v-else>
                <router-link class="link" :to="{ path: '/items/sets/' + item.set }">
                  <SetCard
                  :set="`${item?.set ?? '-'}`"
                  :name="`${item?.name[0]?.name ?? '-'}`"
                  :theme="`${item?.theme?.name ?? '-'}`"
                  :year="convertReleaseDate(item.release_date)"
                  :image="getSetOverviewImage(item?.header_image, item?.images)"
                  :imageContainerWidth="'160px'"
                  :imageContainerHeight="'160px'"
                  :textContainerWidth="'200px'"
                  :textContainerHeight="'150px'"
                  :cardSetFontsize="'14px'"
                  :cardNameFontsize="'14px'"
                  :cardPriceFontsize="'20px'"
                  :cardPriceColor="'#60ffe4'"
                  :borderRadius="'25px'"
                  :isItemSet="true"/>
                </router-link>
              </template>
            </div>
          </template>

          <template v-if="minifigures.length > 0">
            <div v-for="item in minifigures" :key="item.key" class="results">
              <template v-if="isMobile">
                <router-link class="link" :to="{ path: '/items/minifigures/' + item.number }">
                  <SetCard
                  :set="`${item?.number ?? '-'}`"
                  :name="`${item?.name ?? '-'}`"
                  :year="convertReleaseDate(item.release_date)"
                  :image="getSetOverviewImage(null, item?.images)"
                  :imageContainerWidth="'70px'"
                  :imageContainerHeight="'70px'"
                  :textContainerWidth="'90px'"
                  :textContainerHeight="'80px'"
                  :cardSetFontsize="'10px'"
                  :cardNameFontsize="'12px'"
                  :cardYearFontsize="'10px'"
                  :cardNameMinMaxHeight="'1.65rem'"
                  :cardPriceFontsize="'12px'"
                  :cardPriceColor="'#60ffe4'"
                  :borderRadius="'15px'"
                  :transformTextContainer="'translateY(-10px)'"
                  :isItemMinifigure="true"
                  :lineClamp="2"/>
                </router-link>
              </template>
              <template v-else>
                <router-link class="link" :to="{ path: '/items/minifigures/' + item.number }">
                  <SetCard
                  :set="`${item?.number ?? '-'}`"
                  :name="`${item?.name ?? '-'}`"
                  :year="convertReleaseDate(item.release_date)"
                  :image="getSetOverviewImage(null, item?.images)"
                  :imageContainerWidth="'160px'"
                  :imageContainerHeight="'160px'"
                  :textContainerWidth="'200px'"
                  :textContainerHeight="'150px'"
                  :cardSetFontsize="'14px'"
                  :cardNameFontsize="'14px'"
                  :cardPriceFontsize="'20px'"
                  :cardPriceColor="'#60ffe4'"
                  :borderRadius="'25px'"
                  :isItemMinifigure="true"/>
                </router-link>
              </template>
            </div>
          </template>


        </v-row>
      </v-container>
    </template>
  </section>
</template>

<script>
import Search from '../components/Search.vue';
import SearchMobile from '../components/SearchMobile.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import SetCard from '../components/SetCard.vue';

export default {
  name: 'SearchResults',
  components: {
    PrimaryButton,
    Search,
    SearchMobile,
    SetCard,
  },
  inject: [
    'api',
    'isMobile',
    'localCurrency',
    'localLanguage'
  ],
  data() {
    return {
      mobile: false,
      theme: null,
      year: null,
      sets: [],
      minifigures: [],
      themes: [],
      // subthemes: [],
      years: [],
      currentTheme: null,
      queryTheme: this.$route.query.theme || null,
      // currentSubtheme: null,
      // querySubtheme: this.$route.query.subtheme || null,
      currentYearRelease: null,
      queryRelease: this.$route.query.yearRelease || null,
      set_data: [],
      loadingActive: false,
      maxPageCount: '',
      allSetsCount: '',
    }
  },
  watch: {
    routeSearchString(value) {
      if (value) { this.getSets(); }
    },
    queryFilterTheme() {
      this.queryTheme = this.$route.query.theme || null,
      this.getSets();
    },
    // queryFilterSubtheme() {
    //   this.querySubtheme = this.$route.query.subtheme || null,
    //   this.getSets();
    // },
    queryFilterYearRelease() {
      this.queryRelease = this.$route.query.yearRelease || null,
      this.getSets();
    },
    queryTheme(value) {
      if (value) {
        // check input length > 2; debounce query fire
        this.checkInput(value, 2, 'theme');
      }
      else {
        this.queryTheme = null;
        this.currentTheme = null;
        this.pushQuery();
      }
    },
    // querySubtheme(value) {
    //   if (value) {
    //     // check input length > 2; debounce query fire
    //     this.checkInput(value, 2, 'subtheme');
    //   }
    //   else {
    //     this.querySubtheme = null;
    //     this.currentSubtheme = null;
    //     this.pushQuery();
    //   }
    // },
    queryRelease(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'yearRelease');
      }
      else {
        this.queryRelease = null;
        this.currentYearRelease = null;
        this.pushQuery();
      }
    },
  },
  computed: {
    routeSearchString() {
      return this.$route.params.searchString || null;
    },
    queryFilterTheme() {
      return this.$route.query.theme || null;
    },
    // queryFilterSubtheme() {
    //   return this.$route.query.subtheme || '';
    // },
    queryFilterYearRelease() {
      return this.$route.query.yearRelease || null;
    },
  },
  created() {
    this.getSets();
    this.getThemes();
    // this.getSubthemes();
    this.getYears();
    this.windowHeight = window.screen.availHeight + 'px';
  },
  unmounted() {

  },
  methods: {
    async getSets() {
      this.loadingActive = true;
      let responseSets = [];
      let responseMinifigs = [];
      this.sets = [];
      this.minifigures = [];

      const { raw } = await this.api.transport.get('search?search=' + this.routeSearchString + '&fields=*.*,images.directus_files_id.*,minifigures.sets.sets_set.*&sort=-release_date&limit=100');
      if (raw) {
        this.loadingActive = false;
        responseSets = raw.sets;
        responseMinifigs = raw.minifigures;

        // if only one item is found, open setDetail view
        if (responseSets.length == 1) { this.$router.push({ path: '/items/sets/' + responseSets[0].set }) }
        if (responseMinifigs.length == 1) { this.$router.push({ path: '/items/minifigures/' + responseMinifigs[0].number }) }

        if (this.queryFilterTheme && this.queryFilterYearRelease === null) {
          this.sets = responseSets.filter(el => el.theme?.name?.includes(this.queryFilterTheme) ?? '');
          this.minifigures = responseMinifigs.filter(el => el.theme?.name?.includes(this.queryFilterTheme) ?? '');
        }
        else if (this.queryFilterTheme === null && this.queryFilterYearRelease) {
          this.sets = responseSets.filter(el => el.release_date?.includes(this.queryFilterYearRelease) ?? '');
          // this.minifigures = responseMinifigs.filter(el => el.release_date?.includes(this.queryFilterYearRelease) ?? '');
        }
        else if (this.queryFilterTheme && this.queryFilterYearRelease) {
          const setsByTheme = responseSets.filter(el => el.theme?.name?.includes(this.queryFilterTheme) ?? '');
          const setsByYear = setsByTheme.filter(el => el.release_date?.includes(this.queryFilterYearRelease) ?? '');
          this.sets = setsByYear;
        }
        else {
          this.sets = raw.sets;
          this.minifigures = raw.minifigures;
        }
      }
    },
    async getThemes() {
      const res = await this.api.items('themes?fields=*.*&limit=-1').readByQuery();
      let sortedThemesData = this.sortArrayByName(res.data);
      sortedThemesData.forEach(theme => {
        this.themes.push(theme.name);
      });
    },
    // async getSubthemes() {
    //   const res = await this.api.items('subthemes?fields=*.*&limit=-1').readByQuery();
    //   let sortedSubthemesData = this.sortArrayByName(res.data);
    //   sortedSubthemesData.forEach(subtheme => {
    //     this.subthemes.push(subtheme.name);
    //   });
    // },
    getYears() {
      const currentYearRelease = new Date().getFullYear();
      for (let i = currentYearRelease; i > 1970; i--) {
        this.years.push(i.toString());
      }
    },
    getRetailPrice(retail_price) {
      if (retail_price) { return '(UVP ' + retail_price + '€)' }
      return '';
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    convertReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
    resetFilter() {
      this.queryTheme = null;
      this.currentTheme = null;
      this.queryRelease = null;
      // this.currentSubtheme = null;
      // this.querySubtheme = null;
      this.$router.push({ path: '/search/' + this.$route.params.searchString })
    },
    pushRoute(set) {
      this.$router.push({ path: '/items/sets/' + set })
    },
    pushQuery() {
      let queryObject = {}
      if (this.currentTheme) { queryObject['theme'] = this.currentTheme }
      // if (this.currentSubtheme) { queryObject['subtheme'] = this.currentSubtheme }
      if (this.currentYearRelease) { queryObject['yearRelease'] = this.currentYearRelease }
      this.$router.push({ path: '/search/' + this.$route.params.searchString, query: queryObject});
    },
    sortArrayByName(array) {
      return array.sort((a, b) => (a.name > b.name) ? 1 : -1) ?? undefined;
    },
    debounceInputTheme(themeValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentTheme = themeValue;
        this.pushQuery();
      }, 500)
    },
    // debounceInputSubtheme(subthemeValue) {
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.currentSubtheme = subthemeValue;
    //     this.pushQuery();
    //   }, 500)
    // },
    debounceInputRelease(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearRelease = yearValue;
        this.pushQuery();
      }, 500)
    },
    checkInput(inputValue, minChars, filter) {
      if (inputValue.length > 0) {
        if (inputValue.length > minChars) {
          if (filter === 'theme') this.debounceInputTheme(inputValue);
          // if (filter === 'subtheme') this.debounceInputSubtheme(inputValue);
          if (filter === 'yearRelease') this.debounceInputRelease(inputValue);
          return;
        }
        return;
      }
      return;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  height: 100%;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  justify-content: center;
  z-index: 3;
}

.row_search_mobile {
  position: relative;
  width: 100%;
  padding-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.search-string {
  color: #60ffe4;
}

.row_filters {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.row-results {
  position: relative;
  width: 100%;
  justify-content: center;
}

.results {
  margin: 0 auto;
}

.hint-text {
  color: rgb(255, 255, 255);
}

.card_body_loader {
  margin-top: 5rem;
}
.loader {
  width: 50%;
}

.v-expansion-panel {
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 10px;
  color: #ffffff;
}

.v-expansion-panel-title {
  font-size: 12px !important;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}

</style>
