<template>
  <div class="dialog-overlay">
    <div class="dialog-card">
      <div class="dialog-header">
        <span class="header-text">Eintrag löschen</span>
        <v-spacer></v-spacer>
        <i class="fal fa-times icon close" @click="close()"></i>
      </div>
      <form @submit.prevent="deleteCopy()">
        <div class="dialog-body">
          <v-row no-gutters>
            <span class="dialog-text">Soll der Eintrag wirklich dauerhaft aus deinem Portfolio gelöscht werden? Der Vorgang kann nicht rückgängig gemacht werden.</span>
          </v-row>
          <div class="dialog-action">
            <PrimaryButton @click="close()" class="cancel-button" buttonText="Abbrechen" />
            <PrimaryButton faIconClass="far fa-check" type="submit" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../PrimaryButton.vue';

export default {
  name: 'DeleteCollectionEntryDesktop',
  components: {
    PrimaryButton
  },
  props: ['setPortfolio', 'id'],
  inject: [
    'api'
  ],
  data() {
    return {

    }
  },
  watch: {
  },
  created() {
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    close() {
      this.$emit('close');
    },
    async deleteCopy() {
      await this.api.items('collections').deleteOne(this.id);
      if (this.setPortfolio.length == 1) { this.$router.push({ path: '/usercollection/'}); }
      else { this.$router.go(); }
    },
  }
}
</script>

<style scoped>

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog-card {
  position: relative;
  width: 90%;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}

.dialog-text {
  color: rgb(255, 255, 255);
  margin-left: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 14px;
}

.dialog-action {
  margin: 0 auto;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 0.5rem;
  color: #5C5DD8;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%);
}

</style>
