<template>
  <section>
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row-search-mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
      </v-row>
    </v-container>

    <template v-if="loggedIn.value">
      <v-container>
        <v-row no-gutters class="row">
          <div class="mb-6 card_header">
            <v-tabs v-model="routeThemeShortcut" class="mb-2 button_tab">
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/all' }"><v-tab value="all">Alle</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/sw' }"><v-tab value="sw">Star Wars</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/hp' }"><v-tab value="hp">Harry Potter</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/sh' }"><v-tab value="sh">Super Heroes</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/avt' }"><v-tab value="avt">Avatar</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/njo' }"><v-tab value="njo">Ninjago</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/col' }"><v-tab value="col">CMF</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/cas' }"><v-tab value="cas">Castle</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/iaj' }"><v-tab value="iaj">Indiana Jones</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/lor' }"><v-tab value="lor">Herr der Ringe</v-tab></router-link>
              <router-link class="button_tab" :to="{ path: '/insights/ranking/minifigures/poc' }"><v-tab value="poc">Fluch der Karibik</v-tab></router-link>
            </v-tabs>
            <v-divider class="mt-n2"></v-divider>
            <template v-if="isMobile">
              <p class="mx-4 mt-4 normal_text text_mobile">Im Folgenden findest Du eine Liste der teuersten LEGO {{ convertTheme(routeTheme) }} Minifiguren, basierend auf den Verkaufszahlen der letzten 6 Monate.
              Du kannst dabei zwischen dem Zustand neu oder gebraucht wechseln.
                <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die Daten für die Preise der Minifiguren sind aktuell noch nicht komplett, daher ist das Ranking noch nicht final!">
                  <i class="fas fa-exclamation-circle info_tooltip"></i>
                </Popper>
              </p>
            </template>
            <template v-else>
              <p class="mx-6 mt-4 normal_text">Im Folgenden findest Du eine Liste der teuersten LEGO {{ convertTheme(routeTheme) }} Minifiguren, basierend auf den Verkaufszahlen der letzten 6 Monate.
              Du kannst dabei zwischen dem Zustand neu oder gebraucht wechseln.
                <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die Daten für die Preise der Minifiguren sind aktuell noch nicht komplett, daher ist das Ranking noch nicht final!">
                  <i class="fas fa-exclamation-circle info_tooltip"></i>
                </Popper>
              </p>
            </template>
            <template v-if="isMobile">
              <v-row class="row_ranking" no-gutters>
                <div>
                  <span v-if="minifigureConditionSwitch" class="mr-1 label-input">neu</span>
                  <span v-else class="mr-1 label-input">gebraucht</span>
                </div>
                <Toggle v-model="minifigureConditionSwitch" class="mt-1 mr-4"/>
              </v-row>
            </template>
            <template v-else>
              <v-row class="row_ranking" no-gutters>
                <v-col cols="1">
                  <router-link class="ml-2" :to="{ path: '/insights' }"><i class="fal fa-arrow-left icon"></i></router-link>
                </v-col>
                <v-col cols="auto" class="mr-16"></v-col>
                <v-col cols="1">
                  <span class="ml-6 ranking_bricklink_header">#</span>
                </v-col>
                <v-col cols="3">
                  <span class="ml-4 mr-6 ranking_bricklink_header">Name</span>
                </v-col>
                <v-col cols="1">
                  <span class="mr-6 ranking_bricklink_header">Release</span>
                </v-col>
                <v-col cols="1">
                  <span class="mr-6 ranking_bricklink_header">Ø Preis</span>
                </v-col>
                <v-col cols="1">
                  <span class="mr-6 ranking_bricklink_header">Min. Preis</span>
                </v-col>
                <v-col cols="1">
                  <span class="ranking_bricklink_header">Max. Preis</span>
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-row justify="end" no-gutters>
                    <div>
                      <span v-if="minifigureConditionSwitch" class="mr-1 label-input">neu</span>
                      <span v-else class="mr-1 label-input">gebraucht</span>
                    </div>
                    <Toggle v-model="minifigureConditionSwitch" class="mt-1 mr-4"/>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </div>

          <template v-if="loadingActiveMinifigures">
            <div class="card_body_loader">
              <v-progress-linear :active="loadingActiveMinifigures" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
            </div>
          </template>
          <template v-else>
            <template v-if="isMobile">
              <div v-for="(minifigureItem, index) in topMinifigures" :key="minifigureItem.minifigures_number?.number" class="mb-4 card_main mobile">
                <router-link :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">
                  <v-row align="center" class="row_ranking mobile" no-gutters>
                    <v-col cols="1">
                      <span class="ml-2 ranking_number mobile">{{ index + 1 }}</span>
                    </v-col>
                    <v-col cols="1">
                      <div class="minifigure_image_container mobile">
                        <v-img v-if="minifigureItem?.minifigures_number?.images[0]?.directus_files_id" :src="getMinifigureImage(minifigureItem?.minifigures_number?.images[0]?.directus_files_id)"></v-img>
                        <v-icon v-else id="small_image_alt" icon="mdi-account-circle" ></v-icon>
                      </div>
                    </v-col>
                    <v-col cols="3" class="ml-6">
                      <v-row no-gutters>
                        <span class="ranking_bricklink_data mobile">{{ minifigureItem.minifigures_number?.number }}</span>
                      </v-row>
                      <v-row no-gutters>
                        <span id="minifig_name_mobile" class="font-weight-black ranking_bricklink_data mobile">{{ clearName(minifigureItem.minifigures_number?.name) }}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="" class="mr-2">
                      <v-row no-gutters><span class="ranking_bricklink_data mobile_header">Ø Preis</span></v-row>
                      <v-row no-gutters><span class="font-weight-black ranking_bricklink_data mobile">{{ minifigureItem.avg_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</span></v-row>
                    </v-col>
                    <v-col cols="" class="mr-2">
                      <v-row no-gutters><span class="ranking_bricklink_data mobile_header">Min. Preis</span></v-row>
                      <v-row no-gutters><span class="font-weight-black ranking_bricklink_data mobile">{{ minifigureItem.min_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</span></v-row>
                    </v-col>
                    <v-col cols="">
                      <v-row no-gutters><span class="ranking_bricklink_data mobile_header">Max. Preis</span></v-row>
                      <v-row no-gutters><span class="font-weight-black ranking_bricklink_data mobile">{{ minifigureItem.max_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</span></v-row>
                    </v-col>
                  </v-row>
                </router-link>
              </div>
            </template>
            <template v-else>
              <div v-for="(minifigureItem, index) in topMinifigures" :key="minifigureItem.minifigures_number?.number" class="mb-4 card_main">
                <router-link :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">
                  <v-row class="row_ranking" no-gutters>
                    <v-col cols="1">
                      <span class="ml-6 ranking_number">{{ index + 1 }}</span>
                    </v-col>
                    <v-col cols="auto" class="mr-16">
                      <div class="minifigure_image_container">
                        <v-img v-if="minifigureItem?.minifigures_number?.images[0]?.directus_files_id" :src="getMinifigureImage(minifigureItem?.minifigures_number?.images[0]?.directus_files_id)"></v-img>
                        <v-icon v-else id="small_image_alt" icon="mdi-account-circle" ></v-icon>
                      </div>
                    </v-col>
                    <v-col cols="1">
                      <span class="ml-6 ranking_bricklink_data">{{ minifigureItem.minifigures_number?.number }}</span>
                    </v-col>
                    <v-col cols="3">
                      <span class="ml-4 mr-6 ranking_bricklink_data">{{ clearName(minifigureItem.minifigures_number?.name) }}</span>
                    </v-col>
                    <v-col cols="1">
                      <span class="mr-6 ranking_bricklink_data">{{ getReleaseDate(minifigureItem.minifigures_number?.release_date) }}</span>
                    </v-col>
                    <v-col cols="1">
                      <span class="mr-6 ranking_bricklink_data">{{ minifigureItem.avg_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</span>
                    </v-col>
                    <v-col cols="1">
                      <span class="mr-6 ranking_bricklink_data">{{ minifigureItem.min_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</span>
                    </v-col>
                    <v-col cols="1">
                      <span class="mr-6 ranking_bricklink_data">{{ minifigureItem.max_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</span>
                    </v-col>
                  </v-row>
                </router-link>
              </div>
            </template>
          </template>

        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-container>
        <v-row no-gutters>
          <div class="hint-card">
            <i class="fal fa-info icon-hint"></i>
          </div>
        </v-row>
        <v-row no-gutters>
          <span class="hint-text">
            Um Deine Collection anlegen und verwalten zu können, musst Du
            <router-link class="text-link" to="/registration">registriert</router-link>
            sein.
          </span>
          <span class="hint-text">
            Hast Du bereits einen Account, dann
            <router-link class="text-link" to="/login">melde</router-link>
            Dich einfach an.
          </span>
        </v-row>
      </v-container>
    </template>

  </section>
</template>

<script>
import Search from '../../components/Search.vue';
import SearchMobile from '../../components/SearchMobile.vue';
import Toggle from '../../components/Toggle.vue';

export default {
  name: 'AnalysisRankingMinifigures',
  components: {
    Search,
    SearchMobile,
    Toggle,
  },
  inject: [
    'api',
    'isMobile',
    'localCurrency',
    'loggedIn',
  ],
  data() {
    return {
      loadingActiveMinifigures: false,
      topMinifigures: [],
      minifigureConditionSwitch: false,
    }
  },
  watch: {
    routeThemeShortcut(value) {
      if (value) { this.getTopMinifiguresByTheme(); }
    },
    minifigureConditionSwitch() {
      this.getTopMinifiguresByTheme();
    },
  },
  computed: {
    routeThemeShortcut() {
      return this.$route.params.minifigureShortcut || '';
    },
    routeTheme() {
      return this.$route.params.theme || '';
    },
  },
  created() {
    this.getTopMinifiguresByTheme();
  },
  unmounted() {

  },
  methods: {
    async getTopMinifiguresByTheme() {
      this.loadingActiveMinifigures = true;
      this.topMinifigures = [];
      let minifiguresFilter = {}
      if (this.minifigureConditionSwitch === false) {
        minifiguresFilter['bricklink_types_id'] = {"id": {"_eq": "six_month_used" }}
      }
      else {
        minifiguresFilter['bricklink_types_id'] = {"id": {"_eq": "six_month_new" }}
      }
      minifiguresFilter['minifigures_number'] = {"_contains": this.routeThemeShortcut }

      const minifiguresResponse = await this.api.items('minifigures_bricklink_sales?fields=*.*,images.directus_files_id.*,minifigures.*,minifigures.minifigures_number.*,minifigures_number.images.*'
      + '&sort=-avg_price'
      + '&limit=100').readByQuery({
        filter: minifiguresFilter
      });

      if (minifiguresResponse) {
        this.loadingActiveMinifigures = false;
        this.topMinifigures = minifiguresResponse.data;
      }
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
    clearName(string) {
      if (string) {
        const wordCount = 5;
        if (string.includes('(')) { string = string.substring(0, string.indexOf('(')); }
        if (string.includes(',')) { string = string.substring(0, string.indexOf(',')); }
        if (string.includes(' - ')) { string = string.substring(0, string.indexOf(' - ')); }
        let arr = string.split(' ', wordCount);
        if (arr.length > 1) {
          let result = '';
          for (let i = 0; i < wordCount; i++) {
            if (arr[i]) { result = result.concat(arr[i] + ' '); }
          }
          return result;
        }
      }
      return string;
    },
    getMinifigureImage(minifigureImageId) {
      try {
        if (minifigureImageId) { return process.env.VUE_APP_API_IMG_PATH + minifigureImageId + '?height=100' ?? ''; }
        return '';
      }
      catch(err) { return ''; }
    },
    convertTheme(theme) {
      try {
        if (theme) {
          let withSpaces = theme.replaceAll('-', ' ');
          let capChars = capitalize(withSpaces);
          return capChars;
        }
        return '';
      }
      catch(err) { return ''; }

      function capitalize(str) {
        const words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        const result = words.join(' ');
        return result;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1200px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}
.row-search-mobile {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.card_header {
  width: 99%;
  margin: 0 auto;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.ranking_bricklink_header {
  font-size: 14px;
  font-weight: 700;
  color: #312659;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
}

.card_main {
  width: 99%;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 999px;
  transition: 0.2s ease all;
}
  .card_main:hover {
    width: 100%;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
  }

.card_main.mobile {
  border-radius: 25px;
}

.row_ranking {
  display: flex;
  height: 4rem;
  align-content: center;
}

.row_ranking.mobile {
  display: flex;
  height: 3.5rem;
  align-content: center;
}

.minifigure_image_container {
  position: absolute;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 999px;
  background: #ffffff;
  overflow: hidden;
}

.minifigure_image_container.mobile {
  position: absolute;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999px;
  background: #ffffff;
  overflow: hidden;
}

.ranking_number {
  font-weight: 800;
}

.ranking_number,
.ranking_bricklink_data {
  color: #ffffff;
  font-size: 14px;
}

.ranking_number.mobile,
.ranking_bricklink_data.mobile {
  font-size: 12px;
}

.ranking_bricklink_data.mobile_header {
  font-size: 10px;
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.2);
  text-shadow: none;
}

.card_body_loader {
  display: flex;
  height: 10rem;
  width: 100%;
  align-items: center;
}

.loader {
  width: 50%;
}

.info_tooltip {
  padding-left: 0.25rem;
}

#minifig_name_mobile {
  line-height: 1;
  display: -webkit-box;
  max-width: 80px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text_mobile {
  font-size: 12px;
}

.hint-card {
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-content: center;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.3) 100%);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.icon-hint {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  font-size: 42px;
  color: rgb(255, 255, 255);
}

.hint-text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

</style>
