import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/LandingPage/LandingPageInit.vue'
import Deals from '../views/Deals.vue'
import Collection from '../views/UserCollection/CollectionInit.vue'
import ImportCollectionMobile from '../views/UserCollection/ImportCollectionMobile.vue'
import CollectionPortfolioInit from '../views/UserCollection/CollectionPortfolioInit.vue'
import CollectionAnalysis from '../views/UserCollection/CollectionAnalysis.vue'
import Insights from '../views/Insights/Insights.vue'
import AnalysisSet from '../views/Insights/AnalysisSet.vue'
import AnalysisRankingMinifigures from '../views/Insights/AnalysisRankingMinifigures.vue'
import AnalysisRankingMinifiguresAll from '../views/Insights/AnalysisRankingMinifiguresAll.vue'
import Content from '../views/Insights/Content.vue'
import SearchResults from '../views/SearchResults.vue'
import SetDetail from '../views/SetDetail/SetDetail.vue'
import MinifigureDetails from '../views/MinifigureDetail/MinifigureDetails.vue'
import RetailerDetails from '../views/RetailerDetails.vue'
import ThemesOverview from '../views/Themes/ThemesOverview.vue'
import Theme from '../views/Themes/Theme.vue'
import StarWars from '../views/Themes/StarWars.vue'
import HarryPotter from '../views/Themes/HarryPotter.vue'
import Technic from '../views/Themes/Technic.vue'
import Registration from "../views/UserManagement/Registration/RegistrationInit.vue"
import Login from "../views/UserManagement/Login/LoginInit.vue"
import About from "../views/About.vue"
import Imprint from "../views/Imprint.vue"
import Policy from "../views/Policy.vue"
import TermsOfUse from "../views/TermsOfUse.vue"
import Contact from "../views/Contact.vue"
import PasswordReset from '../views/UserManagement/PasswordReset.vue'
import NewDatabaseEntries from '../views/NewDatabaseEntries.vue'
import SetsOverview from '../views/SetOverviewWithFilter.vue'
import EndOfLifeOverview from '../views/EndOfLifeOverview.vue'


const routes = [
  {
    path: '/',
    name: 'LandingPageInit',
    component: LandingPage
  },
  {
    path: '/deals',
    component: Deals,
  },
  {
    path: '/usercollection',
    component: Collection,
  },
  {
    path: '/usercollection/import',
    component: ImportCollectionMobile,
  },
  {
    path: '/usercollection/portfolio/:setnumber',
    component: CollectionPortfolioInit,
  },
  {
    path: '/usercollection/analysis',
    component: CollectionAnalysis,
  },
  {
    path: '/items/sets/:setnumber',
    component: SetDetail
  },
  {
    path: '/items/minifigures/:number',
    component: MinifigureDetails
  },
  {
    path: '/items/retailer/:name',
    component: RetailerDetails
  },
  {
    path: '/search/:searchString',
    component: SearchResults
  },
  {
    path: '/themes/:theme',
    component: Theme
  },
  {
    path: '/themes/star-wars',
    component: StarWars
  },
  {
    path: '/themes/harry-potter',
    component: HarryPotter
  },
  {
    path: '/themes/technic',
    component: Technic
  },
  {
    path: '/themes',
    name: 'ThemesOverview',
    component: ThemesOverview
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: PasswordReset,
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint
  },
  {
    path: '/privacy-policy',
    name: 'Policy',
    component: Policy
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsOfUse
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/new-entries',
    name: 'NewDatabaseEntries',
    component: NewDatabaseEntries
  },
  {
    path: '/items/sets-overview',
    name: 'SetsOverviewWithFilter',
    component: SetsOverview
  },
  {
    path: '/eol-overview',
    name: 'EndOfLifeOverview',
    component: EndOfLifeOverview
  },
  {
    path: '/insights',
    component: Insights
  },
  {
    path: '/insights/analysis/:id/item/:setnumber',
    component: AnalysisSet
  },
  {
    path: '/insights/ranking/minifigures/all',
    component: AnalysisRankingMinifiguresAll
  },
  {
    path: '/insights/ranking/minifigures/:minifigureShortcut',
    component: AnalysisRankingMinifigures
  },
  {
    path: '/insights/content/news/:id',
    component: Content
  },
  {
    path: '/insights/content/review/:id',
    component: Content
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
