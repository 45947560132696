<template>
  <template v-if="loggedIn.value">
    <section>
      <v-container>
        <v-row no-gutters class="row-search">
          <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
        </v-row>
        <v-row class="row_header" no-gutters>
          <h1>Analyse der Collection</h1>
        </v-row>

        <v-row no-gutters class="row_collection_analysis my-4">
          <v-col cols="8">
            <div class="card">
              <div class="tab" no-gutters>
              <div class="card-header">
                <v-tabs v-model="tab" class="tab_button_group">
                  <v-tab class="tab_button" value="owned">Nach Besitz</v-tab>
                  <v-tab class="tab_button" value="retail">Nach UVP</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <router-link to='/usercollection'>
                  <i @click="openAddSetDialog()" class="far fa-arrow-left icon plus" id="icon_back"></i>
                </router-link>
              </div>
              <template v-if="loadingActive">
                <div class="card_body_loader">
                  <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
                </div>
              </template>
              <template v-else>
                <div class="card_body_themes">
                  <v-card-text id="card_body_tab_content">
                    <v-window v-model="tab">

                      <v-window-item value="owned">
                        <v-row no-gutters class="px-6">
                          <v-col cols="3">
                            <label class="label label_header">Themenwelt</label>
                          </v-col>
                          <v-col cols="2">
                            <label class="label label_header">Sets im Besitz</label>
                          </v-col>
                          <v-col>
                            <label class="label label_header">Allokation in %</label>
                          </v-col>
                        </v-row>

                        <template v-for="themeDataCount in themeAllocationDataCount" :key="themeDataCount.theme">
                          <v-row no-gutters class="row_analysis_data" @click="activateThemeDetails(themeDataCount)">
                            <v-col cols="3">
                              <label class="label label_row_analysis">{{ themeDataCount.theme }}</label>
                            </v-col>
                            <v-col cols="2">
                              <label class="label label_row_analysis">{{ themeDataCount.setCount }}</label>
                            </v-col>
                            <v-col>
                              <v-progress-linear v-model="themeDataCount.setCountPercent" rounded class="bar" height="20" bg-color="black" bg-opacity="0.1">
                                <label class="bar_text">{{ themeDataCount.setCountPercent }}%</label>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                        </template>
                      </v-window-item>

                      <v-window-item value="retail">
                        <v-row no-gutters class="px-6">
                          <v-col cols="3">
                            <label class="label label_header">Themenwelt</label>
                          </v-col>
                          <v-col cols="2">
                            <label class="label label_header">UVP Gesamt</label>
                          </v-col>
                          <v-col>
                            <label class="label label_header">Allokation in %</label>
                          </v-col>
                        </v-row>

                        <template v-for="themeDataRetail in themeAllocationDataRetail" :key="themeDataRetail.theme">
                          <v-row no-gutters class="row_analysis_data" @click="activateThemeDetails(themeDataRetail)">
                            <v-col cols="3">
                              <label class="label label_row_analysis">{{ themeDataRetail.theme }}</label>
                            </v-col>
                            <v-col cols="2">
                              <label class="label label_row_analysis">{{ themeDataRetail.retailPrices }} {{ mapCurrencyUnit(localCurrency) }}</label>
                            </v-col>
                            <v-col>
                              <v-progress-linear v-model="themeDataRetail.retailPricesPercent" rounded class="bar" height="20" bg-color="black" bg-opacity="0.1">
                                <label class="bar_text">{{ themeDataRetail.retailPricesPercent }}%</label>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                        </template>
                      </v-window-item>

                    </v-window>
                  </v-card-text>

                  </div>
                </template>
              </div>
            </div>
          </v-col>

          <template v-if="activeTheme == null || activeTheme == undefined">
            <v-col cols="4" class="pl-6">
              <div class="card blank">
                <span class="hint">Klicke auf eine Themenwelt links, <br>um mehr Informationen zu erhalten.</span>
              </div>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="4" class="pl-6">
              <div class="card">
                <div class="card-header">
                  <span class="header-text">Details {{ activeTheme }}</span>
                </div>
                <div class="card_body_themes">
                  <div class="label-container">
                    <tbody>
                      <tr>
                        <td><span class="label">Summe UVP</span></td>
                        <td><span class="label value">{{ themeSumCollectionRetailValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                      </tr>
                      <tr>
                        <td><span class="label">Summe EK</span></td>
                        <td><span class="label value">{{ themeSumCollectionPricePaidValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                      </tr>
                      <tr>
                        <td><span class="label">Ersparnis zur UVP</span></td>
                        <template v-if="themeDiffCollectionSums >= 0.00"><td><span class="label value balance_positive">{{ themeDiffCollectionSums }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                        <template v-else><td><span class="label value balance_negative">{{ themeDiffCollectionSums }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                      </tr>
                      <tr>
                        <td><span class="label">Differenz in %</span></td>
                        <template v-if="themeDiffCollectionSumsPercent >= 0.00"><td><span class="label value balance_positive">{{ themeDiffCollectionSumsPercent }} %</span></td></template>
                        <template v-else><td><span class="label value balance_negative">{{ themeDiffCollectionSumsPercent }} %</span></td></template>
                      </tr>
                      <td class="table_space"></td>
                      <tr>
                        <td><span class="label">Sets im Besitz</span></td>
                        <td><span class="label value">{{ themeSumCollectionSets }}</span></td>
                      </tr>
                      <tr>
                        <td>&emsp;<span class="label">davon neu</span></td>
                        <td><span class="label value">{{ themeSumCollectionSetsNew }}</span></td>
                      </tr>
                      <tr>
                        <td>&emsp;<span class="label">davon gebraucht</span></td>
                        <td><span class="label value">{{ themeSumCollectionSetsUsed }}</span></td>
                      </tr>
                      <tr>
                        <td>&emsp;<span class="label">Unterschiedlich</span>
                          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die Anzahl an unterschiedlichen Einträgen. (zb. Für Sets, welche mehrfach vorhanden sind, wird nur eins addiert).">
                            <i class="fas fa-question-circle info_tooltip"></i>
                          </Popper>
                        </td>
                        <td><span class="label value">{{ themeSumCollectionUniqueSets }} </span></td>
                      </tr>
                      <tr>
                        <td><span class="label">Teile</span></td>
                        <td><span class="label value">{{ themeSumCollectionParts }}</span></td>
                      </tr>
                      <tr>
                        <td>&emsp;<span class="label">Teile neu</span>
                          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die Anzahl neuer Teile ist nur ein ungefährer Wert, basierend auf den Zuständen der angelegten Sets. Zustand 'gebraucht, nicht komplett' wird nicht berücksichtigt.">
                            <i class="fas fa-question-circle info_tooltip"></i>
                          </Popper>
                        </td>
                        <td><span class="label value">{{ themeSumCollectionPartsNew }}</span></td>
                      </tr>
                      <tr>
                        <td>&emsp;<span class="label">Teile gebraucht</span>
                          <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die Anzahl gebrauchter Teile ist nur ein ungefährer Wert, basierend auf den Zuständen der angelegten Sets. Zustand 'gebraucht, nicht komplett' wird nicht berücksichtigt.">
                            <i class="fas fa-question-circle info_tooltip"></i>
                          </Popper>
                        </td>
                        <td><span class="label value">{{ themeSumCollectionPartsUsed }}</span></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><span class="label">Minifiguren</span></td>
                        <td><span class="label value">{{ themeSumCollectionMinifigs }}</span></td>
                      </tr>
                    </tbody>
                  </div>
                </div>
              </div>
            </v-col>
          </template>

        </v-row>

      </v-container>
    </section>
  </template>

  <template v-else>
    <section>
      <v-container>
        <v-row no-gutters class="row-search">
          <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
        </v-row>
        <v-row no-gutters>
          <div class="hint-card">
            <i class="fal fa-info icon-hint"></i>
          </div>
        </v-row>
        <v-row no-gutters>
          <span class="hint-text">
            Um Deine Collection anlegen und verwalten zu können, musst Du
            <router-link class="text-link" to="/registration">registriert</router-link>
            sein.
          </span>
          <span class="hint-text">
            Hast Du bereits einen Account, dann
            <router-link class="text-link" to="/login">melde</router-link>
            Dich einfach an.
          </span>
        </v-row>
      </v-container>
    </section>
  </template>
</template>

<script>
import Search from '../../components/Search.vue';
import Popper from 'vue3-popper';

export default {
  name: "CollectionAnalysis",
  components: {
    Search,
    Popper,
  },
  inject: [
    'api',
    'loggedIn',
    'localCurrency'
  ],
  data() {
    return {
      collection: [],
      themeAllocationDataCount: [],
      themeAllocationDataRetail: [],
      activeTheme: null,
      loadingActive: false,
      themeSumCollectionSets: null,
      themeSumCollectionRetailValue: null,
      themeSumCollectionPricePaidValue: null,
      themeDiffCollectionSums: null,
      themeDiffCollectionSumsPercent: null,
      themeSumCollectionSetsNew: null,
      themeSumCollectionSetsUsed: null,
      themeSumCollectionUniqueSets: null,
      themeSumCollectionParts: null,
      themeSumCollectionPartsNew: null,
      themeSumCollectionPartsUsed: null,
      themeSumCollectionMinifigs: null,
      tab: null,
    }
  },
  created() {
    this.getCollection();
  },
  methods: {
    async getCollection() {
      this.loadingActive = true;
      const response = await this.api.items(
        'collections?filter[user_created][_eq]=$CURRENT_USER&fields=*.*,set.name.*,set.theme.name,set.retail_price.*,set.header_image.*,set.images.directus_files_id.*&limit=-1').readByQuery();
      if (response) {
        this.loadingActive = false;
        this.collection = response.data;
        if (this.collection.length > 0) {
          this.setThemeAllocationDataCount();
          this.setThemeAllocationDataRetail();
        }
      }
    },
    setThemeAllocationDataCount() {
      if (this.collection) {
        let themes = [];
        this.collection.forEach(item => {
          if (item.set?.theme?.name) {
            if (!(themes.includes(item.set?.theme?.name))) {
              themes.push(item.set?.theme?.name ?? undefined);
            }
          }
        });

        let tempArr = [];
        themes.forEach(themeName => {
          let tempSetCount = 0;
          var tempObj = {};
          const filteredByThemes = this.collection.filter(x => x.collection[0]?.set?.theme?.name === themeName)
          filteredByThemes.forEach(set => {
            set.collection.forEach(item => {
              if (!(item.sales_date || item.sales_price)) { tempSetCount++; }
            });
          });
          tempObj = {
            theme: themeName,
            setCount: tempSetCount,
            setCountPercent: this.calcSetCountAlloc(tempSetCount)
          }
          tempArr.push(tempObj);
        });

        let sortedArrCount = tempArr;
        sortedArrCount.sort((a, b) => (a.setCount < b.setCount) ? 1 : ((b.setCount < a.setCount) ? -1 : 0));
        this.themeAllocationDataCount = sortedArrCount;
      }
    },
    setThemeAllocationDataRetail() {
      if (this.collection) {
        let themes = [];
        this.collection.forEach(item => {
          if (item.set?.theme?.name) {
            if (!(themes.includes(item.set?.theme?.name))) {
              themes.push(item.set?.theme?.name ?? undefined);
            }
          }
        });

        let tempArr = [];
        themes.forEach(themeName => {
          let tempSetCount = 0;
          let sumRetailPrice = 0.00;
          var tempObj = {};
          const filteredByThemes = this.collection.filter(x => x.collection[0]?.set?.theme?.name === themeName)
          filteredByThemes.forEach(set => {
            set.collection.forEach(item => {
              if (!(item.sales_date || item.sales_price)) {
                tempSetCount++;
                let currentRetailPriceObj = item?.set.retail_price.filter(x => x.price_code === this.localCurrency);
                sumRetailPrice += currentRetailPriceObj[0]?.price;
              }
            });
          });
          tempObj = {
            theme: themeName,
            setCount: tempSetCount,
            retailPrices: parseFloat(sumRetailPrice.toFixed(2)),
            retailPricesPercent: this.calcSetRetailPriceAlloc(parseFloat(sumRetailPrice.toFixed(2)))
          }
          tempArr.push(tempObj);
        });

        let sortedArrRetail = tempArr;
        sortedArrRetail.sort((a, b) => (a.retailPrices < b.retailPrices) ? 1 : ((b.retailPrices < a.retailPrices) ? -1 : 0));
        this.themeAllocationDataRetail = sortedArrRetail;
      }
    },
    calcSetCountAlloc(setCount) {
      let ownedSetsCount = 0;
      this.collection.forEach(set => {
        set.collection.forEach(item => {
          if (!(item.sales_date || item.sales_price)) { ownedSetsCount++; }
        });
      });
      return ((setCount/ownedSetsCount)*100).toFixed(1);
    },
    calcSetRetailPriceAlloc(themeRetailPrice) {
      let ownedSetsRetailPrice = this.sumCollectionRetailValue();
      return ((themeRetailPrice/ownedSetsRetailPrice)*100).toFixed(1);
    },
    activateThemeDetails(currentTheme) {
      this.activeTheme = currentTheme.theme;
      this.themeSumCollectionSets = currentTheme.setCount;
      this.themeSumCollectionRetailValue = this.sumCollectionRetailValue(this.activeTheme);
      this.themeSumCollectionPricePaidValue = this.sumCollectionPricePaidValue(this.activeTheme);
      this.themeDiffCollectionSumsPercent = this.diffCollectionSumsPercent(this.activeTheme);
      this.themeDiffCollectionSums = this.diffCollectionSums(this.activeTheme);
      this.themeSumCollectionSetsNew = this.sumCollectionSetsNew(this.activeTheme);
      this.themeSumCollectionSetsUsed = this.sumCollectionSetsUsed(this.activeTheme);
      this.themeSumCollectionUniqueSets = this.sumCollectionUniqueItems(this.activeTheme);
      this.themeSumCollectionParts = this.sumCollectionParts(this.activeTheme);
      this.themeSumCollectionPartsNew = this.sumCollectionPartsNew(this.activeTheme);
      this.themeSumCollectionPartsUsed = this.sumCollectionPartsUsed(this.activeTheme);
      this.themeSumCollectionMinifigs = this.sumCollectionMinifigs(this.activeTheme);
    },
    sumCollectionRetailValue(theme) {
      if (theme) {
        if (this.collection) {
          let result = 0.00;
          let localRetailPrice = 0.00;
          this.collection.forEach(set => {
            if (set.set?.retail_price) {
              localRetailPrice = this.mapRetailPriceToLocal(set.set.retail_price, this.localCurrency);
            }
            set.collection.forEach(item => {
              if (item?.set?.theme?.name === theme) {
                if (!(item.sales_date || item.sales_price)) {
                  result = parseFloat(result + localRetailPrice);
                }
              }
            });
          });
          return result.toFixed(2) || '';
        }
        return 0;
      }
      else {
        if (this.collection) {
          let result = 0.00;
          this.collection.forEach(set => {
            if (set.set?.retail_price) {
              const localRetailPrice = this.mapRetailPriceToLocal(set.set.retail_price, this.localCurrency);
              set.collection.forEach(item => {
                if (!(item.sales_date || item.sales_price)) {
                  result = parseFloat(result + localRetailPrice);
                }
              });
            }
          });
          return result.toFixed(2) || '';
        }
      }
      return '';
    },
    sumCollectionPricePaidValue(theme) {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item?.set?.theme?.name === theme) {
              if (!(item.sales_date || item.sales_price)) {
                result += parseFloat((item?.price_payed || 0).toFixed(2));
              }
            }
          });
        });
        return result.toFixed(2) || '';
      }
      return '-';
    },
    diffCollectionSums(theme) {
      return (this.sumCollectionRetailValue(theme) - this.sumCollectionPricePaidValue(theme)).toFixed(2) || 0.00;
    },
    diffCollectionSumsPercent(theme) {
      let result = 0;
      let diff = (this.sumCollectionRetailValue(theme) - this.sumCollectionPricePaidValue(theme)).toFixed(2);
      let calc = ((diff / this.sumCollectionRetailValue(theme)) * 100).toFixed(2);
      if (isNaN(calc)) {
        return result.toFixed(2);
      }
      return calc;
    },
    sumCollectionSetsNew(theme) {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item?.set?.theme?.name === theme) {
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('new')))) { result++; }
            }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionSetsUsed(theme) {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item?.set?.theme?.name === theme) {
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('used')))) { result++; }
            }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionUniqueItems(theme) {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          for (let item of set.collection) {
            if (item?.set?.theme?.name === theme) {
              if (!(item.sales_date || item.sales_price)) {
                result++;
                break;
              }
            }
          }
        });
        return result
      }
      return 0;
    },
    sumCollectionParts(theme) {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item?.set?.theme?.name === theme) {
              if (!(item.sales_date || item.sales_price)) {
                result += item?.set?.pieces ?? 0;
              }
            }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionPartsNew(theme) {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item?.set?.theme?.name === theme) {
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('new')))) {
                result += item?.set?.pieces ?? 0;
              }
            }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionPartsUsed(theme) {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item?.set?.theme?.name === theme) {
              if (!(item.sales_date || item.sales_price) && (item.condition && ((item.condition.includes('used') && !item.condition.includes('used_incomplete'))))) {
                result += item?.set?.pieces ?? 0;
              }
            }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionMinifigs(theme) {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item?.set?.theme?.name === theme) {
              if (!(item.sales_date || item.sales_price)) {
                result += item?.set?.minifig_count?.count ?? 0;
              }
            }
          });
        });
        return result
      }
      return 0;
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
  }
}
</script>

<style scoped>

section {
  min-height: 1200px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}

.card {
  position: relative;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transition: 0.5s ease all;
  z-index: 3;
}

.card_body_themes {
  padding-bottom: 1rem;
}

#card_allocation_retail {
  margin-top: 1.5rem;
}

.row_collection_analysis {
  position: relative;
  z-index: 1;
}

.row_analysis_data {
  align-items: center;
  height: 32px;
  cursor: pointer;
  padding: 0 1.5rem;
}

.row_analysis_data:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.label {
  color: #ffffff;
  font-size: 14px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: 0.5s ease all;
}

.label.value {
  color: #ffffff;
  padding: 0 0 0 1.5rem;
  font-weight: 700;
}

.label_header {
  font-weight: 700;
}

.label_row_analysis {
  cursor: pointer;
}

.bar {
  color: #60ffe4;
}
.bar_text {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.card_body_loader {
  display: flex;
  height: 600px;
  align-items: center;
}

.loader {
  width: 50%;
}

.label-container {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
}

.hint-card {
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-content: center;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.3) 100%);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.icon-hint {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  font-size: 42px;
  color: rgb(255, 255, 255);
}

.hint-text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.card.blank {
  height: 400px;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.2) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.5s ease all;
}

.hint {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.info_tooltip {
  padding-left: 0.5rem;
}

#icon_back {
  padding-right: 0.5rem;
}

.tab_button_group {
  margin-left: 1.5rem;
}

.tab_button {
  color: #60ffe4;
  transition: 0.5s ease all;
}

#card_body_tab_content {
  padding: 1rem 0;
}

.label.value.balance_positive {
  color: #60ffe4;
}

.label.value.balance_negative {
  color: rgb(252, 179, 236);
}

.table_space {
  height: 1rem;
}

</style>
