<template>
  <header>
    <nav>
      <div v-if="!mobile" class="navigation">
        <div class="logo">
          <router-link to='/'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 34.59">
              <g>
                <polygon points="51.13 12.49 51.13 23.54 51.13 34.59 62.44 34.59 62.44 23.54 62.44 12.49 51.13 12.49" :style="'fill:' + logoColor"/>
                <path d="M62.41,11A11.15,11.15,0,0,0,51.13,0V11Z" :style="'fill:' + logoColor"/>
              </g>
              <g>
                <path d="M38.42,23.54a11.15,11.15,0,0,0-11.27-11V34.59H38.46V23.54Z" :style="'fill:' + logoColor"/>
                <path d="M36.84,12.49a11.15,11.15,0,0,0,11.27,11v-11Z" :style="'fill:' + logoColor"/>
              </g>
              <g>
                <polygon points="132.58 1.58 132.58 12.49 132.58 12.63 132.58 23.54 132.58 34.59 143.89 34.59 143.89 23.54 143.89 12.63 143.89 12.49 143.89 1.58 132.58 1.58" :style="'fill:' + logoColor"/>
                <path d="M119.79,23.54a11.15,11.15,0,0,0,11.27,11v-11Z" :style="'fill:' + logoColor"/>
                <path d="M131.07,11.05a11.14,11.14,0,0,0-11.27,11h11.27Z" :style="'fill:' + logoColor"/>
              </g>
              <g>
                <polygon points="92.61 1.58 92.61 12.49 92.61 12.63 92.61 23.54 92.61 34.59 103.92 34.59 103.92 23.54 103.92 12.63 103.92 12.49 103.92 1.58 92.61 1.58" :style="'fill:' + logoColor"/>
                <path d="M105.43,22A11.14,11.14,0,0,0,116.7,11H105.43Z" :style="'fill:' + logoColor"/>
                <path d="M116.7,34.59a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
              </g>
              <g>
                <path d="M76.77,11.05a11.14,11.14,0,0,0-11.27,11H76.77Z" :style="'fill:' + logoColor"/>
                <path d="M78.28,22A11.14,11.14,0,0,0,89.55,11H78.28Z" :style="'fill:' + logoColor"/>
                <path d="M89.55,34.59a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                <path d="M65.5,23.54a11.15,11.15,0,0,0,11.27,11v-11Z" :style="'fill:' + logoColor"/>
              </g>
              <g>
                <path d="M173.94,11a11.15,11.15,0,0,0,11.27,11V11Z" :style="'fill:' + logoColor"/>
                <path d="M186.73,22A11.14,11.14,0,0,0,198,11H186.73Z" :style="'fill:' + logoColor"/>
                <path d="M185.22,23.57a11.14,11.14,0,0,0-11.27,11h11.27Z" :style="'fill:' + logoColor"/>
                <path d="M198,34.59a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
              </g>
              <g>
                <path d="M158.22,11.05a11.14,11.14,0,0,0-11.27,11h11.27Z" :style="'fill:' + logoColor"/>
                <path d="M171,22.06a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                <path d="M171,34.59a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                <path d="M146.94,23.54a11.15,11.15,0,0,0,11.27,11v-11Z" :style="'fill:' + logoColor"/>
              </g>
              <g>
                <polygon points="0 1.58 0 12.49 0 12.63 0 23.54 0 34.59 11.31 34.59 11.31 23.54 11.31 12.63 11.31 12.49 11.31 1.58 0 1.58" :style="'fill:' + logoColor"/>
                <path d="M24.1,22.06a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                <path d="M12.82,34.55a11.14,11.14,0,0,0,11.27-11H12.82Z" :style="'fill:' + logoColor"/>
              </g>
            </svg>
          </router-link>
        </div>
        <ul class="links">
          <li><router-link class="link" to='/deals'>Deals</router-link></li>
          <li><router-link class="link" to='/usercollection'>Collection</router-link></li>
          <li><router-link class="link" to='/themes'>Themen</router-link></li>
          <li><router-link class="link" to='/insights'>Insights</router-link></li>
          <li><router-link class="link" to='/eol-overview?yearEol=2023'>EOL</router-link></li>
        </ul>
        <ul class="user">
          <template v-if="!loggedIn.value">
            <li><router-link class="link link-light" to="/registration">Registrieren</router-link></li>
            <li><router-link class="link link-light" to="/login">Anmelden</router-link></li>
          </template>
          <template v-else>
            <li><router-link class="link link-light" to="/login">{{ logoutText }}</router-link></li>
          </template>
        </ul>
      </div>

      <!-- MOBILE -->
      <ul class="mobile-navigation">
        <div @click="toggleMobileNav" v-show="mobile" class="mobile-nav-icon">
          <transition name="fade-rotate">
            <i v-if="!mobileNav" class="fal fa-bars"></i>
          </transition>
          <transition name="fade-rotate">
            <i v-if="mobileNav" class="fal fa-arrow-up"></i>
          </transition>
        </div>

        <div v-if="mobile" class="branding">
          <transition name="fade">
            <div v-if="!mobileSearch" class="logo-mobile">
              <router-link to='/'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 34.59">
                  <g>
                    <polygon points="51.13 12.49 51.13 23.54 51.13 34.59 62.44 34.59 62.44 23.54 62.44 12.49 51.13 12.49" :style="'fill:' + logoColor"/>
                    <path d="M62.41,11A11.15,11.15,0,0,0,51.13,0V11Z" :style="'fill:' + logoColor"/>
                  </g>
                  <g>
                    <path d="M38.42,23.54a11.15,11.15,0,0,0-11.27-11V34.59H38.46V23.54Z" :style="'fill:' + logoColor"/>
                    <path d="M36.84,12.49a11.15,11.15,0,0,0,11.27,11v-11Z" :style="'fill:' + logoColor"/>
                  </g>
                  <g>
                    <polygon points="132.58 1.58 132.58 12.49 132.58 12.63 132.58 23.54 132.58 34.59 143.89 34.59 143.89 23.54 143.89 12.63 143.89 12.49 143.89 1.58 132.58 1.58" :style="'fill:' + logoColor"/>
                    <path d="M119.79,23.54a11.15,11.15,0,0,0,11.27,11v-11Z" :style="'fill:' + logoColor"/>
                    <path d="M131.07,11.05a11.14,11.14,0,0,0-11.27,11h11.27Z" :style="'fill:' + logoColor"/>
                  </g>
                  <g>
                    <polygon points="92.61 1.58 92.61 12.49 92.61 12.63 92.61 23.54 92.61 34.59 103.92 34.59 103.92 23.54 103.92 12.63 103.92 12.49 103.92 1.58 92.61 1.58" :style="'fill:' + logoColor"/>
                    <path d="M105.43,22A11.14,11.14,0,0,0,116.7,11H105.43Z" :style="'fill:' + logoColor"/>
                    <path d="M116.7,34.59a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                  </g>
                  <g>
                    <path d="M76.77,11.05a11.14,11.14,0,0,0-11.27,11H76.77Z" :style="'fill:' + logoColor"/>
                    <path d="M78.28,22A11.14,11.14,0,0,0,89.55,11H78.28Z" :style="'fill:' + logoColor"/>
                    <path d="M89.55,34.59a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                    <path d="M65.5,23.54a11.15,11.15,0,0,0,11.27,11v-11Z" :style="'fill:' + logoColor"/>
                  </g>
                  <g>
                    <path d="M173.94,11a11.15,11.15,0,0,0,11.27,11V11Z" :style="'fill:' + logoColor"/>
                    <path d="M186.73,22A11.14,11.14,0,0,0,198,11H186.73Z" :style="'fill:' + logoColor"/>
                    <path d="M185.22,23.57a11.14,11.14,0,0,0-11.27,11h11.27Z" :style="'fill:' + logoColor"/>
                    <path d="M198,34.59a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                  </g>
                  <g>
                    <path d="M158.22,11.05a11.14,11.14,0,0,0-11.27,11h11.27Z" :style="'fill:' + logoColor"/>
                    <path d="M171,22.06a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                    <path d="M171,34.59a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                    <path d="M146.94,23.54a11.15,11.15,0,0,0,11.27,11v-11Z" :style="'fill:' + logoColor"/>
                  </g>
                  <g>
                    <polygon points="0 1.58 0 12.49 0 12.63 0 23.54 0 34.59 11.31 34.59 11.31 23.54 11.31 12.63 11.31 12.49 11.31 1.58 0 1.58" :style="'fill:' + logoColor"/>
                    <path d="M24.1,22.06a11.15,11.15,0,0,0-11.27-11v11Z" :style="'fill:' + logoColor"/>
                    <path d="M12.82,34.55a11.14,11.14,0,0,0,11.27-11H12.82Z" :style="'fill:' + logoColor"/>
                  </g>
                </svg>
              </router-link>
            </div>
          </transition>
        </div>
        <transition name="fade">
          <ul v-if="mobileNav" class="mobile-links" :class="{ 'mobile-links-active' : mobileNav }">
            <li @click="toggleMobileNav"><router-link class="link" to='/'>Startseite</router-link></li>
            <li @click="toggleMobileNav"><router-link class="link" to='/deals'>Deals</router-link></li>
            <li @click="toggleMobileNav"><router-link class="link" to='/usercollection'>Collection</router-link></li>
            <li @click="toggleMobileNav"><router-link class="link" to='/themes'>Themen</router-link></li>
            <li @click="toggleMobileNav"><router-link class="link" to='/insights'>Insights</router-link></li>
            <li @click="toggleMobileNav"><router-link class="link" to='/eol-overview'>EOL</router-link></li>
            <template v-if="!loggedIn.value">
              <li @click="toggleMobileNav"><router-link class="link" to='/registration'>Registrieren</router-link></li>
              <li @click="toggleMobileNav"><router-link class="link" to='/login'>Anmelden</router-link></li>
            </template>
            <li v-else @click="toggleMobileNav">
              <router-link class="link" to="/login">{{ logoutText }}</router-link>
            </li>
          </ul>
        </transition>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      logoColor: '#fff',
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      mobileSearch: false,
    }
  },

  inject: [
    'api',
    'user',
    'loggedIn'
  ],

  created() {
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },

  computed: {
    userData() {
      return this.user.value;
    },

    logoutText() {
      if (!this.userData || !this.userData.first_name) {
        return 'Abmelden';
      }

      return this.userData.first_name + ' abmelden';
    }
  }
}
</script>

<style scoped>

header {
  height: 60px;
  background: rgba( 0, 0, 0, 0.80 );
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  position: fixed;
  top: 0;
  width: 100%;
}

nav {
  display: flex;
  margin: 0 auto;
  max-width: 980px;
  height: 100%;
  transition: 0.5s ease-in-out all;
  z-index: 999;
}

.navigation,
.links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation li {
  text-decoration: none;
  list-style: none;
}

.links {
  margin: 0 5rem 0 5rem;
}

.link {
  color: rgb(223, 223, 223);
  font-size: 14px;
  font-weight: 500;
  padding: 1rem 0 0.25rem;
  margin: 0 1.25rem;
  text-decoration: none;
  list-style: none;
  border-bottom: 1px solid transparent;
  letter-spacing: 0.7px;
  transition: 0.5s ease all;
}

.link:hover {
  color: #60ffe4;
  border-bottom: 1px solid #60ffe4;
}

/* .router-link-exact-active {
  color: #60ffe4;
  border-bottom: 1px solid #60ffe4;
} */

.cooming-soon-overlay {
  position: absolute;
  margin-top: 4.25rem;
  margin-left: 13rem;
  padding-top: 0.25rem;
  height: 35px;
  width: 180px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
  color: rgb(220, 220, 220);
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-light {
  font-weight: 100;
  font-size: 0.75rem;
  margin: 0 0.5rem;
}

.branding {
  display: flex;
  align-items: center;
}

.logo {
  width: 140px;
  margin-left: 1rem;
  margin-top: 3px;
  transition: 0.5s ease-in-out all;
}

/* MOBILE STYLING */

.mobile-navigation {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.logo-mobile {
  width: 140px;
  margin-top: 3px;
  z-index: 1000;
}

.mobile-search-icon {
  position: absolute;
  top: 20px;
  right: 45px;
  z-index: 80;
}

.mobile-search-icon i {
  position: absolute;
}

.mobile-search-active {
  position: absolute;
  top: 10px;
  right: 65px;
  height: 40px;
  width: 55%;
  padding-left: 15px;
  color: #ffffff;
  background: linear-gradient(90deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.2) 100%);
  border-radius: 20px;
  letter-spacing: .5px;
  z-index: 79;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

input:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.5s ease all;
}

.mobile-links {
  position: fixed;
  list-style: none;
  background: rgba( 0, 0, 0, 0.80 );
  padding: 20px 30px 35px 8px;
  top: 60px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 20;
}

.mobile-links-active {
  background: rgba( 0, 0, 0, 0.80 );
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
}

.mobile-links li {
  padding: 1rem 3rem;
  border-top: 1px solid #505050;
}

i {
  cursor: pointer;
  font-size: 20px;
  color: rgb(223, 223, 223);
  transition: 0.8s ease all;
}

i:hover {
  color: #ffffff;
}

#close-icon {
  position: absolute;
  margin-top: -2px;
  font-size: 24px;
}

.mobile-nav-icon {
  position: absolute;
  top: 17px;
  left: 25px;
  cursor: pointer;
  transition: 1s ease all;
  z-index: 1000;
}

.mobile-nav-icon i {
  position: absolute;
  font-size: 24px;
}

.cancel-search-link {
  margin-right: 120px;
  color: rgba(174, 249, 255, 1)
}

.cooming-soon-overlay-mobile {
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  padding-left: 60px;
}

</style>
