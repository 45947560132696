<template>
  <section>
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row-search-mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
      </v-row>
    </v-container>

    <v-container id="container_section" fluid>

      <v-row class="row_content" no-gutters>
        <template v-if="isMobile">
          <div class="card_main image_container_mobile">
            <div id="container_title" class="ml-6 mt-3">
              <p class="mb-1" id="header_category_mobile"> {{ insightData.category }}</p>
              <h1 id="header_title"> {{ insightData.title }}</h1>
              <p id="header_subtitle"> {{ insightData.subtitle }}</p>
            </div>
            <div class="image_overlay"></div>
            <img class="header_image" :src="getImagePath(insightData.header_image)" loading="lazy">
          </div>
        </template>
        <template v-else>
          <div class="card_main image_container">
            <div id="container_title" class="ml-6 mt-3">
              <p id="header_category"> {{ insightData.category }}</p>
              <h1 id="header_title"> {{ insightData.title }}</h1>
              <p id="header_subtitle"> {{ insightData.subtitle }}</p>
            </div>
            <div class="image_overlay"></div>
            <img class="header_image" :src="getImagePath(insightData.header_image)" loading="lazy">
          </div>
        </template>
      </v-row>
      <v-row class="mb-n12" justify="start" no-gutters>
        <div class="pl-6 pt-2 card_main container_author">
          <p id="header_author"> {{ insightData.author }}</p>
          <p class="label_author"><span class="mdi mdi-calendar-month"></span> {{ insightData.date }} <span class="mdi mdi-update"></span> {{ insightData.time }} Uhr</p>
        </div>
      </v-row>

      <v-row class="row_content" no-gutters>
        <div class="mt-8 html_container" v-html="insightData.teaser"></div>
      </v-row>

      <v-row class="row_content" no-gutters>
        <div class="mt-8 html_container" v-html="insightData.facts"></div>
      </v-row>
      <v-row class="my-4 row_content" no-gutters>
        <table>
          <tr>
            <td class="info_header"><span class="mdi mdi-circle-medium"></span> Setnummer:</td>
            <td class="info_data">{{ set_data.set.split('-')[0] }}</td>
          </tr>
          <tr>
            <td class="info_header"><span class="mdi mdi-circle-medium"></span> Name:</td>
            <td class="info_data">{{ set_data.name }}</td>
          </tr>
          <tr>
            <td class="info_header"><span class="mdi mdi-circle-medium"></span> Teilezahl:</td>
            <td class="info_data">{{ set_data.pieces }}</td>
          </tr>
          <tr>
            <td class="info_header"><span class="mdi mdi-circle-medium"></span> Minifiguren:</td>
            <td class="info_data">{{ set_data.minifig_count.count }}
              <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die Anzahl exklusiver Minifiguren, kann sich mit der Zeit ändern. Eine Angabe im Text gilt also immer für den Zeitpunkt dieses Artikels.">
                <i class="fas fa-question-circle info_tooltip"></i>
              </Popper>
            </td>
          </tr>
          <tr>
            <td class="pr-4 info_header"><span class="mdi mdi-circle-medium"></span> Releasedatum:</td>
            <td class="info_data">{{ set_data.release_date }}</td>
          </tr>
          <tr>
            <td class="info_header"><span class="mdi mdi-circle-medium"></span> UVP:</td>
            <td class="info_data">{{ set_data.retail_price }}</td>
          </tr>
          <tr>
            <td class="info_header"><span class="mdi mdi-circle-medium"></span> Verfügbarkeit:</td>
            <td class="info_data">{{ set_data.availability }}</td>
          </tr>
        </table>
      </v-row>

      <template v-if="insightData.own_images?.length > 0 || gallery_images?.length > 0">
        <v-row class="mt-8" justify="center" no-gutters>
          <div class="pl-6 py-4 card_main">
            <ImageGallery v-if="gallery_images?.length > 0" galleryID="image_gallery_content" :images="gallery_images" />
            <ImageGallery v-else galleryID="image_gallery_content" :images="insightData.own_images" />
          </div>
        </v-row>
      </template>

      <v-row class="row_content" no-gutters>
        <div class="mt-8 html_container" v-html="insightData.bodytext"></div>
      </v-row>

      <v-row class="row_content" no-gutters>
        <div class="mt-8 mb-4 html_container" v-html="insightData.summary"></div>
        <div>
          <h2>Investmentrating</h2>
          <div class="text-center">
            <v-rating :model-value="convertRating(insightData.rating)" readonly half-increments color="#60ffe4"></v-rating>
            <div class="d-flex align-center flex-column mt-n1">
              <div class="text-h5 font-weight-bold text_rating">{{ insightData.rating }}
                <span class="text-body-1 ml-n2">/100</span>
              </div>
            </div>
          </div>

        </div>
      </v-row>

      <v-row v-if="insightData.related_insights.length > 0" class="pt-12" no-gutters>
        <div class="card_main card_related_sets">
          <div class="card-header" id="idMinifigures">
            <span class="header-text">Auch interessante Artikel</span>
          </div>
          <v-row class="my-2" no-gutters>
            <template v-if="isMobile">
              <div v-for="relatedInsightItem in insightData.related_insights" :key="relatedInsightItem.related_insights_id.id">
                <template v-if="relatedInsightItem?.related_insights_id.category[0]?.insights_category_id.slug?.includes('analysis') && !relatedInsightItem?.related_insights_id.category[0]?.insights_category_id?.slug?.includes('ranking')">
                  <router-link class="link" :to="{ path: '/insights/analysis/'+ relatedInsightItem.related_insights_id.id + '/item/' + relatedInsightItem.related_insights_id.slug_identifier }">
                    <SetCard
                    :set="`${relatedInsightItem.related_insights_id.slug_identifier}`"
                    :name="`${relatedInsightItem.related_insights_id.insights_translations[0].title ?? ''}`"
                    :image="getImagePathById(relatedInsightItem.related_insights_id.thumbnail, '')"
                    :imageContainerWidth="'70px'"
                    :imageContainerHeight="'70px'"
                    :imageCover="true"
                    :textContainerWidth="'90px'"
                    :textContainerHeight="'80px'"
                    :textContainerBackground="'none'"
                    :cardSetFontsize="'10px'"
                    :cardNameFontsize="'12px'"
                    :cardNameMinMaxHeight="'2.35rem'"
                    :cardPriceFontsize="'12px'"
                    :cardPriceColor="'#60ffe4'"
                    :borderRadius="'15px'"
                    :transformTextContainer="'translateY(-10px)'"
                    />
                  </router-link>
                </template>
                <template v-if="relatedInsightItem?.related_insights_id.category[0]?.insights_category_id.slug?.includes('content') && !relatedInsightItem?.related_insights_id.category[0]?.insights_category_id?.slug?.includes('ranking')">
                  <router-link class="link" :to="{ path: relatedInsightItem.related_insights_id.category[0]?.insights_category_id.slug + relatedInsightItem.related_insights_id.id }">
                    <SetCard
                    :set="`-`"
                    :name="`${relatedInsightItem.related_insights_id.insights_translations[0].title ?? ''}`"
                    :image="getImagePathById(relatedInsightItem.related_insights_id.thumbnail, '')"
                    :imageContainerWidth="'70px'"
                    :imageContainerHeight="'70px'"
                    :imageCover="true"
                    :textContainerWidth="'90px'"
                    :textContainerHeight="'80px'"
                    :textContainerBackground="'none'"
                    :cardSetFontsize="'10px'"
                    :cardNameFontsize="'12px'"
                    :cardNameMinMaxHeight="'2.35rem'"
                    :cardPriceFontsize="'12px'"
                    :cardPriceColor="'#60ffe4'"
                    :borderRadius="'15px'"
                    :transformTextContainer="'translateY(-10px)'"
                    />
                  </router-link>
                </template>
              </div>
            </template>
            <template v-else>
              <div v-for="relatedInsightItem in insightData.related_insights" :key="relatedInsightItem.related_insights_id.id">
                <template v-if="relatedInsightItem?.related_insights_id.category[0]?.insights_category_id.slug?.includes('analysis') && !relatedInsightItem?.related_insights_id.category[0]?.insights_category_id?.slug?.includes('ranking')">
                  <router-link class="link" :to="{ path: '/insights/analysis/'+ relatedInsightItem.related_insights_id.id + '/item/' + relatedInsightItem.related_insights_id.slug_identifier }">
                    <SetCard
                    :set="`${relatedInsightItem.related_insights_id.slug_identifier}`"
                    :name="`${relatedInsightItem.related_insights_id.insights_translations[0].title ?? ''}`"
                    :image="getImagePathById(relatedInsightItem.related_insights_id.thumbnail, '')"
                    :imageContainerWidth="'120px'"
                    :imageContainerHeight="'120px'"
                    :imageCover="true"
                    :textContainerWidth="'140px'"
                    :textContainerHeight="'80px'"
                    :textContainerBackground="'none'"
                    :cardSetFontsize="'12px'"
                    :cardNameFontsize="'12px'"
                    :cardNameMinMaxHeight="'1.8rem'"
                    :cardPriceFontsize="'14px'"
                    :cardPriceColor="'#60ffe4'"
                    :borderRadius="'15px'"
                    :transformTextContainer="'translateY(-10px)'"
                    />
                  </router-link>
                </template>
                <template v-if="relatedInsightItem?.related_insights_id.category[0]?.insights_category_id.slug?.includes('content') && !relatedInsightItem?.related_insights_id.category[0]?.insights_category_id?.slug?.includes('ranking')">
                  <router-link class="link" :to="{ path: relatedInsightItem.related_insights_id.category[0]?.insights_category_id.slug + relatedInsightItem.related_insights_id.id }">
                    <SetCard
                    :set="`-`"
                    :name="`${relatedInsightItem.related_insights_id.insights_translations[0].title ?? ''}`"
                    :image="getImagePathById(relatedInsightItem.related_insights_id.thumbnail, '')"
                    :imageContainerWidth="'120px'"
                    :imageContainerHeight="'120px'"
                    :imageCover="true"
                    :textContainerWidth="'140px'"
                    :textContainerHeight="'80px'"
                    :textContainerBackground="'none'"
                    :cardSetFontsize="'12px'"
                    :cardNameFontsize="'12px'"
                    :cardNameMinMaxHeight="'1.8rem'"
                    :cardPriceFontsize="'14px'"
                    :cardPriceColor="'#60ffe4'"
                    :borderRadius="'15px'"
                    :transformTextContainer="'translateY(-10px)'"
                    />
                  </router-link>
                </template>
              </div>
            </template>
          </v-row>
        </div>
      </v-row>

    </v-container>

  </section>
</template>

<script>
import Search from '../../components/Search.vue';
import SearchMobile from '../../components/SearchMobile.vue';
import ImageGallery from '../../components/Dialogs/ImageGallery.vue';
import SetCard from '../../components/SetCard.vue';

export default {
  name: 'AnalysisSetView',
  components: {
    Search,
    SearchMobile,
    ImageGallery,
    SetCard,
  },
  inject: [
    'api',
    'isMobile',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      set_data: {
        amount_per_sales_unit: '',
        availability: '',
        bricklink_sales: [],
        designer: [],
        dimension_depth: '',
        dimension_height: '',
        dimension_width: '',
        dimensions_weight: '',
        dimensions: '',
        ean: '',
        eol_date: '',
        header_image: '',
        images: '',
        in_collections: '',
        investmentrating: '',
        legacy_retail_price: '',
        minifig_count: '',
        minifigures: '',
        name: '',
        packaging: '',
        package_size_depth: '',
        package_size_height: '',
        package_size_width: '',
        package_weight: '',
        pieces: '',
        price_per_piece: '',
        price_per_weight: '',
        recommended_age: '',
        release_date: '',
        retail_price: '',
        set: '',
        subtheme: '',
        tags: '',
        theme: '',
      },
      insightData: {
        author: '',
        category: '',
        date: '',
        header_image: '',
        images: [],
        insights_translations: [],
        own_images: '',
        rating: '',
        related_insights: [],
        slug_identifier: '',
        status: '',
        thumbnail: ''
      },
      gallery_images: [],
    }
  },
  watch: {
    routeId(value) {
      if (value) {
        this.getInsightData();
        this.getSet();
      }
    },
    routeSetnumber(value) {
      if (value) {
        this.getInsightData();
        this.getSet();
      }
    },
  },
  computed: {
    routeId() {
      return this.$route.params.id || '';
    },
    routeSetnumber() {
      return this.$route.params.setnumber || '';
    },
  },
  created() {
    this.getInsightData();
    this.getSet();
    this.availabilities = [
      { text: "LEGO Exklusiv" , value: "lego_exclusive" },
      { text: "Fachhandel" , value: "limited_retail" },
      { text: "Überall" , value: "retail" },
    ];
  },
  unmounted() {

  },
  methods: {
    async getInsightData() {
      this.loadingActive = true;
      this.insightData = {
        author: '',
        // category: '',
        date: '',
        time: '',
        header_image: '',
        insights_translations: [],
        own_images: [],
        rating: 0,
        related_insights: [],
        slug_identifier: '',
        status: '',
        thumbnail: ''
      };
      let filter = {}
      filter['status'] = {"_eq": 'published' }
      filter['id'] = {"_eq": this.$route.params.id }

      const res = await this.api.items('insights?fields=*.*,category.insights_category_id.insights_category_translations.*,category.insights_category_id.*,related_insights.related_insights_id.*,related_insights.related_insights_id.insights_translations.*,related_insights.related_insights_id.category.*,related_insights.related_insights_id.category.insights_category_id.*,own_images.directus_files_id.*').readByQuery({
        filter: filter
      });
      const data = res.data[0];

      if (data) {
        try {
          this.insightData.author = data.author;
          this.insightData.category = data.category[0]?.insights_category_id?.insights_category_translations[0]?.title;
          this.insightData.date = this.convertDateString(data.date, { year: 'numeric', month: '2-digit', day: '2-digit' });
          this.insightData.time = this.convertLocalTimeString(data.date);
          if (data.header_image) {
            this.insightData.header_image = data.header_image;
          }
          else {
            // Fallback to required thumbnail image
            this.insightData.header_image = data.thumbnail;
          }
          this.insightData.title = data.insights_translations[0]?.title;
          this.insightData.subtitle = data.insights_translations[0]?.subtitle;
          this.insightData.teaser = data.insights_translations[0].teaser;
          this.insightData.facts = data.insights_translations[0].facts;
          this.insightData.bodytext = data.insights_translations[0].bodytext;
          this.insightData.summary = data.insights_translations[0].summary;
          if (this.insightData.own_images?.length > 0) { this.insightData.own_images.length = 0; }
          data.own_images.forEach(imageObject => {
            if (!(imageObject.directus_files_id)) { return; }
            this.insightData.own_images.push({
              largeURL: process.env.VUE_APP_API_IMG_PATH + imageObject.directus_files_id?.id + '?height=1800' ?? '',
              thumbnailURL: process.env.VUE_APP_API_IMG_PATH + imageObject.directus_files_id?.id + '?height=300' ?? '',
              width: imageObject.directus_files_id?.width ?? '',
              height: imageObject.directus_files_id?.height ?? '',
            })
          });
          this.insightData.rating = data.rating;
          this.insightData.related_insights = data.related_insights;
        }
        catch(err) { console.error("Something went wrong while fetching the insight data!", err) }
        finally { this.loadingActive = false; }
      }
    },
    async getSet() {
      this.retailerList = [];
      const res = await this.api.items('sets?fields=*.*,images.directus_files_id.*,minifigures.*,minifigures.minifigures_number.*,minifigures.minifigures_number.images.*&filter[set]=' + this.routeSetnumber).readByQuery();
      const data = res.data[0];

      if (data) {
        // Set set_data values
        this.set_data.amount_per_sales_unit = data.amount_per_sales_unit || '-';
        this.set_data.availability = this.availabilities?.find(el => el.value === data.availability)?.text ?? '-';
        this.set_data.bricklink_sales = data.bricklink_sales;
        this.set_data.designer = this.splitDesigner(data.designer) || '';
        this.set_data.dimension_depth = data.dimension_depth || '';
        this.set_data.dimension_height = data.dimension_height || '';
        this.set_data.dimension_width = data.dimension_width || '';
        this.set_data.dimensions_weight = data.dimensions_weight || '';
        this.set_data.dimensions = data.dimensions || '';
        this.set_data.ean = data.ean || '';
        this.set_data.eol_date = data.eol_date || '';
        this.set_data.header_image = data.header_image || '';
        this.set_data.images = data.images || '';
        // add images to gallery
        if (this.gallery_images?.length > 0) { this.gallery_images.length = 0; }
        data.images.forEach(imageObject => {
          if (!(imageObject.directus_files_id)) { return; }
          this.gallery_images.push({
            largeURL: process.env.VUE_APP_API_IMG_PATH + imageObject.directus_files_id?.id + '?height=1800' ?? '',
            thumbnailURL: process.env.VUE_APP_API_IMG_PATH + imageObject.directus_files_id?.id + '?height=300' ?? '',
            width: imageObject.directus_files_id?.width ?? '',
            height: imageObject.directus_files_id?.height ?? '',
          })
        });
        this.set_data.in_collections = data.in_collections || '';
        this.set_data.investmentrating = data.investmentrating || '';
        this.set_data.legacy_retail_price = this.mapRetailPriceToLocal(data.legacy_retail_price, this.localCurrency) || '';
        this.set_data.minifig_count = data.minifig_count || '';
        this.set_data.minifigures = data.minifigures || '';
        this.set_data.name = this.mapNameToLocal(data.name, this.localLanguage) || '';
        this.set_data.note = data.note || '';
        this.set_data.packaging = this.packagings?.find(el => el.value === data.packaging)?.text ?? '';
        this.set_data.package_size_depth = data.package_size_depth || '';
        this.set_data.package_size_height = data.package_size_height || '';
        this.set_data.package_size_width = data.package_size_width || '';
        this.set_data.package_weight = data.package_weight || '';
        this.set_data.pieces = data.pieces || '';
        this.set_data.recommended_age = data.recommended_age || '';
        this.set_data.release_date = this.convertDateString(data.release_date, { year: 'numeric', month: 'numeric' }) || '';
        this.set_data.retail_price = this.mapRetailPriceToLocal(data.retail_price, this.localCurrency) || '-';
        this.set_data.set = data.set || '';
        this.set_data.subtheme = data.subtheme?.name ?? '';
        this.set_data.tags = data.tags || '';
        this.set_data.theme = data.theme?.name || '';

        if ((this.set_data.retail_price && this.set_data.retail_price !== '-') && (this.set_data.pieces && this.set_data.pieces !== '-')) {
          this.set_data.price_per_piece = ((parseFloat(this.set_data.retail_price) / this.set_data.pieces) * 100).toFixed(2);
        }
        else { this.set_data.price_per_piece = '-'}
        if ((this.set_data.retail_price && this.set_data.retail_price !== '-') && (this.set_data.dimensions_weight && this.set_data.dimensions_weight !== '-')) {
          this.set_data.price_per_weight = ((parseFloat(this.set_data.retail_price) / this.set_data.dimensions_weight) * 100).toFixed(2);
        }
        else { this.set_data.price_per_weight = '-'}
      }
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    splitDesigner(designer) {
      if (designer) {
        return designer.split(',');
      }
      else return '';
    },
    convertDateString(dateString, dateMappingObject) {
      if (dateString) {
        try {
          let convertedDateString = (new Date(dateString))?.toLocaleDateString(this.localLanguage, dateMappingObject) ?? '-';
          if (convertedDateString.includes('Invalid')) { convertedDateString = '-'; }
          return convertedDateString;
        }
        catch(err) {
          return '-';
        }
      }
      else {
        return '-';
      }
    },
    convertLocalTimeString(dateString) {
      if (dateString) {
        try {
          let convertedTimeString = (new Date(dateString))?.toLocaleTimeString(this.localLanguage, { hour: 'numeric', minute: 'numeric' }) ?? '-';
          if (convertedTimeString.includes('Invalid')) { convertedTimeString = '-'; }
          return convertedTimeString;
        }
        catch(err) {
          return '-';
        }
      }
      else {
        return '-';
      }
    },
    getImagePath(image) {
      try {
        if (image.id) { return process.env.VUE_APP_API_IMG_PATH + image.id + '?height=1000' ?? ''; }
        return '';
      }
      catch(err) { return ''; }
    },
    getImagePathById(imageId) {
      if (imageId) {
        try {
          return process.env.VUE_APP_API_IMG_PATH + imageId + '?height=200';
        }
        catch(err) {
          console.warn(err);
          return '';
        }
      }
    },
    convertRating(ratingValue) {
      try {
        if (ratingValue) {
          let result = (ratingValue / 20).toFixed(1);
          result = halfRound(result, 0.5);
          return result;
        }
        return 0;
      }
      catch(err) { console.error("Something went wrong while converting rating value!", err) }

      function halfRound(value, step) {
          step || (step = 1.0);
          var inv = 1.0 / step;
          return Math.round(value * inv) / inv;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1300px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.html_container {
  position: relative;
  color: #ffffff;
}

.html_container:deep(h2),
.html_container:deep(h3) {
  text-align: center;
}

.html_container:deep(h2) {
  margin-bottom: 1rem;
}

.html_container:deep(a) {
  display: inline-block;
  font-size: 16px;
  color: #60ffe4;
  cursor: pointer;
  text-decoration: none;
}

.html_container:deep(a::after) {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #60ffe4;
  transition: width .3s;
}

.html_container:deep(a:hover::after) {
  width: 100%;
}

.html_container:deep(ol),
.html_container:deep(ul) {
  text-align: center;
}

.html_container:deep(ul li) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.html_container:deep(ul li::before) {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ffffff;
  margin-right: .5rem;
}



.header-container {
  position: relative;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}
.row-search-mobile {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

#container_section {
  width: 1300px;
}

.row_content {
  flex-direction: column;
  align-items: center
}

.image_container {
  position: relative;
  width: 100%;
  min-height: 500px;
  max-height: 500px;
  overflow: hidden;
}

.image_container_mobile {
  position: relative;
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
}

.header_image {
  position: relative;
  display: flex;
  width: 100%;
  object-fit: contain;
}

.image_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 2;
}

#container_title {
  position: absolute;
  top: 0;
  z-index: 3;
}

#header_title {
  font-size: 18px;
  line-height: 1;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

#header_subtitle {
  margin-top: 0.25rem;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
}

.container_author {
  position: relative;
  width: 240px;
  height: 60px;
  display: grid;
  transform: translateY(-40px) translateX(24px);
  z-index: 4;
}

#header_author {
  height: 10px;
  color: #ffffff;
  font-weight: 800;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  line-height: 1;
}

.label_author {
  color: #ffffff;
  font-size: 14px;
}

.info_header {
  color: #60ffe4;
  font-weight: 700;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
}

.info_data {
  color: #ffffff;
  line-height: 24px;
}

.card_related_sets {
  width: 100%
}

.text_rating {
  color: #ffffff;
}

#header_category {
  color: #ffffff;
  font-weight: 800;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  line-height: 1;
  text-transform: uppercase;
}

#header_category_mobile {
  font-size: 10px;
  color: #ffffff;
  font-weight: 800;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  line-height: 1;
  text-transform: uppercase;
}


@media (min-width: 960px) {
  #header_title {
    font-size: 50px;
  }

  #header_subtitle {
    font-size: 20px;
    margin-top: 0.5rem;
  }

  #header_author {
    font-size: 18px;
  }
}

</style>
