<template>
  <section>
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row-search-mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="mb-6 row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Du suchst eine Themenwelt?" />
      </v-row>
    </v-container>

    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="mx-4">
        <h2 class="header-theme mobile">Top Themenwelten</h2>
      </v-row>
      <v-row no-gutters class="theme-row">
        <div v-for="theme in topThemes" :key="theme" class="theme-card mobile">
          <div @click="pushRoute(theme.name)" class="card-container mobile">
            <div class="card-main">
              <div class="image-container mobile">
                <div v-if="getImage(theme.files[0]?.directus_files_id)" class="bg-image mobile" :style="'background: linear-gradient(180deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.8) 100%), url(' + getImage(theme.files[0]?.directus_files_id ?? '') + ');'"></div>
              </div>
              <span class="card-name mobile">{{ theme.name }}</span>
              <span class="card-sets mobile">{{ theme.set_count }} Sets</span>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row no-gutters>
        <h1 class="header-theme">Top LEGO Themenwelten</h1>
      </v-row>
      <v-row no-gutters class="theme-row">
        <div v-for="theme in topThemes" :key="theme" class="theme-card">
          <div @click="pushRoute(theme.name)" class="card-container">
            <div class="card-main">
              <div class="image-container">
                <div v-if="getImage(theme.files[0]?.directus_files_id)" class="bg-image" :style="'background: linear-gradient(180deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.8) 100%), url(' + getImage(theme.files[0]?.directus_files_id ?? '') + ');'"></div>
              </div>
              <span class="card-name">{{ theme.name }}</span>
              <span class="card-sets">{{ theme.set_count }} Sets</span>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>

    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters>
        <h2 class="header-theme mobile">Themenwelten</h2>
      </v-row>
      <v-row no-gutters class="theme-row">
        <ul v-for="chunk in chunkedThemes" :key="chunk.id" class="theme-list mobile">
          <li v-for="theme in chunk" :key="theme" @click="pushRoute(theme.name)" class="theme-list-item mobile">{{ theme.name }} ({{theme.set_count || 0}})</li>
        </ul>
      </v-row>
    </v-container>
    <v-container v-else fluid>
      <v-row no-gutters>
        <h1 class="header-theme">Themenwelten</h1>
      </v-row>
      <v-row no-gutters class="theme-row">
        <ul v-for="chunk in chunkedThemes" :key="chunk.id" class="theme-list">
          <li v-for="theme in chunk" :key="theme" @click="pushRoute(theme.name)" class="theme-list-item">{{ theme.name }} ({{theme.set_count || 0}})</li>
        </ul>
      </v-row>
    </v-container>

  </section>
</template>

<script>
import Search from '../../components/Search.vue';
import SearchMobile from '../../components/SearchMobile.vue';

export default {
  name: 'Theme',
  components: {
    Search,
    SearchMobile
  },
  inject: [
    'api',
    'isMobile'
  ],
  data() {
    return {
      themes: [],
      topThemes: [],
      chunkedThemes: [],
      setImage: ''
    }
  },
  created() {
    this.getThemes();
  },
  methods: {
    async getThemes() {
      const res = await this.api.items('themes?fields=*.*&limit=-1').readByQuery();
      this.themes = res.data.sort((a, b) => (a.name > b.name) ? 1 : -1);

      this.topThemes = this.getTopThemes(this.themes);

      this.chunkedThemes = this.chunkArray(this.themes, 37)

    },
    getTopThemes(themes) {
      let tempThemes = [];
      const topThemeList = ['Star Wars', 'Harry Potter', 'City', 'Architecture', 'Marvel Super Heroes', 'Avatar', 'BrickHeadz',
      'The Lord of the Rings', 'Jurassic World', 'Ideas', 'Ninjago', 'Speed Champions', 'Technic', 'Indiana Jones', 'Collectable Minifigures'];

      themes.forEach(themeObject => {
        if (topThemeList.includes(themeObject.name)) {
          tempThemes.push(themeObject);
        }
        tempThemes.sort((a, b) => (a.name > b.name) ? 1 : -1);
      });
      return tempThemes;
    },
    getImage(image) {
      if (image) {
        try {
          const img = process.env.VUE_APP_API_IMG_PATH + image + '?height=120' ?? '';
          return img;
        }
        catch {
          return '';
        }
      }
      return '';
    },
    pushRoute(theme) {
      const themeNoSpecialChars = (((theme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
      const routeTheme = themeNoSpecialChars.toLowerCase();
      this.$router.push({ path: '/themes/' + routeTheme });
    },
    chunkArray(array, chunkCount) {
      var myArray = [];
      for(var i = 0; i < array.length; i += chunkCount) {
        myArray.push(array.slice(i, i + chunkCount));
      }
      return myArray;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#trademark {
  font-size: 1.5rem;
  vertical-align: super;
}

section {
  width: 100%;
  height: 100%;
  min-height: 1080px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  justify-content: center;
  z-index: 3;
}

.row-search-mobile {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.header-theme {
  margin: 0 auto;
}

.header-theme.mobile {
  padding-top: 1rem;
}

.theme-row {
  justify-content: center;
  margin: 1rem 0;
}

.card-container {
  position: relative;
  height: 100px;
  width: 200px;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.6) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transition: 0.5s ease all;
}

.card-container.mobile {
  height: 90px;
  width: 90px;
  border-radius: 20px;
}

.theme-card {
  position: relative;
  display: block;
  margin: 1rem;
  cursor: pointer;
  transition: .5s ease all;
}

.theme-card.mobile {
  margin: 0.5rem;
}

.image-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.image-container.mobile {
  border-radius: 20px;
}

.bg-image {
  height: 100%;
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  transition: 0.5s ease all;
}

.bg-image.mobile {
  opacity: 0.3;
}

.card-main {
  display: flex;
  justify-content: center;
}

.card-name {
  position: absolute;
  width: 80%;
  text-align: center;
  bottom: 1.5rem;
  color: #ffffff;
  font-size: 18px;
  font-weight: 800;
  line-height: 1;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
}

.card-name.mobile {
  font-size: 12px;
  font-weight: 700;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.card-sets {
  position: absolute;
  bottom: 0.3rem;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
}

.card-sets.mobile {
  font-size: 10px;
}

.theme-card:hover .bg-image {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  transform: scale(1.1);
}

.theme-card:hover .text-container {
  border: 1px solid rgb(255, 255, 255);
}

.theme-list {
  display: flex;
  flex-direction: column;
  width: 320px;
  text-decoration: none;
  list-style: none;
}

.theme-list.mobile {
  width: 150px;
}

.theme-list-item {
  font-size: 14px;
  color: #ffffff;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
  margin: 0.25rem;
  cursor: pointer;
  transition: 0.5s ease all;
}

.theme-list-item.mobile {
  font-size: 12px;
  margin: 0.5rem;
}

.theme-list-item:hover {
  color: #60ffe4;
}


/* MEDIA SCREENS */
@media (min-width: 600px) {

}

@media (min-width: 1280px) {

}

</style>
