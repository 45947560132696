<template>
  <div class="dialog-overlay">

      <div class="dialog-card">
        <div class="dialog-header">
          <span class="header-text">Top 10 Themenwelten</span>
          <v-spacer></v-spacer>
          <i class="fal fa-times icon close" @click="close()"></i>
        </div>
        <div class="dialog-body">
          <v-row no-gutters justify="center">
            <DonutChart :chartData="chartData" />
          </v-row>
        </div>
      </div>

  </div>
</template>

<script>
import DonutChart from '../DonutChart.vue';

export default {
  name: 'AllocationThemesDialogAllDevices',
  components: {
    DonutChart
  },
  props: ['collection'],
  inject: [

  ],
  data() {
    return {
      chartData: [],
    }
  },
  watch: {
  },
  created() {
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();

    this.setChartData();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    close() {
      this.$emit('close');
    },
    setChartData() {
      if (this.collection) {
        let themes = [];
        const topThemeCount = 10;
        this.collection.forEach(item => {
          if (item.set?.theme?.name) {
            if (!(themes.includes(item.set?.theme?.name))) {
              themes.push(item.set?.theme?.name ?? undefined);
            }
          }
        });

        let tempArr = [];
        themes.forEach(themeName => {
          let tempSetCount = 0;
          var tempObj = {};
          const filteredByThemes = this.collection.filter(x => x.collection[0]?.set?.theme?.name === themeName)
          filteredByThemes.forEach(item => {
            tempSetCount += item.collection.length;
          });
          tempObj = {
            theme: themeName,
            setCount: tempSetCount
          }
          tempArr.push(tempObj);
        });

        let sortedArr = tempArr;
        sortedArr.sort((a, b) => (a.setCount < b.setCount) ? 1 : ((b.setCount < a.setCount) ? -1 : 0));
        this.chartData = sortedArr.slice(0, topThemeCount);

        let newArr = sortedArr.slice(topThemeCount, sortedArr.length);
        if (newArr.length > 0) {
          let restSetCount = 0;
          newArr.forEach(item => {
            restSetCount += item.setCount;
          });
          let restObj = {
            theme: 'Rest',
            setCount: restSetCount
          }
          this.chartData.push(restObj);
        }
      }
    },
  }
}
</script>

<style scoped>

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog-card {
  position: relative;
  width: 90%;
  max-width: 600px;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.dialog-body {
  padding: 1rem 1.5rem;
}

.dialog-text {
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin: 0 auto;
}

.dialog-action {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 1rem;
  color: #5C5DD8;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%);
}

</style>
