<template>
  <!-- delete set dialog -->
  <transition name="fade">
    <DeleteCollectionEntryDesktop v-if="deleteDialog" :setPortfolio="setPortfolio" :id="id" @close="deleteDialog = false" />
  </transition>

  <!-- add set to collection dialog -->
  <transition name="fade">
    <AddSetDialogDesktop v-if="addSetDialogActive" :set_data="set_data" @close="addSetDialogActive = false" />
  </transition>

  <section>
    <v-container>
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
      </v-row>
    </v-container>

    <v-container>
      <v-row no-gutters class="row-set-details">
        <v-row no-gutters class="row-set-header">
          <div class="card card-collection-header">
            <div @click="pushRouteToSet(set_data?.set)" class="image-container medium">
              <v-img v-if="set_data?.image" class="image" :src="set_data?.image" :lazy-src="set_data?.image"></v-img>
              <v-icon v-else id="main_image_alt" icon="mdi-package" ></v-icon>
            </div>
            <div class="card-header">
              <span class="header-text active-set">{{ set_data?.set ?? '-' }} {{ set_data?.name ?? '' }}</span>
              <v-spacer></v-spacer>
              <i @click="openAddSetDialog()" class="fal fa-plus icon plus"></i>
              <i @click="saveActiveSetChanges()" class="fal fa-save icon save"></i>
              <i @click="closePortfolioOverview()" class="fal fa-times icon close"></i>
            </div>
            <div class="card-body active-set-info">
              <span class="collection-header">UVP:</span>
              <label v-if="set_data?.retail_price" class="label" for="">{{ set_data?.retail_price }}</label>
              <label v-else class="label" for="">-</label>
              <span class="collection-header">Themenwelt:</span>
              <label class="label" for="">{{ set_data?.theme }}</label>
              <span class="collection-header">Release:</span>
              <label v-if="(new Date(set_data?.release_date))?.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric'}) != 'Invalid Date'" class="label" for="">{{ (new Date(set_data?.release_date))?.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric'}) ?? '-' }}</label>
              <label v-else class="label" for="">-</label>
              <span class="collection-header">EOL:</span>
              <label v-if="(new Date(set_data?.eol_date))?.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric'}) != 'Invalid Date'" class="label" for="">{{ (new Date(set_data?.eol_date))?.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric'}) ?? '-' }}</label>
              <label v-else class="label" for="">-</label>
              <span class="collection-header">Marktlaufzeit:</span>
              <label v-if="lifecycle" class="label" for="">{{ lifecycle }} Monate</label>
              <label v-else class="label" for="">-</label>
            </div>
          </div>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pr-6">
            <div class="card card-collection-set">
              <div class="card-header">
                <span class="header-text">Portfolio</span>
              </div>
              <div class="card-body portfolio">

                <table class="collection-table set">
                  <thead>
                    <tr>
                      <th style="padding-left: 1.4rem;"><span class="collection-header">EK-Datum</span></th>
                      <th><span class="collection-header">EK-Preis</span></th>
                      <th><span class="collection-header">Zustand</span></th>
                      <th><span class="collection-header">Händler</span></th>
                      <th><span class="collection-header"></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="td in setPortfolio" :key="td" class="table-row set">
                      <td style="padding-left: 1.4rem;" class="collection-data set" :class="{selected: rowSelected}" @click="activatePortfolioEntry(td)">{{ convertDateTime(td.purchase_date) }}</td>
                      <td class="collection-data set" @click="activatePortfolioEntry(td)">{{ td.price_payed?.toFixed(2) ?? 0 }}</td>
                      <td class="collection-data set" @click="activatePortfolioEntry(td)">{{ convertCondition('toText', td.condition) }}</td>
                      <td class="collection-data set" @click="activatePortfolioEntry(td)">{{ td.retailer }}</td>
                      <td class="collection-data set" @click="openDeleteDialog(td)"><i class="fal fa-trash-alt icon delete-set"></i></td>
                      <td class="collection-data set" @click="activatePortfolioEntry(td)"><i class="fal fa-edit icon edit-set"></i></td>
                      <template v-if="td.sales_price || td.sales_date"><td class="collection-data set" @click="activatePortfolioEntry(td)"><i class="fal fa-shopping-cart icon-sold"></i></td></template>
                      <template v-else><td class="collection-data set" @click="activatePortfolioEntry(td)"><i class="fal fa-shopping-cart icon-not-sold"></i></td></template>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </v-col>

          <template v-if="!portfolioSetActive">
          <v-col cols="6">
            <v-row no-gutters class="row-side-informations">
              <div class="card blank">
                <span class="hint">Klicke auf ein Eintrag in deinem Portfolio.</span>
              </div>
            </v-row>
          </v-col>
          </template>
          <template v-if="portfolioSetActive">
          <v-col cols="6">
            <v-row no-gutters class="row-side-informations">
              <div class="card card-purchase-informations">
                <div class="card-header">
                  <span class="header-text">Einkauf</span>
                </div>
                <div class="card-body">
                  <v-row no-gutters class="pl-5">
                    <v-col class="pr-5" cols="5">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-model="purchase_date" clearable class="input_field" color="#60ffe4" label="Einkaufsdatum" type="date" placeholder="tt.mm.jjjj"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col class="pr-5" cols="4">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-model.number="price_payed" clearable class="input_field" color="#60ffe4" label="Einkaufspreis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col class="pr-5" cols="3">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-model.number="discountPercent" readonly class="input_field discount" color="#60ffe4" label="Rabatt" append-inner-icon="mdi-percent-outline" type="number" placeholder="0,00"></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pl-5">
                    <v-col class="pr-5" cols="12">
                      <v-row no-gutters class="input-container">
                        <v-select v-model="condition" :items="conditions" hide-details class="mb-6 input_field" color="#60ffe4" label="Zustand" append-inner-icon="mdi-gift-open" theme="dark"></v-select>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pl-5">
                    <v-col class="pr-5" cols="6">
                      <v-row no-gutters class="input-container">
                        <v-combobox v-model="retailer" :items="retailerSuggestionList" clearable hide-details class="input_field" color="#60ffe4" append-inner-icon="mdi-storefront-outline" label="Händler" placeholder="LEGO" theme="dark"></v-combobox>
                      </v-row>
                    </v-col>
                    <v-col class="pr-5" cols="6">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-model="storage" class="input_field" color="#60ffe4" label="Lagerplatz" append-inner-icon="mdi-library-shelves" type="text" placeholder="Regal X, Fach Y"></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="false" no-gutters class="pb-4 pl-5">
                    <v-col class="pr-5" cols="4">
                      <label class="label-input" for="">Wunsch VK-Preis</label>
                      <v-row no-gutters class="input-container">
                        <input v-model.number="wishSalesPrice" class="input-field" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00">
                        <span class="input-unit">{{ mapCurrencyUnit(localCurrency) }}</span>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="card card-sales-informations">
                <div class="card-header">
                  <span class="header-text mr-1">Verkauf</span>
                  <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content='Um die Felder zu aktivieren, markieren das Set als verkauft! Um einen Eintrag dauerhaft als verkauft zu markieren, muss mindestens das Verkaufsdatum oder der Verkaufspreis ausgefüllt werden!'>
                    <i class="fas fa-question-circle info_tooltip"></i>
                  </Popper>
                  <v-spacer></v-spacer>
                  <span class="label-input">Set verkauft?</span>
                  <Toggle v-model="sold" class="toggle-sold"/>
                </div>
                <div class="card-body">
                  <v-row no-gutters class="pl-5">
                    <v-col class="pr-5" cols="5">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-if="sold" v-model="sales_date" clearable class="input_field" color="#60ffe4" label="Verkaufsdatum" type="date" placeholder="tt.mm.jjjj"></v-text-field>
                        <v-text-field v-else disabled class="input_field" color="#60ffe4" label="Verkaufsdatum" type="date" placeholder="tt.mm.jjjj"></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pl-5">
                    <v-col class="pr-5" cols="4">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-if="sold" v-model.number="sales_price" clearable class="input_field" color="#60ffe4" label="Verkaufspreis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00"></v-text-field>
                        <v-text-field v-else disabled class="input_field" color="#60ffe4" label="Verkaufspreis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" placeholder="0,00"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col class="pr-5" cols="4">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-if="sold" v-model.number="fees" clearable class="input_field" color="#60ffe4" label="Gebühren" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00"></v-text-field>
                        <v-text-field v-else disabled class="input_field" color="#60ffe4" label="Gebühren" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" placeholder="0,00"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col class="pr-5" cols="4">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-if="sold" v-model.number="shipping" clearable class="input_field" color="#60ffe4" label="Versand" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00"></v-text-field>
                        <v-text-field v-else disabled class="input_field" color="#60ffe4" label="Versand" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" placeholder="0,00"></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pl-5">
                    <v-col class="pr-5" cols="4">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-if="sold" v-model.number="earnings" clearable class="input_field" color="#60ffe4" label="Erlös" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00"></v-text-field>
                        <v-text-field v-else disabled class="input_field" color="#60ffe4" label="Erlös" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number"  placeholder="0,00"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col class="pr-5" cols="4">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-if="sold" v-model.number="result" clearable class="input_field" color="#60ffe4" label="Ergebnis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00"></v-text-field>
                        <v-text-field v-else disabled class="input_field" color="#60ffe4" label="Ergebnis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" placeholder="0,00"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col class="pr-5" cols="4">
                      <v-row no-gutters class="input-container">
                        <v-text-field v-if="sold" v-model.number="roi" readonly class="input_field" color="#60ffe4" label="ROI" append-inner-icon="mdi-percent-outline" type="number" placeholder="0,00"></v-text-field>
                        <v-text-field v-else disabled class="input_field" color="#60ffe4" label="ROI" append-inner-icon="mdi-percent-outline" type="number" placeholder="0,00"></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-row no-gutters class="pt-6">
                <v-col class="pr-2" cols="7">
                  <div class="card">
                    <div class="card-header">
                      <span class="header-text mr-1">Performance</span>
                      <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content='Die Performance wird basierend auf dem aktuellen Marktpreis und deinem Einkaufspreis berechnet. Hierbei wird der angegebene Zustand herangezogen. Ist der Zustand nicht angegeben, wird die Performance nicht berechet. Bitte stell den Zustand ein.
                                                                                                      Die Daten aus einem Verkauf werden hier nicht berücksichtigt! Diese Berechnungen findest du in dem Bereich "VERKAUF".'>
                        <i class="fas fa-question-circle info_tooltip"></i>
                      </Popper>
                      <v-spacer></v-spacer>
                    </div>
                    <div class="card-body">
                      <v-row no-gutters class="pb-4 pl-5">
                        <tbody>
                          <tr>
                            <td v-if="this.condition?.includes('Neu') ?? ''"><span class="label">Aktueller Wert (neu)</span></td>
                            <td v-else-if="this.condition?.includes('Gebraucht') ?? ''"><span class="label">Aktueller Wert (gebr.)</span></td>
                            <td v-else><span class="label">Aktueller Wert</span></td>
                            <td><span class="label value">{{ getBricklinkSales() || '-' }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                          </tr>
                          <tr>
                            <td><span class="label">Performance</span></td>
                            <template v-if="diffPerformance >= 0.00"><td><span class="label value balance_positive">{{ diffPerformance }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                            <template v-else><td><span class="label value balance_negative">{{ diffPerformance }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                          </tr>
                          <tr>
                            <td><span class="label">Performance in %</span></td>
                            <template v-if="diffPerformancePercent == 'Infinity'"><td><span class="label value balance_positive">Awesome ;)</span></td></template>
                            <template v-else-if="diffPerformancePercent >= 0.00"><td><span class="label value balance_positive">{{ diffPerformancePercent }} %</span></td></template>
                            <template v-else><td><span class="label value">{{ diffPerformancePercent }} <span v-show="diffPerformancePercent != '-'">%</span></span></td></template>
                          </tr>
                        </tbody>
                      </v-row>
                    </div>
                  </div>
                </v-col>
                <v-col class="pl-2" cols="5">
                  <div class="card blank small">
                    <div class="card-header">
                      <span class="header-text blank">Preis Trend</span>
                      <v-spacer></v-spacer>
                    </div>
                    <div class="card-body">
                      <span class="coming-soon">Kommt demnächst...</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
          </template>
        </v-row>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import Search from '../../components/Search.vue';
import Toggle from '../../components/Toggle.vue';
import AddSetDialogDesktop from '../../components/Dialogs/AddSetDialogDesktop.vue';
import DeleteCollectionEntryDesktop from '../../components/Dialogs/DeleteCollectionEntryDesktop.vue';
import moment from 'moment/min/moment-with-locales';

export default {
  name: 'Collection',
  components: {
    Search,
    Toggle,
    AddSetDialogDesktop,
    DeleteCollectionEntryDesktop,
    // DealCard,
  },
  inject: [
    'api',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      mobile: null,
      portfolioSetActive: false,
      addSetDialogActive: false,
      deleteDialog: false,
      rowSelected: false,
      responseMessage: '',
      // headers: [
      //   { title: 'Kaufdatum', key: 'purchase_date' },
      //   { title: 'Kaufpreis', key: 'price_paid' },
      //   { title: 'Zustand', key: 'condition' },
      //   { title: 'Händler', key: 'retailer' },
      // ],
      // itemsPerPage: 25,

      retailerSuggestionList: ['Alternate','Alza','Amazon','DisneyShop','Ebay','Galaxus','Galeria','JB Spielwaren','Kaufland','Kleinanzeigen','LEGO','Lucky Bricks','Media Markt','Müller','Otto','Proshop','Saturn','Spielemax','Taschengelddieb','Thalia','Toys For Fun','myToys'],

      setPortfolio: [],
      activeSet: {},

      // portfolioTableData: [],

      set_data: {
        eol_date: '',
        name: '',
        release_date: '',
        retail_price: '',
        set: '',
        theme: '',
        bricklink_sales: '',
        image: null
      },

      lifecycle: '',

      condition: null,
      discountPercent: '',
      earnings: '',
      fees: '',
      id: '',
      price_payed: '',
      purchase_date: '',
      result: '',
      retailer: '',
      roi: '',
      sales_date: '',
      sales_price: '',
      shipping: '',
      sold: false,
      storage: '',
      wishSalesPrice: '',
      currentSetValue: '',

      collectionSetItem: [],
      firstChartEntryDatetime: null,
      chartDataValue: [],
      loadingActive: false
    }
  },
  watch: {
    price_payed() {
      this.discountPercent = this.calcDiscount();
      if (this.sold) { this.result = (parseFloat(this.earnings - this.price_payed)).toFixed(2) ?? null; }
    },
    sold() {
      if (!this.sold) { this.clearSalesData(); }
    },
    sales_price() { if (this.sold) { this.earnings = (parseFloat(this.sales_price - this.fees - this.shipping)).toFixed(2) ?? null; } },
    fees() { if (this.sold) { this.earnings = (parseFloat(this.sales_price - this.fees - this.shipping)).toFixed(2) ?? null; } },
    shipping() { if (this.sold) { this.earnings = (parseFloat(this.sales_price - this.fees - this.shipping)).toFixed(2) ?? null; } },
    earnings() { if (this.sold) { this.result = parseFloat(this.earnings - this.price_payed).toFixed(2) ?? null; } },
    result() {
      if (this.sold) {
        this.roi = (parseFloat((this.result / this.price_payed) * 100)).toFixed(2) ?? null;
      }
    }
  },
  computed: {
    diffPerformance() {
      const currentSetValue = this.getBricklinkSales()
      return (currentSetValue - this.price_payed).toFixed(2) || 0.00;
    },
    diffPerformancePercent() {
      let result = 0.00;
      const currentSetValue = this.getBricklinkSales()
      let diff = (currentSetValue - this.price_payed).toFixed(2);
      let calc = ((diff / this.price_payed) * 100).toFixed(2);
      if (isNaN(calc)) {
        return result.toFixed(2);
      }
      if (currentSetValue == 0.00 || currentSetValue == '') { return '-'; }
      return calc;
    },
  },
  created() {
    this.conditions = [
      { title: "Neu, versiegelt" , value: "new_sealed" },
      { title: "Neu, leichte Schäden" , value: "new_minor_damages" },
      { title: "Neu, starke Schäden" , value: "new_major_damages" },
      { title: "Gebraucht, Tüten verschlossen" , value: "used_bags_closed" },
      { title: "Gebraucht, gebaut, nicht bespielt" , value: "used_build_not_played" },
      { title: "Gebraucht, bespielt" , value: "used_played" },
      { title: "Gebraucht, Karton fehlt" , value: "used_box_missing" },
      { title: "Gebraucht, nicht komplett" , value: "used_incomplete" }
      ];

    this.getCollection();
  },
  methods: {
    async getCollection() {
      const response = await this.api.items(
        'collections?filter[user_created][_eq]=$CURRENT_USER&fields=*.*,set.name.*,set.theme.name,set.retail_price.*,set.header_image.*,set.images.directus_files_id.*,set.bricklink_sales.*&limit=-1&filter[set][_eq]='
        + this.$route.params.setnumber).readByQuery();
      if (response) {
        this.collectionSetItem = response.data[0];
        if (this.collectionSetItem) {
          this.set_data.set = this.collectionSetItem?.set?.set ?? '';
          this.set_data.name = this.mapNameToLocal(this.collectionSetItem?.set?.name, this.localLanguage) ?? '';
          this.set_data.theme = this.collectionSetItem?.set?.theme?.name ?? '';
          if (this.collectionSetItem?.set?.release_date) { this.set_data.release_date = new Date(this.collectionSetItem?.set?.release_date ) ?? ''; }
          else { this.set_data.release_date = '-'}
          this.set_data.retail_price = this.mapRetailPriceToLocal(this.collectionSetItem?.set?.retail_price, this.localCurrency);
          if (this.collectionSetItem?.set?.eol_date) { this.set_data.eol_date = new Date(this.collectionSetItem?.set?.eol_date) ?? '-'; }
          else { this.set_data.eol_date = '-'}
          this.lifecycle = this.returnLifecycle(this.set_data.release_date, this.set_data.eol_date) || '';
          this.set_data.image = this.getSetOverviewImage(this.collectionSetItem?.set?.header_image, this.collectionSetItem?.set?.images) ?? '';
          this.setPortfolio = this.collectionSetItem?.collection ?? '';
          this.set_data.bricklink_sales = this.collectionSetItem?.set?.bricklink_sales ?? '';
        }
      }
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=300';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=300';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=300' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=300';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=300' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    activatePortfolioEntry(currentEntry) {
      const tempConditions = [
        { title: "Neu, versiegelt" , value: "new_sealed" },
        { title: "Neu, leichte Schäden" , value: "new_minor_damages" },
        { title: "Neu, starke Schäden" , value: "new_major_damages" },
        { title: "Gebraucht, Tüten verschlossen" , value: "used_bags_closed" },
        { title: "Gebraucht, gebaut, nicht bespielt" , value: "used_build_not_played" },
        { title: "Gebraucht, bespielt" , value: "used_played" },
        { title: "Gebraucht, Karton fehlt" , value: "used_box_missing" },
        { title: "Gebraucht, nicht komplett" , value: "used_incomplete" }
      ];

      this.id = currentEntry.id;
      this.condition = tempConditions.find(el => el.value === currentEntry.condition)?.value ?? null;
      if (currentEntry.discount) { this.discountPercent = currentEntry?.discount?.toFixed(2) ?? ''; }
      else { this.discountPercent = this.calcDiscount(); }
      this.earnings = currentEntry?.earnings?.toFixed(2) ?? '';
      this.fees = currentEntry.fees?.toFixed(2) ?? '';
      this.price_payed = currentEntry?.price_payed?.toFixed(2) ?? '';
      this.purchase_date = currentEntry?.purchase_date?.substring(0, 10) ?? '';
      this.result = currentEntry?.result?.toFixed(2) ?? '';
      this.retailer = currentEntry.retailer;
      this.roi = currentEntry?.roi?.toFixed(2) ?? '';
      this.sales_date = currentEntry?.sales_date?.substring(0, 10) ?? '';
      this.sales_price = currentEntry?.sales_price?.toFixed(2) ?? '';
      this.shipping = currentEntry?.shipping?.toFixed(2) ?? '';
      if (currentEntry.sales_price || currentEntry.sales_date) {
        this.sold = true;
      }
      else this.sold = false;
      this.storage = currentEntry.storage;
      this.portfolioSetActive = true;
    },
    async deleteCopy() {
      await this.api.items('collections').deleteOne(this.id);
      if (this.setPortfolio.length == 1) { this.$router.push({ path: '/usercollection/'}); }
      else { this.$router.go(); }
    },
    async saveActiveSetChanges() {
      if (this.portfolioSetActive) {
        let discount = null;
        if (this.discountPercent && this.discountPercent !== 0) { discount = parseFloat(this.discountPercent)?.toFixed(2) ?? null }
        let earnings = null;
        if (this.earnings && this.earnings !== 0) { earnings = parseFloat(this.earnings)?.toFixed(2) ?? null }
        let fees = null;
        if (this.fees && this.fees !== 0) { fees = parseFloat(this.fees)?.toFixed(2) ?? null }
        let price_payed = null;
        toString(this.price_payed);
        if (this.price_payed) { price_payed = parseFloat(this.price_payed)?.toFixed(2) ?? null }
        let result = null;
        if (this.result && this.result !== 0) { result = parseFloat(this.result)?.toFixed(2) ?? null }
        let roi = null;
        if (this.roi && this.roi !== 0) { roi = parseFloat(this.roi)?.toFixed(2) ?? null }
        let sales_price = null;
        if (this.sales_price && this.sales_price !== 0) { sales_price = parseFloat(this.sales_price)?.toFixed(2) ?? null }
        let shipping = null;
        if (this.shipping && this.shipping !== 0) { shipping = parseFloat(this.shipping)?.toFixed(2) ?? null }

        let data = {
          condition: this.condition,
          discount: discount,
          earnings: earnings,
          fees: fees,
          price_payed: price_payed?.toString()?.replace(',', '.') ?? '',
          purchase_date: this.convertGermanDateString(this.purchase_date),
          result: result,
          retailer: this.retailer || null,
          roi: roi,
          sales_date: this.convertGermanDateString(this.sales_date),
          sales_price: sales_price,
          shipping: shipping,
          storage: this.storage || null
        }
        await this.api.items('collections').updateOne(this.id, data);
        this.$router.go();
      }
    },
    closePortfolioOverview() {
      this.portfolioSetActive = false;
      this.$router.push({ path: '/usercollection/'});
    },
    clearSalesData() {
      this.sold = false;
      this.sales_date = '';
      this.sales_price = '';
      this.fees = '';
      this.shipping = '';
      this.earnings = '';
      this.result = '';
      this.roi = '';
    },
    openAddSetDialog() {
      if (this.addSetDialogActive === false) {
        this.addSetDialogActive = true;
      }
    },
    openDeleteDialog(currentEntry) {
      if (this.deleteDialog === false) {
        this.deleteDialog = true;
        this.id = currentEntry.id;
      }
    },
    returnLifecycle(release, eol) {
      if (release == '-' && eol == '-') return '';
      else if (release && eol !== '-') return this.calculateMonthDifference(release, eol);
      return '';
    },
    convertCondition(direction, conditionValue) {
      try {
        if (conditionValue) {
          if (direction === 'toText') return this.conditions.find(el => el.value === conditionValue).title;
          if (direction === 'toValue') return this.conditions.find(el => el.title === conditionValue).value;
        }
        else {
          return null;
        }
        return "";
      }
      catch { return ""; }
    },
    convertDateTime(dateValue) {
      if (dateValue) { return (new Date(dateValue))?.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}) ?? ''; }
      else { return ''; }
    },
    calcDiscount() {
      if (this.price_payed === 0 || this.price_payed === 0.00 || this.price_payed === '0.00' || this.price_payed === '0,00') {
        let result = parseFloat(100).toFixed(2)
        return result;
      }
      if (this.set_data.retail_price && this.set_data.retail_price !== '-' && this.set_data.retail_price !== '0.00' && this.price_payed) {
        try {
          let calc = ((1-(this.price_payed / this.set_data.retail_price))*100);
          let toFloat = parseFloat(calc);
          let result = toFloat.toFixed(2);
          return result;
        }
        catch { return null }
      }
      else return null;
    },
    pushRouteToSet(set) {
      this.$router.push({ path: '/items/sets/' + set });
    },
    toTimestamp(stringDate) {
      var date = Date.parse(stringDate);
      return date / 1000;
    },
    convertGermanDateString(dateString) {
      if (dateString) {
        let day = dateString.split('-')[2];
        let month = dateString.split('-')[1];
        let year = dateString.split('-')[0];

        if (day.length == 2 && month.length == 2 && year.length == 4) {
          let m = moment(day + '.' + month + '.' + year + '12:00', 'DD MM YYYY HH:mm Z', 'de');
          return m.toISOString();
        }
        else return null;
      }
      else return null;
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    calculateMonthDifference(startDate, endDate) {
      if (startDate != null || startDate != undefined) return '';
      if (endDate != null || endDate != undefined) return '';
      const monthDifference = endDate.getMonth() - startDate.getMonth();
      const yearDifference = endDate.getYear() - startDate.getYear();
      return (monthDifference + yearDifference * 12) + 1;
    },
    getBricklinkSales() {
      let result = 0.00;
      if (this.condition?.includes('new') ?? '') {
        result = this.set_data.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.avg_price ?? 0.00;
        return result.toFixed(2);
      }
      if (this.condition?.includes('used') ?? '') {
        result = this.set_data.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.avg_price ?? 0.00;
        return result.toFixed(2);
      }
      return result;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1200px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}

.row-collection {
  padding-top: 2rem;
}

.row-set-header {
  margin-bottom: 1.5rem;
}

.row-set-details {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.row-side-informations {
  flex-wrap: nowrap;
  flex-direction: column;
}

.card {
  position: relative;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transition: 0.5s ease all;
  z-index: 3;
}

.card.blank {
  height: 800px;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.2) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.5s ease all;
}

.card.blank.small {
  height: 135px;
}

.card-header {
  width: 100%;
}

.card-body.portfolio {
  height: 750px;
  overflow: auto;
}

.card-body.active-set-info {
  padding-left: 9rem;
  margin-bottom: 1rem;
}

.card-collection-header {
  width: 100%;
}

.card-collection,
.card-collection-set {
  width: 100%;
  height: 800px;
  padding-bottom: 15px;
}

.card-sales-informations {
  margin-top: 1.5rem;
}

.collection-table {
  width: 100%;
  border-spacing: 0;
}

.card-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 44px;
}

.card-header-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 11.5rem;
}

th {
  text-align: start;
}

.active-set {
  color: #60ffe4;
  margin-left: 9rem;
  transition: 0.5s ease all;
}

.header-action-link.save {
  margin-left: 1rem;
}

.table-row:hover {
  background: rgba(0, 0, 0, 0.2);
}

/* tr.selected td {
  background: rgba(0, 0, 0, 0.1);
} */

.table-row.set {
  cursor: pointer;
}

.icon-not-sold {
  margin-right: 1rem;
  color: rgb(82 95 191);
  font-weight: 500;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
}

.icon-sold {
  margin-right: 1rem;
  color: #60ffe4;
  font-weight: 500;
}

.collection-header {
  font-size: 12px;
  font-weight: 700;
  color: #312659;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
  transition: 0.5s ease all;
}

.collection-data {
  font-size: 14px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.collection-data.set {
  font-size: 12px;
  padding: 0.5rem 0;
  transition: 0.5s ease all;
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  overflow: hidden;
  cursor: pointer;
}

.image-container.small {
  width: 44px;
  height: 44px;
  margin: 0.3rem 0 0.3rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}

.image-container.medium {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 20px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  transform: translateY(-18px);
}

.image {
  height: 100%;
  transition: 0.5s ease transform;
}

.text-link {
  display: inline-block;
  font-size: 14px;
}

.label {
  color: #ffffff;
  font-size: 12px;
  padding: 0 1.5rem 0 0.5rem;
  transition: 0.5s ease all;
}

.discount {
  cursor: default;
}

.label.value {
  color: #ffffff;
  padding: 0 0 0 0.5rem;
  font-weight: 700;
}

.label.value.balance_positive {
  color: #60ffe4;
}

.label.value.balance_negative {
  color: rgb(252, 179, 236);
}

.performance-data {
  text-align: end;
}

.table-performance {
  margin-left: 1.3rem;
  margin-bottom: 1rem;
}

::-webkit-scrollbar-track {
  margin: 1.5rem;
}

.toggle-sold {
  margin: 0 1.3rem 0 0.5rem;
}

.hint {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.hidden-container {
  position: absolute;
}

.coming-soon {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin: 2rem 0
}

.header-text.blank {
  opacity: 0.2;
}

.row-response-message {
  min-height: 2rem;
  padding: 0.75rem 0 1.5rem 2.2rem;
  transition: 0.5s ease all;
}

.response-message {
  font-size: 14px;
  color: #d5d9ff;
  transition: 0.5s ease all;
}

#main_image_alt {
  height: 100%;
  width: 100%;
  font-size: 3.5rem;
  color: rgba(0, 0, 0, 0.2);
  text-shadow: none;
}

@media (min-width: 1280px) {
  .input-unit,
  .label,
  .collection-header,
  .collection-data.set {
    font-size: 14px;
  }

  .active-set {
    font-size: 20px;
  }
}

</style>
