<template>
  <RegistrationMobile v-if="isMobile" />
  <RegistrationDesktop v-else />
</template>

<script>
import RegistrationMobile from './RegistrationMobile.vue';
import RegistrationDesktop from './RegistrationDesktop.vue';

export default {
  name: "RegistrationInit",
  components: {
    RegistrationMobile,
    RegistrationDesktop
  },
  inject: [
    'api'
  ],
  data() {
    return {
      isMobile: false
    }
  },
  unmounted() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true });
  },
  mounted() {
    if (('ontouchstart' in window) ||
        ("ontouchstart" in document.documentElement) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0) ||
        (window.matchMedia("(pointer: coarse)").matches)) {
          this.isMobile = true;
        }
  },
  methods: {
    onResize () {
      if (('ontouchstart' in window) ||
          ("ontouchstart" in document.documentElement) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0) ||
          (window.matchMedia("(pointer: coarse)").matches)) {
            this.isMobile = true;
          }
    }
  }
}
</script>
