<template>
  <div id="chart">
    <apexchart
      id="donut_chart"
      type="donut"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: 'DonutChart',
  props: ['chartData'],
  data() {
    return {
      series: this.setChartData('series'),
      chartOptions: {
        chart: {
          type: 'donut',
          fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
          foreColor: '#ffffff',
        },
        colors: ['#5c59e2', '#FF6B6C', '#06D6A0', '#FFC53A', '#9B00A3', '#8786EA', '#FF9999', '#6AEBC8', '#FFD470', '#E59AE9', '#acabc7'],
        labels: this.setChartData('labels'),
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          offsetY: 0,
          onItemClick: {
            toggleDataSeries: false
          },
        },
        stroke: {
          show: false,
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 20
            },
            donut: {
              size: '55%',
              background: 'transparent',
            },
          }
        },
        noData: {
          text: 'Füge Sets zu deiner Collection hinzu.',
          align: 'center',
          verticalAlign: 'top',
          offsetX: 0,
          offsetY: 50,
          style: {
            color: '#ffffff',
            fontSize: '14px',
            fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
          }
        },
      },
    }
  },
  methods: {
    setChartData(returnDataSwitch) {
      let result = [];

      this.chartData.forEach(element => {
        if (returnDataSwitch === 'series') {
          result.push(element.setCount);
        }
        if (returnDataSwitch === 'labels') {
          result.push(element.theme);
        }
      });
      return result;
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
  }
}
</script>

<style scoped>

#chart {
  width: 400px;
}

</style>
