<template>
    <div class="card">

      <div v-if="discountBadgeActive" class="discount-container" :style="discountBadgeStyleObject">
        <p class="discount-text" :style="discountTextStyleObject">-{{ discount }}%</p>
      </div>

      <div class="image-container" :style="imageContainerStyleObject">
        <v-img v-if="image" class="image" :src="image" :lazy-src="image"></v-img>
        <v-icon v-else id="main_image_alt" icon="mdi-package" ></v-icon>
      </div>

      <div class="text-container" :style="textContainerStyleObject">
        <div class="card-main">
          <span class="card-set" :style="cardSetStyleObject">{{ set }}</span>
          <span class="card-theme" :style="cardThemeStyleObject">{{ theme || '-' }}</span>
          <span class="my-1 card-name" :style="cardNameStyleObject">{{ name || '?' }}</span>
          <template v-if="typeof retailPrice === 'object' && retailPrice !== null">
            <span class="card-retail-price" :style="cardRetailPriceStyleObject">{{ formatRetailPrice(retailPrice) }}</span>
          </template>
          <template v-else>
            <span v-if="retailPrice" class="card-retail-price" :style="cardRetailPriceStyleObject">{{ retailPrice }} {{ mapCurrencyUnit(localCurrency) }}</span>
          </template>
          <template v-if="typeof price === 'object' && price !== null">
            <span class="card-price" :style="cardPriceStyleObject">{{ formatRetailPrice(price) }}*</span>
          </template>
          <template v-else>
            <span v-if="price" class="card-price" :style="cardPriceStyleObject">{{ price }}* {{ mapCurrencyUnit(localCurrency) }}</span>
          </template>
        </div>
        <div v-if="yearActive" class="card-footer">
          <span class="card-year" :style="cardYearStyleObject">{{ getReleaseDate(year) }}</span>
        </div>
      </div>

    </div>

</template>

<script>
export default {
  name: 'DealCard',
  props: ['image', 'theme', 'year', 'set', 'name', 'retailPrice', 'price',
  'discountBadgeWidth', 'discountBadgeHeight', 'discountTextFontsize', 'discountBadgeBackgroundColor', 'transformDiscountBadge', 'discountTextColor', 'priceActive', 'discount', 'discountBadgeActive', 'textContainerWidth', 'textContainerHeight',
  'imageContainerWidth', 'imageContainerHeight', 'cardSetFontsize', 'cardThemeFontsize', 'cardNameFontsize', 'cardRetailPriceFontsize', 'yearActive', 'cardYearFontsize', 'cardPriceFontsize',
  'cardPriceColor', 'cardNameMinMaxHeight', 'lineClamp', 'transformTextContainer', 'borderRadius'],
  inject: [
    'localCurrency',
    'localLanguage'
  ],
  computed: {
  },
  components: {

  },
  data() {
    return {
      discountBadgeStyleObject: {
        width: this.discountBadgeWidth,
        height: this.discountBadgeHeight,
        background: this.discountBadgeBackgroundColor,
        transform: this.transformDiscountBadge
        // width: this.discountBadgeContainerAlignment,
      },
      discountTextStyleObject: {
        fontSize: this.discountTextFontsize,
        color: this.discountTextColor
      },
      imageContainerStyleObject: {
        width: this.imageContainerWidth,
        height: this.imageContainerHeight,
        borderRadius: this.borderRadius
      },
      textContainerStyleObject: {
        width: this.textContainerWidth,
        height: this.textContainerHeight,
        transform: this.transformTextContainer,
        borderRadius: this.borderRadius
      },
      cardSetStyleObject: {
        fontSize: this.cardSetFontsize
      },
      cardThemeStyleObject: {
        fontSize: this.cardThemeFontsize
      },
      cardNameStyleObject: {
        fontSize: this.cardNameFontsize,
        minHeight: this.cardNameMinMaxHeight,
        maxHeight: this.cardNameMinMaxHeight,
        webkitLineClamp: this.lineClamp
      },
      cardRetailPriceStyleObject: {
        fontSize: this.cardRetailPriceFontsize
      },
      cardYearStyleObject: {
        fontSize: this.cardYearFontsize
      },
      cardPriceStyleObject: {
        fontSize: this.cardPriceFontsize,
        color: this.cardPriceColor
      }
    }
  },
  methods: {
    formatRetailPrice(priceObject) {
      try {
        if (priceObject) {
          let localRetailPrice = priceObject?.filter(el => el.price_code === this.localCurrency)?.[0].price ?? null;
          if (localRetailPrice) {
            localRetailPrice = localRetailPrice.toFixed(2);
            return '(UVP ' + localRetailPrice + ' ' + this.mapCurrencyUnit(this.localCurrency) + ')'
          }
        }
        return '-';
      }
      catch(err) { console.warn(err, 'Formatting of the retail price not possible!'); }
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
  }
}

</script>

<style scoped>

.card {
  cursor: pointer;
  display: grid;
  justify-content: center;
  margin: 0 1rem;
  transition: 0.5s ease transform;
}

.card:hover .image {
  transform: scale(1.2);
}

.discount-container {
  position: relative;
  display: grid;
  width: 60px;
  height: 40px;
  justify-content: center;
  align-content: center;
  background: rgb(187, 255, 244);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  z-index: 2;
  transform: translate(120px, 20px);
}

.discount-text {
  color: #09c5a6;
  font-size: 14px;
  font-weight: 700;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 140px;
  height: 140px;
  border-radius: 20px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  z-index: 1;
  transition: 0.5s ease transform;
}

.image {
  height: 100%;
  transition: 0.5s ease transform;
}

.text-container {
  position: relative;
  height: 180px;
  width: 180px;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.6) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transform: translateY(-20px);
}

.card-main {
  position: relative;
  display: grid;
  top: 15%;
  left: 10%;
  width: 85%;
  overflow: hidden;
  transition: .5s ease all;
}

.card-set {
  position: relative;
  display: block;
  font-size: 14px;
  color: #ffffff;
  font-weight: 300;
  line-height: 1.5;
}

.card-theme {
  position: relative;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.card-name {
  position: relative;
  display: block;
  min-height: 3rem;
  max-height: 3rem;
  font-size: 14px;
  color: #ffffff;
  overflow: hidden;
  font-weight: 800;
  line-height: 1.05;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.card-retail-price {
  position: relative;
  display: block;
  width: 90%;
  bottom: 22%;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  font-weight: 300;
}

.card-price {
  position: relative;
  width: 90%;
  color: #60ffe4;
  font-size: 18px;
  font-weight: 800;
  line-height: 1;
  text-shadow: 1px 1px 0px #0E545A;
}

.card-footer {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0;
  height: 15%;
  width: 100%;
}

.card-year {
  position: absolute;
  left: 10%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
}

#main_image_alt {
  height: 100%;
  font-size: 4rem;
  color: rgba(0, 0, 0, 0.2);
}

</style>
