<template>

  <template v-if="loggedIn.value">
    <section class="section-top">
      <v-container class="pa-0">
        <v-row no-gutters class="row_search_mobile">
          <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
        </v-row>

        <div class="container_card px-4">
          <div class="card">
            <div class="card-header">
              <span class="header-text">Collection importieren</span>
              <v-spacer></v-spacer>
              <i class="far fa-arrow-left icon" @click="pushCollectionRoute()"></i>
            </div>
            <div class="card-body">
              <v-row no-gutters class="mb-2">
                <span class="card-text">Importiere Deine Sammlung ganz einfach über unsere wunderbare Importfunktion.
                  <br> Dazu kannst Du eine der folgenden Alternativen wählen:</span>
              </v-row>

              <v-tabs v-model="tab" class="button_tab" center-active show-arrows>
                <v-tab value="csv">CSV Datei</v-tab>
                <v-tab value="brickset">Brickset</v-tab>
                <v-tab value="brickeconomy">BrickEconomy</v-tab>
              </v-tabs>
              <v-card-text class="py-4 px-0">
                <v-window v-model="tab">

                  <v-window-item value="csv">
                    <span class="card-text">Verwende diese Option, wenn Du Deine Datensätze in Microsoft Excel oder Apple Numbers gespeichert hast. <br>
                    Die Datei muss eine benutzerdefinierte CSV-Datei (kommagetrennte Werte) im von brickdex erwarteten Format sein.</span><br>
                    <h3>Anleitung:</h3>
                    <ol>
                      <li><span class="card-text">Erstelle eine ordnungsgemäß formatierte CSV-Datei.</span></li>
                      <li><span class="card-text">Als Übung: Erzeuge eine neue Textdatei und kopiere folgendes Beispiel und speichere diese als CSV-Datei:</span></li>
                      <div class="codeblock">
                        <code class="text_codeblock">Number,Qty owned used,Qty owned new <br>
                        21310-1,1,2</code>
                      </div>

                      <li><span class="card-text">Die erste Spalte muss die Setnummer enthalten (Beispiel 75192-1).<br>
                        Du kannst eine Setnummer-Variante einfügen, z.B. 75192-1. Ist keine Variante gepflegt, wird -1 verwendet.</span></li>
                      <li><span class="card-text">Die zweite Spalte muss die Menge des Sets (gebraucht) enthalten.</span></li>
                      <li><span class="card-text">Die dritte Spalte muss die Menge des Sets (neu) enthalten.</span></li>
                      <li><span class="card-text">Lade diese Datei nun hier unter <i id="icon_file_in_text" class="far fa-file-import"></i> hoch.</span></li>
                      <li><span class="card-text">Klicke auf <span class="text_bold">IMPORTIEREN</span> um Deine Brickset Sammlung hoch zu laden.<br>
                        Hinweis: Wenn Du bereis Sets in deiner Sammlung hier angelegt hast, werden die importierten Sets angehangen.</span></li>
                    </ol>
                  </v-window-item>

                  <v-window-item value="brickset">
                    <span class="card-text">Du hast Deine Sammlung bei Brickset erfasst? Kein Problem.<br>
                    Du kannst Deine Daten bequem in brickdex importieren und zukünftig die Reise mit uns weiter gehen.</span><br>
                    <h3>Anleitung:</h3>
                    <ol>
                      <li><span class="card-text">Geh auf <a target="blank_" href="https://brickset.com/" class="text-link">brickset.com</a> und melde Dich an.</span></li>
                      <li><span class="card-text">Klicke auf den Button <span class="text_bold">MY SETS</span> oben auf der Seite.</span></li>
                      <li><span class="card-text">Nun findest Du unter <span class="text_bold">SETS OWNED</span> den Button <span class="text_bold">EXPORT</span>.</span></li>
                      <li><span class="card-text">Gehe mit der Maus über den Button <span class="text_bold">EXPORT</span> und klicke auf <span class="text_bold">As CSV</span>.</span></li>
                      <li><span class="card-text">Speichere die Datei <span class="text_bold">Brickset-MySets-owned.csv</span> auf deinem Rechner.</span></li>
                      <li><span class="card-text">Lade diese Datei nun hier unter <i id="icon_file_in_text" class="far fa-file-import"></i> hoch.</span></li>
                      <li><span class="card-text">Klicke auf <span class="text_bold">IMPORTIEREN</span> um Deine Brickset Sammlung hoch zu laden.<br>
                        Hinweis: Wenn Du bereis Sets in deiner Sammlung hier angelegt hast, werden die importierten Sets angehangen.</span></li>
                    </ol>
                  </v-window-item>

                  <v-window-item value="brickeconomy">
                    <span class="card-text">Du hast Deine Sammlung bei BrickEconomy erfasst? Kein Problem.<br>
                    Du kannst Deine Daten bequem in brickdex importieren und zukünftig die Reise mit uns weiter gehen.</span><br>
                    <h3>Anleitung:</h3>
                    <ol>
                      <li><span class="card-text">Geh auf <a target="blank_" href="https://www.brickeconomy.com/" class="text-link">brickeconomy.com</a> und melde Dich an.</span></li>
                      <li><span class="card-text">Klicke auf den Button <span class="text_bold">My Collection</span> oben auf der Seite.</span></li>
                      <li><span class="card-text">Klicke auf <span class="text_bold">My Owned Sets</span> in der Liste.</span></li>
                      <li><span class="card-text">Nun findest Du unter <span class="text_bold">My Owned Sets</span> den Button <span class="text_bold">Import/Export</span> auf der rechten Seite.</span></li>
                      <li><span class="card-text">Unter dem Punkt <span class="text_bold">Export Sets</span> klicke auf <span class="text_bold">To a CSV file</span>.</span></li>
                      <li><span class="card-text">Wähle bei 1. <span class="text_bold">CSV file</span> und bei 2. <span class="text_bold">By sets</span> aus.</span></li>
                      <li><span class="card-text">Klicke anschließend auf <span class="text_bold">EXPORT</span> um Deine Sammlung zu speichern. <br>
                        Eine Datei mit dem Namen <span class="text_bold">BrickEconomy-Sets</span> wurde erzeugt.</span></li>
                      <li><span class="card-text">Lade diese Datei nun hier unter <i id="icon_file_in_text" class="far fa-file-import"></i> hoch.</span></li>
                      <li><span class="card-text">Klicke auf <span class="text_bold">IMPORTIEREN</span> um Deine BrickEconomy Sammlung hoch zu laden.<br>
                        Hinweis: Wenn Du bereis Sets in deiner Sammlung hier angelegt hast, werden die importierten Sets angehangen.</span></li>
                    </ol>
                  </v-window-item>

                </v-window>
              </v-card-text>
              <v-row class="row_input_file" no-gutters>
                <label class="custom_input_file">
                  <input type="file" name="input_file" accept="text/csv" @change="setFile" required/>
                  <i id="icon_file" class="far fa-file-import"></i>
                </label>
                <span class="text_input_file"> {{ defaultFileName }} </span>
              </v-row>
              <v-row no-gutters class="message-container">
                <span class="message">{{ message }}</span>
              </v-row>
              <div class="card-action">
                <PrimaryButton @click="pushCollectionRoute()" class="cancel-button" buttonText="Zurück" />
                <PrimaryButton @click="importCollection()" faIconClass="fa fa-upload" iconSize="18px" type="submit"/>
              </div>
            </div>
          </div>
        </div>

      </v-container>

    </section>
  </template>

  <template v-else>
    <section class="section-top" :style="'min-height:' + windowHeight">
      <v-container class="pa-0">
        <v-row no-gutters class="row_search_mobile">
          <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
        </v-row>
        <v-row no-gutters>
          <div class="hint-card">
            <i class="fal fa-info icon-hint"></i>
          </div>
        </v-row>
        <v-row no-gutters>
          <span class="hint-text">
            Um Deine Collection anlegen und verwalten zu können, musst Du
            <router-link class="text-link" to="/registration">registriert</router-link>
            sein.
          </span>
          <span class="hint-text">
            Hast Du bereits einen Account, dann
            <router-link class="text-link" to="/login">melde</router-link>
            Dich einfach an.
          </span>
        </v-row>
      </v-container>
    </section>
  </template>

</template>

<script>
import SearchMobile from '../../components/SearchMobile.vue';
import PrimaryButton from '../../components/PrimaryButton.vue';

export default {
  name: 'Collection',
  components: {
    SearchMobile,
    PrimaryButton
  },
  inject: [
    'api',
    'loggedIn'
  ],
  data() {
    return {
      windowHeight: null,
      defaultFileName: "Datei wählen ...",
      inputFile: null,
      tab: null,
      message: '',
      loading: true,
    }
  },
  watch: {

  },
  computed: {

  },
  created() {
    this.windowHeight = window.innerHeight - 234 + 'px'; // window height - footer height
  },
  methods: {
    setFile(event) {
      this.defaultFileName = event.target.files[0].name ? event.target.files[0].name : undefined
      this.inputFile = event.target.files[0] ? event.target.files[0] : undefined
    },
    async importCollection() {
      if (this.inputFile) {
        const formData = new FormData();

        formData.append('title', 'collection-import');
        formData.append('file', this.inputFile);

        const response = await this.api.transport.post('/import/collection', formData);
        if(response.status === 204 || response.status === 200) {
          setTimeout(() => this.$router.push({ path: '/usercollection' }), 1000);
        } else {
          this.message = 'Etwas ist schief gelaufen.';
          setTimeout(() => (this.message = ""), 3000);
        }
      }
      else {
        this.message = "Bitte lade eine CSV Datei hoch."
        setTimeout(() => (this.message = ""), 3000);
      }
    },
    pushCollectionRoute() {
      this.$router.push({ path: '/usercollection' });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.section-top {
  position: relative;
  height: 100%;
  padding-bottom: 5rem;
  background: linear-gradient(105deg, rgba(165,252,255,1) -10%, rgba(120,152,239,1) 25%, rgba(90,84,225,1) 73%, rgba(149,78,181,1) 100%);
}

.row_search_mobile {
  position: relative;
  width: 100%;
  padding-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.container_card {
  position: relative;
  justify-content: left;
}

.card {
  position: relative;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transition: 0.5s ease all;
  z-index: 3;
}

.card-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 44px;
}

.card-body {
  margin: 1rem 1.5rem;
}

.card-text {
  color: #ffffff;
  font-size: 12px;
}

.card-action {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 1rem;
  color: #5C5DD8 !important;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%) !important;
}

.row_input_file {
  align-items: center;
}

.custom_input_file {
  display: flex;
  color: #ffffff;
  width: 80px;
  height: 44px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg,rgba(255, 255, 255, 0.1)rgba(255, 255, 255, 0.3));
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid hsla(0,0%,100%,.8);
  border-radius: 999px;
  cursor: pointer;
  transition: 0.5s ease all;
}

.custom_input_file:hover {
  background: linear-gradient(135deg,rgba(255, 255, 255, 0.1)rgba(255, 255, 255, 0.4));
  box-shadow: 0px 2px 4px rgba(28, 8, 77, 0.3);
}

#icon_file {
  font-size: 16px;
  color: #ffffff;
}

#icon_file_in_text {
  margin-right: 0.25rem;
}

.text_input_file {
  margin-left: 1rem;
  color: #ffffff;
  font-size: 12px;
}

.button_tab {
  color: #60ffe4;
}

ol {
  margin: 0 0 1rem 1rem;
  color: #ffffff;
}

ol li {
  padding: 0.25rem 0;
}

.text-link {
  font-size: 16px;
}

.message-container {
  min-height: 24px;
}

.message {
  font-size: 16px;
  color: #ffd6f6;
  margin: 0 auto;
  transition: 0.5s ease all;
}

.codeblock {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  background: rgba(92, 93, 216, 0.2);
  border-radius: 10px;
}

.text_codeblock {
  color: #60ffe4;
  padding: 0.15rem 0;
  font-size: 12px;
}

.hint-card {
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-content: center;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.3) 100%);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.icon-hint {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  font-size: 42px;
  color: rgb(255, 255, 255);
}

.hint-text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.row-response-message {
  min-height: 2rem;
  padding: 0.75rem 0 1.5rem 2.2rem;
  transition: 0.5s ease all;
}

.response-message {
  font-size: 14px;
  color: #d5d9ff;
  transition: 0.5s ease all;
}

.v-tabs--density-default {
  height: 44px;
}

</style>
