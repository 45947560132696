<template>
  <div :id="galleryID">
    <a
      v-for="(image, key) in imagesData"
      :key="key"
      :href="image.largeURL"
      :data-pswp-width="image.width"
      :data-pswp-height="image.height"
      target="_blank"
      rel="noreferrer">
      <img class="main_image" :src="image.thumbnailURL" alt="" loading="lazy">
    </a>
  </div>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default {
  name: 'ZoomMainImage',
  props: {
    galleryID: String,
    images: Array,
  },
  setup(props) {
    return {
      imagesData: props.images,
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: '#' + this.$props.galleryID,
        children: 'a',
        showHideAnimationType: 'fade',
        pswpModule: () => import('photoswipe'),
        padding: { top: 60, bottom: 40, left: 80, right: 80 },
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {},
};
</script>

<style scoped>

.main_image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}

</style>
