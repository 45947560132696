<template>
  <label class="switch">
    <input v-model="toggleValue" type="checkbox">
    <span class="slider round"></span>
  </label>

</template>

<script>
export default {
  name: 'Toggle',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  computed: {
    toggleValue: {
      get() {
        if (this.modelValue !== undefined) return this.modelValue;
        return '';
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data() {
    return {

    }
  },
}



</script>

<style scoped>

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
  -webkit-transition: .8s;
  transition: .8s;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(255,255,255,0.2) 40%, rgba(255,255,255,0.5) 100%);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #60FFE4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #60FFE4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider:hover,
.input:focus:hover {
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
}

</style>
