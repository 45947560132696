<template>
  <div class="card">

    <div v-if="discountBadgeActive" class="discount-container">
      <p class="discount-text">-{{ discount }}%</p>
    </div>

    <div class="image_container_card" :style="imageContainerStyleObject">
      <template v-if="image">
        <v-img class="image_card" :src="image" :lazy-src="image"></v-img>
      </template>
      <template v-else>
        <template v-if="isItemMinifigure">
          <v-icon id="small_image_alt" icon="mdi-account-circle"></v-icon>
        </template>
        <template v-else-if="isItemSet">
          <v-icon id="small_image_alt" icon="mdi-package"></v-icon>
        </template>
        <template v-else>
          <v-icon id="small_image_alt" icon="mdi-cloud-question"></v-icon>
        </template>
      </template>
    </div>

    <div class="text-container" :style="textContainerStyleObject">
      <div class="card-main" :style="cardMainStyleObject">
        <span class="card-set">{{ set }}</span>
        <span v-if="themeActive" class="card-theme">{{ theme || '-' }}</span>
        <span class="mt-1 card-name">{{ name || '?' }}</span>
        <v-row no-gutters class="row_price">
          <span v-if="price" class="card-price" :style="cardPriceStyleObject">{{ price }}* <span>{{ this.mapCurrencyUnit(localCurrency) }}</span></span>
          <span class="card-retail-price">{{ retailPrice }}</span>
        </v-row>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'DealCardMobile',
  props: ['image', 'theme', 'year', 'set', 'name', 'retailPrice', 'price', 'priceActive', 'discount', 'discountBadgeActive', 'imageContainerWidth', 'imageContainerHeight', 'borderRadiusImage',
          'textContainerWidth', 'textContainerHeight', 'borderRadiusTextContainer', 'textContainerBackground', 'transformTextContainer', 'themeActive', 'textContainerMarginLeft', 'cardPriceFontsize',
          'cardPriceColor'],
  components: {

  },
  inject: [
    'localCurrency'
  ],
  data() {
    return {
      imageContainerStyleObject: {
        width: this.imageContainerWidth,
        height: this.imageContainerHeight,
        borderRadius: this.borderRadiusImage
      },
      textContainerStyleObject: {
        width: this.textContainerWidth,
        height: this.textContainerHeight,
        borderRadius: this.borderRadiusTextContainer,
        background: this.textContainerBackground,
        transform: this.transformTextContainer,
      },
      cardMainStyleObject: {
        marginLeft: this.textContainerMarginLeft
      },
      cardPriceStyleObject: {
        fontSize: this.cardPriceFontsize,
        color: this.cardPriceColor
      }
    }
  },
  methods: {
    mapCurrencyUnit(localCurrency) {
      if (localCurrency === 'eur') return '€';
      else if (localCurrency === 'usd') return '$';
      else if (localCurrency === 'gbp') return '£';
      else if (localCurrency === 'chf') return 'Fr';
      else return '';
    },
  }
}

</script>

<style scoped>

.card {
  cursor: pointer;
  display: grid;
  transition: 0.5s ease transform;
}

.card:hover .image_card {
  transform: scale(1.4);
}

.discount-container {
  position: absolute;
  display: grid;
  width: 60px;
  height: 30px;
  justify-content: center;
  align-content: center;
  background: rgb(187, 255, 244);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  right: 0;
  z-index: 3;
  transform: translateX(15px);
}

.discount-text {
  color: #09c5a6;
  font-size: 14px;
  font-weight: 700;
}



.image_card {
  height: 100%;
  transition: 0.5s ease transform;
}

.text-container {
  position: absolute;
  height: 80px;
  width: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.6) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transform: translate(10px, 10px);
}

.card-main {
  position: relative;
  width: 80%;
  margin-left: 5rem;
  overflow: hidden;
  transition: .5s ease all;
}

.card-set {
  position: relative;
  font-size: 12px;
  color: #ffffff;
  font-weight: 300;
}

.card-theme {
  position: relative;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.card-name {
  position: relative;
  display: block;
  min-height: 1.5rem;
  max-height: 1.5rem;
  width: 95%;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 800;
  line-height: 1;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
}

.row_price {
  display: flex;
  align-items: flex-end;
}

.card-retail-price {
  position: relative;
  margin-left: 0.5rem;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
}

.card-price {
  position: relative;
  color: #60ffe4;
  font-size: 14px;
  font-weight: 800;
  text-shadow: 1px 1px 0px #0E545A;
  line-height: 1;
}

.card-footer {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 12px;
  height: 15%;
  width: 100%;
}

.card-year {
  position: absolute;
  left: 10%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
}

.image_container_card {
  position: relative;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  z-index: 1;
  transition: 0.5s ease transform;
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.2);
}

</style>
