<template>
  <section>
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row-search-mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
      </v-row>
    </v-container>

    <template v-if="isMobile">
      <v-row class="ma-4">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title>Filter anzeigen</v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row no-gutters class="dialog-body">
                <v-combobox v-model="queryRetailer" :items="retailer" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" append-inner-icon="mdi-account-multiple" label="Händler" density="compact" theme="dark"></v-combobox>
                <template v-if="!queryTheme && !queryEol && !queryAvailability">
                  <v-combobox v-model="queryRelease" :items="years" clearable hide-details class=" mb-2 input_field mobile" color="#60ffe4" label="Release" append-inner-icon="mdi-calendar-month-outline" density="compact" theme="dark"></v-combobox>
                </template>
                <template v-if="!queryTheme && !queryRelease && !queryAvailability">
                  <v-combobox v-model="queryEol" :items="years" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="EOL" append-inner-icon="mdi-calendar-month-outline" density="compact" theme="dark"></v-combobox>
                </template>
                <template v-if="!queryRelease && !queryEol && !queryAvailability">
                  <v-combobox v-model="queryTheme" :items="themes" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" append-inner-icon="mdi-shape" label="Themenwelt" density="compact" theme="dark"></v-combobox>
                </template>
                <template v-if="!queryRelease && !queryEol && !queryTheme">
                  <v-combobox v-model="queryAvailability" :items="availabilities" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="Verfügbarkeit" append-inner-icon="mdi-cart" density="compact" theme="dark"></v-combobox>
                </template>
                <template v-if="queryTheme || queryRetailer || queryRelease || queryEol || queryAvailability">
                  <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                </template>
                <template v-else>
                  <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                </template>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
    <template v-else>
      <v-container>
        <v-row no-gutters class="row_filters">
          <v-col cols="3" class="mx-2 mb-4">
            <v-combobox v-model="queryRetailer" :items="retailer" clearable hide-details class="input_field" color="#60ffe4" append-inner-icon="mdi-account-multiple" label="Händler" theme="dark"></v-combobox>
          </v-col>
          <v-col v-if="!queryTheme && !queryEol && !queryAvailability" cols="2" class="mx-2 mb-4">
            <v-combobox v-model="queryRelease" :items="years" clearable hide-details class="input_field" color="#60ffe4" label="Release" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
          </v-col>
          <v-col v-if="!queryTheme && !queryRelease && !queryAvailability" cols="2" class="mx-2 mb-4">
            <v-combobox v-model="queryEol" :items="years" clearable hide-details class="input_field" color="#60ffe4" label="EOL" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
          </v-col>
          <v-col v-if="!queryRelease && !queryEol && !queryAvailability" cols="3" class="mx-2 mb-4">
            <v-combobox v-model="queryTheme" :items="themes" clearable hide-details class="input_field" color="#60ffe4" append-inner-icon="mdi-shape" label="Themenwelt" theme="dark"></v-combobox>
          </v-col>
          <v-col v-if="!queryRelease && !queryEol && !queryTheme" cols="3" class="mx-2 mb-4">
            <v-combobox v-model="queryAvailability" :items="availabilities" clearable hide-details class="input_field" color="#60ffe4" label="Verfügbarkeit" append-inner-icon="mdi-cart" theme="dark"></v-combobox>
          </v-col>
          <v-col cols="auto">
            <template v-if="queryTheme || queryRetailer || queryRelease || queryEol || queryAvailability">
              <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
            </template>
            <template v-else>
              <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-if="loadingActive">
      <div class="card_body_loader">
        <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
      </div>
    </template>
    <template v-else>
      <v-container v-if="isMobile" class="pa-0">
        <template v-if="sets.length > 0">
          <v-row no-gutters class="ml-4 row_deals">
            <div v-for="item in sets" :key="item.key" class="mb-6 deal_item">
              <DealCardMobile
              @click="pushRoute(item.set.set)"
              :set="`${item.set.set}`"
              :name="`${item.set.name[0]?.name ?? ''}`"
              discountBadgeActive=true
              :discount="`${item.discount.toFixed(1)}`"
              :retailPrice="formatRetailPrice(item.set?.retail_price[0]?.price ?? '')"
              :price="`${item.price.toFixed(2)}`"
              :year="getReleaseDate(item.set.release_date)"
              :image="getSetOverviewImage(item?.set?.header_image, item?.set?.images)"
              />
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row no-gutters>
            <span class="hint-text">Aktuell haben wir hier leider keinen Deal für Dich.<br>
              Aber wir suchen natürlich weiter...</span>
          </v-row>
        </template>
      </v-container>

      <v-container v-else fluid>
        <v-row v-if="sets.length > 0" no-gutters justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination v-model="queryPageCount" class="mb-4" :length="maxPageCount" color="#60ffe4"></v-pagination>
            </v-container>
          </v-col>
        </v-row>
        <template v-if="sets.length > 0">
          <v-row no-gutters class="row_deals desktop">
            <div v-for="item in sets" :key="item.key">
              <DealCard
              v-if="item.price"
              @click="pushRoute(item.set.set)"
              :set="`${item.set.set}`"
              :name="`${item.set.name[0]?.name ?? ''}`"
              discountBadgeActive=true
              :discount="`${item?.discount?.toFixed(1) ?? ''}`"
              :retailPrice="formatRetailPrice(item.set?.retail_price[0]?.price ?? '')"
              :price="`${item.price?.toFixed(2) ?? ''}`"
              :theme="`${item.set.theme?.name ?? ''}`"
              :yearActive=true
              :year="item.set.release_date"
              :image="getSetOverviewImage(item?.set?.header_image, item?.set?.images)"
              />
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row no-gutters>
            <span class="hint-text">Aktuell haben wir hier leider keinen Deal für Dich.<br>
              Aber wir suchen natürlich weiter...</span>
          </v-row>
        </template>
        <v-row v-if="sets.length > 0" no-gutters justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination v-model="queryPageCount" class="mb-4" :length="maxPageCount" color="#60ffe4"></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </section>
</template>

<script>
import Search from '../components/Search.vue';
import SearchMobile from '../components/SearchMobile.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import DealCard from '../components/DealCard.vue';
import DealCardMobile from '../components/DealCardMobile.vue';

export default {
  name: 'Deals',
  components: {
    PrimaryButton,
    SearchMobile,
    Search,
    DealCard,
    DealCardMobile
  },
  inject: [
    'api',
    'isMobile',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      mobile: false,
      themes: [],
      currentTheme: null,
      queryTheme: this.$route.query.theme || null,
      retailer: [],
      currentRetailer: null,
      queryRetailer: this.$route.query.retailer || null,
      years: [],
      currentYearRelease: null,
      queryRelease: this.$route.query.yearRelease || null,
      currentYearEol: null,
      queryEol: this.$route.query.yearEol || null,
      currentAvailability: null,
      queryAvailability: [
          { title: "LEGO Exklusiv" , value: "lego_exclusive" },
          { title: "Fachhandel" , value: "limited_retail" },
          { title: "Alle" , value: "retail" },
        ].find(el => el.value === this.$route.query.availability),
      sets: [],
      loadingActive: false,
      queryPageCount: 1,
      maxPageCount: '',
    }
  },
  watch: {
    queryFilterTheme() {
      this.queryTheme = this.$route.query.theme || null,
      this.getSets();
    },
    queryFilterRetailer() {
      this.queryRetailer = this.$route.query.retailer || null,
      this.getSets();
    },
    queryFilterYearRelease() {
      this.queryRelease = this.$route.query.yearRelease || null,
      this.getSets();
    },
    queryFilterYearEol() {
      this.queryEol = this.$route.query.yearEol || null,
      this.getSets();
    },
    queryFilterAvailability() {
      this.queryAvailability = [
        { title: "LEGO Exklusiv" , value: "lego_exclusive" },
        { title: "Fachhandel" , value: "limited_retail" },
        { title: "Alle" , value: "retail" },
      ].find(el => el.value === this.$route.query.availability);
      this.getSets();
    },
    queryPageCount() {
      this.getSets();
    },
    queryTheme(value) {
      if (value) {
        // check input length > 2; debounce query fire
        this.checkInput(value, 2, 'theme');
      }
      else {
        this.queryTheme = null;
        this.currentTheme = null;
        this.pushQuery();
      }
    },
    queryRetailer(value) {
      if (value) {
        // check input length > 2; debounce query fire
        this.checkInput(value, 2, 'retailer');
      }
      else {
        this.queryTheme = null;
        this.currentTheme = null;
        this.pushQuery();
      }
    },
    queryRelease(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'yearRelease');
      }
      else {
        this.queryRelease = null;
        this.currentYearRelease = null;
        this.pushQuery();
      }
    },
    queryEol(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'yearEol');
      }
      else {
        this.queryEol = null;
        this.currentYearEol = null;
        this.pushQuery();
      }
    },
    queryAvailability(value) {
      this.currentAvailability = value;
      this.pushQuery();
    },
  },
  computed: {
    queryFilterTheme() {
      return this.$route.query.theme || null;
    },
    queryFilterRetailer() {
      return this.$route.query.retailer || null;
    },
    queryFilterYearRelease() {
      return this.$route.query.yearRelease || null;
    },
    queryFilterYearEol() {
      return this.$route.query.yearEol || null;
    },
    queryFilterAvailability() {
      return this.$route.query.availability || null;
    },
  },
  created() {
    this.getSets();
    this.getThemes();
    this.getRetailer();
    this.getYears();
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
    this.availabilities = [
      { title: "LEGO Exklusiv" , value: "lego_exclusive" },
      { title: "Fachhandel" , value: "limited_retail" },
      { title: "Alle" , value: "retail" },
    ];
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    async getSets() {
      let res = [];
      this.sets = [];
      this.loadingActive = true;

      let filterObject = {};
      if (this.queryFilterTheme) { filterObject['set'] = {...filterObject['set'], ...{"theme": {"name": this.queryFilterTheme }}}}
      if (this.queryFilterYearRelease) { filterObject['set'] = {...filterObject['set'], ...{"release_date": {"_between": [ this.queryFilterYearRelease + '-01-01T12:00:00', this.queryFilterYearRelease + '-12-31T12:00:00']}}}}
      if (this.queryFilterYearEol) { filterObject['set'] = {...filterObject['set'], ...{"eol_date": {"_between": [ this.queryFilterYearEol + '-01-01T12:00:00', this.queryFilterYearEol + '-12-31T12:00:00']}}}}
      if (this.queryFilterAvailability) { filterObject['set'] = {...filterObject['set'], ...{"availability": this.queryFilterAvailability }}}
      if (this.queryFilterRetailer) { filterObject['retailer'] = {...filterObject['retailer'], ...{"name": this.queryFilterRetailer }}}
      filterObject['discount'] = {"_gt": 5}

      res = await this.api.items('retailer_prices?fields=*.*,set.name.name,set.retail_price.*,set.theme.name,set.header_image.id,set.images.directus_files_id.*'
      + '&sort=-discount'
      + '&meta=filter_count'
      + '&limit=50&page=' + this.queryPageCount).readByQuery({
        filter: filterObject
      });
      this.allSetsCount = res.meta.filter_count;
      this.maxPageCount = Math.ceil((this.allSetsCount/50));

      res.data.forEach(set => {
        let setData = {
          discount: set.discount || '',
          price: set.price || '',
          set: set.set || '',
        };
        if (setData.discount && setData.price && setData.set && setData.discount != null) {
          if (setData.discount > 0.00) {
            this.sets.push(setData);
          }
        }
      });

      const setsMapped = [];
      const map = new Map();
      for (const item of this.sets) {
        if (!map.has(item.set.set)) {
          map.set(item.set.set, true);
          setsMapped.push(item);
        }
      }
      this.sets = setsMapped;
      this.loadingActive = false;
    },
    async getThemes() {
      const res = await this.api.items('themes?fields=*.*&limit=-1').readByQuery();
      let sortedThemesData = this.sortArrayByName(res.data);
      sortedThemesData.forEach(theme => {
        this.themes.push(theme.name);
      });
    },
    async getRetailer() {
      const res = await this.api.items('retailer?fields=*.*&limit=-1').readByQuery();
      let sortedRetailerData = this.sortArrayByName(res.data);
      sortedRetailerData.forEach(retailer => {
        this.retailer.push(retailer.name);
      });
    },
    formatRetailPrice(retail_price) {
      if (retail_price) { return '(UVP ' + retail_price + ' ' + this.mapCurrencyUnit(this.localCurrency) + ')' }
      return '-';
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    getYears() {
      const currentYearRelease = new Date().getFullYear();
      for (let i = currentYearRelease; i > 1970; i--) {
        this.years.push(i.toString());
      }
    },
    resetFilter() {
      this.queryTheme = null;
      this.currentTheme = null;
      this.queryRetailer = null;
      this.currentRetailer = null;
      this.queryRelease = null;
      this.currentYearRelease = null;
      this.queryEol = null;
      this.currentYearEol = null;
      this.queryAvailability = null;
      this.currentAvailability = null;
      this.$router.push({ path: '/deals' })
    },
    pushRoute(set) {
      this.$router.push({ path: '/items/sets/' + set })
    },
    pushQuery() {
      let queryObject = {}
      if (this.currentTheme) { queryObject['theme'] = this.currentTheme }
      if (this.currentRetailer) { queryObject['retailer'] = this.currentRetailer }
      if (this.currentYearRelease) { queryObject['yearRelease'] = this.currentYearRelease }
      if (this.currentYearEol) { queryObject['yearEol'] = this.currentYearEol }
      if (this.currentAvailability) { queryObject['availability'] = this.currentAvailability.value }
      this.$router.push({ path: '/deals', query: queryObject});
    },
    sortArrayByName(array) {
      return array.sort((a, b) => (a.name > b.name) ? 1 : -1) ?? undefined;
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    debounceInputTheme(themeValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentTheme = themeValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputRetailer(retailerValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentRetailer = retailerValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputRelease(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearRelease = yearValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputEol(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearEol = yearValue;
        this.pushQuery();
      }, 500)
    },
    checkInput(inputValue, minChars, filter) {
      if (inputValue.length > 0) {
        if (inputValue.length > minChars) {
          if (filter === 'theme') this.debounceInputTheme(inputValue);
          if (filter === 'retailer') this.debounceInputRetailer(inputValue);
          if (filter === 'yearRelease') this.debounceInputRelease(inputValue);
          if (filter === 'yearEol') this.debounceInputEol(inputValue);
          return;
        }
        return;
      }
      return;
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1200px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}

.row-search-mobile {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.row_filters {
  display: flex;
  justify-content: center;
}
.row_deals {
  position: relative;
  width: 99%;
  margin-bottom: 2rem;
}

.row_deals.desktop {
  justify-content: center;
}

.deal_item {
  position: relative;
  width: 90%;
}

.hint-text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.card_body_loader {
  margin-top: 5rem;
}

.loader {
  width: 50%;
}

.reset_button {
  z-index: 1;
}

.v-expansion-panel {
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 10px;
  color: #ffffff;
}

.v-expansion-panel-title {
  font-size: 12px !important;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}


</style>
