<template>
  <section>

    <v-container class="container">
      <span class="header">Nutzungsbedingungen</span>

      <span class="policy-header">Allgemein</span>
      <span class="policy-data">brickdex GmbH</span>
      <span class="policy-data">Anton-Saefkow-Straße 2</span>
      <span class="policy-data">18069 Rostock</span>
      <br>
      <span class="policy-data">Telefon: +49 177 24 54 999</span>
      <span class="policy-data">E-Mail: hello@brickdex.io</span>
      <br>

      <span class="policy-header">§1 Zustandekommen des Vertragsverhältnisses,<br> Änderungen der Nutzungsbedingungen</span>
      <p class="policy-text">
        1.1 Die nachfolgenden Nutzungsbedingungen regeln das vertragliche Verhältnis zwischen dem Anbieter der Online-Plattform <b>brickdex.io</b> (nachfolgend auch Anbieter) und dem Nutzer.
      </p>
      <br>
      <p class="policy-text">
        1.2 Mit dem Abschluss des Registrierungsvorganges für die Online-Plattform <b>brickdex.io</b> gibt der Nutzer ein Angebot zum Abschluss eines Nutzungsvertrages auf der Grundlage
        dieser Nutzungsbedingungen ab. Mit der Freischaltung des Nutzeraccounts durch den Anbieter nimmt dieser das Angebot zum Abschluss eines Nutzungsvertrages an.
      </p>
      <br>
      <p class="policy-text">
        1.3 Entgegenstehende oder von diesen Nutzungsbedingungen abweichende Bedingungen des Nutzers werden im Zweifel nur durch eine ausdrückliche schriftliche Bestätigung durch den Anbieter anerkannt.
      </p>
      <br>
      <p class="policy-text">
        1.4 Der Anbieter ist berechtigt, diese Bedingungen während der laufenden Mitgliedschaft zu ändern und anzupassen. Der Anbieter wird dem Nutzer die geänderten Bedingungen in Textform übermitteln
        und auf die Neuregelungen besonders hinweisen. Zugleich wird der Anbieter dem Nutzer eine angemessene Frist für die Erklärung einräumen, ob er die geänderten Nutzungsbedingungen für die weitere
        Inanspruchnahme der Leistungen akzeptiert. Erfolgt innerhalb dieser Frist keine Erklärung, so gelten die geänderten Bedingungen als vereinbart. Der Anbieter wird den Nutzer bei Fristbeginn
        ausdrücklich auf diese Rechtsfolge hinweisen. Widerspricht der Nutzer der Änderung dieser Bedingungen, so ist der Anbieter berechtigt, den Nutzungsvertrag zum Zeitpunkt des Inkrafttretens der
        Nutzungsbedingungen gegenüber den übrigen Nutzern, fristlos zu kündigen.
      </p>
      <br>

      <span class="policy-header">§2 Gegenstand der Nutzungsvertrages</span>
      <p class="policy-text">
        2.1 Gegenstand des Nutzungsvertrages ist die Bereitstellung einer weltweit zugänglichen Online-Plattform durch den Anbieter. Die Online-Plattform ist folgendem Themenbereich gewidmet: LEGO Preisvergleich,
        Anlegen und Verwalten der eigenen LEGO Sammlung und damit verbundenen Services.
      </p>
      <br>
      <p class="policy-text">
        2.2 Die Online-Plattform <b>brickdex.io</b> stellt dem Nutzer folgende Nutzungsmöglichkeiten zur Verfügung:<br><br>
        <ul class="policy-list">
          <li>Anlegung eines eigenen Profils zur Person, welches den anderen registrierten Nutzern, nicht aber der Öffentlichkeit der Internetnutzer zugänglich ist.</li>
          <li>Anlegung eines eigenen Profils zur Person, welches den anderen registrierten Nutzern und darüber hinaus jedem Internetnutzer öffentlich zugänglich und über Suchmaschinen auffindbar ist.</li>
          <li>Upload von eigenem Content und Contentmanagement über den Nutzeraccount.</li>
          <li>Kontaktaufnahme zu anderen Nutzern, Verwalten der Kontaktdaten.</li>
          <li>Bestätigung und Ablehnung von Kontaktanfragen anderer Nutzer.</li>
          <li>Empfangen und Versenden und Mitteilungen von und zu anderen Nutzern.</li>
          <li>Betätigung einer Suchanfrage nach anderen Nutzern und nach Profildaten.</li>
          <li>Eintrag von Mitteilungen in Nutzer-Foren zu den dort vorgesehenen Themenbereichen.</li>
          <li>Eintrag von Mitteilungen und Kommentaren in Blogs zu den dort vorgesehenen Themenbereichen.</li>
        </ul>
      </p>
      <br>
      <p class="policy-text">
        2.3 Die konkrete grafische und funktionelle Ausgestaltung der vertragsgegenständlichen Nutzungsmöglichkeiten, die Erweiterung der Nutzungsmöglichkeiten mit zusätzlichen Features oder deren
        Ergänzung mit kostenpflichtigen Zusatzleistungen stehen im Ermessen des Anbieters. Der Anbieter ist unter Beibehaltung der vertragsgegenständlichen Nutzungsmöglichkeiten, jederzeit berechtigt,
        die konkrete Ausgestaltung zu modifizieren und anzupassen.
      </p>
      <br>
      <p class="policy-text">
        2.4 Die Plattform steht dem Nutzer zeitlich unbegrenzt zur Verfügung. Unvermeidbare, unvorhersehbare und außergewöhnliche Ereignisse, die zur Nichtverfügbarkeit der Plattform führen können,
        wie z.B. Stromausfälle, Hackingeingriffe, Ausfälle der Telekommunikationsleitungen ab dem Übergabepunkt an das Internet werden auf das Verfügbarkeitsminimum nicht angerechnet.
      </p>
      <br>

      <span class="policy-header">§3 Registrierung, Nutzeraccount, Umgang mit Passwörtern</span>
      <p class="policy-text">
        3.1 Für die Nutzung der Online-Plattform <b>brickdex.io</b> können sich:<br><br>
        <ul class="policy-list">
          <li>ausschließlich natürliche Personen</li>
          <li>natürliche oder juristische Personen registrieren.</li>
          <li>Die Registrierung einer juristischen Person kann nur über eine vertretungsberechtigungsberechtigte Person vorgenommen werden.</li>
          <li>Es können sich nur Nutzer registrieren, die mindestens 18 Jahre alt sind.</li>
        </ul>
      </p>
      <br>
      <p class="policy-text">
        3.2 Der Nutzer ist verpflichtet, gemäß den Vorgaben des Registrierungsformulars wahrheitsgemäße und vollständige Angaben zu seiner Person zu machen und seine Daten stets auf dem aktuellen Stand zu halten.
      </p>
      <br>
      <p class="policy-text">
        3.3 Der Nutzer ist verpflichtet, sein Passwort geheim zu halten, sicher aufzubewahren und Dritten gegenüber nicht zugänglich zu machen. Wird das Passwort gegenüber Dritten bekannt,
        so hat der Nutzer dem Anbieter hiervon unverzüglich per E-Mail oder schriftlich zu informieren, damit das alte Passwort gesperrt und ein neues Passwort vergeben werden kann.
        Der Nutzer ist nicht berechtigt, seinen Nutzeraccount Dritten zur Verfügung zu stellen. Der Nutzer nimmt zur Kenntnis, dass es für die Handlungen eines Dritten, dem er seinen Nutzeraccount
        zur Verfügung stellt ist, vollumfänglich verantwortlich ist.
      </p>
      <br>

      <span class="policy-header">§4 Allgemeine Pflichten des Nutzers</span>
      <p class="policy-text">
        4.1 Der Nutzer darf die vom Anbieter bereitgestellten Nutzungsmöglichkeiten nur im Rahmen der vertraglich vorausgesetzten Zwecke nutzen. Jede über diese Zweckbindung hinausgehende missbräuchliche
        Nutzung ist dem Nutzer untersagt, dazu zählen insbesondere folgende Handlungen:<br><br>
        <ul class="policy-list">
          <li>Systematisches Auslesen der Kontaktdaten anderer Nutzer zum Zwecke der Weitergabe an Dritte.</li>
          <li>Unzumutbare Belästigungen anderer Nutzer durch aggressive, obszöne, beleidigende, verleumderische oder aufdringliche Forenbeiträge oder Nachrichten.</li>
          <li>Unzumutbare Belästigungen anderer Nutzer durch das massenweise Versenden von unverlangter Werbung.</li>
          <li>Umfangreiche oder dauerhafte Nutzung der Plattform <b>brickdex.io</b> zur Veröffentlichung und Verbreitung von Inhalten, die sachlich nicht dem Zweck oder Themenbereich der Internetcommunity
            oder ihrer Blogs und Foren entsprechen und die die Attraktivität der Internetcommunity für andere Nutzer beeinträchtigen können.</li>
          <li>Jegliche Maßnahmen, die zu übermäßigen Systembelastungen oder zu Systemstörungen führen.</li>
          <li>Das Nutzen fremder Identitäten für die Registrierung, das Einstellen von Beiträgen oder das Versenden von Nachrichten.</li>
        </ul>
      </p>
      <br>
      <p class="policy-text">
        4.2 Jede der vorbenannten Pflichtverletzungen berechtigt den Anbieter zur fristlosen Kündigung des Nutzungsvertrages und zur Sperrung des Nutzeraccounts. Darüber hinaus ist der Anbieter
        berechtigt, die Freischaltung eines neuen Accounts für diesen Nutzer für die Dauer eines Jahres ab der Kündigung zu verweigern.
      </p>
      <br>
      <p class="policy-text">
        4.3 Äußerungen und Kommentare in Nachrichten, Foren- oder Blogeinträgen sollen den Regeln eines höflichen und respektvollen Umgangs untereinander folgen. Kritiken und Meinungsäußerungen
        sollten konstruktiv geäußert werden und dürfen.
      </p>
      <br>

      <span class="policy-header">§5 Pflichten des Nutzers bezüglich eingestellter Inhalte</span>
      <p class="policy-text">
        5.1 Der Nutzer hat sicherzustellen, dass er durch das Einstellen von Inhalten (Texte, Bilder, Fotos, Videos, Namen, Marken u. Ä.) keine Rechte Dritter verletzt (z.B. Verletzung von
        Urheberrechten, Persönlichkeitsrechtsverletzungen, Wettbewerbsverletzungen).
      </p>
      <br>
      <p class="policy-text">
        5.2 Der Nutzer stellt den Anbieter von sämtlichen Ansprüchen Dritter frei, die diese in Bezug auf eine von ihm begangene und von ihm zu vertretende Rechtsverletzung gegenüber dem Anbieter
        geltend machen. Der Nutzer übernimmt diesbezüglich sämtliche notwendigen Kosten der Rechtsverteidigung.
      </p>
      <br>
      <p class="policy-text">
        5.3 Der Anbieter ist berechtigt, die vom Nutzer eingestellten Inhalte zu löschen oder zu deaktivieren, wenn diese gegen die Rechte Dritter verstoßen oder Dritte wegen einer Rechtsverletzung
        Ansprüche geltend machen, deren Begründetheit nicht offenkundig auszuschließen ist.
      </p>
      <br>
      <p class="policy-text">
        5.4 Erhält der Anbieter von einer möglichen Rechtsverletzung durch die Inhalte des Nutzers Kenntnis, wird er dem Nutzer hiervon unverzüglich in Textform Mitteilung machen.
      </p>
      <br>

      <span class="policy-header">§6 Kündigung und Löschung des Nutzeraccounts</span>
      <p class="policy-text">
        6.1 Der Nutzer ist berechtigt, den Nutzungsvertrag mit dem Anbieter jederzeit ohne Angabe von Gründen und ohne Einhaltung einer Frist zu kündigen. Die Kündigung bedarf der Textform
        oder kann konkludent durch Deaktivierung des Nutzeraccounts erklärt werden. Im Falle der Kündigung wird das Profil des Nutzers gelöscht, seine in Foren oder Blogs veröffentlichten Beiträge
        und Inhalte bleiben jedoch, ohne Verlinkung zu seinem Profil, bestehen.
      </p>
      <br>
      <p class="policy-text">
        6.2 Der Anbieter ist berechtigt, den Nutzungsvertrag mit einer Frist von einem Monat zum Ende des vorangehenden Monats zu kündigen.
      </p>
      <br>
      <p class="policy-text">
        6.3 Der Anbieter ist neben der fristgebundenen Kündigung berechtigt, den Nutzungsvertrag ohne Einhaltung einer Frist zu kündigen, wenn ein wichtiger Grund vorliegt.
        Ein wichtiger Grund liegt insbesondere dann vor, wenn der Nutzer:<br><br>
        <ul class="policy-list">
          <li>nachhaltig und schwerwiegend gegen seine vertraglichen Pflichten verstößt (vgl. §4),</li>
          <li>bei der Nutzung der Online-Plattform schuldhaft und schwerwiegend gegen gesetzliche Vorschriften verstößt.</li>
          <li>In diesen Fällen ist der Anbieter auch berechtigt, den Nutzeraccount zu löschen und die Einrichtung eines neuen Accounts für diesen Nutzer für die Dauer eines Jahres nach Zugang der Kündigung zu verweigern.</li>
        </ul>
      </p>
      <br>
      <p class="policy-text">
        6.4 Der Anbieter wird von seinem Recht zur ordentlichen Kündigung und zur Löschung des Nutzeraccounts Gebrauch machen, wenn der Nutzer sich mindestens ein Jahr lang nicht mehr eingelogged
        und auf eine Erinnerungsnachricht nicht reagiert hat.
      </p>
      <br>

      <span class="policy-header">§7 Haftung</span>
      <p class="policy-text">
        7.1 Der Anbieter haftet in Fällen des Vorsatzes oder der groben Fahrlässigkeit nach den gesetzlichen Bestimmungen. Die Haftung für Garantien erfolgt verschuldensunabhängig.
        Für leichte Fahrlässigkeit haftet der Anbieter ausschließlich nach den Vorschriften des Produkthaftungsgesetzes, wegen der Verletzung des Lebens, des Körpers oder der Gesundheit oder
        wegen der Verletzung wesentlicher Vertragspflichten. Der Schadensersatzanspruch für die leicht fahrlässige Verletzung wesentlicher Vertragspflichten ist jedoch auf den vertragstypischen,
        vorhersehbaren Schaden begrenzt, soweit nicht wegen der Verletzung des Lebens, des Körpers oder der Gesundheit gehaftet wird. Für das Verschulden von Erfüllungsgehilfen und Vertretern haftet
        der Anbieter in demselben Umfang.
      </p>
      <br>
      <p class="policy-text">
        7.2 Die Regelung des vorstehenden §7.1 erstreckt sich auf Schadensersatz neben der Leistung, den Schadensersatz statt der Leistung und den Ersatzanspruch wegen vergeblicher Aufwendungen,
        gleich aus welchem Rechtsgrund, einschließlich der Haftung wegen Mängeln, Verzugs oder Unmöglichkeit.
      </p>
      <br>

      <span class="policy-header">§8 Schlussbestimmungen</span>
      <p class="policy-text">
        8.1 Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des Internationalen Privatrechts und des UN-Kaufrechts. Hiervon ausgenommen sind Ansprüche auf dem
        Gebiet der gewerblichen Schutzrechte und des Urheberrechts.
      </p>
      <br>
      <p class="policy-text">
        8.2 Für die Bestimmung des Gerichtsstandes gilt Folgendes:<br><br>
        <ul class="policy-list">
          <li>Hat der Nutzer keinen allgemeinen Gerichtsstand in der Bundesrepublik Deutschland oder ist der Nutzer Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen,
            so ist Gerichtsstand der Sitz des Anbieters. Der Anbieter kann den Nutzer auch an seinem allgemeinen Gerichtsstand verklagen.</li>
        </ul>
      </p>
      <br>
    </v-container>
  </section>
</template>

<script>

export default {
  name: 'Policies',
  components: {

  },
  inject: [
    'api'
  ],
  data() {
    return {

    }
  },
  created() {

  },
  unmounted() {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  height: 100%;
  background: rgb(251 245 255);
  padding-bottom: 4rem;
}

.header {
  font-size: 44px;
  font-weight: 800;
  color: rgb(0, 0, 0);
  margin-bottom: 2rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
}

.policy-header {
  font-size: 24px;
  font-weight: 700;
  margin: 1.5rem 0 0.5rem;
}

.policy-data,
.policy-text {
  font-size: 18px;
}

.policy-link {
  color: rgb(255, 108, 223);
  font-size: 18px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}

  .policy-link:hover {
    color: rgb(252, 179, 236);
    border-bottom: 1px solid rgb(252, 179, 236);
  }

.policy-text {
  width: 70%;
}

.policy-list {
  margin-left: 2rem;
}

</style>
