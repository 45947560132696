<template>
  <section>
    <template v-if="isMobile">
      <div class="header-container">
        <v-row no-gutters class="row_search_mobile">
          <SearchMobile class="search" :buttonIcon="'fa fa-search'" :placeholderText="'Ein spezielles ' + themeData.name + ' Set?'" />
        </v-row>
      </div>
    </template>
    <template v-else>
      <v-container class="header-container">
        <v-row no-gutters class="mb-6 row-search">
          <Search class="search" buttonText="Suchen" :placeholderText="'Ein spezielles ' + themeData.name + ' Set?'" />
        </v-row>
      </v-container>
    </template>

    <template v-if="isMobile">
      <v-container>
        <v-row justify="center" no-gutters>
          <h2 class="mb-4">{{ themeData?.name ?? '?' }}</h2>
        </v-row>
        <v-row justify="center" no-gutters >
          <div class="mr-8 info-card">
            <span class="info-label mobile">Marktstart</span>
            <span class="mb-4 info-value mobile">{{ themeData.year_from || '-' }}</span>
          </div>
          <div class="mr-8 info-card">
            <span class="info-label mobile">Sets</span>
            <span class="mb-4 info-value mobile">{{ themeData.set_count || '-' }}</span>
          </div>
          <div class="mr-8 info-card">
            <span class="info-label mobile">Minifiguren</span>
            <span class="mb-4 info-value mobile">{{ themeData.minifigure_count || '-' }}</span>
          </div>
          <div class="mr-8 info-card">
            <span class="info-label mobile">Unterthemen</span>
            <span class="mb-4 info-value mobile">{{ themeData.subtheme_count || '-' }}</span>
          </div>
          <div class="info-card">
            <span class="info-label mobile">UVP Summe</span>
            <span v-if="themeData.worth" class="info-value mobile">{{ themeData.worth }} <span>{{ this.mapCurrencyUnit(localCurrency) }}</span></span>
            <span v-else class="info-value mobile">-</span>
          </div>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-container>
        <v-row justify="center" no-gutters>
          <h1 class="mb-8 header-theme">{{ themeData?.name ?? '?' }}</h1>
        </v-row>
        <v-row class="mb-6 info-row" no-gutters>
          <v-col class="info-container">
            <div class="info-card">
              <span class="info-label">Marktstart</span>
              <span class="info-value">{{ themeData.year_from || '-' }}</span>
            </div>
          </v-col>
          <v-col class="info-container">
            <div class="info-card">
              <span class="info-label">Sets</span>
              <span class="info-value">{{ themeData.set_count || '-' }}</span>
            </div>
          </v-col>
          <v-col class="info-container">
            <div class="info-card">
              <span class="info-label">Minifiguren</span>
              <span class="info-value">{{ themeData.minifigure_count || '-' }}</span>
            </div>
          </v-col>
          <v-col class="info-container">
            <div class="info-card">
              <span class="info-label">Unterthemen</span>
              <span class="info-value">{{ themeData.subtheme_count || '-' }}</span>
            </div>
          </v-col>
          <v-col class="info-container">
            <div class="info-card">
              <span class="info-label">UVP Summe</span>
              <span v-if="themeData.worth" class="info-value">{{ themeData.worth }} <span>{{ this.mapCurrencyUnit(localCurrency) }}</span></span>
              <span v-else class="info-value">-</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-container fluid>
      <v-row justify="center" no-gutters>
        <h2 v-if="isMobile">Übersicht aller Sets</h2>
        <h2 v-else class="mt-8 mb-4">Übersicht aller Sets</h2>
      </v-row>
      <v-container>
        <template v-if="isMobile">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>Filter anzeigen</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-row no-gutters class="dialog-body">
                  <v-combobox v-model="queryRelease" :items="years" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="Release" append-inner-icon="mdi-calendar-month-outline" density="compact" theme="dark"></v-combobox>
                  <v-combobox v-model="queryEol" :items="years" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="EOL" append-inner-icon="mdi-calendar-month-outline" density="compact" theme="dark"></v-combobox>
                  <v-combobox v-model="queryAvailability" :items="availabilities" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="Verfügbarkeit" append-inner-icon="mdi-cart" density="compact" theme="dark"></v-combobox>
                  <template v-if="queryRelease || queryEol || queryAvailability">
                    <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                  </template>
                  <template v-else>
                    <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                  </template>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-else>
          <v-row no-gutters class="row_filters">
            <v-col  cols="2" class="mx-2">
              <v-combobox v-model="queryRelease" :items="years" clearable hide-details class="input_field" color="#60ffe4" label="Release" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
            </v-col>
            <v-col  cols="2" class="mx-2">
              <v-combobox v-model="queryEol" :items="years" clearable hide-details class="input_field" color="#60ffe4" label="EOL" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
            </v-col>
            <v-col  cols="3" class="mx-2">
              <v-combobox v-model="queryAvailability" :items="availabilities" clearable hide-details class="input_field" color="#60ffe4" label="Verfügbarkeit" append-inner-icon="mdi-cart" theme="dark"></v-combobox>
            </v-col>
            <v-col cols="auto" class="mx-2">
              <template v-if="queryRelease || queryEol || queryAvailability">
                <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
              </template>
              <template v-else>
                <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
              </template>
            </v-col>
          </v-row>
        </template>
      </v-container>


      <template v-if="loadingActive">
        <div class="card_body_loader">
          <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
        </div>
      </template>
      <template v-else>
        <v-row v-if="sets.length > 0" no-gutters justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination v-model="queryPageCount" class="mb-4" :length="maxPageCount" color="#60ffe4"></v-pagination>
            </v-container>
          </v-col>
        </v-row>
        <v-row no-gutters class="deal-row">
          <template v-if="isMobile">
            <div v-for="setItem in sets" :key="setItem.key" class="card mobile">
              <router-link :to="'/items/sets/' + setItem?.set ?? '-'">
                <SetCard
                :set="`${setItem.set}`"
                :name="`${this.mapNameToLocal(setItem?.name, localLanguage) || '-' }`"
                :retailPrice="setItem?.retail_price ?? ''"
                :year="getReleaseDate(setItem.release_date)"
                :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                :imageContainerWidth="'70px'"
                :imageContainerHeight="'70px'"
                :textContainerWidth="'90px'"
                :textContainerHeight="'100px'"
                :cardSetFontsize="'10px'"
                :cardNameFontsize="'12px'"
                :cardNameMinMaxHeight="'1.65rem'"
                :cardPriceFontsize="'12px'"
                :cardPriceColor="'#60ffe4'"
                :cardYearFontsize="'10px'"
                :borderRadius="'15px'"
                :transformTextContainer="'translateY(-15px)'"
                :lineClamp="2"
                />
              </router-link>
            </div>
          </template>
          <template v-else>
            <div v-for="setItem in sets" :key="setItem.key" class="card">
              <router-link :to="'/items/sets/' + setItem?.set ?? '-'">
                <div class="image-container-deals">
                  <v-img v-if="getSetHeaderImage(setItem.header_image)" class="image" :src="getSetHeaderImage(setItem.header_image, null)" :lazy-src="getSetHeaderImage(setItem.header_image, null)"></v-img>
                  <v-img v-else-if="getSetFallbackImage(setItem.images)" class="image" :src="getSetFallbackImage(setItem.images)" :lazy-src="getSetFallbackImage(setItem.images)"></v-img>
                  <v-icon v-else id="small_image_alt" icon="mdi-package" ></v-icon>
                </div>
                <div class="text-container">
                  <div class="card-main">
                    <span class="card-set">{{ setItem?.set ?? '-' }}</span>
                    <span class="card-name">{{ this.mapNameToLocal(setItem?.name, localLanguage) || '-' }}</span>
                    <span class="card-retail-price">UVP</span>
                    <span class="card-price">{{ this.mapRetailPriceToLocal(setItem.retail_price) || '-' }}</span>
                  </div>
                  <div class="card-footer">
                    <span v-if="setItem.release_date" class="card-year">{{ (new Date(setItem.release_date)).toLocaleDateString(localLanguage, {year: 'numeric'}) || '-' }}</span>
                    <span v-else class="card-year">-</span>
                  </div>
                </div>
              </router-link>
            </div>
          </template>
        </v-row>
        <v-row v-if="sets.length > 0" no-gutters justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination v-model="queryPageCount" class="mb-4" :length="maxPageCount" color="#60ffe4"></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </template>

    </v-container>

  </section>
</template>

<script>
import Search from '../../components/Search.vue';
import SearchMobile from '../../components/SearchMobile.vue';
import PrimaryButton from '../../components/PrimaryButton.vue';
import SetCard from '../../components/SetCard.vue';

export default {
  name: 'Theme',
  components: {
    Search,
    SearchMobile,
    PrimaryButton,
    SetCard,
  },
  inject: [
    'api',
    'isMobile',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      sets: [],
      themeData: {
        id: '',
        mapping: [],
        minifigure_count: '',
        name: '',
        set_count: '',
        subtheme_count: '',
        worth: '',
        year_from: '',
        year_until: ''
      },
      years: [],
      currentYearRelease: null,
      queryRelease: this.$route.query.yearRelease || null,
      currentYearEol: null,
      queryEol: this.$route.query.yearEol || null,
      currentAvailability: null,
      queryAvailability: [
          { title: "LEGO Exklusiv" , value: "lego_exclusive" },
          { title: "Fachhandel" , value: "limited_retail" },
          { title: "Überall" , value: "retail" },
        ].find(el => el.value === this.$route.query.availability),
      queryPageCount: 1,
      maxPageCount: '',
      loadingActive: false,
    }
  },
  watch: {
    routeTheme(value) {
      if (value) { this.getTheme(); }
    },
    queryFilterYearRelease() {
      this.queryRelease = this.$route.query.yearRelease || null,
      this.getSets();
    },
    queryFilterYearEol() {
      this.queryEol = this.$route.query.yearEol || null,
      this.getSets();
    },
    queryFilterAvailability() {
      this.queryAvailability = [
        { title: "LEGO Exklusiv" , value: "lego_exclusive" },
        { title: "Fachhandel" , value: "limited_retail" },
        { title: "Überall" , value: "retail" },
      ].find(el => el.value === this.$route.query.availability);
      this.getSets();
    },
    queryPageCount() {
      this.getSets();
    },
    queryRelease(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'yearRelease');
      }
      else {
        this.queryRelease = null;
        this.currentYearRelease = null;
        this.pushQuery();
      }
    },
    queryEol(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'yearEol');
      }
      else {
        this.queryEol = null;
        this.currentYearEol = null;
        this.pushQuery();
      }
    },
    queryAvailability(value) {
      this.currentAvailability = value;
      this.pushQuery();
    },
  },
  computed: {
    routeTheme() {
      return this.$route.params.theme || null;
    },
    queryFilterYearRelease() {
      return this.$route.query.yearRelease || null;
    },
    queryFilterYearEol() {
      return this.$route.query.yearEol || null;
    },
    queryFilterAvailability() {
      return this.$route.query.availability || null;
    },
  },
  created() {
    this.getSets();
    this.getTheme();
    this.getYears();
    this.availabilities = [
      { title: "LEGO Exklusiv" , value: "lego_exclusive" },
      { title: "Fachhandel" , value: "limited_retail" },
      { title: "Überall" , value: "retail" },
    ];
  },
  methods: {
    async getSets() {
      let res = [];
      this.sets = [];
      this.loadingActive = true;

      let filterObject = {};
      filterObject['theme'] = {"name": this.convertRouteToTheme(this.routeTheme)}
      if (this.queryFilterYearRelease) { filterObject['release_date'] = {"_between": [ this.queryFilterYearRelease + '-01-01T12:00:00', this.queryFilterYearRelease + '-12-31T12:00:00']}}
      if (this.queryFilterYearEol) { filterObject['eol_date'] = {"_between": [ this.queryFilterYearEol + '-01-01T12:00:00', this.queryFilterYearEol + '-12-31T12:00:00']}}
      if (this.queryFilterAvailability) { filterObject['availability'] = this.queryFilterAvailability }

      res = await this.api.items('sets?fields=*.*,images.directus_files_id.*'
      + '&sort=-release_date'
      + '&meta=filter_count'
      + '&limit=50&page=' + this.queryPageCount).readByQuery({
        filter: filterObject
      });
      this.allSetsCount = res.meta.filter_count;
      this.maxPageCount = Math.ceil((this.allSetsCount/50));
      this.sets = res.data;
      this.loadingActive = false;

    },
    async getTheme() {
      const res = await this.api.items('themes?fields=*.*&filter[name]=' + this.convertRouteToTheme(this.routeTheme)).readByQuery();
      const theme = res.data[0];

      this.themeData.id = theme.id || '';
      this.themeData.mapping = theme.mapping || '';
      this.themeData.minifigure_count = theme.minifigure_count || '';
      this.themeData.name = theme.name || '';
      this.themeData.set_count = theme.set_count || '';
      this.themeData.subtheme_count = theme.subtheme_count || '';
      this.themeData.worth = theme.worth?.toFixed(0) ?? '';
      this.themeData.year_from = theme.year_from || '';
      this.themeData.year_until = theme.year_until || '';
    },
    getSetHeaderImage(header_image) {
      if (header_image) {
        if (header_image.id) { return process.env.VUE_APP_API_IMG_PATH + header_image?.id + '?height=200' ?? ''; }
      }
      return '';
    },
    getSetFallbackImage(images) {
      if (images) {
        if (images[1]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[1]?.directus_files_id.id + '?height=200' ?? ''; }
        if (images[0]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[0]?.directus_files_id.id + '?height=200' ?? ''; }
      }
      return '';
    },
    getYears() {
      const currentYearRelease = new Date().getFullYear();
      for (let i = currentYearRelease; i > 1970; i--) {
        this.years.push(i.toString());
      }
    },
    resetFilter() {
      this.queryRelease = null;
      this.currentYearRelease = null;
      this.queryEol = null;
      this.currentYearEol = null;
      this.queryAvailability = null;
      this.currentAvailability = null;
      this.$router.push({ path: '/themes/' + this.convertRouteToTheme(this.routeTheme)});
    },
    pushQuery() {
      let queryObject = {}
      if (this.currentYearRelease) { queryObject['yearRelease'] = this.currentYearRelease }
      if (this.currentYearEol) { queryObject['yearEol'] = this.currentYearEol }
      if (this.currentAvailability) { queryObject['availability'] = this.currentAvailability.value }
      this.$router.push({ path: '/themes/' + this.convertRouteToTheme(this.routeTheme), query: queryObject});
    },
    convertRouteToTheme(routeTheme) {
      const themeWithSpaces = routeTheme.replaceAll('-', ' ');
      return themeWithSpaces;
    },
    mapRetailPriceToLocal(priceObject) {
      try {
        if (priceObject) {
          let localRetailPrice = priceObject?.filter(el => el.price_code === this.localCurrency)[0]?.price ?? null;
          if (localRetailPrice) {
            localRetailPrice = localRetailPrice.toFixed(2);
            return localRetailPrice + ' ' + this.mapCurrencyUnit(this.localCurrency);
          }
        }
        return '-';
      }
      catch(err) { console.warn(err, 'Formatting of the retail price not possible!'); }
    },
    mapCurrencyUnit(localCurrency) {
      if (localCurrency === 'eur') return '€';
      else if (localCurrency === 'usd') return '$';
      else if (localCurrency === 'gbp') return '£';
      else if (localCurrency === 'chf') return 'Fr';
      else return '';
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    debounceInputRelease(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearRelease = yearValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputEol(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearEol = yearValue;
        this.pushQuery();
      }, 500)
    },
    checkInput(inputValue, minChars, filter) {
      if (inputValue.length > 0) {
        if (inputValue.length > minChars) {
          if (filter === 'theme') this.debounceInputTheme(inputValue);
          if (filter === 'retailer') this.debounceInputRetailer(inputValue);
          if (filter === 'yearRelease') this.debounceInputRelease(inputValue);
          if (filter === 'yearEol') this.debounceInputEol(inputValue);
          return;
        }
        return;
      }
      return;
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  min-height: 1200px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  justify-content: center;
  z-index: 3;
}

.row_search_mobile {
  position: relative;
  width: 100%;
  padding-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.info-row {
  flex-direction: column;
}

.main_container {
  max-width: 1100px;
  margin-bottom: 1rem;
}

.header-theme {
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
}

.header {
  color: #ffffff;
  margin: 2rem auto 4rem auto;
}

.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-label {
  color: #60ffe4;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0.1rem;
  line-height: 0.8rem;
  text-transform: uppercase;
  z-index: 2;
}

.info-label.mobile {
  font-size: 10px;
  font-weight: 800;
}

.info-value {
  color: #ffffff;
  font-size: 34px;
  font-weight: 500;
  line-height: 2.5rem;
  z-index: 2;
}

.info-value.mobile {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5rem;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 140px;
  height: 140px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.5);
  transition: 0.5s ease transform;
  z-index: 2;
}

.image {
  height: 100%;
  transition: 0.5s ease transform;
}

.deal-row {
  justify-content: center;
}

.card {
  cursor: pointer;
  display: grid;
  justify-content: center;
  margin: 0 1rem;
  transition: 0.5s ease transform;
  z-index: 2;
}

.card:hover .image {
  transform: scale(1.2);
}

.card.mobile {
  margin: 0 auto;
}

.discount-container {
  position: relative;
  display: grid;
  width: 45px;
  height: 30px;
  justify-content: center;
  align-content: center;
  background: linear-gradient(135deg, rgba(255, 104, 104, 1) 30%, rgba(192, 72, 72, 1) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  z-index: 3;
  transform: translateY(20px);
}

.discount-text {
  color: rgb(15, 15, 15);
  font-size: 12px;
  font-weight: 800;
  text-shadow: 1px 1px 0px #FFE2EC;
}

.image-container-deals {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  transition: 0.5s ease transform;
  z-index: 2;
}

.image-container-deals.mobile {
  width: 70px;
  height: 70px;
  border-radius: 15px;
}

.text-container {
  position: relative;
  height: 140px;
  width: 140px;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.3) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  transform: translateY(-20px);
}

.text-container.mobile {
  height: 120px;
  width: 100px;
  border-radius: 15px;
  transform: translateY(-10px);
}

.card-main {
  position: relative;
  display: block;
  top: 20%;
  left: 10%;
  height: 67%;
  width: 80%;
  overflow: hidden;
  transition: .5s ease all;
}

.card-main.mobile {
  top: 15%;
  width: 95%;
}

.card-set {
  position: relative;
  display: block;
  font-size: 10px;
  color: #ffffff;
  font-weight: 300;
}

.card-name {
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 2.35rem;
  max-height: 2.35rem;
  width: 90%;
  font-size: 12px;
  color: #ffffff;
  font-weight: 800;
  line-height: 1;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
}

.card-name.mobile {
  min-height: 2.35rem;
  max-height: 2.35rem;
  width: 95%;
  font-size: 12px;
  font-weight: 800;
}

.card-retail-price {
  position: absolute;
  display: block;
  width: 90%;
  bottom: 22%;
  color: #ffffff;
  font-size: 10px;
  font-weight: 300;
}

.card-retail-price.mobile {
  width: 90%;
  bottom: 18%;
  font-size: 8px;
  font-weight: 300;
}


.card-price {
  position: absolute;
  display: block;
  width: 90%;
  bottom: 0;
  color: #60ffe4;
  font-size: 20px;
  font-weight: 700;
  text-shadow: 1px 1px 0px #0e545a;
}

.card-price.mobile {
  font-size: 14px;
  font-weight: 700;
}

.card-footer {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0;
  height: 15%;
  width: 100%;
}

.card-year {
  position: absolute;
  left: 10%;
  color: #ffffff;
  font-size: 10px;
  font-weight: 300;
}

.row_filters {
  display: flex;
  justify-content: center;
  z-index: 3;
}

.card_body_loader {
  margin-top: 5rem;
}

.loader {
  width: 50%;
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.2);
}

.v-expansion-panel {
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 10px;
  color: #ffffff;
}

.v-expansion-panel-title {
  font-size: 12px !important;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}


/* MEDIA SCREENS */
@media (min-width: 600px) {
  .info-row {
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .in-collection-row {
    justify-content: center;
  }

  .in-collection-items {
    margin: 1rem 0;
  }

  .data-column {
    max-width: 50%;
  }

  .in-collection-header {
    font-size: 14px;
    font-weight: 800;
  }

  .in-collection-value {
    font-size: 16px;
  }
}

</style>
