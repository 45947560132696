<template>
  <template v-if="isMobile">
    <div :id="galleryID">
      <a v-for="(image, key) in imagesData"
        class="gallery_image"
        :key="key"
        :href="image.largeURL"
        :data-pswp-width="image.width"
        :data-pswp-height="image.height"
        target="_blank"
        rel="noreferrer">
        <img class="media_image mobile" :src="image.thumbnailURL" alt="" loading="lazy">
      </a>
    </div>
  </template>
  <template v-else>
    <div :id="galleryID">
      <a v-for="(image, key) in imagesData"
        class="gallery_image"
        :key="key"
        :href="image.largeURL"
        :data-pswp-width="image.width"
        :data-pswp-height="image.height"
        target="_blank"
        rel="noreferrer">
        <img class="media_image" :src="image.thumbnailURL" alt="" loading="lazy">
      </a>
    </div>
  </template>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default {
  name: 'SimpleGallery',
  props: {
    galleryID: String,
    images: Array,
  },
  inject: [
    'isMobile'
  ],
  setup(props) {
    return {
      imagesData: props.images,
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: '#' + this.$props.galleryID,
        children: 'a',
        showHideAnimationType: 'fade',
        pswpModule: () => import('photoswipe'),
        padding: { top: 60, bottom: 40, left: 80, right: 80 },
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {},
};
</script>

<style scoped>

.media_image {
  width: 120px;
  height: 120px;
  margin: 0.5rem 1.8rem 0.5rem 0;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.4);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.4);
  cursor: zoom-in;
  object-fit: cover;
  transition: 0.5s ease all;
}

.media_image.mobile {
  width: 70px;
  height: 70px;
}

.media_image:hover {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.7);
  -moz-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.7);
}

.gallery_image {
  cursor: zoom-in;
}

a.gallery_image {
  width: 50px;
}

.pswp__img {
  background: #ffffff;
}

#image-copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  margin: 0.5rem 1rem;
}

</style>
