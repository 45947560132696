<template>
  <div class="dialog-overlay">
    <form @submit.prevent="deleteCollection()">
      <template v-if="isMobile">
        <div class="dialog_card mobile">
          <div class="dialog-header">
            <span class="header-text">Collection löschen</span>
            <v-spacer></v-spacer>
            <i class="fal fa-times icon close" @click="close()"></i>
          </div>

            <div class="dialog-body">
              <v-row no-gutters>
                <span class="dialog_text mobile">Hiermit löscht Du deine gesamte Collection!</span>
              </v-row>
              <v-row no-gutters>
                <span class="text_warning mobile">Achtung, dies kann nicht rückgängig gemacht werden! Wenn die Collection gelöscht wurde,
                  muss diese wieder neu importiert oder angelegt werden.
                </span>
              </v-row>
              <v-row no-gutters>
                <ol id="ol_mobile">
                  <li><span class="dialog_text mobile">Um wirklich ernst zu machen, tippe das Wort <span class="text_bold mobile">löschen</span> in das Textfeld ein.</span></li>
                  <li><span class="dialog_text mobile">Klicke anschließend auf den Button <span class="text_bold mobile"><i class="fal fa-trash-alt"></i></span>.</span></li>
                </ol>
              </v-row>
              <v-row no-gutters>
                <v-text-field @input="trimInput()" v-model="deleteInput" clearable class="input_field" color="#ffd1f5" append-inner-icon="mdi-delete-outline" label="Tippe 'löschen' ein" placeholder="löschen" hint="Kann nicht rückgängig gemacht werden" required></v-text-field>
              </v-row>
            </div>

          <div class="dialog-action">
            <PrimaryButton @click="close()" class="cancel-button" buttonText="Abbrechen" />
            <PrimaryButton class="delete_button" faIconClass="fal fa-trash-alt" type="submit" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="dialog_card">
          <div class="dialog-header">
            <span class="header-text">Collection löschen</span>
            <v-spacer></v-spacer>
            <i class="fal fa-times icon close" @click="close()"></i>
          </div>

            <div class="dialog-body">
              <v-row no-gutters>
                <span class="dialog_text">Hiermit löscht Du deine gesamte Collection!</span>
              </v-row>
              <v-row no-gutters>
                <span class="text_warning">Achtung, dies kann nicht rückgängig gemacht werden! Wenn die Collection gelöscht wurde,
                  muss diese wieder neu importiert oder angelegt werden.
                </span>
              </v-row>
              <v-row no-gutters>
                <ol>
                  <li><span class="dialog_text">Um wirklich ernst zu machen, tippe das Wort <span class="text_bold">löschen</span> in das Textfeld ein.</span></li>
                  <li><span class="dialog_text">Klicke anschließend auf den Button <span class="text_bold">LÖSCHEN</span>.</span></li>
                </ol>
              </v-row>
              <v-row no-gutters>
                <v-text-field @input="trimInput()" v-model="deleteInput" clearable class="input_field" color="#ffd1f5" append-inner-icon="mdi-delete-outline" label="Tippe 'löschen' ein" placeholder="löschen" hint="Kann nicht rückgängig gemacht werden" required></v-text-field>
              </v-row>
            </div>

          <div class="dialog-action">
            <PrimaryButton @click="close()" class="cancel-button" buttonText="Abbrechen" />
            <PrimaryButton class="delete_button" buttonText="Löschen" type="submit" />
          </div>
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import PrimaryButton from '../PrimaryButton.vue';

export default {
  name: 'ClearCollectionDialogDesktop',
  components: {
    PrimaryButton
  },
  props: ['collection'],
  inject: [
    'api',
    'isMobile'
  ],
  data() {
    return {
      deleteInput: "",
      deleteButtonActive: false,
    }
  },
  watch: {

  },
  created() {
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    close() {
      this.$emit('close');
    },
    trimInput() {
      this.deleteInput = this.deleteInput.trim();
    },
    async deleteCollection() {
      let setIDs = [];
      this.collection.forEach(SetEntry => {
        SetEntry.collection.forEach(item => {
          setIDs.push(item.id);
        });
      });
      await this.api.items('collections').deleteMany(setIDs);
      this.$router.go();
    },
  }
}
</script>

<style scoped>

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog_card {
  position: relative;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.dialog_card.mobile {
  margin: 0 auto;
  width: 90%;
}

.dialog-body {
  margin: 1rem 1.5rem;
}

.dialog_text {
  margin-bottom: 1rem;
  font-size: 14px;
  color: rgb(255, 255, 255);
}

.dialog_text.mobile {
  font-size: 12px;
}

.dialog-action {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 1rem;
  color: #5C5DD8;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%);
}

.text_warning {
  max-width: 600px;
  margin-bottom: 1rem;
  color: rgb(252, 179, 236);
  text-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
  font-size: 14px;
  font-weight: 700;
}

.text_warning.mobile {
  font-size: 12px;
}

ol {
  margin: 0 0 1rem 1rem;
  color: #ffffff;
}

ol li {
  padding: 0.25rem 0;
}

#ol_mobile {
  font-size: 12px;
}

</style>
