<template>
  <template v-if="isMobile">
    <div class="card-mobile">
      <div class="image-container" :style="containerHeight">
        <v-img class="image" :src="require(`../assets/${image}`)" :style="style"></v-img>
      </div>
      <div class="card-footer mobile">
        <div class="card-theme mobile">{{ theme }}</div>
      </div>
    </div>
  </template>

  <template v-else>
    <div class="card">
      <div class="image-container" :style="containerHeight">
        <v-img class="image" :src="require(`../assets/${image}`)" :style="style"></v-img>
      </div>
      <div class="card-footer">
        <div class="card-theme">{{ theme }}</div>
        <div class="card-sets">{{ sets }}</div>
      </div>
    </div>
  </template>
</template>

<script>
export default {
  name: 'ThemeCard',
  props: ['theme', 'sets', 'image', 'imageOffsetY', 'imageContainerHeight'],
  inject: [
    'isMobile'
  ],
  data() {
    return {

    }
  },
  computed: {
    containerHeight() {
      return "height:" + this.imageContainerHeight + "px;";
    },
    style() {
      return "transform: translateY(" + this.imageOffsetY + "px);";
    }
  },
  methods: {

  }
}



</script>

<style scoped>

.card {
  position: relative;
  height: 160px;
  width: 170px;
  background: linear-gradient(90deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.4) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
  margin: 1rem;
  transition: 0.5s ease transform;
}

.card-mobile {
  position: relative;
  height: 80px;
  width: 100px;
  background: linear-gradient(90deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.4) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 15px;
  margin: 1rem;
  transition: 0.5s ease transform;
}

.card:hover {
  transform: translateY(-10px);
}

.card:hover .image-container {
  transform: scale(1.1);
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: 0.5s ease transform;
}

.card-footer {
  position: absolute;
  bottom: 0;
  height: 55px;
  width: 100%;
  background: linear-gradient(90deg, rgb(143, 102, 255) 0%, rgb(252, 179, 236) 100%);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: .5s ease all;
}

.card-footer.mobile {
  height: 30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.card-theme {
  position: relative;
  top: 8px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.card-theme.mobile {
  top: 4px;
  font-size: 10px;
}

.card-sets {
  position: relative;
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
}

.card-sets.mobile {
  margin-top: 5px;
  font-size: 8px;
}

.image {
  position: absolute;
  width: 350px;
  margin: 0 auto;
  transform: translateY(-20px);
  transition: 0.5s ease width;
}

@media (min-width: 600px) {
  .card {
    width: 200px;
  }
}

@media (min-width: 1280px) {
  .card {
    height: 180px;
    width: 230px;
    margin: 30px;
  }

  .card-footer {
    height: 70px;
  }

  .card-theme {
    font-size: 24px;
  }

  .card-sets {
    font-size: 14px;
  }
}

@media (min-width: 1920px) {
  .card {
    height: 250px;
    width: 300px;
    margin: 40px;
  }

  .card-footer {
    height: 80px;
  }

  .card-theme {
    font-size: 24px;
  }

  .card-sets {
    font-size: 18px;
  }
}


</style>
