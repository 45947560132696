<template>
  <template v-if="isMobile">
    <section>

      <v-container class="pa-0">
        <v-row no-gutters class="row_search_mobile">
          <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
        </v-row>
        <v-row no-gutters>
          <ul class="ml-6 mb-4 mt-2 breadcrumb_mobile">
            <li><router-link class="link" :to="{ path: '/themes' }">Themenwelt</router-link></li>
            <li v-if="minifigure_data.themes && minifigure_data.themes?.length == 1"><router-link class="link" :to="{ path: getThemeLink(minifigure_data.themes[0])}">{{ minifigure_data.themes[0] }}</router-link></li>
            <li v-if="minifigure_data.subthemes && minifigure_data.subthemes?.length == 1"><router-link class="link" :to="{ path: '/items/sets-overview', query: { subtheme: minifigure_data.subthemes[0] } }">{{ minifigure_data.subthemes[0] }}</router-link></li>
          </ul>
        </v-row>
        <v-row no-gutters>
          <span class="ml-6 mb-4 minifig_name_mobile">{{ minifigure_data.number?.split('-')[0] }} {{ minifigure_data.name }}</span>
        </v-row>
        <v-row no-gutters class="image-actions-row">
          <div class="image-container mobile">
            <ZoomMainImage v-if="main_image?.length > 0" galleryID="main_image" :images="main_image" />
            <v-icon v-else id="main_image_alt" icon="mdi-account-circle" ></v-icon>
          </div>
        </v-row>

        <v-row no-gutters>
          <div class="mx-6 mt-6 card_main card_minifigure_prices">
            <div class="card-header">
              <p class="header-text header_mobile">Aktueller Angebotspreis</p>
              <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die Preise beziehen sich auf aktuelle Angebotspreise für den Zustand neu und gebraucht.">
                <i class="fas fa-question-circle info_tooltip"></i>
              </Popper>
            </div>
            <div class="card-body">
              <v-table density="compact" class="ml-6 table">
                <tr>
                  <td class="info-list-header text_mobile">Neu</td>
                  <td class="info-list-header text_mobile">Gebraucht</td>
                </tr>
                <tr>
                  <td>
                    <v-table density="compact" class="table">
                      <tr>
                        <td class="info-list-data text_mobile">Min. Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_new')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                      <tr>
                        <td class="info-list-data text_mobile">Max. Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_new')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                      <tr>
                        <td class="info-list-data text_mobile">Ø Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_new')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                    </v-table>
                  </td>
                  <td>
                    <v-table density="compact" class="table">
                      <tr>
                        <td class="info-list-data text_mobile">Min. Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_used')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                      <tr>
                        <td class="info-list-data text_mobile">Max. Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_used')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                      <tr>
                        <td class="info-list-data text_mobile">Ø Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_used')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                    </v-table>
                  </td>
                </tr>
              </v-table>

              <div class="card-header">
                <p class="header-text header_mobile">Verkaufspreis</p>
                <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Verkaufspreise der letzten sechs Monate für den Zustand neu und gebraucht.">
                  <i class="fas fa-question-circle info_tooltip"></i>
                </Popper>
              </div>
              <v-table density="compact" class="ml-6 table">
                <tr>
                  <td class="info-list-header text_mobile">Neu</td>
                  <td class="info-list-header text_mobile">Gebraucht</td>
                </tr>
                <tr>
                  <td>
                    <v-table density="compact" class="table">
                      <tr>
                        <td class="info-list-data text_mobile">Min. Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_new')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                      <tr>
                        <td class="info-list-data text_mobile">Max. Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_new')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                      <tr>
                        <td class="info-list-data text_mobile">Ø Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_new')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                    </v-table>
                  </td>
                  <td>
                    <v-table density="compact" class="table">
                      <tr>
                        <td class="info-list-data text_mobile">Min. Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_used')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                      <tr>
                        <td class="info-list-data text_mobile">Max. Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_used')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                      <tr>
                        <td class="info-list-data text_mobile">Ø Preis</td>
                        <td class="info-list-data text_mobile"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_used')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                      </tr>
                    </v-table>
                  </td>
                </tr>
              </v-table>
            </div>
          </div>
        </v-row>

        <v-row no-gutters>
          <div class="mx-6 mt-6 card_main card_minifigure_info">
            <div class="card-header">
              <p class="header-text header_mobile">Informationen</p>
            </div>
            <div class="card-body">
              <div class="info-list">
                <MinifigureInformationTable :minifigure_data="minifigure_data" />
              </div>
            </div>
          </div>
        </v-row>

        <v-row v-if="relatedSets.length > 0" no-gutters>
          <v-col cols="12">
            <div class="mx-6 mt-6 card_main card_related_sets">
              <div class="card-header">
                <span class="header-text header_mobile">In Sets enthalten</span>
              </div>
              <v-row class="my-2" no-gutters>
                <div v-for="setItem in relatedSets" :key="setItem.key">
                  <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
                    <SetCard
                    :set="`${setItem.set}`"
                    :name="`${setItem.name[0]?.name ?? ''}`"
                    :retailPrice="setItem?.retail_price ?? ''"
                    :year="getReleaseDate(setItem.release_date)"
                    :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                    :imageContainerWidth="'70px'"
                    :imageContainerHeight="'70px'"
                    :textContainerWidth="'90px'"
                    :textContainerHeight="'100px'"
                    :textContainerBackground="'none'"
                    :cardSetFontsize="'10px'"
                    :cardNameFontsize="'12px'"
                    :cardNameMinMaxHeight="'1.65rem'"
                    :cardPriceFontsize="'12px'"
                    :cardPriceColor="'#60ffe4'"
                    :cardYearFontsize="'10px'"
                    :borderRadius="'15px'"
                    />
                  </router-link>
                </div>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="relatedMinifigures.length > 0" no-gutters class="pb-6">
          <v-col cols="12">
            <div class="mx-6 mt-6 card_main card-minifigures">
              <div class="card-header">
                <span class="header-text header_mobile">Zu {{ this.minifigure_data.number }} {{ clearName(this.minifigure_data.name) }} passende Minifiguren</span>
                <v-spacer></v-spacer>
              </div>
              <div class="card-body minifigures">
                <v-row no-gutters class="ml-6 mt-4 mb-4">
                  <div class="minifigure_container mobile" v-for="minifig in relatedMinifigures" :key="minifig.key">
                    <router-link id="minifigure_link" :to="{ path: '/items/minifigures/' + minifig?.number }">
                      <div v-if="true" class="image_container_minifigures mobile">
                        <img v-if="getMinifigureImage(minifig?.images)" class="image minifigures" :src="getMinifigureImage(minifig?.images)" loading="lazy">
                        <v-icon v-else id="small_image_alt_mobile" icon="mdi-account-circle" ></v-icon>
                      </div>
                      <div class="minifigures_info_card">
                        <span class="minifigure_name mobile"> {{ minifig?.name ?? '-' }}</span>
                        <span class="minifigure_bricklink mobile">{{ minifig?.number ?? '-' }}</span>
                        <span v-if="minifig?.sets?.length" class="minifigure_bricklink mobile">in {{ minifig.sets?.length ?? '' }} Sets</span>
                      </div>
                    </router-link>
                  </div>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </template>
  <template v-else>
    <section>
      <v-container>
        <v-row no-gutters class="row-search">
          <Search class="search" buttonText="Suchen" placeholderText="Suchst du eine bestimmte Minifigur?" />
        </v-row>
      </v-container>

      <v-container>
        <v-row no-gutters class="row_minifig_header">
          <div class="image-container">
            <ZoomMainImage v-if="main_image?.length > 0" galleryID="main_image" :images="main_image" />
            <v-icon v-else id="main_image_alt" icon="mdi-account-circle" ></v-icon>
          </div>
          <v-col>
            <ul class="breadcrumb">
              <li><router-link class="link" :to="{ path: '/themes' }">Themenwelt</router-link></li>
                <li v-if="minifigure_data.themes && minifigure_data.themes?.length == 1"><router-link class="link" :to="{ path: getThemeLink(minifigure_data.themes[0])}">{{ minifigure_data.themes[0] }}</router-link></li>
                <li v-if="minifigure_data.subthemes && minifigure_data.subthemes?.length == 1"><router-link class="link" :to="{ path: '/items/sets-overview', query: { subtheme: minifigure_data.subthemes[0] } }">{{ minifigure_data.subthemes[0] }}</router-link></li>
            </ul>
            <h1 class="set_minifig_name">{{ minifigure_data.number?.split('-')[0] }} {{ minifigure_data.name }}</h1>

          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row no-gutters class="pt-4">

          <v-col class="px-4" cols="7">
            <div class="card_main card-set-info">
              <div class="card-header">
                <p class="header-text">Informationen</p>
              </div>
              <div class="card-body">
                <div class="info-list">
                  <MinifigureInformationTable :minifigure_data="minifigure_data" />
                </div>
              </div>
            </div>
          </v-col>

          <v-col class="px-4" cols="5">
            <div class="card_main card-set-info">
              <div class="card-header">
                <p class="header-text">Aktueller Angebotspreis</p>
                <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Die Preise beziehen sich auf aktuelle Angebotspreise für den Zustand neu und gebraucht.">
                  <i class="fas fa-question-circle info_tooltip"></i>
                </Popper>
              </div>
              <div class="card-body">
                <v-table density="compact" class="ml-6 table">
                  <tr>
                    <td class="info-list-header">Neu</td>
                    <td class="info-list-header">Gebraucht</td>
                  </tr>
                  <tr>
                    <td>
                      <v-table density="compact" class="table">
                        <tr>
                          <td class="info-list-data">Min. Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_new')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                        <tr>
                          <td class="info-list-data">Max. Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_new')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                        <tr>
                          <td class="info-list-data">Ø Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_new')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                      </v-table>
                    </td>
                    <td>
                      <v-table density="compact" class="table">
                        <tr>
                          <td class="info-list-data">Min. Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_used')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                        <tr>
                          <td class="info-list-data">Max. Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_used')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                        <tr>
                          <td class="info-list-data">Ø Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'current_used')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                      </v-table>
                    </td>
                  </tr>
                </v-table>

                <div class="card-header">
                  <p class="header-text">Verkaufspreis</p>
                  <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Verkaufspreise der letzten sechs Monate für den Zustand neu und gebraucht.">
                    <i class="fas fa-question-circle info_tooltip"></i>
                  </Popper>
                </div>
                <v-table density="compact" class="ml-6 table">
                  <tr>
                    <td class="info-list-header">Neu</td>
                    <td class="info-list-header">Gebraucht</td>
                  </tr>
                  <tr>
                    <td>
                      <v-table density="compact" class="table">
                        <tr>
                          <td class="info-list-data">Min. Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_new')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                        <tr>
                          <td class="info-list-data">Max. Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_new')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                        <tr>
                          <td class="info-list-data">Ø Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_new')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                      </v-table>
                    </td>
                    <td>
                      <v-table density="compact" class="table">
                        <tr>
                          <td class="info-list-data">Min. Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_used')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                        <tr>
                          <td class="info-list-data">Max. Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_used')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                        <tr>
                          <td class="info-list-data">Ø Preis</td>
                          <td class="info-list-data"><b>{{ minifigurePrices.find(item => item.bricklink_types_id === 'six_month_used')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                        </tr>
                      </v-table>
                    </td>
                  </tr>
                </v-table>
              </div>
            </div>
          </v-col>

        </v-row>

        <v-row v-if="relatedSets.length > 0" class="pt-8 px-4" no-gutters>
          <v-col cols="12">
            <div class="card_main card_related_sets">
              <div class="card-header">
                <span class="header-text">In Sets enthalten</span>
              </div>
              <v-row class="my-2" no-gutters>
                <div v-for="setItem in relatedSets" :key="setItem.key">
                  <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
                    <SetCard
                    :set="`${setItem.set}`"
                    :name="`${setItem.name[0]?.name ?? ''}`"
                    :retailPrice="setItem?.retail_price ?? ''"
                    :year="getReleaseDate(setItem.release_date)"
                    :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                    :imageContainerWidth="'120px'"
                    :imageContainerHeight="'120px'"
                    :textContainerWidth="'160px'"
                    :textContainerHeight="'110px'"
                    :textContainerBackground="'none'"
                    :cardSetFontsize="'12px'"
                    :cardNameFontsize="'12px'"
                    :cardNameMinMaxHeight="'1.65rem'"
                    :cardPriceFontsize="'14px'"
                    :cardPriceColor="'#60ffe4'"
                    :borderRadius="'15px'"
                    />
                  </router-link>
                </div>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="relatedMinifigures.length > 0" class="pt-8 px-4" no-gutters>
          <v-col cols="12">
            <div class="card_main card-minifigures">
              <div class="card-header">
                <span class="header-text">Zu {{ this.minifigure_data.number }} {{ clearName(this.minifigure_data.name) }} passende Minifiguren</span>
                <v-spacer></v-spacer>
              </div>
              <div class="card-body minifigures">
                <v-row no-gutters class="ml-6 mb-4">
                  <div class="minifigure_container" v-for="minifig in relatedMinifigures" :key="minifig.key">
                    <router-link id="minifigure_link" :to="{ path: '/items/minifigures/' + minifig?.number }">
                      <div v-if="true" class="image_container_minifigures">
                        <img v-if="getMinifigureImage(minifig?.images)" class="image minifigures" :src="getMinifigureImage(minifig?.images)" loading="lazy">
                        <v-icon v-else id="small_image_alt" icon="mdi-account-circle" ></v-icon>
                      </div>
                      <div class="minifigures_info_card">
                        <span class="minifigure_name"> {{ minifig?.name ?? '-' }}</span>
                        <span class="minifigure_bricklink">{{ minifig?.number ?? '-' }}</span>
                        <span v-if="minifig?.sets?.length" class="minifigure_bricklink">in {{ minifig.sets?.length ?? '' }} Sets</span>
                      </div>
                    </router-link>
                  </div>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </template>
</template>

<script>
import Search from '../../components/Search.vue';
import SearchMobile from '../../components/SearchMobile.vue';
import ZoomMainImage from '../../components/Dialogs/ZoomMainImage.vue';
import MinifigureInformationTable from '../MinifigureDetail/MinifigureInformationTable.vue';
import SetCard from '../../components/SetCard.vue';

export default {
  name: 'MinifigureDetails',
  components: {
    Search,
    SearchMobile,
    ZoomMainImage,
    MinifigureInformationTable,
    SetCard,
  },
  inject: [
    'api',
    'loggedIn',
    'isMobile',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      isSmallScreenWidth: null,
      infoDialog: false,
      collectionDialog: false,
      addSetDialogActive: false,
      ratilerHeader: null,
      responseMessage: '',

      minifigure_data: {
        images: [],
        name: '',
        number: '',
        release_date: '',
        sets: [],
        themes: [],
        subthemes: [],
      },
      minifigurePrices: [],
      relatedSets: [],
      relatedMinifigures: [],

      main_image: [],
    }
  },
  watch: {
    routeMinifigNumber(value) {
      if (value) { this.getMinifigure(); }
    },
  },
  computed: {
    routeMinifigNumber() {
      return this.$route.params.number || '';
    },
  },
  created() {
    this.getMinifigure();

    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
  },
  methods: {
    checkScreenWidth() {
      let windowWidth = window.innerWidth;
      if (windowWidth <= 970) {
        this.isSmallScreenWidth = true;
        return;
      }
      this.infoDialog = false;
      this.isSmallScreenWidth = false;
    },
    async getMinifigure() {
      this.relatedSets = [];
      let relatedMinifiguresNumbers = [];
      const res = await this.api.items('minifigures?fields=*.*,images.directus_files_id.*,'
      + 'sets.sets_set.*,sets.sets_set.name.*,sets.sets_set.images.*,sets.sets_set.header_image.*,'
      + 'sets.sets_set.theme.*,sets.sets_set.subtheme.*,sets.sets_set.minifigures.*,sets.sets_set.retail_price.*&filter[number]=' + this.routeMinifigNumber).readByQuery();
      const data = res.data[0];

      // Set minifigure_data values
      this.minifigure_data.number = data.number || '';
      this.minifigure_data.name = data.name || '';
      if (data.images) {
        this.minifigure_data.image = this.getMinifigureImage(data.images)
      }
      if (this.main_image.length > 0) { this.main_image.length = 0; }
      if (data.images?.length > 0) {
        let imgPath = process.env.VUE_APP_API_IMG_PATH + data.images[0].directus_files_id.id;
        this.main_image.push({
          largeURL: imgPath + '?height=400',
          thumbnailURL: imgPath + '?height=200',
          width: data.images[0].directus_files_id.width,
          height: data.images[0].directus_files_id.height,
        });

      }
      this.minifigure_data.release_date = data.release_date || '';
      this.minifigurePrices = data.bricklink_sales;
      if (data.sets) {
        let allSetThemes = [];
        let allSetSubthemes = [];
        let allSetMinifigures = [];
        data.sets.forEach(setItem => {
          if (setItem.sets_set) { this.relatedSets.push(setItem.sets_set); }
          allSetThemes.push(setItem.sets_set?.theme?.name);
          allSetSubthemes.push(setItem.sets_set?.subtheme?.name);
          setItem.sets_set?.minifigures.forEach(mf => {
            allSetMinifigures.push(mf.minifigures_number);
          });
        });
        let uniqueSetThemes = [...new Set(allSetThemes)];
        let uniqueSetSubthemes = [...new Set(allSetSubthemes)];
        let uniqueSetMinifigures = [...new Set(allSetMinifigures)];
        this.minifigure_data.themes = uniqueSetThemes.filter(e => e !== undefined);
        this.minifigure_data.subthemes = uniqueSetSubthemes.filter(e => e !== undefined);
        relatedMinifiguresNumbers = uniqueSetMinifigures.filter(e => e !== undefined).slice(0, 10);
      }

      let relatedMinifiguresFilter = {};
      let relatedFilterObjectArray = [];
      relatedMinifiguresNumbers.forEach(item => {
        let newObjMinifigureNumber = {
          "number": {
            "_eq": item
          }
        };
        relatedFilterObjectArray.push(newObjMinifigureNumber);
      });

      let filterMinifigureString = '';
      filterMinifigureString = this.clearName(this.minifigure_data.name);

      if (filterMinifigureString) {
        let newObjMinifigureName = {
          "name": {
            "_contains": filterMinifigureString
          }
        };
        relatedFilterObjectArray.push(newObjMinifigureName);
      }

      relatedMinifiguresFilter = { "_or": relatedFilterObjectArray }

      const relatedMinifiguresResponse = await this.api.items('minifigures?fields=*.*,images.directus_files_id.*'
      + '&sort=-release_date').readByQuery({
        filter: relatedMinifiguresFilter
      });
      this.relatedMinifigures = relatedMinifiguresResponse.data.filter(e => e.number !== this.minifigure_data.number);
    },
    getMinifigureImage(minifigureImages) {
      try {
        if (minifigureImages) {
          return process.env.VUE_APP_API_IMG_PATH + minifigureImages[0].directus_files_id.id + '?height=100' ?? '';
        }
        return '';
      }
      catch(err) { return ''; }
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
    getSetOverviewImage(header_image, images) {
      let imagePath = '';
      try {
        if (header_image) {
          if (header_image.id != null && header_image.id != undefined && header_image.id !== '') {
            imagePath = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
            return imagePath;
          }
        }
        else if (images) {
          if (images[1]?.directus_files_id != null && images[1]?.directus_files_id != undefined && images[1]?.directus_files_id !== '') {
            imagePath = process.env.VUE_APP_API_IMG_PATH + images[1]?.directus_files_id + '?height=200' ?? '';
            return imagePath
          }
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id != null && images[i]?.directus_files_id != undefined && images[i]?.directus_files_id !== '') {
              imagePath = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id + '?width=200' ?? '';
              return imagePath
            }
          }
        }
      }
      catch (err) {
        try {
          if (images) {
            for (let i = 0; i < images.length; i++) {
              if (images[i]?.directus_files_id != null && images[i]?.directus_files_id != undefined && images[i]?.directus_files_id !== '') {
                imagePath = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id + '?width=200' ?? '';
                return imagePath
              }
            }
          }
        }
        catch (err) { return imagePath;}
       }
      return imagePath;
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    getThemeLink(theme) {
      if (theme) {
        const themeNoSpecialChars = (((theme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
        const routeTheme = themeNoSpecialChars.toLowerCase();
        return '/themes/' + routeTheme;
      }
    },
    getSubthemeLink(subtheme) {
      if (subtheme) {
        const subthemeNoSpecialChars = (((subtheme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
        const routeSubtheme = subthemeNoSpecialChars.toLowerCase();
        return '/items/sets-overview?subtheme=' + routeSubtheme;
      }
    },
    clearName(string) {
      if (string) {
        if (string.includes('(')) { string = string.substring(0, string.indexOf('(')); }
        if (string.includes(',')) { string = string.substring(0, string.indexOf(',')); }
        if (string.includes(' - ')) { string = string.substring(0, string.indexOf(' - ')); }
        let arr = string.split(' ', 3);
        if (arr.length > 1) {
          let result = '';
          for (let i = 0; i < 3; i++) {
            if (arr[i]) { result = result.concat(arr[i] + ' '); }
          }
          return result;
        }
      }
      return string;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  position: relative;
  height: 100%;
  min-height: 1440px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.row-search {
  position: relative;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 40;
}

.row_search_mobile {
  position: relative;
  width: 100%;
  padding-top: 5rem;
  z-index: 40;
}

.row_minifig_header {
  position: relative;
  width: 100%;
  flex-wrap: nowrap;
  z-index: 3;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 160px;
  height: 160px;
  margin-left: 1rem;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  transition: 0.5s ease transform;
}

.image-container.mobile {
  width: 110px;
  height: 110px;
  margin-left: 1.5rem;
}

.image {
  display: flex;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: 0.5s ease transform;
}

.card-price,
.card-set-info,
.card_minifigure_prices,
.card_minifigure_info {
  height: auto;
  padding-bottom: 15px;
}

.info-list {
  margin-left: 1.5rem;
}

.table {
  background: none;
}

.header-text {
  margin-right: 0.25rem;
}

.card-body.minifigures {
  display: flex;
}

.minifigure_container {
  display: flex;
  flex-direction: column;
  width: 110px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  align-items: center;
}

.minifigure_container.mobile {
  width: 80px;
}

.image_container_minifigures {
  position: relative;
  width: 80px;
  height: 100px;
  margin: 0 auto;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  transition: 0.5s ease transform;
}

.image_container_minifigures.mobile {
  width: 50px;
  height: 70px;
}

.minifigures_info_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
}

.minifigure_name {
  height: 2.75rem;
  max-height: 2.75rem;
  font-size: 14px;
  margin: 0 0.5rem 0.5rem 0.5rem;
  color: #312659;
  text-shadow: 1px 1px 3px rgb(255 255 255 / 30%);
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.minifig_name_mobile {
  height: 3.25rem;
  max-height: 3.25rem;
  width: 90%;
  font-size: 24px;
  color: #ffffff;
  font-weight: 800;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.minifigure_bricklink {
  font-size: 14px;
  color: #ffffff;
  line-height: 1;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.minifigure_bricklink.mobile {
  font-size: 12px;
}

#main_image_alt {
  height: 100%;
  font-size: 5rem;
  color: rgba(0, 0, 0, 0.2);
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.2);
}

#small_image_alt_mobile {
  height: 100%;
  width: 100%;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.2);
}

.header_mobile {
  font-size: 14px;
}

ul.breadcrumb_mobile {
  list-style: none;
  line-height: 1.1;
  z-index: 2;
}

ul.breadcrumb_mobile li {
  display: inline;
  font-size: 18px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

ul.breadcrumb_mobile li+li:before {
  padding-left: 8px;
  padding-right: 4px;
  font-size: 12px;
  color: #ffffff;
  content: ">\00a0";
}

ul.breadcrumb_mobile li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: .5px;
}

.card_minifigure_prices,
.card_minifigure_info {
  width: 100%;
}

.text_mobile {
  font-size: 12px;
}

</style>
