<template>
  <section>

    <v-container class="container">
      <span class="header">Datenschutzerklärung</span>

      <span class="policy-header">Allgemein</span>
      <p class="policy-text">
        Als Betreiber dieser Website und als Unternehmen kommen wir mit Ihren personenbezogenen Daten in Kontakt. Gemeint sind alle Daten,
        die etwas über Sie aussagen und mit denen Sie identifiziert werden können. In dieser Datenschutzerklärung möchten wir Ihnen erläutern, in welcher Weise, zu welchem Zweck und auf welcher rechtlichen Grundlage wir Ihre Daten verarbeiten.
        <br>ür die Datenverarbeitung auf dieser Website und in unserem Unternehmen ist verantwortlich:
      </p>
      <br>
      <span class="policy-data">brickdex GmbH</span>
      <span class="policy-data">Anton-Saefkow-Straße 2</span>
      <span class="policy-data">18069 Rostock</span>
      <br>
      <span class="policy-data">Telefon: +49 177 24 54 999</span>
      <span class="policy-data">E-Mail: hello@brickdex.io</span>
      <br>

      <span class="policy-header">Allgemeine Hinweise</span>
      <span class="policy-header">SSL- bzw. TLS-Verschlüsselung</span>
      <p class="policy-text">
        Wenn Sie Ihre Daten auf Webseiten eingeben, Online-Bestellungen aufgeben oder E-Mails über das Internet verschicken, müssen Sie immer damit rechnen,
        dass unberechtigte Dritte auf Ihre Daten zugreifen. Einen vollständigen Schutz vor solchen Zugriffen gibt es nicht. Wir setzen jedoch alles daran,
        Ihre Daten bestmöglich zu schützen und die Sicherheitslücken zu schließen, soweit es uns möglich ist.<br>
        Ein wichtiger Schutzmechanismus ist die SSL- bzw. TLS-Verschlüsselung unserer Website, die dafür sorgt, dass Daten, die Sie an uns übermitteln,
        nicht von Dritten mitgelesen werden können. Sie erkennen die Verschlüsselung an dem Schloss-Icon vor der eingegebenen Internetadresse in Ihrem
        Browser und daran, dass unsere Internetadresse mit https:// beginnt und nicht mit http://.
      </p>

      <span class="policy-header">Wie lange speichern wir Ihre Daten?</span>
      <p class="policy-text">
        An manchen Stellen in dieser Datenschutzerklärung informieren wir Sie darüber, wie lange wir oder die Unternehmen, die Ihre Daten in unserem Auftrag
        verarbeiten, Ihre Daten speichern. Fehlt eine solche Angabe, speichern wir Ihre Daten, bis der Zweck der Datenverarbeitung entfällt, Sie der
        Datenverarbeitung widersprechen oder Sie Ihre Einwilligung in die Datenverarbeitung widerrufen.<br>
        Im Falle eines Widerspruchs oder Widerrufs dürfen wir Ihre Daten allerdings weiterverarbeiten, wenn mindestens eine der folgenden Voraussetzungen vorliegt:<br><br>
        <ul class="policy-list">
          <li>Wir haben zwingende schutzwürdige Gründe für die Fortsetzung der Datenverarbeitung, die Ihre Interessen, Rechte und Freiheiten überwiegen
            (nur bei Widerspruch gegen die Datenverarbeitung; wenn sich der Widerspruch gegen Direktwerbung richtet, können wir keine schutzwürdigen Gründe vorbringen).</li>
          <li>Die Datenverarbeitung ist erforderlich, um Rechtsansprüche geltend zu machen, auszuüben oder zu verteidigen (gilt nicht, wenn sich Ihr Widerspruch gegen Direktwerbung richtet).</li>
          <li>Wir sind gesetzlich verpflichtet, Ihre Daten aufzubewahren.</li>
          <li>In diesem Fall löschen wir Ihre Daten, sobald die Voraussetzung(en) entfällt bzw. entfallen.</li>
        </ul>
      </p>

      <span class="policy-header">Datenweitergabe in die USA</span>
      <p class="policy-text">
        Wir nutzen auf unserer Website auch Tools von Unternehmen, die Ihre Daten in die USA übermitteln und dort speichern und ggf. weiterverarbeiten.
        Für Sie ist das vor allem deshalb von Bedeutung, weil Ihre Daten in den USA nicht den gleichen Schutz genießen wie innerhalb der EU, wo die
        Datenschutzgrundverordnung (DSGVO) gilt. So sind US-Unternehmen z. B. dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben,
        ohne dass Sie als betroffene Person hiergegen gerichtlich vorgehen können. Es kann daher sein, dass US-Behörden (z. B. Geheimdienste) Ihre Daten auf
        US-amerikanischen Servern zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
      </p>

      <span class="policy-header">Ihre Rechte</span>
      <span class="policy-header">Widerspruch gegen die Datenverarbeitung</span>
      <p class="policy-text">
        WENN SIE IN DIESER DATENSCHUTZERKLÄRUNG LESEN, DASS WIR BERECHTIGTE INTERESSEN FÜR DIE VERARBEITUNG IHRER DATEN HABEN UND DIESE DESHALB AUF ART. 6 ABS. 1 SATZ 1 LIT. F)
        DSGVO STÜTZEN, HABEN SIE NACH ART. 21 DSGVO DAS RECHT, WIDERSPRUCH DAGEGEN EINZULEGEN. DAS GILT AUCH FÜR EIN PROFILING, DAS AUF GRUNDLAGE DER GENANNTEN VORSCHRIFT ERFOLGT.
        VORAUSSETZUNG IST, DASS SIE GRÜNDE FÜR DEN WIDERSPRUCH ANFÜHREN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN. EINE BEGRÜNDUNG IST NICHT ERFORDERLICH,
        WENN SICH DER WIDERSPRUCH GEGEN DIE NUTZUNG IHRER DATEN ZUR DIREKTWERBUNG RICHTET.<br>
        FOLGE DES WIDERSPRUCHS IST, DASS WIR IHRE DATEN NICHT MEHR VERARBEITEN DÜRFEN. DAS GILT NUR DANN NICHT, WENN EINE DER FOLGENDEN VORAUSSETZUNGEN VORLIEGT:<br><br>
        <ul class="policy-list">
          <li>WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN.</li>
          <li>DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN.</li>
        </ul><br>
        DIE AUSNAHMEN GELTEN NICHT, WENN SICH IHR WIDERSPRUCH GEGEN DIREKTWERBUNG RICHTET ODER GEGEN EIN PROFILING, DAS MIT DIESER IN VERBINDUNG STEHT.
      </p>

      <span class="policy-header">Weitere Rechte</span>
      <span class="policy-header">Widerruf Ihrer Einwilligung zur Datenverarbeitung</span>
      <p class="policy-text">
        Viele Datenverarbeitungsvorgänge erfolgen auf der Grundlage Ihrer Einwilligung. Diese erteilen Sie z. B. dadurch, dass Sie bei Online-Formularen ein entsprechendes Häkchen
        setzen, bevor Sie das Formular versenden, oder indem Sie bestimmte Cookies zulassen, wenn Sie unsere Website besuchen. Sie können Ihre Einwilligung jederzeit ohne Angabe
        von Gründen widerrufen (Art. 7 Abs. 3 DSGVO). Ab dem Zeitpunkt des Widerrufs dürfen wir Ihre Daten dann nicht mehr verarbeiten. Einzige Ausnahme: Wir sind gesetzlich
        verpflichtet, die Daten eine bestimmte Zeit lang aufzubewahren. Solche Aufbewahrungsfristen gibt es insbesondere im Steuer- und Handelsrecht.
      </p>

      <span class="policy-header">Recht zur Beschwerde bei der zuständigen Aufsichtsbehörde</span>
      <p class="policy-text">
        Wenn Sie der Auffassung sind, dass wir gegen die Datenschutzgrundverordnung (DSGVO) verstoßen, haben Sie nach Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde
        zu beschweren. Sie können sich an eine Aufsichtsbehörde in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Ortes wenden, an dem der mutmaßliche
        Verstoß stattgefunden hat. Das Beschwerderecht besteht neben verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfen.
      </p>

      <span class="policy-header">Recht auf Datenübertragbarkeit</span>
      <p class="policy-text">
        Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrages automatisiert verarbeiten, müssen wir Ihnen oder einem Dritten in einem gängigen maschinenlesbaren
        Format aushändigen, wenn Sie das verlangen. An einen anderen Verantwortlichen können wir die Daten nur übertragen, soweit dies technisch möglich ist.
      </p>

      <span class="policy-header">Recht auf Datenauskunft, -löschung und -berichtigung</span>
      <p class="policy-text">
        Sie haben nach Art. 15 DSGVO das Recht, unentgeltlich Auskunft darüber zu erhalten, welche personenbezogenen Daten wir von Ihnen gespeichert haben, wo die Daten herkommen,
        an wen wir die Daten übermitteln und zu welchem Zweck sie gespeichert werden. Sollten die Daten falsch sein, haben Sie ein Recht auf Berichtigung (Art. 16 DSGVO),
        unter den Voraussetzungen des Art. 17 DSGVO dürfen Sie verlangen, dass wir die Daten löschen.
      </p>

      <span class="policy-header">Recht auf Einschränkung der Verarbeitung</span>
      <p class="policy-text">
        In bestimmten Situationen können Sie nach Art. 18 DSGVO von uns verlangen, dass wir die Verarbeitung Ihrer Daten einschränken. Die Daten dürfen dann – von der Speicherung abgesehen –
        nur noch wie folgt verarbeitet werden:<br><br>
        <ul class="policy-list">
          <li>mit Ihrer Einwilligung</li>
          <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen</li>
          <li>zum Schutz der Rechte einer anderen natürlichen oder juristischen Person</li>
          <li>aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaates</li>
          <br>Das Recht auf Einschränkung der Verarbeitung besteht in den folgenden Situationen:
          <ul class="policy-list">
            <li>Sie haben die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestritten und wir benötigen Zeit, um dies zu überprüfen. Hier besteht das Recht für die Dauer der Prüfung.</li>
            <li>Die Verarbeitung Ihrer personenbezogenen Daten erfolgt zu Unrecht oder war in der Vergangenheit unrechtmäßig. Hier besteht das Recht alternativ zur Löschung der Daten.</li>
            <li>Wir benötigen Ihre personenbezogenen Daten nicht mehr, Sie benötigen sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen. Hier besteht das Recht alternativ zur Löschung der Daten.</li>
            <li>Sie haben Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt und nun müssen Ihre und unsere Interessen gegeneinander abgewogen werden. Hier besteht das Recht, solange das Ergebnis der Abwägung noch nicht feststeht.</li>
          </ul>
        </ul>
      </p>

      <span class="policy-header">Hosting und Content Delivery Networks (CDN)</span>
      <span class="policy-header">Externes Hosting</span>
      <p class="policy-text">
        Unsere Website liegt auf einem Server des folgenden Anbieters für Internetdienste (Hosters):
      </p>
      <br>
      <span class="policy-data">netcup GmbH</span>
      <span class="policy-data">Daimlerstraße 25</span>
      <span class="policy-data">76185 Karlsruhe</span>
      <br>
      <p class="policy-text" style="font-weight: 500">
        Wurde ein Vertrag über Auftragsverarbeitung mit dem Hoster geschlossen?<br>
      </p>
      <p class="policy-text">Ja</p>

      <span class="policy-header">Wie verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Der Hoster speichert alle Daten unserer Website. Dazu gehören auch alle personenbezogenen Daten, die automatisch oder durch Ihre Eingabe erfasst werden. Das können
        insbesondere sein: Ihre IP-Adresse, aufgerufene Seiten, Namen, Kontaktdaten und -anfragen sowie Meta- und Kommunikationsdaten. Bei der Datenverarbeitung hält sich
        die netcup GmbH an unsere Weisungen und verarbeitet die Daten stets nur insoweit, als dies erforderlich ist, um die Leistungspflicht uns gegenüber zu erfüllen.
      </p>

      <span class="policy-header">Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Da wir über unsere Website potenzielle Kunden ansprechen und Kontakte zu bestehenden Kunden pflegen, dient die Datenverarbeitung durch unseren Hoster der Vertragsanbahnung
        und -erfüllung und beruht daher auf Art. 6 Abs. 1 lit. b) DSGVO. Darüber hinaus ist es unser berechtigtes Interesse als Unternehmen, ein professionelles Internetangebot
        bereitzustellen, das die nötigen Anforderungen an Sicherheit, Geschwindigkeit und Effizienz erfüllt. Insoweit verarbeiten wir Ihre Daten außerdem auf der Grundlage von
        Art. 6 Abs. 1 lit. f) DSGVO.
      </p>

      <span class="policy-header">Datenerfassung auf dieser Website</span>
      <span class="policy-header">Verwendung von Cookies</span>
      <p class="policy-text">
        Unsere Website platziert Cookies auf Ihrem Gerät. Dabei handelt es sich um kleine Textdateien, mit denen unterschiedliche Zwecke verfolgt werden. Manche Cookies sind
        technisch notwendig, damit die Website überhaupt funktioniert (notwendige Cookies). Andere werden benötigt, um bestimmte Aktionen oder Funktionen auf der Site ausführen
        zu können (funktionale Cookies). So wäre es beispielsweise ohne Cookies nicht möglich, die Vorzüge eines Warenkorbs in einem Online-Shop zu nutzen. Wieder andere Cookies
        dienen dazu, das Nutzerverhalten zu analysieren oder Werbemaßnahmen zu optimieren. Wenn wir Dienstleistungen Dritter auf unserer Website nutzen, z. B. zur Abwicklung von
        Zahlungsvorgängen, können auch diese Unternehmen Cookies auf Ihrem Gerät hinterlassen, wenn Sie die Website aufrufen (sog. Third-Party-Cookies).
      </p>

      <span class="policy-header">Wie verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Session-Cookies werden nur für die Dauer einer Sitzung auf Ihrem Gerät gespeichert. Sobald Sie den Browser schließen, verschwinden sie also von selbst. Permanent-Cookies
        bleiben dagegen auf Ihrem Gerät, wenn Sie sie nicht selbst löschen. Das kann z. B. dazu führen, dass Ihr Nutzerverhalten dauerhaft analysiert wird. Sie können über die
        Einstellungen in Ihrem Browser Einfluss darauf nehmen, wie er mit Cookies umgeht:<br><br>
        <ul class="policy-list">
          <li>Wollen Sie informiert werden, wenn Cookies gesetzt werden?</li>
          <li>Wollen Sie Cookies generell oder für bestimmte Fälle ausschließen?</li>
          <li>Wollen Sie, dass Cookies beim Schließen des Browsers automatisch gelöscht werden?</li>
        </ul><br>
        Wenn Sie Cookies deaktivieren bzw. nicht zulassen, kann die Funktionalität der Website eingeschränkt sein.<br>
        Sofern wir Cookies von anderen Unternehmen oder zu Analysezwecken einsetzen, informieren wir Sie hierüber im Rahmen dieser Datenschutzerklärung. Auch fragen wir diesbezüglich Ihre Einwilligung ab, wenn Sie unsere Website aufrufen.
      </p>

      <span class="policy-header">Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Wir haben ein berechtigtes Interesse daran, dass unsere Online-Angebote ohne technische Probleme von den Besuchern genutzt werden können und ihnen alle gewünschten Funktionen
        zur Verfügung stehen. Die Speicherung notwendiger und funktionaler Cookies auf Ihrem Gerät erfolgt daher auf der Grundlage von Art. 6 Abs. 1 lit. f) DSGVO. Alle anderen Cookies
        setzen wir auf der Grundlage von Art. 6 Abs. 1 lit. a) DSGVO ein, sofern Sie uns eine entsprechende Einwilligung erteilen. Diese können Sie jederzeit mit Wirkung für die Zukunft
        widerrufen. Haben Sie bei der Abfrage der Einwilligung in die Platzierung notwendiger und funktionaler Cookies eingewilligt, erfolgt auch die Speicherung dieser Cookies
        ausschließlich auf der Grundlage Ihrer Einwilligung.
      </p>

      <span class="policy-header">Server-Log-Dateien</span>
      <p class="policy-text">
        Server-Log-Dateien protokollieren alle Anfragen und Zugriffe auf unsere Website und halten Fehlermeldungen fest. Sie umfassen auch personenbezogene Daten, insbesondere
        Ihre IP-Adresse. Diese wird allerdings schon nach kurzer Zeit vom Provider anonymisiert, sodass wir die Daten nicht Ihrer Person zuordnen können. Die Daten werden
        automatisch von Ihrem Browser an unseren Provider übermittelt.
      </p>

      <span class="policy-header">Wie verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Unser Provider speichert die Server-Log-Dateien, um die Aktivitäten auf unserer Website nachvollziehen zu können und Fehler ausfindig zu machen.
        Die Dateien enthalten die folgenden Daten:<br><br>
        <ul class="policy-list">
          <li>Browsertyp und -version</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer-URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse (ggf. anonymisiert)</li>
        </ul><br>
        Wir führen diese Daten nicht mit anderen Daten zusammen, sondern nutzen sie lediglich für die statistische Auswertung und zur Verbesserung unserer Website.
      </p>

      <span class="policy-header">Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Wir haben ein berechtigtes Interesse daran, dass unsere Website fehlerfrei läuft. Auch ist es unser berechtigtes Interesse, einen anonymisierten Überblick über die Zugriffe auf unsere Website zu erhalten.
        Die Datenverarbeitung ist deshalb gemäß Art. 6 Abs. 1 lit. f) DSGVO rechtmäßig.
      </p>

      <span class="policy-header">Kontaktformular</span>
      <p class="policy-text">
        Sie können uns über das Kontaktformular auf dieser Website eine Nachricht zukommen lassen.
      </p>

      <span class="policy-header">Wie verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Wir speichern Ihre Nachricht und die Angaben aus dem Formular, um Ihre Anfrage inklusive Anschlussfragen bearbeiten zu können. Das betrifft auch die angegebenen Kontaktdaten.
        Ohne Ihre Einwilligung geben wir die Daten nicht an andere Personen weiter.
      </p>

      <span class="policy-header">Wie lange speichern wir Ihre Daten?</span>
      <p class="policy-text">
        Wir löschen Ihre Daten, sobald einer der folgenden Punkte eintritt:<br><br>
        <ul class="policy-list">
          <li>Ihre Anfrage wurde abschließend bearbeitet.</li>
          <li>Sie fordern uns zur Löschung der Daten auf.</li>
          <li>Sie widerrufen Ihre Einwilligung zur Speicherung.</li>
        </ul><br>
        Das gilt nur dann nicht, wenn wir gesetzlich dazu verpflichtet sind, die Daten aufzubewahren.
      </p>

      <span class="policy-header">Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Sofern Ihre Anfrage mit unserer vertraglichen Beziehung in Zusammenhang steht oder der Durchführung vorvertraglicher Maßnahmen dient, verarbeiten wir Ihre Daten auf der
        Grundlage von Art. 6 Abs. 1 lit. b) DSGVO. In allen anderen Fällen ist es unser berechtigtes Interesse, an uns gerichtete Anfragen effektiv zu bearbeiten.
        Rechtsgrundlage der Datenverarbeitung ist somit Art. 6 Abs. 1 lit. f) DSGVO. Haben Sie in die Speicherung Ihrer Daten eingewilligt, ist Art. 6 Abs. 1 lit.
        a) DSGVO die Rechtsgrundlage. In diesem Fall können Sie Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.
      </p>

      <span class="policy-header">Anfrage per E-Mail, Telefon oder Telefax</span>
      <p class="policy-text">
        Sie können uns per E-Mail eine Nachricht zukommen lassen oder uns anrufen.
      </p>

      <span class="policy-header">Analyse-Tools und Werbung</span>
      <p class="policy-text">
        Wir nutzen die folgenden Tools, um das Verhalten unserer Websitebesucher zu analysieren und ihnen Werbung zu zeigen.
      </p>

      <span class="policy-header">etracker</span>
      <p class="policy-text" style="font-weight: 500">
        Was ist etracker?<br>
      </p>
      <p class="policy-text">
        Tool zur Analyse des Nutzerverhaltens
      </p>
      <p class="policy-text" style="font-weight: 500">
        Wer verarbeitet Ihre Daten?<br>
      </p>
      <p class="policy-text">
        etracker GmbH, Erste Brunnenstraße 1, 20459 Hamburg, Deutschland
      </p>
      <p class="policy-text" style="font-weight: 500">
        Wurde ein Vertrag über Auftragsverarbeitung mit etracker geschlossen?<br>
      </p>
      <p class="policy-text">
        Ja
      </p>
      <p class="policy-text" style="font-weight: 500">
        Wo finden Sie weitere Informationen über den Datenschutz bei etracker?<br>
      </p>
      <p class="policy-text">
        <a class="policy-link" href="https://www.etracker.com/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutz - etracker</a>
      </p>

      <span class="policy-header">Wie verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Wir sind stets daran interessiert, unser Webangebot für die Nutzer zu optimieren und Werbung optimal zu platzieren. Dabei hilft uns etracker, ein Tool,
        welches das Verhalten der Nutzer analysiert und uns so die nötige Datenbasis für Anpassungen liefert. Das Besondere an etracker ist, dass es Analyse- und
        Optimierungs-Cookies nur dann einsetzt, wenn Sie dem vorher zugestimmt haben. In diesem Fall führt das Tool eine statistische Reichweiten-Analyse unserer
        Website und eine Erfolgsmessung unserer Online-Marketing-Maßnahmen durch. Zudem ermöglicht es Testverfahren, um z. B. unterschiedliche Versionen unseres
        Online-Angebotes oder seiner Bestandteile zu testen und zu optimieren. Die Cookies von etracker enthalten keine Informationen, über die Sie identifiziert
        werden können, und wenn doch, werden diese frühestmöglich anonymisiert oder pseudonymisiert.
      </p>

      <span class="policy-header">Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Als Websitebetreiber haben wir ein berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens zu dem Zweck, unser Webangebot und die dort platzierte
        Werbung zu optimieren. Die Datenverarbeitung ist deshalb nach Art. 6 Abs. 1 lit. f) DSGVO rechtmäßig. Für den Fall, dass Sie z. B. der Speicherung von Cookies zugestimmt
        oder auf sonstige Weise in die Datenverarbeitung durch etracker eingewilligt haben, ist ausschließlich Art. 6 Abs. 1 lit. a) DSGVO die Rechtsgrundlage.
        Sie können die Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.
      </p>

      <span class="policy-header">Newsletter und Postwerbung</span>
      <span class="policy-header">Rapidmail</span>
      <p class="policy-text" style="font-weight: 500">
        Was ist Rapidmail?<br>
      </p>
      <p class="policy-text">
        Dienst zum Versand von Newslettern und zur Analyse des Empfängerverhaltens
      </p>
      <p class="policy-text" style="font-weight: 500">
        Wer verarbeitet Ihre Daten?<br>
      </p>
      <p class="policy-text">
        rapidmail GmbH, Augustinerplatz 2, 79098 Freiburg i. Br., Deutschland
      </p>
      <p class="policy-text" style="font-weight: 500">
        Wurde ein Vertrag über Auftragsverarbeitung mit Rapidmail geschlossen?<br>
      </p>
      <p class="policy-text">
        Ja
      </p>
      <p class="policy-text" style="font-weight: 500">
        Wo finde ich weitere Informationen über den Datenschutz bei Rapidmail?<br>
      </p>
      <p class="policy-text">
        <a class="policy-link" href="https://www.rapidmail.de/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung - rapidmail</a>
      </p>

      <span class="policy-header">Wie verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Für unseren Newsletterversand nutzen wir Rapidmail. Der Dienst verwaltet für uns die Daten der Newsletterabonnenten, verschickt unseren Newsletter und analysiert unsere Newsletterkampagnen.<br>
        Wenn Sie unseren Newsletter zugeschickt bekommen möchten, benötigen wir Ihre E-Mail-Adresse. Wir werden außerdem mit Hilfe einer Bestätigungsmail (Double-Opt-in-Verfahren)
        überprüfen, ob Sie wirklich der Inhaber dieser E-Mail-Adresse sind. Weitere Daten erheben wir nicht bzw. nur auf freiwilliger Basis. Ihre Daten verwenden wir ausschließlich
        für den Newsletterversand.<br>
        Versenden wir einen Newsletter über Rapidmail und öffnen Sie diesen, verbindet sich eine im Newsletter enthaltene Datei automatisch mit den Servern von Rapidmail.
        So erfährt der Dienst, dass der Newsletter geöffnet wurde, und registriert alle Klicks auf die darin enthaltenen Links. Darüber hinaus erfasst Rapidmail technische
        Informationen, wie den Zeitpunkt des Abrufs, die IP-Adresse, Browsertyp und Betriebssystem.<br>
        Sie können den Newsletter jederzeit abbestellen.
      </p>

      <span class="policy-header">Wie lange speichern wir Ihre Daten?</span>
      <p class="policy-text">
        Nachdem Sie sich abgemeldet haben, werden die Daten aus dem Newsletterverteiler gelöscht. Unter Umständen setzen wir Ihre E-Mail-Adresse zugleich auf eine Blacklist; das ist beispielsweise
        erforderlich, wenn uns ein Widerspruch gegen Werbung von Ihnen vorliegt. Die Speicherung erfolgt dann auf der Grundlage von Art. 6 Abs. 1 lit. f) DSGVO.<br>
        Im Übrigen behalten wir uns vor, die Daten nach Fortfall des Erhebungszwecks oder nach eigenem Ermessen jederzeit zu löschen.
      </p>

      <span class="policy-header">Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Mit dem Eintrag in die Abonnentenliste willigen Sie in die Datenverarbeitung durch Rapidmail ein. Diese erfolgt damit rechtmäßig auf der Grundlage von Art. 6 Abs. 1 lit.
        a) DSGVO. Die Einwilligung können Sie durch das Abbestellen des Newsletters oder durch eine formlose Mitteilung an uns widerrufen. Für uns heißt das, dass wir Ihnen ab diesem
        Zeitpunkt keine Newsletter mehr zusenden dürfen.
      </p>

      <span class="policy-header">Online-Marketing und Partnerprogramme</span>
      <span class="policy-header">Amazon PartnerNet</span>
      <p class="policy-text" style="font-weight: 500">
        Was ist Amazon PartnerNet?<br>
      </p>
      <p class="policy-text">
        Affiliate-Marketing-Programm von Amazon Europe (weitere Informationen: <a class="policy-link" href="https://partnernet.amazon.de/help/operating/policies" target="_blank" rel="noopener noreferrer">Richtlinien - Amazon PartnerNet</a>)
      </p>
      <p class="policy-text" style="font-weight: 500">
        Wer verarbeitet Ihre Daten?<br>
      </p>
      <p class="policy-text">
        Amazon Europe Core S.à r.l., 38 avenue John F. Kennedy, 1855 Luxemburg, Luxemburg
      </p>
      <p class="policy-text" style="font-weight: 500">
        Wo finden Sie weitere Informationen über den Datenschutz bei Amazon PartnerNet?<br>
      </p>
      <p class="policy-text">
        <a class="policy-link" href="https://www.amazon.de/gp/help/customer/display.html/?nodeId=3312401" target="_blank" rel="noopener noreferrer">https://www.amazon.de/gp/help/customer/display.html/?nodeId=3312401</a>
      </p>

      <span class="policy-header">Wie verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Wir nehmen am EU-Partnerprogramm von Amazon teil. Das macht sich in der Weise bemerkbar, dass auf unserer Website Amazon-Werbeanzeigen und Links zur Website von Amazon eingebunden sind.
        Registriert Amazon Käufe über diese Anzeigen oder Links, erhalten wir eine Affiliate-Vergütung. Um nachvollziehen zu können, ob ein Kauf über eine Anzeige oder einen Link auf unserer
        Website zustande gekommen ist, platziert Amazon Cookies auf Ihrem Gerät oder setzt vergleichbare Wiedererkennungstechnologien ein (z. B. Device Fingerprinting).
      </p>

      <span class="policy-header">Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?</span>
      <p class="policy-text">
        Wir haben ein berechtigtes Interesse an einer korrekten Berechnung unserer Affiliate-Vergütung. Die Nutzung Ihrer Daten erfolgt daher auf der Grundlage von Art. 6 Abs. 1 lit.
        f) DSGVO. Haben Sie in die Datenverarbeitung eingewilligt, erfolgt die Verarbeitung ausschließlich auf der Grundlage von Art. 6 Abs. 1 lit. a) DSGVO. Sie können die Einwilligung
        jederzeit mit Wirkung für die Zukunft widerrufen.
      </p>

    </v-container>
  </section>
</template>

<script>

export default {
  name: 'Policies',
  components: {

  },
  inject: [
    'api'
  ],
  data() {
    return {

    }
  },
  created() {

  },
  unmounted() {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  height: 100%;
  background: rgb(245, 255, 250);
  padding-bottom: 4rem;
}

.header {
  font-size: 44px;
  font-weight: 800;
  color: rgb(0, 0, 0);
  margin-bottom: 2rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
}

.policy-header {
  font-size: 24px;
  font-weight: 700;
  margin: 1.5rem 0 0.5rem;
}

.policy-data,
.policy-text {
  font-size: 18px;
}

.policy-link {
  color: rgb(255, 108, 223);
  font-size: 18px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}

  .policy-link:hover {
    color: rgb(252, 179, 236);
    border-bottom: 1px solid rgb(252, 179, 236);
  }

.policy-text {
  width: 70%;
}

.policy-list {
  margin-left: 2rem;
}

</style>
