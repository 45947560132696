<template>
  <v-btn v-if="faIconClass" class="button" plain rounded="pill" size="large">
    <i id="icon" :class="faIconClass" :style="'font-size:' + iconSize"></i>
  </v-btn>
  <v-btn v-else class="button" plain rounded="pill" size="large">{{ buttonText }}</v-btn>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: [ 'buttonText', 'faIconClass', 'iconSize' ],
  components: {

  },
  data() {
    return {

    }
  },
}



</script>

<style scoped>

.button {
  padding: 0 1.5rem;
  font-weight: 700;
  color: #5C5DD8;
  background: linear-gradient(180deg, rgba(174, 249, 255, 1) 25%, rgba(96, 255, 229, 1) 130%);
  z-index: 20;
}

#icon {
  font-size: 20px;
}

</style>
