<template>
  <!-- small-screen shipping info dialog -->
  <transition name="fade">
    <div @click="infoDialog = !infoDialog" v-show="infoDialog" class="dialog-overlay">
      <div class="dialog-card">
        <div class="dialog-header">
          <span class="header-text">{{ ratilerHeader }} Details</span>
          <v-spacer></v-spacer>
          <i class="fal fa-times icon close"></i>
        </div>
        <div class="dialog-body">
          <v-row no-gutters class="row-flex-column">
            <span class="dialog-header-text">Versandkosten:</span>
            <span class="dialog-data-text">3.99 <span>{{ ' ' + this.mapCurrencyUnit(localCurrency) }}</span></span>
          </v-row>
          <v-row no-gutters class="row-flex-column">
            <span class="dialog-header-text">Versand Details:</span>
            <span class="dialog-data-text">Return period - 14 days</span>
            <span class="dialog-data-text">Shipping - DHL, DPD, Hermes</span>
          </v-row>
          <v-row no-gutters class="row-flex-column">
            <span class="dialog-header-text">Zahlungs Methoden:</span>
            <span class="dialog-data-text">PayPal, VISA, MasterCard, Klarna, Amazon Pay, Nachname</span>
          </v-row>
        </div>
      </div>
    </div>
  </transition>

  <!-- add set to collection dialog for desktop -->
  <transition name="fade">
    <AddSetDialogDesktop v-if="addSetDialogActive" :set_data="set_data" @close="addSetDialogActive = false" />
  </transition>

  <!-- add set to collection dialog for mobile devices -->
  <template v-if="isMobile">
    <SetDetailMobile :headerImage="headerImage" :mainImage="main_image" :galleryImages="gallery_images" :set_data="set_data" :retailerList="retailerList" :relatedSets="relatedSets" />
  </template>

  <template v-else>
    <div class="background-image-container">
      <img class="background-image" :src="headerImage" loading="lazy">
    </div>
    <section>
      <v-container>
        <v-row no-gutters class="row-search">
          <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
        </v-row>
      </v-container>

      <template v-if="!isSmallScreenWidth">
        <v-container>
          <v-row no-gutters class="row_set_header">
            <div class="image-container">
              <ZoomMainImage v-if="main_image?.length > 0" galleryID="main_image" :images="main_image" />
              <v-icon v-else id="main_image_alt" icon="mdi-package" ></v-icon>
            </div>

            <v-col>
              <ul class="breadcrumb">
                <li><router-link class="link" :to="{ path: '/themes' }">Themenwelt</router-link></li>
                <li v-if="set_data.theme !== '-'"><router-link class="link" :to="{ path: '/themes/' + convertThemeToPath(set_data.theme)}">{{ set_data.theme }}</router-link></li>
                <li v-if="set_data.subtheme !== '-'"><router-link class="link" :to="{ path: '/items/sets-overview', query: { subtheme: set_data.subtheme } }">{{ set_data.subtheme }}</router-link></li>
              </ul>
              <h1 class="set_minifig_name">{{ set_data.set.split('-')[0] }} {{ set_data.name }}</h1>
              <div class="call-to-actions">
                <div class="cta-container">
                  <i class="fa fa-star set-rating-star"></i>
                  <div class="rating-container">
                    <span class="rating-header">Fan Ranking</span>
                    <span class="rating-value">-/100</span>
                  </div>
                </div>
                <div class="cta-container">
                  <i class="fa fa-star set-rating-star"></i>
                  <div class="rating-container">
                    <span class="rating-header">Investment</span>
                    <span class="rating-value">{{ set_data.investmentrating }}/100</span>
                  </div>
                </div>
                <div v-show="!isSmallScreenWidth" class="cta-buttons">
                  <SecondaryButton @click="openAddSetDialog()" class="button-secondary" buttonText="Zur Collection"/>
                  <template v-if="false">
                    <!-- WATCHLIST NOCH NICHT VERFÜGBAR -->
                    <SecondaryButton class="button-secondary watchlist" buttonText="Zur Watchlist"/>
                  </template>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-if="!isSmallScreenWidth">
        <v-container>
          <v-row no-gutters class="pt-4">

            <v-col class="px-4" cols="6">
              <div class="card_main card-set-info">
                <div class="card-header">
                  <p class="header-text">Set Informationen</p>
                </div>
                <div class="card-body">
                  <div class="info-list">
                    <SetInformationTable :set_data="set_data" />
                  </div>
                </div>
                <div class="card-header">
                  <p class="header-text">Community</p>
                </div>
                <div class="card-body">
                  <div class="info-list">
                    <table>
                      <tr>
                        <td class="info-list-header">In Collections</td>
                        <td class="info-list-data">{{ set_data.in_collections || '0' }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col class="px-4" cols="6">
              <div class="mb-4 card_main card-set-info">
                <div class="card-header">
                  <p class="header-text with_tooltip">Aktueller Angebotspreis</p>
                  <Popper class="tooltip" placement="bottom" arrow="true" offsetDistance="8" content="Die Preise beziehen sich auf aktuelle Angebotspreise für den Zustand neu und gebraucht. Es handelt sich dabei nicht um einen Endkundenpreis.
                                                                                                    Hier werden unterschiedliche Preise summiert, ob diese inkl. MwSt. und ggf. zzgl. Versandkosten sind, kann nicht nachvollzogen werden.
                                                                                                    Aktuelle Preise können zwischenzeitlich höher sein.">
                    <i class="fas fa-question-circle info_tooltip"></i>
                  </Popper>
                </div>
                <div class="card-body">
                  <v-table density="compact" class="ml-6 table">
                    <tr>
                      <td class="info-list-header">Neu</td>
                      <td class="info-list-header">Gebraucht</td>
                    </tr>
                    <tr>
                      <td>
                        <v-table density="compact" class="table">
                          <tr>
                            <td class="info-list-data">Min. Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_new')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                          <tr>
                            <td class="info-list-data">Max. Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_new')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                          <tr>
                            <td class="info-list-data">Ø Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_new')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                        </v-table>
                      </td>
                      <td>
                        <v-table density="compact" class="table">
                          <tr>
                            <td class="info-list-data">Min. Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_used')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                          <tr>
                            <td class="info-list-data">Max. Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_used')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                          <tr>
                            <td class="info-list-data">Ø Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'current_used')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                        </v-table>
                      </td>
                    </tr>
                  </v-table>

                  <div class="card-header">
                    <p class="header-text with_tooltip">Verkaufspreis</p>
                    <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Verkaufspreise der letzten sechs Monate für den Zustand neu und gebraucht. Es handelt sich dabei nicht um einen Endkundenpreis.
                                                                                                    Hier werden unterschiedliche Preise summiert, ob diese inkl. MwSt. und ggf. zzgl. Versandkosten sind, kann nicht nachvollzogen werden.
                                                                                                    Aktuelle Preise können zwischenzeitlich höher sein.">
                      <i class="fas fa-question-circle info_tooltip"></i>
                    </Popper>
                  </div>
                  <v-table density="compact" class="ml-6 table">
                    <tr>
                      <td class="info-list-header">Neu</td>
                      <td class="info-list-header">Gebraucht</td>
                    </tr>
                    <tr>
                      <td>
                        <v-table density="compact" class="table">
                          <tr>
                            <td class="info-list-data">Min. Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_new')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                          <tr>
                            <td class="info-list-data">Max. Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_new')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                          <tr>
                            <td class="info-list-data">Ø Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_new')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                        </v-table>
                      </td>
                      <td>
                        <v-table density="compact" class="table">
                          <tr>
                            <td class="info-list-data">Min. Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_used')?.min_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                          <tr>
                            <td class="info-list-data">Max. Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_used')?.max_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                          <tr>
                            <td class="info-list-data">Ø Preis</td>
                            <td class="info-list-data"><b>{{ set_data.bricklink_sales.find(item => item.bricklink_types_id === 'six_month_used')?.avg_price?.toFixed(2) ?? '-' }} {{ mapCurrencyUnit(localCurrency) }}</b></td>
                          </tr>
                        </v-table>
                      </td>
                    </tr>
                  </v-table>
                </div>
              </div>
              <div class="card_main card-price">
                <div class="card-header">
                  <span class="header-text">Preisvergleich</span>
                  <v-spacer></v-spacer>
                  <Toggle v-model="withShipping" />
                  <label class="label">inkl. Versandkosten</label>
                </div>
                <v-row no-gutters class="retailer-list-header">
                  <div>
                    <template v-if="set_data.retail_price !== '-'">
                      <span class="retail-price">{{ set_data.retail_price }} <span>{{ ' ' + this.mapCurrencyUnit(localCurrency) }}</span></span>
                    </template>
                    <template v-else>
                      <span class="retail-price">-</span>
                    </template>
                  </div>
                </v-row>
                <div class="card-body">
                  <RetailerList :set_data="set_data" :retailerList="retailerList" :withShipping="withShipping" />
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="pt-8">
            <v-col class="px-4" cols="6">
              <div class="card_main">
                <div class="card-header">
                  <span class="header-text">Minifiguren</span>
                  <v-spacer></v-spacer>
                </div>
                <div class="card-body minifigures">
                  <v-row no-gutters class="ml-6 mb-6">
                    <template v-if="set_data.minifigures.length > 0">
                      <div class="minifigure_container" v-for="minifig in set_data.minifigures" :key="minifig.minifigures_number?.number?.key">
                        <router-link id="minifigure_link" :to="{ path: '/items/minifigures/' + minifig.minifigures_number?.number }">
                          <div class="minifigure_amount_container">
                            <p class="minifigure_amount_text">{{ minifig.amount ?? 'amount' }}x</p>
                          </div>
                          <div v-if="true" class="image_container_minifigures">
                            <img v-if="getMinifigureImage(minifig?.minifigures_number?.images[0]?.directus_files_id)" class="image minifigures" :src="getMinifigureImage(minifig?.minifigures_number?.images[0]?.directus_files_id)" loading="lazy">
                            <v-icon v-else id="small_image_alt" icon="mdi-account-circle" ></v-icon>
                          </div>
                          <div class="minifigures_info_card">
                            <span class="minifigure_name"> {{ minifig.minifigures_number?.name ?? '-' }}</span>
                            <span class="minifigure_bricklink">{{ minifig.minifigures_number?.number ?? '-' }}</span>
                            <span v-if="minifig.minifigures_number?.sets?.length" class="minifigure_bricklink">in {{ minifig.minifigures_number?.sets?.length ?? '' }} Sets</span>
                          </div>
                        </router-link>
                      </div>
                    </template>
                    <template v-else>
                      <span class="hint_text">Keine Minifiguren in {{ set_data.set }} enthalten oder noch nicht angelegt.</span>
                    </template>
                  </v-row>
                </div>
              </div>

            </v-col>
            <v-col class="px-4" cols="6">
              <div class="card_main card-media">
                <div class="card-header">
                  <span class="header-text">Media</span>
                </div>
                <div class="card-body">
                  <v-row no-gutters class="ml-6 mb-4">
                    <template v-if="gallery_images.length > 0">
                      <ImageGallery galleryID="image_gallery" :images="gallery_images" />
                    </template>
                    <template v-else>
                      <span class="hint_text">Keine Bilder zu {{ set_data.set }} vorhanden.</span>
                    </template>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="relatedSets.length > 0" class="pt-8 px-4" no-gutters>
            <div class="card_main card_related_sets">
              <div class="card-header" id="idMinifigures">
                <span class="header-text">Dazu passende {{ set_data?.theme ?? '' }} <span v-if="set_data.subtheme">{{ ' oder ' + set_data.subtheme }}</span> Sets</span>
              </div>
              <v-row class="my-2" no-gutters>
                <div v-for="setItem in relatedSets" :key="setItem.key">
                  <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
                    <SetCard
                    :set="`${setItem.set}`"
                    :name="`${setItem.name[0]?.name ?? ''}`"
                    :retailPrice="setItem?.retail_price ?? ''"
                    :year="getReleaseDate(setItem.release_date)"
                    :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                    :imageContainerWidth="'120px'"
                    :imageContainerHeight="'120px'"
                    :textContainerWidth="'160px'"
                    :textContainerHeight="'110px'"
                    :textContainerBackground="'none'"
                    :cardSetFontsize="'12px'"
                    :cardNameFontsize="'12px'"
                    :cardNameMinMaxHeight="'1.65rem'"
                    :cardPriceFontsize="'14px'"
                    :cardPriceColor="'#60ffe4'"
                    :borderRadius="'15px'"
                    />
                  </router-link>
                </div>
              </v-row>
            </div>
          </v-row>
        </v-container>
      </template>

      <template v-if="isSmallScreenWidth">
        <!-- SMALL SCREEN VIEW -->
        <v-container>
          <v-row no-gutters class="row_set_header">
            <div class="image-container">
              <ZoomMainImage galleryID="main_image" :images="main_image" />
            </div>
            <v-col>
              <v-container class="pa-0 col-set-main-data">
                <h1 class="setnumber-small-screen">{{ set_data.set.split('-')[0] }}</h1>
                <h1 class="setname-small-screen">{{ set_data.name }}</h1>

                <div class="call-to-actions small-screen">
                  <div class="cta-container">
                    <i class="fa fa-star set-rating-star small-screen"></i>
                    <div class="rating-container">
                      <span class="rating-header small-screen">Fan Ranking</span>
                      <span class="rating-value small-screen">-/100</span>
                    </div>
                  </div>
                  <div class="cta-container">
                    <i class="fa fa-star set-rating-star small-screen"></i>
                    <div class="rating-container">
                      <span class="rating-header small-screen">Investment</span>
                      <span class="rating-value small-screen">{{ set_data.investmentrating }}/100</span>
                    </div>
                  </div>
                </div>
              </v-container>
            </v-col>
          </v-row>
          <div class="button-container">
            <v-btn @click="openAddSetDialog()" class="secondary-icon-button" plain rounded="pill" size="large"><i class="fas fa-boxes"></i></v-btn>
            <template v-if="false">
              <!-- WATCHLIST NOCH NICHT VERFÜGBAR -->
              <v-btn class="secondary-icon-button" plain rounded="pill" size="large"><i class="fas fa-binoculars"></i></v-btn>
            </template>
          </div>

          <div class="card_main card-price small-screen">
            <div class="card-header">
              <span class="header-text">Preisvergleich</span>
              <v-spacer></v-spacer>
              <Toggle v-model="withShipping" />
              <label class="label">inkl. Versandkosten</label>
            </div>
            <v-row no-gutters class="retailer-list-header">
              <div>
                <template v-if="set_data.retail_price !== '-'">
                  <span class="retail-price">{{ set_data.retail_price }} <span>{{ ' ' + this.mapCurrencyUnit(localCurrency) }}</span></span>
                </template>
                <template v-else>
                  <span class="retail-price">-</span>
                </template>
              </div>
            </v-row>
            <div class="card-body">
              <RetailerList :set_data="set_data" :retailerList="retailerList" :withShipping="withShipping" />
            </div>
          </div>

          <div class="card_main card-set-info small-screen">
            <div class="card-header">
              <span class="header-text">Set Informationen</span>
            </div>
            <div class="card-body">
              <div class="info-list">
                <SetInformationTable :set_data="set_data" />
              </div>
              <div class="card-header">
                <span class="header-text">Community</span>
              </div>
              <div class="card-body">
                <div class="info-list">
                  <table>
                    <tr>
                      <td class="info-list-header small-screen">In Collections</td>
                      <td class="info-list-data small-screen">{{ set_data.in_collections || '0' }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="card_main card-media small-screen">
            <div class="card-header">
              <span class="header-text">Media</span>
            </div>
            <div class="card-body">
              <v-row no-gutters class="ml-6 mb-4">
                <template v-if="gallery_images.length > 0">
                  <ImageGallery galleryID="image_gallery" :images="gallery_images" />
                </template>
                <template v-else>
                  <span class="ml-6 hint_text">Keine Bilder zu {{ set_data.set }} vorhanden.</span>
                </template>
              </v-row>
            </div>
          </div>

        </v-container>
      </template>
    </section>
  </template>

</template>

<script>
import SetDetailMobile from '../SetDetail/SetDetailMobile.vue';
import Search from '../../components/Search.vue';
import SetCard from '../../components/SetCard.vue';
import SecondaryButton from '../../components/SecondaryButton.vue';
import Toggle from '../../components/Toggle.vue';
import SetInformationTable from '../SetDetail/SetInformationTable.vue';
import RetailerList from '../SetDetail/RetailerList.vue';
import AddSetDialogDesktop from '../../components/Dialogs/AddSetDialogDesktop.vue';
import moment from 'moment/min/moment-with-locales';
import ImageGallery from '../../components/Dialogs/ImageGallery.vue';
import ZoomMainImage from '../../components/Dialogs/ZoomMainImage.vue';

export default {
  name: 'SetDetail',
  components: {
    SetDetailMobile,
    Search,
    Toggle,
    SetCard,
    SecondaryButton,
    SetInformationTable,
    RetailerList,
    AddSetDialogDesktop,
    ImageGallery,
    ZoomMainImage
},
  inject: [
    'api',
    'loggedIn',
    'isMobile',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      isSmallScreenWidth: null,
      infoDialog: false,
      collectionDialog: false,
      addSetDialogActive: false,
      ratilerHeader: null,
      responseMessage: '',

      set_data: {
        amount_per_sales_unit: '',
        availability: '',
        bricklink_sales: [],
        designer: [],
        dimension_depth: '',
        dimension_height: '',
        dimension_width: '',
        dimensions_weight: '',
        dimensions: '',
        ean: '',
        eol_date: '',
        header_image: '',
        images: '',
        in_collections: '',
        investmentrating: '',
        legacy_retail_price: '',
        minifig_count: '',
        minifigures: '',
        name: '',
        packaging: '',
        package_size_depth: '',
        package_size_height: '',
        package_size_width: '',
        package_weight: '',
        pieces: '',
        price_per_piece: '',
        price_per_weight: '',
        recommended_age: '',
        release_date: '',
        retail_price: '',
        set: '',
        subtheme: '',
        tags: '',
        theme: '',
      },

      relatedSets: [],

      retailerList: [],
      withShipping: false,
      clickedImage: '',
      zoomImageDialogActive: false,
      imageCopyright: '',

      amount: null,
      condition: '',
      purchase_date: null,
      retailer: null,
      price_payed: null,
      discount: null,
      storage: null,

      main_image: [],
      gallery_images: [],
    }
  },
  watch: {
    routeSetNumber(value) {
      if (value) { this.getSet(); }
    },
    price_payed() {
      if (this.set_data.retail_price !== '') {
        this.discount = parseFloat(((1-(this.price_payed / this.set_data.retail_price))*100).toFixed(2));
      }
    },
    withShipping(active) {
      if (active) {
        this.retailerList.sort((a, b) => (parseFloat(a.priceWithShipping) > parseFloat(b.priceWithShipping)) ? 1 : -1);
      }
      else {
        this.retailerList.sort((a, b) => (parseFloat(a.currentPrice) > parseFloat(b.currentPrice)) ? 1 : -1);
      }
    }
  },
  computed: {
    routeSetNumber() {
      return this.$route.params.setnumber || '';
    },
    headerImage() {
      if (this.set_data.header_image) {
        try {
          const img = process.env.VUE_APP_API_IMG_PATH + this.set_data.header_image.id + '?height=1800';
          return img;
        }
        catch(err) {
          if (this.set_data.images) {
            try {
              const img = process.env.VUE_APP_API_IMG_PATH + this.set_data.images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=1800';
              return img;
            }
            catch(err) {
              for (let i = 0; i < this.set_data.images.length; i++) {
                if (this.set_data.images[i]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + this.set_data.images[i]?.directus_files_id?.id + '?width=1800' ?? ''; }
              }
            }
          }
        }
      }
      else if (this.set_data.images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + this.set_data.images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=1800';
          return img;
        }
        catch(err) {
          for (let i = 0; i < this.set_data.images.length; i++) {
            if (this.set_data.images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + this.set_data.images[i]?.directus_files_id?.id + '?width=300' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    mainImage() {
      if (this.set_data.header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + this.set_data.header_image.id + '?height=300';
          return img;
        }
        catch(err) {
          if (this.set_data.images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + this.set_data.images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=300';
              return img;
            }
            catch(err) {
              for (let i = 0; i < this.set_data.images.length; i++) {
                if (this.set_data.images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + this.set_data.images[i]?.directus_files_id?.id + '?width=300' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (this.set_data.images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + this.set_data.images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=300';
          return img;
        }
        catch(err) {
          for (let i = 0; i < this.set_data.images.length; i++) {
            if (this.set_data.images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + this.set_data.images[i]?.directus_files_id?.id + '?width=300' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    mediaImages() {
      if (this.set_data.images) {
        let result = [];
        this.set_data.images.forEach(img => {
          let object = {
            id: img.directus_files_id?.id ?? '',
            copyright: img.directus_files_id?.copyright ?? '',
            path: process.env.VUE_APP_API_IMG_PATH + img.directus_files_id?.id + '?height=300' ?? ''
          }
          if (object.id) { result.push(object); }
        });
        return result;
      }
      return '';
    },
    displayMinifigCount() {
      if (this.set_data.minifig_count) {
        if (this.set_data.minifig_count.count > 0) {
          return this.set_data.minifig_count.count + ' | ' + this.set_data.minifig_count.exclusive + ' excl.' || '';
        }
      }
      return 0;
    },
  },
  created() {
    this.conditions = [
      { text: "Neu, versiegelt" , value: "new_sealed" },
      { text: "Neu, leichte Schäden" , value: "new_minor_damages" },
      { text: "Neu, starke Schäden" , value: "new_major_damages" },
      { text: "Gebraucht, Tüten verschlossen" , value: "used_bags_closed" },
      { text: "Gebraucht, gebaut, nicht bespielt" , value: "used_build_not_played" },
      { text: "Gebraucht, bespielt" , value: "used_played" },
      { text: "Gebraucht, Karton fehlt" , value: "used_box_missing" },
      { text: "Gebraucht, nicht komplett" , value: "used_incomplete" }
    ];
    this.availabilities = [
      { text: "LEGO Exklusiv" , value: "lego_exclusive" },
      { text: "Fachhandel" , value: "limited_retail" },
      { text: "Überall" , value: "retail" },
    ];
    this.packagings = [
      { text: "Karton" , value: "box" },
      { text: "Polybag" , value: "polybag" },
      { text: "Verschiedene" , value: "misc" },
    ];

    this.getSet();

    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();

  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      let windowWidth = window.innerWidth;
      if (windowWidth <= 970) {
        this.isSmallScreenWidth = true;
        return;
      }
      this.infoDialog = false;
      this.isSmallScreenWidth = false;
    },
    async getSet() {
      this.retailerList = [];
      let resRelatedSets = [];
      const res = await this.api.items('sets?fields=*.*,images.directus_files_id.*,minifigures.*,minifigures.minifigures_number.*,minifigures.minifigures_number.images.*&filter[set]=' + this.routeSetNumber).readByQuery();
      const data = res.data[0];

      // Set set_data values
      this.set_data.amount_per_sales_unit = data.amount_per_sales_unit || '';
      this.set_data.availability = this.availabilities.find(el => el.value === data.availability)?.text ?? '';
      this.set_data.bricklink_sales = data.bricklink_sales;
      this.set_data.designer = this.splitDesigner(data.designer) || '';
      this.set_data.dimension_depth = data.dimension_depth || '';
      this.set_data.dimension_height = data.dimension_height || '';
      this.set_data.dimension_width = data.dimension_width || '';
      this.set_data.dimensions_weight = data.dimensions_weight || '';
      this.set_data.dimensions = data.dimensions || '';
      this.set_data.ean = data.ean || '';
      this.set_data.eol_date = data.eol_date || '';
      this.set_data.header_image = data.header_image || '';
      this.set_data.images = data.images || '';
      // add images to gallery
      if (this.gallery_images.length > 0) { this.gallery_images.length = 0; }
      data.images.forEach(imageObject => {
        if (!(imageObject.directus_files_id)) { return; }
        this.gallery_images.push({
          largeURL: process.env.VUE_APP_API_IMG_PATH + imageObject.directus_files_id?.id + '?height=1800' ?? '',
          thumbnailURL: process.env.VUE_APP_API_IMG_PATH + imageObject.directus_files_id?.id + '?height=300' ?? '',
          width: imageObject.directus_files_id?.width ?? '',
          height: imageObject.directus_files_id?.height ?? '',
        })
      });
      // set main image
      if (this.main_image.length > 0) { this.main_image.length = 0; }
      if (data.header_image) {
        try {
          let imgPath = process.env.VUE_APP_API_IMG_PATH + data.header_image.id;
          this.main_image.push({
            largeURL: imgPath + '?height=1800',
            thumbnailURL: imgPath + '?height=300',
            width: data.header_image?.width,
            height: data.header_image?.height,
          });
        }
        catch(err) {
          if (data.images) {
            try {
              let imgPath = process.env.VUE_APP_API_IMG_PATH + data.images
                          ?.find(img => img.directus_files_id?.filename_download
                          ?.includes('_alt1' || 'Alt1'))?.directus_files_id?.id;
              this.main_image.push({
                largeURL: imgPath + '?height=1800',
                thumbnailURL: imgPath + '?height=300',
                width: data.images?.find(img => img.directus_files_id?.filename_download
                                    ?.includes('_alt1' || 'Alt1'))?.directus_files_id?.width,
                height: data.images?.find(img => img.directus_files_id?.filename_download
                                    ?.includes('_alt1' || 'Alt1'))?.directus_files_id?.height,
              });
            }
            catch(err) {
              for (let i = 0; i < data.images.length; i++) {
                if (data.images[i]?.directus_files_id) {
                  let imgPath = process.env.VUE_APP_API_IMG_PATH + data.images[i]?.directus_files_id?.id ?? '';
                  if (imgPath != '' || imgPath != undefined) {
                    this.main_image.push({
                      largeURL: imgPath + '?height=1800',
                      thumbnailURL: imgPath + '?height=300',
                      width: data.images[i]?.directus_files_id?.width,
                      height: data.images[i]?.directus_files_id?.height,
                    });
                  }
                }
              }
            }
          }
        }
      }
      else if (data.images) {
        try {
          let imgPath = process.env.VUE_APP_API_IMG_PATH + data.images
                      ?.find(img => img.directus_files_id?.filename_download
                      ?.includes('_alt1' || 'Alt1')).directus_files_id?.id;
          this.main_image.push({
            largeURL: imgPath + '?height=1800',
            thumbnailURL: imgPath + '?height=300',
            width: data.images?.find(img => img.directus_files_id?.filename_download
                              ?.includes('_alt1' || 'Alt1'))?.directus_files_id?.width,
            height: data.images?.find(img => img.directus_files_id?.filename_download
                              ?.includes('_alt1' || 'Alt1'))?.directus_files_id?.height,
          });
        }
        catch(err) {
          for (let i = 0; i < data.images.length; i++) {
            if (data.images[i]?.directus_files_id) {
              let imgPath = process.env.VUE_APP_API_IMG_PATH + data.images[i]?.directus_files_id?.id ?? '';
              if (imgPath != '' || imgPath != undefined) {
                this.main_image.push({
                  largeURL: imgPath + '?height=1800',
                  thumbnailURL: imgPath + '?height=300',
                  width: data.images[i]?.directus_files_id?.width,
                  height: data.images[i]?.directus_files_id?.height,
                });
              }
            }
          }
        }
      }
      this.set_data.in_collections = data.in_collections || '';
      this.set_data.investmentrating = data.investmentrating || '';
      this.set_data.legacy_retail_price = this.mapRetailPriceToLocal(data.legacy_retail_price) || '';
      this.set_data.minifig_count = data.minifig_count || '';
      this.set_data.minifigures = data.minifigures || '';
      this.set_data.name = this.mapNameToLocal(data.name) || '';
      this.set_data.note = data.note || '';
      this.set_data.packaging = this.packagings.find(el => el.value === data.packaging)?.text ?? '';
      this.set_data.package_size_depth = data.package_size_depth || '';
      this.set_data.package_size_height = data.package_size_height || '';
      this.set_data.package_size_width = data.package_size_width || '';
      this.set_data.package_weight = data.package_weight || '';
      this.set_data.pieces = data.pieces || '';
      this.set_data.recommended_age = data.recommended_age || '';
      this.set_data.release_date = data.release_date || '';
      this.set_data.retail_price = this.mapRetailPriceToLocal(data.retail_price) || '-';
      this.set_data.set = data.set || '';
      this.set_data.subtheme = data.subtheme?.name ?? '';
      this.set_data.tags = data.tags || '';
      this.set_data.theme = data.theme?.name || '';

      if ((this.set_data.retail_price && this.set_data.retail_price !== '-') && (this.set_data.pieces && this.set_data.pieces !== '-')) {
        this.set_data.price_per_piece = ((parseFloat(this.set_data.retail_price) / this.set_data.pieces) * 100).toFixed(2);
      }
      else { this.set_data.price_per_piece = '-'}
      if ((this.set_data.retail_price && this.set_data.retail_price !== '-') && (this.set_data.dimensions_weight && this.set_data.dimensions_weight !== '-')) {
        this.set_data.price_per_weight = ((parseFloat(this.set_data.retail_price) / this.set_data.dimensions_weight) * 100).toFixed(2);
      }
      else { this.set_data.price_per_weight = '-'}

      const res_prices = await this.api.items('retailer_prices?fields=*.*&filter[set]=' + this.routeSetNumber).readByQuery();

      res_prices.data.forEach(priceItem => {
        let newRetailerItem = {};
        newRetailerItem.affiliate_link = priceItem.affiliate_link || '';
        newRetailerItem.currentPrice = priceItem.price?.toFixed(2) ?? '';
        newRetailerItem.logo = this.getRetailerImage(priceItem.retailer.logo);
        newRetailerItem.minimum_order_value = priceItem.retailer.minimum_order_value || '';
        newRetailerItem.name = priceItem.retailer.name || '';
        newRetailerItem.return_costs = priceItem.retailer.return_costs || '';
        newRetailerItem.return_policy = priceItem.retailer.return_policy || '';
        newRetailerItem.shipping = priceItem.retailer.shipping || '';
        newRetailerItem.withdrawal_period = priceItem.retailer.withdrawal_period || '';
        newRetailerItem.discount = this.getDiscount(newRetailerItem.currentPrice, this.set_data.retail_price);
        newRetailerItem.priceWithShipping = this.getPriceWithShipping(newRetailerItem.currentPrice, newRetailerItem.shipping);
        newRetailerItem.product_link = priceItem.product_link || '';
        newRetailerItem.discountWithShpping = this.getDiscount(newRetailerItem.priceWithShipping, this.set_data.retail_price);
        newRetailerItem.fallback_affiliate_url = priceItem?.retailer?.fallback_affiliate_url ?? '';

        if (!newRetailerItem.name.includes('Galeria') && !newRetailerItem.name.includes('Steinchenwelt')) { this.retailerList.push(newRetailerItem); }
        this.retailerList.sort((a, b) => (parseFloat(a.currentPrice) > parseFloat(b.currentPrice)) ? 1 : -1);
      });

      // Set all filter parameter to filter object
      let filterObject = {}
      if (this.set_data.subtheme) { filterObject['subtheme'] = {"name": this.set_data.subtheme }}
      else {
        if (this.set_data.theme) { filterObject['theme'] = {"name": this.set_data.theme }}
        if (this.set_data.release_date) { filterObject['release_date'] = {"_between": [ this.set_data.release_date + '-01-01T12:00:00', this.set_data.release_date + '-12-31T12:00:00']}}
      }

      // send request
      resRelatedSets = await this.api.items('sets?fields=*.*,images.directus_files_id.*,minifigures.*'
      + '&sort=-release_date'
      + '&meta=filter_count').readByQuery({
        filter: filterObject
      });
      this.relatedSets = resRelatedSets.data;
      this.relatedSets = this.relatedSets.filter(el => el.set !== this.set_data.set);
    },
    openZoomImage(image) {
      if (this.zoomImageDialogActive === false) {
        if (image.id != null) {
          this.zoomImageDialogActive = true;
          this.imageCopyright = image.copyright;
          this.clickedImage = image;
        }
      }
    },
    openAddSetDialog() {
      if (this.addSetDialogActive === false) {
        this.addSetDialogActive = true;
      }
    },
    openInfoDialog(retailerName) {
      this.ratilerHeader = retailerName;
      this.infoDialog = true;
    },
    getRetailerImage(imageId) {
      if (imageId) { return process.env.VUE_APP_API_IMG_PATH + imageId + '?height=100'; }
      return '';
    },
    getPriceWithShipping(price, shipping) {
      const newPrice = parseFloat(price);
      const newShipping = parseFloat(shipping);
      if (newPrice && typeof newPrice == 'number' && typeof newShipping == 'number' && !(isNaN(newShipping))) {
        const result = newPrice + newShipping;
        return result.toFixed(2);
      }
      return newPrice.toFixed(2);
    },
    getDiscount(retailerPrice, rrp) {
      if (rrp && rrp !== '-' && rrp !== '0.00') { return parseFloat(((1-(retailerPrice / rrp))*100)).toFixed(1) || ''; }
      else return '';
    },
    convertGermanDateString(dateString) {
      if (dateString) {
        let day = dateString.split('-')[2];
        let month = dateString.split('-')[1];
        let year = dateString.split('-')[0];

        if (day.length == 2 && month.length == 2 && year.length == 4) {
          let m = moment(day + '.' + month + '.' + year + '12:00', 'DD MM YYYY HH:mm Z', 'de');
          return m.toISOString();
        }
        else return null;
      }
      else return null;
    },
    formatPackageSize(height, width, depth) {
      if (height || width || depth) {
        let returnHeight = '-';
        let returnWidth = '-';
        let returnDepth = '-';
        if (height) { returnHeight = (height / 10).toFixed(1); }
        if (width) { returnWidth = (width / 10).toFixed(1); }
        if (depth) { returnDepth = (depth / 10).toFixed(1); }
        return returnHeight + ' x ' + returnWidth + ' x ' + returnDepth + ' cm';
      }
      else return '-';
    },
    convertThemeToPath(theme) {
      if (theme) {
        const themeNoSpecialChars = (((theme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
        const routeTheme = themeNoSpecialChars.toLowerCase();
        return routeTheme;
      }
      else return '';
    },
    mapNameToLocal(nameObject) {
      const localNameObject = nameObject.filter(el => el.languages_id === this.localLanguage)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === this.localCurrency)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    getMinifigureImage(minifigureImageId) {
      try {
        if (minifigureImageId) { return process.env.VUE_APP_API_IMG_PATH + minifigureImageId + '?height=200' ?? ''; }
        return '';
      }
      catch(err) { return ''; }
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
    splitDesigner(designer) {
      if (designer) {
        return designer.split(',');
      }
      else return '';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  position: relative;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog-card {
  position: relative;
  width: 80%;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.close-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.2);
  border-bottom-left-radius: 25px;
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  z-index: 50;
}

.close-zoomed-iamge {
  color: rgb(29, 25, 95);
}

  .close-zoomed-iamge:hover {
    color: rgb(255, 169, 236);
  }

.dialog-card-zoomed-image {
  position: relative;
  max-height: 80%;
  max-width: 1600px;
  overflow: hidden;
  background: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}

.dialog-action {
  margin: 0 auto;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 1rem;
  color: #5C5DD8;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%);
}

.row-flex-column {
  flex-direction: column;
  margin: 0.5rem 0;
}

.dialog-header-text {
  margin-left: 1.5rem;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.dialog-data-text {
  margin-left: 1.5rem;
  font-size: 10px;
  color: #ffffff;
}

.row-search {
  position: relative;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 1rem;
  justify-content: center;
  z-index: 40;
}

.background-image-container {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 19rem;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px -2px 20px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  z-index: 1;
}

.background-image {
  width: 100%;
  margin-top: 60px;
  opacity: 0.3;
  transform: scale(1.7);
  transition: 0.5s ease transform;
}

.row_set_header {
  position: relative;
  width: 100%;
  flex-wrap: nowrap;
  z-index: 3;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 160px;
  height: 160px;
  margin-left: 1rem;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  transition: 0.5s ease transform;
}

.image {
  display: flex;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: 0.5s ease transform;
}

.media-image-container {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0.5rem 1.8rem 0.5rem 0;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  cursor: zoom-in;
  transition: 0.5s ease all;
}

  .media-image-container:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 5px 20px 0 rgba(0,0,0,0.7);
    -moz-box-shadow: 0 5px 20px 0 rgba(0,0,0,0.7);
  }

.media-image {
  position: relative;
  height: 100%;
  width: 100%;
  transition: 0.5s ease all;
}

#image-copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  margin: 0.5rem 1rem;
}

.col-set-main-data {
  height: 100%;
}

.setnumber-small-screen {
  margin-left: 1rem;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.6);
  line-height: 1.2;
}

.setname-small-screen {
  min-height: 4rem;
  max-height: 4rem;
  overflow: hidden;
  margin-left: 1rem;
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.6);
  line-height: 1;
}

.call-to-actions {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
}

.call-to-actions.small-screen {
  margin-left: 1rem;
}

.cta-buttons {
  display: flex;
}

.cta-container {
  display: flex;
  margin-right: 2rem;
}

.rating-container {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.rating-header {
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
}

.rating-header.small-screen {
  font-size: 10px;
}

.rating-value {
  font-size: 20px;
  line-height: 22px;
  min-width: 65px;
  color: #ffffff;
  font-weight: 600;
}

.rating-value.retailer {
  font-size: 16px;
}

.rating-value.small-screen {
  font-size: 16px;
  line-height: 16px;
}

.set-rating-star {
  font-size: 32px;
  background-image:linear-gradient(135deg, rgb(255, 255, 210), rgb(255, 196, 70));
  background-clip: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.set-rating-star.small-screen {
  font-size: 26px;
}

.button-secondary {
  padding: 0 1rem;
  font-size: 14px;
}

.button-secondary.watchlist {
  margin-left: 1.5rem
}

.coming-soon {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin: 2rem 0
}

.header-text.blank {
  opacity: 0.2;
}

.card-price,
.card-set-info {
  height: auto;
  padding-bottom: 15px;
}

.card-set-info.small-screen,
.card-price.small-screen,
.card-media.small-screen {
  margin: 1rem;
}

.card-header {
  display: flex;
  height: 44px;
  align-items: center;
}

.label {
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 0.1rem;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
}

.info-list {
  margin-left: 1.5rem;
}

.info-list-header {
  min-width: 120px;
}

.retailer-rating-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rating-star {
  color: #ffffff;
  font-size: 24px;
  margin: 0 0.25rem;
}

.rating-star.small-screen {
  font-size: 20px;
  margin-left: 0.25;
}

.button-container {
  display: flex;
  margin: 2rem 1rem;
  justify-content: center;
}

.secondary-icon-button {
  width: 45%;
  margin: 0 1rem;
  color: rgb(96, 255, 228);
  background: linear-gradient(90deg, rgba(96, 255, 228, 0.2) 25%, rgba(96, 255, 228, 0.4) 100%);
  border: 1px solid rgba(96, 255, 228, 0.6);
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
}

.buying-info-container {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.buying-info {
  color: #ffffff;
  font-size: 20px;
}

.card-body.minifigures {
  display: flex;
}

.minifigure_container {
  display: flex;
  flex-direction: column;
  width: 110px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  align-items: center;
}

.image_container_minifigures {
  position: relative;
  width: 80px;
  height: 100px;
  margin: 0 auto;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  transition: 0.5s ease transform;
}

.minifigures_info_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
}

.minifigure_name {
  height: 2.75rem;
  max-height: 2.75rem;
  font-size: 14px;
  margin: 0 0.5rem 0.5rem 0.5rem;
  color: #312659;
  text-shadow: 1px 1px 3px rgb(255 255 255 / 30%);
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.minifigure_bricklink {
  font-size: 14px;
  color: #ffffff;
  line-height: 1;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.minifigure_amount_container {
  position: absolute;
  display: grid;
  width: 40px;
  height: 30px;
  justify-content: center;
  align-content: center;
  background: rgb(187, 255, 244);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  z-index: 2;
  transform: translateX(75px) translateY(-20px);
}

.minifigure_amount_text {
  color: #09c5a6;
  font-size: 14px;
  font-weight: 700;
}

.retailer-list-header {
  display: flex;
  align-items: center;
  margin: 0 1.5rem 0 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.retail-price {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.card_related_sets {
  width: 100%
}

.hint_text {
  font-size: 14px;
  color: rgb(255, 255, 255);
}

#main_image_alt {
  height: 100%;
  font-size: 5rem;
  color: rgba(0, 0, 0, 0.2);
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.2);
}

.table {
  background: none;
}

.with_tooltip {
  margin-right: 0.25rem;
}

@media (min-width: 960px) {
  .info-list-header {
    min-width: 150px;
  }
}

@media (min-width: 1280px) {
  .info-list-header {
    min-width: 140px;
  }
  .retail-price {
    font-size: 22px;
  }
}

@media (min-width: 1920px) {
  .rating-value {
    font-size: 22px;
    line-height: 22px;
  }
  .rating-value.retailer {
    font-size: 20px;
  }
  .dialog-card {
    width: 70%;
  }
}

</style>
