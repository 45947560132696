<template>
  <section>
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row-search-mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
      </v-row>
    </v-container>

    <v-container>
      <template v-if="isMobile">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title>Filter anzeigen</v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row no-gutters class="dialog-body">
                <v-combobox v-model="queryTheme" :items="themes" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" append-inner-icon="mdi-shape" label="Themenwelt" density="compact" theme="dark"></v-combobox>
                <v-combobox v-model="queryRelease" :items="years" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="Release" append-inner-icon="mdi-calendar-month-outline" density="compact" theme="dark"></v-combobox>
                <v-combobox v-model="queryEol" :items="years" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="EOL" append-inner-icon="mdi-calendar-month-outline" density="compact" theme="dark"></v-combobox>
                <v-text-field v-model="queryRetailPriceStart" clearable class="mb-n3 input_field mobile" color="#60ffe4" label="ab UVP" append-inner-icon="mdi-currency-eur" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                <v-text-field v-model="queryRetailPriceEnd" clearable class="mb-n3 input_field mobile" color="#60ffe4" label="bis UVP" append-inner-icon="mdi-currency-eur" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                <v-combobox v-model="querySubtheme" :items="subthemes" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" append-inner-icon="mdi-shape-outline" label="Unterthemenwelt" density="compact" theme="dark"></v-combobox>
                <v-text-field v-model="queryMinifigures" clearable class="mb-n3 input_field mobile" color="#60ffe4" label="Minifiguren" append-inner-icon="mdi-account-multiple" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="1" placeholder="Anzahl" density="compact"></v-text-field>
                <v-text-field v-model="queryDesigner" clearable class="mb-n3 input_field mobile" color="#60ffe4" label="Designer" append-inner-icon="mdi-pencil-ruler" type="text" density="compact"></v-text-field>
                <v-combobox v-model="queryAvailability" :items="availabilities" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="Verfügbarkeit" append-inner-icon="mdi-cart" density="compact" theme="dark"></v-combobox>
                <template v-if="queryTheme || queryRelease || queryEol || queryRetailPriceStart || queryRetailPriceEnd || querySubtheme || queryMinifigures || queryDesigner || queryAvailability">
                  <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                </template>
                <template v-else>
                  <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                </template>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-else>
        <v-row no-gutters class="row_filters">
          <v-col cols="4" class="mx-2">
            <v-combobox v-model="queryTheme" :items="themes" clearable hide-details class="input_field" color="#60ffe4" append-inner-icon="mdi-shape" label="Themenwelt" theme="dark"></v-combobox>
          </v-col>
          <v-col cols="2" class="mx-2">
            <v-combobox v-model="queryRelease" :items="years" clearable hide-details class="input_field" color="#60ffe4" label="Release" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
          </v-col>
          <v-col cols="2" class="mx-2">
            <v-combobox v-model="queryEol" :items="years" clearable hide-details class="input_field" color="#60ffe4" label="EOL" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
          </v-col>
          <v-col cols="2" class="mx-2">
            <v-text-field v-model="queryRetailPriceStart" clearable class="input_field" color="#60ffe4" label="ab UVP" append-inner-icon="mdi-currency-eur" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" hint="Filtert Sets ab dieser UVP"></v-text-field>
          </v-col>
          <v-col cols="2" class="mx-2">
            <v-text-field v-model="queryRetailPriceEnd" clearable class="input_field" color="#60ffe4" label="bis UVP" append-inner-icon="mdi-currency-eur" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" hint="Filtert Sets bis zu dieser UVP"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="row_filters">
          <v-col cols="4" class="mx-2">
            <v-combobox v-model="querySubtheme" :items="subthemes" clearable hide-details class="input_field" color="#60ffe4" append-inner-icon="mdi-shape-outline" label="Unterthemenwelt" theme="dark"></v-combobox>
          </v-col>
          <v-col cols="2" class="mx-2">
            <v-text-field v-model="queryMinifigures" clearable class="input_field" color="#60ffe4" label="Minifiguren" append-inner-icon="mdi-account-multiple" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="1" placeholder="Anzahl" hint="Filtert Sets nach dieser Anzahl an Minifiguren"></v-text-field>
          </v-col>
          <v-col cols="2" class="mx-2">
            <v-text-field v-model="queryDesigner" clearable class="input_field" color="#60ffe4" label="Designer" append-inner-icon="mdi-pencil-ruler" type="text" hint="Filtert Sets nach dem Designer"></v-text-field>
          </v-col>
          <v-col cols="3" class="mx-2">
            <v-combobox v-model="queryAvailability" :items="availabilities" clearable hide-details class="input_field" color="#60ffe4" label="Verfügbarkeit" append-inner-icon="mdi-cart" theme="dark"></v-combobox>
          </v-col>
          <v-col>
            <template v-if="queryTheme || queryRelease || queryEol || queryRetailPriceStart || queryRetailPriceEnd || querySubtheme || queryMinifigures || queryDesigner || queryAvailability">
              <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
            </template>
            <template v-else>
              <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-container>

    <template v-if="loadingActive">
      <div class="card_body_loader">
        <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
      </div>
    </template>
    <template v-else>
      <v-container v-if="isMobile" class="pa-0">
        <template v-if="set_data.length > 0">
          <v-row class="mb-4" no-gutters>
            <h3 v-if="allSetsCount" class="header">Insgesamt habe ich <span class="result_text">{{ allSetsCount }}</span>
              <template v-if="allSetsCount > 1"> Sets</template>
              <template v-else> Set</template>
              gefunden.</h3>
          </v-row>
          <v-row class="row_deals mobile" justify="center" no-gutters>
            <div v-for="setItem in set_data" :key="setItem.key" class="mx-2">
              <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
                <SetCard
                :set="`${setItem.set}`"
                :name="`${setItem.name[0]?.name ?? ''}`"
                :retailPrice="setItem?.retail_price ?? ''"
                :year="getReleaseDate(setItem.release_date)"
                :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                :imageContainerWidth="'70px'"
                :imageContainerHeight="'70px'"
                :textContainerWidth="'90px'"
                :textContainerHeight="'100px'"
                :cardSetFontsize="'10px'"
                :cardNameFontsize="'12px'"
                :cardNameMinMaxHeight="'1.65rem'"
                :cardPriceFontsize="'12px'"
                :cardPriceColor="'#ffffff'"
                :cardYearFontsize="'10px'"
                :borderRadius="'15px'"
                :transformTextContainer="'translateY(-15px)'"
                :lineClamp="2"
                />
              </router-link>
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row no-gutters>
            <span class="hint-text">Aktuell haben wir hier leider keinen Deal für Dich.<br>
              Aber wir suchen natürlich weiter...</span>
          </v-row>
        </template>
      </v-container>
      <v-container v-else fluid>
        <template v-if="set_data.length > 0">
          <v-row class="mt-n6" no-gutters>
            <h3 v-if="allSetsCount" class="header">Insgesamt habe ich <span class="result_text">{{ allSetsCount }}</span>
              <template v-if="allSetsCount > 1"> Sets</template>
              <template v-else> Set</template>
              gefunden.</h3>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination v-model="queryPageCount" class="mb-4" :length="maxPageCount" color="#60ffe4"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
          <v-row no-gutters class="row_deals desktop">
            <div v-for="setItem in set_data" :key="setItem.key">
              <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
                <SetCard
                :set="`${setItem.set}`"
                :name="`${setItem.name[0]?.name ?? ''}`"
                :theme="`${setItem.theme?.name ?? ''}`"
                :retailPrice="setItem?.retail_price ?? ''"
                :year="getReleaseDate(setItem.release_date)"
                :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                :imageContainerWidth="'140px'"
                :imageContainerHeight="'140px'"
                :textContainerWidth="'180px'"
                :textContainerHeight="'150px'"
                :cardSetFontsize="'14px'"
                :cardNameFontsize="'14px'"
                :cardPriceFontsize="'16px'"
                :cardPriceColor="'#ffffff'"
                :borderRadius="'25px'"
                />
              </router-link>
            </div>
          </v-row>
          <v-row>
            <v-container>
              <v-row justify="center">
                <v-col cols="8">
                  <v-container class="max-width">
                    <v-pagination v-model="queryPageCount" class="mb-4" :length="maxPageCount" color="#60ffe4"></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </template>
        <template v-else>
          <v-row no-gutters>
            <span class="hint-text">Unter den aktiven Filtern habe ich leider nichts gefunden.</span>
          </v-row>
        </template>
      </v-container>
    </template>
  </section>
</template>

<script>
import Search from '../components/Search.vue';
import SearchMobile from '../components/SearchMobile.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import SetCard from '../components/SetCard.vue';
// import DealCardMobile from '../components/DealCardMobile.vue';

export default {
  name: 'SetOverviewWithFilter',
  components: {
    PrimaryButton,
    SearchMobile,
    Search,
    SetCard,
    // DealCardMobile
  },
  inject: [
    'api',
    'isMobile',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      mobile: false,
      themes: [],
      subthemes: [],
      currentTheme: null,
      queryTheme: this.$route.query.theme || null,
      currentSubtheme: null,
      querySubtheme: this.$route.query.subtheme || null,
      years: [],
      currentYearRelease: null,
      queryRelease: this.$route.query.yearRelease || null,
      currentYearEol: null,
      queryEol: this.$route.query.yearEol || null,
      currentRetailPriceStart: null,
      queryRetailPriceStart: this.$route.query.retailPriceStart || null,
      currentRetailPriceEnd: null,
      queryRetailPriceEnd: this.$route.query.retailPriceEnd || null,
      currentMinifigures: null,
      queryMinifigures: this.$route.query.minifigures || null,
      currentDesigner: null,
      queryDesigner: this.$route.query.designer || null,
      currentAvailability: null,
      queryAvailability: [
          { title: "LEGO Exklusiv" , value: "lego_exclusive" },
          { title: "Fachhandel" , value: "limited_retail" },
          { title: "Überall" , value: "retail" },
        ].find(el => el.value === this.$route.query.availability),
      set_data: [],
      loadingActive: false,
      queryPageCount: 1,
      maxPageCount: '',
      allSetsCount: '',
    }
  },
  watch: {
    queryFilterTheme() {
      this.queryTheme = this.$route.query.theme || null,
      this.getSets();
    },
    queryFilterSubtheme() {
      this.querySubtheme = this.$route.query.subtheme || null,
      this.getSets();
    },
    queryFilterYearRelease() {
      this.queryRelease = this.$route.query.yearRelease || null,
      this.getSets();
    },
    queryFilterYearEol() {
      this.queryEol = this.$route.query.yearEol || null,
      this.getSets();
    },
    queryFilterRetailPriceStart() {
      this.queryRetailPriceStart = this.$route.query.retailPriceStart || null,
      this.getSets();
    },
    queryFilterRetailPriceEnd() {
      this.queryRetailPriceEnd = this.$route.query.retailPriceEnd || null,
      this.getSets();
    },
    queryFilterMinifigures() {
      this.queryMinifigures = this.$route.query.minifigures || null,
      this.getSets();
    },
    queryFilterDesigner() {
      this.queryDesigner = this.$route.query.designer || null,
      this.getSets();
    },
    queryFilterAvailability() {
      this.queryAvailability = [
        { title: "LEGO Exklusiv" , value: "lego_exclusive" },
        { title: "Fachhandel" , value: "limited_retail" },
        { title: "Überall" , value: "retail" },
      ].find(el => el.value === this.$route.query.availability);
      this.getSets();
    },
    queryPageCount() {
      this.getSets();
    },
    queryTheme(value) {
      if (value) {
        // check input length > 2; debounce query fire
        this.checkInput(value, 2, 'theme');
      }
      else {
        this.queryTheme = null;
        this.currentTheme = null;
        this.pushQuery();
      }
    },
    querySubtheme(value) {
      if (value) {
        // check input length > 2; debounce query fire
        this.checkInput(value, 2, 'subtheme');
      }
      else {
        this.querySubtheme = null;
        this.currentSubtheme = null;
        this.pushQuery();
      }
    },
    queryRelease(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'yearRelease');
      }
      else {
        this.queryRelease = null;
        this.currentYearRelease = null;
        this.pushQuery();
      }
    },
    queryEol(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'yearEol');
      }
      else {
        this.queryEol = null;
        this.currentYearEol = null;
        this.pushQuery();
      }
    },
    queryRetailPriceStart(value) {
      this.currentRetailPriceStart = value;
      this.pushQuery();
    },
    queryRetailPriceEnd(value) {
      this.currentRetailPriceEnd = value;
      this.pushQuery();
    },
    queryMinifigures(value) {
      this.currentMinifigures = value;
      this.pushQuery();
    },
    queryDesigner(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'designer');
      }
      else {
        this.queryDesigner = null;
        this.currentDesigner = null;
        this.pushQuery();
      }
    },
    queryAvailability(value) {
      this.currentAvailability = value;
      this.pushQuery();
    },
  },
  computed: {
    queryFilterTheme() {
      return this.$route.query.theme || null;
    },
    queryFilterSubtheme() {
      return this.$route.query.subtheme || null;
    },
    queryFilterYearRelease() {
      return this.$route.query.yearRelease || null;
    },
    queryFilterYearEol() {
      return this.$route.query.yearEol || null;
    },
    queryFilterRetailPriceStart() {
      return this.$route.query.retailPriceStart || null;
    },
    queryFilterRetailPriceEnd() {
      return this.$route.query.retailPriceEnd || null;
    },
    queryFilterMinifigures() {
      return this.$route.query.minifigures || null;
    },
    queryFilterDesigner() {
      return this.$route.query.designer || null;
    },
    queryFilterAvailability() {
      return this.$route.query.availability || null;
    },
  },
  created() {
    this.getSets();
    this.getThemes();
    this.getSubthemes();
    this.getYears();
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
    this.availabilities = [
      { title: "LEGO Exklusiv" , value: "lego_exclusive" },
      { title: "Fachhandel" , value: "limited_retail" },
      { title: "Überall" , value: "retail" },
    ];
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    async getSets() {
      let res = [];
      this.set_data = [];
      this.loadingActive = true;

      // Set all filter parameter to filter object
      let filterObject = {}
      if (this.queryFilterTheme) { filterObject['theme'] = {"name": this.queryFilterTheme }}
      if (this.queryFilterSubtheme) { filterObject['subtheme'] = {"name": this.queryFilterSubtheme }}
      if (this.queryFilterYearRelease) { filterObject['release_date'] = {"_between": [ this.queryFilterYearRelease + '-01-01T12:00:00', this.queryFilterYearRelease + '-12-31T12:00:00']}}
      if (this.queryFilterYearEol) { filterObject['eol_date'] = {"_between": [ this.queryFilterYearEol + '-01-01T12:00:00', this.queryFilterYearEol + '-12-31T12:00:00']}}
      if (this.queryFilterRetailPriceStart && this.queryFilterRetailPriceEnd === null) { filterObject['retail_price'] = {"_and": [{"price": {"_gte": this.queryFilterRetailPriceStart }}, {"price_code": this.localCurrency }]}}
      if (this.queryFilterRetailPriceStart === null && this.queryFilterRetailPriceEnd) { filterObject['retail_price'] = {"_and": [{"price": {"_lte": this.queryFilterRetailPriceEnd }},{"price_code": this.localCurrency }]}}
      if (this.queryFilterRetailPriceStart && this.queryFilterRetailPriceEnd) { filterObject['retail_price'] = {"_and": [{"price": {"_between": [this.queryFilterRetailPriceStart, this.queryFilterRetailPriceEnd]}},{"price_code": this.localCurrency}]}}
      if ((this.queryFilterRetailPriceStart === this.queryFilterRetailPriceEnd) && (this.queryFilterRetailPriceStart !== null && this.queryFilterRetailPriceEnd !== null)) { filterObject['retail_price'] = {"_and": [{"price": this.queryFilterRetailPriceStart},{"price_code": this.localCurrency}]}}
      if (this.queryFilterMinifigures) { filterObject['count(minifigures)'] = {"_eq": this.queryFilterMinifigures }}
      if (this.queryFilterDesigner) { filterObject['designer'] = {"_contains": this.queryFilterDesigner }}
      if (this.queryFilterAvailability) { filterObject['availability'] = this.queryFilterAvailability }

      // send request
      res = await this.api.items('sets?fields=*.*,images.directus_files_id.*,minifigures.*,minifig_count.*'
      + '&sort=-release_date'
      + '&meta=filter_count'
      + '&limit=50&page=' + this.queryPageCount).readByQuery({
        filter: filterObject
      });
      this.allSetsCount = res.meta.filter_count;
      this.maxPageCount = Math.ceil((this.allSetsCount/50));
      this.set_data = res.data;
      this.loadingActive = false;
    },
    async getThemes() {
      const res = await this.api.items('themes?fields=*.*&limit=-1').readByQuery();
      let sortedThemesData = this.sortArrayByName(res.data);
      sortedThemesData.forEach(theme => {
        this.themes.push(theme.name);
      });
    },
    async getSubthemes() {
      const res = await this.api.items('subthemes?fields=*.*&limit=-1').readByQuery();
      let sortedSubthemesData = this.sortArrayByName(res.data);
      sortedSubthemesData.forEach(subtheme => {
        this.subthemes.push(subtheme.name);
      });
    },
    getYears() {
      const currentYearRelease = new Date().getFullYear();
      const nextYearRelease = new Date().getFullYear() + 1;
      this.years.push(nextYearRelease.toString());
      for (let i = currentYearRelease; i > 1970; i--) {
        this.years.push(i.toString());
      }
    },
    formatRetailPrice(retail_price) {
      if (retail_price) { return retail_price + ' ' + this.mapCurrencyUnit(this.localCurrency) }
      return '-';
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    resetFilter() {
      this.queryTheme = null;
      this.currentTheme = null;
      this.querySubtheme = null;
      this.currentSubtheme = null;
      this.queryRelease = null;
      this.currentYearRelease = null;
      this.queryEol = null;
      this.currentYearEol = null;
      this.queryRetailPriceStart = null;
      this.currentRetailPriceStart = null;
      this.queryRetailPriceEnd = null;
      this.currentRetailPriceEnd = null;
      this.queryMinifigures = null;
      this.currentMinifigures = null;
      this.queryDesigner = null;
      this.currentDesigner = null;
      this.queryAvailability = null;
      this.currentAvailability = null;
      this.$router.push({ path: '/items/sets-overview' })
    },
    pushQuery() {
      let queryObject = {}
      if (this.currentTheme) { queryObject['theme'] = this.currentTheme }
      if (this.currentSubtheme) { queryObject['subtheme'] = this.currentSubtheme }
      if (this.currentYearRelease) { queryObject['yearRelease'] = this.currentYearRelease }
      if (this.currentYearEol) { queryObject['yearEol'] = this.currentYearEol }
      if (this.currentRetailPriceStart) { queryObject['retailPriceStart'] = this.currentRetailPriceStart }
      if (this.currentRetailPriceEnd) { queryObject['retailPriceEnd'] = this.currentRetailPriceEnd }
      if (this.currentMinifigures) { queryObject['minifigures'] = this.currentMinifigures }
      if (this.currentDesigner) { queryObject['designer'] = this.currentDesigner }
      if (this.currentAvailability) { queryObject['availability'] = this.currentAvailability.value }
      this.$router.push({ path: '/items/sets-overview', query: queryObject});
    },
    sortArrayByName(array) {
      return array.sort((a, b) => (a.name > b.name) ? 1 : -1) ?? undefined;
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    debounceInputTheme(themeValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentTheme = themeValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputSubtheme(subthemeValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentSubtheme = subthemeValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputRelease(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearRelease = yearValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputEol(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearEol = yearValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputDesigner(designerValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentDesigner = designerValue;
        this.pushQuery();
      }, 500)
    },
    checkInput(inputValue, minChars, filter) {
      if (inputValue.length > 0) {
        if (inputValue.length > minChars) {
          if (filter === 'theme') this.debounceInputTheme(inputValue);
          if (filter === 'subtheme') this.debounceInputSubtheme(inputValue);
          if (filter === 'yearRelease') this.debounceInputRelease(inputValue);
          if (filter === 'yearEol') this.debounceInputEol(inputValue);
          if (filter === 'designer') this.debounceInputDesigner(inputValue);
          return;
        }
        return;
      }
      return;
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1200px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.header {
  margin: 0 auto;
}

.result_text {
  color: #60ffe4;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}

.row-search-mobile {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.row_filters {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.row_deals {
  position: relative;
  width: 98%;
  margin-bottom: 2rem;
}

.row_deals.desktop {
  justify-content: center;
}

.deal_item {
  position: relative;
  width: 92%;
}

.hint-text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.card_body_loader {
  margin-top: 5rem;
}

.loader {
  width: 50%;
}

.reset_button {
  z-index: 1;
}

.v-expansion-panel {
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 10px;
  color: #ffffff;
}

.v-expansion-panel-title {
  font-size: 12px !important;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}




</style>
