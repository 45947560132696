<template>
  <section>
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row-search-mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
      </v-row>
    </v-container>

    <v-container>
      <template v-if="isMobile">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title>Filter anzeigen</v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row no-gutters class="dialog-body">
                <v-combobox v-model="queryEol" :items="years" hide-details class="mb-2 input_field mobile" color="#60ffe4" label="EOL" append-inner-icon="mdi-calendar-month-outline" density="compact" theme="dark"></v-combobox>
                <v-divider class="mt-1 mb-3 mx-n2"></v-divider>
                <v-text-field v-model="queryRetailPriceStart" clearable class="mb-n3 input_field mobile" color="#60ffe4" label="ab UVP" append-inner-icon="mdi-currency-eur" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                <v-text-field v-model="queryRetailPriceEnd" clearable class="mb-n3 input_field mobile" color="#60ffe4" label="bis UVP" append-inner-icon="mdi-currency-eur" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="compact"></v-text-field>
                <v-combobox v-model="queryAvailability" :items="availabilities" clearable hide-details class="mb-2 input_field mobile" color="#60ffe4" label="Verfügbarkeit" append-inner-icon="mdi-cart" density="compact" theme="dark"></v-combobox>
                <template v-if="queryRetailPriceStart || queryRetailPriceEnd || queryAvailability">
                  <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                </template>
                <template v-else>
                  <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
                </template>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-else>
        <v-row no-gutters class="mb-2 row_filters">
          <p class="normal_text">Hier kannst du das gewünschte EOL-Jahr ändern:
            <Popper class="tooltip" placement="top" arrow="true" offsetDistance="8" content="Als Standardwert ist 2023 eingestellt und wird aufgerufen, wenn das Feld leer wird.">
              <i class="fas fa-exclamation-circle info_tooltip"></i>
            </Popper>
          </p>
        </v-row>
        <v-row no-gutters class="mb-6 row_filters">
          <v-col cols="2" class="mx-2">
            <v-combobox v-model="queryEol" :items="years" hide-details class="input_field" color="#60ffe4" label="EOL" append-inner-icon="mdi-calendar-month-outline" theme="dark"></v-combobox>
          </v-col>
        </v-row>
        <v-row no-gutters class="row_filters">
          <p class="mb-2 normal_text">Filter das eingestellte EOL-Jahr gerne noch ein bisschen:</p>
        </v-row>
        <v-row no-gutters class="row_filters">
          <v-col cols="2" class="mx-2">
            <v-text-field v-model="queryRetailPriceStart" clearable class="input_field" color="#60ffe4" label="ab UVP" append-inner-icon="mdi-currency-eur" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" hint="Filtert Sets ab dieser UVP"></v-text-field>
          </v-col>
          <v-col cols="2" class="mx-2">
            <v-text-field v-model="queryRetailPriceEnd" clearable class="input_field" color="#60ffe4" label="bis UVP" append-inner-icon="mdi-currency-eur" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" hint="Filtert Sets bis zu dieser UVP"></v-text-field>
          </v-col>
          <v-col cols="3" class="mx-2">
            <v-combobox v-model="queryAvailability" :items="availabilities" clearable hide-details class="input_field" color="#60ffe4" label="Verfügbarkeit" append-inner-icon="mdi-cart" theme="dark"></v-combobox>
          </v-col>
          <v-col cols="auto">
            <template v-if="queryRetailPriceStart || queryRetailPriceEnd || queryAvailability">
              <PrimaryButton @click="resetFilter()" class="mt-1 reset_button" buttonText="Zurücksetzen"/>
            </template>
            <template v-else>
              <PrimaryButton disabled class="mt-1 reset_button" buttonText="Zurücksetzen"/>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-container>

    <template v-if="loadingActive">
      <div class="card_body_loader">
        <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
      </div>
    </template>
    <template v-else>
      <v-container v-if="isMobile" class="pa-0">
        <template v-if="theme_sorted_set_data">
          <v-row class="mb-4" no-gutters>
            <h3 v-if="allSetsCount" class="header">Insgesamt habe ich <span class="result_text">{{ allSetsCount }}</span>
              <template v-if="allSetsCount > 1"> Sets</template>
              <template v-else> Set</template>
              gefunden.</h3>
          </v-row>
          <v-row class="row" justify="center" no-gutters>
            <div v-for="(key, theme) in theme_sorted_set_data" :key="key">
              <v-row class="mb-6 row" no-gutters>
                <router-link :to="{ path: '/themes/' + theme }">
                  <h2 class="ml-4 mt-8 mb-2 header">{{ theme }}</h2>
                </router-link>
                <div v-for="set in theme_sorted_set_data[theme]" :key="set.set" class="mb-4 card_main mobile">
                  <router-link :to="{ path: '/items/sets/' + set?.set }">
                    <v-row class="row_sets" no-gutters>
                      <v-col cols="auto" class="mr-12">
                        <div class="ml-1 image_container">
                          <v-img v-if="set?.header_image || set?.images" :src="getSetOverviewImage(set?.header_image, set?.images)"></v-img>
                          <v-icon v-else id="small_image_alt" icon="mdi-package" ></v-icon>
                        </div>
                      </v-col>
                      <v-col cols="auto" class="d-flex flex-column">
                        <span class="ml-6 card_text_header mobile">Setnummer</span>
                        <span class="ml-6 card_text mobile">{{ set?.set }}</span>
                      </v-col>
                      <v-col cols="3" class="d-flex flex-column">
                          <span class="ml-4 card_text_header mobile">Name</span>
                          <span id="card_text_name_mobile" class="ml-4 card_text mobile">{{ set?.name[0]?.name ?? '?' }}</span>
                      </v-col>
                      <v-col cols="auto" class="d-flex flex-column">
                        <span class="ml-2 card_text_header mobile">UVP</span>
                        <span class="ml-2 card_text mobile">{{ formatRetailPrice(set?.retail_price) || '-' }}</span>
                      </v-col>
                      <v-col cols="auto" class="d-flex flex-column">
                        <span class="ml-6 card_text_header mobile">Release</span>
                        <span class="ml-6 card_text mobile">{{ getReleaseDate(set?.release_date) || '-' }}</span>
                      </v-col>
                    </v-row>
                  </router-link>
                </div>
              </v-row>
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row no-gutters>
            <span class="hint-text">Ich kann aktuell keine Einträge zu den gegebenen Filtern finden!</span>
          </v-row>
        </template>
      </v-container>
      <v-container v-else>
        <template v-if="theme_sorted_set_data">
          <v-row class="mt-n6" no-gutters>
            <h3 v-if="allSetsCount" class="header">Insgesamt habe ich <span class="result_text">{{ allSetsCount }}</span>
              <template v-if="allSetsCount > 1"> Sets</template>
              <template v-else> Set</template>
              gefunden.</h3>
          </v-row>
          <v-row class="row" justify="center" no-gutters>
            <div v-for="(key, theme) in theme_sorted_set_data" :key="key">
              <v-row class="mb-6 row" no-gutters>
                <router-link :to="{ path: '/themes/' + theme }">
                  <h1 class="ml-2 mt-8 mb-2 header">{{ theme }}</h1>
                </router-link>
                <div v-for="set in theme_sorted_set_data[theme]" :key="set.set" class="mb-4 card_main">
                  <router-link :to="{ path: '/items/sets/' + set?.set }">
                    <v-row class="row_sets" no-gutters>
                      <v-col cols="auto" class="mr-16">
                        <div class="ml-1 image_container">
                          <v-img v-if="set?.images" :src="getSetOverviewImage(set?.header_image, set?.images)"></v-img>
                          <v-icon v-else id="small_image_alt" icon="mdi-package" ></v-icon>
                        </div>
                      </v-col>
                      <v-col cols="1" class="d-flex flex-column">
                        <span class="ml-6 card_text_header">Setnummer</span>
                        <span class="ml-6 card_text">{{ set?.set }}</span>
                      </v-col>
                      <v-col cols="3" class="d-flex flex-column">
                        <span class="ml-4 card_text_header">Name</span>
                        <span class="ml-4 mr-6 card_text">{{ set?.name[0]?.name ?? '?' }}</span>
                      </v-col>
                      <v-col cols="1" class="d-flex flex-column">
                        <span class="ml-6 card_text_header">UVP</span>
                        <span class="ml-6 card_text">{{ formatRetailPrice(set?.retail_price) || '-' }}</span>
                      </v-col>
                      <v-col cols="1" class="d-flex flex-column">
                        <span class="ml-6 card_text_header">Release</span>
                        <span class="ml-6 card_text">{{ getReleaseDate(set?.release_date) || '-' }}</span>
                      </v-col>
                      <v-col cols="1" class="d-flex flex-column">
                        <span class="ml-2 card_text_header">EOL</span>
                        <span class="ml-2 card_text">{{ getReleaseDate(set?.eol_date) || '-' }}</span>
                      </v-col>
                      <v-col cols="2" class="d-flex flex-column">
                        <span class="ml-2 card_text_header">Verfügbarkeit</span>
                        <span class="ml-2 card_text">{{ getAvailabiliy(set?.availability) || '-' }}</span>
                      </v-col>
                    </v-row>
                  </router-link>
                </div>
              </v-row>
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row no-gutters>
            <span class="hint-text">Unter den aktiven Filtern habe ich leider nichts gefunden.</span>
          </v-row>
        </template>
      </v-container>
    </template>
  </section>
</template>

<script>
import Search from '../components/Search.vue';
import SearchMobile from '../components/SearchMobile.vue';
import PrimaryButton from '../components/PrimaryButton.vue';

export default {
  name: 'EndOfLifeOverview',
  components: {
    PrimaryButton,
    SearchMobile,
    Search,
  },
  inject: [
    'api',
    'isMobile',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      mobile: false,
      years: [],
      currentYearEol: '2023',
      queryEol: this.$route.query.yearEol || '2023',
      currentRetailPriceStart: null,
      queryRetailPriceStart: this.$route.query.retailPriceStart || null,
      currentRetailPriceEnd: null,
      queryRetailPriceEnd: this.$route.query.retailPriceEnd || null,
      currentAvailability: null,
      queryAvailability: [
          { title: "LEGO Exklusiv" , value: "lego_exclusive" },
          { title: "Fachhandel" , value: "limited_retail" },
          { title: "Überall" , value: "retail" },
        ].find(el => el.value === this.$route.query.availability),
      theme_sorted_set_data: {},
      loadingActive: false,
      allSetsCount: '',
    }
  },
  watch: {
    queryFilterYearEol() {
      this.queryEol = this.$route.query.yearEol || '2023',
      this.getSets();
    },
    queryFilterRetailPriceStart() {
      this.queryRetailPriceStart = this.$route.query.retailPriceStart || null,
      this.getSets();
    },
    queryFilterRetailPriceEnd() {
      this.queryRetailPriceEnd = this.$route.query.retailPriceEnd || null,
      this.getSets();
    },
    queryFilterAvailability() {
      this.queryAvailability = [
        { title: "LEGO Exklusiv" , value: "lego_exclusive" },
        { title: "Fachhandel" , value: "limited_retail" },
        { title: "Überall" , value: "retail" },
      ].find(el => el.value === this.$route.query.availability);
      this.getSets();
    },
    queryEol(value) {
      if (value) {
        // check input length > 3; debounce query fire
        this.checkInput(value, 3, 'yearEol');
      }
      else {
        this.queryEol = '2023';
        this.currentYearEol = '2023';
        this.pushQuery();
      }
    },
    queryRetailPriceStart(value) {
      this.currentRetailPriceStart = value;
      this.pushQuery();
    },
    queryRetailPriceEnd(value) {
      this.currentRetailPriceEnd = value;
      this.pushQuery();
    },
    queryMinifigures(value) {
      this.currentMinifigures = value;
      this.pushQuery();
    },
    queryAvailability(value) {
      this.currentAvailability = value;
      this.pushQuery();
    },
  },
  computed: {
    queryFilterYearEol() {
      return this.$route.query.yearEol || '2023';
    },
    queryFilterRetailPriceStart() {
      return this.$route.query.retailPriceStart || null;
    },
    queryFilterRetailPriceEnd() {
      return this.$route.query.retailPriceEnd || null;
    },
    queryFilterAvailability() {
      return this.$route.query.availability || null;
    },
  },
  created() {
    this.getSets();
    this.getYears();
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
    this.availabilities = [
      { title: "LEGO Exklusiv" , value: "lego_exclusive" },
      { title: "Fachhandel" , value: "limited_retail" },
      { title: "Überall" , value: "retail" },
    ];
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    async getSets() {
      let res = [];
      this.theme_sorted_set_data = [];
      this.loadingActive = true;

      // Set all filter parameter to filter object
      let filterObject = {}
      if (this.queryFilterYearEol) { filterObject['eol_date'] = {"_between": [ this.queryFilterYearEol + '-01-01T12:00:00', this.queryFilterYearEol + '-12-31T12:00:00']}}
      if (this.queryFilterRetailPriceStart && this.queryFilterRetailPriceEnd === null) { filterObject['retail_price'] = {"_and": [{"price": {"_gte": this.queryFilterRetailPriceStart }}, {"price_code": this.localCurrency }]}}
      if (this.queryFilterRetailPriceStart === null && this.queryFilterRetailPriceEnd) { filterObject['retail_price'] = {"_and": [{"price": {"_lte": this.queryFilterRetailPriceEnd }},{"price_code": this.localCurrency }]}}
      if (this.queryFilterRetailPriceStart && this.queryFilterRetailPriceEnd) { filterObject['retail_price'] = {"_and": [{"price": {"_between": [this.queryFilterRetailPriceStart, this.queryFilterRetailPriceEnd]}},{"price_code": this.localCurrency}]}}
      if ((this.queryFilterRetailPriceStart === this.queryFilterRetailPriceEnd) && (this.queryFilterRetailPriceStart !== null && this.queryFilterRetailPriceEnd !== null)) { filterObject['retail_price'] = {"_and": [{"price": this.queryFilterRetailPriceStart},{"price_code": this.localCurrency}]}}
      if (this.queryFilterAvailability) { filterObject['availability'] = this.queryFilterAvailability }

      // send request
      res = await this.api.items('sets?fields=*.*,images.directus_files_id.*,minifigures.*,minifigures.minifigures_number.*,minifigures.minifigures_number.images.*'
      + '&sort=theme.name'
      + '&meta=filter_count'
      + '&limit=1000').readByQuery({
        filter: filterObject
      });
      this.allSetsCount = res.meta.filter_count;
      this.theme_sorted_set_data = groupByThemeName(res.data);
      this.loadingActive = false;

      function groupByThemeName(arr) {
        var groupedObj = {};
        arr.forEach(element => {
          var themeName = element?.theme?.name || 'Verschiedenes';
          if (!groupedObj[themeName]) {
            groupedObj[themeName] = [];
          }
          groupedObj[themeName].push(element);
        });
        const orderedObj = Object.keys(groupedObj).sort().reduce(
          (obj, key) => {
            obj[key] = groupedObj[key];
            return obj;
          },
          {}
        );
        return orderedObj;
      }
    },
    getYears() {
      const currentYearRelease = new Date().getFullYear();
      const nextYearRelease = new Date().getFullYear() + 1;
      this.years.push(nextYearRelease.toString());
      for (let i = currentYearRelease; i > 1970; i--) {
        this.years.push(i.toString());
      }
    },
    formatRetailPrice(priceObject) {
      try {
        if (priceObject) {
          let localRetailPrice = priceObject?.filter(el => el.price_code === this.localCurrency)[0]?.price ?? null;
          if (localRetailPrice) {
            localRetailPrice = localRetailPrice.toFixed(2);
            return localRetailPrice + ' ' + this.mapCurrencyUnit(this.localCurrency);
          }
        }
        return '-';
      }
      catch(err) { console.warn(err, 'Formatting of the retail price not possible!'); }
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    resetFilter() {
      this.queryEol = '2023';
      this.queryRetailPriceStart = null;
      this.currentRetailPriceStart = null;
      this.queryRetailPriceEnd = null;
      this.currentRetailPriceEnd = null;
      this.queryAvailability = null;
      this.currentAvailability = null;
      this.$router.push({ path: '/eol-overview', query: {'yearEol': "2023"}});
    },
    pushQuery() {
      let queryObject = {}
      if (this.currentYearEol) { queryObject['yearEol'] = this.currentYearEol }
      if (this.currentRetailPriceStart) { queryObject['retailPriceStart'] = this.currentRetailPriceStart }
      if (this.currentRetailPriceEnd) { queryObject['retailPriceEnd'] = this.currentRetailPriceEnd }
      if (this.currentAvailability) { queryObject['availability'] = this.currentAvailability.value }
      this.$router.push({ path: '/eol-overview', query: queryObject});
    },
    sortArrayByName(array) {
      return array.sort((a, b) => (a.name > b.name) ? 1 : -1) ?? undefined;
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    debounceInputTheme(themeValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentTheme = themeValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputSubtheme(subthemeValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentSubtheme = subthemeValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputRelease(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearRelease = yearValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputEol(yearValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentYearEol = yearValue;
        this.pushQuery();
      }, 500)
    },
    debounceInputDesigner(designerValue) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.currentDesigner = designerValue;
        this.pushQuery();
      }, 500)
    },
    checkInput(inputValue, minChars, filter) {
      if (inputValue.length > 0) {
        if (inputValue.length > minChars) {
          if (filter === 'theme') this.debounceInputTheme(inputValue);
          if (filter === 'subtheme') this.debounceInputSubtheme(inputValue);
          if (filter === 'yearRelease') this.debounceInputRelease(inputValue);
          if (filter === 'yearEol') this.debounceInputEol(inputValue);
          if (filter === 'designer') this.debounceInputDesigner(inputValue);
          return;
        }
        return;
      }
      return;
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
    getAvailabiliy(availability) {
      try {
        if (availability) {
          let availabilities = [
            { text: "LEGO Exklusiv" , value: "lego_exclusive" },
            { text: "Fachhandel" , value: "limited_retail" },
            { text: "Überall" , value: "retail" },
          ];
          return availabilities.find(el => el.value === availability).text;
        }
      }
      catch (err) { console.warn(err, "Converting availability not possible!"); }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1200px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.header {
  margin: 0 auto;
}

.result_text {
  color: #60ffe4;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}

.row-search-mobile {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.row_filters {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.row {
  position: relative;
  display: block;
}
.hint-text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.card_body_loader {
  margin-top: 5rem;
}

.loader {
  width: 50%;
}

.reset_button {
  z-index: 1;
}

.v-expansion-panel {
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 10px;
  color: #ffffff;
}

.v-expansion-panel-title {
  font-size: 12px !important;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}

.row_sets {
  display: flex;
  height: 4rem;
  align-content: center;
}

.card_main {
  width: 99%;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 999px;
  transition: 0.2s ease all;
}
  .card_main:hover {
    width: 100%;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
  }

.card_main.mobile {
  width: 94%;
}

.image_container {
  position: absolute;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 999px;
  background: #ffffff;
  overflow: hidden;
}

.card_text_header {
  color: #ffffff;
  font-size: 10px;
  line-height: 0.6rem;
  text-transform: uppercase;
}

.card_text_header.mobile {
  font-size: 9px;
  line-height: 0.6rem;
}

.card_text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 800;
}

.card_text.mobile {
  font-size: 10px;
  font-weight: 800;
}

.info_tooltip {
  padding-left: 0.15rem;
}

#card_text_name_mobile {
  margin-top: 2px;
  position: relative;
  min-height: 1.4rem;
  max-height: 1.4rem;
  overflow: hidden;
  line-height: 1;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

</style>
