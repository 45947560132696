<template>
  <section>
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row-search-mobile">
        <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du was bestimmtes?" />
      </v-row>
    </v-container>

    <v-container>
      <v-row class="mt-n6 mb-6" justify="center" no-gutters>
        <div class="container_text">
          <template v-if="isMobile"><h2 class="text-center">Neuheiten unserer Datenbank</h2></template>
          <template v-else><h1>Neuheiten unserer Datenbank</h1></template>
          <p v-if="isMobile" class="normal_text text_mobile">Hier findest du alle Set-Neuheiten und kürzlich zur brickdex-Datenbank hinzugefügten Sets. Suchst du nach einer bestimmten Themenwelt, dann schau dich gerne
          <router-link class="text-link mobile" to='/themes'>hier</router-link> um.<br> Die aktuellen Top-Deals findest du auf der <router-link class="text-link mobile" to='/deals'>Deals-Übersicht</router-link>.</p>
          <p v-else class="normal_text">Hier findest du alle Set-Neuheiten und kürzlich zur brickdex-Datenbank hinzugefügten Sets.<br> Suchst du nach einer bestimmten Themenwelt, dann schau dich gerne
          <router-link class="text-link" to='/themes'>hier</router-link> um. Die aktuellen Top-Deals findest du auf der <router-link class="text-link" to='/deals'>Deals-Übersicht</router-link>.</p>
        </div>
      </v-row>
    </v-container>

    <v-container v-if="false">
      <v-row no-gutters class="row-filters">
        <Combobox v-model="queryTheme" :options="themes" class="filter" placeholderText="Themenwelt"/>
        <template v-if="queryTheme || queryRetailer">
          <PrimaryButton @click="resetFilter()" faIconClass="fa fa-undo"/>
        </template>
      </v-row>
    </v-container>

    <template v-if="loadingActive">
      <div class="card_body_loader">
        <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
      </div>
    </template>
    <template v-else>
      <v-container v-if="isMobile" class="pa-0">
        <template v-if="sets.length > 0">
          <v-row no-gutters class="row_deals mobile">
            <div v-for="setItem in sets" :key="setItem.key" class="deal_item">
              <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
                <DealCardMobile
                  class="ml-4 mb-6"
                  :set="`${setItem.set}`"
                  :name="`${setItem.name[0]?.name ?? ''}`"
                  :theme="`${setItem.theme?.name ?? ''}`"
                  :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                  :imageContainerWidth="'70px'"
                  :imageContainerHeight="'70px'"
                  :borderRadiusImage="'15px'"
                  :textContainerHeight="'70px'"
                  :themeActive=true
                  :textContainerMarginLeft="'4.5rem'"
                />
              </router-link>
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row no-gutters>
            <span class="hint-text">Aktuell haben wir hier leider keinen Deal für Dich.<br>
              Aber wir suchen natürlich weiter...</span>
          </v-row>
        </template>
      </v-container>

      <v-container v-else fluid>
        <template v-if="sets.length > 0">
          <v-row no-gutters class="row_deals desktop">
            <div v-for="setItem in sets" :key="setItem.key">
              <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
                <DealCard
                  :set="`${setItem.set}`"
                  :name="`${setItem.name[0]?.name ?? ''}`"
                  :theme="`${setItem.theme?.name ?? ''}`"
                  :image="getSetOverviewImage(setItem.header_image, setItem.images)"
                  :yearActive=true
                  :year="setItem.release_date ?? null"
                  :textContainerHeight="'160px'"
                  :cardSetFontsize="'14px'"
                  :cardThemeFontsize="'14px'"
                  :cardNameFontsize="'14px'"
                />
              </router-link>
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row no-gutters>
            <span class="hint-text">Hier habe ich leider nichts gefunden... </span>
          </v-row>
        </template>
      </v-container>
    </template>
  </section>
</template>

<script>
import Search from '../components/Search.vue';
import SearchMobile from '../components/SearchMobile.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import Combobox from '../components/Combobox.vue';
import DealCard from '../components/DealCard.vue';
import DealCardMobile from '../components/DealCardMobile.vue';

export default {
  name: 'NewDatabaseEntries',
  components: {
    PrimaryButton,
    Combobox,
    SearchMobile,
    Search,
    DealCard,
    DealCardMobile
  },
  inject: [
    'api',
    'isMobile',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      mobile: false,
      themes: [],
      currentTheme: '',
      queryTheme: this.$route.query.theme || '',
      retailer: [],
      currentRetailer: '',
      queryRetailer: this.$route.query.retailer || '',
      sets: [],
      loadingActive: false,
    }
  },
  watch: {
    queryFilterTheme() {
      this.getSets();
    },
    queryFilterRetailer() {
      this.getSets();
    },
    queryTheme(value) {
      if (value) {
        this.currentTheme = this.themes.find(el => el.text === value)?.text ?? '';
        this.pushQuery();
      }
    },
    queryRetailer(value) {
      if (value) {
        this.currentRetailer = this.retailer.find(el => el.text === value)?.text ?? '';
        this.pushQuery();
      }
    },
  },
  computed: {
    queryFilterTheme() {
      return this.$route.query.theme || '';
    },
    queryFilterRetailer() {
      return this.$route.query.retailer || '';
    },
  },
  created() {
    this.getSets();
    this.getThemes();
    this.getRetailer();
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    async getSets() {
      let res = [];
      this.sets = [];
      this.loadingActive = true;
      if (this.queryFilterTheme) {
        res = await this.api.items('sets?fields=*.*,images.directus_files_id.*&filter={"_and":[{"name":{"name":{"_nempty":true}}},{"images":{"set_set":{"_nempty":true}}}]}&filter[set][theme][name]=' + this.queryFilterTheme + '&sort=-date_created&limit=50').readByQuery();
      }
      else { res = await this.api.items('sets?fields=*.*,images.directus_files_id.*&filter={"_and":[{"name":{"name":{"_nempty":true}}},{"images":{"set_set":{"_nempty":true}}}]}&sort=-date_created&limit=50').readByQuery(); }

      this.sets = res.data;
      this.loadingActive = false;
    },
    async getThemes() {
      const res = await this.api.items('themes?fields=*.*&limit=-1').readByQuery();
      let sortedThemesData = this.sortArrayByName(res.data);

      sortedThemesData.forEach(theme => {
        let themeData = {
          text: theme.name || '',
          value: theme.id || ''
        };
        this.themes.push(themeData);
      });
    },
    async getRetailer() {
      const res = await this.api.items('retailer?fields=*.*&limit=-1').readByQuery();
      let sortedRetailerData = this.sortArrayByName(res.data);

      sortedRetailerData.forEach(retailer => {
        let retailerData = {
          text: retailer.name || '',
          value: retailer.id || ''
        };
        this.retailer.push(retailerData);
      });
    },
    formatRetailPrice(retail_price) {
      if (retail_price) { return '(UVP ' + retail_price + ' ' + this.mapCurrencyUnit(this.localCurrency) + ')' }
      return '-';
    },
    getSetOverviewImage(header_image, images) {
      try {
        if (header_image?.id) { return process.env.VUE_APP_API_IMG_PATH + header_image?.id + '?height=200' ?? ''; }
        if (images) {
          if (images[1]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[1]?.directus_files_id.id + '?height=200' ?? ''; }
          if (images[0]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[0]?.directus_files_id.id + '?height=200' ?? ''; }
        }
        return '';
      }
      catch(err) { return ''; }
    },
    resetFilter() {
      this.queryTheme = '';
      this.currentTheme = '';
      this.queryRetailer = '';
      this.currentRetailer = '';
      this.$router.push({ path: '/new-entries' })
    },
    pushQuery() {
      if (this.currentTheme && this.currentRetailer === '') { this.$router.push({ path: '/new-entries', query: { theme: this.currentTheme }}); return; }
      else if (this.currentTheme === '' && this.currentRetailer) { this.$router.push({ path: '/new-entries', query: { retailer: this.currentRetailer }}); return; }
      else { this.$router.push({ path: '/new-entries', query: { theme: this.currentTheme, retailer: this.currentRetailer }}); }
    },
    sortArrayByName(array) {
      return array.sort((a, b) => (a.name > b.name) ? 1 : -1) ?? undefined;
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1200px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 3;
}

.row-search-mobile {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.row-filters {
  display: flex;
  flex-wrap: nowrap;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 1rem;
  justify-content: center;
}

.row_deals {
  position: relative;
  width: 98%;
  margin-bottom: 2rem;
}

.row_deals.desktop {
  justify-content: center;
}

.deal_item {
  position: relative;
  width: 90%;
}

.hint-text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.card_body_loader {
  margin-top: 5rem;
}

.loader {
  width: 50%;
}

.container_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.normal_text {
  text-align: center;
}

.text_mobile {
  font-size: 12px;
}

.text-link.mobile {
  font-size: 12px;
}


</style>
