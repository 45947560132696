<template>
    <v-btn class="button" plain rounded="pill" size="large">{{ buttonText }}</v-btn>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
      buttonText: String
  },
  components: {

  },
  data() {
    return {

    }
  },
}



</script>

<style scoped>

.button {
  padding: 0 1.5rem;
  font-weight: 500;
  color: rgb(96, 255, 228);
  background: linear-gradient(90deg, rgba(96, 255, 228, 0.2) 25%, rgba(96, 255, 228, 0.4) 100%);
  border: 1px solid rgba(96, 255, 228, 0.6);
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  text-transform: capitalize;
}

</style>
