<template>
  <section>
    <v-container>
      <v-row no-gutters class="row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
      </v-row>
    </v-container>
    <v-container fluid class="about-container">
      <v-row no-gutters class="row-header">
        <h1>Das Team</h1>
      </v-row>
      <v-row no-gutters class="row-all-member">
        <v-col class="col-member">
          <div class="member-image-container">
            <v-img cover class="member-image" src="../assets/user_default.jpg"></v-img>
          </div>
          <h2 class="member-name">Michael</h2>
          <span class="member-aka">Promobricks</span>
          <v-row class="member-data-container">
            <a class="link" target="_blank" href="https://www.youtube.com/promobricks"><i class="fab fa-youtube"></i></a>
            <a class="link" target="_blank" href="https://t.me/promobricks_chat"><i class="fab fa-telegram-plane"></i></a>
            <a class="link" target="_blank" href="https://www.instagram.com/promobricks/"><i class="fab fa-instagram"></i></a>
            <p class="member-bio">Michael Friedrichs, Gründer und Betreiber des LEGO News Blogs
              <a class="link-in-text" target="_blank" href="https://promobricks.de/">PROMOBRICKS</a>,
              Familienvater und Apple-Nerd. Mein Faible sind ältere LEGO Sets aus seiner Kindheit.
              Ich sammle Modular-Häuser und Sets aus den Reihen Architecture und Ideas sowie Raritäten wie LEGO Mitarbeiter-Sets.
            </p>
          </v-row>

        </v-col>
        <v-col class="col-member">
          <div class="member-image-container">
            <v-img cover class="member-image" src="../assets/user_default.jpg"></v-img>
          </div>
          <h2 class="member-name">Dennis</h2>
          <span class="member-aka">Brickery</span>
          <v-row class="member-data-container">
            <a class="link" target="_blank" href="https://discord.gg/ydQYy8tv63"><i class="fab fa-discord"></i></a>
            <a class="link" target="_blank" href="https://www.youtube.com/channel/UCUeqSCvi8sNQOyySGue6KaQ"><i class="fab fa-youtube"></i></a>
            <a class="link" target="_blank" href="https://www.instagram.com/the_brickery_/"><i class="fab fa-instagram"></i></a>
            <p class="member-bio">Dennis Gabler, kreativer LEGO Youtuber und Discord-Dompteur. In meinen Kanälen geht es um den LEGO Sammelspaß,
              den Handel mit EOL-Sets und das Thema LEGO Investments. Ich sammle OVP BrickHeadz, Star Wars und Modulars. Sowie Ideas Sets und alles,
              was mit Piraten zu tun hat. Mein absoluter Liebling ist der 75192 Millenium Falke.
            </p>
          </v-row>

        </v-col>
        <v-col class="col-member">
          <div class="member-image-container">
            <v-img cover class="member-image" src="../assets/user_default.jpg"></v-img>
          </div>
          <h2 class="member-name">Chris</h2>
          <span class="member-aka">Augustin Brothers</span>
          <v-row class="member-data-container">
            <a class="link" target="_blank" href="https://www.instagram.com/augustin.brothers/"><i class="fab fa-instagram"></i></a>
            <a class="link" target="_blank" href="https://store.bricklink.com/AugustinBrother"><i class="fa fa-store-alt" style="font-size: 16px;"></i></a>
            <p class="member-bio">Christian Lienert, Gründer der AugustinBrothers - Fachhandel für LEGO Sets, Einzelsteine und Minifiguren.
              Mit AugustinBrothers Customs habe ich mich zudem auf die individuelle Bedruckung von LEGO Steinen und Minifiguren spezialisiert.
              Beim Spielwareninvestor Podcast habe ich eine eigene Reihe zum Thema LEGO Investment mit Schwerpunkt auf Bricklink.
              Ich sammle LEGO Burgen von 1980 bis heute (Team Black Flacon).
            </p>
          </v-row>
        </v-col>
        <v-col class="col-member">
          <div class="member-image-container">
            <v-img cover class="member-image" src="../assets/user_default.jpg"></v-img>
          </div>
          <h2 class="member-name">Thomas</h2>
          <span class="member-aka">Lucky Bricks</span>
          <v-row class="member-data-container">
            <a class="link" target="_blank" href="https://www.youtube.com/channel/UCu6ZsDmCZobUELyyMswkwtQ"><i class="fab fa-youtube"></i></a>
            <a class="link" target="_blank" href="https://lucky-bricks.de/"><i class="fa fa-store-alt" style="font-size: 16px;"></i></a>
            <a class="link" target="_blank" href="https://store.bricklink.com/anguray"><i class="fa fa-store-alt" style="font-size: 16px;"></i></a>
            <p class="member-bio">Thomas Quade, Inhaber von Lucky Bricks dem Fachhandel für neues und gebrauchtes LEGO. Ich versorge im Laden in Rostock,
              über unseren Onlineshop und auf Bricklink legobegeisterte Kunden aus der ganzen Welt.
              Mein Herz schlägt für klassische Sets aus den 80er und 90er Jahren und LEGO NINJAGO, sowie absolute Ikonen wie der 10179 Millenium Falke.
            </p>
          </v-row>
        </v-col>
        <v-col class="col-member">
          <div class="member-image-container">
            <v-img cover class="member-image" src="../assets/user_default.jpg"></v-img>
          </div>
          <h2 class="member-name">Björn</h2>
          <span class="member-aka">Ol-Forestman</span>
          <v-row class="member-data-container">
            <a class="link" target="_blank" href="https://www.instagram.com/ol.forestman/"><i class="fab fa-instagram"></i></a>
            <a class="link" target="_blank" href="https://store.bricklink.com/ol.forestman#/shop"><i class="fa fa-store-alt" style="font-size: 16px;"></i></a>
            <a class="link" target="_blank" href="https://www.geocouch.de/"><i class="fa fa-globe-americas" style="font-size: 16px;"></i></a>
            <p class="member-bio">Björn Jacob, Bricklink Händler, Lego Investor, IT-Spezialist, Bergsteiger und Wildwasserpaddler.
              In den 80er und frühen 90er Jahren steckte ich all mein Taschengeld in meine LEGO Stadt und sammelte fleißig Piratenschiffe.
              Mit dem Release von Castle Löwenstein flammte meine Liebe zu LEGO wieder auf. Seitdem sammle ich vor allem OVP Sets rund um LEGO Architecture und LEGO Modular Buildings.
              Mein Lieblingsset ist die wunderschöne 6285 Black Seas Barracuda.
            </p>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class="row-header">
        <h1>Was ist brickdex?</h1>
      </v-row>
      <v-row>
        <p class="about-us">brickdex.io ist die neue Online-Plattform für LEGO Fans und LEGO Sammler. Wir bieten Dir nicht nur eine der umfangreichsten LEGO
          Produktdatenbanken auf dem deutschsprachigen Markt, sondern stellen mit brickdex.io die Suche nach den besten LEGO Angeboten auf eine neue Qualitätsstufe.
          Dabei ist der Preisvergleich nur der Anfang. Als Nutzer von brickdex.io kannst Du Deine LEGO Sammlung anlegen, verwalten und je nach Wunsch analysieren.
          Zudem kannst Du Dich mit anderen Sammlern und Experten austauschen. Die besten Shop-Deals behälst Du dabei stets im Blick.<br><br>
          Da brickdex.io fortlaufend weiterentwickelt wird, laden wir Dich ein, mit uns gemeinsam auf eine aufregende Reise zu gehen. Mit Deinen Ideen und Wünschen
          kannst Du dieses einzigartige LEGO Fan-Projekt mitgestalten. Nach und nach werden weitere neue und innovative Features für Dich umgesetzt.<br><br>
          Hinter brickdex.io stehen fünf langjährige LEGO Fans und Experten auf ihrem Gebiet: Michael ist Gründer und Betreiber der LEGO News Seite promobricks.de.
          Er kennt fast alle Leaks und ist Profi für Content und Marketing. Dennis ist das Mastermind hinter Brickery. Er kennt die LEGO Sets, bei denen sich ein
          Investment lohnt, und wo man diese zum Bestpreis bekommen kann. Christian ist einer der größten deutschen Bricklink-Händler und Experte, wenn es um Minifiguren,
          einzelne Teile oder ausgefallene Sets geht. Thomas ist der Inhaber von Lucky Bricks, langjähriger LEGO Vertragshändler mit einem Laden sowie einem großem
          Online-Shop, und ein Experte für Logistik. Björn ist Geschäftsführer eines mittelständischen IT-Unternehmens und LEGO Sammler mit einem Herz für Sets aus
          den 80er und frühen 90er Jahren.
        </p>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import Search from '../components/Search.vue';
// import PrimaryButton from '../components/PrimaryButton.vue';

export default {
  name: 'About',
  components: {
    Search,
    // PrimaryButton,
  },
  inject: [
    'api'
  ],
  data() {
    return {

    }
  },
  created() {

  },
  unmounted() {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  height: 100%;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.row-search,
.row-header,
.row-all-member {
  width: 100%;
  justify-content: center;
}

.row-search {
  margin-top: 100px;
}

.row-header {
  margin: 3rem 0;
}

.row-all-member {
  margin-bottom: 5rem;
}

.col-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;
  max-width: 350px;
  margin: 1rem;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 30%, rgba(255, 255, 255, 0.4) 100%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.5s ease all;
  border-radius: 25px;
}

.col-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
}

.member-image-container {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 25px;
  margin-top: 1rem;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.5);
  transition: 0.5s ease all;
}

.member-image {
  cursor: pointer;
  transition: 0.5s ease all;
}

.col-member:hover .member-image-container {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.7);
}

.member-name {
  margin-top: 0.5rem;
  font-size: 28px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.5s ease all;
}

.col-member:hover .member-name {
  text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.4);
}

.member-aka {
  color: rgb(255, 255, 255);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  transform: translateY(-10px);
}

.member-data-container {
  flex: 0;
  justify-content: center;
  align-items: flex-start;
}

.member-bio {
  margin: 1rem 3rem 2rem 3rem;
  color: rgb(255, 255, 255);
}

.about-us {
  width: 80%;
  margin: 0 auto;
  font-size: 18px;
  padding: 1rem 2rem 4rem 2rem;
  color: rgb(255, 255, 255);
}

.link {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin: 0.5rem 0.5rem;
}

.link-in-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
}

  .link-in-text:hover,
  .link:hover {
    color: rgb(96, 255, 228);
  }

/* MEDIA SCREENS */
@media (min-width: 1280px) {
  .member-image-container {
    width: 150px;
    height: 150px;
  }

  .member-name {
    font-size: 36px;
  }

}

</style>
