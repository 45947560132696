<template>
  <section :style="'min-height:' + windowHeight">
    <template v-if="loggedIn.value">
      <v-container class="logged-in-container">
        <v-row>
          <span class="header">Fertig für heute?</span>
        </v-row>
        <v-row>
          <PrimaryButton id="logout-button" @click="logout" buttonText="Abmelden" />
        </v-row>
        <v-row>
          <span class="login-message">{{ message }}</span>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-container class="card-container">
        <div class="card">
          <div class="dialog-body">
            <v-row>
              <span class="header">Willkommen zurück!</span>
            </v-row>
            <v-row>
              <div class="form-container">
                <form @submit.prevent="login">
                  <label class="label-input" for="email">E-Mail *</label>
                  <v-row no-gutters class="input-container">
                    <input id="email" v-model="email" class="input-field" placeholder="jemand@beispiel.de" type="email" required>
                    <i class="fal fa-user input-icon"></i>
                  </v-row>
                  <label class="label-input" for="password">Passwort *</label>
                  <v-row no-gutters class="input-container">
                    <input id="password" v-model="password" class="input-field" placeholder="Passwort" type="password" required>
                    <i class="fal fa-key input-icon"></i>
                  </v-row>
                  <v-row>
                    <router-link class="text-link forgot-password" :to="{ name: 'ResetPassword' }">Passwort vergessen?</router-link>
                  </v-row>
                  <v-row>
                    <PrimaryButton id="login-button" buttonText="Anmelden" type="submit" />
                  </v-row>
                  <v-row>
                    <span class="register-text">Du möchtest einen Account?</span><router-link class="text-link register" :to="{ name: 'Registration' }">Registrieren</router-link>
                  </v-row>
                </form>
              </div>
              <v-row>
                <span class="login-message">{{ message }}</span>
              </v-row>
            </v-row>
          </div>
        </div>
      </v-container>
    </template>
  </section>
</template>

<script>
import PrimaryButton from '../../../components/PrimaryButton.vue';
import router from "../../../plugins/router";

export default {
  name: "RegistrationInit",
  components: {
    PrimaryButton
  },
  inject: [
    'api',
    'loadUser',
    'clearUser',
    'loggedIn',
  ],
  data() {
    return {
      windowHeight: '',
      email: '',
      password: '',
      message: '',
    }
  },
  created() {
    this.windowHeight = window.innerHeight -220 + 'px';
  },
  methods: {
    login() {
      this.api.auth.login({
        email: this.email,
        password: this.password,
      }).then(() => {
        this.message = 'Login erfolgreich.';
        this.password = '';
        this.loadUser();
        setTimeout(() => {
          router.push('/');
        }, 2000);
      }).catch(() => {
        this.message = 'Login fehlgeschlagen. E-Mail oder Passwort falsch!';
      });
    },
    logout() {
      this.api.auth.logout().then(() => {
        this.clearUser();
        this.message = 'Logout erfolgreich';
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }).catch(() => {
        this.message = "Es ist etwas schief gelaufen."
      });
    },
    onResize () {
      this.smallWindow = window.innerWidth < 970;
      this.windowHeight = window.innerHeight - 220 + 'px';
    },
  }
}
</script>

<style scoped>
section {
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

#clouds {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.logged-in-container {
  padding-top: 10rem;
}

.card-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6rem;
}

.card {
  width: 90%;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.7) 45%, rgba(92, 89, 226, 0.5) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 12px 0 rgb(0, 0, 0, 0.3);
  border-radius: 25px;
}

.v-row {
  margin: 5px;
  padding-bottom: 1rem;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 32px;
  font-weight: 800;
  color: rgb(255, 255, 255);
  z-index: 5;
}

.form-container {
  width: 100%;
}

label {
  position: relative;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.input-field {
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.register-text {
  font-size: 14px;
  color: #ffffff;
  margin-left: 1rem;
  transform: translateY(-0.75rem);
}

.text-link.forgot-password {
  font-size: 14px;
  margin-left: 1rem;
}

.text-link.register {
  font-size: 14px;
  margin-left: 0.75rem;
  transform: translateY(-0.75rem);
  z-index: 2;
}

#logout-button {
  width: 100%;
}

#login-button {
  width: 100%;
}

.login-message {
  font-size: 14px;
  color: #d5d9ff;
  transition: 0.5s ease all;
}


</style>
