<template>
  <v-container class="pa-0">
    <v-row ref="searchRow" class="mx-4 search-row" no-gutters>
      <input clearable class="input-search" @input="checkInput" @keyup.enter="pushResultRoute()" v-model="inputValue" type="search" :placeholder="placeholderText">
      <div class="close-icon">
        <i @click="clearInput" v-if="closeIcon" class="fal fa-times"></i>
      </div>
      <PrimaryButton class="button-search" @click="pushResultRoute()" :buttonText="buttonText" :faIconClass="buttonIcon" :style="'background: linear-gradient(180deg,' + buttonColor1 + ' 25%, ' + buttonColor2 +' 130%); color:' + textColor + ';'" />
    </v-row>
    <v-row class="mx-4" no-gutters>
      <transition name="fade">
        <div v-if="suggestionsActive" class="suggestions-container">
          <template v-if="inputValue.length < 3">
            <div class="card" :style="'background: linear-gradient(180deg,' + suggestionBgColor1 + ' 45%, ' + suggestionBgColor2 +' 100%); color:' + suggestionTextColor + ';'">
              <span :style="'color:' + suggestionLabelColor" class="header-text suggestion hint">gib mind. 3 Zeichen ein</span>
            </div>
          </template>
          <template v-else>
            <div class="card" :style="'background: linear-gradient(180deg,' + suggestionBgColor1 + ' 45%, ' + suggestionBgColor2 +' 100%); color:' + suggestionTextColor + ';'">
              <span v-if="sets.length > 0" :style="'color:' + suggestionLabelColor" class="header-text suggestion">Sets</span>
              <div v-for="set in sets" :key="set" class="sets-suggestion-list">
                <div @click="pushSetRoute(set.setnumber)" class="suggestion-item">
                  <div class="image-container">
                    <v-img v-if="set.image" class="image" :src="set?.image ?? ''" :lazy-src="set?.image ?? ''"></v-img>
                  </div>
                  <div class="item-label">
                    <span class="label number">{{ set.setnumber }}</span>
                    <span class="label">{{ set.name }}</span>
                  </div>
                </div>
              </div>
              <template v-if="false">
                <!-- MINIFIGUREN NOCH NICHT FREIGESCHALTET -->
                <span v-if="minifigures.length > 0" :style="'color:' + suggestionLabelColor" class="header-text suggestion">Minifiguren</span>
                <div v-for="minifig in minifigures" :key="minifig" class="minifig-suggestion-list">
                  <div @click="pushMinifigRoute(minifig.number)" class="suggestion-item">
                    <div class="image-container">
                      <v-img v-if="minifig.image" class="image" :src="minifig?.image" :lazy-src="minifig?.image ?? ''"></v-img>
                    </div>
                    <div class="item-label">
                      <span class="label number">{{ minifig.number }}</span>
                      <span class="label">{{ minifig.name }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </transition>
    </v-row>
  </v-container>

</template>

<script>
import PrimaryButton from './PrimaryButton.vue';

export default {
  name: 'Search',
  props: ['placeholderText', 'buttonText', 'buttonIcon', 'buttonColor1', 'buttonColor2', 'textColor', 'suggestionBgColor1', 'suggestionBgColor2', 'suggestionLabelColor', 'suggestionTextColor' ],
  components: {
    PrimaryButton
  },
  inject: [
    'api'
  ],
  data() {
    return {
      closeIcon: false,
      inputValue: "",
      suggestionsActive: false,
      sets: [],
      minifigures: [],
      themes: [],
      mainImage: '',
      suggestionWidth: null,
      debounce: null
    }
  },
  watch: {
    inputValue(value) {
      if (value) {
        this.suggestionsActive = true;
      }
    }
  },
  created() {
    window.addEventListener('resize', this.checkWidth);
  },
  mounted() {
    this.checkWidth();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWidth);
  },
  methods: {
    debounceSearch(searchValue) {
      this.sets = [];
      this.minifigures = [];
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchSets(searchValue);
      }, 500)
    },
    checkInput() {
      if (this.inputValue.length > 0) {
        this.closeIcon = true;
        if (this.inputValue.length > 2) {
          this.debounceSearch(this.inputValue);
          return;
        }
        return;
      }
      this.closeIcon = false;
      this.suggestionsActive = false;
      return;
    },
    clearInput() {
      this.inputValue = '';
      this.closeIcon = false;
      this.suggestionsActive = false;
      this.sets = [];
      this.minifigures = [];
    },
    checkWidth() {
      let width = this.$refs.searchRow.clientWidth;
      this.suggestionWidth = width + 'px';
    },
    pushResultRoute() {
      if (this.inputValue !== '') {
        this.$router.push({ path: '/search/' + this.inputValue });
        this.clearInput();
      }
    },
    pushSetRoute(selectedItem) {
      if (selectedItem !== '') {
        this.$router.push({ path: '/items/sets/' + selectedItem });
        this.clearInput();
      }
    },
    pushMinifigRoute(selectedItem) {
      if (selectedItem !== '') {
        this.$router.push({ path: '/items/minifigures/' + selectedItem });
        this.clearInput();
      }
    },
    async searchSets(searchValue) {
      this.sets = [];
      this.minifigures = [];

      const { raw } = await this.api.transport.get('search?search=' + searchValue.trim() + '&fields=*.*,images.directus_files_id.*');

      raw.sets.forEach(set => {
        let result = {
          setnumber: set?.set ?? '-',
          name: this.mapNameToLocal(set.name, 'de-DE') || '-',
          image: this.getMainImage(set.header_image, set.images)
        };
        this.sets.push(result);
      });

      // raw.minifigures.forEach(minifig => {
      //   let result = {
      //     number: minifig.number,
      //     name: minifig.name,
      //     image: 'https://via.placeholder.com/80x44png?text=Demn%C3%A4chst'
      //   };
      //   this.minifigures.push(result);
      // });
    },
    getMainImage(header_image, images) {
      if (header_image?.id) { return process.env.VUE_APP_API_IMG_PATH + header_image?.id + '?height=100' ?? ''; }
      if (images) {
        if (images[1]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[1]?.directus_files_id.id + '?height=100' ?? ''; }
        if (images[0]?.directus_files_id) { return process.env.VUE_APP_API_IMG_PATH + images[0]?.directus_files_id.id + '?height=100' ?? ''; }
      }
      return '';
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    }
  }
}



</script>

<style scoped>

.search-row {
  flex-wrap: nowrap;
}

.input-search {
  height: 44px;
  width: 100%;
  color: #ffffff;
  padding-left: 1rem;
  padding-right: 3rem;
  background: linear-gradient(90deg, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.5) 100%);
  backdrop-filter: saturate(180%) blur(3px);
  -webkit-backdrop-filter: blur( 12.0px );
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: 14px;
  letter-spacing: .5px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  transition: 0.5s ease all;
  z-index: 1;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

input:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255);
  box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.1);
}

.close-icon {
  position: absolute;
  right: 0;
  width: 30px;
  margin-top: 10px;
  transform: translateX(-100px);
  z-index: 3;
}

.close-icon i {
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
}

.button-search {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}

.suggestions-container {
  position: absolute;
  left: 1rem;
  right: 1rem;
  margin-top: 0.5rem;
  z-index: 80;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border-radius: 25px;
  transition: 0.5s ease all;
  z-index: 80;
}

.suggestion-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.suggestion-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.header-text {
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
  padding-left: 1.5rem;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

.header-text.suggestion {
  color: #60ffe4;
  margin: 0.5rem 0;
}

.header-text.suggestion.hint {
  margin: 1rem 0;
}

.sets-suggestion-list:last-of-type,
.minifig-suggestion-list:last-of-type {
  padding-bottom: 1rem;
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #ffffff;
  min-width: 44px;
  height: 44px;
  margin: 0.3rem 0 0.3rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,0.25);
}

.image {
  height: 100%;
  transition: 0.5s ease transform;
}

.item-label{
  display: flex;
  flex-direction: column;
}

.label {
  color: #ffffff;
  font-size: 12px;
  padding-left: 1rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: 0.5s ease all;
}

.number {
  font-weight: 800;
}

::-webkit-scrollbar-track {
  margin: 1.5rem;
}


@media (min-width: 600px) {
  .input-search {
    font-size: 18px;
  }

}

@media (min-width: 1280px) {
  .label {
    font-size: 14px;
  }
}

</style>
