<template>
  <div class="dialog-overlay">
    <div class="dialog-card">
      <div class="dialog-header">
        <span class="header-text header_mobile">Set hinzufügen</span>
        <v-spacer></v-spacer>
        <i class="fal fa-times icon close" @click="close()"></i>
      </div>
      <v-row no-gutters>
        <span class="subheader-text">Füge das Set {{ set_data?.set ?? '' }} zu Deiner eigenen Collection hinzu.</span>
      </v-row>
      <template v-if="loggedIn.value">
        <form @submit.prevent="addToCollection()">
          <div class="pb-2 px-6 dialog-body">
            <v-text-field v-model.number="amount" clearable class="input_field" color="#60ffe4" label="Anzahl" append-inner-icon="mdi-counter" type="number" min="1" pattern="[0-9]" step="1" placeholder="1" density="comfortable" required></v-text-field>
            <v-combobox v-model="condition" :items="conditions" clearable hide-details class="mb-4 input_field" color="#60ffe4" label="Zustand" append-inner-icon="mdi-gift-open" placeholder="muss auswählt werden" theme="dark" required></v-combobox>
            <v-text-field v-model="purchase_date" clearable class="input_field" color="#60ffe4" label="Kaufdatum" type="date" density="comfortable"></v-text-field>
            <v-text-field v-model="retailer" clearable class="input_field" color="#60ffe4" append-inner-icon="mdi-storefront-outline" label="Händler" placeholder="Amazon" density="comfortable"></v-text-field>
            <v-text-field v-model="price_payed" clearable class="input_field" color="#60ffe4" label="Kaufpreis" :append-inner-icon="'mdi-currency-' + this.localCurrency" type="number" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" placeholder="0,00 €" density="comfortable"></v-text-field>
            <v-text-field v-model="storage" clearable class="input_field" color="#60ffe4" append-inner-icon="mdi-library-shelves" label="Lagerplatz" placeholder="Regal 1 Fach 2" hint="Wo dein Set gelagert ist" density="comfortable"></v-text-field>
            <div class="dialog-action">
              <PrimaryButton @click="close()" class="cancel-button" buttonText="Abbrechen" />
              <PrimaryButton faIconClass="far fa-plus" type="submit" />
            </div>
          </div>
        </form>
      </template>
      <template v-else>
        <v-container>
          <v-row no-gutters>
            <div class="hint-card">
              <i class="fal fa-info icon-hint"></i>
            </div>
          </v-row>
          <v-row no-gutters>
            <span class="hint-text">
              Um Deine Collection anlegen und verwalten zu können, musst Du
              <router-link class="text-link" to="/registration">registriert</router-link>
              sein.
            </span>
            <span class="hint-text">
              Hast Du bereits einen Account, dann
              <router-link class="text-link" to="/login">melde</router-link>
              Dich einfach an.
            </span>
          </v-row>
        </v-container>
      </template>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../PrimaryButton.vue';
import moment from 'moment/min/moment-with-locales';

export default {
  name: 'AddSetDialogMobile',
  components: {
    PrimaryButton,
  },
  props: ['set_data'],
  inject: [
    'api',
    'loggedIn',
    'localCurrency'
  ],
  data() {
    return {
      amount: null,
      condition: null,
      purchase_date: null,
      retailer: null,
      price_payed: null,
      discount: null,
      storage: null
    }
  },
  created() {
    this.conditions = [
      { title: "Neu, versiegelt" , value: "new_sealed" },
      { title: "Neu, leichte Schäden" , value: "new_minor_damages" },
      { title: "Neu, starke Schäden" , value: "new_major_damages" },
      { title: "Gebraucht, Tüten verschlossen" , value: "used_bags_closed" },
      { title: "Gebraucht, gebaut, nicht bespielt" , value: "used_build_not_played" },
      { title: "Gebraucht, bespielt" , value: "used_played" },
      { title: "Gebraucht, Karton fehlt" , value: "used_box_missing" },
      { title: "Gebraucht, nicht komplett" , value: "used_incomplete" }
    ];
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async addToCollection() {
      let newCollection = [];
      const convertedDate = this.convertGermanDateString(this.purchase_date);
      if (this.amount > 1) {
        for (let i = 0; i < this.amount; i++ ) {
          let newItem = {
            condition: this.conditions.find(el => el.value === this.condition?.value)?.value ?? null,
            price_payed: this.price_payed?.toString()?.replace(',', '.') ?? '',
            purchase_date: convertedDate,
            retailer: this.retailer,
            discount: this.calcDiscount(),
            storage: this.storage,
            set: this.set_data.set
          }
          newCollection.push(newItem);
        }
        await this.api.items('collections').createMany(newCollection);
      }
      else {
        await this.api.items('collections').createOne({
          condition: this.conditions.find(el => el.value === this.condition?.value)?.value ?? null,
          price_payed: this.price_payed?.toString()?.replace(',', '.') ?? '',
          purchase_date: convertedDate,
          retailer: this.retailer,
          discount: this.calcDiscount(),
          storage: this.storage,
          set: this.set_data.set
        });
      }
      this.close();
      window.location.reload();
    },
    convertGermanDateString(dateString) {
      if (dateString) {
        let day = dateString.split('-')[2];
        let month = dateString.split('-')[1];
        let year = dateString.split('-')[0];

        if (day.length == 2 && month.length == 2 && year.length == 4) {
          let m = moment(day + '.' + month + '.' + year + '12:00', 'DD MM YYYY HH:mm Z', 'de');
          return m.toISOString();
        }
        else return null;
      }
      else return null;
    },
    calcDiscount() {
      if (this.set_data.retail_price && this.set_data.retail_price !== '-' && this.set_data.retail_price !== '0.00' && this.price_payed) {
        try {
          return parseFloat(((1-(this.price_payed / this.set_data.retail_price))*100).toFixed(2));
        }
        catch { return null }
      }
      else return null;
    }
  }
}
</script>

<style scoped>

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dialog-card {
  position: relative;
  width: 92%;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.95) 45%, rgba(92, 89, 226, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.dialog-header-text {
  margin-left: 1.5rem;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.subheader-text {
  margin-left: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 12px;
  color: #ffffff;
}

.dialog-body {
  display: flex;
  flex-direction: column;
}

.hint-card {
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-content: center;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.3) 100%);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.icon-hint {
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;
  font-size: 32px;
  color: rgb(255, 255, 255);
}

.hint-text {
  width: 80%;
  font-size: 14px;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.text-link {
  font-size: 14px;
}

.dialog-action {
  margin: 0 auto;
  padding: 1rem 0;
}

.cancel-button {
  margin-right: 0.5rem;
  color: #5C5DD8;
  background: linear-gradient(180deg, rgba(252, 179, 236, 1) 25%, rgb(255, 136, 229) 130%);
}

.input_field {
  margin-bottom: -8px;
}

.header_mobile {
  font-size: 14px;
}

</style>
