<template>

  <div class="background-image-container">
    <!-- <v-img class="background-image" :src="require(`../assets/LEGO_${routeSetnumber}.jpg`)" alt=""></v-img> -->
    <v-img class="background-image" src="../assets/galeria.png" alt=""></v-img>
  </div>

  <section>
    <v-container>

      <v-row no-gutters class="row-search">
        <Search class="search" placeholderText="Looking for a specific set?" />
      </v-row>

      <v-row no-gutters class="row-set-header">
        <div class="image-container">
          <v-img class="image" src="../assets/galeria.png" alt=""></v-img>
        </div>

        <v-col>
          <v-container class="pa-0 col-set-main-data">
            <ul class="breadcrumb">
              <li><router-link class="link" :to="{ name: '' }">Retailer</router-link></li>
            </ul>
            <!-- <h1 class="setname">{{name}}</h1> -->
           <h1 class="setname">{{ routeRetailorName }}</h1>
            <div class="call-to-actions">
              <div class="cta-container">
                <i class="fa fa-star set-rating-star"></i>
                <div class="rating-container">
                  <p class="rating-header">Overall</p>
                  <p class="rating-value">92/100</p>
                </div>
              </div>
            </div>
          </v-container>


        </v-col>
      </v-row>

    </v-container>

    <v-container v-show="!mobile">
      <v-row no-gutters class="row-price-comparision">
        <v-col cols="8">
          <div class="card card-price">
            <div class="card-header">
              <p class="header-text">Info A</p>
              <v-spacer></v-spacer>
            </div>
            <div class="card-body">

            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="card card-set-info">
            <div class="card-header">
              <p class="header-text">Info B</p>
            </div>
            <div class="card-body">

              <div class="info-list">
                <table>
                  <tr>
                    <td class="info-list-header">Sets in Stock</td>
                    <td class="info-list-data">{{ setnumber }}</td>
                  </tr>
                  <tr>
                    <td class="info-list-header">Example</td>
                    <td class="info-list-data">{{ name }}</td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
        </v-col>
      </v-row>

    </v-container>

    <!-- MOBILE -->
    <v-container v-show="mobile">

    </v-container>
  </section>
</template>

<script>
import Search from '../components/Search.vue';
// import SecondaryButton from '../components/SecondaryButton.vue';
import { useRoute } from 'vue-router';
// import { Directus } from '@directus/sdk';

// const api = new Directus(process.env.VUE_APP_API_DOMAIN);

export default {
  name: 'SetDetails',
  components: {
    Search,
    // SecondaryButton,
  },
  props: {

  },
  data() {
    return {
      dialog: false,

      mobile: null,
      routeRetailorName: "",
      hasSubtheme: false,
    }
  },
  created() {
    const route = useRoute();
    this.routeRetailorName = route.params.name;

    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
  },
  computed: {

  },
  methods: {
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 970) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
}

section {
  position: relative;
  height: 1080px;
  /* height: 100%;  */
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.dialog-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.37);
  z-index: 999;
}

.dialog-card {
  position: relative;
  width: 500px;
  background: linear-gradient(135deg, rgba(120,152,239,0.7) 25%, rgba(90,84,225,0.9) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
}

.dialog-header {
  display: flex;
  align-items: center;
}

.close-dialog {
  margin: 1rem;
  font-size: 24px;
  color: rgb(223, 223, 223);
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.3s ease all;
}

  .close-dialog:hover {
    color: #ffffff;
  }

.dialog-header-text {
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 22px;
}


.row-search {
  position: relative;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
  z-index: 2;
}

.background-image-container {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 300px;
  overflow: hidden;
  z-index: 1;
}

.background-image {
  margin-top: 60px;
  opacity: 0.15;
  transform: scale(2);
  transition: 0.5s ease transform;
}

.row-set-header {
  position: relative;
  width: 100%;
  z-index: 3;
}

.image-container {
  position: relative;
  width: 160px;
  height: 160px;
  margin-left: 2rem;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  -moz-box-shadow: 0 5px 12px 0 rgba(0,0,0,0.5);
  transition: 0.5s ease transform;
}

.image {
  height: 100%;
  transform: scale(.95);
  transition: 0.5s ease transform;
}

ul.breadcrumb {
  margin-left: 2.5rem;
  list-style: none;
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

ul.breadcrumb li+li:before {
  padding: 8px;
  color: #ffffff;
  content: ">\00a0";
}

ul.breadcrumb li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: .5px;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #60ffe4
}

.setname {
  margin-left: 2.5rem;
  color: #ffffff;
  font-size: 40px;
  font-weight: 800;
  line-height: 1;
}

.call-to-actions {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
}

.cta-container {
  display: flex;
  margin-right: 2rem;
}

.rating-container {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.rating-header {
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
}

.rating-value {
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 22px;
}

.set-rating-star {
  font-size: 2rem;
  background-image:linear-gradient(135deg, rgb(255, 255, 210), rgb(255, 196, 70));
  background-clip: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-watchlist {
  margin-left: 1.5rem
}

.card {
  position: relative;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
  transition: 0.5s ease transform;
}

.card-price,
.card-set-info {
  height: auto;
  padding-bottom: 15px;
}

.card-price {
  margin: 2rem;
}

.card-set-info {
  margin: 2rem 0;
}

.card-header {
  display: flex;
  height: 44px;
  align-items: center;
}

.header-text {
  margin-left: 1.5rem;
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.label {
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
}

.info-list {
  margin-left: 1.5rem;
}

.info-list-header {
  min-width: 160px;
  font-weight: 700;
  color: #312659;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
}

.info-list-data {
  color: #ffffff;
}

.retailor-list-item {
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.retailor-image-container {
  display: flex;
  height: 3rem;
  overflow: hidden;
}

.retailor-price {
  color: #60ffe4;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 1px 1px 0px #0e545a;
}

.retailor-price-shipping,
.payments,
.shipping-return,
.shipping {
  color: #ffffff;
  font-size: 10px;
  font-weight: 300;
}

.retailor-rating-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rating-star {
  color: #ffffff;
  font-size: 24px;
  margin-left: 0.5rem;
}

@media (min-width: 1100px) {
  .card-set-info {
    min-width: 350px;
  }

}

@media (min-width: 1280px) {
  .retailor-price {
    font-size: 22px;
  }
}

@media (min-width: 1920px) {
  .retailor-price {
    font-size: 26px;
  }
}

</style>
