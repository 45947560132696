<template>
  <svg id="clouds" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2616.32 604.12">
    <path d="M1613.34,19.72a17,17,0,0,1,4.43.59,19.72,19.72,0,0,1,21.65-11.76,19.74,19.74,0,0,1,34.46,3.55,19.72,19.72,0,0,1,25.67,18.81H1597.47A16.86,16.86,0,0,1,1613.34,19.72Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M402,377.67a114.55,114.55,0,0,0-20.66,1.87c-13.93-22.35-46.65-38-84.81-38a136.72,136.72,0,0,0-16.18,1c-16.56-16.33-44.35-27-75.78-27-38.2,0-70.95,15.72-84.86,38.11A133.69,133.69,0,0,0,92,350.78c-50.81,0-92,27.81-92,62.13H476C465.17,392.37,436.15,377.67,402,377.67Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M2565.32,236.91a54.35,54.35,0,0,0-14.24,1.9A63.36,63.36,0,0,0,2481.45,201a63.43,63.43,0,0,0-110.77,11.4,63.48,63.48,0,0,0-82.54,60.52h328.18A54.16,54.16,0,0,0,2565.32,236.91Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M1920.89,604.12c0-59.12,97.85-88,154.44-79.59C2094,463,2192.58,431,2248.53,462.8c19.44,11,34.62,26.46,43.74,44.43,64.81-16.86,133.88,12.89,154.28,66.43a85.53,85.53,0,0,1,5.68,30.46" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M647.29,175.84a122.46,122.46,0,0,0-19-5.88,60.5,60.5,0,0,0-30.09.86,73.83,73.83,0,0,0-27.65,14.87c-13,11.22-23.55,27.22-29.33,46.76a46.67,46.67,0,0,0-17-3.27c-28.14,0-52.08,25.76-61,61.73H738C733.56,274.68,711.81,201.69,647.29,175.84Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
  </svg>

  <section v-resize="onResize" :style="'height:' + windowHeight">
    <div class="card-container">
      <div class="card">
        <div class="dialog-body">
          <v-row>
            <span class="header">Account erstellen</span>
          </v-row>
          <v-row>
            <v-col v-show="!smallWindow" v-resize="onResize" class="image-column">
              <div class="image-container">
                <svg id="sign-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468.09 379.49">
                  <g style="isolation: isolate">
                    <g>
                      <g style="opacity: 0.30000000000000004">
                        <polygon points="384.56 341.19 360.03 348.5 398.65 356.84 384.56 341.19" style="mix-blend-mode: multiply"/>
                        <polygon points="299.31 316.83 216.4 341.56 305.13 360.66 299.31 316.83" style="mix-blend-mode: multiply"/>
                        <polygon points="128.91 289.65 5.75 277.97 0 279.14 135.36 301.17 128.91 289.65" style="mix-blend-mode: multiply"/>
                        <polygon points="230.42 289.49 119.39 322.6 109.21 325.64 190.46 343.45 291.69 290.09 230.42 289.49" style="mix-blend-mode: multiply"/>
                        <polygon points="87.97 320.38 51.67 331.21 125.47 347.13 87.97 320.38" style="mix-blend-mode: multiply"/>
                        <polygon points="82.02 289.65 63.59 289.47 43.17 295.56 96.25 307.29 82.02 289.65" style="mix-blend-mode: multiply"/>
                        <polygon points="440.34 324.28 404.04 335.11 448.92 344.79 440.34 324.28" style="mix-blend-mode: multiply"/>
                        <polygon points="358.41 293.65 319.73 305.19 411.87 325.41 358.41 293.65" style="mix-blend-mode: multiply"/>
                      </g>
                      <path d="M240.35,213.61a9.31,9.31,0,0,1,2.43.33,10.82,10.82,0,0,1,10-6.63,10.94,10.94,0,0,1,1.91.17,10.83,10.83,0,0,1,18.91,1.94,11,11,0,0,1,3.27-.5,10.83,10.83,0,0,1,10.83,10.83h-56A9.24,9.24,0,0,1,240.35,213.61Z" transform="translate(-42.09 -202.78)" style="fill: #fff"/>
                      <path d="M411.68,266.65a11.61,11.61,0,0,0-3.14.42,14,14,0,0,0-12.9-8.57,14.27,14.27,0,0,0-2.47.22,14,14,0,0,0-24.44,2.52,14,14,0,0,0-18.21,13.34h72.41A12,12,0,0,0,411.68,266.65Z" transform="translate(-42.09 -202.78)" style="fill: #fff"/>
                      <g>
                        <g>
                          <g>
                            <g>
                              <polygon points="440.45 292.42 440.41 316.97 384.76 300.05 384.8 275.49 440.45 292.42" style="fill: #256eb8"/>
                              <polygon points="468.09 284.01 440.45 292.42 384.8 275.49 412.44 267.08 468.09 284.01" style="fill: #a2d4ff"/>
                              <path d="M447.36,478.9l.09-.12.05-.07.06-.13a.14.14,0,0,1,0-.06.69.69,0,0,0,0-.2v-3.05a.68.68,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12-.12.08-.21.12-.14.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.63.16s-.31.09-.48.12l-.65.11-.54.09-.7.07-.56.05-.75,0-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.68,1.11,2.05,1.53q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07H440c.25,0,.5,0,.76,0l.55,0,.75,0,.56-.05.7-.06.54-.09.65-.11L445,480l.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06a2.24,2.24,0,0,0,.27-.13l.14-.07.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M445.56,473.73c2.7.83,2.74,2.2.08,3a19.13,19.13,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,445.56,473.73Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M461.26,483.13l.09-.12,0-.07.06-.13a.14.14,0,0,0,0-.06.69.69,0,0,0,0-.2V479.5a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13a.35.35,0,0,0,0,.08l-.09.11a.6.6,0,0,1-.08.08l-.11.1-.16.12a.5.5,0,0,1-.12.08l-.2.12-.15.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56.05-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.81-.13-.38-.07a8.52,8.52,0,0,1-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3.06c0,.55.69,1.11,2.05,1.53q.51.15,1.08.27c.12,0,.25,0,.38.06s.53.1.81.13l.5.05.77.07.55,0,.76,0,.56,0,.74,0,.57-.06c.23,0,.47,0,.69-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.14-.07.2-.12.12-.08.16-.12.05,0,.06-.06A.6.6,0,0,0,461.26,483.13Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M459.46,478c2.7.83,2.74,2.2.08,3.05a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,459.46,478Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M461.17,474.68l.09-.11a.35.35,0,0,0,.05-.08.31.31,0,0,0,.06-.13l0-.06a.69.69,0,0,0,0-.2v-3.05a.63.63,0,0,1,0,.19l0,.07a.34.34,0,0,1-.06.12.35.35,0,0,1-.05.08.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.11-.14.08-.28.12a.59.59,0,0,1-.14.06l-.48.18-.63.16c-.16,0-.31.09-.49.12a6.09,6.09,0,0,1-.64.11l-.54.09-.7.07-.56.05c-.25,0-.5,0-.75,0l-.56,0-.75,0c-.19,0-.37,0-.55,0s-.52,0-.77-.06l-.5-.05-.82-.13-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54v3.06c0,.55.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.25,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0c.19,0,.37,0,.56-.06s.47,0,.7-.06l.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0,.49-.17.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M459.38,469.51c2.7.84,2.73,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3A19,19,0,0,1,459.38,469.51Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M475.08,478.91l.09-.11a.35.35,0,0,1,.05-.08l.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.27.13-.14.06-.49.17c-.19.06-.41.11-.63.16l-.48.13-.65.1c-.18,0-.35.07-.54.09l-.7.07-.56.05c-.25,0-.5,0-.75,0h-.56l-.75,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2-1-2-1.54v3.06c0,.56.68,1.11,2,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.56-.05.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12a.1.1,0,0,0,.05,0L475,479Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M473.28,473.74c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19.13,19.13,0,0,1,473.28,473.74Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M475.18,487.37l.09-.11a.35.35,0,0,0,.05-.08l.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19.19.19,0,0,1,0,.07l-.06.13-.05.07-.09.12-.08.07a.52.52,0,0,1-.11.1l-.15.12-.13.09-.2.12-.14.07-.28.13-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.69.07-.57.05-.74,0h-.56l-.76,0h-.55l-.76-.07-.51-.05c-.28,0-.54-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.06,1.53a9.78,9.78,0,0,0,1.07.28l.38.06.82.13.49,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.33-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.28-.12.14-.08.2-.12a.57.57,0,0,0,.13-.08l.15-.12a.1.1,0,0,0,.05,0l.07-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M473.38,482.21c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3A19,19,0,0,1,473.38,482.21Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <polygon points="468.09 284.01 468.05 308.56 440.41 316.97 440.45 292.42 468.09 284.01" style="fill: #88c0f9"/>
                              <path d="M489.08,491.6a.48.48,0,0,0,.09-.11.35.35,0,0,0,.05-.08.52.52,0,0,0,.06-.13l0-.06a1.25,1.25,0,0,0,0-.19V488a1.11,1.11,0,0,1,0,.19l0,.07a1.39,1.39,0,0,1-.06.13l-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.11-.16.12-.12.08-.21.12-.14.07-.28.13-.14.06c-.15.06-.31.12-.48.17l-.63.16-.49.13-.65.11-.53.08-.7.07-.57.05c-.24,0-.49,0-.74,0h-.56l-.75,0h-.56l-.76-.07-.5,0c-.29,0-.55-.08-.82-.13l-.38-.06c-.38-.08-.74-.17-1.07-.27-1.37-.42-2.06-1-2.06-1.54V491c0,.56.69,1.11,2.06,1.53a9.41,9.41,0,0,0,1.08.28l.37.06.82.13.5,0c.25,0,.5.06.76.07l.56,0,.75,0,.56,0,.74,0,.57-.05.7-.07.54-.09c.21,0,.44-.06.64-.11s.33-.08.49-.12.37-.08.54-.14l.09,0,.48-.18.14-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M487.29,486.44c2.7.83,2.73,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.75-2.2-.08-3.05A19,19,0,0,1,487.29,486.44Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M489,483.16l.09-.12.05-.07.06-.13s0,0,0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09-.7.06-.56.05-.75,0-.55,0-.76,0-.55,0-.77-.07-.5-.05c-.28,0-.55-.08-.81-.13s-.26,0-.38-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.05c0,.56.69,1.12,2.05,1.54q.51.15,1.08.27l.38.06c.26.05.53.1.81.13l.5.05.77.07h.55l.76,0H483l.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.08,0,.49-.17.14-.06.28-.13.13-.07.21-.12.12-.09.16-.12.05,0s0-.05.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M487.2,478c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A18.91,18.91,0,0,1,487.2,478Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M502.9,487.39l.09-.12.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.07.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13-.05.07-.09.12-.07.08-.12.1-.15.12-.13.08-.2.12-.14.08-.28.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17l-.49.12-.65.11-.54.09c-.22,0-.46,0-.69.06l-.57.06-.74,0-.56,0-.76,0-.55,0-.76-.07-.5,0-.82-.14c-.13,0-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3.05c0,.56.69,1.12,2.06,1.54.33.1.69.19,1.07.27l.38.06c.27.05.53.1.82.13l.5.05.76.07h.55l.76,0h.56c.25,0,.5,0,.74,0l.57-.05.69-.07.54-.08.65-.11.49-.13.54-.13.09,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.08.15-.12.05,0a.46.46,0,0,0,.07-.07Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M501.1,482.22c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,501.1,482.22Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <polygon points="440.45 267.24 440.41 291.8 384.76 274.88 384.8 250.32 440.45 267.24" style="fill: #256eb8"/>
                              <polygon points="468.09 258.84 440.45 267.24 384.8 250.32 412.44 241.91 468.09 258.84" style="fill: #a2d4ff"/>
                              <path d="M447.36,453.73l.09-.12.05-.07.06-.13a.14.14,0,0,1,0-.06.69.69,0,0,0,0-.2v-3.06a.69.69,0,0,1,0,.2l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.12-.08.08-.11.1L447,451l-.12.08-.21.12-.14.08-.27.12-.14.06-.49.17c-.19.07-.41.11-.63.17s-.31.09-.48.12l-.65.11-.54.09c-.23,0-.46,0-.7.06s-.37.05-.56.06l-.75,0-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3c0,.56.68,1.12,2.05,1.54q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07H440c.25,0,.5,0,.76,0h.55l.75,0,.56-.05.7-.07.54-.08.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06a2.24,2.24,0,0,0,.27-.13l.14-.07.21-.12L447,454l.16-.12.05,0,.06-.06A.6.6,0,0,1,447.36,453.73Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M445.56,448.56c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,445.56,448.56Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M461.26,458l.09-.12,0-.07.06-.13a.14.14,0,0,0,0-.06.69.69,0,0,0,0-.2v-3.05a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.13a.35.35,0,0,0,0,.08l-.09.11a.6.6,0,0,1-.08.08l-.11.1-.16.12a.5.5,0,0,1-.12.08l-.2.12-.15.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12l-.65.11c-.18,0-.35.07-.54.09l-.7.07-.56.05-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.81-.13-.38-.07a8.52,8.52,0,0,1-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3.06c0,.55.69,1.11,2.05,1.53q.51.15,1.08.27c.12,0,.25,0,.38.06s.53.1.81.13l.5.05.77.07.55,0,.76,0,.56,0,.74,0,.57-.05.69-.06.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.14-.07.2-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M459.46,452.79c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,459.46,452.79Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M461.17,449.51a.47.47,0,0,0,.09-.12.18.18,0,0,0,.05-.07.38.38,0,0,0,.06-.13l0-.06a.69.69,0,0,0,0-.2v-3.05a.63.63,0,0,1,0,.19l0,.07a.34.34,0,0,1-.06.12.35.35,0,0,1-.05.08.31.31,0,0,1-.09.11.27.27,0,0,1-.07.08.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.11-.14.08-.28.12-.14.06-.48.18-.63.16c-.16,0-.31.09-.49.12a6.09,6.09,0,0,1-.64.11c-.18,0-.36.07-.54.09l-.7.07-.56.05c-.25,0-.5,0-.75,0l-.56,0-.75,0-.55,0c-.26,0-.52,0-.77-.06l-.5-.05-.82-.13-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54v3.06c0,.55.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.25,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56-.06c.24,0,.47,0,.7-.06l.54-.09.65-.11.49-.12.54-.14.08,0,.49-.17.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M459.38,444.34c2.7.84,2.73,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3.05A19,19,0,0,1,459.38,444.34Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M475.08,453.74l.09-.11a.35.35,0,0,1,.05-.08l.06-.13s0,0,0-.06a.68.68,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.27.13-.14.06-.49.17c-.19.06-.41.11-.63.16s-.31.09-.48.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56.05-.75,0h-.56l-.75,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2-1-2-1.54v3.06c0,.56.68,1.11,2,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.56,0,.7-.07.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M473.28,448.57c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3A19,19,0,0,1,473.28,448.57Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M475.18,462.2l.09-.11a.35.35,0,0,0,.05-.08l.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19.19.19,0,0,1,0,.07l-.06.13-.05.07-.09.12-.08.07a.52.52,0,0,1-.11.1l-.15.12-.13.09-.2.12-.14.07-.28.13-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.1c-.18,0-.35.07-.54.09l-.69.07-.57.05-.74,0h-.56l-.76,0h-.55l-.76-.07-.51-.05c-.28,0-.54-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.06,1.53a8,8,0,0,0,1.07.27l.38.07.82.13.49,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.33-.08.49-.12l.54-.14.08,0L474,463l.14-.06.28-.12.14-.08.2-.12a.57.57,0,0,0,.13-.08l.15-.12a.1.1,0,0,0,.05,0l.07-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M473.38,457c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,473.38,457Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <polygon points="468.09 258.84 468.05 283.39 440.41 291.8 440.45 267.24 468.09 258.84" style="fill: #88c0f9"/>
                              <path d="M489.08,466.43a.48.48,0,0,0,.09-.11.35.35,0,0,0,.05-.08.52.52,0,0,0,.06-.13l0-.06a1.25,1.25,0,0,0,0-.19V462.8a1.23,1.23,0,0,1,0,.19l0,.07a1.39,1.39,0,0,1-.06.13l-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.11-.16.12-.12.08-.21.12-.14.07-.28.13-.14.06-.48.17-.63.16-.49.13-.65.11-.53.08-.7.07-.57.05c-.24,0-.49,0-.74,0h-.56l-.75,0h-.56l-.76-.07-.5-.05c-.29,0-.55-.08-.82-.13l-.38-.06c-.38-.08-.74-.17-1.07-.27-1.37-.42-2.06-1-2.06-1.54v3.06c0,.56.69,1.11,2.06,1.53a9.41,9.41,0,0,0,1.08.28c.12,0,.25,0,.37.06l.82.13.5,0c.25,0,.5.06.76.07l.56,0,.75,0,.56,0,.74,0,.57-.05.7-.07.54-.09c.21,0,.44-.06.64-.11s.33-.08.49-.12.37-.08.54-.14l.09,0,.48-.18.14-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M487.29,461.27c2.7.83,2.73,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.75-2.2-.08-3A19,19,0,0,1,487.29,461.27Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M489,458l.09-.12a.18.18,0,0,1,.05-.07l.06-.13s0-.05,0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09-.7.06-.56,0c-.25,0-.5,0-.75,0l-.55,0-.76,0-.55,0-.77-.07-.5,0c-.28,0-.55-.08-.81-.13s-.26,0-.38-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.05c0,.56.69,1.12,2.05,1.54q.51.15,1.08.27l.38.06c.26.05.53.1.81.13l.5,0,.77.07h.55l.76,0H483l.74,0,.57,0,.69-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.08,0,.49-.17.14-.06.28-.13.13-.07.21-.12.12-.09.16-.12.05,0s0-.05.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M487.2,452.82c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3A18.91,18.91,0,0,1,487.2,452.82Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M502.9,462.22l.09-.12L503,462a1.39,1.39,0,0,0,.06-.13.19.19,0,0,0,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06L503,459,503,459l-.09.12-.07.08-.12.1-.15.12-.13.08-.2.12-.14.08-.28.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17l-.49.12-.65.11-.54.09c-.22,0-.46,0-.69.06l-.57.06-.74,0-.56,0-.76,0-.55,0-.76-.07c-.17,0-.34,0-.5-.05s-.55-.08-.82-.13-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3.05c0,.56.69,1.12,2.06,1.54.33.1.69.19,1.07.27l.38.06c.27.05.53.1.82.13l.5.05.76.07h.55l.76,0h.56c.25,0,.5,0,.74,0l.57-.05.69-.07.54-.08.65-.11.49-.13.54-.13.09,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.09a.57.57,0,0,0,.15-.12l.05,0,.07-.07Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M501.1,457.05c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,501.1,457.05Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon points="96.39 283.41 96.36 307.29 69.31 299.07 69.34 275.19 96.39 283.41" style="fill: #256eb8"/>
                            <polygon points="123.27 275.23 96.39 283.41 69.34 275.19 96.22 267.02 123.27 275.23" style="fill: #a2d4ff"/>
                            <path d="M131.33,478.58l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05c-.24,0-.48,0-.73,0h-.54c-.24,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.5v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11.48-.12.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,0,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                            <path d="M129.58,473.56c2.63.81,2.67,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.08-3A18.42,18.42,0,0,1,129.58,473.56Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                          </g>
                          <g>
                            <polygon points="123.27 275.23 123.24 299.11 96.36 307.29 96.39 283.41 123.27 275.23" style="fill: #88c0f9"/>
                            <path d="M144.85,482.7l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12l0,.07-.08.12-.08.07-.1.1-.16.12-.12.08-.2.11-.13.08-.27.12-.14,0c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.05c.26.05.52.1.8.13l.48.05.75.06c.18,0,.36,0,.54,0l.73,0,.54,0c.24,0,.49,0,.73,0l.55-.05.67-.07.53-.08a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                            <path d="M143.1,477.67c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,143.1,477.67Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M144.77,474.48a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.11.1-.15.11a.5.5,0,0,1-.12.08l-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.55.05-.72,0h-.54c-.25,0-.49,0-.74,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.16-1.05-.26-1.33-.41-2-.95-2-1.5v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.53-.08.63-.11.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.14-.06L144,475l.13-.07.2-.12a.5.5,0,0,0,.12-.08.91.91,0,0,0,.16-.11s0,0,0,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                            <path d="M143,469.46c2.62.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,143,469.46Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                          </g>
                          <g>
                            <path d="M158.28,478.6a.94.94,0,0,0,.09-.12.18.18,0,0,0,0-.07l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.07l-.06.12,0,.07a.94.94,0,0,1-.09.12.46.46,0,0,1-.07.07l-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.37.05c.26,0,.52.1.79.13l.49,0c.25,0,.49.05.74.06s.36,0,.54,0l.73,0,.55,0c.24,0,.48,0,.72,0l.55,0,.68-.07.52-.08c.21,0,.43-.06.63-.11s.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                            <path d="M156.54,473.57c2.62.81,2.66,2.14.07,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.07-3A18.53,18.53,0,0,1,156.54,473.57Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <polygon points="412.01 300.95 411.98 325.51 356.33 308.58 356.37 284.02 412.01 300.95" style="fill: #256eb8"/>
                              <polygon points="439.66 292.54 412.01 300.95 356.37 284.02 384.01 275.62 439.66 292.54" style="fill: #a2d4ff"/>
                              <path d="M418.92,487.43a.31.31,0,0,0,.09-.11.35.35,0,0,0,.05-.08.34.34,0,0,0,.06-.12l0-.07a.63.63,0,0,0,0-.19V483.8a.58.58,0,0,1,0,.19l0,.07a.49.49,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.11-.16.12-.12.08-.21.12-.14.07-.28.13-.14.06c-.15.06-.31.12-.48.17l-.63.16-.49.13-.64.11-.54.08-.7.07-.56.05-.75,0h-.56l-.75,0H411l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06c-.38-.08-.75-.17-1.08-.27-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.54q.51.15,1.08.27l.37.06.82.13.5.05c.25,0,.51,0,.77.06s.36,0,.55,0l.75,0,.56,0c.25,0,.5,0,.75,0l.56-.05.7-.07c.19,0,.36-.06.54-.09a6.09,6.09,0,0,0,.64-.11c.18,0,.33-.08.49-.12a4.42,4.42,0,0,0,.55-.14l.08,0,.48-.18a.59.59,0,0,0,.14-.06l.28-.12.14-.08.21-.11.12-.09.16-.12.05,0,.06-.06A.27.27,0,0,0,418.92,487.43Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M417.13,482.27c2.7.83,2.73,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.2-.08-3A19,19,0,0,1,417.13,482.27Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M432.83,491.67l.09-.12a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,0-.13l0-.07a.63.63,0,0,0,0-.19V488a.69.69,0,0,1,0,.2l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.21.12-.14.07a1.82,1.82,0,0,1-.28.13l-.13.06-.49.17-.63.17-.48.12-.65.11-.54.09-.7.06-.56.05c-.25,0-.5,0-.75,0l-.56,0-.75,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13s-.25,0-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2-1-2-1.53v3c0,.56.68,1.11,2,1.54q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07h.55l.76,0h.55c.25,0,.5,0,.75,0l.56-.05.7-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.17.14-.06.27-.13.14-.07.21-.12.12-.09.16-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M431,486.5c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.2-.08-3A18.91,18.91,0,0,1,431,486.5Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M432.74,483.22a.47.47,0,0,0,.09-.12l.05-.07a1.39,1.39,0,0,0,.06-.13l0-.07a.58.58,0,0,0,0-.19v-3.06a1.38,1.38,0,0,1,0,.2l0,.06a.75.75,0,0,1-.06.13.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17s-.42.11-.63.17l-.49.12-.64.11-.54.09c-.23,0-.47,0-.7.06l-.57.06-.74,0-.56,0-.75,0-.56,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.14c-.12,0-.26,0-.37-.06a7.74,7.74,0,0,1-1.08-.27c-1.37-.42-2.06-1-2.06-1.53v3.05c0,.56.69,1.12,2.06,1.54.33.1.7.19,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07h.55l.75,0h.56c.25,0,.5,0,.74,0l.57-.05.7-.07.54-.08.64-.11.49-.13.54-.13.09,0c.17,0,.33-.11.48-.17l.14-.06.28-.13.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,.05,0l.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M431,478.05c2.7.83,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.75-2.19-.08-3A19,19,0,0,1,431,478.05Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M446.64,487.45a.47.47,0,0,0,.09-.12l0-.07a.38.38,0,0,0,.06-.13l0-.06a.69.69,0,0,0,0-.2v-3.05a.68.68,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13.35.35,0,0,1,0,.08l-.09.11-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17a6.48,6.48,0,0,1-.63.17c-.16,0-.31.09-.49.12l-.64.11-.54.09-.7.07-.56.05-.75,0-.56,0-.75,0-.55,0c-.26,0-.52,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.68,1.11,2.05,1.53q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07h.55l.75,0,.56,0,.75,0,.56-.05.7-.06.54-.09.65-.11.48-.13.55-.13.08,0,.49-.17.13-.06a1.82,1.82,0,0,0,.28-.13L446,488l.21-.12.12-.08.16-.12a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M444.85,482.28c2.7.83,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,444.85,482.28Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M446.75,495.91l.09-.12.05-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2v-3.05a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.13a.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12-.12.08-.21.12-.14.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12l-.65.11c-.18,0-.35.07-.54.09l-.7.07-.56.05-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.13-.37-.07a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.69,1.11,2.05,1.53q.51.15,1.08.27c.12,0,.25,0,.38.06s.53.1.81.13l.5.05.77.07.55,0,.76,0,.55,0,.75,0,.57-.05.69-.06.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06a2.24,2.24,0,0,0,.27-.13l.14-.07.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M445,490.74c2.7.83,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,445,490.74Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <polygon points="439.66 292.54 439.62 317.1 411.98 325.51 412.01 300.95 439.66 292.54" style="fill: #88c0f9"/>
                              <path d="M460.65,500.14l.09-.12.05-.07.06-.13a.14.14,0,0,0,0-.06.69.69,0,0,0,0-.2v-3.05a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.08-.09.11a.6.6,0,0,1-.08.08l-.11.1-.16.12a.36.36,0,0,1-.12.08l-.2.12-.14.08-.28.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.69.07-.57.05-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.76-.07l-.51,0-.81-.13-.38-.06a10.62,10.62,0,0,1-1.08-.28c-1.36-.42-2.05-1-2.05-1.53v3.06c0,.55.69,1.11,2.06,1.53.33.1.69.19,1.07.27.12,0,.25,0,.38.06l.82.14.49,0,.77.07.55,0,.76,0,.56,0,.74,0,.57-.06c.23,0,.47,0,.69-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.12.14-.08.2-.12.13-.08.15-.12.05,0,.07-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M458.85,495c2.7.83,2.74,2.2.08,3a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,458.85,495Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M460.57,491.69l.09-.11a.35.35,0,0,1,0-.08.5.5,0,0,0,.05-.13l0-.06a.68.68,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1-.05.13l-.06.07a.83.83,0,0,1-.08.12l-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.28.13-.13.06-.49.17c-.19.06-.41.11-.63.16s-.31.09-.48.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56.05-.75,0H454l-.75,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.26,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56-.05.7-.07.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0,.49-.18.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M458.77,486.52c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3.05A19,19,0,0,1,458.77,486.52Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                            <g>
                              <path d="M474.47,495.92l.09-.11a.35.35,0,0,1,.05-.08l.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19s0,0,0,.07l-.06.13-.05.07-.09.12-.08.07a.52.52,0,0,1-.11.1,1,1,0,0,1-.16.12l-.12.09-.21.12-.14.07-.27.13-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.1c-.18,0-.35.07-.54.09l-.7.07-.56,0c-.25,0-.5,0-.75,0h-.55l-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28l.38.06.81.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57,0,.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.28-.12.13-.08.21-.12a.5.5,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,.05,0,.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                              <path d="M472.67,490.76c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,472.67,490.76Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <polygon points="125.47 340.97 125.43 347.11 69.79 330.18 69.82 324.04 125.47 340.97" style="fill: #477768"/>
                              <polygon points="153.11 332.56 125.47 340.97 69.82 324.04 97.46 315.63 153.11 332.56" style="fill: #6ebaa3"/>
                              <path d="M132.38,527.45l.09-.12.05-.07.06-.13a.14.14,0,0,0,0-.06.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.08-.09.11-.08.08-.11.1-.16.12-.12.09-.2.11-.14.08-.28.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.69.07-.57,0-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.81-.13-.38-.06a10.62,10.62,0,0,1-1.08-.28c-1.36-.42-2-1-2-1.53v3.06c0,.55.69,1.11,2,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.38.06l.82.14.49,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57-.06c.23,0,.47,0,.69-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.12.14-.08.2-.12.12-.08.16-.12.05,0a.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                              <path d="M130.58,522.28c2.7.84,2.74,2.2.08,3.05a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,130.58,522.28Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                            </g>
                            <g>
                              <path d="M146.28,531.68l.09-.11a.35.35,0,0,0,0-.08.52.52,0,0,0,.06-.13l0-.06a1.37,1.37,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12.35.35,0,0,1,0,.08l-.09.12-.07.07-.12.1-.15.12-.12.09-.21.12-.14.07-.28.12-.14.07-.48.17-.63.16c-.16,0-.32.09-.49.12s-.43.08-.65.11-.35.07-.54.09l-.69.07-.57,0c-.24,0-.49,0-.74,0h-.56l-.75,0a5.24,5.24,0,0,1-.56,0c-.26,0-.51,0-.76-.06l-.5-.05-.82-.13-.38-.06c-.38-.08-.74-.17-1.07-.27-1.37-.43-2.06-1-2.06-1.54v3.06c0,.56.69,1.11,2.06,1.53a8,8,0,0,0,1.07.27c.12,0,.26,0,.38.06l.82.14.5,0c.25,0,.5.06.76.07l.56,0,.75,0,.56,0,.74,0,.57-.05.7-.07.54-.09.64-.11c.17,0,.33-.08.49-.12l.54-.14.09,0c.17-.05.33-.11.48-.17l.14-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                              <path d="M144.48,526.51c2.71.84,2.74,2.2.09,3a19,19,0,0,1-9.71,0c-2.71-.84-2.75-2.2-.09-3.05A19,19,0,0,1,144.48,526.51Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M146.2,523.23l.09-.11a.35.35,0,0,1,.05-.08l.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19V519.6a.58.58,0,0,1,0,.19.25.25,0,0,0,0,.07l-.06.13-.05.07-.09.12-.08.07-.11.11-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56.05-.75,0h-.55l-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28l.38.06.81.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.57,0,.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12.37-.08.54-.14l.08,0L145,524l.14-.06.27-.12.14-.08a1.05,1.05,0,0,0,.21-.12.5.5,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                              <path d="M144.4,518.07c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3A19,19,0,0,1,144.4,518.07Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                            </g>
                            <g>
                              <path d="M160.1,527.46l.09-.11a.35.35,0,0,0,.05-.08l.06-.12a.25.25,0,0,0,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13-.05.07-.09.12-.08.07-.11.11-.16.12-.12.08-.2.12-.14.07-.28.13-.14.06a4.65,4.65,0,0,1-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.69.07-.57.05-.74,0h-.56c-.25,0-.51,0-.76,0h-.55l-.77-.07-.5,0c-.28,0-.54-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2-1-2-1.54v3.06c0,.56.69,1.11,2,1.54q.51.15,1.08.27l.38.06.82.13.49.05c.26,0,.51,0,.77.06s.37,0,.55,0l.76,0,.56,0c.25,0,.5,0,.74,0l.57,0,.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.33-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.18a.84.84,0,0,0,.14-.06l.28-.12.14-.07.2-.12.13-.09.15-.12.05,0,.07-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                              <path d="M158.3,522.3c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3A19,19,0,0,1,158.3,522.3Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M160.2,535.93a.47.47,0,0,0,.09-.12.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12l0-.07a.63.63,0,0,0,0-.19v-3.06a1.38,1.38,0,0,1,0,.2l0,.06a.75.75,0,0,1-.06.13l-.05.07a.94.94,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.07-.28.13-.14.06c-.15.06-.31.12-.48.17l-.63.17-.49.12-.64.11-.54.09-.7.06-.57,0c-.24,0-.49,0-.74,0l-.56,0-.75,0h-.56l-.76-.07-.5-.05c-.29,0-.55-.08-.82-.13-.12,0-.26,0-.37-.06q-.59-.12-1.08-.27c-1.37-.42-2.06-1-2.06-1.53v3.05c0,.56.69,1.11,2.06,1.54.33.1.7.19,1.08.27l.37.06c.27,0,.54.1.82.13l.5,0,.77.07h.55l.75,0h.56c.25,0,.5,0,.74,0l.57-.05.7-.07.54-.09c.21,0,.44-.06.64-.11s.33-.08.49-.12a4.26,4.26,0,0,0,.54-.14l.09,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.09.16-.12,0,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                              <path d="M158.41,530.76c2.7.83,2.73,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.75-2.2-.08-3A18.91,18.91,0,0,1,158.41,530.76Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                            </g>
                            <g>
                              <polygon points="153.11 332.56 153.08 338.7 125.43 347.11 125.47 340.97 153.11 332.56" style="fill: #5a9581"/>
                              <path d="M174.1,540.16a.47.47,0,0,0,.09-.12l.05-.07a.49.49,0,0,0,.06-.13l0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.38.38,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17l-.63.17-.49.12-.64.11-.54.09c-.23,0-.47,0-.7.06l-.56.06-.75,0-.56,0-.75,0-.55,0-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13-.12,0-.25,0-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.05c0,.56.68,1.12,2.05,1.54q.51.15,1.08.27l.37.06c.27,0,.54.1.82.13l.5,0,.77.07h.55l.75,0h.56l.75,0,.56-.05.7-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.08,0,.49-.17.13-.06.28-.13.14-.07.21-.12.12-.09a1,1,0,0,0,.16-.12l.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                              <path d="M172.31,535c2.7.83,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,172.31,535Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M174,531.71l.09-.12,0-.07a.75.75,0,0,0,.06-.13l0-.06a1.36,1.36,0,0,0,0-.2v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13a.35.35,0,0,1,0,.08l-.09.11-.07.08-.12.1-.15.12a.57.57,0,0,1-.13.08l-.2.12-.14.08-.28.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17s-.32.09-.49.12l-.65.11-.54.09-.69.07-.57.05-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.14c-.13,0-.26,0-.38-.06a8,8,0,0,1-1.07-.27c-1.37-.42-2.06-1-2.06-1.53v3c0,.56.69,1.12,2.06,1.54.33.1.69.19,1.07.27l.38.06c.27,0,.53.1.82.13l.5,0,.76.07h.56c.25,0,.5,0,.75,0H168c.25,0,.5,0,.74,0l.57,0,.7-.07.54-.08.64-.11.49-.13.54-.13.09,0c.17,0,.33-.11.48-.17l.14-.06.28-.13.14-.07.21-.12.12-.08.15-.12,0,0,.07-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                              <path d="M172.22,526.54c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.7,0c-2.71-.83-2.75-2.19-.09-3A19,19,0,0,1,172.22,526.54Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                            </g>
                            <g>
                              <path d="M187.92,535.94a.47.47,0,0,0,.09-.12l.05-.07a.38.38,0,0,0,.06-.13l0-.06a.69.69,0,0,0,0-.2v-3.05a1.25,1.25,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13.35.35,0,0,1-.05.08l-.09.11-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06-.48.18-.63.16c-.16,0-.32.09-.49.12s-.43.08-.64.11l-.54.09-.7.07-.57,0-.74,0-.56,0-.75,0-.56,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.13-.37-.07a8.11,8.11,0,0,1-1.08-.27c-1.37-.42-2.06-1-2.06-1.53v3.06c0,.55.69,1.11,2.06,1.53.33.1.7.19,1.08.27.12,0,.25,0,.37.06.27,0,.54.1.82.13l.5,0,.77.07.55,0,.75,0,.56,0c.25,0,.5,0,.74,0l.57-.05.7-.06.54-.09.64-.11.49-.12.54-.14.09,0c.17,0,.33-.11.48-.17l.14-.06.28-.13.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,0,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                              <path d="M186.13,530.77c2.7.83,2.73,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,186.13,530.77Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <path d="M148.14,469.2h0V465a.82.82,0,0,1,0,.26l0,.09a1.42,1.42,0,0,1-.08.18l-.07.1a1.83,1.83,0,0,1-.13.16l-.1.11-.15.14-.22.16-.17.12-.28.16-.2.11-.38.17-.19.08-.67.24c-.27.08-.58.15-.87.22s-.43.12-.67.17-.59.11-.89.15l-.75.13c-.31,0-.64.06-1,.09l-.78.07c-.34,0-.68,0-1,0s-.51,0-.77,0-.69,0-1,0l-.76,0-1.06-.1-.69-.06c-.39-.05-.76-.12-1.13-.19l-.51-.08c-.53-.11-1-.23-1.49-.37-1.89-.58-2.84-1.35-2.84-2.12v1.35h0v3h0v4.22c0,.77.95,1.53,2.84,2.12.46.14,1,.26,1.49.37l.51.08c.37.07.74.14,1.13.19l.69.06c.35,0,.7.07,1.06.09s.51,0,.76,0,.69,0,1,0l.77,0c.35,0,.69,0,1,0l.78-.07,1-.1c.26,0,.5-.08.75-.12s.6-.09.89-.15l.68-.17c.25-.06.51-.11.74-.19l.12,0,.67-.24.19-.08.38-.18.2-.1a2.61,2.61,0,0,0,.28-.17l.17-.11.22-.16.07-.06a.6.6,0,0,1,.08-.08l.1-.11a1.83,1.83,0,0,0,.13-.16.86.86,0,0,1,.07-.1,1.42,1.42,0,0,0,.08-.18.32.32,0,0,0,0-.09.84.84,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M151.93,443.92H125.52v23.51h0c0,1.07,1.32,2.14,4,2.95a36.61,36.61,0,0,0,18.68-.05c2.52-.81,3.78-1.86,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M148,440.9c5.19,1.6,5.26,4.22.16,5.85a36.61,36.61,0,0,1-18.68.05c-5.21-1.6-5.28-4.22-.16-5.85A36.5,36.5,0,0,1,148,440.9Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M145.22,441h0v-2.91a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.07.07-.11.1s-.1.07-.15.11l-.11.08-.2.11-.14.08-.26.12-.13.05-.47.17c-.18.06-.39.1-.6.15s-.3.09-.46.12l-.62.11-.51.08c-.22,0-.45,0-.67.06l-.54.05c-.23,0-.47,0-.71,0h-.53l-.72,0h-.53l-.73-.07-.48,0-.78-.13c-.11,0-.24,0-.35-.06a9.1,9.1,0,0,1-1-.26c-1.31-.4-2-.93-2-1.46v.94h0v2.05h0V444c0,.54.65,1.07,2,1.47.32.1.66.18,1,.26.11,0,.24,0,.35.06s.51.09.78.12l.48.05c.24,0,.48,0,.73.06l.53,0,.72,0,.53,0,.71,0,.54,0,.67-.07.51-.08.62-.1.47-.12.51-.13.08,0,.47-.16.13-.06.26-.12.14-.07.2-.11.11-.08.15-.12,0,0,.06-.06.07-.08.09-.11,0-.07a.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19V442h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M143.26,436.64c2.58.8,2.62,2.1.08,2.91a18.05,18.05,0,0,1-9.26,0c-2.59-.79-2.62-2.09-.08-2.9A18.16,18.16,0,0,1,143.26,436.64Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M148.14,439.52h0V435.3a.84.84,0,0,1,0,.27l0,.09a1.42,1.42,0,0,1-.08.18.86.86,0,0,0-.07.1l-.13.16-.1.1-.15.14-.22.17-.17.11-.28.17-.2.1-.38.17-.19.09-.67.24c-.27.08-.58.15-.87.22s-.43.12-.67.17-.59.1-.89.15-.49.09-.75.12l-1,.1-.78.07c-.34,0-.68,0-1,0l-.77,0-1,0c-.25,0-.51,0-.76,0s-.71-.06-1.06-.09l-.69-.07c-.39,0-.76-.11-1.13-.18l-.51-.08a14.06,14.06,0,0,1-1.49-.38c-1.89-.58-2.84-1.35-2.84-2.11v1.35h0v3h0v4.22c0,.76.95,1.53,2.84,2.11a14.06,14.06,0,0,0,1.49.38l.51.08c.37.07.74.13,1.13.18l.69.07c.35,0,.7.07,1.06.09s.51,0,.76,0,.69,0,1,0l.77,0c.35,0,.69,0,1,0l.78-.08c.32,0,.65-.05,1-.09s.5-.08.75-.12.6-.09.89-.15l.68-.17c.25-.06.51-.12.74-.19l.12,0c.24-.07.46-.15.67-.23l.19-.09.38-.17.2-.1.28-.17.17-.11.22-.17.07-.05.08-.09.1-.1.13-.16.07-.11a1.3,1.3,0,0,0,.08-.17.32.32,0,0,0,0-.09.84.84,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M151.93,414.24H125.52v23.51h0c0,1.08,1.32,2.15,4,3a36.5,36.5,0,0,0,18.68-.06c2.52-.8,3.78-1.85,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M148,411.22c5.19,1.6,5.26,4.23.16,5.86a36.61,36.61,0,0,1-18.68.05c-5.21-1.6-5.28-4.22-.16-5.85A36.61,36.61,0,0,1,148,411.22Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M145.22,411.35h0v-2.92a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.44.44,0,0,1-.08.11l-.07.08-.11.09-.15.12-.11.08-.2.11-.14.07-.26.12-.13.06-.47.17-.6.15-.46.12-.62.1-.51.09-.67.06-.54.05-.71,0-.53,0-.72,0-.53,0c-.25,0-.49,0-.73-.06l-.48-.05c-.27,0-.52-.08-.78-.12l-.35-.06a9.1,9.1,0,0,1-1-.26c-1.31-.4-2-.93-2-1.46v.93h0v2.06h0v2.91c0,.53.65,1.06,2,1.47.32.09.66.18,1,.25.11,0,.24,0,.35.06s.51.1.78.13l.48,0,.73.07H138l.72,0h.53c.24,0,.48,0,.71,0l.54-.05c.23,0,.45,0,.67-.06l.51-.08.62-.11c.16,0,.31-.08.47-.12l.51-.13.08,0c.17,0,.32-.11.47-.17l.13-.05.26-.12.14-.07.2-.12.11-.08a.83.83,0,0,0,.15-.11.1.1,0,0,0,0,0l.06-.06.07-.07.09-.11a.43.43,0,0,1,0-.07.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.57.57,0,0,0,0-.18v-2h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M143.26,407c2.58.79,2.62,2.1.08,2.91a18.05,18.05,0,0,1-9.26,0c-2.59-.79-2.62-2.09-.08-2.9A18.05,18.05,0,0,1,143.26,407Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M148.14,409.93h0v-4.22a.84.84,0,0,1,0,.27l0,.09-.08.17-.07.11-.13.16-.1.1-.15.14-.22.17-.17.11-.28.17-.2.1-.38.17-.19.08a5.84,5.84,0,0,1-.67.24c-.27.09-.58.16-.87.23s-.43.12-.67.17-.59.1-.89.15-.49.09-.75.12-.64.06-1,.09-.52.06-.78.07-.68,0-1,0l-.77,0c-.35,0-.69,0-1,0s-.51,0-.76,0-.71-.06-1.06-.09l-.69-.07c-.39-.05-.76-.11-1.13-.18l-.51-.08c-.53-.11-1-.24-1.49-.38-1.89-.58-2.84-1.35-2.84-2.12v1.36h0v3h0v4.21c0,.77.95,1.54,2.84,2.12a14.11,14.11,0,0,0,1.49.37c.16,0,.34.06.51.09.37.06.74.13,1.13.18.23,0,.46,0,.69.06s.7.08,1.06.1l.76,0c.35,0,.69,0,1,0l.77,0,1,0c.27,0,.52,0,.78-.07s.65,0,1-.09.5-.08.75-.12.6-.09.89-.15l.68-.17c.25-.07.51-.12.74-.19l.12,0a5.84,5.84,0,0,0,.67-.24l.19-.08.38-.17.2-.11.28-.16.17-.11.22-.17.07-.05.08-.09a.52.52,0,0,0,.1-.11,1,1,0,0,0,.13-.16.86.86,0,0,0,.07-.1,1.42,1.42,0,0,0,.08-.18s0-.05,0-.08a.84.84,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M151.93,384.65H125.52v23.51h0c0,1.07,1.32,2.14,4,3a36.5,36.5,0,0,0,18.68-.06c2.52-.8,3.78-1.85,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M148,381.63c5.19,1.6,5.26,4.23.16,5.86a36.61,36.61,0,0,1-18.68.05c-5.21-1.6-5.28-4.22-.16-5.86A36.61,36.61,0,0,1,148,381.63Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M145.22,381.76h0v-2.92a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12l-.05.07a.44.44,0,0,1-.08.11l-.07.08-.11.09-.15.12-.11.08-.2.11-.14.07-.26.12-.13.06-.47.16c-.18.06-.39.11-.6.16l-.46.12-.62.1-.51.08-.67.07-.54.05-.71,0-.53,0-.72,0-.53,0c-.25,0-.49,0-.73-.06l-.48-.05c-.27,0-.52-.08-.78-.12l-.35-.06c-.37-.08-.71-.16-1-.26-1.31-.4-2-.93-2-1.46v.93h0v2.06h0v2.92c0,.53.65,1.06,2,1.46a9.1,9.1,0,0,0,1,.26c.11,0,.24,0,.35.06l.78.13.48,0,.73.07H138l.72,0h.53c.24,0,.48,0,.71,0l.54-.05c.23,0,.45,0,.67-.06s.34-.06.51-.09l.62-.1c.16,0,.31-.08.47-.12l.51-.13.08,0,.47-.17.13,0,.26-.12.14-.08.2-.11.11-.08s.11-.07.15-.11l0,0,.06-.06.07-.07.09-.11a.43.43,0,0,1,0-.07,1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.57.57,0,0,0,0-.18V382.7h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M143.26,377.38c2.58.79,2.62,2.09.08,2.9a18.16,18.16,0,0,1-9.26,0c-2.59-.8-2.62-2.1-.08-2.91A18.16,18.16,0,0,1,143.26,377.38Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M192.08,497.14h0v-4.22a1.26,1.26,0,0,1,0,.27.59.59,0,0,1,0,.08,1.42,1.42,0,0,1-.08.18.86.86,0,0,1-.07.1l-.12.17-.11.1a.67.67,0,0,1-.15.14l-.22.17-.16.11-.29.17-.2.1c-.11.06-.25.11-.38.17l-.19.08a5.84,5.84,0,0,1-.67.24c-.27.09-.57.16-.86.23s-.44.12-.68.17-.59.1-.89.15-.49.09-.74.12-.64.06-1,.09-.51.06-.78.07-.68,0-1,0l-.77,0-1,0c-.25,0-.51,0-.76,0s-.7-.06-1.06-.09l-.69-.07c-.39-.05-.75-.11-1.12-.18l-.52-.08c-.53-.11-1-.24-1.49-.38-1.89-.58-2.83-1.35-2.83-2.12v1.36h0v3h0v4.22c0,.77.94,1.54,2.83,2.12a14.75,14.75,0,0,0,1.49.37c.17,0,.35.06.52.09.37.06.74.13,1.13.18.22,0,.46,0,.68.06s.7.08,1.06.1l.77,0c.34,0,.69,0,1,0l.77,0,1,0c.27,0,.52-.05.79-.07l1-.09c.25,0,.49-.08.74-.12s.61-.09.89-.15l.68-.17.75-.19.11,0a5.84,5.84,0,0,0,.67-.24l.19-.08.39-.17.19-.11a2.69,2.69,0,0,0,.29-.16l.16-.11a2.11,2.11,0,0,0,.22-.17l.07-.05.09-.09.1-.11a1,1,0,0,0,.12-.16.36.36,0,0,0,.07-.1.71.71,0,0,0,.08-.18.59.59,0,0,1,0-.08,1.26,1.26,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M195.87,471.86H169.45v23.51h0c0,1.07,1.32,2.14,4,2.95a36.5,36.5,0,0,0,18.68-.05c2.52-.8,3.78-1.85,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M191.92,468.84c5.2,1.6,5.27,4.23.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.22-.15-5.86A36.58,36.58,0,0,1,191.92,468.84Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M189.15,469h0v-2.92a1.37,1.37,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.11-.07.08-.11.09-.15.12-.11.08-.2.11-.13.07-.27.12-.13.06-.46.16-.6.16-.47.12-.62.1-.51.08-.67.07-.53.05-.72,0-.53,0-.72,0-.52,0c-.25,0-.49,0-.74-.06l-.47-.05c-.27,0-.53-.08-.78-.12l-.36-.06c-.36-.08-.71-.16-1-.26-1.31-.4-2-.93-2-1.47V467h0v2.06h0V472c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26c.11,0,.24,0,.36.06l.78.13.47,0,.74.07h.52l.72,0h.54l.71,0,.54-.05.66-.06c.18,0,.34-.06.52-.09l.61-.1c.17,0,.31-.08.47-.12l.52-.13.08,0,.46-.17.13-.06.27-.11.13-.08.2-.11.11-.08.16-.11,0,0,.06-.06a.46.46,0,0,0,.07-.07l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a1.1,1.1,0,0,0,0-.18v-2h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M187.2,464.59c2.58.79,2.61,2.09.08,2.9a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.2,18.2,0,0,1,187.2,464.59Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M158.28,478.62a.94.94,0,0,0,.09-.12l0-.07.06-.12a.14.14,0,0,1,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49.05.74.06l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.05.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06A.46.46,0,0,0,158.28,478.62Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                        <path d="M156.54,473.59c2.62.81,2.66,2.14.07,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.08-3A18.46,18.46,0,0,1,156.54,473.59Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                      </g>
                      <g>
                        <path d="M131.24,478.62a.83.83,0,0,0,.08-.12l.05-.07a.67.67,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08a.94.94,0,0,0-.1.1.91.91,0,0,1-.16.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.13-.08.21-.11.11-.08.16-.12.05,0a.34.34,0,0,1,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                        <path d="M129.49,473.59c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,129.49,473.59Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                      </g>
                      <g>
                        <g>
                          <path d="M464.44,444.82h0V440.6a.84.84,0,0,1,0,.27l0,.09c0,.06-.05.12-.08.17l-.07.11-.13.16-.1.1-.15.14-.22.17-.17.11-.29.17-.19.1-.38.17-.19.09c-.21.08-.43.16-.67.23s-.58.16-.87.23-.43.12-.67.17-.59.1-.89.15-.49.09-.75.12-.64.06-1,.09l-.78.08c-.34,0-.69,0-1,0l-.77,0c-.35,0-.69,0-1,0s-.51,0-.76,0-.71-.06-1.06-.09l-.69-.07c-.39-.05-.76-.11-1.13-.18l-.51-.08a14.06,14.06,0,0,1-1.49-.38c-1.89-.58-2.84-1.35-2.84-2.11V442h0v3h0v4.21c0,.77.95,1.54,2.84,2.12.46.14,1,.27,1.49.38l.51.08c.37.07.74.13,1.13.18l.69.07c.35,0,.7.07,1.06.09s.51,0,.76,0,.69,0,1,0l.77,0c.35,0,.69,0,1,0s.52,0,.78-.07.65-.05,1-.09.5-.08.75-.12.6-.09.89-.15l.68-.17c.24-.06.51-.12.74-.19l.12,0a5.84,5.84,0,0,0,.67-.24l.19-.08.38-.17.19-.1.29-.17.17-.11.22-.17.07-.05L464,450l.1-.1.13-.16.07-.11a1.42,1.42,0,0,0,.08-.18.19.19,0,0,0,0-.08.84.84,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                          <path d="M468.23,419.54H441.82v23.51h0c0,1.07,1.31,2.15,3.95,3a36.47,36.47,0,0,0,18.67-.06c2.53-.8,3.79-1.85,3.79-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                          <path d="M464.29,416.52c5.19,1.6,5.26,4.23.15,5.86a36.58,36.58,0,0,1-18.67.05c-5.21-1.6-5.28-4.22-.16-5.86A36.61,36.61,0,0,1,464.29,416.52Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                          <path d="M461.52,416.65h0v-2.92a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.44.44,0,0,1-.08.11l-.07.08-.11.09-.15.12-.12.08-.19.11-.14.07-.26.12-.14.06a4.09,4.09,0,0,1-.46.16c-.18.06-.39.11-.6.16l-.46.12-.62.1-.51.09-.67.06-.54.05-.71,0-.53,0-.72,0-.53,0c-.25,0-.49,0-.73-.06l-.48-.05c-.27,0-.52-.08-.78-.12l-.36-.06a9.48,9.48,0,0,1-1-.26c-1.31-.4-2-.93-2-1.46v.93h0v2.06h0v2.92c0,.53.65,1.06,2,1.46a9.1,9.1,0,0,0,1,.26c.11,0,.24,0,.35.06l.78.13.48,0,.73.07h.53l.72,0h.53c.24,0,.48,0,.71,0l.54,0c.22,0,.45,0,.67-.06l.51-.08.62-.11c.16,0,.31-.08.47-.12l.51-.13.08,0c.17-.05.32-.11.47-.17l.13-.05.26-.12.14-.07.2-.12.11-.08c.05,0,.11-.07.15-.11l0,0,.06-.06.07-.07.08-.11.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.57.57,0,0,0,0-.18v-2.05h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                          <path d="M459.56,412.27c2.58.79,2.62,2.1.08,2.9a18.16,18.16,0,0,1-9.26,0c-2.59-.8-2.62-2.09-.08-2.9A18.05,18.05,0,0,1,459.56,412.27Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        </g>
                        <g>
                          <path d="M474.58,454.24l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13l-.05.07-.09.11a.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06a10,10,0,0,1-1-.27c-1.33-.41-2-.95-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1,.26l.37.06.79.13.49,0,.74.07.54,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17,0,.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                          <path d="M472.83,449.22c2.63.81,2.67,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,472.83,449.22Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M447.54,454.24a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.08.07a.94.94,0,0,1-.1.1l-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06a9.44,9.44,0,0,1-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07.53,0,.74,0,.54,0,.72,0,.56-.06c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11s0,0,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                          <path d="M445.79,449.22c2.63.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,445.79,449.22Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M464.44,415.38h0v-4.21a.78.78,0,0,1,0,.26l0,.09-.08.18-.07.1a1.83,1.83,0,0,1-.13.16l-.1.11-.15.14-.22.16-.17.12-.29.16-.19.11-.38.17-.19.08-.67.24c-.27.09-.58.15-.87.22a6.56,6.56,0,0,1-.67.17l-.89.16-.75.12c-.31,0-.64.06-1,.09l-.78.07c-.34,0-.69,0-1,0s-.51,0-.77,0-.69,0-1,0l-.76,0c-.36,0-.71-.06-1.06-.1s-.47,0-.69-.06c-.39-.05-.76-.12-1.13-.18-.17,0-.35-.05-.51-.09a14.11,14.11,0,0,1-1.49-.37c-1.89-.58-2.84-1.35-2.84-2.12v1.35h0v3h0v4.22c0,.77.95,1.53,2.84,2.12.46.14,1,.26,1.49.37l.51.08c.37.07.74.14,1.13.19l.69.06,1.06.1.76,0c.35,0,.69,0,1,0s.52,0,.77,0,.69,0,1,0l.78-.07c.32,0,.65-.05,1-.09l.75-.13c.3,0,.6-.09.89-.15l.68-.17c.24-.06.51-.11.74-.19l.12,0,.67-.24.19-.08.38-.18.19-.1c.11-.05.2-.11.29-.16l.17-.12.22-.16.07-.06.08-.08.1-.11a1.83,1.83,0,0,0,.13-.16l.07-.1a1.42,1.42,0,0,0,.08-.18.32.32,0,0,0,0-.09.84.84,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                          <path d="M468.23,390.1H441.82v23.51h0c0,1.07,1.31,2.14,3.95,2.95a36.58,36.58,0,0,0,18.67-.05c2.53-.81,3.79-1.86,3.79-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                          <path d="M464.29,387.08c5.19,1.6,5.26,4.22.15,5.85a36.58,36.58,0,0,1-18.67.05c-5.21-1.6-5.28-4.22-.16-5.85A36.5,36.5,0,0,1,464.29,387.08Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                          <path d="M461.52,387.21h0v-2.92a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07-.08.11-.07.07-.11.1-.15.11a.5.5,0,0,1-.12.08l-.19.12-.14.07-.26.12-.14.05a4.05,4.05,0,0,1-.46.17c-.18.06-.39.1-.6.15s-.3.09-.46.12l-.62.11-.51.08c-.22,0-.45,0-.67.06l-.54.05c-.23,0-.47,0-.71,0H455l-.72,0h-.53l-.73-.07-.48,0-.78-.13c-.12,0-.24,0-.36-.06-.36-.07-.7-.16-1-.25-1.31-.41-2-.94-2-1.47v.94h0v2h0v2.92c0,.53.65,1.06,2,1.46.32.1.66.18,1,.26l.35.06c.26,0,.51.09.78.12l.48,0c.24,0,.48.05.73.06l.53,0,.72,0,.53,0,.71,0,.54,0,.67-.07L458,392l.62-.1.47-.12.51-.13.08,0,.47-.16.13-.06.26-.12.14-.07.2-.11L461,391l.15-.12,0,0,.06-.06.07-.08a.44.44,0,0,0,.08-.11l.05-.07a.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-2.05h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                          <path d="M459.56,382.82c2.58.8,2.62,2.1.08,2.91a18.16,18.16,0,0,1-9.26,0c-2.59-.8-2.62-2.1-.08-2.91A18.16,18.16,0,0,1,459.56,382.82Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        </g>
                        <g>
                          <g>
                            <polygon points="410.83 126.35 410.83 162.81 414.44 162.81 414.44 125.66 410.83 126.35" style="fill: #538b7e"/>
                            <g>
                              <path d="M444.13,331.17c3.17-.11,5.81,1.61,5.89,3.84a3,3,0,0,1-.36,1.53,5.3,5.3,0,0,1,1.81,1.53,6.38,6.38,0,0,1,6,0,6.46,6.46,0,0,1,.71.44,4.41,4.41,0,0,1,1.58-1.63,3.27,3.27,0,0,1-.52-2.42c.44-2.2,3.32-3.48,6.44-2.86s5.28,2.91,4.84,5.11a3.45,3.45,0,0,1-1.83,2.3c1.29,1.7,1.67,2.35.75,4.09-1.36,2.57-5,3.32-8.11,1.66a6.78,6.78,0,0,1-.78-.48,7,7,0,0,1-.4.94,6.29,6.29,0,0,1-8.63,2.35,5.8,5.8,0,0,1-3-3.21,7.49,7.49,0,0,1-1.44.42c-3.46.65-6.72-.54-7.26-3.41-.26-1.34.12-1.79,1-2.91a3.72,3.72,0,0,1-2.2-3C438.45,333.19,441,331.29,444.13,331.17Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                              <path d="M444.16,329.57c3.17-.11,5.81,1.61,5.89,3.84a3.07,3.07,0,0,1-.36,1.54,5.28,5.28,0,0,1,1.81,1.52,6.41,6.41,0,0,1,6,0,7,7,0,0,1,.7.45,4.55,4.55,0,0,1,1.59-1.64,3.22,3.22,0,0,1-.52-2.42c.44-2.2,3.32-3.48,6.43-2.86s5.29,2.91,4.85,5.11a3.45,3.45,0,0,1-1.83,2.3c1.29,1.71,1.67,2.35.75,4.09-1.36,2.57-5,3.32-8.11,1.67a8.54,8.54,0,0,1-.78-.48,6.86,6.86,0,0,1-.4.93,6.28,6.28,0,0,1-8.63,2.36,5.84,5.84,0,0,1-3-3.22,7.39,7.39,0,0,1-1.43.42c-3.47.66-6.73-.54-7.26-3.4-.26-1.35.12-1.8,1-2.91a3.77,3.77,0,0,1-2.21-3.05C438.47,331.59,441,329.69,444.16,329.57Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                              <g>
                                <path d="M444.13,334.18h0l.05-1h0l.25-4.58a1.36,1.36,0,0,0,0,.29l0,.1a1.67,1.67,0,0,0,.08.2l.07.11.12.19.11.12.16.16a2.33,2.33,0,0,0,.23.19,1.09,1.09,0,0,0,.17.13l.3.2.21.12.4.21.2.1a6,6,0,0,0,.72.3c.28.11.61.2.92.3s.47.16.72.22l1,.22.8.17c.34.07.69.11,1,.16l.84.12c.37.05.75.07,1.12.1s.55.06.83.07l1.13,0,.83,0c.39,0,.77,0,1.16,0s.5,0,.75,0c.42,0,.83-.08,1.23-.13.19,0,.39,0,.57-.06a14.9,14.9,0,0,0,1.64-.32c2.08-.52,3.15-1.29,3.2-2.13l-.17,3.11h0l-.1,1.83h0v.16l0,.45h0l-.14,2.49c-.05.84-1.12,1.62-3.2,2.14-.51.12-1.06.23-1.64.31-.18,0-.38,0-.56.06-.41,0-.81.11-1.24.14l-.75,0c-.38,0-.76,0-1.16,0s-.55,0-.82,0-.76,0-1.13,0-.56,0-.84-.07-.75-.05-1.11-.09l-.85-.13c-.34,0-.7-.09-1-.15l-.81-.18c-.32-.07-.65-.13-.95-.22s-.49-.15-.73-.22-.55-.16-.8-.25l-.12-.05c-.26-.09-.49-.19-.71-.29l-.21-.11-.4-.2-.21-.13-.3-.19-.17-.14-.23-.19-.07-.06-.09-.1-.1-.12-.13-.18-.07-.12a1.18,1.18,0,0,1-.07-.2l0-.09a1.53,1.53,0,0,1,0-.3l.1-1.81h0v-.16h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                                <path d="M447.63,326.44c-4.12,1-4.28,3.07-.37,4.55a28.44,28.44,0,0,0,14.54.83c4.12-1,4.28-3.06.37-4.55A28.55,28.55,0,0,0,447.63,326.44Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                              </g>
                              <path d="M454,330a1.85,1.85,0,0,0,.75.19,1.79,1.79,0,0,0,1.83-1.74l0-1.42a1.79,1.79,0,1,0-3.57-.1l0,1.42A1.79,1.79,0,0,0,454,330Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                              <circle cx="412.08" cy="123.73" r="1.03" style="fill: #538b7e"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M465.19,381.51h0v-3.1h0v-4.58a1.55,1.55,0,0,1,0,.29l0,.09a1.76,1.76,0,0,1-.09.2l-.08.11-.13.17-.11.12-.17.15-.24.18-.18.12-.31.18-.21.11a4.08,4.08,0,0,1-.42.19l-.21.09c-.22.09-.46.18-.72.26s-.63.17-.94.25-.48.13-.73.18l-1,.16-.8.14-1.05.1c-.28,0-.56.06-.85.07s-.74,0-1.12,0-.55,0-.83,0-.76,0-1.13,0-.56,0-.83,0-.77-.07-1.15-.1l-.75-.07c-.43-.05-.83-.13-1.23-.2-.18,0-.38-.05-.56-.09-.57-.12-1.11-.25-1.62-.41-2.05-.63-3.08-1.46-3.08-2.3v3.12h0v3.91h0v4.08h0v4.58c0,.84,1,1.67,3.08,2.3a14.74,14.74,0,0,0,1.62.41c.18,0,.38.06.56.09.41.07.81.15,1.23.2l.75.07c.38,0,.76.08,1.15.1s.55,0,.83,0,.75,0,1.13,0l.84,0,1.11,0,.85-.08,1.05-.1.81-.13c.32-.06.66-.1,1-.17s.49-.12.73-.18.56-.13.81-.21l.13,0c.26-.08.5-.17.73-.26l.2-.09.42-.18.21-.12a2.29,2.29,0,0,0,.31-.18l.18-.12.24-.18.08-.06.09-.09.11-.12a1.09,1.09,0,0,0,.13-.17.76.76,0,0,0,.08-.11,1.76,1.76,0,0,0,.09-.2l0-.09a1.45,1.45,0,0,0,0-.29v-3.9h0v-.17h0Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                              <path d="M462.11,371.52c4.06,1.24,4.11,3.29.13,4.56a28.55,28.55,0,0,1-14.57,0c-4.06-1.25-4.11-3.29-.12-4.56A28.4,28.4,0,0,1,462.11,371.52Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                            </g>
                            <circle cx="412.74" cy="165.84" r="6.1" style="fill: #336052"/>
                            <path d="M465.19,385.59h1.69a2.11,2.11,0,0,0,2.11-2.11v-8.66a5,5,0,0,1,.49-2.16l5.73-12.11L472,359.14l-6.8,14.68Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                            <path d="M444.59,385.59H442.9a2.11,2.11,0,0,1-2.11-2.11v-8.66a5,5,0,0,0-.48-2.16l-5.74-12.11,3.23-1.41,6.79,14.68Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                          </g>
                          <g>
                            <path d="M486.29,358.29c-2.75-1.59-5.89-1.3-7,.63a3,3,0,0,0-.39,1.53,5.28,5.28,0,0,0-2.32.5,6.37,6.37,0,0,0-5.27-2.78,5.69,5.69,0,0,0-.83.06,4.67,4.67,0,0,0-.63-2.19,3.27,3.27,0,0,0,1.59-1.89c.64-2.16-1.3-4.64-4.35-5.55s-6,.1-6.67,2.25a3.43,3.43,0,0,0,.54,2.89,5.2,5.2,0,0,0-3.25,4.59c0,2.91,2.86,5.27,6.39,5.27a6.84,6.84,0,0,0,.91-.06,6.77,6.77,0,0,0-.09,1,6.38,6.38,0,0,0,10.7,4.71,8.12,8.12,0,0,0,1.07,1c2.76,2.2,6.47,2.14,8.28-.14a4.75,4.75,0,0,0,.91-3.82,3.73,3.73,0,0,0,3.37-1.66C490.36,362.74,489,359.88,486.29,358.29Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                            <path d="M486.29,356.58c-2.75-1.59-5.89-1.3-7,.63a3,3,0,0,0-.39,1.53,5.28,5.28,0,0,0-2.32.5,6.37,6.37,0,0,0-5.27-2.78,5.69,5.69,0,0,0-.83.06,4.64,4.64,0,0,0-.63-2.19,3.27,3.27,0,0,0,1.59-1.89c.64-2.16-1.3-4.64-4.35-5.55s-6,.1-6.67,2.25A3.43,3.43,0,0,0,461,352a5.2,5.2,0,0,0-3.25,4.59c0,2.91,2.86,5.27,6.39,5.27a6.84,6.84,0,0,0,.91-.06,6.77,6.77,0,0,0-.09,1,6.39,6.39,0,0,0,6.39,6.39,6.3,6.3,0,0,0,4.31-1.69,7.6,7.6,0,0,0,1.07,1c2.76,2.2,6.47,2.14,8.28-.14a4.75,4.75,0,0,0,.91-3.82,3.73,3.73,0,0,0,3.37-1.66C490.36,361,489,358.17,486.29,356.58Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                            <g>
                              <path d="M484.62,360.65h0l.41-.93h0l1.83-4.2a.92.92,0,0,1-.15.25l-.08.07a.67.67,0,0,1-.15.14l-.12.07a1.18,1.18,0,0,1-.19.11l-.15.06-.21.07-.29.07-.22,0-.35,0-.24,0H484l-.77-.06c-.3,0-.63-.09-1-.15l-.74-.12-1-.24c-.26-.07-.52-.12-.79-.2l-1-.33-.81-.27c-.34-.12-.69-.27-1-.41l-.77-.31c-.35-.15-.69-.32-1-.48l-.75-.36c-.34-.17-.67-.36-1-.55l-.66-.36c-.37-.22-.7-.45-1-.68l-.48-.3q-.72-.51-1.32-1c-1.63-1.4-2.24-2.58-1.9-3.35l-1.25,2.86h0l-.73,1.68h0l-.06.16-.18.41h0l-1,2.3c-.33.76.28,1.94,1.9,3.34q.6.51,1.32,1l.48.31c.34.22.68.45,1,.67l.66.36c.34.19.67.38,1,.56l.75.35c.34.16.67.33,1,.48l.78.31c.34.14.69.29,1,.42l.81.26,1,.33c.27.08.53.13.8.2s.64.18.95.24.5.08.75.12.56.11.82.14l.14,0,.77.05h.68l.24,0,.36,0,.21,0,.29-.07.09,0,.13-.05.14-.06.2-.1.11-.08a1.07,1.07,0,0,0,.16-.14l.07-.07a1,1,0,0,0,.16-.25l.72-1.66h0l.06-.15h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                              <path d="M485,352.16c3.21,2.77,2.44,4.66-1.72,4.23a28.55,28.55,0,0,1-13.36-5.78c-3.22-2.77-2.45-4.67,1.71-4.24A28.54,28.54,0,0,1,485,352.16Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                            </g>
                            <path d="M477.73,352.49a1.72,1.72,0,0,1-.76-.17,1.78,1.78,0,0,1-.86-2.37l.68-1.25a1.79,1.79,0,0,1,3.24,1.51l-.69,1.25A1.77,1.77,0,0,1,477.73,352.49Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                            <circle cx="437.01" cy="146.5" r="1.03" style="fill: #538b7e"/>
                          </g>
                          <g>
                            <path d="M423.1,357.4c2.75-1.59,5.89-1.31,7,.63a3,3,0,0,1,.4,1.53,5.34,5.34,0,0,1,2.32.49,6.39,6.39,0,0,1,5.26-2.78,7.72,7.72,0,0,1,.84.06,4.49,4.49,0,0,1,.63-2.18,3.31,3.31,0,0,1-1.6-1.9c-.64-2.15,1.31-4.63,4.35-5.54s6,.1,6.68,2.25a3.47,3.47,0,0,1-.54,2.89,5.2,5.2,0,0,1,3.24,4.59c0,2.91-2.85,5.27-6.38,5.27a7,7,0,0,1-.92-.06,5.66,5.66,0,0,1,.09,1,6.38,6.38,0,0,1-10.7,4.7,7.61,7.61,0,0,1-1.07,1.05c-2.76,2.2-6.46,2.13-8.28-.14a4.77,4.77,0,0,1-.9-3.83,3.74,3.74,0,0,1-3.38-1.66C419,361.85,420.35,359,423.1,357.4Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                            <path d="M423.1,355.69c2.75-1.59,5.89-1.31,7,.63a3,3,0,0,1,.4,1.53,5.34,5.34,0,0,1,2.32.49,6.39,6.39,0,0,1,5.26-2.78,7.72,7.72,0,0,1,.84.06,4.49,4.49,0,0,1,.63-2.18,3.31,3.31,0,0,1-1.6-1.9c-.64-2.15,1.31-4.63,4.35-5.54s6,.1,6.68,2.25a3.47,3.47,0,0,1-.54,2.89,5.2,5.2,0,0,1,3.24,4.59c0,2.91-2.85,5.27-6.38,5.27-.31,0-.62,0-.92-.06a5.66,5.66,0,0,1,.09,1,6.38,6.38,0,0,1-10.7,4.7,7.61,7.61,0,0,1-1.07,1.05c-2.76,2.2-6.46,2.13-8.28-.14a4.77,4.77,0,0,1-.9-3.83,3.74,3.74,0,0,1-3.38-1.66C419,360.14,420.35,357.28,423.1,355.69Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                            <g>
                              <path d="M424.77,359.75h0l-.4-.93h0l-1.83-4.2a1,1,0,0,0,.16.25.46.46,0,0,1,.07.07,1.07,1.07,0,0,0,.16.14.41.41,0,0,0,.11.07,1.18,1.18,0,0,0,.19.11l.15.06.22.07.29.07.21,0,.36,0,.24,0h.68a7.09,7.09,0,0,0,.77-.05c.31,0,.64-.1,1-.15l.75-.12,1-.24.79-.2,1-.33c.27-.09.54-.17.81-.27l1-.41.78-.31,1-.48c.25-.12.51-.23.75-.36s.68-.37,1-.55.45-.24.66-.37c.37-.21.71-.44,1.05-.67.15-.1.33-.2.48-.31a15.83,15.83,0,0,0,1.32-1c1.62-1.4,2.23-2.57,1.9-3.34l1.24,2.86h0l.73,1.68h0l.07.15.18.42h0l1,2.29c.34.77-.27,1.94-1.9,3.34a14.33,14.33,0,0,1-1.32,1c-.15.11-.32.21-.48.31-.34.23-.67.46-1,.68l-.66.36c-.33.18-.66.38-1,.55l-.75.36c-.34.16-.68.33-1,.48l-.77.31c-.35.14-.69.29-1,.41l-.81.27-1,.33c-.27.08-.53.13-.79.2l-1,.24-.75.12c-.27,0-.56.11-.82.14h-.13l-.77.06h-.69l-.23,0-.36,0-.22,0-.29-.07-.09,0-.12-.05-.15-.06a1.18,1.18,0,0,1-.19-.11l-.12-.07a.67.67,0,0,1-.15-.14l-.08-.07a1.28,1.28,0,0,1-.15-.25l-.73-1.67h0l-.07-.16h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                              <path d="M424.42,351.27c-3.22,2.76-2.45,4.66,1.71,4.23a28.5,28.5,0,0,0,13.37-5.79c3.22-2.77,2.45-4.66-1.72-4.23A28.51,28.51,0,0,0,424.42,351.27Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                            </g>
                            <path d="M431.66,351.6a1.82,1.82,0,0,0,.75-.17,1.78,1.78,0,0,0,.86-2.38l-.68-1.25a1.78,1.78,0,0,0-3.23,1.52l.68,1.25A1.78,1.78,0,0,0,431.66,351.6Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                            <circle cx="388.2" cy="145.61" r="1.03" style="fill: #538b7e"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon points="449.57 320.23 449.54 344.79 421.71 336.33 421.75 311.77 449.57 320.23" style="fill: #256eb8"/>
                          <polygon points="463.4 316.04 449.57 320.23 421.75 311.77 435.57 307.58 463.4 316.04" style="fill: #a2d4ff"/>
                          <path d="M484.31,515.18l.09-.11a.35.35,0,0,1,.05-.08l.06-.12s0,0,0-.07a.63.63,0,0,0,0-.19v-3.06a.58.58,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1-.05.13l-.05.07-.09.12-.08.07-.11.11-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06a4.65,4.65,0,0,1-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56,0-.75,0h-.55l-.76,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.54q.51.15,1.08.27l.37.06.82.13.5,0c.26,0,.51.05.77.06l.55,0,.76,0,.55,0c.25,0,.5,0,.75,0l.56-.05.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12.37-.08.54-.14l.08,0,.49-.18.14-.06.27-.12.14-.08a1,1,0,0,0,.21-.11l.12-.09.16-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                          <path d="M482.51,510c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,482.51,510Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <polygon points="463.4 316.04 463.36 340.59 449.54 344.79 449.57 320.23 463.4 316.04" style="fill: #88c0f9"/>
                          <path d="M498.21,519.42l.09-.12.05-.08.06-.12a.25.25,0,0,0,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.2.12-.14.07-.28.13-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09-.69.06-.57,0-.74,0-.56,0c-.26,0-.51,0-.76,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.81-.13s-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2-1-2-1.53v3.05c0,.56.69,1.11,2,1.54q.51.15,1.08.27l.38.06c.27,0,.53.1.82.13l.49,0,.77.07h.55l.76,0h.56l.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.33-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.17.14-.06.28-.13.14-.07.2-.12.13-.09.15-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #256eb8"/>
                          <path d="M496.41,514.25c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3A18.91,18.91,0,0,1,496.41,514.25Z" transform="translate(-42.09 -202.78)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <path d="M192.08,467.35h0v-4.22a1.26,1.26,0,0,1,0,.27l0,.09a1.42,1.42,0,0,1-.08.18.86.86,0,0,0-.07.1l-.12.16-.11.1a.67.67,0,0,1-.15.14l-.22.17-.16.11-.29.17-.2.1a3.34,3.34,0,0,1-.38.17L190,465l-.67.24c-.27.08-.57.15-.86.22l-.68.17c-.28.06-.59.1-.89.15s-.49.09-.74.12l-1,.1-.78.07c-.34,0-.68,0-1,0l-.77,0-1,0c-.25,0-.51,0-.76,0s-.7-.06-1.06-.09l-.69-.07c-.39,0-.75-.11-1.12-.18l-.52-.08a14.06,14.06,0,0,1-1.49-.38c-1.89-.58-2.83-1.34-2.83-2.11v1.35h0v3h0v4.22c0,.76.94,1.53,2.83,2.11a14.71,14.71,0,0,0,1.49.38l.52.08c.37.07.74.13,1.13.18l.68.07c.36,0,.7.07,1.06.09s.51,0,.77,0,.69,0,1,0l.77,0c.34,0,.68,0,1,0l.79-.08,1-.09c.25,0,.49-.08.74-.12s.61-.09.89-.15.45-.11.68-.17l.75-.19.11,0c.24-.07.46-.15.67-.23l.19-.09a3.52,3.52,0,0,0,.39-.17l.19-.1.29-.17.16-.11a2.11,2.11,0,0,0,.22-.17l.07,0,.09-.09.1-.1.12-.16.07-.11A.65.65,0,0,0,192,472l0-.09a1.26,1.26,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M195.87,442.07H169.45v23.51h0c0,1.08,1.32,2.15,4,3a36.5,36.5,0,0,0,18.68-.06c2.52-.8,3.78-1.85,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M191.92,439.05c5.2,1.6,5.27,4.23.16,5.86a36.61,36.61,0,0,1-18.68,0c-5.2-1.6-5.27-4.22-.15-5.85A36.57,36.57,0,0,1,191.92,439.05Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M189.15,439.18h0v-2.92a1.11,1.11,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.11-.07.08-.11.09-.15.12-.11.08-.2.11-.13.07-.27.12-.13.06-.46.17-.6.15-.47.12-.62.1-.51.09-.67.06-.53,0-.72,0-.53,0-.72,0-.52,0c-.25,0-.49,0-.74-.06l-.47-.05c-.27,0-.53-.08-.78-.12L179,438a9.68,9.68,0,0,1-1-.26c-1.31-.4-2-.93-2-1.46v.93h0v2.06h0v2.91c0,.53.66,1.06,2,1.47.32.09.67.18,1,.25.11,0,.24,0,.36.06s.51.1.78.13l.47,0,.74.07h.52l.72,0h.54l.71,0,.54-.05c.22,0,.44,0,.66-.06l.52-.08.61-.11.47-.11.52-.14.08,0,.46-.17.13-.05.27-.12.13-.07.2-.12.11-.08a.91.91,0,0,0,.16-.11l0,0,.06-.06.07-.07.09-.11a.18.18,0,0,1,.05-.07.5.5,0,0,0,0-.13l0-.06a1.1,1.1,0,0,0,0-.18v-2.05h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M187.2,434.8c2.58.79,2.61,2.1.08,2.91a18.09,18.09,0,0,1-9.27,0c-2.58-.79-2.62-2.09-.08-2.9A18.09,18.09,0,0,1,187.2,434.8Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M192.08,437.68h0v-4.22a1.26,1.26,0,0,1,0,.27l0,.08a1.42,1.42,0,0,1-.08.18.86.86,0,0,1-.07.1l-.12.17-.11.1a.67.67,0,0,1-.15.14l-.22.17-.16.11-.29.17-.2.1c-.11.06-.25.11-.38.17l-.19.08a5.84,5.84,0,0,1-.67.24c-.27.09-.57.16-.86.23l-.68.17c-.28.06-.59.1-.89.15s-.49.09-.74.12-.64.06-1,.09-.51.06-.78.07-.68,0-1,0l-.77,0-1,0c-.25,0-.51,0-.76,0s-.7-.06-1.06-.09l-.69-.07c-.39-.05-.75-.11-1.12-.18l-.52-.08c-.53-.11-1-.24-1.49-.38-1.89-.58-2.83-1.35-2.83-2.12v1.36h0v3h0V442c0,.77.94,1.54,2.83,2.12a14.75,14.75,0,0,0,1.49.37c.17,0,.35.06.52.09.37.06.74.13,1.13.18.22,0,.46,0,.68.06s.7.08,1.06.1l.77,0c.34,0,.69,0,1,0l.77,0,1,0c.27,0,.52-.05.79-.07s.64,0,1-.09.49-.08.74-.12.61-.09.89-.15l.68-.17.75-.19.11,0a5.84,5.84,0,0,0,.67-.24l.19-.08.39-.17.19-.11a2.69,2.69,0,0,0,.29-.16l.16-.11a2.11,2.11,0,0,0,.22-.17l.07-.05.09-.09.1-.11a1,1,0,0,0,.12-.16.36.36,0,0,0,.07-.1.71.71,0,0,0,.08-.18.59.59,0,0,1,0-.08,1.26,1.26,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M195.87,412.4H169.45v23.51h0c0,1.07,1.32,2.14,4,2.95a36.5,36.5,0,0,0,18.68-.05c2.52-.8,3.78-1.85,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M191.92,409.38c5.2,1.6,5.27,4.23.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.22-.15-5.86A36.58,36.58,0,0,1,191.92,409.38Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M189.15,409.51h0v-2.92a1.37,1.37,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12L189,407l-.09.11a.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.11.08-.2.11-.13.07-.27.12-.13.06-.46.16-.6.16-.47.12-.62.1-.51.08-.67.07-.53.05-.72,0-.53,0-.72,0-.52,0c-.25,0-.49,0-.74-.06l-.47-.05c-.27,0-.53-.08-.78-.12l-.36-.06c-.36-.08-.71-.16-1-.26-1.31-.4-2-.93-2-1.47v.94h0v2.06h0v2.92c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26c.11,0,.24,0,.36.06l.78.13.47,0,.74.07h.52l.72,0h.54l.71,0,.54-.05c.22,0,.44,0,.66-.06s.34-.06.52-.09l.61-.1c.17,0,.31-.08.47-.12l.52-.13.08,0,.46-.17.13-.06.27-.11.13-.08.2-.11.11-.08.16-.11s0,0,0,0a.34.34,0,0,0,.06-.06l.07-.07.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a1.1,1.1,0,0,0,0-.18v-2.05h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M187.2,405.13c2.58.79,2.61,2.09.08,2.9a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.2,18.2,0,0,1,187.2,405.13Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M192.08,408.09h0v-4.22a1.09,1.09,0,0,1,0,.26l0,.09a1.42,1.42,0,0,1-.08.18l-.07.1-.12.16-.11.11a1,1,0,0,1-.15.14l-.22.16-.16.12-.29.16-.2.11c-.11.06-.25.11-.38.17l-.19.08-.67.24c-.27.09-.57.15-.86.23l-.68.17c-.28.06-.59.1-.89.15s-.49.09-.74.12-.64.06-1,.09-.51.06-.78.07l-1,0-.77,0-1,0-.76,0c-.36,0-.7-.06-1.06-.1s-.46,0-.69-.06c-.39-.05-.75-.12-1.12-.18-.17,0-.36-.05-.52-.09A14.11,14.11,0,0,1,176,406c-1.89-.58-2.83-1.35-2.83-2.12v1.36h0v3h0v4.22c0,.77.94,1.54,2.83,2.12a14.75,14.75,0,0,0,1.49.37c.17,0,.35.06.52.09.37.06.74.13,1.13.18.22,0,.46,0,.68.06s.7.08,1.06.1l.77,0c.34,0,.69,0,1,0l.77,0,1,0,.79-.07,1-.09.74-.12.89-.16c.24,0,.45-.11.68-.17s.51-.11.75-.18l.11,0,.67-.24.19-.08.39-.17.19-.11.29-.16.16-.12.22-.16.07-.05.09-.09.1-.11a1,1,0,0,0,.12-.16.61.61,0,0,0,.07-.1.71.71,0,0,0,.08-.18l0-.09a1.17,1.17,0,0,0,0-.26v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M195.87,382.81H169.45v23.51h0c0,1.07,1.32,2.14,4,2.95a36.61,36.61,0,0,0,18.68,0c2.52-.81,3.78-1.85,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M191.92,379.79c5.2,1.6,5.27,4.22.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.23-.15-5.86A36.47,36.47,0,0,1,191.92,379.79Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M189.15,379.92h0V377a1.1,1.1,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1.58.58,0,0,1-.15.11l-.11.08-.2.12-.13.07-.27.12-.13.06-.46.16-.6.16-.47.11-.62.11-.51.08-.67.07-.53,0-.72,0h-.53l-.72,0h-.52l-.74-.07-.47,0c-.27,0-.53-.08-.78-.13l-.36,0c-.36-.08-.71-.16-1-.26-1.31-.41-2-.94-2-1.47v.94h0V380h0v2.92c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26l.36.06c.25,0,.51.09.78.12l.47.05c.25,0,.49.05.74.06l.52,0,.72,0,.54,0,.71,0,.54-.05.66-.06.52-.09.61-.1.47-.12.52-.13.08,0c.16,0,.32-.1.46-.16l.13-.06.27-.12.13-.07.2-.11.11-.08.16-.12,0,0a.34.34,0,0,0,.06-.06l.07-.08a.48.48,0,0,0,.09-.11l.05-.07a.61.61,0,0,0,0-.12l0-.06a1.11,1.11,0,0,0,0-.19v-2h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M187.2,375.54c2.58.79,2.61,2.09.08,2.9a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.09,18.09,0,0,1,187.2,375.54Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M192.08,378.17h0V374a1.26,1.26,0,0,1,0,.27l0,.08a1.42,1.42,0,0,1-.08.18.86.86,0,0,1-.07.1l-.12.17-.11.1a1,1,0,0,1-.15.14l-.22.17-.16.11-.29.17-.2.1c-.11.06-.25.11-.38.17l-.19.08a5.84,5.84,0,0,1-.67.24c-.27.09-.57.16-.86.23l-.68.17c-.28.06-.59.1-.89.15s-.49.09-.74.12-.64.06-1,.09-.51.06-.78.07-.68,0-1,0l-.77,0-1,0c-.25,0-.51,0-.76,0s-.7-.06-1.06-.09l-.69-.07c-.39,0-.75-.11-1.12-.18l-.52-.08c-.53-.11-1-.24-1.49-.38-1.89-.58-2.83-1.35-2.83-2.12v1.36h0v3h0v4.22c0,.77.94,1.54,2.83,2.12a14.75,14.75,0,0,0,1.49.37c.17,0,.35.06.52.09.37.06.74.13,1.13.18.22,0,.46,0,.68.06s.7.08,1.06.1l.77,0c.34,0,.69,0,1,0l.77,0,1,0c.27,0,.52,0,.79-.07l1-.09c.25,0,.49-.08.74-.12s.61-.09.89-.15l.68-.17.75-.19.11,0a5.84,5.84,0,0,0,.67-.24l.19-.08.39-.17.19-.11a2.69,2.69,0,0,0,.29-.16l.16-.11a2.11,2.11,0,0,0,.22-.17l.07,0,.09-.09.1-.11a1,1,0,0,0,.12-.16.36.36,0,0,0,.07-.1.71.71,0,0,0,.08-.18l0-.08a1.26,1.26,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M195.87,352.89H169.45V376.4h0c0,1.07,1.32,2.14,4,3a36.5,36.5,0,0,0,18.68-.05c2.52-.8,3.78-1.85,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M191.92,349.87c5.2,1.6,5.27,4.23.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.22-.15-5.86A36.58,36.58,0,0,1,191.92,349.87Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M189.15,350h0v-2.92a1.37,1.37,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.11a.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.11.08-.2.11-.13.07-.27.12-.13.06-.46.16-.6.16-.47.12-.62.1-.51.08-.67.07-.53.05-.72,0-.53,0-.72,0-.52,0c-.25,0-.49,0-.74-.06l-.47-.05c-.27,0-.53-.08-.78-.12s-.25,0-.36-.06c-.36-.08-.71-.16-1-.26-1.31-.4-2-.93-2-1.47V348h0v2.06h0V353c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26c.11,0,.24,0,.36.06l.78.13.47,0,.74.07h.52l.72,0,.54,0c.23,0,.47,0,.71,0l.54-.05c.22,0,.44,0,.66-.06s.34-.06.52-.09l.61-.1c.17,0,.31-.08.47-.12l.52-.13.08,0,.46-.17.13-.06.27-.11.13-.08.2-.11.11-.08.16-.11,0,0,.06-.06.07-.07.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a1.1,1.1,0,0,0,0-.18v-2.05h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M187.2,345.62c2.58.79,2.61,2.09.08,2.9a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.2,18.2,0,0,1,187.2,345.62Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M192.08,348.5h0v-4.22a1.09,1.09,0,0,1,0,.26l0,.09a1.42,1.42,0,0,1-.08.18l-.07.1-.12.16-.11.11a1,1,0,0,1-.15.14l-.22.16-.16.12-.29.16-.2.11c-.11.06-.25.11-.38.17l-.19.08-.67.24c-.27.09-.57.15-.86.23l-.68.17-.89.15-.74.12c-.32,0-.64.06-1,.09s-.51.06-.78.07l-1,0-.77,0-1,0-.76,0c-.36,0-.7-.06-1.06-.1l-.69-.06c-.39-.05-.75-.12-1.12-.18-.17,0-.36-.05-.52-.09a14.11,14.11,0,0,1-1.49-.37c-1.89-.58-2.83-1.35-2.83-2.12v1.36h0v3h0v4.22c0,.77.94,1.54,2.83,2.12.47.14,1,.26,1.49.37l.52.09c.37.06.74.13,1.13.18.22,0,.46,0,.68.06l1.06.1.77,0c.34,0,.69,0,1,0l.77,0,1,0,.79-.07,1-.09.74-.13c.3,0,.61-.09.89-.15l.68-.17c.25-.06.51-.11.75-.18l.11,0,.67-.24.19-.08.39-.17.19-.11.29-.16.16-.12.22-.16.07-.06.09-.08.1-.11a1.67,1.67,0,0,0,.12-.16.61.61,0,0,0,.07-.1.71.71,0,0,0,.08-.18l0-.09a1.27,1.27,0,0,0,0-.26v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M195.87,323.22H169.45v23.51h0c0,1.07,1.32,2.14,4,2.95a36.61,36.61,0,0,0,18.68-.05c2.52-.81,3.78-1.85,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M191.92,320.2c5.2,1.6,5.27,4.22.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.23-.15-5.86A36.47,36.47,0,0,1,191.92,320.2Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M189.15,320.33h0v-2.92a1.1,1.1,0,0,1,0,.18l0,.06a.5.5,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11-.07.07-.11.1a.58.58,0,0,1-.15.11l-.11.08-.2.12-.13.07-.27.12-.13.05-.46.17-.6.16-.47.11-.62.11-.51.08c-.22,0-.44,0-.67.06l-.53.05-.72,0h-.53l-.72,0h-.52l-.74-.07-.47,0c-.27,0-.53-.08-.78-.13s-.25,0-.36-.06c-.36-.07-.71-.16-1-.25-1.31-.41-2-.94-2-1.47v.94h0v2h0v2.92c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26l.36.06c.25,0,.51.09.78.12l.47,0c.25,0,.49.05.74.06l.52,0,.72,0,.54,0,.71,0,.54-.05.66-.06.52-.09.61-.1.47-.12.52-.13.08,0c.16-.05.32-.1.46-.16l.13-.06.27-.12.13-.07.2-.11.11-.08.16-.12,0,0,.06-.06.07-.08a.48.48,0,0,0,.09-.11l.05-.07a.61.61,0,0,0,0-.12l0-.06a1.11,1.11,0,0,0,0-.19v-2.05h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M187.2,315.94c2.58.8,2.61,2.1.08,2.91a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.2,18.2,0,0,1,187.2,315.94Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <path d="M192.08,318.91h0v-4.22a1.26,1.26,0,0,1,0,.27l0,.09a1.42,1.42,0,0,1-.08.18l-.07.1-.12.16-.11.11-.15.14-.22.16-.16.12-.29.16-.2.1-.38.18-.19.08-.67.24c-.27.08-.57.15-.86.22l-.68.17c-.28.06-.59.11-.89.15l-.74.13c-.32,0-.64.06-1,.09l-.78.07c-.34,0-.68,0-1,0s-.51,0-.77,0l-1,0-.76,0-1.06-.1c-.22,0-.46,0-.69-.06-.39-.05-.75-.12-1.12-.19l-.52-.08c-.53-.11-1-.23-1.49-.37-1.89-.58-2.83-1.35-2.83-2.12V316h0v3h0v4.22c0,.77.94,1.53,2.83,2.12.47.14,1,.26,1.49.37l.52.08c.37.07.74.14,1.13.19l.68.06c.36,0,.7.07,1.06.09l.77,0c.34,0,.69,0,1,0l.77,0c.34,0,.68,0,1,0l.79-.07,1-.1c.25,0,.49-.08.74-.12s.61-.09.89-.15l.68-.17.75-.19.11,0,.67-.24.19-.08.39-.18.19-.1.29-.17a.91.91,0,0,0,.16-.11l.22-.16.07-.06.09-.08.1-.11a1.67,1.67,0,0,0,.12-.16l.07-.1a.71.71,0,0,0,.08-.18l0-.09a1.26,1.26,0,0,0,0-.27v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #39346b"/>
                        <path d="M195.87,293.62H169.45v23.52h0c0,1.07,1.32,2.14,4,2.95a36.61,36.61,0,0,0,18.68,0c2.52-.81,3.78-1.86,3.78-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M191.92,290.6c5.2,1.61,5.27,4.23.16,5.86a36.61,36.61,0,0,1-18.68.05c-5.2-1.6-5.27-4.22-.15-5.85A36.57,36.57,0,0,1,191.92,290.6Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                        <path d="M189.15,290.74h0v-2.91a1.1,1.1,0,0,1,0,.18l0,.06a1.12,1.12,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11a.46.46,0,0,1-.07.07l-.11.1a1,1,0,0,1-.15.11l-.11.08-.2.11-.13.08-.27.11-.13.06-.46.17c-.19.06-.4.1-.6.15s-.3.09-.47.12l-.62.1c-.17,0-.33.07-.51.09s-.44,0-.67.06l-.53.05c-.24,0-.48,0-.72,0h-.53l-.72,0h-.52l-.74-.07-.47,0-.78-.13c-.12,0-.25,0-.36-.06a9.68,9.68,0,0,1-1-.26c-1.31-.4-2-.93-2-1.46v.94h0v2.06h0v2.91c0,.54.66,1.07,2,1.47.32.1.67.18,1,.26l.36.06c.25,0,.51.09.78.12l.47.05c.25,0,.49.05.74.06l.52,0,.72,0,.54,0,.71,0,.54-.05.66-.07.52-.08.61-.1.47-.12.52-.13.08,0,.46-.16.13-.06.27-.12.13-.07.2-.11.11-.08.16-.12,0,0a.2.2,0,0,0,.06-.07.23.23,0,0,0,.07-.07l.09-.11.05-.07a.61.61,0,0,0,0-.12l0-.06a1.37,1.37,0,0,0,0-.19v-2.05h0Z" transform="translate(-42.09 -202.78)" style="fill: #4c4282"/>
                        <path d="M187.2,286.35c2.58.8,2.61,2.1.08,2.91a18.09,18.09,0,0,1-9.27,0c-2.58-.79-2.62-2.09-.08-2.9A18.2,18.2,0,0,1,187.2,286.35Z" transform="translate(-42.09 -202.78)" style="fill: #6a66ac"/>
                      </g>
                      <g>
                        <g>
                          <polygon points="138.46 29.88 138.46 66.34 142.08 66.34 142.08 29.19 138.46 29.88" style="fill: #538b7e"/>
                          <g>
                            <path d="M171.76,234.7c3.18-.11,5.82,1.6,5.9,3.84a3,3,0,0,1-.37,1.53,5.43,5.43,0,0,1,1.82,1.53,6.33,6.33,0,0,1,5.95,0,5.64,5.64,0,0,1,.71.44,4.49,4.49,0,0,1,1.58-1.63,3.32,3.32,0,0,1-.52-2.42c.44-2.21,3.32-3.49,6.44-2.86s5.28,2.91,4.85,5.11a3.45,3.45,0,0,1-1.84,2.29c1.29,1.71,1.68,2.35.76,4.1-1.37,2.57-5,3.31-8.12,1.66a7.77,7.77,0,0,1-.78-.48,6.91,6.91,0,0,1-.39.94,6.29,6.29,0,0,1-8.63,2.35,5.86,5.86,0,0,1-3-3.21,8.17,8.17,0,0,1-1.43.42c-3.47.65-6.73-.55-7.27-3.41-.25-1.35.13-1.79,1-2.91a3.74,3.74,0,0,1-2.21-3.05C166.08,236.72,168.59,234.81,171.76,234.7Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                            <path d="M171.79,233.1c3.18-.11,5.82,1.61,5.9,3.84a3.08,3.08,0,0,1-.37,1.54,5.41,5.41,0,0,1,1.82,1.52,6.39,6.39,0,0,1,6,0c.25.14.48.29.71.45a4.52,4.52,0,0,1,1.58-1.64,3.31,3.31,0,0,1-.52-2.42c.44-2.2,3.32-3.48,6.44-2.86s5.28,2.91,4.84,5.11a3.42,3.42,0,0,1-1.83,2.3c1.29,1.71,1.68,2.35.75,4.09-1.36,2.57-5,3.32-8.11,1.67a7.85,7.85,0,0,1-.78-.49,5.53,5.53,0,0,1-.4.94,6.26,6.26,0,0,1-8.62,2.35,5.79,5.79,0,0,1-3-3.21,7.28,7.28,0,0,1-1.44.42c-3.46.66-6.72-.54-7.26-3.4-.25-1.35.13-1.8,1-2.91a3.74,3.74,0,0,1-2.2-3C166.11,235.12,168.62,233.22,171.79,233.1Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                            <g>
                              <path d="M171.76,237.71h0l.06-1h0l.25-4.58a.93.93,0,0,0,0,.29,1,1,0,0,0,0,.1,1.59,1.59,0,0,0,.07.2l.07.11.13.19.1.12c.05,0,.11.1.16.16l.23.19.17.13.31.2.2.12.4.21.21.1.71.3c.29.11.61.2.93.3l.72.22c.3.08.63.15,1,.22l.8.17,1,.16c.28,0,.55.09.84.12s.74.07,1.11.1.56.06.84.07.75,0,1.13,0l.83,0c.39,0,.77,0,1.15,0s.51,0,.75,0c.43,0,.83-.08,1.24-.13.18,0,.38,0,.56-.06a14.9,14.9,0,0,0,1.64-.32c2.08-.52,3.16-1.29,3.2-2.13l-.17,3.11h0l-.1,1.83h0v.16l0,.45h0l-.14,2.5c0,.84-1.11,1.61-3.2,2.13a15.18,15.18,0,0,1-1.63.32c-.18,0-.38,0-.57.06-.4.05-.81.1-1.23.13-.25,0-.51,0-.76,0s-.76,0-1.15,0l-.83,0c-.38,0-.75,0-1.13,0s-.56,0-.84-.07l-1.11-.09-.84-.13c-.35-.05-.7-.09-1-.16l-.8-.17-1-.22c-.26-.06-.48-.15-.72-.22s-.55-.16-.8-.25l-.13,0a6.58,6.58,0,0,1-.71-.3l-.2-.1-.41-.21-.2-.12a2.23,2.23,0,0,1-.3-.2,1.18,1.18,0,0,1-.18-.13l-.22-.19-.08-.06-.08-.1L172,241a.76.76,0,0,1-.12-.19.41.41,0,0,1-.07-.11.84.84,0,0,1-.08-.2.29.29,0,0,1,0-.1.7.7,0,0,1,0-.29l.1-1.81h0v-.17h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                              <path d="M175.26,230c-4.11,1-4.28,3.07-.37,4.55a28.45,28.45,0,0,0,14.54.83c4.12-1,4.29-3.06.37-4.55A28.55,28.55,0,0,0,175.26,230Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                            </g>
                            <path d="M181.58,233.52a1.89,1.89,0,0,0,.75.19,1.8,1.8,0,0,0,1.84-1.74l0-1.42a1.79,1.79,0,1,0-3.57-.1l0,1.42A1.78,1.78,0,0,0,181.58,233.52Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                            <circle cx="139.71" cy="27.25" r="1.03" style="fill: #538b7e"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M192.82,285h0v-3.1h0v-4.59a1,1,0,0,1,0,.3l0,.09a.66.66,0,0,1-.09.19l-.07.11-.14.18a.57.57,0,0,1-.11.11,1.29,1.29,0,0,1-.17.16l-.23.18-.18.12-.32.18a1.39,1.39,0,0,1-.21.11l-.41.19-.21.09c-.23.09-.47.18-.73.26s-.62.16-.94.24-.47.14-.73.19l-1,.16c-.27.05-.53.1-.81.13s-.7.07-1,.1-.56.07-.84.08-.75,0-1.12,0l-.84,0c-.38,0-.75,0-1.13,0s-.55,0-.83,0-.77-.07-1.15-.1l-.75-.07-1.22-.2-.57-.09a16.42,16.42,0,0,1-1.61-.41c-2.05-.63-3.08-1.47-3.08-2.3v3.11h0v3.91h0v4.08h0V293c0,.84,1,1.67,3.08,2.3a15.93,15.93,0,0,0,1.62.41c.17,0,.37.06.56.09.4.07.8.15,1.22.2l.75.07c.38,0,.76.08,1.15.1l.83,0c.38,0,.75,0,1.13,0l.84,0c.37,0,.75,0,1.12,0l.85-.08,1-.1.81-.14c.33-.05.66-.09,1-.16s.49-.12.73-.18.56-.13.82-.21l.12,0c.26-.08.5-.17.73-.26l.21-.09.42-.18.2-.12.32-.18.18-.12.24-.18.07-.06.09-.09.11-.12a1,1,0,0,0,.14-.17l.07-.11a.88.88,0,0,0,.09-.2l0-.09a1,1,0,0,0,0-.29v-3.9h0v-.17h0Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                            <path d="M189.75,275c4.05,1.25,4.1,3.3.12,4.57a28.51,28.51,0,0,1-14.56,0c-4.06-1.25-4.12-3.29-.12-4.56A28.4,28.4,0,0,1,189.75,275Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                          </g>
                          <circle cx="140.37" cy="69.37" r="6.1" style="fill: #336052"/>
                          <path d="M192.82,289.12h1.7a2.11,2.11,0,0,0,2.1-2.11v-8.66a5,5,0,0,1,.49-2.16l5.73-12.11-3.22-1.41-6.8,14.67Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                          <path d="M172.23,289.12h-1.7a2.11,2.11,0,0,1-2.1-2.11v-8.66a5,5,0,0,0-.49-2.16l-5.73-12.11,3.22-1.41,6.8,14.67Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                        </g>
                        <g>
                          <path d="M213.92,261.82c-2.75-1.59-5.89-1.31-7,.63a3.14,3.14,0,0,0-.39,1.53,5.45,5.45,0,0,0-2.32.49,6.39,6.39,0,0,0-5.27-2.77,5.69,5.69,0,0,0-.83.06,4.53,4.53,0,0,0-.63-2.19,3.26,3.26,0,0,0,1.59-1.9c.65-2.15-1.3-4.63-4.34-5.54s-6,.1-6.68,2.25a3.47,3.47,0,0,0,.54,2.89,5.2,5.2,0,0,0-3.25,4.59c0,2.91,2.86,5.27,6.39,5.27a7,7,0,0,0,.92-.06,5.66,5.66,0,0,0-.09,1,6.38,6.38,0,0,0,10.7,4.7,7.1,7.1,0,0,0,1.07,1.05c2.75,2.2,6.46,2.14,8.28-.14a4.8,4.8,0,0,0,.9-3.83,3.74,3.74,0,0,0,3.38-1.66C218,266.27,216.67,263.41,213.92,261.82Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                          <path d="M213.92,260.11c-2.75-1.59-5.89-1.31-7,.63a3.14,3.14,0,0,0-.39,1.53,5.45,5.45,0,0,0-2.32.49,6.39,6.39,0,0,0-5.27-2.77,5.69,5.69,0,0,0-.83.06,4.49,4.49,0,0,0-.63-2.19,3.26,3.26,0,0,0,1.59-1.9c.65-2.15-1.3-4.63-4.34-5.54s-6,.1-6.68,2.25a3.47,3.47,0,0,0,.54,2.89,5.2,5.2,0,0,0-3.25,4.59c0,2.91,2.86,5.27,6.39,5.27a7,7,0,0,0,.92-.06,5.66,5.66,0,0,0-.09,1,6.38,6.38,0,0,0,10.7,4.7,7.1,7.1,0,0,0,1.07,1.05c2.75,2.2,6.46,2.14,8.28-.14a4.81,4.81,0,0,0,.9-3.83,3.74,3.74,0,0,0,3.38-1.66C218,264.56,216.67,261.7,213.92,260.11Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                          <g>
                            <path d="M212.25,264.17h0l.4-.93h0l1.83-4.2a1,1,0,0,1-.16.25.46.46,0,0,0-.07.07,1.07,1.07,0,0,1-.16.14.41.41,0,0,1-.11.07l-.2.11-.14.06-.22.07-.29.07-.21,0-.36,0-.24,0h-.68l-.77-.06c-.31,0-.64-.09-1-.15l-.75-.12-1-.24c-.27-.07-.53-.12-.8-.2l-1-.33-.81-.27c-.35-.12-.7-.27-1-.41l-.78-.31c-.35-.15-.68-.32-1-.48s-.51-.23-.75-.36-.68-.37-1-.55-.45-.24-.66-.37c-.37-.21-.71-.44-1.05-.67-.16-.1-.33-.2-.48-.31a15.83,15.83,0,0,1-1.32-1c-1.62-1.4-2.23-2.57-1.9-3.34l-1.24,2.86h0l-.73,1.68h0l-.07.16-.18.41h0l-1,2.29c-.33.77.28,1.95,1.91,3.35q.6.51,1.32,1l.47.3c.34.23.68.46,1.05.68l.66.36c.33.19.66.38,1,.55l.75.36c.34.16.68.33,1,.48l.78.31c.35.14.69.29,1,.41l.81.27,1,.33c.27.08.53.13.79.2l1,.24.74.12c.28.05.57.11.83.14h.13l.77.06h.69l.23,0,.36,0,.22-.05.29-.07.09,0,.12,0,.15-.06a1.18,1.18,0,0,0,.19-.11.41.41,0,0,0,.11-.07,1.07,1.07,0,0,0,.16-.14l.08-.07a1.28,1.28,0,0,0,.15-.25l.73-1.67h0l.07-.15h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                            <path d="M212.6,255.69c3.22,2.76,2.45,4.66-1.71,4.23a28.5,28.5,0,0,1-13.37-5.79c-3.22-2.77-2.45-4.66,1.72-4.23A28.51,28.51,0,0,1,212.6,255.69Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                          </g>
                          <path d="M205.36,256a1.75,1.75,0,0,1-.76-.17,1.78,1.78,0,0,1-.85-2.37l.68-1.25a1.78,1.78,0,1,1,3.23,1.51L207,255A1.78,1.78,0,0,1,205.36,256Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                          <circle cx="164.64" cy="50.03" r="1.03" style="fill: #538b7e"/>
                        </g>
                        <g>
                          <path d="M150.73,260.93c2.75-1.59,5.89-1.31,7,.63a3,3,0,0,1,.39,1.53,5.28,5.28,0,0,1,2.32.49,6.37,6.37,0,0,1,5.27-2.78,7.54,7.54,0,0,1,.83.06,4.6,4.6,0,0,1,.63-2.18,3.29,3.29,0,0,1-1.59-1.9c-.64-2.15,1.3-4.63,4.35-5.54s6,.1,6.67,2.25a3.41,3.41,0,0,1-.54,2.88,5.21,5.21,0,0,1,3.25,4.59c0,2.92-2.86,5.28-6.39,5.28a9.07,9.07,0,0,1-.91-.06,6.77,6.77,0,0,1,.09,1,6.38,6.38,0,0,1-10.7,4.7,8.18,8.18,0,0,1-1.07,1.05c-2.76,2.2-6.47,2.13-8.29-.14a4.82,4.82,0,0,1-.9-3.83,3.74,3.74,0,0,1-3.38-1.66C146.66,265.38,148,262.52,150.73,260.93Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                          <path d="M150.73,259.22c2.75-1.59,5.89-1.31,7,.63a3,3,0,0,1,.39,1.53,5.28,5.28,0,0,1,2.32.49,6.37,6.37,0,0,1,5.27-2.78,7.54,7.54,0,0,1,.83.06,4.6,4.6,0,0,1,.63-2.18,3.29,3.29,0,0,1-1.59-1.9c-.64-2.15,1.3-4.63,4.35-5.54s6,.1,6.67,2.25a3.41,3.41,0,0,1-.54,2.88,5.21,5.21,0,0,1,3.25,4.59c0,2.92-2.86,5.28-6.39,5.28a9.07,9.07,0,0,1-.91-.06,6.65,6.65,0,0,1,.09,1,6.38,6.38,0,0,1-10.7,4.7,8.18,8.18,0,0,1-1.07,1.05c-2.76,2.2-6.47,2.13-8.29-.14a4.82,4.82,0,0,1-.9-3.83,3.74,3.74,0,0,1-3.38-1.66C146.66,263.66,148,260.81,150.73,259.22Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                          <g>
                            <path d="M152.4,263.28h0l-.41-.93h0l-1.84-4.2a1.25,1.25,0,0,0,.16.24l.07.08.16.14.12.07.19.11.15.06.21.07.29.07.22,0,.35,0,.24,0H153c.25,0,.5,0,.78-.05s.63-.1.95-.15l.75-.12.95-.24.8-.21c.33-.09.66-.21,1-.32l.81-.27,1-.41c.26-.11.52-.2.78-.32s.68-.31,1-.48l.74-.35,1-.55c.22-.13.45-.24.66-.37.36-.22.7-.44,1-.67.16-.1.33-.2.48-.31a14.33,14.33,0,0,0,1.32-1c1.63-1.4,2.24-2.57,1.9-3.34l1.25,2.86h0l.74,1.67h0l.07.15.18.41h0l1,2.29c.33.77-.28,1.94-1.9,3.34a15.83,15.83,0,0,1-1.32,1c-.15.11-.32.21-.48.31-.34.23-.68.46-1.05.67-.21.13-.44.24-.65.37l-1,.55c-.24.13-.5.24-.75.36s-.67.33-1,.48l-.78.31c-.35.14-.69.29-1,.41l-.81.27-1,.33c-.27.08-.52.13-.79.2l-.95.24-.75.12c-.28.05-.56.11-.83.14H157l-.77.06h-.68l-.24,0-.36,0-.21,0-.29-.07-.09,0-.13-.05-.15-.06a1.18,1.18,0,0,1-.19-.11.41.41,0,0,1-.11-.07,1.07,1.07,0,0,1-.16-.14l-.07-.07a1,1,0,0,1-.16-.25l-.73-1.67h0l-.07-.16h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                            <path d="M152.05,254.8c-3.21,2.76-2.44,4.66,1.72,4.23a28.46,28.46,0,0,0,13.36-5.79c3.22-2.77,2.45-4.66-1.72-4.23A28.51,28.51,0,0,0,152.05,254.8Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                          </g>
                          <path d="M159.29,255.13a1.87,1.87,0,0,0,.76-.17,1.79,1.79,0,0,0,.86-2.38l-.68-1.25a1.79,1.79,0,1,0-3.24,1.52l.68,1.25A1.79,1.79,0,0,0,159.29,255.13Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                          <circle cx="115.83" cy="49.14" r="1.03" style="fill: #538b7e"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <polygon points="324.66 295.76 190.6 337.49 165.07 329.56 299.45 287.76 324.66 295.76" style="fill: #6ebaa3"/>
                              <polygon points="190.6 337.49 190.57 343.45 165.03 335.53 165.07 329.56 190.6 337.49" style="fill: #477768"/>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M279.56,516a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.44.44,0,0,1-.09.11l-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.09,2,1.49.32.1.67.19,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M277.81,511c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.38,18.38,0,0,1,277.81,511Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M293.06,520.13l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.5v3c0,.54.66,1.09,2,1.49a10,10,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M291.31,515.11c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.49,18.49,0,0,1,291.31,515.11Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M293,511.92l.09-.11,0-.07a.31.31,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12l-.05.07a.83.83,0,0,1-.08.12l-.07.07-.11.1a.91.91,0,0,1-.16.11l-.11.09-.21.11-.13.07-.27.13-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11l.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M291.23,506.9c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,291.23,506.9Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M306.48,516a.48.48,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.14.14,0,0,0,0,.07l-.06.12-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.11-.12.09-.2.11-.14.07a1.69,1.69,0,0,1-.27.13l-.13,0c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.62.11-.53.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06l.79.13.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.62-.11.48-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M304.74,511c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,304.74,511Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M252.58,524.23l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.19.19,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.07l-.06.12a.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.26.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.05.27-.12.13-.08.2-.11.12-.08.16-.12,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M250.83,519.21c2.62.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,250.83,519.21Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M266.08,528.34l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06c.26,0,.52.1.8.13l.48,0c.25,0,.5.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0c.17,0,.33-.11.48-.17L265,529l.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M264.33,523.32c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,264.33,523.32Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M266,520.13l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11a.46.46,0,0,1-.07.07l-.11.1-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0-.72,0h-.55l-.73,0h-.53l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.55-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1,.27l.36.06c.26,0,.52.09.8.12l.48,0,.75.07h.53l.74,0,.54,0c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M264.25,515.11c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.38,18.38,0,0,1,264.25,515.11Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M279.5,524.24l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09-.68.06-.54,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.37-.06q-.56-.12-1.05-.27c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49,0,.74.07h.54l.73,0,.54,0c.24,0,.49,0,.73,0l.55-.05.67-.06.53-.09c.21,0,.42-.06.63-.11s.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M277.76,519.22c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.38,18.38,0,0,1,277.76,519.22Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M225.55,532.67a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07-.1.1-.16.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36-.05c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12s0,0,0,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M223.8,527.65c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,223.8,527.65Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <polygon points="324.66 295.76 324.62 301.72 190.57 343.45 190.6 337.49 324.66 295.76" style="fill: #5a9581"/>
                                  <path d="M239.05,536.78l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.15s-.31.09-.48.12a5.89,5.89,0,0,1-.63.11l-.52.08-.68.07-.55,0c-.24,0-.48,0-.72,0l-.54,0-.73,0-.54,0-.75-.06-.48,0c-.28,0-.54-.08-.8-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49A10.07,10.07,0,0,0,229,538l.36.06c.27.05.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M237.31,531.76c2.62.81,2.66,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,237.31,531.76Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M239,528.57l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17-.6.16-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.55.66,1.09,2,1.49a10,10,0,0,0,1,.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M237.22,523.55c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.49,18.49,0,0,1,237.22,523.55Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M252.48,532.68a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.44.44,0,0,1-.09.11l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.49a9.25,9.25,0,0,0,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M250.73,527.66c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,250.73,527.66Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path d="M333.64,499.35l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1,0,.08l-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16a5.43,5.43,0,0,1-.61.16c-.16,0-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.37-.05c-.36-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.05.74.06l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M331.89,494.33c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,331.89,494.33Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M347.14,503.46l.09-.12,0-.07a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0,0,.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.15s-.31.09-.48.12l-.63.11-.52.08-.68.07L342,502l-.72,0-.54,0-.73,0-.54,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.05q-.55-.12-1-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.24,0,.36.06.27.05.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M345.4,498.44c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,345.4,498.44Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M347.06,495.25l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a1.25,1.25,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07L346,493l-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.55.66,1.09,2,1.49a10,10,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M345.31,490.23c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.49,18.49,0,0,1,345.31,490.23Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M360.56,499.36a.44.44,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.49a9.25,9.25,0,0,0,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M358.82,494.34c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,358.82,494.34Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M306.61,507.79l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.08.07a.47.47,0,0,1-.1.1l-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05-.72,0h-.55l-.73,0H299l-.75-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27c.11,0,.24,0,.36.06s.52.09.8.12l.48.05.75.07h.53l.73,0,.55,0c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0s0-.05.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M304.86,502.77c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,304.86,502.77Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M320.11,511.9l.09-.11.05-.08.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13l-.05.07-.09.11L320,509a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09-.68.06-.55.05c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.28,0-.53-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27c.12,0,.25,0,.37.06s.52.09.79.12l.48.05.75.07H313l.73,0,.54,0c.24,0,.49,0,.72,0l.55,0,.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0L320,512Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M318.37,506.88c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,318.37,506.88Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M320,503.69l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.54,0-.74,0-.53,0-.75-.07-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54l.73,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17-.05.36-.08.52-.13l.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M318.29,498.67c2.62.81,2.65,2.14.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,318.29,498.67Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M333.54,507.8a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55.06-.73,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.18,1,.26l.37.06.79.13.49,0,.74.07h.54c.24,0,.49,0,.73,0h.55l.72,0,.55-.05.68-.06c.18,0,.34-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11s0,0,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M331.79,502.78c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,331.79,502.78Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <polygon points="165.32 322.27 190.71 330.1 204.07 325.93 178.85 318.13 165.32 322.27" style="fill: #4c4282"/>
                          <polygon points="190.86 286.71 190.82 293.14 165.29 285.22 165.32 278.79 190.86 286.71" style="fill: #3d3777"/>
                          <polygon points="190.71 330.1 190.82 336.6 165.29 328.68 165.32 322.25 190.71 330.1" style="fill: #3d3777"/>
                          <polygon points="190.86 286.71 204.22 282.54 204.22 332.42 190.79 336.6 190.71 330.1 197.65 327.84 197.65 290.93 190.82 293.14 190.86 286.71" style="fill: #6c6cba"/>
                          <polygon points="324.19 295.77 310.83 299.94 310.83 249.76 324.25 245.59 324.33 252.09 316.3 254.35 316.3 291.55 324.22 289.33 324.19 295.77" style="fill: #6c6cba"/>
                          <polygon points="165.32 278.79 190.86 286.71 204.22 282.54 178.85 274.65 165.32 278.79" style="fill: #4c4282"/>
                          <polygon points="197.65 327.84 172.09 320.2 172.09 287.33 190.82 293.14 197.65 290.93 197.65 327.84" style="fill: #392f6e"/>
                        </g>
                        <polygon points="310.83 299.94 284.99 292.1 284.99 256.67 308.72 249.28 310.83 249.76 310.83 299.94" style="fill: #392f6e"/>
                        <g>
                          <g>
                            <path d="M333.6,499.2a.94.94,0,0,0,.09-.12l.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0-.75-.06-.48-.05c-.28,0-.53-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.36.06.27.05.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                            <path d="M331.86,494.18c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,331.86,494.18Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                          </g>
                          <g>
                            <path d="M347.11,503.31a.83.83,0,0,0,.08-.12l.05-.07a.67.67,0,0,0,.06-.12.25.25,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.19.19,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.08.07-.1.1-.16.12-.12.08-.2.11-.13.08-.27.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.13.13-.07.21-.11.11-.08.16-.12,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                            <path d="M345.36,498.29c2.62.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,345.36,498.29Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                          </g>
                        </g>
                        <polygon points="310.83 249.76 308.72 249.28 322.32 245.05 324.25 245.59 310.83 249.76" style="fill: #bfbfbf"/>
                        <polygon points="324.22 289.33 316.19 287.61 316.19 291.55 324.22 289.33" style="fill: #4c4282"/>
                        <g>
                          <g>
                            <g>
                              <polygon points="298.75 231.82 164.69 273.55 139.15 265.63 273.54 223.83 298.75 231.82" style="fill: #6ebaa3"/>
                              <polygon points="164.69 273.55 164.66 279.44 139.12 271.52 139.15 265.63 164.69 273.55" style="fill: #477768"/>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M253.64,452.09a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.25.25,0,0,0,0,.07l-.06.12-.05.07a.94.94,0,0,1-.09.12.46.46,0,0,1-.07.07l-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06L252,450c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.05.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M251.9,447.07c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,251.9,447.07Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M267.15,456.2l.09-.12a.43.43,0,0,0,0-.07.34.34,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.37,1.37,0,0,1,0,.19l0,.06a.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12L266,454l-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49.05.75.06l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.13-.08.21-.11.11-.08.16-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M265.4,451.18c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,265.4,451.18Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M267.07,448l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09-.68.06-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27l.36.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.55l.72,0,.55-.05.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0a.2.2,0,0,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M265.32,443c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,265.32,443Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M280.57,452.1l.09-.11,0-.08a.61.61,0,0,0,.05-.12l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11a.46.46,0,0,1-.07.07l-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09-.68.06-.55.05-.72,0h-.54l-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1,.27c.12,0,.24,0,.36.06s.52.09.8.12l.48,0,.75.07h.54l.73,0h.54c.24,0,.48,0,.72,0l.55,0,.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M278.83,447.08c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,278.83,447.08Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M226.66,460.29l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.68.06l-.54.05c-.24,0-.49,0-.73,0h-.54l-.73,0H219l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.49a9.25,9.25,0,0,0,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M224.92,455.27c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3A18.49,18.49,0,0,1,224.92,455.27Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M240.17,464.4l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.07.08-.11.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.55.66,1.09,2,1.49a10,10,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07H233l.74,0h.54c.24,0,.48,0,.72,0l.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M238.42,459.38c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.49,18.49,0,0,1,238.42,459.38Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M240.09,456.19a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l-.05.07-.08.12-.08.07-.1.1-.16.12-.12.08-.2.11-.13.07-.27.13-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06l.79.13.49,0c.25,0,.49.06.75.07l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11.47-.12c.18,0,.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11l0,0a.34.34,0,0,0,.06-.06A.6.6,0,0,0,240.09,456.19Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M238.34,451.18c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,238.34,451.18Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M253.59,460.3a.48.48,0,0,0,.09-.11l0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.07a1.69,1.69,0,0,1-.27.13l-.13.05-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06c.27.05.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11.48-.12.52-.13.09,0,.47-.17.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M251.85,455.29c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,251.85,455.29Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M199.64,468.73a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.06-.73,0-.54,0-.73,0-.54,0-.74-.07-.49,0L190,467l-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54c.24,0,.49,0,.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08,1,1,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M197.89,463.71c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,197.89,463.71Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <polygon points="298.75 231.82 298.71 237.71 164.66 279.44 164.69 273.55 298.75 231.82" style="fill: #5a9581"/>
                                  <path d="M213.14,472.84l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.08,1.08,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.54,0-.74,0-.53,0-.75-.07-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07H206c.25,0,.49,0,.74,0h.54c.24,0,.48,0,.72,0l.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08L213,473a.1.1,0,0,1,0,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M211.39,467.82c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.49,18.49,0,0,1,211.39,467.82Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M213.06,464.64l.09-.12a.43.43,0,0,0,0-.07.34.34,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.37,1.37,0,0,1,0,.19l0,.06a.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M211.31,459.62c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,211.31,459.62Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M226.56,468.75a.94.94,0,0,0,.09-.12.18.18,0,0,0,0-.07l.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.12,0,.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06A.46.46,0,0,0,226.56,468.75Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M224.82,463.73c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,224.82,463.73Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path d="M307.72,435.41a.31.31,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.06-.73,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M306,430.39c2.62.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,306,430.39Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M321.23,439.52l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.54,0-.74,0-.53,0-.75-.07-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53c.25,0,.49,0,.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M319.48,434.5c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.49,18.49,0,0,1,319.48,434.5Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M321.15,431.32l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49.05.75.06l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.13-.08.21-.11.11-.08.16-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M319.4,426.3c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,319.4,426.3Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M334.65,435.43a.94.94,0,0,0,.09-.12l.05-.07.06-.12a.25.25,0,0,1,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.28,0-.53-.08-.8-.13-.11,0-.24,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.05.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06A.46.46,0,0,0,334.65,435.43Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M332.91,430.41c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,332.91,430.41Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M280.7,443.85a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.13.07-.27.13-.14.05c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06l.79.13.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08L278,445l.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08.91.91,0,0,0,.16-.11l0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M279,438.83c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.6,18.6,0,0,1,279,438.83Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M294.2,448l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.07a2.24,2.24,0,0,1-.27.13l-.13.05c-.15.06-.31.12-.48.17l-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.8.13.48,0c.25,0,.5.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11.48-.12c.17,0,.36-.08.52-.13l.08,0c.17-.05.33-.11.48-.17l.13-.06a1.31,1.31,0,0,0,.27-.12l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M292.46,443c2.62.8,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,292.46,443Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M294.12,439.76l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12l0,.08-.09.11-.07.07-.11.1-.16.12-.11.08a1.39,1.39,0,0,1-.21.11l-.13.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0l-.54,0-.74,0c-.18,0-.36,0-.53,0s-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.05c.26.05.52.1.8.13l.48.05c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.14-.08.2-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M292.37,434.74c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.38,18.38,0,0,1,292.37,434.74Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M307.62,443.87l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.48.12a6.17,6.17,0,0,1-.62.11l-.53.09-.68.06-.54.05c-.24,0-.49,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0s-.5,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06q-.56-.12-1.05-.27c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.05c.26.05.52.1.79.13l.49.05c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M305.88,438.85c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,305.88,438.85Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon points="324.64 239.8 190.59 281.52 165.05 273.6 299.43 231.8 324.64 239.8" style="fill: #6ebaa3"/>
                              <polygon points="190.59 281.52 190.55 287.45 165.01 279.53 165.05 273.6 190.59 281.52" style="fill: #477768"/>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M279.54,460.06l.08-.11.05-.07a1.39,1.39,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05c-.24,0-.48,0-.72,0h-.55l-.73,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.73,0h.55l.72,0,.55-.05.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M277.79,455c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,277.79,455Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M293,464.17l.09-.11.05-.07.06-.13a.14.14,0,0,1,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1.05.27c.12,0,.25,0,.37.06s.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55-.05.67-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M291.3,459.15c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,291.3,459.15Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M293,456l.09-.11.05-.07a.5.5,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1,0,.13l-.05.07L293,453l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.54,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M291.22,450.94c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,291.22,450.94Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M306.47,460.07a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09l-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07.54,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.34-.06.52-.09l.63-.1.47-.12.53-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M304.72,455.05c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,304.72,455.05Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M252.56,468.27l.09-.12,0-.07a.31.31,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12l-.05.07a.83.83,0,0,1-.08.12l-.07.07-.11.1-.16.12-.11.08-.21.11-.13.07-.27.13-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05a2.24,2.24,0,0,0,.27-.13l.13-.07.21-.11.11-.09a.91.91,0,0,0,.16-.11l.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M250.81,463.25c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,250.81,463.25Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M266.06,472.38a.94.94,0,0,0,.09-.12l.05-.07.06-.13s0,0,0-.06a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.14.14,0,0,0,0,.07l-.06.12-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.07a1.69,1.69,0,0,1-.27.13l-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.62.11-.53.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.09a.83.83,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M264.32,467.36c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,264.32,467.36Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M266,464.17l.09-.11.05-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.15s-.31.09-.48.12a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0l-.54,0-.73,0c-.18,0-.36,0-.54,0l-.75-.06-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.05c.27.05.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M264.24,459.15c2.62.81,2.66,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,264.24,459.15Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M279.49,468.28l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.73,0l-.54,0-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36,0c.26.05.52.1.79.13l.49,0c.25,0,.49.05.74.06l.54,0,.73,0,.55,0c.24,0,.48,0,.72,0l.55,0,.68-.07.52-.08c.21,0,.43-.06.63-.11s.31-.08.47-.12.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M277.74,463.26c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,277.74,463.26Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M225.53,476.71l.09-.12,0-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a1.48,1.48,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M223.78,471.69c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,223.78,471.69Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <polygon points="324.64 239.8 324.61 245.72 190.55 287.45 190.59 281.52 324.64 239.8" style="fill: #5a9581"/>
                                  <path d="M239,480.82a.47.47,0,0,0,.09-.12.18.18,0,0,0,0-.07l.06-.12a.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.18.18,0,0,1,0,.07.94.94,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0,.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M237.29,475.8c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,237.29,475.8Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M239,472.61l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06q-.56-.12-1.05-.27c-1.32-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.37.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55-.05.67-.06.53-.09.62-.1.48-.13.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M237.21,467.59c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,237.21,467.59Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M252.46,476.72a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06q-.56-.12-1-.27c-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.06c.26,0,.52.09.8.12l.48,0,.75.07h.53l.73,0h.55c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M250.71,471.7c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,250.71,471.7Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path d="M333.62,443.39l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.14-.08.2-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M331.87,438.37c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,331.87,438.37Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M347.12,447.5a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M345.38,442.48c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,345.38,442.48Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M347,439.29l.09-.11.05-.07.06-.13a.14.14,0,0,1,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07L346,437l-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1.05.27l.37.06c.26,0,.52.09.79.12l.48.05.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55,0,.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17L346,440l.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M345.3,434.27c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,345.3,434.27Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M360.55,443.4a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.1.09-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.17-1.05-.27-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1.05.27l.36.06c.26,0,.52.09.79.12l.49.05.75.07h.53l.73,0h.55c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09.63-.1.47-.13c.18,0,.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.05-.07A.27.27,0,0,0,360.55,443.4Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M358.8,438.38c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,358.8,438.38Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M306.59,451.83l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12.35.35,0,0,0-.05.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.17c-.19.06-.4.1-.6.15s-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0l-.54,0-.74,0c-.17,0-.36,0-.53,0s-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.12s-.24,0-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.05c.26.05.52.1.8.13l.48.05.75.06.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.48-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M304.84,446.81c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.38,18.38,0,0,1,304.84,446.81Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M320.1,455.94l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11L320,453a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.26.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.53.09-.67.06-.55,0c-.24,0-.48,0-.73,0l-.54,0-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.05c.26.05.52.1.79.13l.49.05c.25,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08c.21,0,.43-.06.63-.11s.31-.08.47-.12l.53-.13.08,0,.47-.17.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0A.34.34,0,0,0,320,456Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M318.35,450.92c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,318.35,450.92Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M320,447.73l.09-.11,0-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13,0,.07a.48.48,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.54.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13L310,446c-.37-.08-.72-.16-1-.26-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M318.27,442.71c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3A18.49,18.49,0,0,1,318.27,442.71Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M333.52,451.84l.09-.11,0-.07a.38.38,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.75.75,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M331.77,446.82c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.49,18.49,0,0,1,331.77,446.82Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon points="350.48 247.79 216.43 289.52 190.89 281.59 325.27 239.79 350.48 247.79" style="fill: #6ebaa3"/>
                              <polygon points="216.43 289.52 216.4 295.46 190.86 287.54 190.89 281.59 216.43 289.52" style="fill: #477768"/>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M305.38,468.05l.09-.11a.18.18,0,0,1,0-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0H299l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06a10,10,0,0,1-1.05-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M303.64,463c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,303.64,463Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M318.89,472.16a.76.76,0,0,0,.08-.11l0-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l0,.07a.76.76,0,0,1-.08.11l-.08.08-.11.09-.15.12a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06a9.25,9.25,0,0,1-1-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27l.36.06.79.13.49,0,.74.07h.54l.73,0H313c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M317.14,467.14c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.8-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,317.14,467.14Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M318.8,464a.31.31,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1a.83.83,0,0,1-.15.11l-.12.09-.2.11-.14.07a1.11,1.11,0,0,1-.27.12l-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06.79.13.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07c.18,0,.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M317.06,458.93c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,317.06,458.93Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M332.31,468.06l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.12a.46.46,0,0,0-.07.07l-.11.1a.91.91,0,0,1-.16.11l-.11.09-.21.11-.13.07a1.31,1.31,0,0,1-.27.12l-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07c.18,0,.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.14-.07.2-.12.11-.08a.91.91,0,0,0,.16-.11l.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M330.56,463c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,330.56,463Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M278.4,476.26l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.24,0,.36.06.27.05.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M276.66,471.24c2.62.8,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,276.66,471.24Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M291.91,480.37a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.25.25,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.14.14,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.83.83,0,0,1-.08.12l-.08.07-.11.1-.15.12-.12.08-.2.11-.13.08-.27.12-.14.05c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.87,9.87,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.13.13-.07.2-.11.12-.08.16-.12,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M290.16,475.35c2.62.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,290.16,475.35Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M291.82,472.16l.09-.11a.35.35,0,0,0,0-.08l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06L292,469l0,.07-.09.11a.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.11c-.21.05-.42.08-.63.11l-.53.09-.67.06-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49,0,.74.07h.54l.73,0,.54,0c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.15-.12.05,0s0-.05.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M290.08,467.14c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,290.08,467.14Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M305.33,476.27l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a1.25,1.25,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.07-.11.1-.16.12-.11.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12s-.25,0-.36-.06q-.55-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1,.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.74,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M303.58,471.25c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.38,18.38,0,0,1,303.58,471.25Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M251.37,484.7a.94.94,0,0,0,.09-.12l0-.07.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.12,0,.08-.09.11-.07.07a.52.52,0,0,1-.11.1L251,482l-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16a6.06,6.06,0,0,1-.61.16c-.16,0-.31.09-.48.12L248,483l-.53.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.28,0-.53-.08-.8-.13l-.36-.05q-.56-.12-1-.27c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07L248,486l.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11L251,485l.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M249.63,479.68c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,249.63,479.68Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <polygon points="350.48 247.79 350.45 253.74 216.4 295.46 216.43 289.52 350.48 247.79" style="fill: #5a9581"/>
                                  <path d="M264.88,488.81a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07a.47.47,0,0,1-.1.1l-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16a5.43,5.43,0,0,1-.61.16c-.16,0-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49.05.75.06l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0a.34.34,0,0,1,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M263.13,483.79c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,263.13,483.79Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M264.8,480.6a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.11.09-.15.12a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.36-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.5v3c0,.54.67,1.09,2,1.49.32.1.68.19,1.05.27l.36.06.79.13.49,0,.74.07h.54l.73,0H259c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M263.05,475.58c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.38,18.38,0,0,1,263.05,475.58Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M278.3,484.71l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.1L278,482l-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.11-.6.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.5v3c0,.54.66,1.09,2,1.49a10,10,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.24,0,.48,0,.72,0l.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12L278,485l.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M276.56,479.69c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,276.56,479.69Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path d="M359.46,451.38a.94.94,0,0,0,.09-.12l.05-.07.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0-.75-.06-.48,0c-.28,0-.53-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06A.46.46,0,0,0,359.46,451.38Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M357.72,446.36c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,357.72,446.36Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M373,455.49l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.08.07a.94.94,0,0,1-.1.1l-.16.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16a5.43,5.43,0,0,1-.61.16c-.16,0-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.36,0c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.25,0,.49.05.75.06l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M371.22,450.47c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,371.22,450.47Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M372.89,447.28a.76.76,0,0,0,.08-.11l0-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13,0,.07a.44.44,0,0,1-.09.11l-.07.08-.11.09-.15.12a.5.5,0,0,1-.12.08l-.2.12-.14.07-.26.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.5v3c0,.54.67,1.09,2,1.49.32.1.68.19,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0H367c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M371.14,442.26c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.38,18.38,0,0,1,371.14,442.26Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M386.39,451.39l.09-.11a.18.18,0,0,1,0-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17-.6.16-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0H380l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.5v3c0,.54.66,1.09,2,1.49q.49.15,1,.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M384.64,446.37c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.38,18.38,0,0,1,384.64,446.37Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="M332.43,459.82l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.11a6.17,6.17,0,0,1-.62.11l-.53.09-.68.06-.54,0c-.24,0-.49,0-.73,0H326l-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06q-.56-.12-1.05-.27c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49.05.74.07h.54l.73,0,.54,0c.24,0,.49,0,.73,0l.55-.05.67-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M330.69,454.8c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,330.69,454.8Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M345.94,463.93l.09-.11,0-.08a.34.34,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a1.39,1.39,0,0,1-.06.13l-.05.07-.08.11a.46.46,0,0,1-.07.07l-.11.1-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0h-.55l-.73,0h-.53l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12s-.25,0-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.74,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M344.19,458.91c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,344.19,458.91Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M345.86,455.72a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08a.94.94,0,0,0-.1.1l-.16.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.46,0-.68.06l-.55.06-.73,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.79.13.49,0,.75.07h.53l.73,0H340c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12c.18-.05.37-.08.53-.14l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M344.11,450.7c2.62.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,344.11,450.7Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                                <g>
                                  <path d="M359.36,459.83l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.54,0-.73,0-.54,0-.75-.07-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12c.17,0,.36-.08.52-.14l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                                  <path d="M357.62,454.81c2.62.81,2.65,2.14.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,357.62,454.81Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon points="220.06 260.05 267.8 246.11 262.93 207.04 261.28 205.86 215.17 219.21 220.06 260.05" style="fill: #545454"/>
                          <polygon points="221.69 259.09 267.8 244.74 262.93 207.04 216.82 220.4 221.69 259.09" style="fill: #2f2f2f"/>
                        </g>
                        <polygon points="220.17 260.05 243.55 269.49 289.12 255.59 289.12 253.77 267.8 244.74 220.17 260.05" style="fill: #545454"/>
                        <polygon points="221.69 259.09 243.66 267.71 289.12 253.77 267.8 244.74 221.69 259.09" style="fill: #6d6d6d"/>
                      </g>
                      <g>
                        <path d="M249.1,476.56h0v-4.22a.84.84,0,0,0,0,.27l0,.08a1.51,1.51,0,0,0,.09.18.33.33,0,0,0,.06.1l.13.17.1.1.16.14c.06.06.14.11.21.17l.17.11a2.69,2.69,0,0,0,.29.16l.19.11.38.17.2.08a5.22,5.22,0,0,0,.67.24c.26.09.57.15.86.23l.67.17c.29.06.6.1.9.15s.48.09.74.12.64.06,1,.09.52.06.78.07l1,0,.77,0c.35,0,.69,0,1,0l.76,0c.36,0,.71-.06,1.06-.1s.47,0,.69-.06c.39-.05.76-.12,1.13-.18.17,0,.35-.05.52-.09a14.54,14.54,0,0,0,1.48-.37c1.89-.58,2.84-1.35,2.84-2.12v1.36h0v3h0v4.22c0,.77-.95,1.54-2.84,2.12a14.54,14.54,0,0,1-1.48.37c-.17,0-.35.06-.52.09-.37.06-.74.13-1.13.18-.22,0-.46,0-.69.06s-.7.08-1.06.1l-.76,0c-.35,0-.69,0-1,0l-.77,0-1,0c-.26,0-.52-.05-.78-.07s-.65-.05-1-.09-.5-.08-.75-.12-.6-.09-.89-.15-.45-.12-.67-.17-.52-.12-.75-.19l-.12,0-.67-.24-.19-.08-.38-.17-.19-.11-.29-.16-.17-.12-.22-.16-.06-.05-.09-.09-.1-.11a1,1,0,0,1-.13-.16l-.07-.1-.08-.18,0-.09a.75.75,0,0,1,0-.26v-3h0Z" transform="translate(-42.09 -202.78)" style="fill: #d13d24"/>
                        <path d="M245.31,451.28h26.41v23.51h0c0,1.07-1.31,2.14-4,2.95a36.58,36.58,0,0,1-18.67-.05c-2.53-.8-3.79-1.85-3.79-2.9h0Z" transform="translate(-42.09 -202.78)" style="fill: #dc5240"/>
                        <path d="M249.25,448.26c-5.19,1.6-5.26,4.22-.15,5.85a36.47,36.47,0,0,0,18.67.06c5.21-1.61,5.28-4.22.16-5.86A36.61,36.61,0,0,0,249.25,448.26Z" transform="translate(-42.09 -202.78)" style="fill: #ff816e"/>
                        <path d="M251.52,449.21c-3.94.93-4,2.46-.12,3.4a35.9,35.9,0,0,0,14.16,0c3.94-.93,4-2.45.11-3.39A35.85,35.85,0,0,0,251.52,449.21Z" transform="translate(-42.09 -202.78)" style="fill: #af2f1e"/>
                      </g>
                      <g>
                        <path d="M251.33,484.71l.09-.11.05-.07a1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.09L251,482l-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.73,0h-.54l-.75-.07-.48,0-.8-.13-.36-.06a10,10,0,0,1-1.05-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.27,0,.52.09.8.12l.48.05.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55-.05.68-.06L248,486l.62-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12L251,485l.15-.12,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                        <path d="M249.59,479.69c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,249.59,479.69Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                      </g>
                      <g>
                        <path d="M278.26,484.71l.09-.11a.18.18,0,0,1,0-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.14.06-.47.17-.6.16-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06a10,10,0,0,1-1-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1,.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.74,0h.54c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                        <path d="M276.51,479.69c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,276.51,479.69Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                      </g>
                      <g>
                        <path d="M373.13,445.1a9.63,9.63,0,0,1,3.58,5.61c.88,3.81-1.79,10.78-1.79,10.78Z" transform="translate(-42.09 -202.78)" style="fill: #c13333"/>
                        <polygon points="310.39 337.47 271.19 349.44 271.19 320.61 310.39 305.57 310.39 337.47" style="fill: #7c7c7c"/>
                        <polygon points="300.75 323.55 265.84 311.5 301.6 300.36 300.75 323.55" style="fill: #adadad"/>
                        <g>
                          <path d="M313.08,463.5s3.82,3.09,4.89,6.59c.37,1.21.51,3.87-4.74,6.54a5.52,5.52,0,0,1-7.75-2.2l-1.85-7.36C304.22,463.71,313.08,463.5,313.08,463.5Z" transform="translate(-42.09 -202.78)" style="fill: #f5dfb7"/>
                          <polygon points="277.35 284.37 292.39 280.24 310.39 287.27 296.65 292.7 277.35 284.37" style="fill: #4470b5"/>
                          <path d="M338.73,495.48s1.94-7.26,9.09-4.62c8.63,3.18,8,8.6,8.05,11,0,4.86-3.81,13.65-12.81,10.67s-30-12.69-30-12.69V478.29l6.36,2.49v6.37Z" transform="translate(-42.09 -202.78)" style="fill: #243962"/>
                          <polygon points="286.22 271.73 271 275.55 277.35 278.04 292.39 273.91 286.22 271.73" style="fill: #4470b5"/>
                          <polygon points="292.39 280.24 277.35 284.37 277.35 278.04 292.39 273.91 292.39 280.24" style="fill: #36599c"/>
                          <polygon points="261.33 288.93 276.37 284.8 294.37 291.83 280.63 297.27 261.33 288.93" style="fill: #4470b5"/>
                          <polygon points="289.57 310.05 292.9 313.55 317.82 303.78 316.43 290.23 289.57 295.42 289.57 310.05" style="fill: #132644"/>
                          <g>
                            <polygon points="282.89 284.92 282.95 291.38 298.29 299.77 298.23 292.11 282.89 284.92" style="fill: #243962"/>
                            <polygon points="298.23 292.11 334.57 279.11 334.62 285.72 298.29 299.77 298.23 292.11" style="fill: #2b4789"/>
                            <polygon points="282.18 284.68 287.2 241.89 303.62 248.51 298.23 292.11 282.18 284.68" style="fill: #8e0d0d"/>
                            <polygon points="303.62 248.51 331.14 238.95 335.99 278.68 298.23 292.11 303.62 248.51" style="fill: #ff5f5f"/>
                            <polygon points="287.2 241.89 316.66 232.33 331.14 238.95 303.62 248.51 287.2 241.89" style="fill: #e53e3e"/>
                            <path d="M335.36,441.65" transform="translate(-42.09 -202.78)" style="fill: #323232"/>
                            <path d="M339.85,440.41l0,2.55s.77,3.17,10.17,3.09,10.44-3.27,10.44-3.27l0-4.12Z" transform="translate(-42.09 -202.78)" style="fill: #f0c889"/>
                            <path d="M333,428.54l0,6.79s1.26,7.22,17,7,16.37-5.55,16.35-7.32l-.15-17.5-32.76.28Z" transform="translate(-42.09 -202.78)" style="fill: #f5dfb7"/>
                            <path d="M314.64,465.07a8.05,8.05,0,0,0,7.16-4.76c2.41-4.94,7.45-13.29,13-9s4.64,12.65,3.53,16.31c-.44,1.45-5.67,11.49-11.58,13.05-7.06,1.87-12.88-2.63-13.67-3.25-2.78-2.91-3.5-6.79-2-9.49A6.73,6.73,0,0,1,314.64,465.07Z" transform="translate(-42.09 -202.78)" style="fill: #c13333"/>
                          </g>
                          <path d="M340.88,399.19c-5.87,3-12.68,5.5-11.12,22.13,1.15,12.32,3.13,3,7.2,10.07s7.62,10.29,15.92,9.43c9.24-.95,18.41,2.22,20.87-10.57.91-4.73,1.66-12.65-.52-19.23C369.51,399.85,357,390.85,340.88,399.19Z" transform="translate(-42.09 -202.78)" style="fill: #2f2f2f"/>
                          <path d="M367.62,405.77s7.13,8.56,3.65,25.78C371.27,431.55,372.64,416,367.62,405.77Z" transform="translate(-42.09 -202.78)" style="fill: #545454"/>
                          <path d="M365.43,411.25s4.43,13.25-.55,23.57C364.88,434.82,367.8,420.64,365.43,411.25Z" transform="translate(-42.09 -202.78)" style="fill: #545454"/>
                          <path d="M338,403.05s-7.81,4.8-5.28,16.67C332.68,419.72,332.45,408.11,338,403.05Z" transform="translate(-42.09 -202.78)" style="fill: #545454"/>
                          <path d="M322.71,500.05s1.93-7.27,9.09-4.63c8.63,3.19,8,8.6,8.05,11,0,4.85-3.81,13.65-12.81,10.66s-30-12.69-30-12.69V482.85l6.36,2.5v6.36Z" transform="translate(-42.09 -202.78)" style="fill: #243962"/>
                          <polygon points="270.2 276.3 254.97 280.11 261.33 282.61 276.37 278.47 270.2 276.3" style="fill: #4470b5"/>
                          <polygon points="276.37 284.8 261.33 288.93 261.33 282.61 276.37 278.47 276.37 284.8" style="fill: #36599c"/>
                        </g>
                        <polygon points="344.64 348.69 305.44 360.66 305.44 283.82 344.64 271.85 344.64 348.69" style="fill: #cacaca"/>
                        <polygon points="299.99 281.98 305.44 283.82 344.64 271.85 339.65 269.9 299.99 281.98" style="fill: #adadad"/>
                        <path d="M342.08,484.76V526l-20.83-7.26-7.87-2.7-5.45-1.84V550.4l5.45,1.84V533a2.73,2.73,0,0,1,3.63-2.58l22.38,7.71a4,4,0,0,1,2.69,3.78V561.6l5.45,1.84V486.6Z" transform="translate(-42.09 -202.78)" style="fill: #969696"/>
                      </g>
                      <path d="M252.89,440.46s-7.31-6.68-4.6-22.66,14.26-20.09,11.33-32.4" transform="translate(-42.09 -202.78)" style="fill: none;stroke: #ccc;stroke-linecap: round;stroke-miterlimit: 10"/>
                      <path d="M252.83,419.53c.87-11.6,8.58-16.7,11.42-24.35,2.67-7.21.36-15.81-3.05-22.88" transform="translate(-42.09 -202.78)" style="fill: none;stroke: #ccc;stroke-linecap: round;stroke-miterlimit: 10"/>
                      <path d="M246.35,334.54s2.68,4.06,6.48,1.16c2.63-2,4.22-6.79-.89-10.59-7.9-5.88-17.71,2.49-12.95,15,3.82,10,11.85,12.31,19.47,27.08" transform="translate(-42.09 -202.78)" style="fill: none;stroke: #ccc;stroke-linecap: round;stroke-miterlimit: 10"/>
                      <g>
                        <g>
                          <polygon points="94.53 120.91 94.53 157.37 98.14 157.37 98.14 120.21 94.53 120.91" style="fill: #538b7e"/>
                          <g>
                            <path d="M127.83,325.73c3.18-.12,5.81,1.6,5.9,3.83a3.18,3.18,0,0,1-.37,1.54,5.28,5.28,0,0,1,1.81,1.52,6.41,6.41,0,0,1,6,0c.25.14.48.29.71.45a4.52,4.52,0,0,1,1.58-1.64,3.25,3.25,0,0,1-.52-2.42c.44-2.2,3.32-3.48,6.44-2.86s5.28,2.91,4.84,5.11a3.42,3.42,0,0,1-1.83,2.3c1.29,1.71,1.68,2.35.75,4.09-1.36,2.57-5,3.32-8.11,1.67a8.54,8.54,0,0,1-.78-.48,6.86,6.86,0,0,1-.4.93,6.28,6.28,0,0,1-8.63,2.36,5.79,5.79,0,0,1-3-3.22,8.29,8.29,0,0,1-1.44.43c-3.46.65-6.72-.55-7.26-3.41-.25-1.35.12-1.8,1-2.91a3.74,3.74,0,0,1-2.2-3C122.15,327.75,124.65,325.84,127.83,325.73Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                            <path d="M127.86,324.13c3.17-.12,5.81,1.6,5.89,3.84a3,3,0,0,1-.36,1.53A5.3,5.3,0,0,1,135.2,331a6.35,6.35,0,0,1,6,0,6.46,6.46,0,0,1,.71.44,4.41,4.41,0,0,1,1.58-1.63,3.24,3.24,0,0,1-.52-2.43c.44-2.2,3.32-3.48,6.43-2.86s5.29,2.92,4.85,5.12a3.41,3.41,0,0,1-1.83,2.29c1.29,1.71,1.67,2.35.75,4.09-1.36,2.58-5,3.32-8.11,1.67a6.78,6.78,0,0,1-.78-.48,7,7,0,0,1-.4.94,6.29,6.29,0,0,1-8.63,2.35,5.8,5.8,0,0,1-3-3.21,8.28,8.28,0,0,1-1.44.42c-3.47.65-6.73-.55-7.26-3.41-.26-1.35.12-1.8,1-2.91a3.74,3.74,0,0,1-2.2-3.05C122.17,326.15,124.68,324.24,127.86,324.13Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                            <g>
                              <path d="M127.83,328.73h0l0-1h0l.25-4.57a1.5,1.5,0,0,0,0,.29l0,.1a1.54,1.54,0,0,0,.08.19l.07.12.12.18.11.12.16.16a2.11,2.11,0,0,0,.23.19l.17.14.3.19.21.13.4.21.2.1.72.3c.28.11.61.2.92.29l.72.23c.31.08.64.14,1,.21s.53.13.8.18l1,.16c.28,0,.56.09.85.12l1.11.1.83.07,1.13,0,.83,0c.39,0,.77,0,1.16,0s.5,0,.75,0c.43,0,.83-.08,1.24-.13.18,0,.38,0,.56-.06.58-.09,1.13-.19,1.64-.32,2.08-.52,3.15-1.3,3.2-2.13l-.17,3.11h0l-.1,1.83h0v.17l0,.45h0l-.14,2.5c0,.83-1.12,1.61-3.2,2.13-.51.13-1.06.23-1.64.32l-.56.06c-.41,0-.81.1-1.24.13-.24,0-.5,0-.75,0s-.76,0-1.15,0l-.83,0c-.38,0-.76,0-1.13,0l-.84-.07-1.11-.1c-.29,0-.56-.08-.85-.12l-1-.16c-.28-.05-.54-.12-.8-.18s-.66-.13-1-.21-.49-.15-.73-.23-.55-.15-.8-.25l-.12,0-.71-.3-.21-.1-.4-.21-.2-.12-.31-.2-.17-.13-.23-.2-.07-.06-.09-.1L128,332l-.13-.18-.07-.11c0-.07,0-.14-.07-.2a1,1,0,0,0,0-.1,1,1,0,0,1,0-.29l.09-1.82h0v-.17h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                              <path d="M131.33,321c-4.12,1-4.28,3.07-.37,4.56a28.55,28.55,0,0,0,14.54.83c4.12-1,4.28-3.07.37-4.55A28.55,28.55,0,0,0,131.33,321Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                            </g>
                            <path d="M137.65,324.54a1.7,1.7,0,0,0,.75.19,1.77,1.77,0,0,0,1.83-1.74l0-1.42a1.79,1.79,0,0,0-3.57-.1l0,1.43A1.79,1.79,0,0,0,137.65,324.54Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                            <circle cx="95.78" cy="118.28" r="1.03" style="fill: #538b7e"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M148.89,376.07h0V373h0v-4.59a1.45,1.45,0,0,1,0,.29,1,1,0,0,0,0,.1,1.63,1.63,0,0,1-.09.19.76.76,0,0,1-.08.11l-.13.18-.11.11-.17.15-.24.18c-.06,0-.11.09-.18.13l-.31.18-.21.11-.42.18-.2.1-.73.25c-.29.1-.63.17-.94.25s-.48.13-.73.18-.65.12-1,.17l-.81.13-1.05.1-.85.08-1.11,0-.84,0c-.38,0-.76,0-1.13,0s-.56,0-.83,0-.77-.06-1.15-.1l-.75-.07c-.42-.05-.82-.12-1.23-.2-.18,0-.38-.05-.56-.09-.57-.11-1.11-.25-1.62-.4-2.05-.64-3.08-1.47-3.08-2.3v3.11h0v3.91h0v4.08h0v4.59c0,.83,1,1.67,3.08,2.3.51.15,1,.29,1.62.41.18,0,.38.05.56.09.41.07.81.14,1.23.19.24,0,.5,0,.75.07l1.15.11.83,0c.38,0,.75,0,1.13,0l.84,0,1.11,0,.85-.08c.35,0,.71-.05,1.05-.1s.54-.09.81-.13l1-.16.73-.19.81-.2.13,0,.73-.26.2-.09.42-.19.21-.11.31-.18a.83.83,0,0,0,.18-.13,1.08,1.08,0,0,0,.24-.18l.08-.05.09-.1.11-.11.14-.18.07-.11a1.63,1.63,0,0,0,.09-.19l0-.1a1.35,1.35,0,0,0,0-.29v-3.9h0V380h0Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                            <path d="M145.82,366.07c4.05,1.25,4.1,3.29.12,4.56a28.55,28.55,0,0,1-14.57.05c-4.06-1.25-4.11-3.3-.12-4.57A28.55,28.55,0,0,1,145.82,366.07Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                          </g>
                          <circle cx="96.44" cy="160.4" r="6.1" style="fill: #336052"/>
                          <path d="M148.89,380.14h1.69a2.1,2.1,0,0,0,2.11-2.1v-8.67a5.05,5.05,0,0,1,.49-2.16l5.73-12.11-3.22-1.4-6.8,14.67Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                          <path d="M128.29,380.14H126.6a2.11,2.11,0,0,1-2.11-2.1v-8.67a5,5,0,0,0-.48-2.16l-5.74-12.11,3.23-1.4,6.79,14.67Z" transform="translate(-42.09 -202.78)" style="fill: #538b7e"/>
                        </g>
                        <g>
                          <path d="M170,352.85c-2.75-1.59-5.89-1.31-7,.63a3,3,0,0,0-.39,1.52,5.43,5.43,0,0,0-2.32.5,6.37,6.37,0,0,0-5.27-2.78,7.54,7.54,0,0,0-.83.06,4.6,4.6,0,0,0-.63-2.18,3.29,3.29,0,0,0,1.59-1.9c.64-2.15-1.3-4.63-4.35-5.54s-6,.09-6.67,2.25a3.41,3.41,0,0,0,.54,2.88,5.21,5.21,0,0,0-3.25,4.59c0,2.92,2.86,5.28,6.39,5.28a9.07,9.07,0,0,0,.91-.06,6.65,6.65,0,0,0-.09,1,6.38,6.38,0,0,0,10.7,4.7,8.12,8.12,0,0,0,1.07,1c2.76,2.2,6.47,2.14,8.29-.14a4.78,4.78,0,0,0,.9-3.82,3.71,3.71,0,0,0,3.37-1.66C174.06,357.29,172.74,354.44,170,352.85Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                          <path d="M170,351.14c-2.75-1.59-5.89-1.31-7,.63a3,3,0,0,0-.39,1.52,5.43,5.43,0,0,0-2.32.5A6.37,6.37,0,0,0,155,351a7.54,7.54,0,0,0-.83.06,4.6,4.6,0,0,0-.63-2.18,3.29,3.29,0,0,0,1.59-1.9c.64-2.15-1.3-4.63-4.35-5.54s-6,.09-6.67,2.24a3.42,3.42,0,0,0,.54,2.89,5.21,5.21,0,0,0-3.25,4.59c0,2.92,2.86,5.28,6.39,5.28a9.07,9.07,0,0,0,.91-.06,6.65,6.65,0,0,0-.09,1,6.38,6.38,0,0,0,10.7,4.7,8.12,8.12,0,0,0,1.07,1c2.76,2.2,6.47,2.14,8.29-.14a4.78,4.78,0,0,0,.9-3.82,3.71,3.71,0,0,0,3.37-1.66C174.06,355.58,172.74,352.72,170,351.14Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                          <g>
                            <path d="M168.32,355.2h0l.41-.92h0l1.84-4.21a1.07,1.07,0,0,1-.16.25l-.07.07-.16.15-.12.07-.19.1-.15.07-.21.07-.29.07-.22,0-.35,0-.24,0h-.69c-.24,0-.49,0-.77-.05s-.63-.1-1-.15-.48-.07-.74-.13-.63-.15-.95-.24-.53-.12-.8-.2-.66-.21-1-.32l-.81-.27-1-.42-.78-.31c-.35-.15-.69-.32-1-.48l-.75-.35-1-.56c-.22-.12-.45-.23-.66-.36-.37-.22-.7-.45-1-.67l-.48-.31q-.72-.51-1.32-1c-1.63-1.4-2.24-2.58-1.9-3.34l-1.25,2.85h0l-.73,1.67h0l-.06.15-.18.41h0l-1,2.29c-.33.77.28,1.94,1.9,3.34a15.83,15.83,0,0,0,1.32,1c.15.11.32.21.48.31.34.23.68.45,1.05.67.21.13.44.24.65.36s.67.38,1,.56l.75.35c.34.17.67.33,1,.48s.52.21.78.32l1,.41c.27.1.54.18.81.27s.67.23,1,.32l.8.21c.32.08.64.17.95.23s.5.09.75.13.56.11.83.14h.13c.27,0,.52,0,.77.05h.68l.24,0,.36,0,.21,0,.29-.07.09,0,.13-.05.14-.06.2-.11.11-.07.16-.14.07-.08a.91.91,0,0,0,.16-.24l.72-1.67h0l.07-.15h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                            <path d="M168.67,346.71c3.21,2.77,2.44,4.67-1.72,4.24a28.56,28.56,0,0,1-13.36-5.79c-3.22-2.77-2.45-4.66,1.71-4.23A28.63,28.63,0,0,1,168.67,346.71Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                          </g>
                          <path d="M161.43,347a1.85,1.85,0,0,1-.76-.16,1.79,1.79,0,0,1-.86-2.38l.68-1.25a1.79,1.79,0,0,1,3.24,1.52l-.68,1.25A1.8,1.8,0,0,1,161.43,347Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                          <circle cx="120.71" cy="141.06" r="1.03" style="fill: #538b7e"/>
                        </g>
                        <g>
                          <path d="M106.8,352c2.75-1.58,5.89-1.3,7,.63a3.14,3.14,0,0,1,.39,1.53,5.32,5.32,0,0,1,2.32.5,6.37,6.37,0,0,1,5.26-2.78,5.83,5.83,0,0,1,.84.06,4.56,4.56,0,0,1,.63-2.19,3.33,3.33,0,0,1-1.6-1.89c-.64-2.15,1.31-4.63,4.35-5.54s6,.09,6.68,2.24a3.46,3.46,0,0,1-.54,2.89,5.23,5.23,0,0,1,3.25,4.59c0,2.91-2.86,5.27-6.39,5.27a7,7,0,0,1-.92-.06,5.69,5.69,0,0,1,.09,1,6.38,6.38,0,0,1-10.7,4.7,7.55,7.55,0,0,1-1.07,1c-2.76,2.2-6.46,2.14-8.28-.14a4.78,4.78,0,0,1-.9-3.82,3.76,3.76,0,0,1-3.38-1.66C102.72,356.4,104.05,353.54,106.8,352Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                          <path d="M106.8,350.24c2.75-1.59,5.89-1.3,7,.63a3.14,3.14,0,0,1,.39,1.53,5.32,5.32,0,0,1,2.32.5,6.37,6.37,0,0,1,5.26-2.78,5.83,5.83,0,0,1,.84.06,4.56,4.56,0,0,1,.63-2.19,3.33,3.33,0,0,1-1.6-1.89C121,344,123,341.47,126,340.56s6,.09,6.68,2.24a3.46,3.46,0,0,1-.54,2.89,5.23,5.23,0,0,1,3.25,4.59c0,2.91-2.86,5.27-6.39,5.27a7,7,0,0,1-.92-.06,5.69,5.69,0,0,1,.09,1,6.38,6.38,0,0,1-10.7,4.7,7.55,7.55,0,0,1-1.07,1c-2.76,2.2-6.46,2.14-8.28-.14a4.78,4.78,0,0,1-.9-3.82,3.76,3.76,0,0,1-3.38-1.66C102.72,354.69,104.05,351.83,106.8,350.24Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                          <g>
                            <path d="M108.47,354.31h0l-.4-.93h0l-1.83-4.2a1,1,0,0,0,.16.25l.07.07a1.07,1.07,0,0,0,.16.14l.11.08.2.1.14.06.22.08.29.07.21,0,.36,0,.24,0h.68l.77-.05c.31,0,.64-.1,1-.16l.75-.12c.31-.06.63-.16.95-.24s.52-.12.79-.2l1-.33.81-.26c.35-.13.69-.28,1-.42l.78-.31,1-.48.75-.35c.35-.18.68-.37,1-.56l.66-.36c.37-.22.71-.45,1.05-.68l.48-.3c.47-.34.92-.68,1.32-1,1.62-1.4,2.23-2.58,1.9-3.34l1.24,2.85h0l.73,1.68h0l.07.16.18.41h0l1,2.3c.33.76-.28,1.94-1.91,3.34q-.6.51-1.32,1c-.15.1-.32.2-.47.31-.35.22-.68.45-1.05.67l-.66.36c-.33.19-.66.38-1,.56l-.75.35c-.34.16-.68.33-1,.48l-.77.31c-.35.14-.69.29-1,.42s-.54.17-.81.27-.67.23-1,.32-.53.14-.79.2-.65.18-1,.24-.49.08-.74.13-.57.1-.83.13l-.13,0c-.27,0-.53,0-.77.05h-.69l-.23,0-.36,0-.22,0-.29-.07-.09,0-.12,0-.15-.06-.19-.1-.12-.07-.15-.15a.27.27,0,0,1-.08-.07,1.28,1.28,0,0,1-.15-.25l-.73-1.66h0l-.07-.15h0Z" transform="translate(-42.09 -202.78)" style="fill: #d67d19"/>
                            <path d="M108.12,345.82c-3.22,2.77-2.45,4.66,1.71,4.24a28.6,28.6,0,0,0,13.37-5.79c3.22-2.77,2.45-4.67-1.72-4.24A28.51,28.51,0,0,0,108.12,345.82Z" transform="translate(-42.09 -202.78)" style="fill: #ffc773"/>
                          </g>
                          <path d="M115.36,346.15a1.68,1.68,0,0,0,.75-.17,1.77,1.77,0,0,0,.86-2.37l-.68-1.25a1.78,1.78,0,0,0-3.23,1.51l.68,1.25A1.78,1.78,0,0,0,115.36,346.15Z" transform="translate(-42.09 -202.78)" style="fill: #336052"/>
                          <circle cx="71.9" cy="140.16" r="1.03" style="fill: #538b7e"/>
                        </g>
                      </g>
                      <path d="M345.71,403.05s-6.92,4.95-5.39,12.74C340.32,415.79,342,406.52,345.71,403.05Z" transform="translate(-42.09 -202.78)" style="fill: #545454"/>
                      <g>
                        <g>
                          <polygon points="411.3 347.37 398.65 350.99 373.17 343.09 387.87 339.68 411.3 347.37" style="fill: #f6c578"/>
                          <polygon points="398.65 350.99 398.62 356.84 373.14 348.93 373.17 343.09 398.65 350.99" style="fill: #c27e2a"/>
                        </g>
                        <g>
                          <g>
                            <path d="M433.61,546.19l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.54.05-.73,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.07,2,1.48.32.1.67.19,1,.27l.37.05c.26,0,.52.1.79.13l.48,0c.25,0,.49,0,.75.06l.53,0,.73,0,.54,0,.73,0,.54,0,.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #c27e2a"/>
                            <path d="M431.87,541.18c2.62.81,2.65,2.13.08,3a18.3,18.3,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-2.95A18.41,18.41,0,0,1,431.87,541.18Z" transform="translate(-42.09 -202.78)" style="fill: #f6c578"/>
                          </g>
                          <g>
                            <polygon points="411.3 347.37 411.26 353.21 398.62 356.84 398.65 350.99 411.3 347.37" style="fill: #e8ad52"/>
                            <path d="M447.09,550.29a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.08.07a.94.94,0,0,1-.1.1l-.16.12-.11.08-.21.11-.13.08L446,548l-.14.06-.47.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.62.11-.53.08-.67.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.48q.5.15,1.05.27l.36.06c.26,0,.52.09.79.12l.49.05.74.07H440l.73,0,.54,0c.24,0,.48,0,.72,0l.55,0,.67-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17L446,551l.27-.12.13-.07.21-.12.11-.08.16-.11,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #c27e2a"/>
                            <path d="M445.35,545.28c2.61.81,2.65,2.13.07,3a18.3,18.3,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.56,18.56,0,0,1,445.35,545.28Z" transform="translate(-42.09 -202.78)" style="fill: #f6c578"/>
                          </g>
                        </g>
                      </g>
                      <polygon points="164.65 363.84 140.12 371.16 178.74 379.49 164.65 363.84" style="mix-blend-mode: multiply;opacity: 0.3"/>
                      <g>
                        <g>
                          <polygon points="191.39 370.02 178.74 373.65 153.26 365.74 167.97 362.33 191.39 370.02" style="fill: #f6c578"/>
                          <polygon points="178.74 373.65 178.71 379.49 153.23 371.59 153.26 365.74 178.74 373.65" style="fill: #c27e2a"/>
                        </g>
                        <g>
                          <g>
                            <path d="M213.7,568.85a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.26.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.52.09-.67.06-.55,0c-.24,0-.48,0-.73,0l-.54,0-.73,0h-.53l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.36-.08-.71-.17-1-.27-1.33-.4-2-.94-2-1.48v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.79.13.48,0,.75.07h.53l.73,0h.55l.72,0,.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12c.17,0,.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #c27e2a"/>
                            <path d="M212,563.84c2.62.8,2.66,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.62-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,212,563.84Z" transform="translate(-42.09 -202.78)" style="fill: #f6c578"/>
                          </g>
                          <g>
                            <polygon points="191.39 370.02 191.35 375.87 178.71 379.49 178.74 373.65 191.39 370.02" style="fill: #e8ad52"/>
                            <path d="M227.18,573l.09-.12a.43.43,0,0,0,0-.07.34.34,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.47.12-.63.1-.52.09-.68.06-.55.05-.72,0h-.54l-.73,0h-.54l-.74-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06.8.13.48,0,.74.07h.54c.24,0,.48,0,.73,0h.54c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06s.34-.06.52-.09l.63-.1.47-.12.52-.13.09,0,.47-.17.13,0a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #c27e2a"/>
                            <path d="M225.44,567.94c2.61.8,2.65,2.13.08,2.95a18.56,18.56,0,0,1-9.42,0c-2.62-.81-2.65-2.13-.07-3A18.41,18.41,0,0,1,225.44,567.94Z" transform="translate(-42.09 -202.78)" style="fill: #f6c578"/>
                          </g>
                        </g>
                      </g>
                      <polygon points="190.28 344.13 165.76 351.45 204.37 359.78 190.28 344.13" style="mix-blend-mode: multiply;opacity: 0.3"/>
                      <g>
                        <g>
                          <polygon points="217.04 350.35 204.4 353.98 178.91 346.08 193.62 342.67 217.04 350.35" style="fill: #e53e3e"/>
                          <polygon points="204.4 353.98 204.36 359.83 178.88 351.92 178.91 346.08 204.4 353.98" style="fill: #8e0d0d"/>
                        </g>
                        <g>
                          <g>
                            <path d="M239.35,549.18l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.13.07a1.59,1.59,0,0,1-.27.12l-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.67.06l-.55.05-.72,0H233c-.24,0-.48,0-.73,0h-.53l-.74-.07-.49,0-.79-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.53,0,.74,0,.54,0,.72,0,.55,0,.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #8e0d0d"/>
                            <path d="M237.61,544.17c2.62.81,2.66,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-2.95A18.41,18.41,0,0,1,237.61,544.17Z" transform="translate(-42.09 -202.78)" style="fill: #ff5f5f"/>
                          </g>
                          <g>
                            <polygon points="217.04 350.35 217 356.2 204.36 359.83 204.4 353.98 217.04 350.35" style="fill: #c13333"/>
                            <path d="M252.83,553.28l.09-.11a.18.18,0,0,1,.05-.07A1.12,1.12,0,0,0,253,553l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0-.47.17c-.19.06-.41.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.48,0-.8-.13-.36-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06c.26.05.52.1.8.13l.48.05c.25,0,.49,0,.74.06l.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.07Z" transform="translate(-42.09 -202.78)" style="fill: #8e0d0d"/>
                            <path d="M251.09,548.27c2.62.81,2.65,2.13.08,3a18.45,18.45,0,0,1-9.42,0c-2.62-.81-2.65-2.13-.07-3A18.41,18.41,0,0,1,251.09,548.27Z" transform="translate(-42.09 -202.78)" style="fill: #ff5f5f"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <path d="M264.84,488.89a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08a.94.94,0,0,0-.1.1l-.16.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.06-.55.05c-.24,0-.48,0-.73,0h-.54c-.24,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.18,1,.26l.36.06.79.13.49,0,.74.07h.54l.73,0H259l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12c.18,0,.37-.08.53-.14l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11,0,0,.06-.06Z" transform="translate(-42.09 -202.78)" style="fill: #477768"/>
                        <path d="M263.09,483.87c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,263.09,483.87Z" transform="translate(-42.09 -202.78)" style="fill: #7acbb0"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </v-col>
            <v-col>
              <div class="form-container">
                <form @submit.prevent="register">
                  <label class="label-input" for="email">E-Mail *</label>
                  <v-row no-gutters class="input-container">
                    <input id="email" v-model="email" class="input-field" placeholder="jemand@beispiel.de" type="email" required>
                    <i class="fal fa-user input-icon"></i>
                  </v-row>
                  <label class="label-input" for="nickname">Benutzername *</label>
                  <v-row no-gutters class="input-container">
                    <input id="nickname" v-model="nickname" class="input-field" placeholder="Nickname" required>
                    <i class="fal fa-tag input-icon"></i>
                  </v-row>
                  <label class="label-input" for="password">Passwort *</label>
                  <v-row no-gutters class="input-container">
                    <input id="password" v-model="password" class="input-field" placeholder="Passwort" type="password" required>
                    <i class="fal fa-key input-icon"></i>
                  </v-row>
                  <div class="policy-container">
                    <input class="checkbox" type="checkbox" required>
                    <span class="policy-text">Ich habe die
                      <router-link class="text-link policy" to="/privacy-policy">Datenschutzerklärung</router-link>
                      von brickdex gelesen und akzeptiert.
                    </span>
                  </div>
                  <v-row>
                    <PrimaryButton id="register-button" buttonText="Account anlegen" type="submit" />
                  </v-row>
                  <v-row>
                    <router-link class="text-link login" to="/login">Du hast bereits einen Account?</router-link>
                  </v-row>
                </form>
              </div>
              <v-row>
                <span class="register-message">{{ message }}</span>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PrimaryButton from '../../../components/PrimaryButton.vue';
import router from "../../../plugins/router";

export default {
  name: "Registration",
  components: {
    PrimaryButton
  },
  inject: [
    'api'
  ],
  data() {
    return {
      smallWindow: false,
      windowHeight: '',
      email: '',
      nickname: '',
      password: '',
      message: '',
    }
  },
  mounted() {
    this.onResize()
  },
  methods: {
    register() {
      this.api.transport.post('users', {
        email: this.email,
        nickname: this.nickname,
        password: this.password,
      }).then(() => {
        this.message = this.email + ' erfolgreich angelegt.';
        this.login();
        this.password = '';
        setTimeout(() => {
          router.push('/');
        }, 2000);
      }).catch((err) => {
        this.message = err.message;
      });
    },
    login() {
      this.api.auth.login({
        email: this.email,
        password: this.password,
      });
    },
    onResize () {
      this.smallWindow = window.innerWidth < 970;
      this.windowHeight = window.innerHeight - 220 + 'px';
    },
  }
}
</script>

<style scoped>
section {
  min-height: 650px;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

#clouds {
  position: absolute;
  top: 15rem;
  right: 0;
  bottom: 0;
  left: -8rem;
  height: 50%;
  width: 100%;
  z-index: 1;
}

.card-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-10%);
  z-index: 2;
}

.card {
  width: 900px;
  margin: 0 2rem;
  padding-bottom: 0.5rem;
  background: linear-gradient(135deg, rgba(126, 166, 241, 0.7) 45%, rgba(92, 89, 226, 0.5) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 12px 0 rgb(0, 0, 0, 0.3);
  border-radius: 25px;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 30px;
  font-weight: 800;
  color: rgb(255, 255, 255);
  z-index: 5;
}

.image-column {
  display: flex;
  justify-content: flex-end;
}

.image-container {
  width: 400px;
  height: 350px;
  margin-top: 1.5rem;
  margin-right: 3rem;
}

#sign-up {
  position: relative;
  z-index: 2;
}

label {
  position: relative;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.input-field {
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.v-row {
  margin: 5px;
  padding-bottom: 1rem;
}

#register-button {
  margin: 2rem 0 0.25rem 0;
  width: 100%;
}

.text-link.login {
  font-size: 14px;
  margin-left: 1rem;
  transform: translateY(-1rem);
  z-index: 2;
}

.policy-container {
  display: flex;
}

.checkbox {
  cursor: pointer;
  margin: 0.25rem 0.5rem 0 1rem;
}

.text-link.policy {
  font-size: 14px;
}

.policy-text {
  font-size: 14px;
  color: #ffffff;
}

.register-message {
  font-size: 14px;
  color: #d5d9ff;
  transition: 0.5s ease all;
}

/* MEDIA QUERIES */
@media (min-width: 970px) {
  .header {
    font-size: 44px;
    font-weight: 800;
  }
}

</style>
