<template>
  <LandingPageMobile v-if="isMobile" />
  <LandingPageDesktop v-else />
</template>

<script>
import LandingPageMobile from './LandingPageMobile.vue';
import LandingPageDesktop from './LandingPageDesktop.vue';

export default {
  name: "LandingPageInit",
  components: {
    LandingPageMobile,
    LandingPageDesktop
  },
  inject: [
    'isMobile'
  ],
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>
