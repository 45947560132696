<template>
  <template v-if="isMobile">
    <table>
      <tr>
        <td class="info-list-header text_mobile">Bricklink Id</td>
        <td class="info-list-data text_mobile">{{ minifigure_data.number }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Name</td>
        <td class="info-list-data text_mobile">{{ minifigure_data.name }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Release</td>
        <template v-if="convertDateString(minifigure_data.release_date) !== '-' && convertDateString(minifigure_data.release_date) !== '' && convertDateString(minifigure_data.release_date) !== null">
          <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data"><a :href="getReleaseDateLink(minifigure_data.release_date)" class="table_text_link link text_mobile">{{ convertDateString(minifigure_data.release_date) }}</a></v-chip>
        </template>
        <template v-else>
          <td class="mr-1 mb-1 info-list-data text_mobile">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Themenwelten</td>
        <td>
          <template v-for="theme in minifigure_data.themes" :key="theme">
            <router-link :to="{ path: getThemeLink(theme) }">
              <v-chip v-if="theme" id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data">{{ theme }}</v-chip>
              <span v-else>-</span>
            </router-link>
          </template>
        </td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Unterthemen</td>
        <td>
          <template v-if="minifigure_data.subthemes.length > 0">
            <template v-for="subtheme in minifigure_data.subthemes" :key="subtheme">
              <router-link :to="{ path: '/items/sets-overview', query: { subtheme: subtheme } }">
                <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data">{{ subtheme }}</v-chip>
              </router-link>
            </template>
          </template>
          <template v-else>
            <span class="mr-1 mb-1 info-list-data text_mobile">-</span>
          </template>
        </td>
      </tr>
    </table>
  </template>
  <template v-else>
    <table>
      <tr>
        <td class="info-list-header text_mobile">Bricklink Id</td>
        <td class="info-list-data text_mobile">{{ minifigure_data.number }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Name</td>
        <td class="info-list-data text_mobile">{{ minifigure_data.name }}</td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Release</td>
        <template v-if="convertDateString(minifigure_data.release_date) !== '-' && convertDateString(minifigure_data.release_date) !== '' && convertDateString(minifigure_data.release_date) !== null">
          <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data"><a :href="getReleaseDateLink(minifigure_data.release_date)" class="table_text_link link">{{ convertDateString(minifigure_data.release_date) }}</a></v-chip>
        </template>
        <template v-else>
          <td class="mr-1 mb-1 info-list-data text_mobile">-</td>
        </template>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Themenwelten</td>
        <td>
          <template v-for="theme in minifigure_data.themes" :key="theme">
            <router-link :to="{ path: getThemeLink(theme) }">
              <v-chip v-if="theme" id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ theme }}</v-chip>
              <span v-else>-</span>
            </router-link>
          </template>
        </td>
      </tr>
      <tr>
        <td class="info-list-header text_mobile">Unterthemen</td>
        <td>
          <template v-if="minifigure_data.subthemes.length > 0">
            <template v-for="subtheme in minifigure_data.subthemes" :key="subtheme">
              <router-link :to="{ path: '/items/sets-overview', query: { subtheme: subtheme } }">
                <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ subtheme }}</v-chip>
              </router-link>
            </template>
          </template>
          <template v-else>
            <span class="mr-1 mb-1 info-list-data text_mobile">-</span>
          </template>
        </td>
      </tr>
    </table>
  </template>
</template>

<script>
export default {
  name: 'MinifigureInformationTable',
  props: [ 'minifigure_data' ],
  inject: [
    'isMobile',
    'localCurrency',
    'localLanguage'
  ],
  methods: {
    displayMinifigCount() {
      if (this.set_data.minifig_count) {
        if (this.set_data.minifig_count.count > 0) {
          return this.set_data.minifig_count.count + ' | ' + this.set_data.minifig_count.exclusive + ' excl.' || '';
        }
      }
      return 0;
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    convertDateString(dateString) {
      if (dateString) {
        try {
          let convertedDateString = (new Date(dateString))?.toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'}) ?? '-';
          if (convertedDateString.includes('Invalid')) { convertedDateString = '-'; }
          return convertedDateString;
        }
        catch(err) {
          return '-';
        }
      }
      else {
        return '-';
      }
    },
    formatPackageSize(height, width, depth) {
      if (height || width || depth) {
        let returnHeight = null;
        let returnWidth = null;
        let returnDepth = null;
        if (height) { returnHeight = (height / 10).toFixed(1); }
        if (width) { returnWidth = (width / 10).toFixed(1); }
        if (depth) { returnDepth = (depth / 10).toFixed(1); }
        return returnHeight + ' x ' + returnWidth + ' x ' + returnDepth + ' cm';
      }
      else return '-';
    },
    getThemeLink(theme) {
      if (theme) {
        const themeNoSpecialChars = (((theme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
        const routeTheme = themeNoSpecialChars.toLowerCase();
        return '/themes/' + routeTheme;
      }
    },
    getReleaseDateLink(release_date) {
      if (release_date) {
        try {
          let converted_release_year = (new Date(release_date))?.toLocaleDateString(this.localLanguage, {year: 'numeric'}) ?? '-';
          if (converted_release_year.includes('Invalid')) { converted_release_year = '-'; }
          return '/items/sets-overview?yearRelease=' + converted_release_year;
        }
        catch(err) {
          return '/items/sets-overview';
        }
      }
      else {
        return '/items/sets-overview';
      }
    },
    getEolDateLink(eol_date) {
      if (eol_date) {
        try {
          let converted_eol_date = (new Date(eol_date))?.toLocaleDateString(this.localLanguage, {year: 'numeric'}) ?? '-';
          if (converted_eol_date.includes('Invalid')) { converted_eol_date = '-'; }
          return '/items/sets-overview?yearEol=' + converted_eol_date;
        }
        catch(err) {
          return '/items/sets-overview';
        }
      }
      else {
        return '/items/sets-overview';
      }
    },
    getPriceLink(price) {
      return '/items/sets-overview?retailPriceStart=' + price + '&retailPriceEnd=' + price;
    },
  }
}

</script>

<style scoped>
.info-list-header {
  min-width: 120px;
}

.info-list-data {
  font-size: 14px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.link {
  cursor: pointer;
}

  .link:hover {
    color: #60ffe4;
    transition: 0.5s ease all;
  }

.table_text_link {
  font-size: 14px;
  color: #ffffff;
}

.info_tooltip {
  color: rgba(49, 38, 89, 0.7);
  font-size: 14px;
  cursor: pointer;
  transition: 0.5s ease all;
}

.info_tooltip:hover {
  color: rgba(49, 38, 89, 1);
}

#chip_link {
  font-size: 14px;
  cursor: pointer;
  transition: 0.5s ease all;
}

  #chip_link:hover {
    color: #60ffe4;
  }

#chip_link_mobile {
  font-size: 12px;
  white-space: normal;
  height: 100%;
  padding: 4px 10px;
  cursor: pointer;
  transition: 0.5s ease all;
}

  #chip_link_mobile:hover {
    color: #60ffe4;
  }

.text_mobile {
  font-size: 12px;
}

@media (min-width: 960px) {
  .info-list-header {
    min-width: 150px;
  }
}

@media (min-width: 1280px) {
  .info-list-header {
    min-width: 140px;
  }
}

</style>
