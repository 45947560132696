<template>
  <CollectionMobile v-if="isMobile" />
  <CollectionDesktop v-else />
</template>

<script>
import CollectionMobile from './CollectionMobile.vue';
import CollectionDesktop from './CollectionDesktop.vue';

export default {
  name: "CollectionInit",
  components: {
    CollectionMobile,
    CollectionDesktop
  },
  inject: [
    'isMobile'
  ],
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>
