<template>
  <section>
    <v-container v-if="isMobile" class="pa-0">
      <v-row no-gutters class="row-search-mobile">
        <v-row no-gutters class="row_search_mobile">
          <SearchMobile :buttonIcon="'fa fa-search'" placeholderText="Dein Suchbegriff?" />
        </v-row>
      </v-row>
    </v-container>
    <v-container v-else class="header-container">
      <v-row no-gutters class="mb-6 row-search">
        <Search class="search" buttonText="Suchen" placeholderText="Suchst du ein bestimmtes Set?" />
      </v-row>
    </v-container>

    <v-container class="pa-4" fluid>

      <v-row justify="center" no-gutters>
        <div class="container_text">
          <template v-if="isMobile"><h2>Content is King<i class="mdi mdi-crown" id="icon_crown"></i></h2></template>
          <template v-else><h1>Content is King<i class="mdi mdi-crown" id="icon_crown"></i></h1></template>
          <p v-if="isMobile" class="normal_text text_mobile">Hier findest du immer wieder frischen Content, zum Beispiel in Form von Investment Analysen und vieles mehr!</p>
          <p v-else class="normal_text">Hier findest du immer wieder frischen Content, zum Beispiel in Form von Investment Analysen und vieles mehr!</p>
        </div>
      </v-row>

      <template v-if="isMobile">
        <v-row class="mb-4" justify="center" no-gutters>
          <v-slide-group v-model="model" class="slider_group" show-arrows>
            <v-slide-group-item  v-for="item in insightsData" :key="item">
              <template v-if="item.link.includes('ranking') && item.status === 'published'">
                <template v-if="loggedIn.value">
                  <router-link :to="{ path: item.link + item.minifigureShortcut }">
                    <div class="card_main card_analysis_tile_mobile mx-2 my-6">
                      <h3 class="card_eyebrow mobile ml-4 mt-2">{{ item.eyebrow_text }}</h3>
                      <span class="title_text mobile ml-4 mr-2 mt-2">{{ item.title }}</span>
                      <span class="subtitle_text mobile ml-4 mr-2 mt-1">{{ item.subtitle }}</span>
                      <v-img class="image mt-2" :src="getImagePath(item.thumbnail)"></v-img>
                    </div>
                  </router-link>
                </template>
                <template v-else>
                  <div class="card_main card_analysis_tile_mobile mx-2 my-6">
                    <h3 class="card_eyebrow mobile ml-4 mt-2">{{ item.eyebrow_text }}</h3>
                    <span class="title_text mobile ml-4 mr-2 mt-2">{{ item.title }}</span>
                    <p class="hint_text_mobile ml-4 mt-2">
                      Zu den Minifiguren Rankings kommst du, wenn du
                      <router-link class="text-link mobile" to="/registration">registriert</router-link>
                      bzw. <br>
                      <router-link class="text-link mobile" to="/login">eingeloggt</router-link>
                      bist.
                    </p>
                  </div>
                </template>
              </template>
              <template v-if="item.link.includes('analysis') && item.status === 'published'">
                <router-link v-if="item.slug_identifier !== 0" :to="{ path: '/insights/analysis/' + item.id + '/item/' + item.slug_identifier }">
                  <div class="card_main card_analysis_tile_mobile mx-2 my-6">
                    <h3 class="card_eyebrow mobile ml-4 mt-2">{{ item.eyebrow_text }}</h3>
                    <span class="title_text mobile ml-4 mr-2 mt-2">{{ item.title }}</span>
                    <span class="subtitle_text mobile ml-4 mr-2 mt-1">{{ item.subtitle }}</span>
                    <v-img class="image mt-2" :src="getImagePath(item.thumbnail)"></v-img>
                  </div>
                </router-link>
              </template>
              <template v-if="item.link.includes('content') && item.status === 'published'">
                <router-link :to="{ path: item.link + item.id }">
                  <div class="card_main card_analysis_tile_mobile mx-2 my-6">
                    <h3 class="card_eyebrow mobile ml-4 mt-2">{{ item.eyebrow_text }}</h3>
                    <span class="title_text mobile ml-4 mr-2 mt-2">{{ item.title }}</span>
                    <span class="subtitle_text mobile ml-4 mr-2 mt-1">{{ item.subtitle }}</span>
                    <v-img class="image mt-2" :src="getImagePath(item.thumbnail)"></v-img>
                  </div>
                </router-link>
              </template>
            </v-slide-group-item>
          </v-slide-group>
        </v-row>
      </template>
      <template v-else>
        <v-row class="mb-4" justify="center" no-gutters>
          <v-slide-group v-model="model" class="px-4 slider_group" show-arrows>
            <v-slide-group-item  v-for="item in insightsData" :key="item">
              <template v-if="item.link.includes('ranking') && item.status === 'published'">
                <template v-if="loggedIn.value">
                  <router-link :to="{ path: item.link + item.minifigureShortcut }">
                    <div class="card_main card_analysis_tile mx-6 my-8">
                      <h3 class="card_eyebrow ml-6 mt-2">{{ item.eyebrow_text }}</h3>
                      <span class="title_text mx-6 mt-4">{{ item.title }}</span>
                      <span class="subtitle_text mx-6 mt-2">{{ item.subtitle }}</span>
                      <v-img class="image mt-6" :src="getImagePath(item.thumbnail)"></v-img>
                    </div>
                  </router-link>
                </template>
                <template v-else>
                  <div class="card_main card_analysis_tile mx-6 my-8">
                    <h3 class="card_eyebrow ml-6 mt-2">{{ item.eyebrow_text }}</h3>
                    <span class="title_text mx-6 mt-4">{{ item.title }}</span>
                    <p class="hint_text ml-6 mt-4 mr-2">
                      Zu den Minifiguren Rankings kommst du, wenn du
                      <router-link class="text-link" to="/registration">registriert</router-link>
                      bzw. <br>
                      <router-link class="text-link" to="/login">eingeloggt</router-link>
                      bist.
                    </p>
                  </div>
                </template>
              </template>
              <template v-if="item.link.includes('analysis') && item.status === 'published'">
                <router-link v-if="item.slug_identifier !== 0" :to="{ path: '/insights/analysis/' + item.id + '/item/' + item.slug_identifier }">
                  <div class="card_main card_analysis_tile mx-6 my-8">
                    <h3 class="card_eyebrow ml-6 mt-2">{{ item.eyebrow_text }}</h3>
                    <span class="title_text mx-6 mt-4">{{ item.title }}</span>
                    <span class="subtitle_text mx-6 mt-2">{{ item.subtitle }}</span>
                    <v-img class="image mt-6" :src="getImagePath(item.thumbnail)"></v-img>
                  </div>
                </router-link>
              </template>
              <template v-if="item.link.includes('content') && item.status === 'published'">
                <router-link :to="{ path: item.link + item.id }">
                  <div class="card_main card_analysis_tile mx-6 my-8">
                    <h3 class="card_eyebrow ml-6 mt-2">{{ item.eyebrow_text }}</h3>
                    <span class="title_text mx-6 mt-4">{{ item.title }}</span>
                    <span class="subtitle_text mx-6 mt-2">{{ item.subtitle }}</span>
                    <v-img class="image mt-6" :src="getImagePath(item.thumbnail)"></v-img>
                  </div>
                </router-link>
              </template>
            </v-slide-group-item>
          </v-slide-group>
        </v-row>
      </template>

      <v-row justify="center" class="mb-4" no-gutters>
        <div class="container_text">
          <template v-if="isMobile"><h2>Sets in Collections</h2></template>
          <template v-else><h1>Sets in Collections</h1></template>
          <p v-if="isMobile" class="normal_text text_mobile">Von der Community für die Community!<br>
            Hier findest du die Anzahl an Sets, welche die User von brickdex in Ihren Collections gepflegt haben.<br>
            Einmal zusammengefasst je Themenwelt und für das einzelne Set.
          </p>
          <p v-else class="normal_text">Von der Community für die Community!<br>
            Hier findest du die Anzahl an Sets, welche die User von brickdex in Ihren Collections gepflegt haben.<br>
            Einmal zusammengefasst je Themenwelt und für das einzelne Set.
          </p>
        </div>
      </v-row>


      <template v-if="isMobile">
        <template v-if="loggedIn.value">
          <v-row justify="center" class="mb-4" no-gutters>
            <div class="card_main mobile">
              <div class="card-header">
                <span class="header-text header_mobile">nach Themenwelt</span>
              </div>
              <template v-if="loadingActive">
                <div class="card_body_loader">
                  <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
                </div>
              </template>
              <template v-else>
                <div class="ml-6 mb-6 card_in_collection mobile">
                  <template v-for="themeData in inCollectionData" :key="themeData.theme">
                    <v-row no-gutters class="row_analysis_data">
                      <v-col cols="7">
                        <router-link class="" :to="{ path: '/themes/' + getThemeLink(themeData.theme) }">
                          <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data">{{ themeData.theme }}</v-chip>
                        </router-link>
                      </v-col>
                      <v-col cols="5">
                        <label class="label label_row_analysis">{{ themeData.inCollectionCount }}</label>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </template>
            </div>
          </v-row>
          <v-row justify="center" no-gutters>
            <div class="card_main mobile">
              <div class="card-header">
                <span class="header-text header_mobile">nach Set</span>
              </div>
              <template v-if="loadingActive">
                <div class="card_body_loader">
                  <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
                </div>
              </template>
              <template v-else>
                <div class="ml-6 mb-6 card_in_collection mobile">
                  <template v-for="setData in inCollectionDataSets" :key="setData.set">
                    <v-row no-gutters class="row_analysis_data">
                      <v-col cols="7" class="pr-4">
                        <router-link class="" :to="{ path: '/items/sets/' + getThemeLink(setData.set) }">
                          <v-chip id="chip_link_mobile" size="small" class="mr-1 mb-1 info-list-data">{{ setData.set.split('-')[0] }} {{ setData.name }}</v-chip>
                        </router-link>
                      </v-col>
                      <v-col cols="5">
                        <label class="label label_row_analysis">{{ setData.inCollectionCount }}</label>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </template>
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row class="ma-4" justify="center" no-gutters>
            <div class="ma-4 pa-4 card_main card_register">
              <span class="hint_text_mobile">
                Um die Cummunity-Daten einsehen und genießen zu können, musst Du
                <router-link class="text-link mobile" to="/registration">registriert</router-link>
                sein.<br>
                Hast Du bereits einen Account, dann
                <router-link class="text-link mobile" to="/login">melde</router-link>
                Dich einfach an.
              </span>
            </div>
          </v-row>
        </template>
      </template>
      <template v-else>
        <template v-if="loggedIn.value">
          <v-row class="my-4" justify="center" no-gutters>
            <div class="ma-4 card_main">
              <div class="card-header">
                <span class="header-text">nach Themenwelt</span>
              </div>
              <template v-if="loadingActive">
                <div class="card_body_loader">
                  <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
                </div>
              </template>
              <template v-else>
                <div class="ml-6 mb-6 card_in_collection">
                  <template v-for="themeData in inCollectionData" :key="themeData.theme">
                    <v-row no-gutters class="row_analysis_data">
                      <v-col cols="7">
                        <router-link class="" :to="{ path: '/themes/' + getThemeLink(themeData.theme) }">
                          <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ themeData.theme }}</v-chip>
                        </router-link>
                      </v-col>
                      <v-col cols="5">
                        <label class="label label_row_analysis">{{ themeData.inCollectionCount }}</label>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </template>
            </div>
            <div class="ma-4 card_main">
              <div class="card-header">
                <span class="header-text">nach Set</span>
              </div>
              <template v-if="loadingActive">
                <div class="card_body_loader">
                  <v-progress-linear :active="loadingActive" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
                </div>
              </template>
              <template v-else>
                <div class="ml-6 mb-6 card_in_collection">
                  <template v-for="setData in inCollectionDataSets" :key="setData.set">
                    <v-row no-gutters class="row_analysis_data">
                      <v-col cols="7" class="pr-4">
                        <router-link class="" :to="{ path: '/items/sets/' + getThemeLink(setData.set) }">
                          <v-chip id="chip_link" size="small" class="mr-1 mb-1 info-list-data">{{ setData.set.split('-')[0] }} {{ setData.name }}</v-chip>
                        </router-link>
                      </v-col>
                      <v-col cols="5">
                        <label class="label label_row_analysis">{{ setData.inCollectionCount }}</label>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </template>
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row class="ma-4" justify="center" no-gutters>
            <div class="ma-4 pa-4 card_main card_register">
              <span class="hint_text">
                Um die Cummunity-Daten einsehen und genießen zu können, musst Du
                <router-link class="text-link" to="/registration">registriert</router-link>
                sein.<br>
                Hast Du bereits einen Account, dann
                <router-link class="text-link" to="/login">melde</router-link>
                Dich einfach an.
              </span>
            </div>
          </v-row>
        </template>
      </template>


    </v-container>

  </section>
</template>

<script>
import Search from '../../components/Search.vue';
import SearchMobile from '../../components/SearchMobile.vue';

export default {
  name: 'InsightsView',
  components: {
    Search,
    SearchMobile
  },
  inject: [
    'api',
    'isMobile',
    'loggedIn',
  ],
  data() {
    return {
      model: null,
      loadingActive: false,
      insightsData: [],
      inCollectionData: [],
      inCollectionDataSets: [],
    }
  },
  created() {
    this.getInsightData();
    this.getInCollectionData();
  },
  unmounted() {

  },
  methods: {
    async getInsightData() {
      this.loadingActive = true;
      this.insightsData = [];
      let filter = {}
      filter['status'] = {"_eq": 'published' }

      const res = await this.api.items('insights?fields=*.*,category.insights_category_id.insights_category_translations.*,category.insights_category_id.*'
      + '&limit=5'
      + '&sort=-date').readByQuery({
        filter: filter
      });

      if (res) {
        try {
          res.data.forEach(element => {
            let newInsightObject = {
              id: element?.id,
              eyebrow_text: element?.category[0]?.insights_category_id?.insights_category_translations[0]?.title,
              title: element?.insights_translations[0]?.title,
              subtitle: element?.insights_translations[0]?.subtitle,
              thumbnail: element?.thumbnail,
              link: element?.category[0]?.insights_category_id?.slug,
              slug_identifier: element?.slug_identifier,
              author: element?.author,
              status: element?.status,
              minifigureShortcut: element?.category[0]?.insights_category_id?.minifigure_shortcut,
            };
            this.insightsData.push(newInsightObject);
          });
        }
        catch(err) { console.error("Something went wrong while fetching the insight data!", err) }
        finally { this.loadingActive = false; }
      }
    },
    async getInCollectionData() {
      this.loadingActive = true;
      this.inCollectionData = [];
      this.inCollectionDataSets = [];
      let filter = {}
      filter['in_collections'] = {"_gt": 0 }

      const res = await this.api.items('sets?fields=*.*,images.directus_files_id.*'
      + '&limit=100').readByQuery({
        filter: filter
      });

      if (res) {
        let sumInCollectionByTheme = [];
        let sumInCollectionBySet = [];
        try {
          res.data.forEach(element => {
            if (!sumInCollectionByTheme.some(el => el.theme === element?.theme?.name)) {
              if (element?.theme?.name) {
                let obj = {
                  theme: element?.theme?.name,
                  inCollectionCount: element?.in_collections
                }
                sumInCollectionByTheme.push(obj)
              }
            }
            else {
              if (element?.theme?.name) {
                let currentObj = sumInCollectionByTheme.find(el => el.theme === element?.theme?.name);
                currentObj.inCollectionCount = currentObj.inCollectionCount + element?.in_collections
              }
            }
            if (!sumInCollectionBySet.some(el => el.set === element?.set)) {
              let obj = {
                  set: element?.set,
                  name: this.mapNameToLocal(element?.name, 'de-DE'),
                  inCollectionCount: element?.in_collections
                }
                sumInCollectionBySet.push(obj)
            }
            else {
              if (element?.set) {
                let currentObj = sumInCollectionBySet.find(el => el.set === element?.set);
                currentObj.inCollectionCount = currentObj.inCollectionCount + element?.in_collections
              }
            }
            this.inCollectionData = sumInCollectionByTheme.sort((a, b) => (a.inCollectionCount < b.inCollectionCount) ? 1 : ((b.inCollectionCount < a.inCollectionCount) ? -1 : 0));
            this.inCollectionDataSets = sumInCollectionBySet.sort((a, b) => (a.inCollectionCount < b.inCollectionCount) ? 1 : ((b.inCollectionCount < a.inCollectionCount) ? -1 : 0));
          });
        }
        catch(err) { console.error("Something went wrong while fetching the InCollection data!", err) }
        finally { this.loadingActive = false; }
      }
    },
    getImagePath(image) {
      try {
        if (image.id) { return process.env.VUE_APP_API_IMG_PATH + image.id + '?height=300' ?? ''; }
        return '';
      }
      catch(err) { return ''; }
    },
    getLocalImagePath(image) {
      return require('../../assets/' + image);
    },
    getThemeLink(theme) {
      if (theme) {
        const themeNoSpecialChars = (((theme.replaceAll(' ', '-')).replaceAll('\'', '')).replaceAll(':', '')).replaceAll(',', '');
        const routeTheme = themeNoSpecialChars.toLowerCase();
        return routeTheme;
      }
    },
    mapNameToLocal(nameObject, local) {
      const localNameObject = nameObject.filter(el => el.languages_id === local)[0]?.name ?? '';
      const enNameObject = nameObject.filter(el => el.languages_id === 'en-US')[0]?.name ?? '';
      if (localNameObject) return localNameObject;
      else if (enNameObject) return enNameObject;
      else return '';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section {
  width: 100%;
  height: 100%;
  min-height: 1200px;
  overflow: hidden;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

.header-container {
  position: relative;
}

.row-search {
  width: 100%;
  margin-top: 100px;
  justify-content: center;
  z-index: 3;
}
.row-search-mobile {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 1rem;
  z-index: 40;
}

.card_analysis_tile {
  height: 25rem;
  width: 20rem;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s ease all;
}

  .card_analysis_tile:hover {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
  }

  .card_analysis_tile:hover .image {
    transform: scale(1.1) translateY(0.5rem);
  }

  .card_analysis_tile:hover .image_minifigs {
    transform: scale(1.2) translateY(0.5rem);
  }

  .card_analysis_tile:hover .image_shadow_overlay {
    transform: translateY(-0.25rem);
  }

.card_analysis_tile_mobile {
  height: 13rem;
  width: 9rem;
  overflow: hidden;
}

.card_eyebrow {
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.card_eyebrow.mobile {
  font-size: 10px;
}

.title_text {
  position: relative;
  font-size: 28px;
  color: #ffffff;
  font-weight: 800;
  display: inline-block;
  line-height: 1.2;
  z-index: 2;
}

.title_text.mobile {
  font-size: 14px;
}

.subtitle_text {
  position: relative;
  font-size: 16px;
  color: #ffffff;
  display: inline-block;
  line-height: 1.2;
  z-index: 2;
}

.subtitle_text.mobile {
  font-size: 12px;
}

.image,
.image_minifigs {
  position: relative;
  transition: 0.5s ease all;
  z-index: 1;
}

.image_minifigs {
  transform: scale(1.1);
}

.image_shadow_overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.5s ease all;
  z-index: 999;
}

.slider_group {
  color: #60ffe4 !important;
}

.card_body_loader {
  display: flex;
  height: 600px;
  width: 20rem;
  align-items: center;
}

.loader {
  width: 50%;
}

.label {
  color: #ffffff;
  font-size: 14px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: 0.5s ease all;
}

.label_header {
  font-weight: 700;
}

.label.value {
  color: #ffffff;
  padding: 0 0 0 1.5rem;
  font-weight: 700;
}

.tab_button {
  color: #60ffe4;
  transition: 0.5s ease all;
}

.info-list-data {
  font-size: 14px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

#chip_link {
  font-size: 14px;
  cursor: pointer;
  max-width: 99%;
  transition: 0.5s ease all;
}

  #chip_link:hover {
    color: #60ffe4;
  }

#chip_link_mobile {
  font-size: 12px;
  cursor: pointer;
  max-width: 99%;
  transition: 0.5s ease all;
}

#chip_link_mobile:hover {
  color: #60ffe4;
}

.card_main.mobile {
  width: 100%;
}

.card_in_collection {
  width: 400px;
  max-height: 800px;
  overflow: auto;
}

.card_in_collection.mobile {
  max-height: 400px;
}

.container_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.normal_text {
  text-align: center;
}

#icon_crown {
  position: absolute;
  transform: rotate(45deg) translateY(-10px) translateX(-22px);
}

.text-link.mobile {
  font-size: 12px;
}

.hint_text {
  color: rgb(255, 255, 255);
}

.hint_text_mobile {
  font-size: 12px;
  color: rgb(255, 255, 255);
}

.card_register {
  justify-items: center;
}

.card_register_ranking {
  width: 9rem;
}

.header_mobile {
  font-size: 14px;
}

.text_mobile {
  font-size: 12px;
}

</style>
