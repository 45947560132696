<template>
  <svg id="background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2616.32 604.12">
    <path d="M1613.34,19.72a17,17,0,0,1,4.43.59,19.72,19.72,0,0,1,21.65-11.76,19.74,19.74,0,0,1,34.46,3.55,19.72,19.72,0,0,1,25.67,18.81H1597.47A16.86,16.86,0,0,1,1613.34,19.72Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M402,377.67a114.55,114.55,0,0,0-20.66,1.87c-13.93-22.35-46.65-38-84.81-38a136.72,136.72,0,0,0-16.18,1c-16.56-16.33-44.35-27-75.78-27-38.2,0-70.95,15.72-84.86,38.11A133.69,133.69,0,0,0,92,350.78c-50.81,0-92,27.81-92,62.13H476C465.17,392.37,436.15,377.67,402,377.67Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M2565.32,236.91a54.35,54.35,0,0,0-14.24,1.9A63.36,63.36,0,0,0,2481.45,201a63.43,63.43,0,0,0-110.77,11.4,63.48,63.48,0,0,0-82.54,60.52h328.18A54.16,54.16,0,0,0,2565.32,236.91Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M1920.89,604.12c0-59.12,97.85-88,154.44-79.59C2094,463,2192.58,431,2248.53,462.8c19.44,11,34.62,26.46,43.74,44.43,64.81-16.86,133.88,12.89,154.28,66.43a85.53,85.53,0,0,1,5.68,30.46" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
    <path d="M647.29,175.84a122.46,122.46,0,0,0-19-5.88,60.5,60.5,0,0,0-30.09.86,73.83,73.83,0,0,0-27.65,14.87c-13,11.22-23.55,27.22-29.33,46.76a46.67,46.67,0,0,0-17-3.27c-28.14,0-52.08,25.76-61,61.73H738C733.56,274.68,711.81,201.69,647.29,175.84Z" transform="translate(0 0)" style="fill: #fff;isolation: isolate;opacity: 0.20000000298023224"/>
  </svg>

  <section class="section-main">
    <v-container>

      <v-row class="row-search" no-gutters>
        <Search class="mb-6 search" buttonText="Suchen" placeholderText="Ein Set oder eine Minifigur?" />
      </v-row>

      <v-row justify="center" class="mt-12 text-center" no-gutters>
        <h2 class="fade_in">LEGO<sup id="trademark_symbol">&reg;</sup> Sets und Minifiguren vergleichen, suchen & verwalten.</h2>
      </v-row>

      <v-row justify="center" class="mt-12" no-gutters>
        <h1 class="fade_in">Top Deals</h1>
      </v-row>
      <v-row justify="center" class="mb-2" :style="{ minHeight: '185px' }" no-gutters>
        <div v-for="setItem in dealSets.slice(0,12)" :key="setItem.key">
          <router-link class="link" :to="{ path: '/items/sets/' + setItem?.set?.set }">
            <DealCard class="fade_in"
              v-if="setItem?.price"
              :set="`${setItem?.set?.set}`"
              :name="`${setItem?.set?.name[0]?.name ?? ''}`"
              discountBadgeActive=true
              :discount="`${setItem?.discount?.toFixed(1) ?? ''}`"
              :discountBadgeWidth="'50px'"
              :discountBadgeHeight="'30px'"
              :transformDiscountBadge="'translate(60px,10px)'"
              :discountTextFontsize="'12px'"
              :retailPrice="setItem?.set?.retail_price ?? ''"
              :price="`${setItem?.price?.toFixed(2) ?? ''}`"
              :theme="`${setItem?.set?.theme?.name ?? ''}`"
              :yearActive=false
              :image="getSetOverviewImage(setItem?.set?.header_image, setItem?.set?.images)"
              :transformTextContainer="'translateY(-10px)'"
              :imageContainerWidth="'80px'"
              :imageContainerHeight="'80px'"
              :textContainerWidth="'100px'"
              :textContainerHeight="'120px'"
              :cardSetFontsize="'10px'"
              :cardThemeFontsize="'10px'"
              :cardNameFontsize="'12px'"
              :lineClamp="2"
              :cardNameMinMaxHeight="'1.7rem'"
              :cardPriceFontsize="'14px'"
              :cardRetailPriceFontsize="'10px'"
              :cardYearFontsize="'10px'"
              :borderRadius="'15px'"
              />
          </router-link>
        </div>
      </v-row>
      <v-row class="mt-4 pb-6" justify="center" no-gutters >
        <PrimaryButton :to="{ path: '/deals' }" buttonText="Zu weiteren Deals"/>
      </v-row>

      <v-row justify="center" class="mt-12 mb-4" no-gutters>
        <v-col cols="auto" class="mx-4 mb-6">
          <div class="card_main">
            <div class="card_main_header">
              <p class="header-text">Collection</p>
            </div>
            <div class="">
              <template v-if="loggedIn.value">
                <template v-if="loadingActiveCollection">
                  <div class="card_body_loader">
                    <v-progress-linear :active="loadingActiveCollection" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
                  </div>
                </template>
                <template v-else>
                  <template v-if="collection.length > 0">
                    <AreaChartWithDatetime v-if="chartData.length > 0" :chartData="chartData" :firstChartEntryDatetime="firstChartEntryDatetime" />
                      <div class="label_container">
                        <tbody>
                          <tr>
                            <td><span class="label">Sets im Besitz</span></td>
                            <td><span class="label value">{{ sumCollectionSets }}</span></td>
                          </tr>
                          <tr>
                            <td><span class="label">Summe Wert</span></td>
                            <td><span class="label value">{{ sumCollectionCurrentValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                          </tr>
                          <tr>
                            <td><span class="label">Summe UVP</span></td>
                            <td><span class="label value">{{ sumCollectionRetailValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                          </tr>
                          <tr>
                            <td><span class="label">Summe EK</span></td>
                            <td><span class="label value">{{ sumCollectionPricePaidValue }} {{ mapCurrencyUnit(localCurrency) }}</span></td>
                          </tr>
                          <tr>
                            <td><span class="label">Performance</span></td>
                            <template v-if="diffPerformance >= 0.00"><td><span class="label value balance_positive">{{ diffPerformance }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                            <template v-else><td><span class="label value balance_negative">{{ diffPerformance }} {{ mapCurrencyUnit(localCurrency) }}</span></td></template>
                          </tr>
                          <tr>
                            <td><span class="label">Performance in %</span></td>
                            <template v-if="diffPerformancePercent >= 0.00"><td><span class="label value balance_positive">{{ diffPerformancePercent }} %</span></td></template>
                            <template v-else><td><span class="label value balance_negative">{{ diffPerformancePercent }} <span v-show="diffPerformancePercent != '-'">%</span></span></td></template>
                          </tr>
                        </tbody>
                        <router-link id="collection_link" to='/usercollection'>
                          <v-chip id="chip_link" size="small" class="ml-1 mt-2">Zur Collection</v-chip>
                        </router-link>
                      </div>
                  </template>
                  <template v-else>
                    <div id="add_collection_button_container" no-gutters>
                      <span id="add_collection_text">
                        Du hast noch keinen Eintrag in deiner Collection.
                      </span>
                      <PrimaryButton :to="{ path: '/usercollection' }" buttonText="Collection anlegen"/>
                    </div>
                  </template>
                </template>
              </template>
              <template v-else>
                <v-row class="hint_row" no-gutters>
                  <span class="hint_text">
                    Um Deine Collection anlegen und verwalten zu können, musst Du
                    <router-link class="text-link" to="/registration">registriert</router-link>
                    sein.<br>
                    Hast Du bereits einen Account, dann
                    <router-link class="text-link" to="/login">melde</router-link>
                    Dich einfach an.
                  </span>
                </v-row>
              </template>
            </div>
          </div>
        </v-col>
        <v-col cols="auto" class="mx-4">
          <div class="card_main">
            <div class="card_main_header">
              <p class="header-text">Top 10 Minifiguren</p>
              <v-spacer></v-spacer>
              <span v-if="minifigureConditionSwitch" class="mr-1 label-input">neu</span>
              <span v-else class="mr-1 label-input">gebraucht</span>
              <Toggle v-model="minifigureConditionSwitch" class="mr-4"/>
            </div>
            <div class="">
              <template v-if="loadingActiveMinifigures">
                <div class="card_body_loader">
                  <v-progress-linear :active="loadingActiveMinifigures" indeterminate rounded class="loader" height="6" bg-opacity="0.2"></v-progress-linear>
                </div>
              </template>
              <template v-else>
                <v-tabs v-model="tabTopMinifigures" density="compact" class="mb-2 button_tab">
                  <v-tab value="all">Alle</v-tab>
                  <v-tab value="starwars">Star Wars</v-tab>
                  <v-tab value="superheroes">Super Heroes</v-tab>
                </v-tabs>
                <v-window v-model="tabTopMinifigures" :style="'min-height: 445px; min-width: 410px; overflow: auto;'">
                  <v-window-item value="all">
                    <v-table density="compact" class="table" hover>
                      <thead>
                        <tr>
                          <th class="text-left table_header"></th>
                          <th class="text-left table_header">#</th>
                          <th class="text-left table_header">Name</th>
                          <th class="text-left table_header">Ø Preis</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="minifigureItem in topMinifigures" :key="minifigureItem.minifigures_number?.number">
                          <td>
                            <router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">
                              <v-avatar color="white" size="30" class="my-1">
                                <v-img v-if="minifigureItem?.minifigures_number?.images[0]?.directus_files_id" alt="Avatar" :src="getMinifigureImage(minifigureItem?.minifigures_number?.images[0]?.directus_files_id)"></v-img>
                                <v-icon v-else id="small_image_alt" icon="mdi-account-circle" ></v-icon>
                              </v-avatar>
                            </router-link>
                          </td>
                          <td><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ minifigureItem.minifigures_number?.number }}</router-link></td>
                          <td id="td_minifigure_name"><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ clearName(minifigureItem.minifigures_number?.name) }}</router-link> </td>
                          <td><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ minifigureItem.avg_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</router-link></td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-window-item>
                  <v-window-item value="starwars">
                    <v-table density="compact" class="table" hover>
                      <thead>
                        <tr>
                          <th class="text-left table_header"></th>
                          <th class="text-left table_header">#</th>
                          <th class="text-left table_header">Name</th>
                          <th class="text-left table_header">Ø Preis</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="minifigureItem in topMinifigures" :key="minifigureItem.minifigures_number?.number">
                          <td>
                            <router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">
                              <v-avatar color="white" size="30" class="my-1">
                                <v-img v-if="minifigureItem?.minifigures_number?.images[0]?.directus_files_id" alt="Avatar" :src="getMinifigureImage(minifigureItem?.minifigures_number?.images[0]?.directus_files_id)"></v-img>
                                <v-icon v-else id="small_image_alt" icon="mdi-account-circle" ></v-icon>
                              </v-avatar>
                            </router-link>
                          </td>
                          <td><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ minifigureItem.minifigures_number?.number }}</router-link></td>
                          <td id="td_minifigure_name"><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ clearName(minifigureItem.minifigures_number?.name) }}</router-link> </td>
                          <td><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ minifigureItem.avg_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</router-link></td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-window-item>
                  <v-window-item value="superheroes">
                    <v-table density="compact" class="table" hover>
                      <thead>
                        <tr>
                          <th class="text-left table_header"></th>
                          <th class="text-left table_header">#</th>
                          <th class="text-left table_header">Name</th>
                          <th class="text-left table_header">Ø Preis</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="minifigureItem in topMinifigures" :key="minifigureItem.minifigures_number?.number">
                          <td>
                            <router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">
                              <v-avatar color="white" size="30" class="my-1">
                                <v-img v-if="minifigureItem?.minifigures_number?.images[0]?.directus_files_id" alt="Avatar" :src="getMinifigureImage(minifigureItem?.minifigures_number?.images[0]?.directus_files_id)"></v-img>
                                <v-icon v-else id="small_image_alt" icon="mdi-account-circle" ></v-icon>
                              </v-avatar>
                            </router-link>
                          </td>
                          <td><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ minifigureItem.minifigures_number?.number }}</router-link></td>
                          <td id="td_minifigure_name"><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ clearName(minifigureItem.minifigures_number?.name) }}</router-link> </td>
                          <td><router-link class="text-left info-list-data" :to="{ path: '/items/minifigures/' + minifigureItem.minifigures_number?.number }">{{ minifigureItem.avg_price.toFixed(2) }} {{ mapCurrencyUnit(localCurrency) }}</router-link></td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-window-item>
                </v-window>
              </template>
              <router-link id="collection_link" to='/insights/ranking/minifigures/all'>
                <v-chip id="chip_link" size="small" class="ml-4 mb-4">Zeig mir mehr</v-chip>
              </router-link>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-12 mb-4" no-gutters>
        <h1 class="fade_in">Neu bei brickdex</h1>
      </v-row>
      <v-row justify="center" class="mb-4" :style="{ minHeight: '185px' }" no-gutters>
        <div v-for="setItem in lastAddedSets" :key="setItem.key">
          <router-link class="link" :to="{ path: '/items/sets/' + setItem.set }">
            <SetCard class="fade_in"
              :set="`${setItem.set}`"
              :name="`${setItem.name[0]?.name ?? ''}`"
              :year="getReleaseDate(setItem.release_date)"
              :image="getSetOverviewImage(setItem.header_image, setItem.images)"
              :imageContainerWidth="'80px'"
              :imageContainerHeight="'80px'"
              :textContainerWidth="'100px'"
              :textContainerHeight="'105px'"
              :cardSetFontsize="'14px'"
              :cardNameFontsize="'14px'"
              :borderRadius="'15px'"
              :transformTextContainer="'translateY(-10px)'"
              />
          </router-link>
        </div>
      </v-row>
      <v-row no-gutters justify="center">
        <PrimaryButton :to="{ path: '/new-entries' }" buttonText="zeig mir mehr"/>
      </v-row>

      <v-row class="mt-12 mb-4" no-gutters justify="center">
        <h1 class="fade_in">brickdex Stats</h1>
      </v-row>

        <v-row no-gutters>
          <v-col>
            <div class="card_stats fade_in">
              <span class="stats_header fade_in">Menge an</span>
              <span class="stats_header fade_in">Sets</span>
              <span class="stats_value fade_in"> {{ this.allSetsCount.toLocaleString(this.localLanguage) }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="card_stats fade_in">
              <span class="stats_header fade_in">Menge an</span>
              <span class="stats_header fade_in">Minifiguren</span>
              <span class="stats_value fade_in"> {{ this.allMinifiguresCount.toLocaleString(this.localLanguage) }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="card_stats fade_in">
              <span class="stats_header fade_in">Sets in</span>
              <span class="stats_header fade_in">Collections</span>
              <span class="stats_value fade_in"> {{ this.allCollectionSetCount.toLocaleString(this.localLanguage) }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="card_stats fade_in">
              <span class="stats_header fade_in">Menge an</span>
              <span class="stats_header fade_in">Teilen</span>
              <span class="stats_value fade_in"> {{ this.allPieceCount.toLocaleString(this.localLanguage) }}</span>
            </div>
          </v-col>
        </v-row>

    </v-container>
  </section>

  <section class="section-collection">
    <v-container>
      <v-row no-gutters class="row-collection scroll_fade scroll_fade_fromLeft">
        <v-col cols="7">
          <svg id="img-collection" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.09 456.92">
            <g style="isolation: isolate">
              <g>
                <g style="mix-blend-mode: multiply">
                  <g>
                    <path d="M498.23,603.44c0,11.68-26.55,19.92-60.42,19.92s-62.21-13.27-62.21-25,27.45-20,61.32-20S498.23,591.76,498.23,603.44Z" transform="translate(-55.09 -186.5)" style="fill: #e5e5e5"/>
                    <polygon points="253.04 435.69 213.49 427.14 241.58 419.18 253.04 435.69" style="fill: #e5e5e5"/>
                    <polygon points="316.92 393.79 332.48 388.01 306.67 379.39 304.87 376.81 300.91 377.47 243.54 358.31 217.38 366.57 243.54 378.84 237.55 380.53 257.54 388.96 275.47 384.48 315.73 393.18 316.92 393.79" style="fill: #e5e5e5"/>
                    <polygon points="200.99 452.42 158 443.42 188.53 433.52 200.99 452.42" style="fill: #e5e5e5"/>
                    <polygon points="119.28 450.67 76.29 441.68 106.81 431.78 119.28 450.67" style="fill: #e5e5e5"/>
                    <polygon points="80.13 429.43 30 418.49 60.53 408.6 80.13 429.43" style="fill: #e5e5e5"/>
                    <path d="M405.35,622.45l-21.89-7-10.09,3.12s-5.34-3.72-10.31-3.72c-5.37,0-4.15,2.82-4.15,2.82a17.71,17.71,0,0,0-4.41.37c-6.64,2.09.1,6.55.1,6.55s-3.66,1-4.1,3c-.95,4.29,6.3,5.2,6.3,5.2l36.09,9.79,6.3.47-6.13-9,7.7.27Z" transform="translate(-55.09 -186.5)" style="fill: #e5e5e5"/>
                  </g>
                  <polygon points="71.42 371.24 56.24 352.47 56.24 341.87 24.91 341.87 37.7 352.05 15.5 359.04 71.42 371.24" style="fill: #e5e5e5"/>
                  <polygon points="136.77 391.8 74.46 378.53 190.59 342.39 224.65 342.8 136.77 391.8" style="fill: #e5e5e5"/>
                </g>
                <polygon points="152.8 440.65 109.82 431.65 140.34 421.75 152.8 440.65" style="fill: #bfbfbf"/>
                <path d="M95.15,528.45S38,314.93,177.74,315.11c91.7.12,85.28-128,175.83-128.61s104.86,70.1,101.77,135.81c-2.11,44.91-21.25,101.64,28.8,112.14,57.11,12,36.31,94,36.31,94Z" transform="translate(-55.09 -186.5)" style="fill: #ffebfc"/>
                <rect y="341.15" width="493.09" height="1.45" style="fill: #a195bc"/>
                <g>
                  <g>
                    <polygon points="80.94 394.56 104.37 390.21 82.84 383.62 59.4 387.97 80.94 394.56" style="fill: #f8c778"/>
                    <polygon points="81.18 429.67 80.91 394.57 59.4 387.97 59.67 423.07 81.18 429.67" style="fill: #d69034"/>
                    <path d="M121,589.24a9.81,9.81,0,0,0-1.54-8.8,3.14,3.14,0,0,0-2.88-1.39l2.37-.42a3.14,3.14,0,0,1,2.89,1.39,9.87,9.87,0,0,1,1.54,8.8,3.13,3.13,0,0,1-2.22,2.24l-2.38.43A3.16,3.16,0,0,0,121,589.24Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                    <path d="M115.87,590.1c1.83,2.19,4.12,1.8,5.1-.86a9.81,9.81,0,0,0-1.54-8.8c-1.83-2.19-4.11-1.81-5.1.86A9.85,9.85,0,0,0,115.87,590.1Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                  </g>
                  <g>
                    <path d="M131.91,610.1a9.87,9.87,0,0,0-1.54-8.8,3.17,3.17,0,0,0-2.88-1.39l2.37-.42a3.14,3.14,0,0,1,2.89,1.39,9.83,9.83,0,0,1,1.53,8.8,3.13,3.13,0,0,1-2.21,2.24l-2.38.43A3.16,3.16,0,0,0,131.91,610.1Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                    <path d="M126.81,611c1.83,2.19,4.11,1.8,5.1-.86a9.87,9.87,0,0,0-1.54-8.8c-1.83-2.19-4.11-1.81-5.1.86A9.84,9.84,0,0,0,126.81,611Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                  </g>
                  <g>
                    <polygon points="81.18 429.67 104.62 425.32 104.37 390.21 80.94 394.56 81.18 429.67" style="fill: #eaaf52"/>
                    <path d="M131.74,592.55a9.81,9.81,0,0,0-1.54-8.8,3.16,3.16,0,0,0-2.88-1.39l2.37-.42a3.18,3.18,0,0,1,2.89,1.39,9.87,9.87,0,0,1,1.54,8.8,3.16,3.16,0,0,1-2.22,2.25l-2.38.42A3.14,3.14,0,0,0,131.74,592.55Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                    <path d="M126.64,593.41c1.83,2.19,4.12,1.8,5.1-.86a9.81,9.81,0,0,0-1.54-8.8c-1.83-2.19-4.11-1.81-5.1.86A9.84,9.84,0,0,0,126.64,593.41Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                  </g>
                  <g>
                    <path d="M121.13,606.78a9.83,9.83,0,0,0-1.53-8.8,3.18,3.18,0,0,0-2.89-1.39l2.38-.42a3.19,3.19,0,0,1,2.89,1.39,9.83,9.83,0,0,1,1.53,8.8,3.14,3.14,0,0,1-2.22,2.25l-2.37.42A3.13,3.13,0,0,0,121.13,606.78Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                    <path d="M116,607.64c1.83,2.19,4.12,1.81,5.1-.86a9.83,9.83,0,0,0-1.53-8.8c-1.84-2.19-4.12-1.81-5.1.86A9.83,9.83,0,0,0,116,607.64Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon points="270.25 338.06 136.19 379.79 110.66 371.87 245.04 330.07 270.25 338.06" style="fill: #605eaa"/>
                        <polygon points="136.19 379.79 136.16 391.72 110.62 383.8 110.66 371.87 136.19 379.79" style="fill: #392f6e"/>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M238.15,542l.09-.11,0-.07a.69.69,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17l-.6.16-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1,.26l.36.06.8.13.48,0,.75.07H231l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M236.41,537c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,236.41,537Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M251.66,546.15a.76.76,0,0,0,.08-.11l0-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13,0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.26.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0H244l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M249.91,541.13c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,249.91,541.13Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M251.57,538a.94.94,0,0,0,.09-.12l.05-.07.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18s0,0,0,.07l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.62.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13,0a1.69,1.69,0,0,0,.27-.13.61.61,0,0,0,.14-.07l.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M249.83,532.93c2.62.8,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,249.83,532.93Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M265.08,542.06l.09-.12,0-.07a.34.34,0,0,0,.06-.12l0-.07a1.13,1.13,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06A10.07,10.07,0,0,1,254,540c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0a2.24,2.24,0,0,0,.27-.13l.13-.07.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M263.33,537c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,263.33,537Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M211.17,550.25l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12L210,548l-.48.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.27,0,.52.1.8.13l.48,0,.75.06.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0L210,551l.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M209.43,545.23c2.62.81,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,209.43,545.23Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M224.68,554.36l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.25,0,.49.05.74.06l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M222.93,549.34c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,222.93,549.34Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M224.59,546.15a.44.44,0,0,0,.09-.11l0-.07.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13,0,.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06a9.25,9.25,0,0,1-1-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M222.85,541.13c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.38,18.38,0,0,1,222.85,541.13Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M238.1,550.26l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.08-.11.09-.16.12-.11.08-.2.12-.14.07L237,548l-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06a10,10,0,0,1-1.05-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07H231l.74,0h.54l.72,0,.55,0,.68-.06.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M236.35,545.24c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.38,18.38,0,0,1,236.35,545.24Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M184.14,558.69l.09-.11.05-.08.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.08-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.48.12a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.23,0-.48,0-.72,0l-.54,0-.73,0h-.54l-.74-.07-.49,0c-.28,0-.53-.08-.8-.12-.11,0-.24,0-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.37.05c.26,0,.52.1.79.13l.48,0,.75.06c.18,0,.36,0,.54,0l.73,0,.54,0c.24,0,.49,0,.72,0l.55,0,.68-.07.53-.08a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M182.4,553.67c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,182.4,553.67Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <polygon points="270.27 338.06 270.23 349.99 136.16 391.72 136.19 379.79 270.27 338.06" style="fill: #4c4284"/>
                            <path d="M197.65,562.8l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07a.94.94,0,0,1-.1.1l-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0l-.55,0-.73,0H190l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.36-.06c-.37-.08-.73-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36,0c.26.05.52.1.79.13l.49.05c.25,0,.49,0,.75.06s.35,0,.53,0l.73,0,.55,0c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,0-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M195.9,557.78c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.38,18.38,0,0,1,195.9,557.78Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M197.57,554.59a.76.76,0,0,0,.08-.11l0-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0L193,556l.68-.06c.18,0,.34-.06.52-.09l.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M195.82,549.57c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,195.82,549.57Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M211.07,558.7l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17l-.6.16-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.54c-.25,0-.49,0-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M209.33,553.68c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,209.33,553.68Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M265.2,533.81l.09-.11,0-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13,0,.07-.09.11-.07.08L265,531l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06a10,10,0,0,1-1.05-.27c-1.32-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49,0,.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55,0,.67-.06.53-.09.62-.1.48-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08L265,534l.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M263.46,528.79c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,263.46,528.79Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                        <g>
                          <path d="M278.71,537.92l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a1.39,1.39,0,0,1-.06.13l0,.07a.76.76,0,0,1-.08.11l-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06a10,10,0,0,1-1.05-.27c-1.33-.4-2-.94-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.73,0h.55l.72,0,.55,0,.68-.06.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M277,532.9c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.38,18.38,0,0,1,277,532.9Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M278.63,529.71a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07c-.09.05-.18.08-.27.12l-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13c-.12,0-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07c.18,0,.34-.06.52-.09l.63-.1.47-.12c.18,0,.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08.91.91,0,0,0,.16-.11l0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M276.88,524.69c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,276.88,524.69Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                        <g>
                          <path d="M292.13,533.82l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.12-.07.07-.11.1-.15.11-.12.08-.2.12-.14.07a1.31,1.31,0,0,1-.27.12l-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13c-.12,0-.24,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07c.18,0,.35-.06.53-.09l.62-.1.48-.12.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08L292,534l.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M290.39,528.8c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,290.39,528.8Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <polygon points="110.95 364.73 136.34 372.56 149.7 368.39 124.48 360.59 110.95 364.73" style="fill: #c1c1c1"/>
                      <polygon points="136.48 329.17 136.45 335.6 110.91 327.68 110.95 321.24 136.48 329.17" style="fill: #9e9e9e"/>
                      <polygon points="136.34 372.56 136.45 379.06 110.91 371.14 110.95 364.7 136.34 372.56" style="fill: #9e9e9e"/>
                      <polygon points="136.48 329.17 149.84 325 149.84 374.88 136.42 379.06 136.34 372.56 143.28 370.3 143.28 333.39 136.45 335.6 136.48 329.17" style="fill: #ccc"/>
                      <polygon points="269.82 338.23 256.45 342.4 256.45 292.22 269.88 288.04 269.96 294.55 263.02 296.81 263.02 334 269.85 331.79 269.82 338.23" style="fill: #ccc"/>
                      <polygon points="110.95 321.24 136.48 329.17 149.84 325 124.48 317.11 110.95 321.24" style="fill: #a7fcfa"/>
                      <polygon points="143.28 370.3 117.72 362.66 117.72 329.79 136.45 335.6 143.28 333.39 143.28 370.3" style="fill: #8c8c8c"/>
                    </g>
                    <polygon points="256.45 342.4 230.62 334.56 230.62 299.13 254.35 291.74 256.45 292.22 256.45 342.4" style="fill: #8c8c8c"/>
                    <g>
                      <g>
                        <path d="M292.23,525.37l.09-.11.05-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.48.12a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0l-.54,0-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.53-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36,0c.27,0,.52.1.8.13l.48,0,.75.06.54,0,.73,0,.54,0c.24,0,.49,0,.72,0l.55-.05.68-.07.53-.08a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                        <path d="M290.49,520.35c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.42,18.42,0,0,1,290.49,520.35Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                      </g>
                      <g>
                        <path d="M305.74,529.48l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07-.1.1-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.73,0l-.54,0-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36.05c.26,0,.52.1.79.13l.49,0c.25,0,.49.05.74.06s.36,0,.54,0l.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06L305,530l.13-.08.2-.11.12-.08.16-.12,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                        <path d="M304,524.46c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.38,18.38,0,0,1,304,524.46Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                      </g>
                    </g>
                    <polygon points="256.45 292.22 254.35 291.74 267.94 287.51 269.88 288.04 256.45 292.22" style="fill: #a7fcfa"/>
                    <polygon points="269.85 331.79 263.02 330.07 263.02 334 269.85 331.79" style="fill: #c1c1c1"/>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="270.25 276.12 136.19 317.85 110.66 309.92 245.04 268.12 270.25 276.12" style="fill: #605eaa"/>
                        <polygon points="136.19 317.85 136.16 329.77 110.62 321.85 110.66 309.92 136.19 317.85" style="fill: #392f6e"/>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M238.15,480.09l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.07a2.24,2.24,0,0,1-.27.13l-.13.05c-.15.06-.31.12-.48.17l-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.8.13.48,0c.25,0,.5.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11.48-.12.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.06a1.31,1.31,0,0,0,.27-.12l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M236.41,475.07c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.64,18.64,0,0,1,236.41,475.07Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M251.66,484.2a.44.44,0,0,0,.08-.11l0-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07l-.06.12,0,.07a.47.47,0,0,1-.09.12.46.46,0,0,1-.07.07l-.11.1-.15.12-.12.08-.2.11-.14.07-.26.13-.14.05c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06l.79.13.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.14-.06a1.59,1.59,0,0,0,.27-.12l.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l.05,0a.34.34,0,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M249.91,479.19c2.62.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,249.91,479.19Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M251.57,476l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.48.12a6.17,6.17,0,0,1-.62.11l-.53.09-.67.06-.55,0c-.24,0-.49,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0s-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.37,0c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.05c.26.05.52.1.79.13l.49.05c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06A.46.46,0,0,0,251.57,476Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M249.83,471c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,249.83,471Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M265.08,480.11l.09-.11,0-.08a.34.34,0,0,0,.06-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12s0,.05,0,.08l-.09.11-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0l-.55,0-.73,0c-.18,0-.36,0-.53,0s-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.05c.26.05.52.1.8.13l.48.05c.25,0,.49,0,.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.13-.08a1.39,1.39,0,0,0,.21-.11l.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M263.33,475.09c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,263.33,475.09Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M211.17,488.3l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11a.46.46,0,0,1-.07.07l-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09-.68.06-.55.05-.72,0h-.54l-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27c.12,0,.24,0,.36.06.27,0,.52.09.8.12l.48.05.75.07h.54l.73,0h.54l.72,0,.55-.05.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0L210,489l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M209.43,483.28c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,209.43,483.28Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M224.68,492.41l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.53.09-.67.06-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0H217l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27.11,0,.24,0,.36.06s.52.09.79.12l.49,0,.74.07h.54l.73,0h.55c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0a.2.2,0,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M222.93,487.39c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,222.93,487.39Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M224.59,484.2a.31.31,0,0,0,.09-.11l0-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13,0,.07a.48.48,0,0,1-.09.11l-.07.08-.11.1a.83.83,0,0,1-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09l-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54c.24,0,.49,0,.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l.05,0a.34.34,0,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M222.85,479.18c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,222.85,479.18Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M238.1,488.31l.09-.11.05-.07a.5.5,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1a.91.91,0,0,1-.16.11l-.11.08-.2.12-.14.07L237,486l-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0-.75-.07-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.53,0c.25,0,.49,0,.74,0h.54l.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17-.05.33-.11.48-.17L237,489l.27-.12.14-.07.2-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M236.35,483.29c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,236.35,483.29Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M184.14,496.74l.09-.11.05-.07.06-.13a.14.14,0,0,1,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.49a10,10,0,0,0,1.05.27l.37.06.79.13.48,0,.75.07H177l.73,0h.54c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06l.53-.09.62-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M182.4,491.72c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,182.4,491.72Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <polygon points="270.27 276.11 270.23 288.04 136.16 329.77 136.19 317.85 270.27 276.11" style="fill: #4c4284"/>
                            <path d="M197.65,500.85a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.1.1-.16.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0H190l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.5.33.1.68.18,1,.26l.36.06.79.13.49,0,.75.07h.53l.73,0h.55c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,0-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M195.9,495.83c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,195.9,495.83Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M197.57,492.65l.08-.12,0-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l0,.07a.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.13.07-.27.13-.14.05c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.14-.05.27-.13.13-.07.2-.11.12-.09a.91.91,0,0,0,.16-.11l0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M195.82,487.63c2.62.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,195.82,487.63Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M211.07,496.76l.09-.12.05-.07a.5.5,0,0,0,0-.13l0-.06a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.07a2.24,2.24,0,0,1-.27.13l-.13,0c-.15.06-.31.12-.48.17l-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13L201,495a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49A10.07,10.07,0,0,0,201,498c.12,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.5.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.05a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.11.12-.09.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M209.33,491.74c2.62.8,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,209.33,491.74Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M265.2,471.86a.48.48,0,0,0,.09-.11l0-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13,0,.07a.48.48,0,0,1-.09.11l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.62.1c-.18,0-.35.07-.53.09s-.45,0-.68.06l-.55.06-.72,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54c.24,0,.48,0,.73,0h.54c.24,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M263.46,466.84c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.14-.07-3A18.49,18.49,0,0,1,263.46,466.84Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                        <g>
                          <path d="M278.71,476l.09-.11,0-.07a.38.38,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.11,1.11,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13l0,.07a.44.44,0,0,1-.08.11l-.07.08-.11.1a.91.91,0,0,1-.16.11l-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0-.75-.07-.49,0-.79-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53c.25,0,.49,0,.73,0h.55c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M277,471c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,277,471Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M278.63,467.77a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.83.83,0,0,1-.08.12l-.08.07-.11.1-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.25,0,.49.05.74.06l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12s0,0,0,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M276.88,462.75c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,276.88,462.75Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                        <g>
                          <path d="M292.13,471.88l.09-.12,0-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0-.75-.06-.48,0c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.24,0,.36.06s.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M290.39,466.86c2.62.81,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,290.39,466.86Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <polygon points="110.95 302.78 136.34 310.61 149.7 306.44 124.48 298.64 110.95 302.78" style="fill: #c1c1c1"/>
                      <polygon points="136.48 267.22 136.45 273.65 110.91 265.73 110.95 259.3 136.48 267.22" style="fill: #9e9e9e"/>
                      <polygon points="136.34 310.61 136.45 317.12 110.91 309.19 110.95 302.76 136.34 310.61" style="fill: #9e9e9e"/>
                      <polygon points="136.48 267.22 149.84 263.05 149.84 312.94 136.42 317.12 136.34 310.61 143.28 308.35 143.28 271.44 136.45 273.65 136.48 267.22" style="fill: #ccc"/>
                      <polygon points="269.82 276.28 256.45 280.45 256.45 230.28 269.88 226.1 269.96 232.6 263.02 234.86 263.02 272.06 269.85 269.85 269.82 276.28" style="fill: #ccc"/>
                      <polygon points="110.95 259.3 136.48 267.22 149.84 263.05 124.48 255.16 110.95 259.3" style="fill: #a7fcfa"/>
                      <polygon points="143.28 308.35 117.72 300.71 117.72 267.84 136.45 273.65 143.28 271.44 143.28 308.35" style="fill: #8c8c8c"/>
                    </g>
                    <polygon points="256.45 280.45 230.62 272.61 230.62 237.18 254.35 229.79 256.45 230.28 256.45 280.45" style="fill: #8c8c8c"/>
                    <g>
                      <g>
                        <path d="M292.23,463.42l.09-.11.05-.07a1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.54l-.73,0h-.54l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.49a10,10,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06l.53-.09.62-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                        <path d="M290.49,458.4c2.62.81,2.66,2.14.08,3a18.64,18.64,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,290.49,458.4Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                      </g>
                      <g>
                        <path d="M305.74,467.53a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08a.94.94,0,0,0-.1.1l-.16.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.06-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.49a9.44,9.44,0,0,0,1.05.27l.36.06.79.13.49,0,.74.07h.54l.73,0h.55c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                        <path d="M304,462.51c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,304,462.51Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                      </g>
                    </g>
                    <polygon points="256.45 230.28 254.35 229.79 267.94 225.56 269.88 226.1 256.45 230.28" style="fill: #a7fcfa"/>
                    <polygon points="269.85 269.85 263.02 268.12 263.02 272.06 269.85 269.85" style="fill: #c1c1c1"/>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="270.25 214.06 136.19 255.79 110.66 247.87 245.04 206.06 270.25 214.06" style="fill: #605eaa"/>
                        <polygon points="136.19 255.79 136.16 267.72 110.62 259.79 110.66 247.87 136.19 255.79" style="fill: #392f6e"/>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M238.15,418l.09-.12,0-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06c.26,0,.52.1.8.13l.48.05.75.06.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M236.41,413c2.62.81,2.65,2.13.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,236.41,413Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M251.66,422.15l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.12a.18.18,0,0,1,0,.07.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.26.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49.05.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M249.91,417.13c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,249.91,417.13Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M251.57,413.94l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.67.06l-.55,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05.67-.06.53-.09.63-.1.47-.13.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07A.23.23,0,0,0,251.57,413.94Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M249.83,408.92c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,249.83,408.92Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M265.08,418.05l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.25,1.25,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07H258l.74,0h.54l.72,0,.55-.05.68-.06.52-.09.63-.1.48-.13.52-.13.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M263.33,413c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,263.33,413Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M211.17,426.24l.09-.11a.18.18,0,0,1,.05-.07.69.69,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12L210,424c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.54l-.73,0h-.54l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54l.73,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.09,0L210,427l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M209.43,421.22c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,209.43,421.22Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M224.68,430.35a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1-.15.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0H217l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.68.18,1,.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.55c.24,0,.48,0,.72,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M222.93,425.33c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,222.93,425.33Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M224.59,422.15a.47.47,0,0,0,.09-.12l0-.07.06-.12a.25.25,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18s0,.05,0,.07l-.06.12a.18.18,0,0,1,0,.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13.61.61,0,0,0,.14-.07l.2-.11.12-.09a.83.83,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M222.85,417.13c2.62.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,222.85,417.13Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M238.1,426.26l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.07a.57.57,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08L237,424l-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.05a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M236.35,421.24c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,236.35,421.24Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M184.14,434.68a.48.48,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,1,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.37.06.79.13.48,0,.75.07.54,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.09,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M182.4,429.66c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,182.4,429.66Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <polygon points="270.27 214.06 270.23 225.98 136.16 267.72 136.19 255.79 270.27 214.06" style="fill: #4c4284"/>
                            <path d="M197.65,438.79a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.1.1a.91.91,0,0,1-.16.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.36-.06a9.48,9.48,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.79.13.49,0,.75.07.53,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12c.18-.05.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l0-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M195.9,433.77c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,195.9,433.77Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M197.57,430.59l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07.47.47,0,0,1-.09.12.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.05.74.06l.54,0,.73,0,.55,0,.72,0L193,432l.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M195.82,425.57c2.62.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,195.82,425.57Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M211.07,434.7l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.24,0,.36.06s.52.1.8.13l.48.05.75.06.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M209.33,429.68c2.62.81,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,209.33,429.68Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M265.2,409.81a.94.94,0,0,0,.09-.12l0-.07.06-.12s0-.05,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.19.19,0,0,0,0,.07l-.06.12,0,.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.62.11-.53.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.09A.83.83,0,0,0,265,410l.05,0,.06-.06A.46.46,0,0,0,265.2,409.81Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M263.46,404.79c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,263.46,404.79Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                        <g>
                          <path d="M278.71,413.92l.09-.12,0-.07a.34.34,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.21-.11.11-.09a.91.91,0,0,0,.16-.11l.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M277,408.9c2.63.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,277,408.9Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M278.63,405.71l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.73,0l-.54,0-.73,0H271l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36,0c.26.05.52.1.79.13l.49.05c.25,0,.49.05.74.06l.54,0,.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M276.88,400.69c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,276.88,400.69Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                        <g>
                          <path d="M292.13,409.82l.09-.11,0-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0,0,.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.15s-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0l-.54,0-.73,0h-.54l-.75-.07-.48,0c-.28,0-.54-.08-.8-.12s-.24,0-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.05c.27.05.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.07.53-.08a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08L292,410l.05,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M290.39,404.8c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,290.39,404.8Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <polygon points="110.95 240.73 136.34 248.56 149.7 244.39 124.48 236.59 110.95 240.73" style="fill: #c1c1c1"/>
                      <polygon points="136.48 205.16 136.45 211.6 110.91 203.68 110.95 197.24 136.48 205.16" style="fill: #9e9e9e"/>
                      <polygon points="136.34 248.56 136.45 255.06 110.91 247.13 110.95 240.7 136.34 248.56" style="fill: #9e9e9e"/>
                      <polygon points="136.48 205.16 149.84 200.99 149.84 250.88 136.42 255.06 136.34 248.56 143.28 246.29 143.28 209.38 136.45 211.6 136.48 205.16" style="fill: #ccc"/>
                      <polygon points="268.72 214.22 255.36 218.39 255.36 168.22 268.78 164.04 268.87 170.54 261.93 172.81 261.93 210 268.76 207.79 268.72 214.22" style="fill: #ccc"/>
                      <polygon points="110.95 197.24 136.48 205.16 149.84 200.99 124.48 193.1 110.95 197.24" style="fill: #a7fcfa"/>
                      <polygon points="143.28 246.29 117.72 238.66 117.72 205.79 136.45 211.6 143.28 209.38 143.28 246.29" style="fill: #8c8c8c"/>
                    </g>
                    <polygon points="255.36 218.39 229.52 210.55 229.52 175.12 253.25 167.73 255.36 168.22 255.36 218.39" style="fill: #8c8c8c"/>
                    <g>
                      <g>
                        <path d="M292.23,401.36a.48.48,0,0,0,.09-.11l.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.54,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                        <path d="M290.49,396.34c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,290.49,396.34Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                      </g>
                      <g>
                        <path d="M305.74,405.47a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08a.94.94,0,0,0-.1.1.91.91,0,0,1-.16.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09l-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07.54,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12c.18-.05.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06L305,406l.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                        <path d="M304,400.45c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,304,400.45Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                      </g>
                    </g>
                    <polygon points="255.44 168.22 253.34 167.73 266.93 163.5 268.87 164.04 255.44 168.22" style="fill: #c1c1c1"/>
                    <polygon points="268.69 207.79 261.87 206.06 261.87 210 268.69 207.79" style="fill: #c1c1c1"/>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="270.25 151.58 136.19 193.31 110.66 185.39 245.04 143.59 270.25 151.58" style="fill: #605eaa"/>
                        <polygon points="136.19 193.31 136.16 205.24 110.62 197.31 110.66 185.39 136.19 193.31" style="fill: #392f6e"/>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M238.15,355.56l.09-.12,0-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l0,.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06c.26.05.52.1.8.13l.48.05.75.06.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M236.41,350.54c2.62.81,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,236.41,350.54Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M251.66,359.67l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.12a.18.18,0,0,1,0,.07.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.26.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49,0,.74.06l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M249.91,354.65c2.62.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,249.91,354.65Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M251.57,351.46l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05.67-.06.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M249.83,346.44c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,249.83,346.44Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M265.08,355.57l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.25,1.25,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.55l-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06a10,10,0,0,1-1.05-.27c-1.33-.4-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07H258l.74,0h.54l.72,0,.55-.05.68-.06.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M263.33,350.55c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,263.33,350.55Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M211.17,363.76l.09-.11a.18.18,0,0,1,.05-.07.69.69,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.54l-.73,0h-.54l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54l.73,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M209.43,358.74c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,209.43,358.74Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M224.68,367.87a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0H217l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.18,1,.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11s0,0,0,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M222.93,362.85c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,222.93,362.85Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M224.59,359.67a.47.47,0,0,0,.09-.12l0-.07.06-.12a.25.25,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18s0,.05,0,.07l-.06.12a.18.18,0,0,1,0,.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13,0a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M222.85,354.65c2.62.8,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,222.85,354.65Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M238.1,363.78l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.07a.57.57,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.05a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M236.35,358.76c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,236.35,358.76Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M184.14,372.2a.48.48,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,1,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12L183,370l-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.37.06.79.13.48,0,.75.07.54,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.09,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M182.4,367.18c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,182.4,367.18Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <polygon points="270.27 151.57 270.23 163.5 136.16 205.24 136.19 193.31 270.27 151.57" style="fill: #4c4284"/>
                            <path d="M197.65,376.31a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.1.1a.91.91,0,0,1-.16.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.36-.06a9.48,9.48,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.79.13.49,0,.75.07.53,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12c.18-.05.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l0-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M195.9,371.29c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,195.9,371.29Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M197.57,368.11l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07.47.47,0,0,1-.09.12.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49,0,.74.06l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M195.82,363.09c2.62.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,195.82,363.09Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                          <g>
                            <path d="M211.07,372.22l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16-.6.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26,0,.52.1.8.13l.48,0,.75.06.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                            <path d="M209.33,367.2c2.62.81,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,209.33,367.2Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M265.2,347.33a.94.94,0,0,0,.09-.12l0-.07.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.19.19,0,0,0,0,.07l-.06.12,0,.07a.94.94,0,0,1-.09.12.46.46,0,0,1-.07.07l-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.62.11-.53.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06A.46.46,0,0,0,265.2,347.33Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M263.46,342.31c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,263.46,342.31Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                        <g>
                          <path d="M278.71,351.44l.09-.12,0-.07a.34.34,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.13.13-.07.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M277,346.42c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,277,346.42Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M278.63,343.23l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.73,0l-.54,0-.73,0H271l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36.05c.26,0,.52.1.79.13l.49.05c.25,0,.49,0,.74.06s.36,0,.54,0l.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M276.88,338.21c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,276.88,338.21Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                        <g>
                          <path d="M292.13,347.34l.09-.11,0-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0,0,.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.15s-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0l-.54,0-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36,0c.27.05.52.1.8.13l.48,0,.75.06c.18,0,.36,0,.54,0l.73,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                          <path d="M290.39,342.32c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,290.39,342.32Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <polygon points="110.95 178.25 136.34 186.08 149.7 181.91 124.48 174.11 110.95 178.25" style="fill: #c1c1c1"/>
                      <polygon points="136.48 142.68 136.45 149.12 110.91 141.19 110.95 134.76 136.48 142.68" style="fill: #9e9e9e"/>
                      <polygon points="136.34 186.08 136.45 192.58 110.91 184.66 110.95 178.22 136.34 186.08" style="fill: #9e9e9e"/>
                      <polygon points="136.48 142.68 149.84 138.51 149.84 188.4 136.42 192.58 136.34 186.08 143.28 183.81 143.28 146.91 136.45 149.12 136.48 142.68" style="fill: #ccc"/>
                      <polygon points="269.82 151.75 256.45 155.91 256.45 105.74 269.88 101.56 269.96 108.06 261.93 110.33 261.93 147.52 269.85 145.31 269.82 151.75" style="fill: #ccc"/>
                      <polygon points="110.95 134.76 136.48 142.68 149.84 138.51 124.48 130.62 110.95 134.76" style="fill: #a7fcfa"/>
                      <polygon points="143.28 183.81 117.72 176.18 117.72 143.31 136.45 149.12 143.28 146.91 143.28 183.81" style="fill: #8c8c8c"/>
                    </g>
                    <polygon points="256.45 155.91 230.62 148.07 230.62 112.64 254.35 105.25 256.45 105.74 256.45 155.91" style="fill: #8c8c8c"/>
                    <g>
                      <g>
                        <path d="M292.23,338.88a.48.48,0,0,0,.09-.11l.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.54,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1L290,340l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                        <path d="M290.49,333.86c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,290.49,333.86Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                      </g>
                      <g>
                        <path d="M305.74,343a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.1.1a.91.91,0,0,1-.16.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07.54,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12c.18-.05.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11s0,0,0,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                        <path d="M304,338c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,304,338Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                      </g>
                    </g>
                    <polygon points="256.45 105.74 254.35 105.25 267.94 101.02 269.88 101.56 256.45 105.74" style="fill: #c1c1c1"/>
                    <polygon points="269.85 145.31 261.82 143.59 261.82 147.52 269.85 145.31" style="fill: #c1c1c1"/>
                    <g>
                      <g>
                        <g>
                          <polygon points="270.25 89.25 136.19 130.98 110.66 123.06 245.04 81.26 270.25 89.25" style="fill: #605eaa"/>
                          <polygon points="136.19 130.98 136.16 142.91 110.62 134.99 110.66 123.06 136.19 130.98" style="fill: #392f6e"/>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M238.15,293.23l.09-.11a.18.18,0,0,1,0-.07.69.69,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12L237,291c-.15.06-.31.12-.48.17l-.6.16-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1,.26l.36.06.8.13.48,0,.75.07H231l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0L237,294l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M236.41,288.21c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,236.41,288.21Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M251.66,297.34a.76.76,0,0,0,.08-.11l0-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13,0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.26.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0H244l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M249.91,292.32c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,249.91,292.32Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M251.57,289.14a.94.94,0,0,0,.09-.12l.05-.07.06-.12s0-.05,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18s0,0,0,.07l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.62.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06A.46.46,0,0,0,251.57,289.14Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M249.83,284.12c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,249.83,284.12Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M265.08,293.25l.09-.12,0-.07a.34.34,0,0,0,.06-.12l0-.07a1.13,1.13,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08L264,291l-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05a2.24,2.24,0,0,0,.27-.13l.13-.07.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M263.33,288.23c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,263.33,288.23Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M211.17,301.44l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0-.75-.06-.48,0c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.24,0,.36.06.27.05.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M209.43,296.42c2.62.81,2.65,2.13.07,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,209.43,296.42Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M224.68,305.55a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.83.83,0,0,1-.08.12l-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.25,0,.49,0,.74.06l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.14-.06.27-.12L224,306l.2-.11.12-.08.16-.12,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M222.93,300.53c2.62.81,2.66,2.13.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,222.93,300.53Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M224.59,297.34a.44.44,0,0,0,.09-.11l0-.07.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13,0,.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06a9.25,9.25,0,0,1-1-.27c-1.33-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05.67-.06.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M222.85,292.32c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,222.85,292.32Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M238.1,301.45l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.08-.11.09-.16.12-.11.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06L233,300l-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06a10,10,0,0,1-1.05-.27c-1.33-.4-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07H231l.74,0h.54l.72,0,.55-.05.68-.06.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M236.35,296.43c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.38,18.38,0,0,1,236.35,296.43Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M184.14,309.88l.09-.11.05-.08.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.08-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.48.12a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.23,0-.48,0-.72,0l-.54,0-.73,0h-.54l-.74-.07-.49,0c-.28,0-.53-.08-.8-.12-.11,0-.24,0-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.37.05c.26.05.52.1.79.13l.48.05.75.06.54,0,.73,0,.54,0c.24,0,.49,0,.72,0l.55-.05.68-.07.53-.08a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.07A.46.46,0,0,0,184.14,309.88Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M182.4,304.86c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,182.4,304.86Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <polygon points="270.25 89.25 270.21 101.18 136.16 142.91 136.19 130.98 270.25 89.25" style="fill: #4c4284"/>
                              <path d="M197.65,314l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07-.1.1-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0l-.55,0-.73,0H190l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.05c.26.05.52.1.79.13l.49.05c.25,0,.49.05.75.06s.35,0,.53,0l.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,0-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M195.9,309c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,195.9,309Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M197.57,305.78a.76.76,0,0,0,.08-.11l0-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.55c.24,0,.48,0,.72,0l.55,0,.68-.06c.18,0,.34-.06.52-.09l.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M195.82,300.76c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,195.82,300.76Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M211.07,309.89l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.11L211,307l-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17l-.6.16-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0L211,310Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M209.33,304.87c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,209.33,304.87Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path d="M292.23,276.56l.09-.11.05-.08a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.48.12a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.72,0l-.54,0-.73,0h-.54l-.75-.07-.48,0c-.28,0-.53-.08-.8-.12s-.24,0-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.05c.27.05.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0c.24,0,.49,0,.72,0l.55-.05.68-.07.53-.08a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M290.49,271.54c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,290.49,271.54Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M305.74,280.67l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07-.1.1-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.73,0l-.54,0-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36.05c.26.05.52.1.79.13l.49.05c.25,0,.49,0,.74.06l.54,0,.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.07.52-.08a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M304,275.65c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,304,275.65Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M305.66,272.46a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.26.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0H298l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12L305,273l.2-.12a.5.5,0,0,0,.12-.08l.15-.11,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M303.91,267.44c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,303.91,267.44Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M319.16,276.57l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17l-.6.16-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07H312l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,0,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M317.41,271.55c2.63.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.49,18.49,0,0,1,317.41,271.55Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M265.2,285l.09-.11,0-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13,0,.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06q-.56-.12-1.05-.27c-1.32-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55-.05.67-.06.53-.09.62-.1.48-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M263.46,280c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,263.46,280Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M278.71,289.11l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a1.39,1.39,0,0,1-.06.13l0,.07a.76.76,0,0,1-.08.11l-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06a10,10,0,0,1-1.05-.27c-1.33-.4-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.73,0h.55l.72,0,.55-.05.68-.06.52-.09.63-.1.48-.12L277,290l.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M277,284.09c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,277,284.09Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M278.63,280.9a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13c-.12,0-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07.54,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.34-.06.52-.09l.63-.1.47-.12c.18-.05.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08.91.91,0,0,0,.16-.11l0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M276.88,275.88c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,276.88,275.88Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                            <g>
                              <path d="M292.13,285l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07a2.21,2.21,0,0,1-.27.12l-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06A10.07,10.07,0,0,1,281,283c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0,.75.07.54,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                              <path d="M290.39,280c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,290.39,280Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="149.16 122.06 136.48 125.7 110.95 117.78 125.68 114.36 149.16 122.06" style="fill: #f8c778"/>
                      <polygon points="136.48 125.7 136.45 130.63 110.91 122.7 110.95 117.77 136.48 125.7" style="fill: #c47e2a"/>
                    </g>
                    <g>
                      <g>
                        <path d="M184.43,304.59l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11L184,302l-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09l-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.8.13.48,0,.75.07.53,0,.74,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                        <path d="M182.69,299.57c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,182.69,299.57Z" transform="translate(-55.09 -186.5)" style="fill: #f8c778"/>
                      </g>
                      <g>
                        <polygon points="149.16 122.06 149.12 126.99 136.45 130.63 136.48 125.7 149.16 122.06" style="fill: #eaaf52"/>
                        <path d="M197.94,308.7a.44.44,0,0,0,.08-.11l0-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.87,9.87,0,0,0,1,.26l.37.06.79.13.49,0,.74.07.54,0,.73,0,.55,0,.72,0,.55-.06c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                        <path d="M196.19,303.68c2.62.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,196.19,303.68Z" transform="translate(-55.09 -186.5)" style="fill: #f8c778"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="397.27 405.7 397.23 429.56 370.2 421.35 370.24 397.49 397.27 405.7" style="fill: #0e4772"/>
                      <polygon points="424.12 397.53 397.27 405.7 370.24 397.49 397.09 389.32 424.12 397.53" style="fill: #3397e2"/>
                      <path d="M445.21,584.6a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0,0,0-.07a.57.57,0,0,0,0-.18v-3a.57.57,0,0,1,0,.18.25.25,0,0,0,0,.07l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.62.11-.53.08-.68.07-.54,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #969696"/>
                      <path d="M443.47,579.58c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.49,18.49,0,0,1,443.47,579.58Z" transform="translate(-55.09 -186.5)" style="fill: #ededed"/>
                    </g>
                    <g>
                      <polygon points="424.12 397.53 424.08 421.39 397.23 429.56 397.27 405.7 424.12 397.53" style="fill: #256eba"/>
                      <path d="M458.72,588.71l.09-.12a.43.43,0,0,0,0-.07.34.34,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a1.1,1.1,0,0,1,0,.18l0,.07a.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.13-.08.21-.11.11-.08.16-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #969696"/>
                      <path d="M457,583.69c2.63.8,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.49,18.49,0,0,1,457,583.69Z" transform="translate(-55.09 -186.5)" style="fill: #ededed"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M458.64,580.5l.08-.11a.35.35,0,0,0,0-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l0,.07a.76.76,0,0,1-.08.11l-.08.07-.1.1-.16.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09-.68.06-.55,0-.72,0h-.55l-.73,0H451l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27l.36.06c.26,0,.52.09.79.12l.49,0,.75.07h.53l.73,0h.55l.72,0,.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12L458,581l.21-.12.11-.08.16-.12,0,0s0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #969696"/>
                      <path d="M456.89,575.48c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.38,18.38,0,0,1,456.89,575.48Z" transform="translate(-55.09 -186.5)" style="fill: #ededed"/>
                    </g>
                    <g>
                      <path d="M472.14,584.61l.09-.11,0-.08a.61.61,0,0,0,.05-.12l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09-.68.06-.55,0-.72,0h-.54l-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.55-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.49.15,1,.27c.12,0,.25,0,.36.06.27,0,.52.09.8.12l.48,0,.75.07H465l.73,0h.54c.24,0,.49,0,.72,0l.55-.05.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #969696"/>
                      <path d="M470.4,579.59c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.42,18.42,0,0,1,470.4,579.59Z" transform="translate(-55.09 -186.5)" style="fill: #ededed"/>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="397.27 381.64 397.23 405.5 370.2 397.29 370.24 373.44 397.27 381.64" style="fill: #0e4772"/>
                      <polygon points="424.12 373.48 397.27 381.64 370.24 373.44 397.09 365.27 424.12 373.48" style="fill: #ddd"/>
                      <path d="M445.21,560.54l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.68.06l-.54,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06q-.56-.12-1.05-.27c-1.32-.4-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55,0,.67-.06.53-.09.63-.1.47-.13.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #969696"/>
                      <path d="M443.47,555.52c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-3A18.38,18.38,0,0,1,443.47,555.52Z" transform="translate(-55.09 -186.5)" style="fill: #ededed"/>
                    </g>
                    <g>
                      <polygon points="424.12 373.48 424.08 397.33 397.23 405.5 397.27 381.64 424.12 373.48" style="fill: #256eba"/>
                      <path d="M458.72,564.65l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a1.39,1.39,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.55l-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.74,0h.54l.72,0,.55,0,.68-.06.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #969696"/>
                      <path d="M457,559.63c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.38,18.38,0,0,1,457,559.63Z" transform="translate(-55.09 -186.5)" style="fill: #ededed"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M458.64,556.44a.44.44,0,0,0,.08-.11l0-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l0,.07a.44.44,0,0,1-.08.11l-.08.08-.1.1a.91.91,0,0,1-.16.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.36-.06a9.48,9.48,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.79.13.49,0,.75.07.53,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12c.18,0,.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #969696"/>
                      <path d="M456.89,551.42c2.63.81,2.66,2.14.08,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,456.89,551.42Z" transform="translate(-55.09 -186.5)" style="fill: #ededed"/>
                    </g>
                    <g>
                      <path d="M472.14,560.55a.48.48,0,0,0,.09-.11l0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.8.13.48,0,.75.07.54,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #969696"/>
                      <path d="M470.4,555.53c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,470.4,555.53Z" transform="translate(-55.09 -186.5)" style="fill: #ededed"/>
                    </g>
                  </g>
                </g>
                <g>
                  <path d="M516.66,553.09c0,11.68-26.56,19.92-60.42,19.92S394,559.74,394,548.06v-9.67l122.63,5Z" transform="translate(-55.09 -186.5)" style="fill: #4c4284"/>
                  <path d="M516.66,543.42c0,11.68-26.56,19.92-60.42,19.92S394,550.06,394,538.39s27.45-20,61.31-20S516.66,531.74,516.66,543.42Z" transform="translate(-55.09 -186.5)" style="fill: #605eaa"/>
                </g>
                <g>
                  <polygon points="266.47 407.66 266.44 431.51 253 435.58 253.04 411.73 266.47 407.66" style="fill: #256eba"/>
                  <polygon points="253.04 411.73 253 435.58 239.48 431.49 239.51 407.63 253.04 411.73" style="fill: #0e4772"/>
                  <polygon points="266.47 407.66 253.04 411.73 239.51 407.63 252.94 403.56 266.47 407.66" style="fill: #3397e2"/>
                  <path d="M314.49,594.73l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.12a.46.46,0,0,0-.07.07l-.11.1-.15.11-.12.08-.2.12-.14.07a1.31,1.31,0,0,1-.27.12l-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07c.18,0,.35-.06.53-.09l.62-.1.48-.12c.17,0,.36-.08.52-.13l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #0e4772"/>
                  <path d="M312.75,589.71c2.62.81,2.65,2.14.07,3a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,312.75,589.71Z" transform="translate(-55.09 -186.5)" style="fill: #3397e2"/>
                </g>
                <g>
                  <path d="M242.2,422.94" transform="translate(-55.09 -186.5)" style="fill: #abdf3b"/>
                  <g>
                    <g>
                      <polygon points="190.85 214.36 190.81 238.21 163.78 230 163.82 206.15 190.85 214.36" style="fill: #82e0e0"/>
                      <polygon points="217.7 206.19 190.85 214.36 163.82 206.15 190.67 197.98 217.7 206.19" style="fill: #a7fcfa"/>
                    </g>
                    <polygon points="217.7 206.19 217.67 230.04 190.81 238.21 190.85 214.36 217.7 206.19" style="fill: #b7f1f4"/>
                  </g>
                  <polygon points="190.67 197.98 190.85 214.36 163.82 206.15 190.67 197.98" style="fill: #b7f1f4"/>
                  <polygon points="217.66 202.21 190.79 210.33 163.87 202.21 190.74 194.08 217.66 202.21" style="fill: #f9d5a5"/>
                  <polygon points="163.87 204.8 190.76 213 190.79 210.33 163.87 202.21 163.87 204.8" style="fill: #d69034"/>
                  <polygon points="217.66 202.21 217.66 204.75 190.76 213 190.79 210.33 217.66 202.21" style="fill: #f8c778"/>
                </g>
                <g>
                  <path d="M269.44,352.13" transform="translate(-55.09 -186.5)" style="fill: #abdf3b"/>
                  <g>
                    <g>
                      <polygon points="218.1 143.55 218.06 167.41 191.03 159.2 191.07 135.34 218.1 143.55" style="fill: #82e0e0"/>
                      <polygon points="244.95 135.38 218.1 143.55 191.07 135.34 217.92 127.17 244.95 135.38" style="fill: #a7fcfa"/>
                    </g>
                    <polygon points="244.95 135.38 244.91 159.24 218.06 167.41 218.1 143.55 244.95 135.38" style="fill: #b7f1f4"/>
                  </g>
                  <polygon points="217.92 127.17 218.1 143.55 191.07 135.34 217.92 127.17" style="fill: #b7f1f4"/>
                  <polygon points="245.02 131.34 218.04 139.52 191.07 131.23 218.06 123.04 245.02 131.34" style="fill: #256eba"/>
                  <polygon points="191.07 133.82 218.01 142.19 218.04 139.52 191.07 131.23 191.07 133.82" style="fill: #0e4772"/>
                  <polygon points="245.02 131.34 245.02 133.89 218.01 142.19 218.04 139.52 245.02 131.34" style="fill: #3397e2"/>
                </g>
                <g>
                  <g>
                    <path d="M251.78,351.39l.09-.11a.35.35,0,0,1,0-.08.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12L251,349l-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.55-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1,.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.74,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06L251,352l.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M250,346.37c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.42,18.42,0,0,1,250,346.37Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                  <g>
                    <path d="M265.29,355.5l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.09.11.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.53.09-.67.06-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12l-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49.05.74.07h.54l.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.67-.06.53-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0s0-.05.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M263.54,350.48c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,263.54,350.48Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M224.51,422.12l.09-.11.05-.07a.69.69,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.54,0-.73,0-.54,0-.75-.07-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54l.73,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12c.17-.05.36-.08.52-.14l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M222.77,417.1c2.62.81,2.65,2.14.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,222.77,417.1Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                  <g>
                    <path d="M238,426.23a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55.06-.73,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.18,1.05.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0,.55-.05c.23,0,.46,0,.68-.06s.34-.06.52-.09l.63-.1.47-.12.53-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.16-.11s0,0,0,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M236.27,421.21c2.62.81,2.66,2.14.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,236.27,421.21Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                </g>
                <g>
                  <path d="M242.2,484.9" transform="translate(-55.09 -186.5)" style="fill: #abdf3b"/>
                  <g>
                    <g>
                      <polygon points="190.85 276.31 190.81 300.17 163.78 291.96 163.82 268.1 190.85 276.31" style="fill: #82e0e0"/>
                      <polygon points="217.7 268.14 190.85 276.31 163.82 268.1 190.67 259.94 217.7 268.14" style="fill: #a7fcfa"/>
                    </g>
                    <polygon points="217.7 268.14 217.67 292 190.81 300.17 190.85 276.31 217.7 268.14" style="fill: #b7f1f4"/>
                  </g>
                  <polygon points="190.67 259.94 190.85 276.31 163.82 268.1 190.67 259.94" style="fill: #b7f1f4"/>
                  <polygon points="217.52 264.07 190.79 272.29 163.85 264.06 190.58 255.83 217.52 264.07" style="fill: #f9d5a5"/>
                  <polygon points="163.85 266.64 190.76 274.95 190.79 272.29 163.85 264.06 163.85 266.64" style="fill: #d69034"/>
                  <polygon points="217.52 264.07 217.52 266.62 190.76 274.95 190.79 272.29 217.52 264.07" style="fill: #f8c778"/>
                </g>
                <g>
                  <g>
                    <path d="M224.51,484.08l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05-.72,0h-.54l-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12s-.24,0-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.27,0,.52.09.8.12l.48.05.75.07h.54l.73,0,.54,0c.24,0,.48,0,.72,0l.55-.05.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M222.77,479.06c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-3A18.42,18.42,0,0,1,222.77,479.06Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                  <g>
                    <path d="M238,488.19l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.53.09-.67.06-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0s0-.05.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M236.27,483.17c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,236.27,483.17Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="188.67 404.73 188.64 428.59 161.61 420.38 161.64 396.52 188.67 404.73" style="fill: #c47e2a"/>
                      <polygon points="215.52 396.56 188.67 404.73 161.64 396.52 188.49 388.35 215.52 396.56" style="fill: #f8c778"/>
                      <path d="M236.62,583.63l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12l0-.06a1.37,1.37,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07-.1.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16a5.43,5.43,0,0,1-.61.16c-.16,0-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.36,0c-.37-.08-.73-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                      <path d="M234.87,578.61c2.63.81,2.66,2.13.08,2.95a18.38,18.38,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.49,18.49,0,0,1,234.87,578.61Z" transform="translate(-55.09 -186.5)" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <polygon points="215.52 396.56 215.49 420.42 188.64 428.59 188.67 404.73 215.52 396.56" style="fill: #eaaf52"/>
                      <path d="M250.12,587.74a.94.94,0,0,0,.09-.12l0-.07.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l0,.08-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16a6.06,6.06,0,0,1-.61.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.28,0-.53-.08-.8-.13l-.36,0q-.56-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.48,0,.75.06.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                      <path d="M248.38,582.72c2.62.81,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,248.38,582.72Z" transform="translate(-55.09 -186.5)" style="fill: #f8c778"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M250,579.53l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1,0,.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.11-.6.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.5v3c0,.54.66,1.09,2,1.49a10,10,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                      <path d="M248.3,574.51c2.62.81,2.65,2.14.07,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.66-2.14-.08-3A18.42,18.42,0,0,1,248.3,574.51Z" transform="translate(-55.09 -186.5)" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M263.55,583.64a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.09.11l-.07.08-.11.1-.15.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.09,2,1.49a9.85,9.85,0,0,0,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0,.55,0c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                      <path d="M261.8,578.62c2.62.81,2.66,2.14.08,3a18.6,18.6,0,0,1-9.43,0c-2.63-.81-2.67-2.14-.08-3A18.49,18.49,0,0,1,261.8,578.62Z" transform="translate(-55.09 -186.5)" style="fill: #f8c778"/>
                    </g>
                  </g>
                </g>
                <g>
                  <path d="M204.49,625.38" transform="translate(-55.09 -186.5)" style="fill: #abdf3b"/>
                  <g>
                    <g>
                      <polygon points="153.14 416.79 153.11 440.65 126.08 432.44 126.11 408.58 153.14 416.79" style="fill: #82e0e0"/>
                      <polygon points="180 408.62 153.14 416.79 126.11 408.58 152.97 400.41 180 408.62" style="fill: #a7fcfa"/>
                    </g>
                    <polygon points="180 408.62 179.96 432.48 153.11 440.65 153.14 416.79 180 408.62" style="fill: #b7f1f4"/>
                  </g>
                  <polygon points="152.97 400.41 153.14 416.79 126.11 408.58 152.97 400.41" style="fill: #b7f1f4"/>
                  <polygon points="180.02 404.62 153.09 412.77 126.24 404.62 153.18 396.46 180.02 404.62" style="fill: #f685de"/>
                  <polygon points="126.24 407.2 153.06 415.43 153.09 412.77 126.24 404.62 126.24 407.2" style="fill: #b0549d"/>
                  <polygon points="180.02 404.62 180.02 407.17 153.06 415.43 153.09 412.77 180.02 404.62" style="fill: #d667c6"/>
                </g>
                <g>
                  <path d="M253.3,637.38" transform="translate(-55.09 -186.5)" style="fill: #abdf3b"/>
                  <g>
                    <g>
                      <polygon points="201.95 428.8 201.91 452.65 174.88 444.44 174.92 420.59 201.95 428.8" style="fill: #82e0e0"/>
                      <polygon points="228.8 420.63 201.95 428.8 174.92 420.59 201.77 412.42 228.8 420.63" style="fill: #a7fcfa"/>
                    </g>
                    <polygon points="228.8 420.63 228.77 444.48 201.91 452.65 201.95 428.8 228.8 420.63" style="fill: #b7f1f4"/>
                  </g>
                  <polygon points="201.77 412.42 201.95 428.8 174.92 420.59 201.77 412.42" style="fill: #b7f1f4"/>
                  <polygon points="228.78 416.58 201.89 424.77 174.99 416.63 201.89 408.44 228.78 416.58" style="fill: #f9d5a5"/>
                  <polygon points="174.99 419.22 201.86 427.43 201.89 424.77 174.99 416.63 174.99 419.22" style="fill: #d69034"/>
                  <polygon points="228.78 416.58 228.78 419.13 201.86 427.43 201.89 424.77 228.78 416.58" style="fill: #f8c778"/>
                </g>
                <g>
                  <path d="M170.63,635.33" transform="translate(-55.09 -186.5)" style="fill: #abdf3b"/>
                  <g>
                    <g>
                      <polygon points="119.28 426.75 119.25 450.6 92.22 442.39 92.25 418.54 119.28 426.75" style="fill: #82e0e0"/>
                      <polygon points="146.13 418.58 119.28 426.75 92.25 418.54 119.1 410.37 146.13 418.58" style="fill: #a7fcfa"/>
                    </g>
                    <polygon points="146.13 418.58 146.1 442.44 119.25 450.6 119.28 426.75 146.13 418.58" style="fill: #b7f1f4"/>
                  </g>
                  <polygon points="119.1 410.37 119.28 426.75 92.25 418.54 119.1 410.37" style="fill: #b7f1f4"/>
                  <polygon points="146.07 414.56 119.22 422.72 92.3 414.56 119.15 406.4 146.07 414.56" style="fill: #256eba"/>
                  <polygon points="92.3 417.15 119.19 425.39 119.22 422.72 92.3 414.56 92.3 417.15" style="fill: #0e4772"/>
                  <polygon points="146.07 414.56 146.07 417.11 119.19 425.39 119.22 422.72 146.07 414.56" style="fill: #3397e2"/>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="97.39 362.96 102.12 361.52 102.12 326.56 97.39 328.01 97.39 362.96" style="fill: #f8c778"/>
                      <polygon points="72.09 352.28 97.39 362.96 97.39 328.01 72.09 318.19 72.09 352.28" style="fill: #d69034"/>
                      <polygon points="72.09 318.19 97.39 328.01 102.12 326.52 76.82 316.7 72.09 318.19" style="fill: #f9d5a5"/>
                    </g>
                    <g>
                      <g>
                        <polygon points="81.99 335.79 76.45 334.72 97.78 327.98 103.49 329.09 81.99 335.79" style="fill: #f9d5a5"/>
                        <polygon points="81.93 370.9 82.02 335.8 103.49 329.09 103.4 364.19 81.93 370.9" style="fill: #f8c778"/>
                        <path d="M152.18,530.39a9.85,9.85,0,0,1,1.49-8.81,3.18,3.18,0,0,1,2.88-1.4l-2.38-.41a3.17,3.17,0,0,0-2.88,1.41,9.85,9.85,0,0,0-1.48,8.8,3.14,3.14,0,0,0,2.22,2.24l2.38.41A3.17,3.17,0,0,1,152.18,530.39Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                        <path d="M157.29,531.22c-1.82,2.2-4.11,1.83-5.11-.83a9.85,9.85,0,0,1,1.49-8.81c1.83-2.2,4.11-1.83,5.11.84A9.83,9.83,0,0,1,157.29,531.22Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                      </g>
                      <g>
                        <path d="M141.36,551.31a9.84,9.84,0,0,1,1.49-8.81,3.14,3.14,0,0,1,2.87-1.4l-2.37-.41a3.15,3.15,0,0,0-2.88,1.4A9.85,9.85,0,0,0,139,550.9a3.14,3.14,0,0,0,2.23,2.24l2.37.4A3.11,3.11,0,0,1,141.36,551.31Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                        <path d="M146.46,552.14c-1.82,2.2-4.1,1.83-5.1-.83a9.84,9.84,0,0,1,1.49-8.81c1.82-2.2,4.1-1.83,5.1.84A9.8,9.8,0,0,1,146.46,552.14Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                      </g>
                      <g>
                        <polygon points="81.93 370.9 76.39 369.83 76.45 334.72 81.99 335.79 81.93 370.9" style="fill: #c47e2a"/>
                        <path d="M141.43,533.76a9.85,9.85,0,0,1,1.49-8.81,3.15,3.15,0,0,1,2.88-1.4l-2.38-.41a3.18,3.18,0,0,0-2.88,1.4,9.85,9.85,0,0,0-1.49,8.81,3.15,3.15,0,0,0,2.23,2.24l2.38.41A3.15,3.15,0,0,1,141.43,533.76Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                        <path d="M146.54,534.59c-1.82,2.2-4.11,1.83-5.11-.83a9.85,9.85,0,0,1,1.49-8.81c1.82-2.2,4.11-1.83,5.11.84A9.86,9.86,0,0,1,146.54,534.59Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                      </g>
                      <g>
                        <path d="M152.11,547.94a9.84,9.84,0,0,1,1.49-8.81,3.17,3.17,0,0,1,2.88-1.41l-2.38-.41a3.17,3.17,0,0,0-2.88,1.41,9.84,9.84,0,0,0-1.48,8.81,3.13,3.13,0,0,0,2.22,2.23l2.38.41A3.14,3.14,0,0,1,152.11,547.94Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                        <path d="M157.22,548.77c-1.82,2.2-4.11,1.82-5.11-.83a9.84,9.84,0,0,1,1.49-8.81c1.82-2.2,4.11-1.83,5.11.83A9.85,9.85,0,0,1,157.22,548.77Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="50.81 325.07 45.27 323.42 66.59 316.68 72.31 318.37 50.81 325.07" style="fill: #f9d5a5"/>
                        <polygon points="50.75 360.18 50.84 325.08 72.31 318.37 72.22 353.47 50.75 360.18" style="fill: #f8c778"/>
                      </g>
                      <g>
                        <path d="M110.14,540a9.84,9.84,0,0,1,1.49-8.81,3.17,3.17,0,0,1,2.88-1.41l-2.38-.41a3.17,3.17,0,0,0-2.88,1.41,9.84,9.84,0,0,0-1.49,8.81,3.14,3.14,0,0,0,2.23,2.23l2.38.41A3.14,3.14,0,0,1,110.14,540Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                        <path d="M115.25,540.87c-1.82,2.2-4.11,1.82-5.11-.83a9.84,9.84,0,0,1,1.49-8.81c1.82-2.21,4.1-1.83,5.1.83A9.85,9.85,0,0,1,115.25,540.87Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                      </g>
                      <polygon points="50.75 360.18 45.2 358.53 45.27 323.42 50.81 325.07 50.75 360.18" style="fill: #c47e2a"/>
                      <g>
                        <path d="M120.9,536.66a9.85,9.85,0,0,1,1.49-8.81,3.16,3.16,0,0,1,2.87-1.4l-2.37-.41a3.16,3.16,0,0,0-2.88,1.41,9.83,9.83,0,0,0-1.49,8.8,3.14,3.14,0,0,0,2.23,2.24l2.37.41A3.14,3.14,0,0,1,120.9,536.66Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                        <path d="M126,537.49c-1.82,2.2-4.1,1.83-5.1-.83a9.85,9.85,0,0,1,1.49-8.81c1.82-2.2,4.1-1.83,5.1.84A9.8,9.8,0,0,1,126,537.49Z" transform="translate(-55.09 -186.5)" style="fill: #eaaf52"/>
                      </g>
                    </g>
                    <g>
                      <polygon points="71.66 371.25 76.39 369.8 76.39 334.85 71.66 336.3 71.66 371.25" style="fill: #f8c778"/>
                      <polygon points="46.36 361.43 71.66 371.25 71.66 336.3 46.36 326.48 46.36 361.43" style="fill: #d69034"/>
                      <polygon points="46.36 326.48 71.66 336.3 76.39 334.81 51.09 324.99 46.36 326.48" style="fill: #f9d5a5"/>
                    </g>
                    <polygon points="55.05 326.66 72.31 321.16 93.33 329.39 76.39 334.81 55.05 326.66" style="fill: #725350"/>
                  </g>
                  <rect x="64.76" y="326.4" width="19.4" height="3.02" rx="1.51" style="fill: #513937"/>
                  <path d="M136.94,515.85a2.77,2.77,0,0,0,.12-.41c9.23-51.7,13.41-93.06,10.05-93.66-2.65-.47-10.31,26.17-17.64,62.27a.78.78,0,0,1-1.53,0c-7.32-36.1-13.19-62.92-15.8-62.27-4.71,1.17.21,43.15,9.25,94.07Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                  <path d="M147.67,420.84c-2.66-.48-9.61,25.3-17,61.68a.66.66,0,0,1-1.3,0c-7.37-36.38-14.32-62.16-17-61.68-3.34.6,1.32,42.29,10.41,93.46a1.93,1.93,0,0,0,1.9,1.59h10.64a1.93,1.93,0,0,0,1.9-1.59C146.35,463.13,151,421.44,147.67,420.84Z" transform="translate(-55.09 -186.5)" style="fill: #538d7e"/>
                  <path d="M148.08,421.31c-.14-.27-.3-.44-.48-.47a.52.52,0,0,0-.33.07l-3.77,20.38-3-1.88-.24.87,3.11,1.93-12.4,67.11a.8.8,0,0,1-1.59,0l-9.28-49.75,3.74-2.68-.21-.92-3.7,2.66-7-37.58a.67.67,0,0,0-.54-.21.62.62,0,0,0-.34.24l5.47,29.33-4.64-2.24.12,1,4.72,2.27,10.82,58a1.67,1.67,0,0,0,1.65,1.43h0a1.66,1.66,0,0,0,1.64-1.43l7.54-40.79,5.44-1.88c.05-.32.09-.65.13-1l-5.39,1.87Z" transform="translate(-55.09 -186.5)" style="fill: #6ebca5"/>
                </g>
                <g>
                  <g>
                    <path d="M224.51,484.16l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12.35.35,0,0,0-.05.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.15s-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05-.72,0h-.54l-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.05c.27,0,.52.1.8.13l.48,0,.75.06c.18,0,.36,0,.54,0l.73,0,.54,0c.24,0,.48,0,.72,0l.55,0,.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M222.77,479.14c2.62.81,2.65,2.13.07,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.42,18.42,0,0,1,222.77,479.14Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                  <g>
                    <path d="M238,488.27l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.13.07L237,486l-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.53.09-.67.06-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36.06c.26,0,.52.09.79.12l.49.05c.25,0,.49.05.74.06s.36,0,.54,0l.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17L237,489l.27-.12.13-.07.2-.12.12-.08.16-.12,0,0s0-.05.06-.07Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M236.27,483.25c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-3A18.38,18.38,0,0,1,236.27,483.25Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M265.2,285l.09-.11,0-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13,0,.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06q-.56-.12-1.05-.27c-1.32-.4-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55-.05.67-.06.53-.09.62-.1.48-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M263.46,280c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.62-.81-2.66-2.13-.07-2.95A18.38,18.38,0,0,1,263.46,280Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                  <g>
                    <path d="M278.71,289.11l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a1.39,1.39,0,0,1-.06.13l0,.07a.76.76,0,0,1-.08.11l-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06a10,10,0,0,1-1.05-.27c-1.33-.4-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.73,0h.55l.72,0,.55-.05.68-.06.52-.09.63-.1.48-.12L277,290l.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #392f6e"/>
                    <path d="M277,284.09c2.63.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.66-2.13-.08-2.95A18.38,18.38,0,0,1,277,284.09Z" transform="translate(-55.09 -186.5)" style="fill: #6c6cbc"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M367.76,552.17V533.46s1.61-8.62,10.76-7.35,12.7,13.89,5,20.29" transform="translate(-55.09 -186.5)" style="fill: #508b9b"/>
                    <path d="M353.57,571V540.16s.09-8.61,9.24-7.34,12.7,13,4.95,19.42v16.37l4.25,2.08v9.6Z" transform="translate(-55.09 -186.5)" style="fill: #162d33"/>
                    <polygon points="312.67 365.74 312.67 382.11 328.35 376.33 328.35 359.91 312.67 365.74" style="fill: #3b6a7a"/>
                    <polygon points="316.92 384.19 332.48 378.41 332.48 388.01 316.92 393.79 316.92 384.19" style="fill: #3b6a7a"/>
                    <polygon points="312.67 382.11 316.92 384.19 332.48 378.41 328.35 376.33 312.67 382.11" style="fill: #508b9b"/>
                    <polygon points="296.56 351.45 332.87 338.46 332.92 346.23 296.62 359.11 296.56 351.45" style="fill: #3b6a7a"/>
                    <g>
                      <path d="M395.63,495.21a8.07,8.07,0,0,1-7.7-3.81c-3-4.6-9.06-12.24-14.05-7.33s-3,13.12-1.44,16.61,9.38,10.89,13.12,11.48,12-2.44,12-2.44l-.31-2.25a6,6,0,0,1-5.38-6c-.32-5.59,4-4,4-4Z" transform="translate(-55.09 -186.5)" style="fill: #725350"/>
                      <path d="M395.94,497.49s-4.09-1.81-4,4a5.64,5.64,0,0,0,5.38,6Z" transform="translate(-55.09 -186.5)" style="fill: #162d33"/>
                      <path d="M398.09,509.76c-5.35-.25-8.35-3.46-8.23-8.79.05-2.39.77-4.09,2.13-5.05a5.36,5.36,0,0,1,4.45-.56l-.69,2.12a3.16,3.16,0,0,0-2.49.26c-.74.54-1.14,1.64-1.17,3.28-.09,4.13,2,6.32,6.1,6.52Z" transform="translate(-55.09 -186.5)" style="fill: #472b2a"/>
                      <path d="M412,504s-2-2.34-7-.74l-6.35,1-1.87-11.77a9.27,9.27,0,0,1,7-3.56c4.8-.3,7.1,2.31,7.88,4.48S412,504,412,504Z" transform="translate(-55.09 -186.5)" style="fill: #162d33"/>
                      <path d="M395.2,493.91l2.16,15.93s5.43,2.31,8.05-4l-1.09-8S397.71,499.08,395.2,493.91Z" transform="translate(-55.09 -186.5)" style="fill: #254553"/>
                      <rect x="411.29" y="493.29" width="2.23" height="10.56" transform="translate(-118.39 -126.51) rotate(-7.73)" style="fill: #254553"/>
                      <path d="M401.86,498.4a6.87,6.87,0,0,1-4.31-.62,5,5,0,0,1-2.35-3.87,4.38,4.38,0,0,1,2-4.21,10.82,10.82,0,0,1,4.85-1.76c4.78-.65,10.17,1,10.75,5.25l-2.21.3c-.37-2.74-4.48-3.85-8.24-3.34a8.64,8.64,0,0,0-3.84,1.35c-1.27.92-1.15,1.82-1.11,2.11a2.83,2.83,0,0,0,1.27,2.24c1.52.88,4.18.14,5.08-.22l.57,2.18A11.18,11.18,0,0,1,401.86,498.4Z" transform="translate(-55.09 -186.5)" style="fill: #3b6a7a"/>
                    </g>
                    <polygon points="281.24 344.27 281.29 350.73 296.62 359.11 296.56 351.45 281.24 344.27" style="fill: #254553"/>
                    <polygon points="280.52 344.02 285.54 301.28 301.94 307.89 296.56 351.45 280.52 344.02" style="fill: #d33d24"/>
                    <polygon points="301.94 307.89 329.44 298.28 334.29 338.03 296.56 351.45 301.94 307.89" style="fill: #f47458"/>
                    <polygon points="285.54 301.28 314.97 291.73 329.44 298.28 301.94 307.89 285.54 301.28" style="fill: #f48876"/>
                    <path d="M346.7,484.75" transform="translate(-55.09 -186.5)" style="fill: #323232"/>
                    <path d="M351.18,483.51l0,2.55s.77,3.17,10.16,3.09,10.43-3.27,10.43-3.27l0-4.11Z" transform="translate(-55.09 -186.5)" style="fill: #f2ca8b"/>
                    <path d="M344.31,471.66l.05,6.78s1.26,7.21,17,7,16.35-5.55,16.33-7.32l-.14-17.48-31.92.28Z" transform="translate(-55.09 -186.5)" style="fill: #f7e1b9"/>
                    <path d="M368.92,480.51a6.16,6.16,0,0,1-3.5-1.15.58.58,0,1,1,.69-.93c.14.1,3.39,2.41,6-.69a.58.58,0,0,1,.82-.06.57.57,0,0,1,.06.81A5.19,5.19,0,0,1,368.92,480.51Z" transform="translate(-55.09 -186.5)" style="fill: #162d33"/>
                    <path d="M377.56,465.19s-4.79,4.46-13.39,3.09-4.22-.65-4.22,4.8-2.53,8.6-1.56,9.79,4.6,2.61,14.26,1c0,0-7.42,3.34-21.44.51-4.95-1-7.44-4.22-7.23-8.29.33-6.23,1.16-11.35,1.16-11.35l10-8.92S378.06,455,377.56,465.19Z" transform="translate(-55.09 -186.5)" style="fill: #e8b674"/>
                    <g>
                      <circle cx="309.43" cy="285.18" r="2.07" style="fill: #162d33"/>
                      <ellipse cx="309.51" cy="284.57" rx="0.62" ry="0.71" style="fill: #fef8f5"/>
                      <path d="M375.49,469.93c0,1.05-.7,2.26-1.57,2.29s-1.15-.72-1.19-1.76c-.06-1.42.73-2.28,1.6-2.31S375.45,468.89,375.49,469.93Z" transform="translate(-55.09 -186.5)" style="fill: #162d33"/>
                      <ellipse cx="374.16" cy="469.68" rx="0.48" ry="0.63" transform="translate(-72.12 -172.41) rotate(-2.11)" style="fill: #fef8f5"/>
                    </g>
                    <g>
                      <path d="M316.33,500.82s-10.13-2.07-12.57,1.26v13.7a40.38,40.38,0,0,1,9.62-1.35c4,.15,6.95.84,6.95,2v-9.32Z" transform="translate(-55.09 -186.5)" style="fill: #254553"/>
                      <path d="M315.17,502.93l-2.24.91a4.87,4.87,0,0,0,0,4.66c.8,1.47,2,3,4,2.82a10.63,10.63,0,0,0,2.71-1.14C323,508,315.17,502.93,315.17,502.93Z" transform="translate(-55.09 -186.5)" style="fill: #162d33"/>
                      <path d="M345.16,495.06c4.17,4.6,6.49,12.93,5.15,16.56s-5.82,4.06-12.09.71-9.84-5.3-12.08-4.55-6.49,2.4-6.49,2.4-3.81.35-5.15-5.34,3.13-7.53,3.13-7.53l9.85-6.45a11.67,11.67,0,0,1,7.83,0C339.12,492.43,344,493.78,345.16,495.06Z" transform="translate(-55.09 -186.5)" style="fill: #472b2a"/>
                      <path d="M320.33,516.38c0-1.1-2.27-2-6.61-2-5.49-.07-8.82.72-9.74,1.14-.28.13-.26.27-.18.46a1.88,1.88,0,0,0,1.38.9,11.87,11.87,0,0,0,3.84.08s-4.86-.92,3-1.15a14.87,14.87,0,0,1,4.59.34c2.32.59-2.83.81-4,.74l.75,1.25C320.54,518.73,320.33,516.38,320.33,516.38Z" transform="translate(-55.09 -186.5)" style="fill: #162d33"/>
                      <path d="M309,516.93v-1s-1.47.06-1.53.38S309,516.93,309,516.93Z" transform="translate(-55.09 -186.5)" style="fill: #254553"/>
                      <path d="M312.63,516.86v-1.09s4.59,0,4.59.61S312.63,516.86,312.63,516.86Z" transform="translate(-55.09 -186.5)" style="fill: #254553"/>
                    </g>
                    <path d="M341.38,457.16c-.47.5-1.2.22-1.32.78-.79,3.71,1.67,6.9,5.31,8.26,8.75,3.26,29.82,5.54,38.24,0,1.92-1.27,1.87-3.91,1.91-5,.16-3.85-6.94-5.82-11.65-6.33a160.73,160.73,0,0,0-16.56-1.37c-4.85,0-11.31-.19-15.14,2.88A7.09,7.09,0,0,0,341.38,457.16Z" transform="translate(-55.09 -186.5)" style="fill: #d33d24"/>
                    <path d="M341.38,454.81c-3.11,3.34-.3,7.44,4,9,8.75,3.26,30.18,6.06,38.24,0,6.09-4.59-3.75-10.64-9.74-11.29a163.57,163.57,0,0,0-16.56-1.37c-4.85,0-11.31-.19-15.14,2.88A7.09,7.09,0,0,0,341.38,454.81Z" transform="translate(-55.09 -186.5)" style="fill: #f47458"/>
                    <path d="M358.45,462.8c14.12,1.55,22.61-2.53,22.13-8.5s-7.12-12.36-13.7-13.3a8.54,8.54,0,0,0-5.21-2.23c-4.22,0-6,2-6,2s-8.45,1.72-10.79,7.07S339.3,460.7,358.45,462.8Z" transform="translate(-55.09 -186.5)" style="fill: #d33d24"/>
                    <path d="M367.28,442a2.11,2.11,0,0,0,.46-.8c-.29-.07-.58-.12-.86-.16-.4,1-2.58,2-5.6,2a8.59,8.59,0,0,1-5-1.32,1.48,1.48,0,0,1-.61-.9s-.32.06-.85.21a2.19,2.19,0,0,0,.41.86c-8,3.16-10.32,12.9-10.86,15.89a6.1,6.1,0,0,0,.75.79c.24-1.57,2.28-12.95,10.81-16a6.89,6.89,0,0,0,2.22,1,55.6,55.6,0,0,0-3.8,18.71l.87.15A56.85,56.85,0,0,1,359,443.69a13.16,13.16,0,0,0,2.31.2c.45,0,.88,0,1.3-.06a30.1,30.1,0,0,1,6.13,19.05l.87-.12a31.21,31.21,0,0,0-6-19.07,7.91,7.91,0,0,0,3-1.12c1.51.84,10.63,6.33,11.35,17.11a8.33,8.33,0,0,0,.81-.73C377.74,448.73,369.46,443.25,367.28,442Z" transform="translate(-55.09 -186.5)" style="fill: #f47458"/>
                    <path d="M318.69,551.82l20.41-13.11s11.59-4.14,13.49,9.79c1.51,11.08-4,12.82-4,12.82s-4.36,1.19-7.05-1.13l-8.73,6,3,7.69L329.47,578Z" transform="translate(-55.09 -186.5)" style="fill: #162d33"/>
                    <path d="M369.43,539.32l-17.59,5.81s3.67,12.65-3.28,16.19l19.49-6.81S376.05,549.78,369.43,539.32Z" transform="translate(-55.09 -186.5)" style="fill: #508b9b"/>
                    <polygon points="280.7 387.39 299.36 381.55 296.74 373.68 277.69 379.69 280.7 387.39" style="fill: #508b9b"/>
                    <polygon points="274.38 391.52 280.7 387.39 299.36 381.55 291.9 386.53 274.38 391.52" style="fill: #3b6a7a"/>
                    <path d="M332.78,566.19l8.73-6s3.3,2.08,6.7,1.13Z" transform="translate(-55.09 -186.5)" style="fill: #3b6a7a"/>
                  </g>
                  <polygon points="302.22 307.79 328.3 298.69 316.35 319.28 302.22 307.79" style="fill: #725350"/>
                  <polygon points="316.91 344.21 316.04 344.52 316.04 309.69 316.91 309.69 316.91 344.21" style="fill: #472b2a"/>
                  <polygon points="309.88 305.12 316.56 310.87 321.9 300.91 309.88 305.12" style="fill: #fff"/>
                  <path d="M359.69,493.46l7.65,6s1.08-4.22,4.23-1.62L365,491.61Z" transform="translate(-55.09 -186.5)" style="fill: #472b2a"/>
                  <path d="M371.86,497.94s1.7-3.26,3.06-.43l5.91-11.44-4.3,1.5Z" transform="translate(-55.09 -186.5)" style="fill: #472b2a"/>
                  <polyline points="321.37 301.1 316.47 310.22 310.34 304.96 309.38 305.29 316.72 311.58 322.6 300.66" style="fill: #472b2a"/>
                  <polygon points="316.6 320.24 301.94 307.89 302.77 307.6 316.36 318.92 328.08 298.77 329.44 298.28 316.6 320.24" style="fill: #472b2a"/>
                  <circle cx="314.93" cy="314.26" r="1.15" style="fill: #472b2a"/>
                  <path d="M359.79,532a5.51,5.51,0,0,1-3.53-1.09,1.55,1.55,0,0,1-.58-1.21V525.5l13.38-4.42V526a1.49,1.49,0,0,1-.05.43c-.19.79-1.19,3.47-5.79,5A11.35,11.35,0,0,1,359.79,532Zm-3.24-5.85v3.55a.67.67,0,0,0,.25.53c.78.62,2.61,1.49,6.15.34,4.18-1.36,5.05-3.67,5.22-4.34a.85.85,0,0,0,0-.23v-3.7Z" transform="translate(-55.09 -186.5)" style="fill: #472b2a"/>
                  <rect x="356.11" y="525.23" width="12.52" height="0.87" transform="translate(-204.62 -42.4) rotate(-18.72)" style="fill: #472b2a"/>
                  <path d="M377.79,526a5.52,5.52,0,0,1-3.54-1.09,1.52,1.52,0,0,1-.58-1.21v-4.18l13.39-4.42V520a1.54,1.54,0,0,1-.06.43c-.19.79-1.19,3.47-5.79,5A11,11,0,0,1,377.79,526Zm-3.25-5.85v3.55a.69.69,0,0,0,.25.54c.78.62,2.61,1.49,6.15.34,4.18-1.36,5.05-3.67,5.22-4.35a.78.78,0,0,0,0-.22v-3.71Z" transform="translate(-55.09 -186.5)" style="fill: #472b2a"/>
                  <rect x="374.1" y="519.21" width="12.52" height="0.87" transform="translate(-201.73 -36.95) rotate(-18.72)" style="fill: #472b2a"/>
                  <path d="M358.78,511.53a.44.44,0,0,1-.41-.3.43.43,0,0,1,.27-.55l9.52-3.22a.43.43,0,1,1,.28.82l-9.52,3.22A.35.35,0,0,1,358.78,511.53Z" transform="translate(-55.09 -186.5)" style="fill: #472b2a"/>
                  <path d="M374.43,505.87" transform="translate(-55.09 -186.5)" style="fill: none;stroke: #472b2a;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 3px"/>
                  <path d="M374.54,506.27a.44.44,0,0,1-.41-.3.43.43,0,0,1,.27-.55l9.52-3.22a.43.43,0,0,1,.55.27.44.44,0,0,1-.28.55l-9.51,3.22Z" transform="translate(-55.09 -186.5)" style="fill: #472b2a"/>
                </g>
                <g>
                  <g>
                    <polygon points="343.77 395.15 343.77 430.57 347.29 430.57 347.29 394.48 343.77 395.15" style="fill: #538d7e"/>
                    <g>
                      <path d="M390.33,583.63c3.08-.12,5.65,1.55,5.73,3.73a2.85,2.85,0,0,1-.36,1.48,5.22,5.22,0,0,1,1.76,1.49,6.16,6.16,0,0,1,6.48.44,4.4,4.4,0,0,1,1.53-1.59,3.18,3.18,0,0,1-.5-2.35c.42-2.14,3.22-3.38,6.25-2.78s5.14,2.83,4.71,5a3.38,3.38,0,0,1-1.78,2.23c1.25,1.66,1.63,2.28.73,4-1.33,2.5-4.85,3.22-7.88,1.62a6.45,6.45,0,0,1-.76-.47,6.49,6.49,0,0,1-.38.91,6.12,6.12,0,0,1-8.39,2.29,5.7,5.7,0,0,1-2.94-3.12,8.63,8.63,0,0,1-1.39.41c-3.37.63-6.53-.53-7.06-3.31-.24-1.31.13-1.75.95-2.83a3.62,3.62,0,0,1-2.14-3C384.81,585.59,387.25,583.74,390.33,583.63Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                      <path d="M390.36,582.08c3.08-.12,5.65,1.55,5.72,3.73a2.94,2.94,0,0,1-.35,1.48,5.31,5.31,0,0,1,1.76,1.48,6.21,6.21,0,0,1,5.79,0,5.22,5.22,0,0,1,.68.43,4.43,4.43,0,0,1,1.54-1.59,3.13,3.13,0,0,1-.5-2.35c.42-2.14,3.22-3.39,6.25-2.78s5.13,2.83,4.71,5a3.38,3.38,0,0,1-1.78,2.24c1.25,1.66,1.62,2.28.73,4-1.33,2.5-4.86,3.22-7.88,1.62a6.45,6.45,0,0,1-.76-.47,6.56,6.56,0,0,1-.39.91A6.1,6.1,0,0,1,397.5,598a5.66,5.66,0,0,1-2.94-3.12,8.63,8.63,0,0,1-1.39.41c-3.37.63-6.54-.53-7.06-3.31-.25-1.31.12-1.75.95-2.83a3.65,3.65,0,0,1-2.15-3C384.84,584,387.27,582.19,390.36,582.08Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                      <g>
                        <path d="M390.33,586.55h0l.05-1h0l.24-4.45a1.43,1.43,0,0,0,0,.29l0,.09a1.54,1.54,0,0,0,.08.19.41.41,0,0,0,.07.11.94.94,0,0,0,.12.18l.1.12.15.15.22.19.17.13.3.19.19.12.4.2a.93.93,0,0,0,.2.1c.21.1.44.2.69.29s.59.2.9.29l.7.22c.29.08.62.14.93.21l.78.17c.32.06.67.1,1,.15l.81.12,1.09.1.81.06c.36,0,.73,0,1.1,0l.8,0c.38,0,.75,0,1.12,0l.73,0c.42,0,.81-.08,1.2-.13.18,0,.38,0,.55-.06.56-.08,1.1-.19,1.59-.31,2-.5,3.07-1.26,3.11-2.07l-.16,3h0l-.1,1.78h0v.16l0,.44h0l-.13,2.42c-.05.81-1.09,1.57-3.11,2.07a14.45,14.45,0,0,1-1.59.31c-.18,0-.37,0-.55.06-.4.05-.79.1-1.2.13-.24,0-.49,0-.73,0s-.74,0-1.12,0-.54,0-.81,0-.73,0-1.1,0l-.81-.07-1.08-.1c-.28,0-.54-.08-.82-.12s-.68-.09-1-.15l-.78-.17c-.31-.07-.63-.13-.93-.21l-.7-.22c-.26-.08-.54-.15-.78-.24l-.12,0-.69-.3-.2-.09-.39-.21-.2-.12a2.9,2.9,0,0,1-.29-.19,1.09,1.09,0,0,1-.17-.13l-.22-.18-.07-.06-.09-.1-.1-.12-.12-.17-.07-.12a1.07,1.07,0,0,1-.07-.19.32.32,0,0,1,0-.09.72.72,0,0,1,0-.29l.1-1.76h0V587h0Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                        <path d="M393.73,579c-4,1-4.16,3-.36,4.42a27.6,27.6,0,0,0,14.12.81c4-1,4.17-3,.36-4.42A27.7,27.7,0,0,0,393.73,579Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                      </g>
                      <path d="M399.87,582.48a1.74,1.74,0,0,0,2.51-1.51l-.05-1.38a1.73,1.73,0,1,0-3.46-.09l0,1.38A1.74,1.74,0,0,0,399.87,582.48Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                      <circle cx="344.99" cy="392.6" r="1" style="fill: #538d7e"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M410.79,632.53h0v-3h0v-4.46a1.26,1.26,0,0,1,0,.28,1,1,0,0,1,0,.1c0,.06,0,.12-.09.19l-.07.1a1.09,1.09,0,0,1-.13.17l-.11.11-.16.15-.23.18-.18.12-.3.17-.21.11-.4.18-.2.09c-.22.09-.46.17-.71.25s-.6.16-.91.24-.46.13-.71.18l-.95.16-.78.13c-.33,0-.68.06-1,.09l-.82.08-1.09,0-.81,0c-.37,0-.73,0-1.1,0l-.8,0-1.12-.1-.73-.07c-.41-.05-.8-.12-1.19-.19-.18,0-.37-.06-.55-.09-.55-.12-1.08-.25-1.57-.4-2-.61-3-1.42-3-2.23v3h0v3.81h0v4h0v4.45c0,.81,1,1.62,3,2.24.49.15,1,.28,1.57.39.18,0,.37.06.55.09.39.07.78.14,1.19.19l.73.07c.37,0,.74.08,1.12.1s.53,0,.8,0,.73,0,1.1,0l.81,0c.37,0,.73,0,1.09,0s.55-.05.82-.07l1-.1c.27,0,.52-.09.79-.13s.64-.09.94-.16.47-.12.71-.18.54-.12.79-.2l.12,0c.25-.08.49-.17.71-.26l.2-.08.41-.18.2-.11.3-.18.18-.12.23-.17.07-.06.09-.09.11-.11.13-.17.07-.11a1.63,1.63,0,0,0,.09-.19l0-.09a1.45,1.45,0,0,0,0-.28v-3.79h0v-.16h0Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                      <path d="M407.8,622.82c3.94,1.21,4,3.2.12,4.43a27.7,27.7,0,0,1-14.15,0c-3.94-1.21-4-3.2-.12-4.43A27.59,27.59,0,0,1,407.8,622.82Z" transform="translate(-55.09 -186.5)" style="fill: #538d7e"/>
                    </g>
                    <circle cx="345.63" cy="433.52" r="5.93" style="fill: #336052"/>
                    <path d="M410.79,636.49h1.64a2.05,2.05,0,0,0,2.05-2V626a4.87,4.87,0,0,1,.47-2.09l5.57-11.77-3.13-1.36-6.6,14.25Z" transform="translate(-55.09 -186.5)" style="fill: #538d7e"/>
                    <path d="M390.78,636.49h-1.65a2,2,0,0,1-2-2V626a4.87,4.87,0,0,0-.47-2.09l-5.57-11.77,3.13-1.36,6.6,14.25Z" transform="translate(-55.09 -186.5)" style="fill: #538d7e"/>
                  </g>
                  <g>
                    <path d="M431.28,610c-2.67-1.54-5.72-1.27-6.8.61a2.89,2.89,0,0,0-.39,1.49,5.28,5.28,0,0,0-2.25.48,6.2,6.2,0,0,0-5.12-2.7,5.29,5.29,0,0,0-.8.06,4.36,4.36,0,0,0-.62-2.12,3.16,3.16,0,0,0,1.55-1.85c.62-2.09-1.27-4.5-4.22-5.38s-5.86.09-6.49,2.18a3.32,3.32,0,0,0,.53,2.81,5.06,5.06,0,0,0-3.16,4.46c0,2.83,2.78,5.12,6.21,5.12a6.65,6.65,0,0,0,.89-.06,5.33,5.33,0,0,0-.09,1,6.21,6.21,0,0,0,6.2,6.21,6.13,6.13,0,0,0,4.19-1.64,7.73,7.73,0,0,0,1,1c2.68,2.13,6.28,2.07,8.05-.14a4.65,4.65,0,0,0,.88-3.71,3.67,3.67,0,0,0,3.28-1.62C435.24,614.29,434,611.52,431.28,610Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                    <path d="M431.28,608.31c-2.67-1.54-5.72-1.27-6.8.61a2.89,2.89,0,0,0-.39,1.49,5,5,0,0,0-2.25.48,6.2,6.2,0,0,0-5.12-2.7,5.29,5.29,0,0,0-.8.06,4.36,4.36,0,0,0-.62-2.12,3.19,3.19,0,0,0,1.55-1.85c.62-2.09-1.27-4.5-4.22-5.38s-5.86.09-6.49,2.18a3.32,3.32,0,0,0,.53,2.81,5.06,5.06,0,0,0-3.16,4.46c0,2.83,2.78,5.12,6.21,5.12a8.87,8.87,0,0,0,.89-.06,5.33,5.33,0,0,0-.09,1A6.19,6.19,0,0,0,420.91,619a7.66,7.66,0,0,0,1,1c2.68,2.14,6.28,2.08,8.05-.13a4.66,4.66,0,0,0,.88-3.72,3.64,3.64,0,0,0,3.28-1.61C435.24,612.63,434,609.85,431.28,608.31Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                    <g>
                      <path d="M429.66,612.26h0l.39-.9h0l1.78-4.08a.75.75,0,0,1-.15.24l-.07.07a1,1,0,0,1-.15.14l-.11.07-.19.1-.14.06-.21.07-.28.07-.21,0-.35,0-.23,0h-.67c-.23,0-.48,0-.74,0s-.62-.1-.94-.15l-.72-.12c-.3-.06-.62-.15-.93-.23s-.51-.12-.77-.2-.64-.21-1-.31l-.78-.27c-.34-.12-.68-.26-1-.4s-.51-.19-.76-.3-.66-.31-1-.47l-.73-.34c-.34-.17-.66-.36-1-.54l-.65-.35c-.35-.21-.68-.44-1-.66-.15-.1-.32-.19-.46-.29a15.6,15.6,0,0,1-1.29-1c-1.58-1.35-2.17-2.5-1.84-3.24L412.3,602h0l-.71,1.63h0l-.07.15-.17.4h0l-1,2.23c-.32.74.27,1.89,1.85,3.25.39.33.82.66,1.28,1l.46.3c.34.22.66.44,1,.65.2.12.43.23.64.35s.65.37,1,.54l.73.34,1,.47.75.3,1,.41.79.26c.32.1.65.22,1,.31s.51.13.77.2.62.17.93.23l.72.12c.27.05.55.11.8.14h.13c.26,0,.51,0,.75.05h.66l.23,0,.35,0,.21,0,.28-.07.09,0,.12,0L428,615l.19-.1.11-.07a1.07,1.07,0,0,0,.16-.14.46.46,0,0,0,.07-.07,1,1,0,0,0,.15-.24l.7-1.62h0l.06-.15h0Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                      <path d="M430,604c3.12,2.68,2.38,4.52-1.66,4.11a27.7,27.7,0,0,1-13-5.63c-3.12-2.69-2.38-4.52,1.67-4.11A27.75,27.75,0,0,1,430,604Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                    </g>
                    <path d="M423,604.34a1.78,1.78,0,0,1-.74-.17,1.73,1.73,0,0,1-.83-2.3l.66-1.22a1.73,1.73,0,0,1,3.14,1.47l-.66,1.22A1.74,1.74,0,0,1,423,604.34Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                    <circle cx="369.21" cy="414.72" r="1" style="fill: #538d7e"/>
                  </g>
                  <g>
                    <path d="M369.9,609.1c2.67-1.54,5.72-1.26,6.81.62a2.91,2.91,0,0,1,.38,1.48,5.28,5.28,0,0,1,2.25.48,6.2,6.2,0,0,1,5.12-2.7,7.36,7.36,0,0,1,.81.06,4.25,4.25,0,0,1,.61-2.12,3.21,3.21,0,0,1-1.55-1.84c-.62-2.09,1.27-4.5,4.23-5.39s5.86.1,6.48,2.19a3.3,3.3,0,0,1-.53,2.8,5.06,5.06,0,0,1,3.16,4.46c0,2.83-2.78,5.12-6.21,5.12a6.4,6.4,0,0,1-.88-.06,6.5,6.5,0,0,1,.08,1,6.21,6.21,0,0,1-6.2,6.2,6.13,6.13,0,0,1-4.19-1.64,7.17,7.17,0,0,1-1,1c-2.68,2.14-6.28,2.08-8.05-.14a4.6,4.6,0,0,1-.87-3.71,3.66,3.66,0,0,1-3.28-1.61C365.94,613.42,367.23,610.65,369.9,609.1Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                    <path d="M369.9,607.44c2.67-1.54,5.72-1.27,6.81.62a2.91,2.91,0,0,1,.38,1.48,5.28,5.28,0,0,1,2.25.48,6.2,6.2,0,0,1,5.12-2.7,7.36,7.36,0,0,1,.81.06,4.25,4.25,0,0,1,.61-2.12,3.21,3.21,0,0,1-1.55-1.84c-.62-2.09,1.27-4.5,4.23-5.39s5.86.1,6.48,2.18a3.32,3.32,0,0,1-.53,2.81,5.06,5.06,0,0,1,3.16,4.46c0,2.83-2.78,5.12-6.21,5.12a6.4,6.4,0,0,1-.88-.06,6.4,6.4,0,0,1,.08,1,6.21,6.21,0,0,1-6.2,6.2,6.13,6.13,0,0,1-4.19-1.64,7.73,7.73,0,0,1-1,1c-2.68,2.13-6.28,2.07-8.05-.14a4.6,4.6,0,0,1-.87-3.71,3.64,3.64,0,0,1-3.28-1.62C365.94,611.76,367.23,609,369.9,607.44Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                    <g>
                      <path d="M371.52,611.39h0l-.4-.91h0l-1.78-4.08a1,1,0,0,0,.15.24l.07.07.15.14.11.07.19.1.15.06.21.07.28.07.21,0,.34,0,.23,0c.14,0,.29,0,.45,0a1.61,1.61,0,0,0,.22,0c.23,0,.48,0,.75,0s.61-.09.93-.15.47-.06.72-.12.62-.15.93-.23l.77-.19,1-.32L378,606l1-.4.76-.31c.34-.14.66-.31,1-.46l.73-.35c.34-.17.66-.35,1-.54l.64-.35c.35-.21.68-.43,1-.65l.47-.3c.46-.33.89-.66,1.28-1,1.58-1.36,2.17-2.5,1.85-3.25l1.21,2.78h0l.71,1.63h0l.07.15.17.4h0l1,2.22c.33.75-.27,1.89-1.85,3.25-.38.33-.82.66-1.28,1l-.46.3c-.33.22-.66.44-1,.65-.2.12-.43.24-.64.35s-.64.37-1,.54l-.73.35c-.33.16-.66.32-1,.46l-.75.31c-.34.13-.67.28-1,.4s-.52.17-.79.26l-1,.32-.78.19c-.31.08-.62.17-.92.23s-.49.08-.73.13-.55.1-.8.13H376a6.59,6.59,0,0,1-.75.05h-.66l-.23,0-.35,0-.21,0-.28-.06-.09,0-.12,0-.14-.05-.19-.11a.41.41,0,0,1-.11-.07.67.67,0,0,1-.15-.14.27.27,0,0,1-.08-.07,1,1,0,0,1-.15-.24l-.7-1.61h0l-.06-.15h0Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                      <path d="M371.18,603.15c-3.12,2.68-2.37,4.53,1.67,4.11a27.79,27.79,0,0,0,13-5.62c3.13-2.69,2.38-4.53-1.67-4.11A27.64,27.64,0,0,0,371.18,603.15Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                    </g>
                    <path d="M378.21,603.47a1.63,1.63,0,0,0,.74-.17,1.72,1.72,0,0,0,.83-2.3l-.66-1.22a1.74,1.74,0,0,0-3.14,1.48l.66,1.21A1.74,1.74,0,0,0,378.21,603.47Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                    <circle cx="321.79" cy="413.86" r="1" style="fill: #538d7e"/>
                  </g>
                </g>
                <polygon points="383.61 356.05 343.99 347.76 372 338.46 383.61 356.05" style="fill: #4c4284"/>
                <polygon points="431.04 357.87 391.42 349.58 419.43 340.28 431.04 357.87" style="fill: #4c4284"/>
                <g>
                  <path d="M435.19,541" transform="translate(-55.09 -186.5)" style="fill: #abdf3b"/>
                  <g>
                    <g>
                      <polygon points="383.84 332.37 383.81 356.23 356.78 348.02 356.81 324.16 383.84 332.37" style="fill: #82e0e0"/>
                      <polygon points="410.69 324.2 383.84 332.37 356.81 324.16 383.66 315.99 410.69 324.2" style="fill: #a7fcfa"/>
                    </g>
                    <polygon points="410.69 324.2 410.66 348.06 383.81 356.23 383.84 332.37 410.69 324.2" style="fill: #b7f1f4"/>
                  </g>
                  <polygon points="383.66 315.99 383.84 332.37 356.81 324.16 383.66 315.99" style="fill: #b7f1f4"/>
                  <polygon points="410.6 320.18 383.78 328.35 356.91 320.23 383.65 311.89 410.6 320.18" style="fill: #f9d5a5"/>
                  <polygon points="356.91 322.82 383.75 331.01 383.78 328.35 356.91 320.23 356.91 322.82" style="fill: #d69034"/>
                  <polygon points="410.6 320.18 410.6 322.73 383.75 331.01 383.78 328.35 410.6 320.18" style="fill: #f8c778"/>
                </g>
                <g>
                  <polygon points="444.29 330.1 444.25 353.96 430.82 358.03 430.86 334.17 444.29 330.1" style="fill: #eaaf52"/>
                  <polygon points="430.86 334.17 430.82 358.03 417.3 353.93 417.33 330.07 430.86 334.17" style="fill: #c47e2a"/>
                  <polygon points="444.29 330.1 430.86 334.17 417.33 330.07 430.76 326 444.29 330.1" style="fill: #f8c778"/>
                  <path d="M492.31,517.18l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07a.94.94,0,0,1-.1.1l-.16.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.08-.68.07-.55.05c-.24,0-.48,0-.72,0l-.55,0-.73,0c-.18,0-.36,0-.54,0s-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36-.05c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36,0c.26.05.52.1.79.13l.49.05c.25,0,.49,0,.75.06l.53,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12s0,0,0,0l.06-.06Z" transform="translate(-55.09 -186.5)" style="fill: #c47e2a"/>
                  <path d="M490.56,512.16c2.62.81,2.66,2.13.08,3a18.49,18.49,0,0,1-9.43,0c-2.63-.81-2.67-2.13-.08-2.95A18.38,18.38,0,0,1,490.56,512.16Z" transform="translate(-55.09 -186.5)" style="fill: #f8c778"/>
                </g>
                <g>
                  <g>
                    <polygon points="429.05 271.23 429.05 306.65 432.56 306.65 432.56 270.56 429.05 271.23" style="fill: #538d7e"/>
                    <g>
                      <path d="M475.6,459.7c3.09-.11,5.65,1.56,5.73,3.73a2.86,2.86,0,0,1-.36,1.49,5.35,5.35,0,0,1,1.77,1.48,6.19,6.19,0,0,1,5.78,0,5,5,0,0,1,.69.43,4.27,4.27,0,0,1,1.54-1.59,3.18,3.18,0,0,1-.51-2.35c.43-2.14,3.23-3.39,6.25-2.78s5.14,2.83,4.71,5a3.38,3.38,0,0,1-1.78,2.24c1.25,1.65,1.63,2.28.73,4-1.32,2.5-4.85,3.22-7.88,1.62a6.45,6.45,0,0,1-.76-.47,5.48,5.48,0,0,1-.38.91,6.11,6.11,0,0,1-8.38,2.29,5.66,5.66,0,0,1-2.94-3.12,8.75,8.75,0,0,1-1.4.41c-3.37.63-6.53-.53-7.05-3.32-.25-1.3.12-1.74.94-2.82a3.62,3.62,0,0,1-2.14-3C470.08,461.67,472.52,459.82,475.6,459.7Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                      <path d="M475.63,458.15c3.08-.11,5.65,1.56,5.73,3.73a2.86,2.86,0,0,1-.36,1.49,5.31,5.31,0,0,1,1.76,1.48,6.21,6.21,0,0,1,5.79,0,5,5,0,0,1,.69.43,4.33,4.33,0,0,1,1.53-1.59,3.19,3.19,0,0,1-.5-2.36c.42-2.13,3.22-3.38,6.25-2.77s5.13,2.82,4.71,5a3.34,3.34,0,0,1-1.78,2.23c1.25,1.66,1.63,2.29.73,4-1.33,2.5-4.85,3.22-7.88,1.62a6.45,6.45,0,0,1-.76-.47,6.49,6.49,0,0,1-.38.91,6.12,6.12,0,0,1-8.39,2.29,5.65,5.65,0,0,1-2.94-3.13,7,7,0,0,1-1.39.41c-3.37.64-6.53-.53-7.06-3.31-.24-1.3.12-1.74.95-2.82a3.62,3.62,0,0,1-2.14-3C470.11,460.12,472.54,458.27,475.63,458.15Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                      <g>
                        <path d="M475.6,462.63h0l0-1h0l.25-4.45a.88.88,0,0,0,0,.28l0,.1c0,.06.05.13.08.19l.07.11a.94.94,0,0,0,.12.18l.1.12.16.15a1.37,1.37,0,0,0,.22.19,1.26,1.26,0,0,0,.17.13l.29.19.2.12a3.55,3.55,0,0,0,.39.2l.2.1c.21.1.44.2.69.29s.59.19.9.29l.7.22c.3.08.62.14.93.21l.78.17c.33.06.67.1,1,.15s.54.09.82.12.72.06,1.08.09.54.06.81.07l1.1,0,.8,0c.38,0,.75,0,1.12,0s.5,0,.73,0c.42,0,.81-.08,1.2-.12l.55-.06c.56-.08,1.1-.19,1.59-.31,2-.51,3.07-1.26,3.11-2.07l-.16,3h0l-.1,1.78h0v.16l0,.44h0l-.13,2.43c-.05.81-1.09,1.57-3.11,2.07-.5.12-1,.23-1.59.31-.18,0-.37,0-.55.06-.39.05-.79.1-1.2.13l-.73,0c-.37,0-.74,0-1.12,0l-.81,0-1.1,0-.81-.06-1.08-.1c-.28,0-.54-.08-.82-.12s-.68-.09-1-.15l-.77-.17c-.32-.07-.64-.13-.94-.21l-.7-.22c-.26-.08-.53-.15-.77-.24l-.12-.05c-.25-.09-.48-.19-.7-.29l-.19-.09-.4-.21-.2-.12a2.9,2.9,0,0,1-.29-.19l-.17-.13-.22-.19-.07-.05-.08-.1-.11-.12-.12-.18a.39.39,0,0,1-.06-.11.77.77,0,0,1-.08-.19.32.32,0,0,1,0-.09,1.43,1.43,0,0,1,0-.29l.1-1.76h0v-.16h0Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                        <path d="M479,455.11c-4,1-4.16,3-.36,4.42a27.6,27.6,0,0,0,14.12.81c4-1,4.17-3,.36-4.42A27.59,27.59,0,0,0,479,455.11Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                      </g>
                      <path d="M485.14,458.55a1.65,1.65,0,0,0,.73.19,1.74,1.74,0,0,0,1.78-1.69l0-1.38a1.73,1.73,0,0,0-3.46-.1l0,1.39A1.73,1.73,0,0,0,485.14,458.55Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                      <circle cx="430.26" cy="268.68" r="1" style="fill: #538d7e"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M496.06,508.61h0v-3h0v-4.45a1.35,1.35,0,0,1,0,.28,1,1,0,0,0,0,.1.76.76,0,0,1-.09.18.41.41,0,0,1-.07.11l-.13.17a.57.57,0,0,1-.11.11l-.16.15-.23.18-.18.12-.3.17-.2.11-.41.18-.2.09c-.22.09-.46.17-.71.25s-.6.16-.91.24-.46.13-.71.18l-.94.16c-.27,0-.52.09-.79.12s-.68.07-1,.1l-.82.08c-.36,0-.72,0-1.09,0l-.81,0c-.37,0-.73,0-1.1,0s-.54,0-.8,0-.75-.06-1.12-.1-.49,0-.73-.06c-.41-.05-.8-.13-1.19-.2l-.54-.08c-.56-.12-1.09-.25-1.58-.4-2-.61-3-1.42-3-2.23v3h0V508h0v4h0v4.45c0,.81,1,1.62,3,2.24a15,15,0,0,0,1.57.39c.17,0,.36.06.54.09.39.07.78.14,1.19.19l.73.07c.37,0,.74.08,1.12.1l.8,0c.37,0,.73,0,1.1,0l.82,0,1.08,0c.28,0,.55,0,.83-.07l1-.1.79-.13.94-.16c.25-.05.47-.12.71-.18s.54-.12.79-.2l.12,0,.71-.26.2-.08.41-.19.2-.11a2.14,2.14,0,0,0,.3-.17l.18-.12.23-.17.07-.06.09-.09.11-.11.13-.17a1,1,0,0,1,.08-.11,1.54,1.54,0,0,0,.08-.19l0-.09a1,1,0,0,0,0-.28v-3.79h0v-.16h0Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                      <path d="M493.07,498.9c3.94,1.21,4,3.2.12,4.43a27.7,27.7,0,0,1-14.15,0c-3.94-1.21-4-3.2-.11-4.43A27.55,27.55,0,0,1,493.07,498.9Z" transform="translate(-55.09 -186.5)" style="fill: #538d7e"/>
                    </g>
                    <circle cx="430.9" cy="309.59" r="5.93" style="fill: #336052"/>
                    <path d="M496.06,512.57h1.65a2,2,0,0,0,2-2.05V502.1a4.87,4.87,0,0,1,.47-2.09l5.57-11.77-3.13-1.36-6.6,14.25Z" transform="translate(-55.09 -186.5)" style="fill: #538d7e"/>
                    <path d="M476.05,512.57h-1.64a2.05,2.05,0,0,1-2.05-2.05V502.1a4.87,4.87,0,0,0-.47-2.09l-5.57-11.77,3.13-1.36,6.6,14.25Z" transform="translate(-55.09 -186.5)" style="fill: #538d7e"/>
                  </g>
                  <g>
                    <path d="M516.55,486.05c-2.67-1.54-5.72-1.27-6.8.61a3,3,0,0,0-.38,1.49,5,5,0,0,0-2.26.48,6.18,6.18,0,0,0-5.11-2.7,5.32,5.32,0,0,0-.81.06,4.36,4.36,0,0,0-.62-2.12,3.19,3.19,0,0,0,1.55-1.85c.63-2.09-1.26-4.5-4.22-5.38s-5.86.09-6.49,2.18a3.39,3.39,0,0,0,.53,2.81,5.06,5.06,0,0,0-3.16,4.46c0,2.83,2.78,5.12,6.21,5.12a8.67,8.67,0,0,0,.89-.06,6.38,6.38,0,0,0-.09,1,6.2,6.2,0,0,0,10.4,4.57,7.12,7.12,0,0,0,1,1c2.67,2.14,6.28,2.08,8-.13a4.66,4.66,0,0,0,.88-3.72,3.63,3.63,0,0,0,3.28-1.61C520.51,490.37,519.23,487.59,516.55,486.05Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                    <path d="M516.55,484.39c-2.67-1.54-5.72-1.27-6.8.61a2.94,2.94,0,0,0-.38,1.48,5.19,5.19,0,0,0-2.26.49,6.18,6.18,0,0,0-5.11-2.7,5.32,5.32,0,0,0-.81.06,4.43,4.43,0,0,0-.62-2.13,3.15,3.15,0,0,0,1.55-1.84c.63-2.09-1.26-4.5-4.22-5.38s-5.86.09-6.49,2.18a3.4,3.4,0,0,0,.53,2.81,5,5,0,0,0-3.16,4.45c0,2.83,2.78,5.13,6.21,5.13a8.67,8.67,0,0,0,.89-.06,6.38,6.38,0,0,0-.09,1,6.2,6.2,0,0,0,10.4,4.57,7.12,7.12,0,0,0,1,1c2.67,2.14,6.28,2.08,8-.13a4.67,4.67,0,0,0,.88-3.72,3.63,3.63,0,0,0,3.28-1.61C520.51,488.71,519.23,485.93,516.55,484.39Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                    <g>
                      <path d="M514.93,488.34h0l.39-.9h0l1.78-4.08a1.11,1.11,0,0,1-.16.24l-.07.07a1,1,0,0,1-.15.14l-.11.07-.19.1-.14.06-.21.07-.28.07-.21,0-.35,0h-.89l-.75-.05c-.3,0-.62-.1-.93-.15l-.73-.12c-.3-.06-.61-.15-.93-.23l-.77-.2c-.32-.09-.64-.21-1-.32s-.52-.16-.78-.26-.67-.26-1-.4-.51-.19-.76-.3l-1-.47-.73-.34c-.34-.17-.66-.36-1-.54s-.44-.23-.64-.35c-.36-.21-.69-.44-1-.66-.15-.1-.32-.19-.46-.3a12.32,12.32,0,0,1-1.28-1c-1.59-1.36-2.18-2.5-1.85-3.24l-1.21,2.77h0l-.71,1.63h0l-.06.15-.18.4h0l-1,2.23c-.32.74.27,1.88,1.85,3.24.39.34.82.67,1.28,1l.47.29c.33.22.65.45,1,.66l.64.35c.33.18.65.37,1,.54s.48.23.72.34l1,.47.75.3c.34.14.68.28,1,.4l.79.27c.32.1.65.22,1,.31s.51.13.77.2.63.17.93.23l.72.12a8,8,0,0,0,.81.13l.12,0,.75.05h.67l.23,0,.34,0,.21,0,.28-.07.09,0a.43.43,0,0,0,.12-.05l.15-.06.18-.1.11-.07a1.07,1.07,0,0,0,.16-.14l.07-.07a1,1,0,0,0,.15-.24l.71-1.62h0l.06-.14h0Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                      <path d="M515.27,480.09c3.12,2.69,2.38,4.53-1.66,4.12a27.67,27.67,0,0,1-13-5.63c-3.13-2.69-2.39-4.53,1.66-4.11A27.69,27.69,0,0,1,515.27,480.09Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                    </g>
                    <path d="M508.24,480.41a1.76,1.76,0,0,1-.74-.16,1.74,1.74,0,0,1-.83-2.31l.66-1.21a1.73,1.73,0,0,1,3.14,1.47l-.66,1.22A1.72,1.72,0,0,1,508.24,480.41Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                    <circle cx="454.48" cy="290.8" r="1" style="fill: #538d7e"/>
                  </g>
                  <g>
                    <path d="M455.17,485.18c2.67-1.54,5.72-1.27,6.81.62a2.91,2.91,0,0,1,.38,1.48,5.28,5.28,0,0,1,2.25.48,6.2,6.2,0,0,1,5.12-2.7,7.36,7.36,0,0,1,.81.06,4.46,4.46,0,0,1,.61-2.12,3.21,3.21,0,0,1-1.55-1.84c-.62-2.09,1.27-4.5,4.23-5.39s5.86.1,6.48,2.18a3.32,3.32,0,0,1-.53,2.81,5.06,5.06,0,0,1,3.16,4.46c0,2.83-2.78,5.12-6.2,5.12a6.44,6.44,0,0,1-.89-.06,6.4,6.4,0,0,1,.08,1,6.2,6.2,0,0,1-6.2,6.2,6.13,6.13,0,0,1-4.19-1.64,7.73,7.73,0,0,1-1,1c-2.68,2.13-6.28,2.07-8-.14a4.65,4.65,0,0,1-.88-3.71,3.64,3.64,0,0,1-3.28-1.62C451.21,489.5,452.5,486.73,455.17,485.18Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                    <path d="M455.17,483.52c2.67-1.54,5.72-1.27,6.81.61a3,3,0,0,1,.38,1.49,5.28,5.28,0,0,1,2.25.48,6.2,6.2,0,0,1,5.12-2.7,5.52,5.52,0,0,1,.81.06,4.46,4.46,0,0,1,.61-2.12,3.2,3.2,0,0,1-1.55-1.85c-.62-2.09,1.27-4.5,4.23-5.38s5.86.09,6.48,2.18a3.32,3.32,0,0,1-.53,2.81,5,5,0,0,1,3.16,4.46c0,2.83-2.78,5.12-6.2,5.12a6.44,6.44,0,0,1-.89-.06,6.37,6.37,0,0,1,.08,1,6.2,6.2,0,0,1-6.2,6.21,6.13,6.13,0,0,1-4.19-1.64,7.73,7.73,0,0,1-1,1c-2.68,2.13-6.28,2.07-8-.14a4.65,4.65,0,0,1-.88-3.71,3.64,3.64,0,0,1-3.28-1.62C451.21,487.84,452.5,485.07,455.17,483.52Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                    <g>
                      <path d="M456.79,487.47h0l-.39-.9h0l-1.78-4.08a1,1,0,0,0,.15.24.46.46,0,0,0,.07.07,1.07,1.07,0,0,0,.16.14l.11.07.18.1.15.06.21.07.28.07.21,0,.34,0,.24,0h.66c.24,0,.48,0,.75-.05s.62-.1.93-.15l.72-.12c.3-.06.62-.15.93-.23s.51-.12.77-.2.65-.21,1-.31l.79-.26,1-.41.75-.3c.34-.14.67-.31,1-.46l.72-.35c.34-.17.66-.36,1-.54l.64-.35c.36-.21.68-.43,1-.65l.47-.3c.46-.33.89-.66,1.28-1,1.58-1.36,2.17-2.51,1.85-3.25l1.21,2.77h0l.71,1.63h0l.07.15.17.4h0l1,2.23c.33.75-.26,1.89-1.84,3.25q-.59.5-1.29,1l-.46.3c-.33.22-.66.44-1,.65-.2.12-.42.23-.64.35s-.64.37-1,.54l-.73.35c-.33.15-.66.32-1,.46l-.76.31-1,.4-.78.26-1,.32-.78.19c-.31.08-.62.17-.92.23s-.48.08-.73.12-.54.11-.8.14h-.13c-.26,0-.51,0-.75.05h-.66l-.23,0-.35,0-.21,0-.28-.07-.09,0-.12-.05a.59.59,0,0,1-.14-.06l-.19-.1-.11-.07a1,1,0,0,1-.15-.14l-.07-.07a.78.78,0,0,1-.16-.24L457,488h0l-.07-.15h0Z" transform="translate(-55.09 -186.5)" style="fill: #b0549d"/>
                      <path d="M456.45,479.23c-3.12,2.68-2.37,4.53,1.67,4.11a27.75,27.75,0,0,0,13-5.62c3.13-2.69,2.38-4.53-1.67-4.12A27.75,27.75,0,0,0,456.45,479.23Z" transform="translate(-55.09 -186.5)" style="fill: #f685de"/>
                    </g>
                    <path d="M463.49,479.55a1.77,1.77,0,0,0,.73-.17,1.73,1.73,0,0,0,.84-2.3l-.67-1.22a1.73,1.73,0,0,0-3.14,1.47l.66,1.22A1.74,1.74,0,0,0,463.49,479.55Z" transform="translate(-55.09 -186.5)" style="fill: #336052"/>
                    <circle cx="407.06" cy="289.94" r="1" style="fill: #538d7e"/>
                  </g>
                </g>
              </g>
            </g>
          </svg>

        </v-col>
        <v-col class="col-collection" cols="5">
          <h2 class="header-collection">Ein Ort nur für dich und deine Collection</h2>
          <p class="text-collection">Auf brickdex findest Du nicht nur die besten Deals, sondern kannst auch Deine gesammte LEGO Collection verwalten.
            Zusätzlich bekommst Du auch alle wichtigen Informationen über Deine Lieblingssets und Minifiguren.</p>
          <PrimaryButton id="button-register" @click="register" buttonText="Registriere dich" />
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="section-invest">
    <v-container>
      <v-row no-gutters class="row-invest scroll_fade scroll_fade_fromRight">
        <v-col class="col-invest" cols="5">
          <h2 class="header-invest">Das etwas andere Investment</h2>
          <p class="text-invest">Es ist einfach der perfekte Ort für Dich,
            wenn Du ein etwas anderes Investment suchst. Hier bekommst du viele Informationen, die Dich zu einem besseren LEGO Investor machen.
            Sei es durch Analysen von Sets oder EOL Listen, hier bekommst Du all die Informationen an einem wundervollen Ort.</p>
        </v-col>
        <v-col cols="7">
          <svg id="img-invest" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.19 549.26">
            <g style="isolation: isolate">
              <g>
                <g style="mix-blend-mode: multiply">
                  <polygon points="203.54 474.77 166.59 488.7 262.19 512.73 203.54 474.77" style="fill: #e5e5e5"/>
                  <polygon points="343.2 492.58 271.82 450.17 233.13 464.1 343.2 492.58" style="fill: #e5e5e5"/>
                  <path d="M306.2,712.67c-8.41,0-15.23,1.77-15.23,4,0,1,1.45,1.93,3.83,2.64h0l14.37,4.23-1.09-2.91c7.53-.23,13.36-1.9,13.37-3.93C321.44,714.47,314.62,712.68,306.2,712.67Z" transform="translate(-51.09 -184.42)" style="fill: #e5e5e5"/>
                  <path d="M486.67,701.62h0c-2.75-1-7.33-1.72-12.51-1.72-8.42,0-15.24,1.76-15.24,4,0,1,1.48,1.95,3.88,2.66h0l.2.05c.32.09.66.18,1,.26l19.09,4.92,25.6-3.26Z" transform="translate(-51.09 -184.42)" style="fill: #e5e5e5"/>
                  <path d="M426.91,708.38l-4.29-1.83.05,1.32a140.86,140.86,0,0,0-14.23-.72,129.12,129.12,0,0,0-20.78,1.55l-31.32-9.76h0c-2.75-1-7.32-1.72-12.51-1.72-8.41,0-15.23,1.76-15.24,4,0,1,1.48,2,3.89,2.66h0l.2,0c.33.09.67.18,1,.26l31.81,8.2,15.06-2.15c-6.83,1.83-11.05,4.32-11.05,7.08s4.56,5.48,11.9,7.33h0l.11,0c1.44.36,3,.69,4.63,1l28.55,6.15,50.21-7.08-17.57-7.55C447.06,713.37,438.87,710.09,426.91,708.38Z" transform="translate(-51.09 -184.42)" style="fill: #e5e5e5"/>
                  <path d="M162.7,635.55l-1.81,1c-1.92-4.08-7.1-8-15.58-11.11-.36-.13-14.37-.27-14.75-.4H55.67c-.62.22-1.23.44-1.82.67-.15,0-2.62.11-2.76.16a42.08,42.08,0,0,0,6.83,18.58,26.89,26.89,0,0,0,7,7.06c2,1.43,4.26,2.88,6.65,4.31q2.29,1.37,4.5,2.54a48,48,0,0,0,9.93,4l17.41,4.8,23.23-3.31-11.14-3.47c4.47-.32,8.81-.8,12.92-1.44L145,665.74l46.47,5,22.26-11.63Z" transform="translate(-51.09 -184.42)" style="fill: #e5e5e5"/>
                  <path d="M153.12,698.13,138.66,693h0c0-2.19-6.82-4-15.23-4s-15.24,1.77-15.24,4c0,.82,1,1.58,2.59,2.22h0c.49.19,1.06.37,1.67.54l18.07,5.82,19.91-2.87-12,3.95,35.75,9.79,23.81-3-42.32-11.82Z" transform="translate(-51.09 -184.42)" style="fill: #e5e5e5"/>
                  <polygon points="204.18 441.3 193.53 441.3 216.85 445.78 204.18 441.3" style="fill: #bfbfbf"/>
                  <polygon points="238.5 440.71 230.96 441.34 265.55 449.7 238.5 440.71" style="fill: #bfbfbf"/>
                </g>
                <path d="M160.25,625h-89V618.5s-4-92.43,54.85-92.43c53.32,0,42-50.14,38.81-94.18s2.9-119.83,73.26-119.83c52.47,0,67.8,39.27,69.75,57.2s-9,86.89,55.38,86.89c75.53,0,153.75-19,153.75,85.22v83.9Z" transform="translate(-51.09 -184.42)" style="fill: #fcf7f5"/>
                <polygon points="419.38 152.33 378.1 178.93 390.14 180.28 347.47 247.86 323.08 218.75 256.84 317.78 236.32 296.12 144.86 400.72 158.8 412.9 236.87 323.62 259.35 347.33 324.75 249.57 349.57 279.2 406.19 189.55 411.98 198.43 419.38 152.33" style="fill: #ccd8ff"/>
                <path d="M411.28,370.08,398,378.59l6.53.76-49,70.87L325,407.05,143.46,623.69l1.11.93,180.35-215.2,30.6,43.33,50.21-72.65,3.11,4.8Zm-6,9.36Z" transform="translate(-51.09 -184.42)" style="fill: #5c5dd8"/>
                <rect y="439.9" width="493.19" height="1.45" style="fill: #95bdbf"/>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon points="356.05 432.45 356.01 456.33 328.96 448.11 329 424.23 356.05 432.45" style="fill: #6fa3c6"/>
                        <polygon points="382.93 424.27 356.05 432.45 329 424.23 355.87 416.06 382.93 424.27" style="fill: #87b6dd"/>
                        <path d="M400,609.26l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a1.25,1.25,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12l0,.08-.09.11-.07.07-.11.1-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.75-.07-.48,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54c.24,0,.48,0,.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.63-.1.47-.12c.18-.05.37-.08.53-.14l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M398.24,604.24c2.63.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,398.24,604.24Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <polygon points="382.93 424.27 382.89 448.15 356.01 456.33 356.05 432.45 382.93 424.27" style="fill: #b4cfea"/>
                        <path d="M413.51,613.38l.08-.12.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.49,0-.79-.13c-.12,0-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05.28-.13.13-.07.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.76,608.35c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,411.76,608.35Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M413.42,605.16l.09-.11.05-.07.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0H407l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.67.18,1,.26l.37.06.79.13.49,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.68,600.14c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.64-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,411.68,600.14Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <path d="M426.94,609.28l.09-.12.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0c.17,0,.33-.11.48-.17l.13,0a2.24,2.24,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M425.19,604.25c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.08-3A18.42,18.42,0,0,1,425.19,604.25Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="356.05 408.08 356.01 431.95 328.96 423.74 329 399.86 356.05 408.08" style="fill: #79b3d3"/>
                        <polygon points="382.93 399.9 356.05 408.08 329 399.86 355.87 391.68 382.93 399.9" style="fill: #87b6dd"/>
                        <path d="M400,584.89l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12l0,.07-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.54l.73,0,.54,0c.25,0,.49,0,.73,0l.55,0,.67-.06.53-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M398.24,579.86c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,398.24,579.86Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <polygon points="382.93 399.9 382.89 423.78 356.01 431.95 356.05 408.08 382.93 399.9" style="fill: #b4cfea"/>
                        <path d="M413.51,589a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.53c.25,0,.49,0,.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12c.18-.05.36-.08.53-.14l.08,0c.17,0,.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.76,584c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,411.76,584Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M413.42,580.79a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18s0,0,0,.07l-.06.12-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37,0c.26.05.52.1.79.13l.49.05c.25,0,.49,0,.75.06s.35,0,.53,0l.74,0,.54,0c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.68,575.76c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.64-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,411.68,575.76Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <path d="M426.94,584.9l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09c-.22,0-.46,0-.68.06l-.55,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1.05.26l.37.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17-.05.36-.08.52-.14l.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M425.19,579.88c2.63.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,425.19,579.88Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="356.05 383.7 356.01 407.58 328.96 399.36 329 375.49 356.05 383.7" style="fill: #79b3d3"/>
                        <polygon points="382.93 375.53 356.05 383.7 329 375.49 355.87 367.31 382.93 375.53" style="fill: #87b6dd"/>
                        <path d="M400,560.51l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.06-.73,0-.54,0-.73,0-.54,0-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26.05.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11l.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M398.24,555.49c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,398.24,555.49Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <polygon points="382.93 375.53 382.89 399.4 356.01 407.58 356.05 383.7 382.93 375.53" style="fill: #b4cfea"/>
                        <path d="M413.51,564.63l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36.06c.26,0,.52.09.8.12l.48,0,.75.07h.53l.74,0,.54,0c.24,0,.48,0,.72,0l.55,0,.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.17.13-.06.28-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.76,559.6c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,411.76,559.6Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M413.42,556.41a.48.48,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.55,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0c.17-.05.32-.11.47-.17l.13-.06a1.69,1.69,0,0,0,.27-.12l.14-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.68,551.39c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.64-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,411.68,551.39Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <path d="M426.94,560.53l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.28,0-.53-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.37,0c.26.05.52.1.79.13l.49.05.74.07h.54l.73,0,.55,0c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M425.19,555.5c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,425.19,555.5Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="356.05 359.33 356.01 383.21 328.96 374.99 329 351.11 356.05 359.33" style="fill: #79b3d3"/>
                        <polygon points="382.93 351.15 356.05 359.33 329 351.11 355.87 342.94 382.93 351.15" style="fill: #87b6dd"/>
                        <path d="M400,536.14l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a1.25,1.25,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12l0,.08-.09.11-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.53.09-.67.06-.55,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.75-.07-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.09,2,1.49a10,10,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M398.24,531.12c2.63.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,398.24,531.12Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <polygon points="382.93 351.15 382.89 375.03 356.01 383.21 356.05 359.33 382.93 351.15" style="fill: #b4cfea"/>
                        <path d="M413.51,540.25a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.54,0-.74,0-.53,0-.75-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.16-1.05-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0c.17,0,.32-.11.47-.17l.13,0,.28-.13.13-.07.2-.12a.5.5,0,0,0,.12-.08.58.58,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.76,535.23c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,411.76,535.23Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M413.42,532l.09-.11.05-.07.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0H407l-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.09,2,1.49a9.25,9.25,0,0,0,1,.27l.37.06.79.13.49,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.68,527c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.64-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,411.68,527Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <path d="M426.94,536.15,427,536l.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.46,0-.68.06l-.55.06-.73,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.5v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0c.17,0,.33-.11.48-.17l.13-.06a1.31,1.31,0,0,0,.27-.12l.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M425.19,531.13c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.08-3A18.42,18.42,0,0,1,425.19,531.13Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="356.05 334.95 356.01 358.83 328.96 350.62 329 326.74 356.05 334.95" style="fill: #79b3d3"/>
                        <polygon points="382.93 326.78 356.05 334.95 329 326.74 355.87 318.56 382.93 326.78" style="fill: #87b6dd"/>
                        <path d="M400,511.77l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a1.13,1.13,0,0,0,0-.18v-3a1.11,1.11,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.12a.46.46,0,0,0-.07.07l-.11.1a.91.91,0,0,1-.16.11l-.11.09-.21.11-.13.07-.27.13-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.26.05.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.73,0,.55,0,.67-.07.53-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M398.24,506.74c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.53,18.53,0,0,1,398.24,506.74Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <polygon points="382.93 326.78 382.89 350.66 356.01 358.83 356.05 334.95 382.93 326.78" style="fill: #b4cfea"/>
                        <path d="M413.51,515.88a.76.76,0,0,0,.08-.11l.05-.07a1.39,1.39,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.48.12a5.89,5.89,0,0,1-.63.11l-.52.09-.68.06-.55,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.09,2,1.49a9.44,9.44,0,0,0,1.05.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06l.53-.09.62-.1.48-.12.53-.14.08,0,.47-.17.13-.06.28-.12.13-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.76,510.86c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,411.76,510.86Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M413.42,507.67a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12a.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1a.83.83,0,0,1-.15.11l-.12.09-.2.11-.14.07a1.69,1.69,0,0,1-.27.13l-.13.05-.47.17c-.19.06-.41.11-.61.16s-.31.09-.48.12L410,506l-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49,0,.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07L410,509l.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M411.68,502.64c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.64-.81-2.67-2.14-.08-3A18.53,18.53,0,0,1,411.68,502.64Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                      <g>
                        <path d="M426.94,511.78l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.28,0-.53-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.09,2,1.49a10,10,0,0,0,1.05.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0,.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                        <path d="M425.19,506.76c2.63.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.53,18.53,0,0,1,425.19,506.76Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="356.05 310.58 356.01 334.46 328.96 326.24 329 302.37 356.05 310.58" style="fill: #82e0e0"/>
                        <polygon points="382.93 302.41 356.05 310.58 329 302.37 355.87 294.19 382.93 302.41" style="fill: #a7fcfa"/>
                        <path d="M400,487.39l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.49.49,0,0,1-.06.13.43.43,0,0,0,0,.07l-.09.11-.07.08-.11.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.5v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.8.13.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07c.19,0,.35-.06.53-.09l.63-.1.47-.12c.18,0,.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M398.24,482.37c2.63.81,2.66,2.13.08,3a18.64,18.64,0,0,1-9.44,0c-2.63-.82-2.67-2.14-.08-3A18.42,18.42,0,0,1,398.24,482.37Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                      <g>
                        <polygon points="382.93 302.41 382.89 326.28 356.01 334.46 356.05 310.58 382.93 302.41" style="fill: #b7f1f4"/>
                        <path d="M413.51,491.51a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1a.83.83,0,0,1-.15.11l-.12.09-.2.11a.61.61,0,0,1-.14.07,1.69,1.69,0,0,1-.27.13l-.13,0c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.16.13-.06.28-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M411.76,486.48c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.53,18.53,0,0,1,411.76,486.48Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M413.42,483.29a.48.48,0,0,0,.09-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0H407l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06.79.13.49,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07c.18,0,.35-.06.52-.09l.63-.1.48-.12.52-.13.09,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M411.68,478.27c2.62.81,2.66,2.13.08,3a18.64,18.64,0,0,1-9.44,0c-2.64-.82-2.67-2.14-.08-3A18.42,18.42,0,0,1,411.68,478.27Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                      <g>
                        <path d="M426.94,487.41l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.12-.07.07-.11.1-.15.11-.12.09-.2.11-.14.07a2.24,2.24,0,0,1-.27.13l-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.28,0-.53-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.05.74.06l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M425.19,482.38c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.08-3A18.53,18.53,0,0,1,425.19,482.38Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="356.05 286.21 356.01 310.09 328.96 301.87 329 277.99 356.05 286.21" style="fill: #82e0e0"/>
                        <polygon points="382.93 278.03 356.05 286.21 329 277.99 355.87 269.82 382.93 278.03" style="fill: #a7fcfa"/>
                        <path d="M400,463l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16a5.43,5.43,0,0,1-.61.16c-.16,0-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.53.08-.67.07-.55.05c-.24,0-.49,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0l-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.07Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M398.24,458c2.63.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.42,18.42,0,0,1,398.24,458Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                      <g>
                        <polygon points="382.93 278.03 382.89 301.91 356.01 310.09 356.05 286.21 382.93 278.03" style="fill: #b7f1f4"/>
                        <path d="M413.51,467.13a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.09.11l-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55,0c-.24,0-.48,0-.72,0h-.54l-.74,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06c-.37-.08-.73-.16-1.05-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.08,2,1.49a9.48,9.48,0,0,0,1.05.26l.36.06.8.13.48,0c.25,0,.49.06.75.07l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07c.18,0,.35-.06.53-.09l.62-.1.48-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08,1,1,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M411.76,462.11c2.62.81,2.66,2.13.08,3a18.64,18.64,0,0,1-9.44,0c-2.63-.82-2.67-2.14-.08-3A18.42,18.42,0,0,1,411.76,462.11Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M413.42,458.92l.09-.11a.35.35,0,0,0,.05-.08l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16a6.06,6.06,0,0,1-.61.16c-.16,0-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05c-.24,0-.48,0-.72,0l-.55,0-.73,0c-.18,0-.36,0-.54,0s-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.37-.05c-.37-.08-.72-.17-1-.27-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06c.26,0,.52.09.79.12l.49.05.75.07h.53l.74,0h.54l.72,0,.55-.05.68-.06.52-.09.63-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M411.68,453.9c2.62.8,2.66,2.13.08,3a18.42,18.42,0,0,1-9.44,0c-2.64-.81-2.67-2.13-.08-3A18.42,18.42,0,0,1,411.68,453.9Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                      <g>
                        <path d="M426.94,463l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.46,0-.68.06l-.55.05c-.24,0-.48,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-1-2-1.5v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.37.06.79.13.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07c.18,0,.35-.06.52-.09l.63-.1.48-.12.52-.13.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M425.19,458c2.63.81,2.66,2.13.08,3a18.64,18.64,0,0,1-9.44,0c-2.63-.82-2.66-2.14-.08-3A18.42,18.42,0,0,1,425.19,458Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="356.05 261.83 356.01 285.71 328.96 277.5 329 253.62 356.05 261.83" style="fill: #82e0e0"/>
                        <polygon points="382.93 253.66 356.05 261.83 329 253.62 355.87 245.44 382.93 253.66" style="fill: #a7fcfa"/>
                        <path d="M400,438.65l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a1.13,1.13,0,0,0,0-.18v-3a1.11,1.11,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1a.91.91,0,0,1-.16.11l-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.24,0,.36.06s.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M398.24,433.62c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,398.24,433.62Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                      <g>
                        <polygon points="382.93 253.66 382.89 277.54 356.01 285.71 356.05 261.83 382.93 253.66" style="fill: #b7f1f4"/>
                        <path d="M413.51,442.76l.08-.11a.35.35,0,0,0,.05-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16a6.06,6.06,0,0,1-.61.16c-.16,0-.31.09-.48.12a5.89,5.89,0,0,1-.63.11l-.52.08-.68.07-.55,0c-.24,0-.48,0-.72,0l-.54,0-.74,0c-.18,0-.36,0-.53,0s-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.36-.05c-.37-.08-.73-.17-1.05-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1.05.27l.36.06.8.13.48,0,.75.07h.53l.74,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06l.53-.09.62-.1.48-.12.53-.14.08,0,.47-.17.13-.06.28-.12.13-.07.2-.12.12-.08.15-.12.05,0s0,0,.06-.07Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M411.76,437.74c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.42,18.42,0,0,1,411.76,437.74Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M413.42,434.55a.94.94,0,0,0,.09-.12l.05-.07.06-.12a.25.25,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1a.83.83,0,0,1-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49,0,.75.06l.53,0,.74,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0c.17-.05.32-.11.47-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M411.68,429.52c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.64-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,411.68,429.52Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                      <g>
                        <path d="M426.94,438.66l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.14.06-.47.16a5.43,5.43,0,0,1-.61.16c-.15,0-.3.09-.47.12l-.63.11-.52.08-.68.07-.55.05c-.24,0-.48,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0s-.5,0-.74-.06l-.49-.05c-.28,0-.53-.08-.8-.13l-.36-.05q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.5.15,1.05.27l.37.06c.26,0,.52.09.79.12l.49,0,.74.07h.54l.73,0h.55c.24,0,.48,0,.72,0l.55,0L423,440l.52-.09.63-.1.48-.12.52-.14.08,0,.48-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                        <path d="M425.19,433.64c2.63.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.08-3A18.42,18.42,0,0,1,425.19,433.64Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M370,633.32h0V629.1a1.26,1.26,0,0,1,0,.27s0,0,0,.08a.71.71,0,0,1-.08.18.36.36,0,0,1-.07.1l-.12.16-.1.11-.16.14-.22.17-.16.11a2.69,2.69,0,0,1-.29.16l-.19.11-.39.17-.19.08a5.84,5.84,0,0,1-.67.24c-.27.09-.57.15-.86.23l-.68.17c-.28.06-.59.1-.89.15s-.49.09-.74.12-.64.06-1,.09-.51.06-.78.07l-1,0-.77,0c-.35,0-.7,0-1,0l-.77,0c-.36,0-.7-.06-1.05-.1s-.47,0-.69-.06c-.39-.05-.76-.12-1.13-.18-.17,0-.36,0-.52-.09a14.75,14.75,0,0,1-1.49-.37c-1.89-.58-2.83-1.35-2.83-2.12v1.36h0v3h0v4.22c0,.77.95,1.54,2.83,2.12.47.14,1,.27,1.49.37.17,0,.35.06.52.09.37.06.74.13,1.13.18.22,0,.46,0,.69.06s.69.08,1.05.1l.77,0c.34,0,.69,0,1,0l.77,0,1,0c.27,0,.52,0,.79-.07l1-.09.74-.12c.3,0,.61-.09.89-.15l.68-.17.75-.19.11,0,.67-.24.19-.08.39-.17.19-.11L369,639l.16-.12.22-.16.07,0,.09-.09.1-.11a4.91,4.91,0,0,0,.19-.26.71.71,0,0,0,.08-.18l0-.09a1.09,1.09,0,0,0,0-.26v-3h0Z" transform="translate(-51.09 -184.42)" style="fill: #61a0bc"/>
                    <path d="M373.75,608H347.34v23.51h0c0,1.07,1.31,2.14,3.94,3a36.61,36.61,0,0,0,18.68,0c2.52-.8,3.78-1.85,3.78-2.9h0Z" transform="translate(-51.09 -184.42)" style="fill: #79b3d3"/>
                    <path d="M369.8,605c5.2,1.6,5.27,4.22.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.23-.15-5.86A36.58,36.58,0,0,1,369.8,605Z" transform="translate(-51.09 -184.42)" style="fill: #afcee2"/>
                    <path d="M367,605.15h0v-2.92a1.37,1.37,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.11-.07.07-.1.1-.16.12-.11.07-.2.12-.13.07-.27.12-.13.06-.46.16-.6.16-.47.11a6,6,0,0,1-.61.11l-.52.08-.66.07-.54,0-.71,0-.54,0-.72,0-.52,0c-.25,0-.49,0-.74-.07l-.47,0c-.27,0-.53-.08-.78-.13l-.36,0c-.36-.08-.71-.16-1-.26-1.3-.4-2-.93-2-1.47v.94h0v2.05h0v2.92c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26l.36.06.78.13.47,0c.25,0,.49,0,.74.06s.35,0,.52,0l.72,0,.54,0c.23,0,.47,0,.71,0l.54,0L363,610l.52-.09.61-.1c.17,0,.31-.08.47-.12l.52-.13.08,0,.46-.17.13-.06a2.21,2.21,0,0,0,.27-.12l.13-.07.2-.11.12-.08.15-.11,0,0,.06-.06.07-.07a.94.94,0,0,0,.09-.12l.05-.07a.61.61,0,0,0,.05-.12l0-.06a.57.57,0,0,0,0-.18v-2h0Z" transform="translate(-51.09 -184.42)" style="fill: #5188f9"/>
                    <path d="M365.08,600.77c2.58.79,2.61,2.09.08,2.9a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.09,18.09,0,0,1,365.08,600.77Z" transform="translate(-51.09 -184.42)" style="fill: #88cefb"/>
                  </g>
                  <g>
                    <path d="M370,602.9h0v-4.22a1.09,1.09,0,0,1,0,.26l0,.09a.71.71,0,0,1-.08.18.61.61,0,0,1-.07.1l-.12.16-.1.11-.16.14-.22.16L369,600l-.29.16-.19.11-.39.17-.19.08-.67.24c-.27.09-.57.15-.86.23l-.68.17-.89.15-.74.12c-.32,0-.64.06-1,.09s-.51.06-.78.07l-1,0-.77,0c-.35,0-.7,0-1,0l-.77,0c-.36,0-.7-.06-1.05-.1s-.47,0-.69-.06c-.39-.05-.76-.12-1.13-.18-.17,0-.36,0-.52-.09a14.75,14.75,0,0,1-1.49-.37c-1.89-.58-2.83-1.35-2.83-2.12V600h0v3h0v4.22c0,.77.95,1.54,2.83,2.12.47.14,1,.26,1.49.37l.52.09c.37.06.74.13,1.13.18.22,0,.46,0,.69.06l1.05.1.77,0c.34,0,.69,0,1,0s.51,0,.77,0,.69,0,1,0l.79-.07,1-.09.74-.13c.3,0,.61-.09.89-.15l.68-.17c.25-.06.51-.11.75-.18l.11,0,.67-.24.19-.08.39-.17.19-.11.29-.16.16-.12.22-.16.07-.06.09-.08.1-.11a.83.83,0,0,0,.12-.16.61.61,0,0,0,.07-.1.71.71,0,0,0,.08-.18l0-.09a1.27,1.27,0,0,0,0-.26v-3h0Z" transform="translate(-51.09 -184.42)" style="fill: #61a0bc"/>
                    <path d="M373.75,577.62H347.34v23.51h0c0,1.07,1.31,2.14,3.94,3A36.61,36.61,0,0,0,370,604c2.52-.81,3.78-1.85,3.78-2.9h0Z" transform="translate(-51.09 -184.42)" style="fill: #79b3d3"/>
                    <path d="M369.8,574.6c5.2,1.6,5.27,4.22.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.23-.15-5.86A36.47,36.47,0,0,1,369.8,574.6Z" transform="translate(-51.09 -184.42)" style="fill: #afcee2"/>
                    <path d="M367,574.73h0v-2.92a1.1,1.1,0,0,1,0,.18l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.07-.1.1a.91.91,0,0,1-.16.11l-.11.08-.2.12-.13.07-.27.12-.13.05-.46.17-.6.16-.47.11-.61.11-.52.08-.66.06-.54.05-.71,0h-.54l-.72,0h-.52l-.74-.07-.47,0c-.27,0-.53-.08-.78-.13s-.25,0-.36-.06c-.36-.07-.71-.16-1-.25-1.3-.41-2-.94-2-1.47v.94h0v2h0v2.92c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26l.36.06c.25,0,.51.09.78.12l.47,0c.25,0,.49.05.74.06l.52,0,.72,0,.54,0,.71,0,.54-.05.66-.06.52-.09.61-.1.47-.12c.17,0,.36-.08.52-.13l.08,0c.16,0,.32-.1.46-.16l.13-.06.27-.12.13-.07.2-.11.12-.08.15-.12,0,0,.06-.06.07-.08a.48.48,0,0,0,.09-.11l.05-.07A.61.61,0,0,0,367,578l0-.06a.58.58,0,0,0,0-.19v-2.05h0Z" transform="translate(-51.09 -184.42)" style="fill: #5188f9"/>
                    <path d="M365.08,570.34c2.58.8,2.61,2.1.08,2.91a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.2,18.2,0,0,1,365.08,570.34Z" transform="translate(-51.09 -184.42)" style="fill: #88cefb"/>
                  </g>
                  <g>
                    <path d="M370,572.85h0v-4.22a1.09,1.09,0,0,1,0,.26l0,.09a.71.71,0,0,1-.08.18.61.61,0,0,1-.07.1l-.12.16-.1.11-.16.14-.22.16L369,570l-.29.16-.19.11-.39.17-.19.08-.67.24c-.27.09-.57.15-.86.23l-.68.17c-.28.06-.59.1-.89.15l-.74.12c-.32,0-.64.06-1,.09s-.51.06-.78.07l-1,0-.77,0c-.35,0-.7,0-1,0l-.77,0c-.36,0-.7-.06-1.05-.1s-.47,0-.69-.06c-.39,0-.76-.12-1.13-.18-.17,0-.36-.05-.52-.09a14.75,14.75,0,0,1-1.49-.37c-1.89-.58-2.83-1.35-2.83-2.12V570h0v3h0v4.22c0,.77.95,1.54,2.83,2.12.47.14,1,.26,1.49.37.17,0,.35.06.52.09.37.06.74.13,1.13.18.22,0,.46,0,.69.06s.69.08,1.05.1l.77,0c.34,0,.69,0,1,0s.51,0,.77,0,.69,0,1,0l.79-.07c.32,0,.64,0,1-.09l.74-.13c.3,0,.61-.09.89-.15s.45-.11.68-.17.51-.11.75-.18l.11,0,.67-.24.19-.08.39-.17.19-.11.29-.16.16-.12.22-.16.07-.06.09-.08.1-.11a.83.83,0,0,0,.12-.16.61.61,0,0,0,.07-.1.71.71,0,0,0,.08-.18l0-.09a1.27,1.27,0,0,0,0-.26v-3h0Z" transform="translate(-51.09 -184.42)" style="fill: #61a0bc"/>
                    <path d="M373.75,547.57H347.34v23.51h0c0,1.07,1.31,2.14,3.94,2.95A36.61,36.61,0,0,0,370,574c2.52-.81,3.78-1.85,3.78-2.9h0Z" transform="translate(-51.09 -184.42)" style="fill: #79b3d3"/>
                    <path d="M369.8,544.55c5.2,1.6,5.27,4.22.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.23-.15-5.86A36.47,36.47,0,0,1,369.8,544.55Z" transform="translate(-51.09 -184.42)" style="fill: #afcee2"/>
                    <path d="M367,544.68h0v-2.92a1.1,1.1,0,0,1,0,.18l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.07-.1.1a.91.91,0,0,1-.16.11l-.11.08-.2.12-.13.07-.27.12-.13,0-.46.17-.6.16-.47.11-.61.11-.52.08c-.22,0-.44,0-.66.06l-.54,0-.71,0h-.54l-.72,0h-.52l-.74-.07-.47,0c-.27,0-.53-.08-.78-.13l-.36,0c-.36-.08-.71-.17-1-.26-1.3-.41-2-.94-2-1.47v.94h0v2h0v2.92c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26l.36.06c.25,0,.51.09.78.12l.47,0c.25,0,.49,0,.74.06l.52,0,.72,0,.54,0,.71,0,.54,0,.66-.06.52-.09.61-.1.47-.12.52-.13.08,0c.16-.05.32-.1.46-.16l.13-.06.27-.12.13-.07.2-.11.12-.08.15-.12,0,0,.06-.06.07-.08a.48.48,0,0,0,.09-.11l.05-.07a.61.61,0,0,0,.05-.12l0-.06a.58.58,0,0,0,0-.19v-2h0Z" transform="translate(-51.09 -184.42)" style="fill: #5188f9"/>
                    <path d="M365.08,540.29c2.58.8,2.61,2.1.08,2.91a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.2,18.2,0,0,1,365.08,540.29Z" transform="translate(-51.09 -184.42)" style="fill: #88cefb"/>
                  </g>
                  <g>
                    <path d="M370,542.71h0v-4.22a1.26,1.26,0,0,1,0,.27s0,0,0,.08a.71.71,0,0,1-.08.18.36.36,0,0,1-.07.1l-.12.17-.1.1-.16.14-.22.17-.16.11a2.69,2.69,0,0,1-.29.16l-.19.11-.39.17-.19.08a5.84,5.84,0,0,1-.67.24c-.27.09-.57.15-.86.23l-.68.17c-.28.06-.59.1-.89.15s-.49.09-.74.12-.64.06-1,.09-.51.06-.78.07l-1,0-.77,0c-.35,0-.7,0-1,0l-.77,0c-.36,0-.7-.06-1.05-.1s-.47,0-.69-.06c-.39,0-.76-.12-1.13-.18-.17,0-.36-.05-.52-.09a14.75,14.75,0,0,1-1.49-.37C352,540,351,539.26,351,538.49v1.36h0v3h0V547c0,.77.95,1.54,2.83,2.12.47.14,1,.27,1.49.37.17,0,.35.06.52.09.37.06.74.13,1.13.18.22,0,.46,0,.69.06s.69.08,1.05.1l.77,0c.34,0,.69,0,1,0l.77,0,1,0c.27,0,.52-.05.79-.07s.64,0,1-.09.49-.08.74-.12.61-.09.89-.15l.68-.17.75-.19.11,0,.67-.24.19-.08.39-.17.19-.11.29-.16.16-.12a1.25,1.25,0,0,0,.22-.16l.07-.05.09-.09.1-.11a4.91,4.91,0,0,0,.19-.26.71.71,0,0,0,.08-.18l0-.09a1.09,1.09,0,0,0,0-.26v-3h0Z" transform="translate(-51.09 -184.42)" style="fill: #6bc9c7"/>
                    <path d="M373.75,517.43H347.34v23.51h0c0,1.07,1.31,2.14,3.94,2.95a36.61,36.61,0,0,0,18.68,0c2.52-.8,3.78-1.85,3.78-2.9h0Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                    <path d="M369.8,514.41c5.2,1.6,5.27,4.22.16,5.85a36.5,36.5,0,0,1-18.68.06c-5.2-1.61-5.27-4.22-.15-5.86A36.58,36.58,0,0,1,369.8,514.41Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                    <path d="M367,514.54h0v-2.92a1.37,1.37,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.11-.07.07-.1.1-.16.12-.11.07-.2.12-.13.07-.27.12-.13.06-.46.16-.6.16-.47.12-.61.1-.52.08-.66.07-.54,0-.71,0-.54,0-.72,0-.52,0c-.25,0-.49,0-.74-.07l-.47,0c-.27,0-.53-.08-.78-.13l-.36,0c-.36-.08-.71-.16-1-.26-1.3-.4-2-.93-2-1.47v.94h0v2.05h0v2.92c0,.53.66,1.06,2,1.46a9.68,9.68,0,0,0,1,.26l.36.06.78.13.47,0,.74.07h.52l.72,0,.54,0c.23,0,.47,0,.71,0l.54,0c.22,0,.45,0,.66-.06l.52-.09.61-.1c.17,0,.31-.08.47-.12l.52-.13.08,0,.46-.17.13-.06a2.21,2.21,0,0,0,.27-.12l.13-.07.2-.11.12-.08.15-.11,0,0,.06-.06.07-.07a.94.94,0,0,0,.09-.12l.05-.07a.61.61,0,0,0,.05-.12l0-.06a.57.57,0,0,0,0-.18v-2h0Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                    <path d="M365.08,510.16c2.58.79,2.61,2.09.08,2.9a18.2,18.2,0,0,1-9.27,0c-2.58-.8-2.62-2.1-.08-2.91A18.09,18.09,0,0,1,365.08,510.16Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                  </g>
                  <path d="M349.05,543.06a11.8,11.8,0,0,0,2.09.83,27.43,27.43,0,0,0,5.24,1V521.31a27.43,27.43,0,0,1-5.24-1,12.57,12.57,0,0,1-2.09-.84Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                  <path d="M366.37,521.06v23.57a23.74,23.74,0,0,0,3.45-.79l.14-.05V520.22l-.14,0A23.71,23.71,0,0,1,366.37,521.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                  <path d="M366.37,551.14v23.57a22.5,22.5,0,0,0,3.45-.79l.14,0V550.3l-.14.05A23.74,23.74,0,0,1,366.37,551.14Z" transform="translate(-51.09 -184.42)" style="fill: #79b3d3"/>
                  <path d="M366.39,581.3v23.58a23.74,23.74,0,0,0,3.45-.79L370,604V580.46l-.14,0A23.74,23.74,0,0,1,366.39,581.3Z" transform="translate(-51.09 -184.42)" style="fill: #79b3d3"/>
                  <path d="M366.39,611.71v23.58a23.74,23.74,0,0,0,3.45-.79l.14,0V610.88l-.14,0A22.5,22.5,0,0,1,366.39,611.71Z" transform="translate(-51.09 -184.42)" style="fill: #79b3d3"/>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="265.58 425.82 265.55 449.7 238.5 441.48 238.53 417.6 265.58 425.82" style="fill: #79b3d3"/>
                      <polygon points="279.02 421.75 265.58 425.82 238.53 417.6 251.97 413.53 279.02 421.75" style="fill: #87b6dd"/>
                      <path d="M309.52,602.63l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.12-.05.08-.09.11a.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0-.72,0h-.55l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06q-.56-.12-1-.27c-1.33-.41-2-1-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.67.18,1,.26l.37.06.79.13.49,0,.74.07h.54c.25,0,.49,0,.73,0h.55l.72,0,.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17-.05.36-.08.52-.14l.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M307.78,597.61c2.62.81,2.66,2.13.07,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.07-3A18.53,18.53,0,0,1,307.78,597.61Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                    <g>
                      <polygon points="279.02 421.75 278.99 445.62 265.55 449.7 265.58 425.82 279.02 421.75" style="fill: #b4cfea"/>
                      <path d="M323,606.75l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0-.8-.13L313,605a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.27,0,.52.1.8.13l.48,0,.75.06.54,0,.73,0,.54,0,.73,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M321.29,601.72c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,321.29,601.72Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="265.58 401.52 265.55 425.39 238.5 417.18 238.53 393.3 265.58 401.52" style="fill: #79b3d3"/>
                      <polygon points="279.02 397.44 265.58 401.52 238.53 393.3 251.97 389.22 279.02 397.44" style="fill: #87b6dd"/>
                      <path d="M309.52,578.33a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.14.14,0,0,0,0,.07l-.06.12-.05.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27.12,0,.25,0,.37.06s.52.09.79.12l.49,0,.74.07h.54l.73,0,.55,0c.24,0,.48,0,.72,0l.55-.05.68-.06.52-.09a5.89,5.89,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M307.78,573.3c2.62.81,2.66,2.14.07,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.07-3A18.53,18.53,0,0,1,307.78,573.3Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                    <g>
                      <polygon points="279.02 397.44 278.99 421.32 265.55 425.39 265.58 401.52 279.02 397.44" style="fill: #b4cfea"/>
                      <path d="M323,582.44l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11-.07.07-.11.1-.16.12-.11.08-.2.12-.14.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.53.09c-.22,0-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.55.66,1.09,2,1.5.33.1.68.18,1.05.26l.36.06.8.13.48,0,.75.07h.54c.24,0,.49,0,.73,0h.54c.25,0,.49,0,.73,0l.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12c.17,0,.36-.08.52-.14l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M321.29,577.42c2.63.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,321.29,577.42Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="265.58 377.21 265.55 401.09 238.5 392.87 238.53 369 265.58 377.21" style="fill: #79b3d3"/>
                      <polygon points="279.02 373.14 265.58 377.21 238.53 369 251.97 364.92 279.02 373.14" style="fill: #87b6dd"/>
                      <path d="M309.52,554a.48.48,0,0,0,.09-.11l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16l-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.55,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.5v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.25,0,.49.06.74.07l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M307.78,549c2.62.81,2.66,2.14.07,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.07-3A18.42,18.42,0,0,1,307.78,549Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                    <g>
                      <polygon points="279.02 373.14 278.99 397.01 265.55 401.09 265.58 377.21 279.02 373.14" style="fill: #b4cfea"/>
                      <path d="M323,558.14l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.53.08-.67.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1.05.27l.36,0c.27.05.52.1.8.13l.48.05.75.06c.18,0,.36,0,.54,0l.73,0,.54,0c.25,0,.49,0,.73,0l.55,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.17.14-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M321.29,553.11c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,321.29,553.11Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="265.58 352.91 265.55 376.79 238.5 368.57 238.53 344.69 265.58 352.91" style="fill: #79b3d3"/>
                      <polygon points="279.02 348.83 265.58 352.91 238.53 344.69 251.97 340.62 279.02 348.83" style="fill: #87b6dd"/>
                      <path d="M309.52,529.72l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.12-.05.07a.94.94,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.15s-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.24,0-.48,0-.72,0l-.55,0-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06q-.56-.12-1-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.25,9.25,0,0,0,1,.27l.37.06.79.13.49,0,.74.07h.54l.73,0h.55l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M307.78,524.7c2.62.8,2.66,2.13.07,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.07-3A18.53,18.53,0,0,1,307.78,524.7Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                    <g>
                      <polygon points="279.02 348.83 278.99 372.71 265.55 376.79 265.58 352.91 279.02 348.83" style="fill: #b4cfea"/>
                      <path d="M323,533.83l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.08-.11.1-.16.11-.11.08-.2.12-.14.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.53.09s-.45,0-.67.06l-.55.05c-.24,0-.49,0-.73,0h-.54c-.25,0-.49,0-.73,0h-.54l-.74-.07-.49,0-.8-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.33-.41-2-.95-2-1.5v3c0,.54.66,1.08,2,1.49A10.07,10.07,0,0,0,313,535l.36.06c.27,0,.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.73,0,.55-.05.68-.07.52-.08.63-.11.48-.12c.17,0,.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M321.29,528.81c2.63.81,2.66,2.14.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,321.29,528.81Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="265.58 328.6 265.55 352.48 238.5 344.27 238.53 320.39 265.58 328.6" style="fill: #79b3d3"/>
                      <polygon points="279.02 324.53 265.58 328.6 238.53 320.39 251.97 316.31 279.02 324.53" style="fill: #87b6dd"/>
                      <path d="M309.52,505.42a.94.94,0,0,0,.09-.12.18.18,0,0,0,.05-.07l.06-.12s0-.05,0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07a1.11,1.11,0,0,1-.27.12l-.13.06c-.15.06-.31.12-.48.17s-.4.11-.6.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49,0,.74.06l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06A.46.46,0,0,0,309.52,505.42Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M307.78,500.39c2.62.81,2.66,2.14.07,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.07-3A18.53,18.53,0,0,1,307.78,500.39Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                    <g>
                      <polygon points="279.02 324.53 278.99 348.41 265.55 352.48 265.58 328.6 279.02 324.53" style="fill: #b4cfea"/>
                      <path d="M323,509.53l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12l-.05.07-.09.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12a6.37,6.37,0,0,1-.63.11l-.53.09-.67.06-.55.05c-.24,0-.49,0-.73,0l-.54,0-.73,0h-.54l-.74-.07-.49-.05c-.28,0-.54-.08-.8-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49q.49.15,1.05.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.07Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M321.29,504.51c2.63.8,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,321.29,504.51Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="265.58 304.3 265.55 328.18 238.5 319.96 238.53 296.08 265.58 304.3" style="fill: #79b3d3"/>
                      <polygon points="279.02 300.23 265.58 304.3 238.53 296.08 251.97 292.01 279.02 300.23" style="fill: #87b6dd"/>
                      <path d="M309.52,481.11l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07-.09.11a.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.48.17c-.18.06-.4.1-.6.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.55l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.37-.06a10,10,0,0,1-1-.27c-1.33-.4-2-.95-2-1.49v3c0,.55.67,1.09,2,1.5a9.29,9.29,0,0,0,1,.26l.37.06.79.13.49,0,.74.07.54,0,.73,0,.55,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.48-.12.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M307.78,476.09c2.62.81,2.66,2.13.07,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.66-2.13-.07-2.95A18.42,18.42,0,0,1,307.78,476.09Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                    <g>
                      <polygon points="279.02 300.23 278.99 324.1 265.55 328.18 265.58 304.3 279.02 300.23" style="fill: #b4cfea"/>
                      <path d="M323,485.23l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1a.91.91,0,0,1-.16.11l-.11.08-.2.12-.14.07a2.21,2.21,0,0,1-.27.12l-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.28,0-.54-.08-.8-.13s-.24,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06c.27,0,.52.1.8.13l.48.05.75.06.54,0,.73,0,.54,0,.73,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #6fa3c6"/>
                      <path d="M321.29,480.2c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,321.29,480.2Z" transform="translate(-51.09 -184.42)" style="fill: #87b6dd"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="265.5 280 265.46 303.88 238.41 295.66 238.44 271.78 265.5 280" style="fill: #82e0e0"/>
                      <polygon points="278.94 275.92 265.5 280 238.44 271.78 251.89 267.7 278.94 275.92" style="fill: #a7fcfa"/>
                      <path d="M309.44,456.81l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,.05-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.53.08-.67.07-.55.05-.73,0-.54,0-.73,0-.54,0-.75-.06-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.54l.73,0h.54c.25,0,.49,0,.73,0l.55-.05.67-.06.53-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0,.47-.17.14-.06.27-.12.13-.08.21-.11.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                      <path d="M307.69,451.78c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,307.69,451.78Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                    </g>
                    <g>
                      <polygon points="278.94 275.92 278.9 299.8 265.46 303.88 265.5 280 278.94 275.92" style="fill: #b7f1f4"/>
                      <path d="M323,460.92a.76.76,0,0,0,.08-.11l0-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l0,.07L323,458a.23.23,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.48.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.75-.07-.49,0-.79-.13-.36-.06a9.44,9.44,0,0,1-1-.27c-1.33-.41-2-.95-2-1.49v3c0,.55.67,1.09,2,1.5.32.1.68.18,1,.26l.36.06.8.13.48,0,.75.07.53,0,.74,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.53-.09l.62-.1.48-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.28-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.15-.11.05,0a.34.34,0,0,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                      <path d="M321.21,455.9c2.62.81,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,321.21,455.9Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="265.5 255.69 265.46 279.57 238.41 271.35 238.44 247.48 265.5 255.69" style="fill: #82e0e0"/>
                      <polygon points="278.94 251.62 265.5 255.69 238.44 247.48 251.89 243.4 278.94 251.62" style="fill: #a7fcfa"/>
                      <path d="M309.44,432.5l.09-.11.05-.07a.5.5,0,0,0,.05-.13l0-.06a1.08,1.08,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.38.38,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.16.11-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.18,0-.35.07-.53.09s-.45,0-.67.06l-.55.06-.73,0-.54,0-.73,0-.54,0L301,431l-.48,0-.8-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.5.06.75.07l.54,0,.73,0,.54,0,.73,0,.55-.05.67-.07.53-.08.63-.11c.17,0,.31-.08.47-.12s.37-.08.53-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.05.27-.13.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11l.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                      <path d="M307.69,427.48c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,307.69,427.48Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                    </g>
                    <g>
                      <polygon points="278.94 251.62 278.9 275.5 265.46 279.57 265.5 255.69 278.94 251.62" style="fill: #b7f1f4"/>
                      <path d="M323,436.62l.08-.11a.35.35,0,0,0,0-.08.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.19.19,0,0,1,0,.07.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.41.11-.61.16s-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27l.36.06c.26,0,.52.09.8.12l.48.05.75.07h.53l.74,0h.54l.72,0,.55-.05.68-.06.53-.09a6.17,6.17,0,0,0,.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.17.13-.06.28-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0,.34.34,0,0,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                      <path d="M321.21,431.59c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,321.21,431.59Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                    </g>
                  </g>
                </g>
                <rect x="262.12" y="486.96" width="1.45" height="13.99"/>
                <g>
                  <g>
                    <polygon points="343.75 478.65 343.66 492.63 271.39 470.68 271.48 456.7 343.75 478.65" style="fill: #538d7e"/>
                    <polygon points="379.66 467.76 343.75 478.65 271.48 456.7 307.39 445.81 379.66 467.76" style="fill: #a4d6ca"/>
                  </g>
                  <polygon points="379.66 467.76 379.56 481.75 343.66 492.63 343.75 478.65 379.66 467.76" style="fill: #6ebca5"/>
                </g>
                <g>
                  <g>
                    <polygon points="343.75 464.33 343.66 478.31 271.39 456.36 271.48 442.38 343.75 464.33" style="fill: #538d7e"/>
                    <polygon points="379.66 453.44 343.75 464.33 271.48 442.38 307.39 431.49 379.66 453.44" style="fill: #a4d6ca"/>
                  </g>
                  <polygon points="379.66 453.44 379.56 467.43 343.66 478.31 343.75 464.33 379.66 453.44" style="fill: #6ebca5"/>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="343.84 449.97 343.75 463.95 271.48 442 271.57 428.02 343.84 449.97" style="fill: #538d7e"/>
                      <polygon points="379.75 439.08 343.84 449.97 271.57 428.02 307.48 417.13 379.75 439.08" style="fill: #b3e5d8"/>
                      <g>
                        <path d="M417.21,624.49,395.91,631a8.69,8.69,0,0,1-5.05,0l-55.13-16.86a1.68,1.68,0,0,1,0-3.21l20.58-6.24a11.09,11.09,0,0,1,6.48,0l54.42,16.64A1.68,1.68,0,0,1,417.21,624.49Z" transform="translate(-51.09 -184.42)" style="fill: #6ebca6"/>
                        <path d="M393.39,631.88a9.2,9.2,0,0,1-2.7-.4l-55.13-16.86a2.24,2.24,0,0,1,0-4.28l20.59-6.24a11.79,11.79,0,0,1,6.81,0l54.41,16.65a2.23,2.23,0,0,1,0,4.27l-21.3,6.46A9.12,9.12,0,0,1,393.39,631.88Zm-33.84-27.17a10.45,10.45,0,0,0-3.08.46l-20.58,6.24a1.12,1.12,0,0,0,0,2.14L391,630.41a8.11,8.11,0,0,0,4.73,0l21.3-6.45a1.13,1.13,0,0,0,0-2.15l-54.42-16.64A10.45,10.45,0,0,0,359.55,604.71Z" transform="translate(-51.09 -184.42)" style="fill: #538e7d"/>
                      </g>
                    </g>
                    <polygon points="379.75 439.08 379.66 453.07 343.75 463.95 343.84 449.97 379.75 439.08" style="fill: #6ebca5"/>
                  </g>
                  <g>
                    <path d="M406.5,627.33c-10.66,3.44-25.88-.13-45.24-6-18.74-5.69-20.73-11.89-13.45-13.37,3.88-.79,15.54-2.45,36.68,3.79C402.31,617,417.18,623.89,406.5,627.33Z" transform="translate(-51.09 -184.42)" style="fill: #fff"/>
                    <path d="M397,629.09c-9.32,0-21.09-2.9-35.83-7.38-11.22-3.41-17.83-7.48-17.66-10.89.07-1.58,1.58-2.75,4.24-3.29,5.31-1.08,17.17-2,36.89,3.8,7.77,2.3,25.84,8.17,25.9,13.07,0,1.4-1.25,2.48-3.88,3.33h0A31.32,31.32,0,0,1,397,629.09ZM356.8,607.56a44.72,44.72,0,0,0-8.91.79c-2.22.45-3.53,1.37-3.58,2.51-.1,2.21,4.31,6.17,17.08,10,20.23,6.15,34.82,9.3,45,6l.13.39-.13-.39c1.52-.49,3.32-1.33,3.3-2.53,0-3-9.73-7.67-25.3-12.27C372.3,608.57,363.21,607.56,356.8,607.56Z" transform="translate(-51.09 -184.42)" style="fill: #528d7d"/>
                  </g>
                  <path d="M352.54,614.65l2.21.76,15.38-4.14a.2.2,0,0,0,0-.39l-1.94-.64s-6.18.06-8.19-.8c0,0-1.29,0-1.26,1a24.94,24.94,0,0,0,6.22.64l-12.42,3.24A.17.17,0,0,0,352.54,614.65Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                  <path d="M381.51,614.06c-5-1.67-11.27-.92-16.45.75s-3.2,2.71-.79,3.67a24,24,0,0,0,6.56,1.13,36.47,36.47,0,0,0,10.09-1.7C383.67,617,386.53,615.73,381.51,614.06Zm-9.92,4.53c-3,.27-6.74-.47-6.53-1.35.34-1.4,4.21-2,7.55-2.75s8.2,0,8.42.92C381.35,616.84,374.55,618.32,371.59,618.59Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                  <path d="M396.18,618.59c-5-1.67-11.28-.92-16.45.76s-3.2,2.7-.8,3.66a24,24,0,0,0,6.57,1.14,36.55,36.55,0,0,0,10.09-1.71C398.34,621.54,401.2,620.26,396.18,618.59Zm-9.93,4.53c-3,.27-6.74-.47-6.52-1.35.33-1.4,4.2-2,7.55-2.75s8.2,0,8.41.92C396,621.38,389.22,622.85,386.25,623.12Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="262.29 498.74 262.19 512.73 189.92 490.78 190.01 476.79 262.29 498.74" style="fill: #538d7e"/>
                      <polygon points="298.19 487.86 262.29 498.74 190.01 476.79 225.92 465.91 298.19 487.86" style="fill: #a4d6ca"/>
                    </g>
                    <polygon points="298.19 487.86 298.1 501.84 262.19 512.73 262.29 498.74 298.19 487.86" style="fill: #6ebca5"/>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="262.38 484.38 262.29 498.37 190.01 476.42 190.11 462.44 262.38 484.38" style="fill: #538d7e"/>
                        <polygon points="298.29 473.5 262.38 484.38 190.11 462.44 226.01 451.55 298.29 473.5" style="fill: #b3e5d8"/>
                        <g>
                          <path d="M335.75,658.91l-21.3,6.45a8.72,8.72,0,0,1-5.06,0L254.26,648.5a1.68,1.68,0,0,1,0-3.21l20.59-6.24a11.17,11.17,0,0,1,6.48,0l54.42,16.64A1.69,1.69,0,0,1,335.75,658.91Z" transform="translate(-51.09 -184.42)" style="fill: #6ebca6"/>
                          <path d="M311.92,666.3a9.53,9.53,0,0,1-2.69-.4L254.1,649a2.24,2.24,0,0,1,0-4.28l20.58-6.24a11.62,11.62,0,0,1,6.81,0l54.42,16.64a2.24,2.24,0,0,1,0,4.28l-21.3,6.46A9.47,9.47,0,0,1,311.92,666.3Zm-33.83-27.17a10.73,10.73,0,0,0-3.08.45l-20.58,6.24a1.12,1.12,0,0,0,0,2.15l55.14,16.86a8.11,8.11,0,0,0,4.73,0l21.29-6.46a1.12,1.12,0,0,0,0-2.14l-54.41-16.64A10.5,10.5,0,0,0,278.09,639.13Z" transform="translate(-51.09 -184.42)" style="fill: #538e7d"/>
                        </g>
                      </g>
                      <polygon points="298.29 473.5 298.19 487.48 262.29 498.37 262.38 484.38 298.29 473.5" style="fill: #6ebca5"/>
                    </g>
                    <g>
                      <path d="M325,661.75c-10.67,3.44-25.88-.14-45.24-6-18.74-5.7-20.73-11.9-13.46-13.37,3.89-.79,15.54-2.46,36.69,3.79C320.84,651.41,335.72,658.31,325,661.75Z" transform="translate(-51.09 -184.42)" style="fill: #fff"/>
                      <path d="M315.5,663.5c-9.31,0-21.08-2.89-35.82-7.37-11.22-3.41-17.83-7.48-17.67-10.9.08-1.58,1.59-2.74,4.25-3.28,5.31-1.09,17.17-2,36.89,3.8,7.76,2.29,25.84,8.16,25.9,13.07,0,1.39-1.25,2.48-3.88,3.33h0A31.35,31.35,0,0,1,315.5,663.5ZM275.34,642a45.49,45.49,0,0,0-8.91.79c-2.22.46-3.53,1.37-3.58,2.51-.11,2.22,4.3,6.18,17.07,10.06,20.24,6.15,34.83,9.3,45,6l.13.4-.13-.4c1.51-.49,3.32-1.32,3.3-2.52,0-3-9.73-7.68-25.3-12.28C290.83,643,281.75,642,275.34,642Z" transform="translate(-51.09 -184.42)" style="fill: #528d7d"/>
                    </g>
                    <path d="M271.08,649.07l2.21.75,15.38-4.13a.2.2,0,0,0,0-.39l-1.94-.64s-6.18.05-8.2-.81c0,0-1.28,0-1.25,1a24.94,24.94,0,0,0,6.22.64l-12.42,3.24A.17.17,0,0,0,271.08,649.07Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                    <path d="M300.05,648.47c-5-1.67-11.27-.91-16.45.76s-3.2,2.7-.8,3.67a24.48,24.48,0,0,0,6.57,1.13,36.55,36.55,0,0,0,10.09-1.71C302.21,651.42,305.07,650.15,300.05,648.47ZM290.12,653c-3,.26-6.74-.47-6.52-1.35.33-1.41,4.2-2,7.55-2.76s8.2,0,8.41.92C299.88,651.26,293.09,652.74,290.12,653Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                    <path d="M314.72,653c-5-1.68-11.28-.92-16.46.75s-3.2,2.71-.79,3.67a24.48,24.48,0,0,0,6.57,1.13,36.49,36.49,0,0,0,10.08-1.71C316.87,656,319.73,654.68,314.72,653Zm-9.93,4.53c-3,.27-6.74-.47-6.53-1.35.34-1.4,4.21-2,7.56-2.75s8.2-.06,8.41.91C314.55,655.79,307.76,657.27,304.79,657.54Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="272.12 189.88 267.45 203.06 206.36 158.64 211.03 145.46 272.12 189.88" style="fill: #538d7e"/>
                      <polygon points="309.61 191.36 272.12 189.88 211.03 145.46 248.52 146.94 309.61 191.36" style="fill: #b3e5d8"/>
                      <g>
                        <path d="M347.5,372.24l-22.24-.88a8.63,8.63,0,0,1-4.77-1.65l-46.57-34a1.68,1.68,0,0,1,1.06-3l21.49.85a11.24,11.24,0,0,1,6.12,2.12l46,33.56A1.67,1.67,0,0,1,347.5,372.24Z" transform="translate(-51.09 -184.42)" style="fill: #6ebca6"/>
                        <path d="M322.57,371.42a9.24,9.24,0,0,1-2.41-1.26l-46.57-34a2.24,2.24,0,0,1,1.41-4l21.49.85a11.74,11.74,0,0,1,6.43,2.23l46,33.56a2.23,2.23,0,0,1-1.4,4l-22.24-.88A9.21,9.21,0,0,1,322.57,371.42ZM299.5,334.66a10.49,10.49,0,0,0-3.06-.57L275,333.24a1.12,1.12,0,0,0-.7,2l46.56,34a8.2,8.2,0,0,0,4.47,1.55l22.24.88a1.1,1.1,0,0,0,1.1-.76,1.07,1.07,0,0,0-.4-1.27l-46-33.56A10.84,10.84,0,0,0,299.5,334.66Z" transform="translate(-51.09 -184.42)" style="fill: #538e7d"/>
                      </g>
                    </g>
                    <polygon points="309.61 191.36 304.94 204.54 267.45 203.06 272.12 189.88 309.61 191.36" style="fill: #6ebca5"/>
                  </g>
                  <g>
                    <path d="M336.45,371.42c-11.2-.24-24.4-8.61-40.77-20.51-15.84-11.52-15.69-18-8.33-17.05,3.93.53,15.49,2.78,33.42,15.61C335.87,360.28,347.67,371.67,336.45,371.42Z" transform="translate(-51.09 -184.42)" style="fill: #fff"/>
                    <path d="M326.87,370c-8.8-3.05-19-9.64-31.43-18.7-9.49-6.9-14.39-12.91-13.12-16.09.58-1.46,2.39-2.07,5.09-1.71,5.37.72,16.88,3.71,33.6,15.68,6.59,4.71,21.74,16.18,20.19,20.84-.44,1.32-2,1.93-4.76,1.87h0A31.55,31.55,0,0,1,326.87,370ZM296,336.45a44.71,44.71,0,0,0-8.67-2.17c-2.25-.3-3.78.13-4.21,1.2-.82,2,2.05,7.24,12.84,15.09C313,363,325.78,370.77,336.46,371v0c1.59,0,3.57-.16,3.94-1.3.94-2.82-6.67-10.44-19.88-19.89C310.28,342.48,302,338.55,296,336.45Z" transform="translate(-51.09 -184.42)" style="fill: #528d7d"/>
                  </g>
                  <path d="M289.63,341.76l1.84,1.44,15.88,1.13a.21.21,0,0,0,.14-.37l-1.62-1.24s-5.86-2-7.48-3.44c0,0-1.23-.41-1.52.54a25.22,25.22,0,0,0,5.66,2.65l-12.79-1A.17.17,0,0,0,289.63,341.76Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                  <path d="M317.19,350.69c-4.19-3.22-10.35-4.56-15.79-4.68s-3.91,1.51-1.95,3.21a24.44,24.44,0,0,0,5.83,3.22,36.67,36.67,0,0,0,10.09,1.69C318.27,354.18,321.38,353.91,317.19,350.69Zm-10.86,1c-2.89-.72-6.21-2.65-5.73-3.42.78-1.21,4.63-.5,8-.12s7.77,2.64,7.65,3.62C316.12,353.27,309.22,352.44,306.33,351.72Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                  <path d="M329.56,359.78c-4.19-3.23-10.35-4.56-15.79-4.68s-3.91,1.51-1.95,3.21a24.8,24.8,0,0,0,5.83,3.22,36.67,36.67,0,0,0,10.09,1.69C330.64,363.27,333.76,363,329.56,359.78Zm-10.86,1c-2.89-.72-6.21-2.66-5.72-3.42.77-1.21,4.62-.5,8-.12s7.77,2.63,7.65,3.62C328.49,362.35,321.59,361.52,318.7,360.81Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="284.15 60.16 297.08 65.49 249.65 124.27 236.71 118.94 284.15 60.16" style="fill: #538d7e"/>
                      <polygon points="287.51 22.79 284.15 60.16 236.71 118.94 240.08 81.57 287.51 22.79" style="fill: #b3e5d8"/>
                      <g>
                        <path d="M334.41,220.22l-2,22.16a8.76,8.76,0,0,1-1.9,4.69l-36.29,44.8a1.68,1.68,0,0,1-3-1.21l1.93-21.42a11.16,11.16,0,0,1,2.43-6L331.43,219A1.68,1.68,0,0,1,334.41,220.22Z" transform="translate(-51.09 -184.42)" style="fill: #6ebca6"/>
                        <path d="M332.33,245.07a9.3,9.3,0,0,1-1.38,2.35l-36.29,44.8a2.24,2.24,0,0,1-4-1.61l1.93-21.42a11.61,11.61,0,0,1,2.55-6.31L331,218.66a2.21,2.21,0,0,1,2.58-.67,2.19,2.19,0,0,1,1.38,2.28l-2,22.16A9.11,9.11,0,0,1,332.33,245.07Zm-37.87,21.19a10.73,10.73,0,0,0-.72,3l-1.93,21.42a1.08,1.08,0,0,0,.69,1.14,1.11,1.11,0,0,0,1.3-.33l36.29-44.8a8.14,8.14,0,0,0,1.77-4.39l2-22.16a1.12,1.12,0,0,0-2-.81l-35.82,44.22A10.42,10.42,0,0,0,294.46,266.26Z" transform="translate(-51.09 -184.42)" style="fill: #538e7d"/>
                      </g>
                    </g>
                    <polygon points="287.51 22.79 300.44 28.12 297.08 65.49 284.15 60.16 287.51 22.79" style="fill: #6ebca5"/>
                  </g>
                  <g>
                    <path d="M333,231.21c-.8,11.18-9.82,23.94-22.53,39.68-12.3,15.25-18.8,14.77-17.44,7.47.72-3.9,3.55-15.32,17.26-32.59C321.88,231.23,333.84,220,333,231.21Z" transform="translate(-51.09 -184.42)" style="fill: #fff"/>
                    <path d="M331.09,240.71c-3.49,8.63-10.59,18.46-20.26,30.45-7.37,9.13-13.62,13.72-16.73,12.29-1.43-.66-2-2.49-1.45-5.17,1-5.33,4.55-16.67,17.34-32.77,5-6.34,17.25-20.9,21.83-19.11,1.3.5,1.83,2.09,1.63,4.84h0A31.81,31.81,0,0,1,331.09,240.71Zm-35,29.17a45.17,45.17,0,0,0-2.6,8.56c-.41,2.22-.06,3.78,1,4.25,2,.93,7.34-1.67,15.72-12.06,13.29-16.46,21.68-28.8,22.45-39.45l.41,0-.41,0c.11-1.59,0-3.57-1.11-4-2.77-1.08-10.76,6.15-20.86,18.86C302.82,255.89,298.48,263.93,296.07,269.88Z" transform="translate(-51.09 -184.42)" style="fill: #528d7d"/>
                  </g>
                  <path d="M301.05,276.48l1.53-1.76,1.93-15.81a.21.21,0,0,0-.36-.16l-1.31,1.56s-2.27,5.76-3.82,7.3c0,0-.47,1.2.47,1.55a25.32,25.32,0,0,0,2.92-5.53l-1.65,12.72A.16.16,0,0,0,301.05,276.48Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                  <path d="M311.36,249.4c-3.43,4-5.07,10.11-5.46,15.54s1.3,4,3.1,2.11a24,24,0,0,0,3.51-5.66,36.75,36.75,0,0,0,2.2-10C314.9,248.51,314.79,245.38,311.36,249.4Zm.48,10.9c-.86,2.86-3,6.08-3.69,5.55-1.18-.84-.28-4.65.28-8s3-7.63,4-7.46C313.88,250.6,312.7,257.45,311.84,260.3Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                  <path d="M321.06,237.5c-3.43,4-5.08,10.11-5.47,15.54s1.31,4,3.11,2.11a24.38,24.38,0,0,0,3.51-5.66,36.38,36.38,0,0,0,2.19-10C324.6,236.61,324.49,233.48,321.06,237.5Zm.48,10.9c-.86,2.86-3,6.08-3.7,5.55-1.17-.84-.27-4.65.28-8s3-7.63,4-7.46C323.58,238.7,322.4,245.55,321.54,248.4Z" transform="translate(-51.09 -184.42)" style="fill: #538d7f"/>
                </g>
                <g>
                  <g>
                    <path d="M389.45,708.19c0,1.82-5.66,3.3-12.65,3.3s-12.65-1.48-12.65-3.3v-6.61h25.3Z" transform="translate(-51.09 -184.42)" style="fill: #fde400"/>
                    <ellipse cx="325.71" cy="517.16" rx="12.65" ry="3.31" style="fill: #fff498"/>
                  </g>
                  <g>
                    <path d="M389.45,701.1c0,1.82-5.66,3.3-12.65,3.3s-12.65-1.48-12.65-3.3v-6.61h25.3Z" transform="translate(-51.09 -184.42)" style="fill: #fde400"/>
                    <ellipse cx="325.71" cy="510.07" rx="12.65" ry="3.31" style="fill: #fff498"/>
                  </g>
                  <g>
                    <path d="M389.45,694.07c0,1.82-5.66,3.3-12.65,3.3s-12.65-1.48-12.65-3.3v-6.62h25.3Z" transform="translate(-51.09 -184.42)" style="fill: #fde400"/>
                    <ellipse cx="325.71" cy="503.04" rx="12.65" ry="3.31" style="fill: #fff498"/>
                  </g>
                  <g>
                    <path d="M389.45,687.05c0,1.82-5.66,3.3-12.65,3.3s-12.65-1.48-12.65-3.3v-6.62h25.3Z" transform="translate(-51.09 -184.42)" style="fill: #fde400"/>
                    <ellipse cx="325.71" cy="496.02" rx="12.65" ry="3.31" style="fill: #fff498"/>
                  </g>
                </g>
                <path d="M398.31,636.57" transform="translate(-51.09 -184.42)" style="fill: #abdf3b"/>
                <g>
                  <polygon points="98.2 467.26 130.18 455.33 130.26 428.61 98.06 438.6 98.2 467.26" style="fill: #b7b7b7"/>
                  <polygon points="116.67 455.3 148.75 443.02 148.75 424.73 116.63 436.64 116.67 455.3" style="fill: #999"/>
                  <polygon points="148.75 424.73 116.63 436.64 92.53 424.65 124.59 412.89 148.75 424.73" style="fill: #afafaf"/>
                  <polygon points="92.51 399.35 98.47 402.02 130.68 390.42 124.92 387.81 92.51 399.35" style="fill: #afafaf"/>
                  <polygon points="98.63 427.67 130.62 415.74 130.68 390.42 98.5 401.95 98.63 427.67" style="fill: #ccc"/>
                  <g>
                    <path d="M158.74,601.15a7.66,7.66,0,0,1,0-8.21,3.59,3.59,0,0,1,2.89-1.71H159a3.58,3.58,0,0,0-2.89,1.72,7.66,7.66,0,0,0,0,8.21,3.58,3.58,0,0,0,2.85,1.69h2.68A3.53,3.53,0,0,1,158.74,601.15Z" transform="translate(-51.09 -184.42)" style="fill: #969696"/>
                    <ellipse cx="161.61" cy="597.04" rx="5.81" ry="4.06" transform="translate(-487.23 571.57) rotate(-89.74)" style="fill: #bababa"/>
                  </g>
                  <g>
                    <path d="M173.18,595.79a7.66,7.66,0,0,1,0-8.21,3.58,3.58,0,0,1,2.9-1.72h-2.69a3.57,3.57,0,0,0-2.89,1.71,7.66,7.66,0,0,0,0,8.21,3.55,3.55,0,0,0,2.85,1.69H176A3.54,3.54,0,0,1,173.18,595.79Z" transform="translate(-51.09 -184.42)" style="fill: #969696"/>
                    <ellipse cx="176.06" cy="591.67" rx="5.81" ry="4.06" transform="translate(-467.48 580.66) rotate(-89.74)" style="fill: #bababa"/>
                  </g>
                  <path d="M167.73,621.06,151,612.7a2.23,2.23,0,0,1-1.25-2l-.12-24.33-6-2.67v27.19h0l.08,38.42,5.66,2.36,0-17.71c0-1.57.41-3.74,2.18-3l16.25,8.76Z" transform="translate(-51.09 -184.42)" style="fill: #969696"/>
                </g>
                <g>
                  <polygon points="174.72 436.18 174.64 473.49 140.69 486.25 140.77 447.83 174.72 436.18" style="fill: #ccc"/>
                  <path d="M191.86,632.25l-.08,38.42L186.12,668l0-16.87a4.45,4.45,0,0,0-2.18-3.85l-15.69-7.36,0-18.73Z" transform="translate(-51.09 -184.42)" style="fill: #969696"/>
                  <polygon points="174.72 436.18 140.77 447.83 117.23 436.86 149.19 424.94 174.72 436.18" style="fill: #afafaf"/>
                </g>
                <g>
                  <path d="M136.59,646.75l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18V419.88a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07l-.06.12-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49V646.19c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27l.36,0c.26,0,.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.16.13-.06.28-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #184654"/>
                  <path d="M134.84,418.38c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,134.84,418.38Z" transform="translate(-51.09 -184.42)" style="fill: #184654"/>
                </g>
                <g>
                  <path d="M216.54,561.6a8.05,8.05,0,0,1-7.16-4.76c-2.42-4.94-7.45-13.29-13-9s-4.64,12.65-3.53,16.31,8,12,11.59,13,12.26-.91,12.26-.91l0-2.27a6,6,0,0,1-4.59-6.61c.38-5.59,4.5-3.47,4.5-3.47Z" transform="translate(-51.09 -184.42)" style="fill: #f0b042"/>
                  <path d="M216.56,563.9s-3.83-2.31-4.5,3.47a5.64,5.64,0,0,0,4.59,6.61Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                  <path d="M217.15,576.36c-5.28-.93-7.85-4.49-7.06-9.77.35-2.37,1.28-4,2.75-4.75a5.41,5.41,0,0,1,4.49,0l-1,2a3.22,3.22,0,0,0-2.5,0c-.81.43-1.34,1.48-1.59,3.1-.61,4.09,1.16,6.53,5.24,7.24Z" transform="translate(-51.09 -184.42)" style="fill: #ffe0b3"/>
                  <path d="M231.7,572.35s-1.67-2.57-6.81-1.61l-6.43.16L218.08,559a9.29,9.29,0,0,1,7.38-2.65c4.81.31,6.76,3.18,7.27,5.44S231.7,572.35,231.7,572.35Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                  <path d="M216.28,560.25l.14,16.09s5.09,3,8.49-3l-.06-8.08S218.12,565.7,216.28,560.25Z" transform="translate(-51.09 -184.42)" style="fill: #254553"/>
                  <rect x="231.66" y="561.77" width="2.23" height="10.57" transform="matrix(1, -0.01, 0.01, 1, -55.93, -182.41)" style="fill: #254553"/>
                  <path d="M222.32,565.55a6.85,6.85,0,0,1-4.19-1.16,5,5,0,0,1-1.85-4.14,4.38,4.38,0,0,1,2.52-3.93,11,11,0,0,1,5-1.13c4.82,0,10,2.25,10,6.57l-2.23,0c0-2.77-4-4.39-7.75-4.36a8.67,8.67,0,0,0-4,.86c-1.37.75-1.36,1.66-1.36,2a2.8,2.8,0,0,0,1,2.38c1.4,1.08,4.14.68,5.07.43l.3,2.24A10.7,10.7,0,0,1,222.32,565.55Z" transform="translate(-51.09 -184.42)" style="fill: #3b6a7a"/>
                </g>
                <polygon points="157.95 446.07 176.24 439.31 176.02 413.32 157.74 420.6 157.95 446.07" style="fill: #3b6a7a"/>
                <polygon points="173.08 435.87 161.24 429.74 161.19 423.85 172.94 419.17 173.08 435.87" style="fill: #162d33"/>
                <polygon points="161.32 440.22 161.24 429.74 173.08 435.87 161.32 440.22" style="fill: #508b9b"/>
                <polygon points="151.67 417.64 157.74 420.6 176.02 413.32 170.28 410.55 151.67 417.64" style="fill: #508b9b"/>
                <polygon points="151.73 425.25 141.98 420.33 159.19 413.46 161.39 413.94 151.67 417.64 151.73 425.25" style="fill: #3b6a7a"/>
                <path d="M193.07,604.74a14.83,14.83,0,0,0-.72-2.83l16.4-6.28a4.6,4.6,0,0,1,1.53,2.25Z" transform="translate(-51.09 -184.42)" style="fill: #508b9b"/>
                <path d="M209,630.48,208.83,605l-6.07-3,.06,7.61-9.75-4.93s-.89-8.13-7.85-9.69-7.13,8-7,10.52,2.39,10,11.05,14.29S209,630.48,209,630.48Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                <polygon points="136.68 454.73 154.97 447.98 154.75 421.99 136.47 429.26 136.68 454.73" style="fill: #3b6a7a"/>
                <polygon points="151.81 444.54 139.97 438.41 139.91 432.51 151.67 427.83 151.81 444.54" style="fill: #162d33"/>
                <polygon points="140.06 448.88 139.97 438.41 151.81 444.54 140.06 448.88" style="fill: #508b9b"/>
                <polygon points="130.4 426.31 136.47 429.26 154.75 421.99 149.01 419.22 130.4 426.31" style="fill: #508b9b"/>
                <polygon points="130.46 433.92 120.71 428.99 137.91 422.13 140.12 422.6 130.4 426.31 130.46 433.92" style="fill: #3b6a7a"/>
                <path d="M171.8,613.41a15,15,0,0,0-.72-2.84l16.4-6.27a4.6,4.6,0,0,1,1.53,2.25Z" transform="translate(-51.09 -184.42)" style="fill: #508b9b"/>
                <polygon points="106.02 412.32 106.08 418.78 121.42 427.17 121.36 419.51 106.02 412.32" style="fill: #254553"/>
                <polygon points="121.36 419.51 157.7 406.5 157.75 413.12 121.42 427.17 121.36 419.51" style="fill: #3b6a7a"/>
                <polygon points="105.31 412.07 110.33 369.28 126.75 375.9 121.36 419.51 105.31 412.07" style="fill: #d87d19"/>
                <polygon points="126.75 375.9 154.27 366.35 159.12 406.07 121.36 419.51 126.75 375.9" style="fill: #ffc973"/>
                <polygon points="110.33 369.28 139.79 359.73 154.27 366.35 126.75 375.9 110.33 369.28" style="fill: #e89f36"/>
                <path d="M167.5,550.68" transform="translate(-51.09 -184.42)" style="fill: #323232"/>
                <path d="M172,549.44l0,2.55s.77,3.17,10.17,3.09,10.44-3.27,10.44-3.27l0-4.12Z" transform="translate(-51.09 -184.42)" style="fill: #f2ca8b"/>
                <path d="M165.1,537.57l.06,6.8s1.26,7.21,17,7,16.36-5.56,16.35-7.33l-.15-17.5-32.77.28Z" transform="translate(-51.09 -184.42)" style="fill: #f7e1b9"/>
                <path d="M189.74,546.43a6.17,6.17,0,0,1-3.51-1.15.58.58,0,1,1,.69-.93c.14.1,3.39,2.42,6-.68a.57.57,0,0,1,.88.74A5.17,5.17,0,0,1,189.74,546.43Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                <path d="M187.77,639.15l-.21-25.47-6.07-2.95.06,7.61-9.75-4.93s-.89-8.13-7.85-9.69c-6-1.35-7,5.37-7,9h0l0,1.76c0,1.61.91,7.3,8.85,12.68a13.25,13.25,0,0,0,2.23,1.4l.15.07a7.4,7.4,0,0,0,.8.4C177.5,633.22,187.77,639.15,187.77,639.15Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                <g>
                  <path d="M190,456a10.58,10.58,0,0,0,.81-1.05,7.25,7.25,0,0,0,.46-.67,8.63,8.63,0,0,0,.53-1.17c.07-.19.17-.38.23-.57a6.24,6.24,0,0,0,.26-1.75v-8.4a6.18,6.18,0,0,1-.26,1.75c0,.19-.16.39-.23.58a8.63,8.63,0,0,1-.53,1.17c-.13.22-.3.44-.46.66a9.62,9.62,0,0,1-.81,1.06c-.2.23-.43.46-.67.69s-.65.61-1,.92-.92.72-1.43,1.08c-.35.25-.7.5-1.09.75-.58.37-1.23.72-1.88,1.08-.42.23-.82.46-1.27.68-.78.39-1.64.76-2.51,1.13-.43.18-.81.36-1.26.54-1.36.54-2.81,1.06-4.39,1.57-1.76.56-3.75,1-5.66,1.47-1.47.37-2.86.79-4.41,1.12-1.88.38-3.89.66-5.86,1-1.62.27-3.19.58-4.87.8-2,.26-4.2.41-6.31.6-1.71.16-3.38.37-5.11.47-2.23.14-4.49.16-6.74.22-1.69.05-3.36.15-5,.16-2.28,0-4.54-.12-6.81-.19-1.67-.06-3.35-.05-5-.15-2.35-.14-4.63-.4-6.93-.62-1.5-.15-3-.23-4.52-.42-2.56-.31-5-.76-7.4-1.19-1.12-.2-2.31-.33-3.39-.55-3.44-.7-6.71-1.52-9.75-2.45-12.37-3.81-18.57-8.84-18.57-13.88v8.4c0,5,6.2,10.06,18.57,13.87,3,.94,6.31,1.75,9.75,2.45,1.08.22,2.27.36,3.38.56,2.43.42,4.85.87,7.4,1.19,1.47.18,3,.27,4.52.41,2.3.23,4.58.49,6.94.63,1.65.09,3.32.09,5,.15,2.27.07,4.54.18,6.82.18,1.69,0,3.36-.1,5.05-.15,2.25-.06,4.51-.08,6.73-.22,1.74-.11,3.41-.32,5.12-.47,2.12-.19,4.25-.34,6.31-.61,1.68-.21,3.24-.53,4.88-.79,2-.32,4-.6,5.84-1,1.56-.32,2.95-.74,4.43-1.12s3.37-.76,4.89-1.23l.76-.24c1.57-.5,3-1,4.39-1.56.45-.18.83-.37,1.25-.55.88-.37,1.74-.74,2.52-1.12.45-.23.84-.46,1.26-.68.66-.36,1.31-.72,1.88-1.08.4-.25.74-.5,1.1-.75.51-.36,1-.72,1.43-1.09.14-.11.32-.22.45-.34s.36-.38.56-.57S189.76,456.23,190,456Z" transform="translate(-51.09 -184.42)" style="fill: #d33d24"/>
                  <path d="M173.71,428.51c24.42,7.52,24.75,19.86.73,27.53s-63.38,7.77-87.81.25-24.8-19.86-.73-27.53S149.24,421,173.71,428.51Z" transform="translate(-51.09 -184.42)" style="fill: #f47458"/>
                </g>
                <path d="M181.45,574.1a8.06,8.06,0,0,1-7.16-4.75c-2.41-5-7.45-13.29-13-9.05s-4.64,12.65-3.53,16.31,7.94,12,11.58,13,12.26-.9,12.26-.9l0-2.28a6,6,0,0,1-4.59-6.6c.39-5.6,4.5-3.48,4.5-3.48Z" transform="translate(-51.09 -184.42)" style="fill: #f0b042"/>
                <path d="M181.47,576.4s-3.83-2.31-4.5,3.48a5.63,5.63,0,0,0,4.59,6.6Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                <path d="M182.06,588.86c-5.27-.92-7.85-4.49-7.06-9.77.35-2.36,1.28-4,2.75-4.75a5.41,5.41,0,0,1,4.49,0l-1,2a3.16,3.16,0,0,0-2.5,0c-.81.43-1.34,1.48-1.58,3.1-.61,4.09,1.15,6.53,5.24,7.25Z" transform="translate(-51.09 -184.42)" style="fill: #ffe0b3"/>
                <path d="M196.62,584.85s-1.68-2.57-6.82-1.61l-6.43.16L183,571.48a9.28,9.28,0,0,1,7.37-2.65c4.81.31,6.77,3.18,7.27,5.44S196.62,584.85,196.62,584.85Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                <path d="M181.19,572.75l.14,16.09s5.09,3,8.5-3l-.07-8.08S183,578.2,181.19,572.75Z" transform="translate(-51.09 -184.42)" style="fill: #254553"/>
                <rect x="196.57" y="574.27" width="2.23" height="10.57" transform="matrix(1, -0.01, 0.01, 1, -56.04, -182.71)" style="fill: #254553"/>
                <path d="M187.24,578.05a6.86,6.86,0,0,1-4.2-1.16,5,5,0,0,1-1.85-4.14,4.39,4.39,0,0,1,2.52-3.92,10.91,10.91,0,0,1,5-1.14c4.82,0,10,2.25,10,6.57l-2.23,0c0-2.77-4-4.39-7.76-4.36a8.7,8.7,0,0,0-4,.86c-1.37.76-1.36,1.66-1.36,2a2.84,2.84,0,0,0,1,2.38c1.4,1.07,4.14.67,5.07.42l.3,2.24A10.67,10.67,0,0,1,187.24,578.05Z" transform="translate(-51.09 -184.42)" style="fill: #3b6a7a"/>
                <path d="M202.9,629" transform="translate(-51.09 -184.42)"/>
                <path d="M198.39,531.1s-4.8,4.46-13.41,3.1-4.23-.66-4.23,4.8-2.53,8.61-1.55,9.8,4.85,2.88,14.27,1a35,35,0,0,1-20.49.47c-11.36-3.15-7-19.62-7-19.62l10-8.92S198.88,520.9,198.39,531.1Z" transform="translate(-51.09 -184.42)" style="fill: #e8b674"/>
                <path d="M198.38,530.39s-7.43,4.35-17.54.92c-7-2.38-2.76,3.3-2.51,5.84.32,3.14-1.26,3-1.26,3s.13,2.27-1.5,2.73c-1.13.33-2.23.5-2.89-1.21s-.7-3.34-.7-6.09c0-2.23-1.73-.52-1.83,2.53-.14,4.09.33,10.48-1.18,10.69-2.62.35-5.11-3.71-5.75-9.55s-.93-16,.58-19.36c1.62-3.58,3-10.06,18.67-10.15,5.78,0,10.36-2.79,14.21.52C201.87,514.7,201.7,526.6,198.38,530.39Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                <g>
                  <path d="M238.63,577.8h0v-4.22a.84.84,0,0,1,0,.27l0,.09a1.51,1.51,0,0,1-.09.18l-.06.1a1.83,1.83,0,0,1-.13.16l-.1.11-.16.14-.21.16-.17.12-.29.16-.19.1-.38.18-.2.08-.67.24c-.26.08-.57.15-.86.22l-.67.17c-.29.06-.6.11-.9.15l-.74.13c-.31,0-.64.06-1,.09l-.78.07c-.34,0-.69,0-1,0s-.51,0-.77,0-.69,0-1,0l-.76,0-1.06-.1c-.23,0-.47,0-.69-.06-.39-.05-.76-.12-1.13-.19l-.52-.08c-.52-.11-1-.23-1.48-.37-1.89-.58-2.84-1.35-2.84-2.12v1.35h0v3h0v4.22c0,.77.95,1.53,2.84,2.11a14.5,14.5,0,0,0,1.48.38l.52.08c.37.07.74.14,1.13.19l.69.06c.35,0,.7.07,1.06.09s.51,0,.76,0,.69,0,1,0l.77,0c.34,0,.69,0,1,0l.78-.07,1-.1c.26,0,.5-.08.75-.12s.6-.09.89-.15.45-.11.67-.17.52-.12.75-.19l.12,0,.67-.24.19-.08.38-.18.19-.1.29-.17.17-.11a1.84,1.84,0,0,0,.22-.17l.06,0,.09-.08.1-.11a1.83,1.83,0,0,0,.13-.16.86.86,0,0,1,.07-.1l.08-.18,0-.09a.84.84,0,0,0,0-.27v-3h0Z" transform="translate(-51.09 -184.42)" style="fill: #538d7e"/>
                  <path d="M242.42,552.51H216V576h0c0,1.07,1.31,2.14,4,3a36.58,36.58,0,0,0,18.67-.05c2.53-.81,3.78-1.86,3.79-2.9h0Z" transform="translate(-51.09 -184.42)" style="fill: #6ebca5"/>
                  <path d="M238.48,549.49c5.19,1.61,5.26,4.23.15,5.86a36.58,36.58,0,0,1-18.67,0c-5.21-1.6-5.28-4.22-.16-5.85A36.61,36.61,0,0,1,238.48,549.49Z" transform="translate(-51.09 -184.42)" style="fill: #b3e5d8"/>
                  <path d="M236.21,550.45c3.94.93,4,2.45.12,3.4a35.9,35.9,0,0,1-14.16,0c-3.94-.93-4-2.45-.11-3.4A36,36,0,0,1,236.21,550.45Z" transform="translate(-51.09 -184.42)" style="fill: #538d7e"/>
                </g>
                <g>
                  <g>
                    <g>
                      <path d="M491,662.13c0,4.41-13.7,8-30.58,8s-30.57-3.61-30.57-8l0-16,61.14.06Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="409.34" cy="461.7" rx="30.57" ry="7.99" style="fill: #d69034"/>
                      <ellipse cx="409.34" cy="461.05" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M444.39,668.94l1.45.22V652.28h-1.45Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M475,669.11l1.45-.22V652.41H475Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M489,644.46c0,4.41-13.7,8-30.58,8s-30.57-3.61-30.56-8v-16l61.15.06Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="407.32" cy="444.03" rx="30.57" ry="7.99" style="fill: #d69034"/>
                      <ellipse cx="407.32" cy="443.38" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M442.38,651.27l1.44.22V634.61h-1.44Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M473,651.44l1.44-.22V634.74H473Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M491,627c0,4.41-13.7,8-30.58,8s-30.57-3.6-30.57-8l0-16L491,611Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="409.34" cy="426.58" rx="30.57" ry="7.99" style="fill: #d69034"/>
                      <ellipse cx="409.34" cy="425.92" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M444.39,633.82l1.45.22V617.16h-1.45Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M475,634l1.45-.22V617.28H475Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M489,609.33c0,4.41-13.7,8-30.58,8s-30.57-3.6-30.56-8v-16l61.15.06Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="407.32" cy="408.91" rx="30.57" ry="7.99" style="fill: #d69034"/>
                      <ellipse cx="407.32" cy="408.25" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M442.38,616.15l1.44.22V599.49h-1.44Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M473,616.31l1.44-.22V599.61H473Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M491,591.94c0,4.41-13.7,8-30.58,8s-30.57-3.6-30.57-8l0-16L491,576Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="409.34" cy="391.52" rx="30.57" ry="7.99" style="fill: #d69034"/>
                      <ellipse cx="409.34" cy="390.86" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M444.39,598.76l1.45.22V582.1h-1.45Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M475,598.92l1.45-.22V582.22H475Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M489,574.27c0,4.41-13.7,8-30.58,8s-30.57-3.6-30.56-8v-16l61.15,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="407.32" cy="373.85" rx="30.57" ry="7.99" style="fill: #d69034"/>
                      <ellipse cx="407.32" cy="373.19" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M442.38,581.09l1.44.22V564.43h-1.44Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M473,581.25l1.44-.22V564.55H473Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M490.53,504.61c1.34,4.2-10.63,11.75-26.73,16.85s-30.22,5.83-31.55,1.62l-4.83-15.23,58.29-18.47Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="456.56" cy="498.61" rx="30.57" ry="7.99" transform="translate(-180.4 -23.18) rotate(-17.58)" style="fill: #d69034"/>
                      <ellipse cx="456.37" cy="497.99" rx="27.74" ry="6.59" transform="translate(-180.22 -23.27) rotate(-17.58)" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M448.2,525.22c.47-.07.95-.14,1.44-.23l-5.11-16.08-1.38.43Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M477.42,516.1l1.31-.65-5-15.7-1.38.44Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M490,278.3c1.83,4-9.14,12.95-24.5,19.95s-29.31,9.42-31.14,5.41l-6.62-14.54,55.64-25.36Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="455.54" cy="276.44" rx="30.57" ry="7.99" transform="translate(-124.71 29.38) rotate(-24.5)" style="fill: #d69034"/>
                      <ellipse cx="455.27" cy="275.84" rx="27.74" ry="6.59" transform="translate(-124.49 29.21) rotate(-24.5)" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M450.44,303.86c.46-.12.93-.26,1.4-.4l-7-15.35-1.32.6Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M478.34,291.29l1.23-.8-6.85-15-1.31.6Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M482.14,433.64c-2.06,3.9-15.83.66-30.76-7.23S426,409,428.09,405.06l7.47-14.12,54.05,28.57Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="462.58" cy="405.22" rx="7.99" ry="30.57" transform="translate(-162.96 440.35) rotate(-62.13)" style="fill: #d69034"/>
                      <ellipse cx="462.89" cy="404.65" rx="6.59" ry="27.74" transform="translate(-162.28 440.31) rotate(-62.13)" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M437.75,417.93l1.18.87,7.88-14.93-1.28-.68Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M464.75,432.35l1.38.49,7.69-14.58-1.28-.68Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M480.4,232c-2.36,3.73-15.84-.58-30.1-9.61S426.39,203,428.75,199.26l8.55-13.5L489,218.48Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="463.13" cy="202.12" rx="7.99" ry="30.57" transform="translate(-6.57 300.74) rotate(-57.64)" style="fill: #d69034"/>
                      <ellipse cx="463.48" cy="201.57" rx="6.59" ry="27.74" transform="translate(-5.94 300.78) rotate(-57.64)" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M437.38,212.84c.36.32.72.64,1.1,1l9-14.27-1.22-.77Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M463.16,229.34l1.34.59L473.31,216l-1.22-.78Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M465.18,725.72c0,4.41-13.69,8-30.57,8s-30.57-3.61-30.57-8l0-16,61.14.06Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="383.54" cy="525.3" rx="30.57" ry="7.99" style="fill: #d69034"/>
                    <ellipse cx="383.54" cy="524.64" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M418.59,732.53l1.45.23V715.87h-1.45Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M449.2,732.7l1.45-.22V716H449.2Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M463.17,708.05c0,4.41-13.7,8-30.58,8S402,712.4,402,708V692l61.14.06Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="381.52" cy="507.63" rx="30.57" ry="7.99" style="fill: #d69034"/>
                    <ellipse cx="381.52" cy="506.97" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M416.58,714.87l1.44.22V698.2h-1.44Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M447.19,715l1.44-.22V698.33h-1.44Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M465.18,690.59c0,4.42-13.69,8-30.57,8S404,695,404,690.54l0-16,61.14.06Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="383.54" cy="490.17" rx="30.57" ry="7.99" style="fill: #d69034"/>
                    <ellipse cx="383.54" cy="489.51" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M418.59,697.41l1.45.22V680.75h-1.45Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M449.2,697.57l1.45-.22V680.87H449.2Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M463.17,672.92c0,4.42-13.7,8-30.58,8s-30.57-3.6-30.56-8v-16l61.14.06Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="381.52" cy="472.5" rx="30.57" ry="7.99" style="fill: #d69034"/>
                    <ellipse cx="381.52" cy="471.84" rx="27.74" ry="6.59" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M416.58,679.74,418,680V663.08h-1.44Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M447.19,679.9l1.44-.22V663.2h-1.44Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M391.42,709.79c0,2.16-6.7,3.9-14.95,3.89s-15-1.76-14.94-3.92V702l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="325.39" cy="517.55" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="325.39" cy="517.23" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <path d="M372.26,713.58s8.56-6.88,19.16-8.43v5.32S387.18,714.64,372.26,713.58Z" transform="translate(-51.09 -184.42)" style="fill: #e5a338"/>
                  <g>
                    <path d="M368.64,713.13l.71.1V705h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M383.6,713.2l.71-.1V705h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M390.43,701.16c0,2.15-6.69,3.89-14.95,3.89s-14.94-1.77-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="324.4" cy="508.91" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="324.4" cy="508.59" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M367.65,704.49l.71.11v-8.26h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M382.62,704.57l.7-.11V696.4h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M391.42,692.62c0,2.16-6.7,3.9-14.95,3.89s-15-1.76-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="325.39" cy="500.38" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="325.39" cy="500.06" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M368.64,696l.71.11v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M383.6,696l.71-.1v-8.06h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M390.43,684c0,2.16-6.69,3.9-14.95,3.9s-14.94-1.77-14.94-3.92v-7.82l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="324.4" cy="491.74" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="324.4" cy="491.42" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M367.65,687.32l.71.1v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M382.62,687.4l.7-.11v-8.06h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M391.42,675.48c0,2.16-6.7,3.9-14.95,3.89s-15-1.76-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="325.39" cy="483.24" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="325.39" cy="482.92" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M368.64,678.81l.71.11v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M383.6,678.89l.71-.1v-8.06h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M390.43,667.26c0,2.16-6.69,3.9-14.95,3.89s-14.94-1.76-14.94-3.91v-7.82l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="324.4" cy="475.02" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="324.4" cy="474.7" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M367.65,670.6l.71.1v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M382.62,670.67l.7-.1v-8.06h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M391.42,658.76c0,2.16-6.7,3.9-14.95,3.89s-15-1.76-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="325.39" cy="466.52" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="325.39" cy="466.2" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M368.64,662.09l.71.11V654h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M383.6,662.17l.71-.1V654h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path d="M128.57,664.36c0,2.16-6.7,3.9-14.95,3.89s-14.94-1.76-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="62.54" cy="472.12" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="62.54" cy="471.8" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M105.79,667.69l.71.11v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M120.76,667.77l.7-.11v-8h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M127.58,655.72c0,2.16-6.69,3.9-14.94,3.89s-15-1.76-15-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="61.56" cy="463.48" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="61.56" cy="463.16" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M104.81,659.05l.7.11v-8.25h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M119.77,659.13l.71-.1V651h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M128.57,647.19c0,2.15-6.7,3.9-14.95,3.89s-14.94-1.76-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="62.54" cy="454.94" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="62.54" cy="454.62" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M105.79,650.52l.71.11v-8.26h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M120.76,650.6l.7-.11v-8h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M127.58,638.55c0,2.16-6.69,3.9-14.94,3.89s-15-1.76-15-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="61.56" cy="446.31" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="61.56" cy="445.99" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M104.81,641.88l.7.11v-8.25h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M119.77,642l.71-.11V633.8h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M128.57,630.05c0,2.15-6.7,3.9-14.95,3.89S98.68,632.18,98.68,630v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="62.54" cy="437.8" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="62.54" cy="437.48" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M105.79,633.38l.71.11v-8.26h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M120.76,633.46l.7-.11V625.3h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M127.58,621.83c0,2.16-6.69,3.9-14.94,3.89s-15-1.76-15-3.92V614l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="61.56" cy="429.59" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="61.56" cy="429.27" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M104.81,625.16l.7.11V617h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M119.77,625.24l.71-.11v-8h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M128.57,613.33c0,2.15-6.7,3.9-14.95,3.89s-14.94-1.76-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="62.54" cy="421.08" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="62.54" cy="420.76" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M105.79,616.66l.71.11v-8.26h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M120.76,616.74l.7-.11v-8h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path d="M199.87,709.79c0,2.16-6.7,3.9-15,3.89S170,711.92,170,709.76V702l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="133.84" cy="517.55" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="133.84" cy="517.23" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M177.09,713.13l.71.1V705h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M192.06,713.2l.7-.1V705h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M198.88,701.16c0,2.15-6.69,3.89-14.95,3.89S169,703.28,169,701.13v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="132.85" cy="508.91" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="132.85" cy="508.59" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M176.11,704.49l.7.11v-8.26h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M191.07,704.57l.71-.11V696.4h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M199.87,692.62c0,2.16-6.7,3.9-15,3.89S170,694.75,170,692.59v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="133.84" cy="500.38" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="133.84" cy="500.06" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M177.09,696l.71.11v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M192.06,696l.7-.1v-8.06h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M198.88,684c0,2.16-6.69,3.9-14.95,3.9S169,686.11,169,684v-7.82l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="132.85" cy="491.74" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="132.85" cy="491.42" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M176.11,687.32l.7.1v-8.25h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M191.07,687.4l.71-.11v-8.06h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M199.87,675.48c0,2.16-6.7,3.9-15,3.89S170,677.61,170,675.45v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="133.84" cy="483.24" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="133.84" cy="482.92" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M177.09,678.81l.71.11v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M192.06,678.89l.7-.1v-8.06h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M198.88,667.26c0,2.16-6.69,3.9-14.95,3.89S169,669.39,169,667.24v-7.82l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="132.85" cy="475.02" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="132.85" cy="474.7" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M176.11,670.6l.7.1v-8.25h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M191.07,670.67l.71-.1v-8.06h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path d="M155.78,698.84c0,2.15-6.7,3.9-14.95,3.89s-15-1.77-14.94-3.92V691l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="89.75" cy="506.59" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="89.75" cy="506.27" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                      <path d="M147.48,696c5.31-1.26,7.09-.63,8.3-.28v3.11c0,2.16-6.7,3.9-14.95,3.89-.81,0-1.61,0-2.38-.05A14.51,14.51,0,0,1,147.48,696Z" transform="translate(-51.09 -184.42)" style="fill: #e5a338"/>
                    </g>
                    <g>
                      <path d="M133,702.17l.71.11V694H133Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M148,702.25l.71-.11v-8.06H148Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M156.76,690.33c0,2.16-6.69,3.9-14.95,3.9s-14.94-1.77-14.94-3.92V682.5l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="90.73" cy="498.09" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="90.73" cy="497.77" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M134,693.67l.7.1v-8.25H134Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M149,693.75l.71-.11v-8.06H149Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M155.78,682.12c0,2.15-6.7,3.89-14.95,3.89s-15-1.77-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                      <ellipse cx="89.75" cy="489.87" rx="14.95" ry="3.91" style="fill: #d69034"/>
                      <ellipse cx="89.75" cy="489.55" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                    </g>
                    <g>
                      <path d="M133,685.45l.71.11V677.3H133Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                      <path d="M148,685.53l.71-.11v-8.06H148Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M508.7,709.12c0,2.16-6.7,3.9-14.95,3.9s-14.94-1.77-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="442.67" cy="516.88" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="442.67" cy="516.56" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M485.92,712.46l.71.1v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M500.89,712.54l.7-.11v-8.06h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M507.71,700.49c0,2.15-6.69,3.9-14.95,3.89s-14.94-1.76-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="441.68" cy="508.24" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="441.69" cy="507.92" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M484.94,703.82l.7.11v-8.26h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M499.9,703.9l.71-.11v-8.06h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M508.7,692c0,2.16-6.7,3.9-14.95,3.89s-14.94-1.76-14.94-3.91v-7.82l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="442.67" cy="499.71" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="442.67" cy="499.39" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M485.92,695.29l.71.1v-8.25h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M500.89,695.37l.7-.11V687.2h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M507.71,683.32c0,2.15-6.69,3.89-14.95,3.89s-14.94-1.77-14.94-3.92v-7.81l29.89,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="441.68" cy="491.07" rx="14.95" ry="3.91" style="fill: #d69034"/>
                    <ellipse cx="441.69" cy="490.75" rx="13.56" ry="3.22" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M484.94,686.65l.7.11V678.5h-.7Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M499.9,686.73l.71-.11v-8.06h-.71Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <path d="M183.68,541.52h-.2a3.06,3.06,0,0,1-3-2.46l-.21-1.73a2.37,2.37,0,0,1,.55-1.66,2.54,2.54,0,0,1,1.89-.78l5-.18a2.33,2.33,0,0,1,1.69.61,2.24,2.24,0,0,1,.53,1.87l0,.17c-.21,2.07-.39,3.85-2.8,4C185.45,541.51,184.16,541.52,183.68,541.52Zm-.16-.87c.07,0,1.55,0,3.55-.13,1.61-.12,1.77-1,2-3.24l0-.18a1.4,1.4,0,0,0-.28-1.17,1.43,1.43,0,0,0-1-.35l-5,.18a1.7,1.7,0,0,0-1.26.48,1.53,1.53,0,0,0-.35,1l.21,1.71c.19,1.54,2.07,1.69,2.15,1.69Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                  <path d="M193.61,540.36h0c-.07,0-1.92-.13-2.21-1.68a12.91,12.91,0,0,1-.26-1.86h0c0-.91.34-2.5,1.65-2.66a9.44,9.44,0,0,0,3.34-1,1.23,1.23,0,0,1,2.09,1l.07,1.9c0,.14.06,3.27-2.24,3.81A14.26,14.26,0,0,1,193.61,540.36ZM192,536.8a13.81,13.81,0,0,0,.24,1.72c.17.89,1.4,1,1.42,1a15.3,15.3,0,0,0,2.19-.43c1.6-.37,1.57-2.91,1.57-2.93l-.07-1.9a.66.66,0,0,0-.18-.49c-.08,0-.28,0-.6.18a10.51,10.51,0,0,1-3.68,1.09C192,535.12,192,536.68,192,536.8Zm-.44,0Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                  <path d="M180.91,536.8h-.06c-1.68-.25-4.92-1.18-5.06-1.22a.43.43,0,0,1,.24-.83s3.32,1,4.94,1.19a.43.43,0,0,1-.06.86Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                  <path d="M189.84,536.86h-.34a.44.44,0,0,1-.41-.46.42.42,0,0,1,.45-.41,6.21,6.21,0,0,0,1.89-.19.42.42,0,0,1,.56.24.43.43,0,0,1-.24.56A5.74,5.74,0,0,1,189.84,536.86Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                  <circle cx="134.24" cy="353.17" r="2.08" style="fill: #162d33"/>
                  <ellipse cx="134.32" cy="352.56" rx="0.62" ry="0.71" style="fill: #fef8f5"/>
                  <path d="M196.31,535.85c0,1-.7,2.25-1.57,2.28s-1.16-.71-1.19-1.75c-.06-1.43.73-2.29,1.6-2.32S196.27,534.8,196.31,535.85Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                  <ellipse cx="194.98" cy="535.59" rx="0.48" ry="0.63" transform="translate(-70.66 -176.88) rotate(-2.11)" style="fill: #fef8f5"/>
                </g>
                <path d="M198.4,532.73a3.23,3.23,0,0,1-.9.71.44.44,0,0,0,.19.83.45.45,0,0,0,.19,0,2.79,2.79,0,0,0,.53-.35Z" transform="translate(-51.09 -184.42)" style="fill: #162d33"/>
                <path d="M197,385.79a11.34,11.34,0,0,0-3,.41,13.62,13.62,0,0,0-12.55-8.33,14.55,14.55,0,0,0-2.39.21,13.6,13.6,0,0,0-23.76,2.45,13.62,13.62,0,0,0-17.71,13h70.4A11.61,11.61,0,0,0,197,385.79Z" transform="translate(-51.09 -184.42)" style="fill: #fff"/>
                <path d="M226.24,427.55a9.2,9.2,0,0,1,2.43.33,10.82,10.82,0,0,1,11.89-6.46,10.83,10.83,0,0,1,18.92,1.95,10.68,10.68,0,0,1,3.26-.5,10.83,10.83,0,0,1,10.83,10.83h-56A9.27,9.27,0,0,1,226.24,427.55Z" transform="translate(-51.09 -184.42)" style="fill: #fff"/>
                <g>
                  <path d="M124.3,428.1l.11-.57L107.3,424.3l-1.85.24Z" transform="translate(-51.09 -184.42)" style="fill: #d33d24"/>
                  <path d="M186.28,436.6a5.71,5.71,0,0,1-.52,2.4l-49.27-8.88-.1.57,49.1,8.85c-1.88,3.44-7.11,6.64-15.18,9.22a89.63,89.63,0,0,1-9.22,2.37l-27.23-19.78-.34.47,26.8,19.47A157.85,157.85,0,0,1,130.44,454V431.62h-.58V454a157.91,157.91,0,0,1-30-2.75l26.79-19.46-.34-.47L99.14,451.12c-3-.61-5.81-1.33-8.44-2.14-8.5-2.62-14-5.9-15.9-9.46l49-8.83-.1-.57L74.54,439a5.76,5.76,0,0,1-.51-2.3,6,6,0,0,1,1-3.2c-.34.2-.66.4-1,.61a6.43,6.43,0,0,0-.56,2.59,6.25,6.25,0,0,0,.51,2.41l-.16,0,.1.57.31-.06c2,3.77,7.57,7.21,16.32,9.9,11,3.39,25.29,5.07,39.6,5.07,14.65,0,29.3-1.77,40.36-5.29,8.31-2.66,13.68-6,15.59-9.67l.24.05.1-.57-.08,0a6.12,6.12,0,0,0-.05-5.05c-.32-.22-.66-.43-1-.64A6,6,0,0,1,186.28,436.6Z" transform="translate(-51.09 -184.42)" style="fill: #d33d24"/>
                  <path d="M154.86,424.52l-1.85-.24-17.2,3.25.11.57Z" transform="translate(-51.09 -184.42)" style="fill: #d33d24"/>
                </g>
                <g>
                  <path d="M136.59,431l.08-.12a.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18V419.88a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07l-.06.12-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13.05c-.15.06-.3.12-.47.17s-.41.11-.61.16-.31.09-.48.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v10.53c0,.54.67,1.08,2,1.49.32.1.68.19,1,.27l.36.05c.26,0,.52.1.8.13l.48,0c.25,0,.49.05.75.06l.53,0,.74,0,.54,0,.72,0,.55,0,.68-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.53-.13.08,0,.47-.16.13-.06.28-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #184654"/>
                  <path d="M134.84,418.38c2.62.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.53,18.53,0,0,1,134.84,418.38Z" transform="translate(-51.09 -184.42)" style="fill: #226777"/>
                </g>
                <path d="M193.54,511.25s6,3.82,4.29,12.88C197.83,524.13,197.45,516.06,193.54,511.25Z" transform="translate(-51.09 -184.42)" style="fill: #254553"/>
                <path d="M190.55,513.52s7,4.46,5,15C195.56,528.56,195.12,519.14,190.55,513.52Z" transform="translate(-51.09 -184.42)" style="fill: #254553"/>
                <g>
                  <g>
                    <path d="M334.9,720.65c0,2.2-6.83,4-15.24,4s-15.23-1.8-15.23-4v-8l30.47,0Z" transform="translate(-51.09 -184.42)" style="fill: #eaaf52"/>
                    <ellipse cx="268.58" cy="528.26" rx="15.23" ry="3.98" style="fill: #d69034"/>
                    <ellipse cx="268.58" cy="527.93" rx="13.82" ry="3.29" style="fill: #f8c778"/>
                  </g>
                  <g>
                    <path d="M311.68,724.05l.72.11v-8.42h-.72Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                    <path d="M326.93,724.13l.73-.11v-8.21h-.73Z" transform="translate(-51.09 -184.42)" style="fill: #d69034"/>
                  </g>
                </g>
                <g>
                  <polygon points="230.33 417.83 230.3 441.71 216.85 445.78 216.89 421.9 230.33 417.83" style="fill: #b4cfea"/>
                  <polygon points="216.89 421.9 216.85 445.78 203.32 441.68 203.35 417.8 216.89 421.9" style="fill: #79b3d3"/>
                  <polygon points="230.33 417.83 216.89 421.9 203.35 417.8 216.79 413.73 230.33 417.83" style="fill: #87b6dd"/>
                </g>
                <g>
                  <polygon points="230.33 393.72 230.3 417.6 216.85 421.67 216.89 397.8 230.33 393.72" style="fill: #b4cfea"/>
                  <polygon points="216.89 397.8 216.85 421.67 203.32 417.57 203.35 393.69 216.89 397.8" style="fill: #79b3d3"/>
                  <polygon points="230.33 393.72 216.89 397.8 203.35 393.69 216.79 389.62 230.33 393.72" style="fill: #87b6dd"/>
                </g>
                <g>
                  <polygon points="230.35 369.5 230.31 393.38 216.87 397.46 216.91 373.58 230.35 369.5" style="fill: #b7f1f4"/>
                  <polygon points="216.91 373.58 216.87 397.46 203.34 393.36 203.37 369.48 216.91 373.58" style="fill: #82e0e0"/>
                  <polygon points="230.35 369.5 216.91 373.58 203.37 369.48 216.81 365.4 230.35 369.5" style="fill: #a7fcfa"/>
                  <path d="M274.36,554.51a.94.94,0,0,0,.09-.12l.05-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1s-.1.07-.15.11l-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.11-.61.16l-.48.12-.63.11-.52.08-.68.07-.55,0-.72,0-.55,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.49,0-.79-.13-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a9.29,9.29,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.49.05c.25,0,.49,0,.74.06l.54,0,.73,0,.55,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12l.52-.13.09,0c.17,0,.32-.11.47-.17l.13,0a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -184.42)" style="fill: #82e0e0"/>
                  <path d="M272.62,549.48c2.62.81,2.66,2.14.08,3a18.46,18.46,0,0,1-9.45,0c-2.63-.81-2.66-2.14-.07-3A18.42,18.42,0,0,1,272.62,549.48Z" transform="translate(-51.09 -184.42)" style="fill: #a7fcfa"/>
                </g>
              </g>
            </g>
          </svg>

        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="section-community">
    <v-container>
      <v-row no-gutters class="row-community scroll_fade scroll_fade_fromLeft">
        <v-col cols="7">
          <svg id="img-community" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.19 513.55">
            <g style="isolation: isolate">
              <g>
                <path d="M67.93,625s-9.83-68.75,42-68.75,89.6-25.23,84.85-82.93c-3.45-41.85-10.72-100.42,78.39-101s82.71-31.17,83.26-52.78c1.79-70.14,82.16-80,105.3-73.93,52,13.72,58.14,48.53,58.14,125.1S421.4,435.36,418.37,500c-2.08,44.2,34.52,51.82,56.88,51.82C522.17,551.84,507,625,507,625Z" transform="translate(-51.09 -206.61)" style="fill: #e2efff"/>
                <g style="mix-blend-mode: multiply">
                  <path d="M415,672.11s-5.32-3.71-10.28-3.71c-5.36,0-4.14,2.81-4.14,2.81a17,17,0,0,0-4.4.38c-6.62,2.08.09,6.53.09,6.53s-3.64,1-4.08,3c-.95,4.28,6.28,5.19,6.28,5.19l36,9.77,6.28.47-6.12-9,7.68.27L446.88,676,425,669Z" transform="translate(-51.09 -206.61)" style="fill: #e5e5e5"/>
                  <path d="M129.9,625.21H76.61s1.68,5.38,17.75,12.38c12.62,5.49,31.17,5.49,31.17,5.49Z" transform="translate(-51.09 -206.61)" style="fill: #e5e5e5"/>
                  <polygon points="205.1 469.37 270.98 485.96 241.92 458.41 205.1 469.37" style="fill: #e5e5e5"/>
                  <polygon points="122.99 480.03 93.91 452.4 57.09 463.36 90.15 471.72 59.56 480.83 125.88 497.44 101.4 474.57 122.99 480.03" style="fill: #e5e5e5"/>
                  <polygon points="223.75 504.68 260.88 513.55 250.9 496.6 223.75 504.68" style="fill: #e5e5e5"/>
                  <path d="M359.39,648l29.88,3.63L372.79,633l-15.18,7.9-4.06-16.24L235,625.77l.12-.65-89.71-.5v5.56l26.76,11-17.75,5.45L235.09,667l-33-26.32,9.07-.22,91.94,21.28-31.77-22.65,90.73,19.7ZM234,631.4,234,631l.68.15Z" transform="translate(-51.09 -206.61)" style="fill: #e5e5e5"/>
                  <polygon points="360.44 434.81 408.99 441.44 366.24 419.17 360.44 434.81" style="fill: #e5e5e5"/>
                  <polygon points="424.77 436.76 415.48 439.4 430.45 450.96 424.77 436.76" style="fill: #e5e5e5"/>
                </g>
                <rect y="417.7" width="493.19" height="1.44" style="fill: #95abbc"/>
                <polygon points="177.35 496.43 105.51 478.63 140.11 465.83 177.35 496.43" style="fill: #bfbfbf"/>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 417.49 420.36 441.29 355.23 424.91 355.27 401.11 420.4 417.49" style="fill: #82e0e0"/>
                          <polygon points="452.8 409.34 420.4 417.49 355.27 401.11 387.67 392.96 452.8 409.34" style="fill: #a7fcfa"/>
                          <path d="M430.35,608.33l.11-.11.06-.07a.54.54,0,0,0,.07-.13l0-.06a.57.57,0,0,0,0-.18v-3a.63.63,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.11.11-.08.08-.13.09-.19.12-.14.08-.24.12-.17.07-.32.12-.17.06-.57.17c-.22.06-.48.1-.73.15s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66.05-.88,0-.65,0-.88,0-.65,0-.9-.06-.58,0c-.34,0-.65-.08-1-.13l-.44,0q-.68-.12-1.26-.27c-1.61-.41-2.41-.94-2.41-1.48v3c0,.54.8,1.08,2.41,1.49.39.1.81.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66-.05c.28,0,.55,0,.82-.06l.63-.09.76-.1.57-.12.64-.14.09,0,.57-.17.17-.06.32-.12.17-.07.24-.12.14-.08.19-.11s0,0,.06,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,603.33c3.16.8,3.21,2.12.09,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.79,26.79,0,0,1,428.25,603.33Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,612.43a.52.52,0,0,0,.1-.11l.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.09-.19.12-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.1-.74.15s-.37.09-.57.12-.5.08-.76.11l-.63.09-.82.06-.66.05c-.29,0-.58,0-.87,0l-.66,0-.88,0c-.22,0-.43,0-.65,0l-.9-.06-.58,0c-.33,0-.65-.08-1-.12l-.44-.06q-.67-.12-1.26-.27c-1.61-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66c.29,0,.58,0,.87,0l.66,0c.28,0,.55,0,.82-.06l.63-.09.76-.1.57-.12c.22,0,.44-.08.64-.14l.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,607.43c3.17.8,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-2.95A26.79,26.79,0,0,1,444.54,607.43Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,604.25l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1-.63.09-.82.06-.66,0-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.59,0,.89.07H438c.3,0,.59,0,.89,0h.65l.87,0,.67-.05c.27,0,.55,0,.81-.06s.42-.06.63-.09l.76-.1.58-.12.63-.13.1,0,.57-.17.16-.05.33-.13.16-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,599.24c3.17.8,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A26.79,26.79,0,0,1,444.44,599.24Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,608.35l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12s0,0,0-.06a.72.72,0,0,0,0-.19v-3a.58.58,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.18.11-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.76.1-.63.09c-.26,0-.54,0-.81.06l-.67,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.87,0,.67-.05.81-.07.64-.08.75-.11.58-.12.63-.13.1,0,.57-.17.16-.05.33-.12.16-.08.24-.11.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,603.34c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-3A26.58,26.58,0,0,1,460.73,603.34Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,616.52l.1-.11.06-.07a.83.83,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.17.06-.56.16c-.23.06-.49.11-.74.16s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66,0-.87,0-.66,0-.88,0-.65,0-.9-.06-.58,0c-.33,0-.65-.08-1-.13-.15,0-.3,0-.44-.06-.45-.07-.87-.16-1.26-.26-1.61-.41-2.41-1-2.41-1.49v3c0,.54.8,1.08,2.41,1.48.39.1.82.19,1.26.27.14,0,.3,0,.44.06.31,0,.63.09,1,.12l.58.05.9.06c.22,0,.43,0,.65,0l.88,0,.66,0c.29,0,.58,0,.87,0l.66,0,.82-.06.63-.09c.26,0,.52-.06.76-.11s.38-.08.57-.12l.64-.13.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,611.51c3.17.81,3.21,2.13.1,3a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A27,27,0,0,1,460.77,611.51Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 409.34 452.76 433.14 420.36 441.29 420.4 417.49 452.8 409.34" style="fill: #c6ffff"/>
                          <path d="M479.17,620.62a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.19.12-.14.08-.24.12-.17.07-.32.12-.16.06-.57.16c-.23.07-.49.11-.74.16s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66,0-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.89-.06l-.59-.05c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.48.39.1.82.19,1.26.27l.44.06c.32,0,.63.09,1,.12l.59,0,.89.07h.65l.89,0h.65l.87,0,.67-.05.81-.06.63-.09.76-.1.58-.12.63-.14.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.14-.08.19-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,615.61c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A27,27,0,0,1,477.06,615.61Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,612.43a.52.52,0,0,0,.1-.11l.06-.07a.75.75,0,0,0,.07-.12s0,0,0-.07a.58.58,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.09-.18.12-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17c-.23.06-.49.1-.73.15s-.38.09-.58.12-.5.08-.76.11l-.63.09-.81.06-.67.05c-.29,0-.58,0-.87,0l-.65,0-.89,0c-.21,0-.43,0-.64,0s-.6,0-.9-.06l-.59,0c-.33,0-.64-.08-1-.12-.14,0-.3,0-.44-.06-.44-.08-.87-.17-1.26-.27-1.6-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.59,0,.9.07h.64l.89,0H472c.29,0,.59,0,.87,0l.67,0c.27,0,.55,0,.81-.06l.64-.09.75-.1.58-.12c.21,0,.43-.08.63-.14l.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12a.71.71,0,0,0,.15-.08l.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,607.43c3.17.8,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-2.95A26.74,26.74,0,0,1,477,607.43Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,616.54l.11-.12.06-.07.06-.12a.15.15,0,0,0,0-.07.39.39,0,0,0,0-.18v-3a.41.41,0,0,1,0,.19s0,0,0,.06a.54.54,0,0,1-.07.13l0,.07-.11.11-.09.08-.13.1-.18.11-.14.08-.25.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.75.1-.64.09-.81.06-.67,0c-.28,0-.58,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0c-.33,0-.64-.08-1-.12l-.43-.06c-.45-.08-.87-.17-1.27-.27-1.6-.4-2.4-.94-2.4-1.48v3c0,.54.8,1.08,2.4,1.49.4.1.82.18,1.27.26l.44.06.95.13.59,0,.9.07h.64l.89,0h.65c.3,0,.59,0,.88,0l.66,0c.27,0,.55,0,.82-.06l.63-.09.76-.1.57-.12c.21-.05.44-.08.63-.13l.1,0c.21-.05.39-.11.57-.17l.16-.06.33-.12.16-.07.25-.12.14-.08.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,611.53c3.16.8,3.2,2.13.09,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-3A26.79,26.79,0,0,1,493.26,611.53Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 393.2 420.36 417 355.23 400.63 355.27 376.82 420.4 393.2" style="fill: #82e0e0"/>
                          <polygon points="452.8 385.05 420.4 393.2 355.27 376.82 387.67 368.67 452.8 385.05" style="fill: #a7fcfa"/>
                          <path d="M430.35,584a.57.57,0,0,0,.11-.11l.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.11.11-.08.08-.13.09-.19.12-.14.08-.24.12-.17.07-.32.12-.17.06-.57.17c-.22.06-.48.1-.73.15s-.37.09-.57.12-.51.08-.76.11l-.63.08-.82.07-.66.05c-.29,0-.58,0-.88,0l-.65,0-.88,0-.65,0-.9-.06-.58,0c-.34,0-.65-.08-1-.12l-.44-.06q-.68-.12-1.26-.27c-1.61-.41-2.41-.94-2.41-1.48v3c0,.54.8,1.08,2.41,1.49.39.1.81.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66,0c.28,0,.55,0,.82-.06l.63-.09.76-.1.57-.12c.21-.05.44-.08.64-.14l.09,0a5.6,5.6,0,0,0,.57-.17l.17-.06.32-.12.17-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06A.6.6,0,0,0,430.35,584Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,579c3.16.8,3.21,2.13.09,3A26.79,26.79,0,0,1,417,582c-3.17-.8-3.21-2.12-.09-3A26.79,26.79,0,0,1,428.25,579Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,588.15l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.1-.74.16l-.57.11c-.24,0-.5.08-.76.11l-.63.09-.82.06-.66,0c-.29,0-.58,0-.87,0l-.66,0-.88,0h-.65l-.9-.07-.58-.05c-.33,0-.65-.08-1-.12l-.44-.06q-.67-.12-1.26-.27c-1.61-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66,0c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,583.14c3.17.8,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-3A26.79,26.79,0,0,1,444.54,583.14Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,580l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1-.63.09c-.26,0-.54,0-.82.06l-.66.05-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06l1,.13.59,0,.89.07.65,0,.89,0,.65,0,.87,0,.67-.05.81-.07c.22,0,.42-.06.63-.09l.76-.1.58-.12.63-.13.1,0,.57-.17.16-.05.33-.13.16-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,575c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.63,26.63,0,0,1,444.44,575Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,584.06l.1-.11s.05-.05.06-.08a.75.75,0,0,0,.07-.12s0,0,0-.06a.72.72,0,0,0,0-.19v-3a.58.58,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.18.11-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.81.06l-.67.05-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06c.32.05.63.1,1,.13l.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.87,0,.67,0,.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.17.16,0,.33-.12.16-.08.24-.11.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,579.05c3.17.81,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.74,26.74,0,0,1,460.73,579.05Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,592.23a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.17.06-.56.16c-.23.06-.49.11-.74.16s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66,0-.87,0-.66,0-.88,0-.65,0-.9-.06-.58-.05c-.33,0-.65-.08-1-.13-.15,0-.3,0-.44-.06-.45-.07-.87-.16-1.26-.26-1.61-.41-2.41-1-2.41-1.49v3c0,.54.8,1.08,2.41,1.48.39.1.82.19,1.26.27l.44.06c.31,0,.63.09,1,.12l.58,0,.9.07h.65l.88,0,.66,0c.29,0,.58,0,.87,0l.66-.05.82-.06.63-.09.76-.1.57-.12.64-.14.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08.19-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,587.22c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A27,27,0,0,1,460.77,587.22Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 385.05 452.76 408.86 420.36 417 420.4 393.2 452.8 385.05" style="fill: #c6ffff"/>
                          <path d="M479.17,596.33a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.57.57,0,0,0,0-.18v-3a.63.63,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.09-.19.12-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.1-.74.15s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66.05-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.89-.06l-.59,0c-.33,0-.64-.08-1-.13l-.44,0c-.44-.08-.87-.17-1.26-.27-1.6-.41-2.41-.95-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.59,0,.89.07h.65l.89,0h.65l.87,0,.67-.05c.27,0,.55,0,.81-.06l.63-.09.76-.1.58-.12.63-.14.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.14-.08.19-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,591.33c3.17.8,3.21,2.12.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,477.06,591.33Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,588.15l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12.21.21,0,0,0,0-.07.58.58,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.18.11-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.11c-.24,0-.5.08-.76.11l-.63.09-.81.06-.67,0c-.29,0-.58,0-.87,0l-.65,0-.89,0h-.64l-.9-.07-.59-.05c-.33,0-.64-.08-1-.12-.14,0-.3,0-.44-.06-.44-.08-.87-.17-1.26-.27-1.6-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.59,0,.9.07h.64l.89,0H472c.29,0,.59,0,.87,0l.67,0c.27,0,.55,0,.81-.06s.42-.06.64-.09l.75-.1.58-.12.63-.13.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12a.71.71,0,0,0,.15-.08l.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,583.14c3.17.8,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-3A26.74,26.74,0,0,1,477,583.14Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,592.25l.11-.12.06-.07.06-.12s0,0,0-.06a.46.46,0,0,0,0-.19v-3a.41.41,0,0,1,0,.19s0,0,0,.06a.54.54,0,0,1-.07.13l0,.07-.11.11-.09.08-.13.1-.18.11-.14.08-.25.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.75.1-.64.09-.81.06-.67,0c-.28,0-.58,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.43-.06c-.45-.08-.87-.16-1.27-.26-1.6-.41-2.4-1-2.4-1.49v3c0,.54.8,1.08,2.4,1.49.4.1.82.19,1.27.26l.44.06.95.13.59,0,.9.07h.64c.3,0,.59,0,.89,0h.65c.3,0,.59,0,.88,0l.66-.05c.27,0,.55,0,.82-.06l.63-.09.76-.1.57-.12c.21,0,.44-.08.63-.13l.1,0c.21,0,.39-.11.57-.17l.16-.05a2.52,2.52,0,0,0,.33-.13l.16-.07.25-.11.14-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,587.24c3.16.8,3.2,2.13.09,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-3A26.79,26.79,0,0,1,493.26,587.24Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 368.91 420.36 392.71 355.23 376.34 355.27 352.53 420.4 368.91" style="fill: #82e0e0"/>
                          <polygon points="452.8 360.76 420.4 368.91 355.27 352.53 387.67 344.38 452.8 360.76" style="fill: #a7fcfa"/>
                          <path d="M430.35,559.76l.11-.12.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07a.57.57,0,0,1-.11.11.6.6,0,0,1-.08.08l-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.57.17c-.22.06-.48.1-.73.16L427,558c-.24,0-.51.08-.76.11l-.63.09-.82.06-.66,0c-.29,0-.58,0-.88,0l-.65,0-.88,0h-.65l-.9-.07-.58-.05c-.34,0-.65-.08-1-.12l-.44-.06q-.68-.12-1.26-.27c-1.61-.4-2.41-.94-2.41-1.48v3c0,.54.8,1.08,2.41,1.49.39.1.81.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66,0c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.09,0a5.6,5.6,0,0,0,.57-.17l.17-.06.32-.12.17-.07.24-.12L430,560a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,554.75c3.16.8,3.21,2.13.09,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-3A26.79,26.79,0,0,1,428.25,554.75Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,563.86l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.1-.74.16l-.57.12-.76.1-.63.09-.82.06-.66,0-.87,0h-.66l-.88,0h-.65l-.9-.07-.58-.05c-.33,0-.65-.08-1-.12l-.44-.06c-.45-.08-.87-.16-1.26-.27-1.61-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66-.05c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.1,0,.57-.17.16-.06a1.75,1.75,0,0,0,.32-.12l.17-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,558.85c3.17.8,3.21,2.13.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-3A26.79,26.79,0,0,1,444.54,558.85Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,555.67l.1-.11s.05,0,.06-.08a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66.05-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32.05.63.1,1,.13l.59,0c.29,0,.59.06.89.07l.65,0,.89,0,.65,0,.87,0,.67,0,.81-.07.63-.08.76-.11c.2,0,.38-.08.58-.12l.63-.13.1,0,.57-.17.16,0,.33-.12.16-.08L446,556l.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,550.66c3.17.81,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.63,26.63,0,0,1,444.44,550.66Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,559.77l.1-.11s.05,0,.06-.08a.75.75,0,0,0,.07-.12s0,0,0-.06a.65.65,0,0,0,0-.19v-3a.72.72,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,0-.06.07l-.1.12-.09.07-.13.1-.18.12-.15.08-.24.11-.16.07a1.85,1.85,0,0,1-.33.12l-.16.06-.57.17-.73.16-.58.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.81.06l-.67,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.44-.06c-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06c.32.05.63.1,1,.13l.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.87,0,.67,0,.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08a1.33,1.33,0,0,0,.24-.11l.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,554.76c3.17.81,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-3A26.74,26.74,0,0,1,460.73,554.76Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,567.94a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.09-.19.12-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.1-.74.15s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66.05-.87,0-.66,0-.88,0-.65,0-.9-.06-.58,0c-.33,0-.65-.08-1-.13l-.44-.05q-.67-.12-1.26-.27c-1.61-.41-2.41-.95-2.41-1.48v3c0,.54.8,1.08,2.41,1.48.39.11.82.19,1.26.27l.44.06c.31,0,.63.09,1,.12l.58,0,.9.07h.65l.88,0h.66c.29,0,.58,0,.87,0l.66,0,.82-.06.63-.09.76-.1.57-.12.64-.14.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08.19-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,562.94c3.17.8,3.21,2.12.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,460.77,562.94Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 360.76 452.76 384.56 420.36 392.71 420.4 368.91 452.8 360.76" style="fill: #c6ffff"/>
                          <path d="M479.17,572a.52.52,0,0,0,.1-.11l.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.09-.19.12-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.1-.74.15s-.37.09-.57.12-.5.08-.76.11l-.63.08-.82.07-.66.05-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.89-.06l-.59,0c-.33,0-.64-.08-1-.12-.14,0-.3,0-.44-.06-.44-.08-.87-.17-1.26-.27-1.6-.41-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.59,0,.89.07h.65l.89,0h.65l.87,0,.67,0c.27,0,.55,0,.81-.06l.63-.09.76-.1.58-.12c.21-.05.43-.08.63-.14l.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,567c3.17.8,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-3A26.79,26.79,0,0,1,477.06,567Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,563.86l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12s0,0,0-.06a.72.72,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11L479,561l-.13.1-.18.11-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.76.1-.63.09-.81.06-.67,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59-.05c-.33,0-.64-.08-1-.12l-.44-.06c-.44-.08-.87-.16-1.26-.27-1.6-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.59,0,.9.07h.64l.89,0H472c.29,0,.59,0,.87,0l.67-.05c.27,0,.55,0,.81-.06s.42-.06.64-.09l.75-.1.58-.12.63-.13.1,0,.57-.17.16-.06a1.85,1.85,0,0,0,.33-.12l.16-.07.24-.11.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,558.85c3.17.8,3.21,2.13.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-3A26.74,26.74,0,0,1,477,558.85Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,568l.11-.12.06-.07.06-.12s0,0,0-.06a.46.46,0,0,0,0-.19v-3a.39.39,0,0,1,0,.18s0,0,0,.07a.75.75,0,0,1-.07.12l0,.07-.11.11-.09.08-.13.1-.18.11-.14.08-.25.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.75.1-.64.09c-.26,0-.54,0-.81.06l-.67.05c-.28,0-.58,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.43-.06c-.45-.08-.87-.16-1.27-.26-1.6-.41-2.4-.95-2.4-1.49v3c0,.54.8,1.08,2.4,1.49.4.1.82.19,1.27.26l.44.06.95.13.59,0,.9.07.64,0,.89,0,.65,0,.88,0,.66-.05.82-.07.63-.09.76-.1.57-.12c.21,0,.44-.08.63-.13l.1,0c.21-.05.39-.11.57-.17l.16-.05a2.52,2.52,0,0,0,.33-.13l.16-.07.25-.11.14-.08.18-.12s.05,0,.06,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,563c3.16.81,3.2,2.13.09,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.63,26.63,0,0,1,493.26,563Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 344.62 420.36 368.43 355.23 352.05 355.27 328.24 420.4 344.62" style="fill: #82e0e0"/>
                          <polygon points="452.8 336.47 420.4 344.62 355.27 328.24 387.67 320.09 452.8 336.47" style="fill: #a7fcfa"/>
                          <path d="M430.35,535.47l.11-.12.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07-.11.11-.08.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.57.17c-.22.06-.48.1-.73.16l-.57.12-.76.1-.63.09-.82.06-.66,0c-.29,0-.58,0-.88,0h-.65l-.88,0h-.65l-.9-.07-.58,0c-.34,0-.65-.08-1-.12l-.44-.06c-.45-.08-.87-.16-1.26-.27-1.61-.4-2.41-.94-2.41-1.48v3c0,.54.8,1.08,2.41,1.49.39.1.81.19,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66-.05c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.09,0a5.6,5.6,0,0,0,.57-.17l.17-.06a3,3,0,0,0,.32-.12l.17-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,530.46c3.16.8,3.21,2.13.09,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-2.95A26.79,26.79,0,0,1,428.25,530.46Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,539.57l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06c-.17.06-.36.12-.56.17s-.49.11-.74.16l-.57.12-.76.1-.63.09c-.27,0-.54,0-.82.06l-.66.05-.87,0h-.66l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.58,0,.9.07.65,0,.88,0,.66,0,.87,0,.66-.06c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.1,0,.57-.17.16,0,.32-.13.17-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,534.56c3.17.8,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.63,26.63,0,0,1,444.54,534.56Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,531.38l.1-.11s.05,0,.06-.08a.75.75,0,0,0,.07-.12l0-.06a.63.63,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.19.12-.14.08-.24.11-.17.07a3,3,0,0,1-.32.12l-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66,0-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32,0,.63.1,1,.13l.59,0c.29,0,.59.06.89.07l.65,0,.89,0,.65,0,.87,0,.67,0,.81-.07.63-.08.76-.11c.2,0,.38-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,526.37c3.17.81,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A26.79,26.79,0,0,1,444.44,526.37Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,535.48l.1-.11.06-.07.07-.13s0,0,0-.06a.65.65,0,0,0,0-.19v-3a.72.72,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,0-.06.07l-.1.12-.09.07-.13.1-.18.12-.15.08-.24.11-.16.07a3.24,3.24,0,0,1-.33.13l-.16,0-.57.17-.73.16-.58.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.81.06l-.67.06-.87,0-.65,0-.89,0-.64,0-.9-.07-.59,0-1-.13-.44-.06c-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06c.32,0,.63.1,1,.13l.59,0c.3,0,.59.05.9.06l.64,0,.89,0,.65,0,.87,0,.67-.05.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08a1.33,1.33,0,0,0,.24-.11l.15-.08.18-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,530.47c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-3A26.74,26.74,0,0,1,460.73,530.47Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,543.65a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.09-.19.12-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.1-.74.15s-.37.09-.57.12-.5.08-.76.11l-.63.08-.82.07-.66,0-.87,0-.66,0-.88,0-.65,0-.9-.06-.58,0c-.33,0-.65-.08-1-.13l-.44-.05q-.67-.12-1.26-.27c-1.61-.41-2.41-.94-2.41-1.48v3c0,.54.8,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66,0c.28,0,.55,0,.82-.06l.63-.09.76-.1.57-.12.64-.14.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,538.65c3.17.8,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-3A26.79,26.79,0,0,1,460.77,538.65Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 336.47 452.76 360.28 420.36 368.43 420.4 344.62 452.8 336.47" style="fill: #c6ffff"/>
                          <path d="M479.17,547.76l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.1-.74.16l-.57.11c-.24,0-.5.08-.76.11l-.63.09-.82.06-.66,0c-.29,0-.58,0-.87,0l-.66,0-.88,0h-.65l-.89-.07-.59-.05c-.33,0-.64-.08-1-.12-.14,0-.3,0-.44-.06-.44-.08-.87-.17-1.26-.27-1.6-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.59,0,.89.07h.65l.89,0h.65l.87,0,.67,0c.27,0,.55,0,.81-.06s.42-.06.63-.09l.76-.1.58-.12.63-.13.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,542.75c3.17.8,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-3A26.79,26.79,0,0,1,477.06,542.75Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,539.57l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12s0,0,0-.06a.72.72,0,0,0,0-.19v-3a.58.58,0,0,1,0,.18l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.18.11-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.76.1-.63.09c-.26,0-.54,0-.81.06l-.67.05-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.59,0,.9.07.64,0,.89,0,.65,0,.87,0,.67-.06c.27,0,.55,0,.81-.06s.42-.06.64-.09l.75-.1.58-.12.63-.13.1,0,.57-.17.16,0a3.24,3.24,0,0,0,.33-.13l.16-.07.24-.11.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,534.56c3.17.8,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.58,26.58,0,0,1,477,534.56Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,543.67l.11-.11.06-.08.06-.12s0,0,0-.06a.46.46,0,0,0,0-.19v-3a.39.39,0,0,1,0,.18.21.21,0,0,1,0,.07.75.75,0,0,1-.07.12l0,.07-.11.12-.09.07-.13.1L495,541l-.14.08-.25.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.75.1c-.21,0-.42.07-.64.09s-.54,0-.81.06l-.67.05c-.28,0-.58,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.43-.06c-.45-.08-.87-.16-1.27-.26-1.6-.41-2.4-.95-2.4-1.49v3c0,.54.8,1.08,2.4,1.49.4.1.82.19,1.27.26.14,0,.29,0,.44.06.31.05.62.1.95.13l.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.88,0,.66,0,.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12s.44-.08.63-.13l.1,0c.21-.05.39-.11.57-.17l.16,0,.33-.12.16-.08.25-.11L495,544l.18-.12s.05,0,.06,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,538.66c3.16.81,3.2,2.13.09,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.63,26.63,0,0,1,493.26,538.66Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 320.33 420.36 344.14 355.23 327.76 355.27 303.95 420.4 320.33" style="fill: #82e0e0"/>
                          <polygon points="452.8 312.18 420.4 320.33 355.27 303.95 387.67 295.8 452.8 312.18" style="fill: #a7fcfa"/>
                          <path d="M430.35,511.18l.11-.12.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.54.54,0,0,1-.07.13l-.06.07-.11.11-.08.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.57.17c-.22.06-.48.11-.73.16l-.57.12-.76.1-.63.09c-.27,0-.55,0-.82.06l-.66.05c-.29,0-.58,0-.88,0h-.65l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.8,1.08,2.41,1.49.39.1.81.19,1.26.26l.44.06,1,.13.58,0,.9.07.65,0,.88,0,.66,0,.87,0,.66-.06c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.09,0a5.6,5.6,0,0,0,.57-.17l.17-.05.32-.13.17-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,506.17c3.16.8,3.21,2.13.09,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.63,26.63,0,0,1,428.25,506.17Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,515.28l.1-.11.06-.08a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.17.06c-.17.06-.36.12-.56.17s-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66,0c-.29,0-.58,0-.87,0h-.66l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06l1,.13.58,0c.3,0,.6.06.9.07l.65,0,.88,0,.66,0,.87,0,.66,0,.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.1,0,.57-.17.16,0,.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,510.27c3.17.81,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.63,26.63,0,0,1,444.54,510.27Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,507.09l.1-.11s.05-.05.06-.08a.75.75,0,0,0,.07-.12l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.19.12-.14.08-.24.11-.17.07-.32.13-.16.05-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66.06-.87,0-.66,0-.88,0-.65,0-.89-.07-.59,0-1-.13-.44-.06c-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32.05.63.1,1,.13l.59,0c.29,0,.59.05.89.06l.65,0,.89,0,.65,0,.87,0,.67-.05.81-.07.63-.08.76-.11c.2,0,.38-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08.24-.11.14-.08.19-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,502.08c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,444.44,502.08Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,511.19l.1-.11L463,511l.07-.13s0,0,0-.06a.65.65,0,0,0,0-.19v-3a.72.72,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,0-.06.07l-.1.12-.09.07-.13.1-.18.12-.15.08-.24.11-.16.08-.33.12-.16,0-.57.17-.73.16c-.2,0-.38.09-.58.12l-.76.11-.63.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.07l-.59,0-1-.13c-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06c.32,0,.63.1,1,.13l.59,0c.3,0,.59,0,.9.06l.64,0,.89,0,.65,0,.87,0,.67,0,.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.07.24-.12.15-.08.18-.12.06,0a.23.23,0,0,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,506.18c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-3A26.74,26.74,0,0,1,460.73,506.18Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,519.37l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.1-.74.16l-.57.11c-.24,0-.5.08-.76.11l-.63.09-.82.06-.66,0c-.29,0-.58,0-.87,0l-.66,0-.88,0c-.22,0-.43,0-.65,0l-.9-.06-.58-.05c-.33,0-.65-.08-1-.12l-.44-.06q-.67-.12-1.26-.27c-1.61-.4-2.41-.94-2.41-1.48v3c0,.54.8,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66-.05c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,514.36c3.17.8,3.21,2.13.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-3A26.79,26.79,0,0,1,460.77,514.36Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 312.18 452.76 335.99 420.36 344.14 420.4 320.33 452.8 312.18" style="fill: #c6ffff"/>
                          <path d="M479.17,523.47l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.1-.74.16l-.57.12-.76.1-.63.09-.82.06-.66,0-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0c-.33,0-.64-.08-1-.12l-.44-.06c-.44-.08-.87-.16-1.26-.27-1.6-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.59,0,.89.07h.65l.89,0h.65l.87,0,.67-.05c.27,0,.55,0,.81-.06s.42-.06.63-.09l.76-.1.58-.12.63-.13.1,0,.57-.17.16-.06c.11,0,.22-.07.33-.12l.16-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,518.46c3.17.8,3.21,2.13.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-2.95A26.79,26.79,0,0,1,477.06,518.46Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,515.28l.1-.11s.05,0,.06-.08a.75.75,0,0,0,.07-.12s0,0,0-.06a.72.72,0,0,0,0-.19v-3a.58.58,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.18.11-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.81.06l-.67,0c-.29,0-.58,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06l1,.13.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.87,0,.67,0,.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.17.16,0,.33-.12.16-.08.24-.11.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,510.27c3.17.81,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.58,26.58,0,0,1,477,510.27Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,519.38l.11-.11.06-.08.06-.12s0,0,0-.06a.43.43,0,0,0,0-.19v-3a.39.39,0,0,1,0,.18.15.15,0,0,1,0,.07.75.75,0,0,1-.07.12.18.18,0,0,1,0,.07l-.11.12-.09.07-.13.1-.18.11-.14.09-.25.11-.16.07a2.53,2.53,0,0,1-.33.12l-.16.06-.57.17-.73.16-.58.12-.75.1c-.21,0-.42.07-.64.09s-.54,0-.81.06l-.67,0c-.28,0-.58,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.43-.06c-.45-.07-.87-.16-1.27-.26-1.6-.41-2.4-1-2.4-1.49v3c0,.54.8,1.08,2.4,1.49.4.1.82.19,1.27.26.14,0,.29,0,.44.06.31,0,.62.1.95.13l.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.88,0,.66,0,.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12s.44-.08.63-.13l.1,0,.57-.16.16-.06.33-.12.16-.08.25-.11.14-.08.18-.12s.05,0,.06,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,514.37c3.16.81,3.2,2.13.09,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-3A26.79,26.79,0,0,1,493.26,514.37Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 296.04 420.36 319.85 355.23 303.47 355.27 279.66 420.4 296.04" style="fill: #82e0e0"/>
                          <polygon points="452.8 287.89 420.4 296.04 355.27 279.66 387.67 271.51 452.8 287.89" style="fill: #a7fcfa"/>
                          <path d="M430.35,486.89l.11-.12.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.11.12-.08.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.17.06a5.6,5.6,0,0,1-.57.17c-.22.06-.48.11-.73.16l-.57.12-.76.1-.63.09c-.27,0-.55,0-.82.06l-.66.05c-.29,0-.58,0-.88,0h-.65l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.8,1.08,2.41,1.49.39.1.81.19,1.26.26.14,0,.29,0,.44.06l1,.13.58,0c.3,0,.6.06.9.07l.65,0,.88,0,.66,0,.87,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.09,0a5.6,5.6,0,0,0,.57-.17l.17-.05.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,481.88c3.16.81,3.21,2.13.09,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.63,26.63,0,0,1,428.25,481.88Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,491l.1-.11.06-.08a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.09-.24.11-.17.07-.32.12-.17.06c-.17.06-.36.12-.56.17s-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66.05-.87,0h-.66l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.31.05.63.1,1,.13l.58,0c.3,0,.6.06.9.07l.65,0,.88,0,.66,0,.87,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.1,0,.57-.16.16-.06.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,486c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,444.54,486Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,482.8l.1-.11.06-.07.07-.13,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.16.05-.57.17c-.23.06-.49.11-.74.16s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66.05-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.89-.07l-.59,0-1-.13c-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32.05.63.1,1,.13l.59.05c.29,0,.59,0,.89.06l.65,0,.89,0,.65,0,.87,0,.67-.05.81-.07.63-.08.76-.11c.2,0,.38-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.07.24-.12.14-.08.19-.12.06,0a.23.23,0,0,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,477.79c3.17.81,3.21,2.13.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,444.44,477.79Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,486.9l.1-.11.06-.07a.83.83,0,0,0,.07-.13s0,0,0-.06a.65.65,0,0,0,0-.19v-3a.72.72,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,.05-.06.08l-.1.11-.09.07-.13.1-.18.12-.15.08a1.33,1.33,0,0,1-.24.11l-.16.08-.33.12-.16.06-.57.16-.73.16c-.2,0-.38.09-.58.12l-.76.11-.63.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.07l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.07,2.41,1.48.39.1.82.19,1.26.27l.44,0c.32.05.63.1,1,.13l.59.05c.3,0,.59.05.9.06l.64,0,.89,0,.65,0,.87,0,.67-.05.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,481.89c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.74,26.74,0,0,1,460.73,481.89Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,495.08l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13L463,492a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.1-.74.16l-.57.12-.76.1-.63.09-.82.06-.66.05-.87,0h-.66l-.88,0h-.65l-.9-.07-.58-.05c-.33,0-.65-.08-1-.12l-.44-.06q-.67-.12-1.26-.27c-1.61-.4-2.41-.94-2.41-1.48v3c0,.54.8,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66-.05c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.1,0,.57-.17.16-.06.32-.12.17-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,490.07c3.17.8,3.21,2.13.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-3A26.79,26.79,0,0,1,460.77,490.07Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 287.89 452.76 311.7 420.36 319.85 420.4 296.04 452.8 287.89" style="fill: #c6ffff"/>
                          <path d="M479.17,499.18l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1-.63.09c-.26,0-.54,0-.82.06l-.66.05c-.29,0-.58,0-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.59,0,.89.07.65,0,.89,0,.65,0,.87,0,.67-.06c.27,0,.55,0,.81-.06s.42-.06.63-.09l.76-.1.58-.12.63-.13.1,0,.57-.17.16-.05.33-.13.16-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,494.17c3.17.8,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.63,26.63,0,0,1,477.06,494.17Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,491l.1-.11s.05-.05.06-.08a.75.75,0,0,0,.07-.12s0,0,0-.06a.72.72,0,0,0,0-.19v-3a.58.58,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12s0,.05-.06.07l-.1.12-.09.07-.13.1-.18.11-.15.09-.24.11-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.81.06l-.67.05-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32.05.63.1,1,.13l.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.87,0,.67-.05.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08a1.33,1.33,0,0,0,.24-.11l.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,486c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.74,26.74,0,0,1,477,486Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,495.09a1.14,1.14,0,0,1,.11-.11l.06-.08.06-.12s0,0,0-.06a.43.43,0,0,0,0-.19v-3a.46.46,0,0,1,0,.19s0,0,0,.06a.75.75,0,0,1-.07.12.18.18,0,0,1,0,.07l-.11.12-.09.07-.13.1-.18.12-.14.08-.25.11-.16.07a2.52,2.52,0,0,1-.33.13l-.16.05-.57.17-.73.16-.58.12-.75.1c-.21,0-.42.07-.64.09s-.54,0-.81.06l-.67.06-.87,0-.65,0-.89,0-.64,0-.9-.07-.59,0-1-.13-.43-.06c-.45-.07-.87-.16-1.27-.26-1.6-.41-2.4-.95-2.4-1.49v3c0,.54.8,1.08,2.4,1.49.4.1.82.19,1.27.26.14,0,.29,0,.44.06.31.05.62.1.95.13l.59,0c.3,0,.59.05.9.06l.64,0,.89,0,.65,0,.88,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12s.44-.08.63-.13l.1,0,.57-.16.16-.06.33-.12.16-.08.25-.11.14-.08.18-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,490.08c3.16.81,3.2,2.13.09,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.79,26.79,0,0,1,493.26,490.08Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 271.75 420.36 295.56 355.23 279.18 355.27 255.38 420.4 271.75" style="fill: #82e0e0"/>
                          <polygon points="452.8 263.6 420.4 271.75 355.27 255.38 387.67 247.22 452.8 263.6" style="fill: #a7fcfa"/>
                          <path d="M430.35,462.6l.11-.11.06-.08a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.11.12-.08.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.17.06a5.6,5.6,0,0,1-.57.17c-.22.06-.48.11-.73.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.55,0-.82.06l-.66.05c-.29,0-.58,0-.88,0h-.65l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.8,1.08,2.41,1.49.39.1.81.19,1.26.26.14,0,.29,0,.44.06.31,0,.63.1,1,.13l.58,0c.3,0,.6.06.9.07l.65,0,.88,0,.66,0,.87,0,.66,0,.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.09,0,.57-.16.17-.06.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,457.59c3.16.81,3.21,2.13.09,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.79,26.79,0,0,1,428.25,457.59Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,466.7l.1-.11.06-.08a.75.75,0,0,0,.07-.12l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.19.12-.14.08-.24.11-.17.07-.32.13-.17.05c-.17.06-.36.12-.56.17s-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66.05-.87,0h-.66c-.29,0-.59,0-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.07-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.31.05.63.1,1,.13l.58,0c.3,0,.6.06.9.07l.65,0,.88,0,.66,0,.87,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.1,0,.57-.16.16-.06.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,461.69c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,444.54,461.69Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,458.51l.1-.11.06-.07a.83.83,0,0,0,.07-.13l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.08-.1.11-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.16.06-.57.16c-.23.06-.49.11-.74.16s-.37.09-.57.12l-.76.11-.63.08L441,457l-.66.05-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.89-.07l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.53.81,1.07,2.41,1.48.39.1.82.19,1.26.27l.44.05c.32.05.63.1,1,.13l.59.05c.29,0,.59.05.89.06l.65,0,.89,0,.65,0,.87,0L441,460l.81-.07.63-.08.76-.11c.2,0,.38-.08.58-.12l.63-.13.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,453.5c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A26.79,26.79,0,0,1,444.44,453.5Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,462.61l.1-.11.06-.07a.83.83,0,0,0,.07-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.65.65,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,.05-.06.08l-.1.11-.09.07-.13.1-.18.12-.15.08a1.33,1.33,0,0,1-.24.11l-.16.08-.33.12-.16.06-.57.16-.73.16c-.2,0-.38.09-.58.12l-.76.11-.63.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.07l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.07,2.41,1.48.39.1.82.19,1.26.27.15,0,.3,0,.44.06.32,0,.63.09,1,.12l.59.05c.3,0,.59,0,.9.06l.64,0,.89,0,.65,0c.29,0,.59,0,.87,0l.67-.05.81-.07.64-.08c.25,0,.51-.06.75-.11s.39-.08.58-.12l.63-.13.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,457.6c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.9,26.9,0,0,1,460.73,457.6Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,470.79l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.11-.74.16l-.57.12-.76.1-.63.09c-.27,0-.54,0-.82.06l-.66.05-.87,0h-.66l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.8,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.58,0,.9.07h.65c.29,0,.59,0,.88,0h.66l.87,0,.66-.06c.28,0,.55,0,.82-.06s.42-.06.63-.09l.76-.1.57-.12.64-.13.1,0,.57-.17.16,0,.32-.13.17-.07.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,465.78c3.17.8,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A26.79,26.79,0,0,1,460.77,465.78Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 263.6 452.76 287.41 420.36 295.56 420.4 271.75 452.8 263.6" style="fill: #c6ffff"/>
                          <path d="M479.17,474.89l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1-.63.09c-.26,0-.54,0-.82.06l-.66.05-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06l1,.13.59,0c.29,0,.59.06.89.07l.65,0,.89,0,.65,0,.87,0,.67-.05.81-.07.63-.08.76-.11c.2,0,.38-.08.58-.12l.63-.13.1,0,.57-.17.16-.05.33-.12.16-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,469.88c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.63,26.63,0,0,1,477.06,469.88Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,466.7l.1-.11s.05,0,.06-.08a.75.75,0,0,0,.07-.12s0,0,0-.06a.65.65,0,0,0,0-.19v-3a.72.72,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,.05-.06.07l-.1.12-.09.07-.13.1-.18.12-.15.08-.24.11-.16.07a3.24,3.24,0,0,1-.33.13l-.16.05-.57.17-.73.16-.58.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.81.06l-.67.05-.87,0h-.65c-.3,0-.59,0-.89,0h-.64l-.9-.07-.59,0-1-.13-.44-.06c-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32.05.63.1,1,.13l.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.87,0,.67-.05.81-.07L475,468l.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08a1.33,1.33,0,0,0,.24-.11l.15-.08.18-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,461.69c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.74,26.74,0,0,1,477,461.69Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,470.8l.11-.11.06-.07.06-.13s0,0,0-.06a.43.43,0,0,0,0-.19v-3a.46.46,0,0,1,0,.19s0,0,0,.06a.75.75,0,0,1-.07.12.18.18,0,0,1,0,.07l-.11.12-.09.07-.13.1-.18.12-.14.08-.25.11-.16.08-.33.12-.16.05-.57.17-.73.16c-.19,0-.37.09-.58.12l-.75.11-.64.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.07l-.59,0-1-.13c-.14,0-.29,0-.43-.06-.45-.07-.87-.16-1.27-.26-1.6-.41-2.4-.95-2.4-1.49v3c0,.54.8,1.08,2.4,1.49.4.1.82.19,1.27.26.14,0,.29,0,.44.06.31.05.62.1.95.13l.59.05c.3,0,.59,0,.9.06l.64,0,.89,0,.65,0,.88,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12s.44-.08.63-.13l.1,0,.57-.16.16-.06.33-.12.16-.07.25-.12.14-.08.18-.12.06,0a.23.23,0,0,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,465.79c3.16.81,3.2,2.13.09,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.79,26.79,0,0,1,493.26,465.79Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 247.47 420.36 271.27 355.23 254.89 355.27 231.09 420.4 247.47" style="fill: #82e0e0"/>
                          <polygon points="452.8 239.31 420.4 247.47 355.27 231.09 387.67 222.94 452.8 239.31" style="fill: #a7fcfa"/>
                          <path d="M430.35,438.31l.11-.11.06-.08a.75.75,0,0,0,.07-.12l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.11.12-.08.07-.13.1-.19.12-.14.08-.24.11-.17.07a1.75,1.75,0,0,1-.32.12l-.17.06a5.6,5.6,0,0,1-.57.17c-.22.06-.48.11-.73.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.55,0-.82.06l-.66.05c-.29,0-.58,0-.88,0h-.65l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.07-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.8,1.08,2.41,1.49.39.1.81.19,1.26.26.14,0,.29,0,.44.06.31.05.63.1,1,.13l.58,0c.3,0,.6.06.9.07l.65,0,.88,0,.66,0,.87,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.09,0,.57-.16.17-.06.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,433.3c3.16.81,3.21,2.13.09,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.79,26.79,0,0,1,428.25,433.3Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,442.41l.1-.11.06-.07.07-.13,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.17.05c-.17.06-.36.12-.56.17s-.49.11-.74.16l-.57.12-.76.11-.63.08-.82.07-.66.05-.87,0-.66,0-.88,0-.65,0-.9-.07-.58,0-1-.13c-.15,0-.3,0-.44-.06-.45-.07-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.31,0,.63.1,1,.13l.58,0,.9.06.65,0,.88,0,.66,0,.87,0,.66,0,.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.1,0,.57-.16.16-.06.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,437.4c3.17.81,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,444.54,437.4Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,434.22l.1-.11.06-.07a.83.83,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.08-.1.11-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.16.06-.57.16c-.23.06-.49.11-.74.16s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66.05-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.89-.07l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.07,2.41,1.48.39.1.82.19,1.26.27l.44.05c.32,0,.63.1,1,.13l.59,0c.29,0,.59.05.89.06l.65,0,.89,0,.65,0,.87,0,.67,0,.81-.07.63-.08c.26,0,.52-.06.76-.11s.38-.08.58-.12l.63-.13.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,429.21c3.17.81,3.21,2.13.1,3a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A27,27,0,0,1,444.44,429.21Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,438.32a.52.52,0,0,0,.1-.11l.06-.07a.83.83,0,0,0,.07-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.65.65,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.18.12-.15.08a1.33,1.33,0,0,1-.24.11l-.16.08-.33.12-.16.06-.57.16-.73.16c-.2,0-.38.09-.58.12l-.76.11-.63.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.06l-.59-.05c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-1-2.41-1.49v3c0,.54.81,1.08,2.41,1.48.39.1.82.19,1.26.27.15,0,.3,0,.44.06.32,0,.63.09,1,.12l.59.05.9.07h.64l.89,0,.65,0c.29,0,.59,0,.87,0l.67-.05.81-.06.64-.09c.25,0,.51-.06.75-.11l.58-.11.63-.14.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12a.71.71,0,0,0,.15-.08l.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,433.31c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-3A26.9,26.9,0,0,1,460.73,433.31Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,446.5l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.17.06c-.17.06-.36.12-.56.17s-.49.11-.74.16l-.57.12-.76.1-.63.09c-.27,0-.54,0-.82.06l-.66,0c-.29,0-.58,0-.87,0h-.66l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-1-2.41-1.49v3c0,.54.8,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06l1,.13.58,0c.3,0,.6.06.9.07l.65,0,.88,0,.66,0,.87,0,.66-.05.82-.07.63-.08.76-.11.57-.12.64-.13.1,0,.57-.17.16-.05.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,441.49c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A26.63,26.63,0,0,1,460.77,441.49Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 239.31 452.76 263.12 420.36 271.27 420.4 247.47 452.8 239.31" style="fill: #c6ffff"/>
                          <path d="M479.17,450.6l.1-.11s.05-.05.06-.08a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66.05-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32,0,.63.1,1,.13l.59,0c.29,0,.59.06.89.07l.65,0,.89,0,.65,0,.87,0,.67,0,.81-.07.63-.08.76-.11c.2,0,.38-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,445.59c3.17.81,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,477.06,445.59Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,442.41l.1-.11.06-.07.07-.13s0,0,0-.06a.65.65,0,0,0,0-.19v-3a.72.72,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,.05-.06.07l-.1.12-.09.07-.13.1-.18.12-.15.08-.24.11-.16.08-.33.12-.16.05-.57.17-.73.16-.58.12-.76.11-.63.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0-.9-.07-.59,0-1-.13c-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32,0,.63.1,1,.13l.59,0c.3,0,.59.05.9.06l.64,0,.89,0,.65,0,.87,0,.67,0,.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08a1.33,1.33,0,0,0,.24-.11l.15-.08.18-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,437.4c3.17.81,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.74,26.74,0,0,1,477,437.4Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,446.51l.11-.11.06-.07.06-.13s0,0,0-.06a.43.43,0,0,0,0-.19v-3a.46.46,0,0,1,0,.19s0,0,0,.06a.75.75,0,0,1-.07.12.35.35,0,0,1,0,.08l-.11.11-.09.07-.13.1-.18.12-.14.08-.25.11-.16.08-.33.12-.16,0-.57.17-.73.16c-.19,0-.37.09-.58.12l-.75.11-.64.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.07l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.29,0-.43-.06-.45-.07-.87-.16-1.27-.26-1.6-.41-2.4-1-2.4-1.49v3c0,.53.8,1.07,2.4,1.48.4.1.82.19,1.27.27l.44.05c.31.05.62.1.95.13l.59.05c.3,0,.59.05.9.06l.64,0,.89,0,.65,0,.88,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12s.44-.08.63-.13l.1,0,.57-.17.16-.06.33-.12.16-.07.25-.12.14-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,441.5c3.16.81,3.2,2.13.09,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-3A26.79,26.79,0,0,1,493.26,441.5Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 223.18 420.36 246.98 355.23 230.6 355.27 206.8 420.4 223.18" style="fill: #82e0e0"/>
                          <polygon points="452.8 215.03 420.4 223.18 355.27 206.8 387.67 198.65 452.8 215.03" style="fill: #a7fcfa"/>
                          <path d="M430.35,414l.11-.11.06-.07.07-.13,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.11.12-.08.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.17.05a5.6,5.6,0,0,1-.57.17c-.22.06-.48.11-.73.16l-.57.12-.76.11-.63.08-.82.07-.66.05-.88,0-.65,0-.88,0-.65,0-.9-.07-.58,0-1-.13c-.15,0-.3,0-.44-.06-.45-.07-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.8,1.08,2.41,1.49.39.1.81.19,1.26.26.14,0,.29,0,.44.06.31.05.63.1,1,.13l.58.05.9.06.65,0,.88,0,.66,0,.87,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.09,0,.57-.16.17-.06.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,409c3.16.81,3.21,2.13.09,2.95A27,27,0,0,1,417,412c-3.17-.81-3.21-2.13-.09-2.95A26.79,26.79,0,0,1,428.25,409Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,418.12l.1-.11.06-.07.07-.13,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.08-.1.11-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.17.05c-.17.06-.36.12-.56.17s-.49.11-.74.16-.37.09-.57.12l-.76.11-.63.08-.82.07-.66,0-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.9-.07l-.58,0c-.33,0-.65-.08-1-.13-.15,0-.3,0-.44-.06-.45-.07-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.53.81,1.07,2.41,1.48.39.1.82.19,1.26.27l.44.05c.31.05.63.1,1,.13l.58.05.9.06.65,0,.88,0,.66,0,.87,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,413.11c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,444.54,413.11Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,409.93a.52.52,0,0,0,.1-.11l.06-.07a.83.83,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.16.06-.57.16c-.23.06-.49.11-.74.16s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66,0-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.89-.06l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.48.39.1.82.19,1.26.27.14,0,.3,0,.44.06.32,0,.63.09,1,.12l.59.05.89.07H438l.89,0,.65,0c.29,0,.58,0,.87,0l.67-.05.81-.06.63-.09c.26,0,.52-.06.76-.11s.38-.08.58-.12l.63-.13.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.14-.08.19-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,404.92c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A27,27,0,0,1,444.44,404.92Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,414a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.65.65,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.18.12-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17c-.23.06-.49.1-.73.15s-.38.09-.58.12l-.76.11-.63.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.06l-.59-.05c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.48v3c0,.54.81,1.08,2.41,1.48.39.11.82.19,1.26.27l.44.06c.32,0,.63.09,1,.12l.59,0,.9.07h.64l.89,0h.65c.29,0,.59,0,.87,0l.67,0,.81-.06.64-.09.75-.1.58-.12.63-.14.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12a.71.71,0,0,0,.15-.08l.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,409c3.17.81,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.9,26.9,0,0,1,460.73,409Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,422.21l.1-.11L463,422a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12-.17.07-.32.12-.17.06c-.17.06-.36.12-.56.17s-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66.05-.87,0h-.66l-.88,0h-.65l-.9-.07-.58,0-1-.13-.44-.06c-.45-.08-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.8,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.31.05.63.1,1,.13l.58,0c.3,0,.6.06.9.07l.65,0,.88,0,.66,0,.87,0,.66-.05.82-.07.63-.08.76-.11c.2,0,.38-.08.57-.12l.64-.13.1,0,.57-.17.16-.05.32-.12.17-.08.24-.11.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,417.2c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,460.77,417.2Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 215.03 452.76 238.83 420.36 246.98 420.4 223.18 452.8 215.03" style="fill: #c6ffff"/>
                          <path d="M479.17,426.31l.1-.11s.05-.05.06-.08a.75.75,0,0,0,.07-.12l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.19.12-.14.08-.24.11-.17.07a1.75,1.75,0,0,1-.32.12l-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1c-.21,0-.41.07-.63.09s-.54,0-.82.06l-.66.05c-.29,0-.58,0-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26.14,0,.3,0,.44.06.32.05.63.1,1,.13l.59,0c.29,0,.59.06.89.07l.65,0,.89,0,.65,0,.87,0,.67-.05.81-.07.63-.08.76-.11c.2,0,.38-.08.58-.12l.63-.13.1,0,.57-.16.16-.06.33-.12.16-.08.24-.11.14-.08.19-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,421.3c3.17.81,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A26.79,26.79,0,0,1,477.06,421.3Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,418.12l.1-.11.06-.07.07-.13s0,0,0-.06a.65.65,0,0,0,0-.19v-3a.72.72,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,0-.06.08l-.1.11-.09.07-.13.1-.18.12-.15.08-.24.11-.16.08-.33.12-.16.05-.57.17-.73.16c-.2,0-.38.09-.58.12l-.76.11-.63.08-.81.07-.67,0-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.07l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.53.81,1.07,2.41,1.48.39.1.82.19,1.26.27l.44.05c.32.05.63.1,1,.13l.59.05c.3,0,.59.05.9.06l.64,0,.89,0,.65,0,.87,0,.67-.05.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,413.11c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.74,26.74,0,0,1,477,413.11Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,422.22l.11-.11.06-.07.06-.13s0,0,0-.06a.41.41,0,0,0,0-.19v-3a.43.43,0,0,1,0,.19s0,0,0,.06a.75.75,0,0,1-.07.12.35.35,0,0,1,0,.08l-.11.11-.09.07-.13.1-.18.12-.14.08-.25.11-.16.08-.33.12-.16.06-.57.16-.73.16c-.19,0-.37.09-.58.12l-.75.11-.64.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.07l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.29,0-.43-.06-.45-.07-.87-.16-1.27-.26-1.6-.41-2.4-.95-2.4-1.49v3c0,.54.8,1.07,2.4,1.48.4.1.82.19,1.27.27l.44.05c.31,0,.62.1.95.13l.59,0c.3,0,.59.05.9.06l.64,0,.89,0,.65,0,.88,0,.66,0,.82-.07.63-.08c.25,0,.51-.06.76-.11s.38-.08.57-.12.44-.08.63-.13l.1,0,.57-.17.16-.06.33-.12.16-.07.25-.12.14-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,417.21c3.16.81,3.2,2.13.09,3a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A27,27,0,0,1,493.26,417.21Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="420.4 198.86 420.36 222.66 355.23 206.28 355.27 182.48 420.4 198.86" style="fill: #82e0e0"/>
                          <polygon points="452.8 190.71 420.4 198.86 355.27 182.48 387.67 174.33 452.8 190.71" style="fill: #a7fcfa"/>
                          <path d="M430.35,389.7a.57.57,0,0,0,.11-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07a1.14,1.14,0,0,1-.11.11l-.08.08-.13.09L430,387l-.14.08-.24.12-.17.07-.32.12-.17.06-.57.17c-.22.06-.48.1-.73.15s-.37.09-.57.12-.51.08-.76.11l-.63.08-.82.07-.66.05-.88,0-.65,0-.88,0-.65,0-.9-.06-.58-.05c-.34,0-.65-.08-1-.13l-.44-.05q-.68-.12-1.26-.27c-1.61-.41-2.41-.94-2.41-1.48v3c0,.54.8,1.08,2.41,1.49.39.1.81.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66-.05c.28,0,.55,0,.82-.06l.63-.09.76-.1.57-.12.64-.14.09,0,.57-.17.17-.06.32-.12.17-.07.24-.12L430,390l.19-.11s0,0,.06,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M428.25,384.7c3.16.8,3.21,2.12.09,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.21-2.12-.09-2.95A26.79,26.79,0,0,1,428.25,384.7Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M446.65,393.8a.52.52,0,0,0,.1-.11l.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.1-.19.11-.14.08-.24.12-.17.07-.32.12-.17.06-.56.17c-.23.06-.49.1-.74.15s-.37.09-.57.12-.5.08-.76.11l-.63.09-.82.06-.66.05c-.29,0-.58,0-.87,0l-.66,0-.88,0c-.22,0-.43,0-.65,0l-.9-.06-.58,0c-.33,0-.65-.08-1-.12l-.44-.06q-.67-.12-1.26-.27c-1.61-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.58,0,.9.07h.65l.88,0h.66l.87,0,.66-.05c.28,0,.55,0,.82-.06l.63-.09.76-.1.57-.12c.22-.05.44-.08.64-.14l.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.54,388.8c3.17.8,3.21,2.13.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.22-2.12-.1-2.95A26.79,26.79,0,0,1,444.54,388.8Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M446.55,385.62l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.19.11L446,383l-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.11-.74.16l-.57.12-.76.1-.63.09c-.26,0-.54,0-.82.06l-.66.05c-.29,0-.58,0-.87,0h-.66l-.88,0h-.65l-.89-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.59,0,.89.07H438c.3,0,.59,0,.89,0h.65l.87,0,.67-.05c.27,0,.55,0,.81-.06s.42-.06.63-.09l.76-.1.58-.12.63-.13.1,0,.57-.17.16-.05.33-.13.16-.07L446,386l.14-.08.19-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M444.44,380.61c3.17.8,3.21,2.13.1,2.95a27,27,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-3A26.79,26.79,0,0,1,444.44,380.61Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M462.84,389.72l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12s0,0,0-.06a.72.72,0,0,0,0-.19v-3a.58.58,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.18.11-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.76.1-.63.09c-.26,0-.54,0-.81.06l-.67.05-.87,0h-.65l-.89,0h-.64l-.9-.07-.59,0-1-.13-.44-.06c-.44-.08-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.49.39.1.82.19,1.26.26l.44.06,1,.13.59,0c.3,0,.59.06.9.07l.64,0,.89,0,.65,0,.87,0,.67,0,.81-.07.64-.08.75-.11c.21,0,.39-.08.58-.12l.63-.13.1,0,.57-.17.16-.05.33-.12.16-.08.24-.11.15-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.73,384.71c3.17.81,3.21,2.13.1,3a27,27,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.58,26.58,0,0,1,460.73,384.71Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M462.88,397.89l.1-.11.06-.07a.83.83,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.19.12-.14.08-.24.11-.17.08-.32.12-.17.06-.56.16c-.23.06-.49.11-.74.16s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66.05-.87,0-.66,0-.88,0-.65,0-.9-.06-.58-.05c-.33,0-.65-.08-1-.13-.15,0-.3,0-.44-.06-.45-.07-.87-.16-1.26-.26-1.61-.41-2.41-.95-2.41-1.49v3c0,.54.8,1.08,2.41,1.48.39.1.82.19,1.26.27.14,0,.3,0,.44.06.31,0,.63.09,1,.12l.58.05.9.06c.22,0,.43,0,.65,0l.88,0,.66,0c.29,0,.58,0,.87,0l.66-.05.82-.06.63-.09c.26,0,.52-.06.76-.11s.38-.08.57-.12l.64-.13.1,0,.57-.17.16-.06.32-.12.17-.07.24-.12.14-.08.19-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M460.77,392.88c3.17.81,3.21,2.13.1,2.95a26.63,26.63,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A27,27,0,0,1,460.77,392.88Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <polygon points="452.8 190.71 452.76 214.51 420.36 222.66 420.4 198.86 452.8 190.71" style="fill: #c6ffff"/>
                          <path d="M479.17,402a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.19.12-.14.08-.24.12-.17.07-.32.12-.16.06-.57.17c-.23.06-.49.1-.74.15s-.37.09-.57.12l-.76.11-.63.08-.82.07-.66,0-.87,0-.66,0-.88,0-.65,0c-.3,0-.6,0-.89-.06l-.59,0c-.33,0-.64-.08-1-.13-.14,0-.3,0-.44-.06-.44-.07-.87-.16-1.26-.26-1.6-.41-2.41-.95-2.41-1.49v3c0,.54.81,1.08,2.41,1.48.39.1.82.19,1.26.27l.44.06c.32,0,.63.09,1,.12l.59.05.89.07h.65l.89,0h.65l.87,0,.67-.05.81-.06.63-.09.76-.1.58-.12.63-.14.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12.14-.08.19-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477.06,397c3.17.81,3.21,2.13.1,3a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.22-2.13-.1-2.95A27,27,0,0,1,477.06,397Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M479.07,393.8a.52.52,0,0,0,.1-.11l.06-.07a.75.75,0,0,0,.07-.12s0-.05,0-.07a.58.58,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.1-.18.11-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17c-.23.06-.49.1-.73.15s-.38.09-.58.12-.5.08-.76.11l-.63.09-.81.06-.67.05c-.29,0-.58,0-.87,0l-.65,0-.89,0c-.21,0-.43,0-.64,0s-.6,0-.9-.06l-.59,0c-.33,0-.64-.08-1-.12-.14,0-.3,0-.44-.06-.44-.08-.87-.17-1.26-.27-1.6-.4-2.41-.94-2.41-1.48v3c0,.54.81,1.08,2.41,1.49.39.1.82.18,1.26.26l.44.06,1,.13.59,0,.9.07h.64l.89,0H472c.29,0,.59,0,.87,0l.67-.05c.27,0,.55,0,.81-.06l.64-.09.75-.1.58-.12c.21-.05.43-.08.63-.14l.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12a.71.71,0,0,0,.15-.08l.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M477,388.8c3.17.8,3.21,2.13.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-2.95A26.74,26.74,0,0,1,477,388.8Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                        <g>
                          <path d="M495.36,397.91l.11-.12.06-.07.06-.12a.15.15,0,0,0,0-.07.39.39,0,0,0,0-.18v-3a.41.41,0,0,1,0,.19s0,0,0,.06a.54.54,0,0,1-.07.13l0,.07-.11.11-.09.08-.13.1-.18.11-.14.08-.25.12-.16.07-.33.12-.16.06-.57.17-.73.16-.58.12-.75.1-.64.09-.81.06-.67.05c-.28,0-.58,0-.87,0h-.65l-.89,0h-.64l-.9-.07-.59-.05c-.33,0-.64-.08-1-.12l-.43-.06c-.45-.08-.87-.17-1.27-.27-1.6-.4-2.4-.94-2.4-1.48v3c0,.54.8,1.08,2.4,1.49.4.1.82.18,1.27.26l.44.06.95.13.59,0,.9.07h.64l.89,0h.65c.3,0,.59,0,.88,0l.66-.05c.27,0,.55,0,.82-.06l.63-.09.76-.1.57-.12c.21-.05.44-.08.63-.13l.1,0c.21-.05.39-.11.57-.17l.16-.06.33-.12.16-.07.25-.11.14-.09.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                          <path d="M493.26,392.9c3.16.8,3.2,2.13.09,3a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-2.95A26.79,26.79,0,0,1,493.26,392.9Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="264.46 432.27 264.42 456.08 200.22 439.67 200.27 415.87 264.46 432.27" style="fill: #256eba"/>
                          <polygon points="296.35 424.12 264.46 432.27 200.27 415.87 232.16 407.72 296.35 424.12" style="fill: #a4d6ff"/>
                          <path d="M275,623.09l.11-.11.05-.07a.83.83,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13s0,0-.05.07l-.11.11-.08.07-.13.1-.19.12-.13.08-.24.11-.17.08-.32.12-.16.06L273,621c-.22.06-.48.11-.72.16s-.36.09-.56.12l-.75.11-.62.08-.81.07-.65,0-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.06l-.58,0c-.32,0-.63-.08-.94-.13-.14,0-.3,0-.43-.06-.44-.07-.86-.16-1.25-.26-1.58-.41-2.37-.95-2.37-1.49v3c0,.54.79,1.08,2.37,1.48.39.1.81.19,1.25.27.13,0,.29,0,.43.06.31,0,.62.09.94.12l.58,0c.29,0,.58.05.88.06l.64,0,.87,0,.64,0c.29,0,.58,0,.86,0l.66-.05.8-.06.62-.09c.25,0,.51-.06.75-.11s.37-.08.56-.12l.63-.13.09,0,.56-.17.16-.06.32-.12.17-.07.24-.12.14-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M272.9,618.08c3.12.81,3.16,2.13.09,2.95a25.8,25.8,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-2.95A26.11,26.11,0,0,1,272.9,618.08Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M291,627.19a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.18.12-.14.08a1.33,1.33,0,0,1-.24.11l-.16.08-.32.12-.16.06-.56.16c-.22.07-.48.11-.72.16s-.37.09-.56.12l-.75.11-.62.08-.81.07-.65.05-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.06l-.58,0c-.33,0-.63-.08-.94-.13-.15,0-.3,0-.44-.06-.44-.07-.85-.16-1.24-.26-1.58-.41-2.37-.95-2.37-1.49v3c0,.54.79,1.08,2.37,1.48.39.1.81.19,1.24.27l.44.06c.31,0,.61.09.94.12l.58,0,.88.07h.64l.87,0h.64c.29,0,.58,0,.86,0l.65,0,.81-.06.62-.09.75-.1.56-.12.63-.14.09,0,.56-.17.16-.06.32-.12.16-.07.24-.12.14-.08.19-.11s0,0,.05,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M288.94,622.18c3.11.81,3.16,2.13.09,3a25.8,25.8,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-2.95A26.11,26.11,0,0,1,288.94,622.18Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M290.91,619l.11-.11.06-.07.06-.12s0-.05,0-.07a.58.58,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.05.07a1.14,1.14,0,0,1-.11.11.6.6,0,0,1-.08.08l-.13.09-.18.12-.14.08-.24.12-.17.07-.32.12-.16.06-.56.17c-.22.06-.47.1-.72.15s-.36.09-.56.12-.5.08-.75.11l-.62.09-.8.06-.66,0c-.28,0-.57,0-.86,0l-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.06l-.58-.05c-.32,0-.63-.08-.94-.12-.14,0-.3,0-.43-.06-.44-.08-.86-.17-1.25-.27-1.57-.4-2.37-.94-2.37-1.48v3c0,.54.8,1.08,2.37,1.49.39.1.81.18,1.25.26l.43.06.94.13.58,0,.88.07h.64l.87,0H284c.28,0,.57,0,.85,0l.66,0c.27,0,.54,0,.8-.06l.62-.09.75-.1.56-.12c.21-.05.43-.08.63-.14l.1,0c.2,0,.38-.11.56-.17l.16-.06.32-.12.16-.07.24-.12.14-.08.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M288.84,614c3.12.8,3.16,2.13.09,3a26,26,0,0,1-11.2,0c-3.12-.8-3.16-2.12-.09-3A26,26,0,0,1,288.84,614Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M307,623.11l.11-.12s0,0,.05-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.08.08-.13.1-.19.11-.14.08-.24.12-.16.07-.32.12-.16.06-.56.17c-.22.06-.48.1-.72.16l-.56.12-.75.1-.62.09-.81.06-.65,0c-.28,0-.57,0-.86,0l-.64,0-.87,0h-.64l-.88-.07-.58-.05c-.33,0-.63-.08-.94-.12l-.44-.06c-.43-.08-.85-.17-1.24-.27-1.58-.4-2.37-.94-2.37-1.48v3c0,.54.79,1.08,2.37,1.49.39.1.81.18,1.25.26l.43.06.94.13.58,0,.88.07h.64l.87,0H300c.29,0,.58,0,.86,0l.66-.05c.26,0,.54,0,.8-.06s.41-.06.62-.09l.75-.1.56-.12.63-.13.09,0c.2,0,.39-.11.56-.17l.16-.06.32-.12.17-.07.24-.12a.57.57,0,0,0,.13-.08,1.18,1.18,0,0,0,.19-.11l.05,0,.08-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M304.88,618.1c3.11.8,3.16,2.13.09,2.95a26,26,0,0,1-11.2,0c-3.12-.8-3.16-2.12-.09-3A26,26,0,0,1,304.88,618.1Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M307.07,631.31l.1-.12s.05,0,.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.18.11-.14.08-.24.12-.16.07-.32.12-.16.06-.56.17c-.22.06-.48.1-.72.16l-.56.12-.75.1-.62.09-.81.06-.65.05c-.28,0-.57,0-.86,0h-.64l-.87,0H298l-.88-.07-.58,0c-.33,0-.63-.08-.94-.12l-.44-.06c-.44-.08-.85-.16-1.24-.27-1.58-.4-2.37-.94-2.37-1.48v3c0,.54.79,1.08,2.37,1.49.39.1.81.19,1.24.26l.44.06.94.13.58,0,.88.07h.64l.87,0h.64c.29,0,.58,0,.86,0l.65,0c.27,0,.55,0,.81-.06s.41-.06.62-.09l.75-.1.56-.12.63-.13.09,0c.2-.05.39-.11.56-.17l.16-.06a3,3,0,0,0,.32-.12l.16-.07.24-.11.14-.08.19-.12.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M305,626.3c3.11.8,3.16,2.13.09,3a26,26,0,0,1-11.2,0c-3.12-.8-3.16-2.12-.09-2.95A26,26,0,0,1,305,626.3Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <polygon points="296.35 424.12 296.31 447.93 264.42 456.08 264.46 432.27 296.35 424.12" style="fill: #8ac2fb"/>
                          <path d="M323.11,635.41l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.18.11-.14.08-.24.12-.16.07-.32.12-.16.06c-.17.06-.36.12-.56.17l-.72.16-.57.12-.74.1-.62.09-.81.06-.65,0-.86,0h-.64l-.87,0H314l-.89-.07-.57,0-.95-.13-.43-.06c-.44-.08-.85-.16-1.24-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.8.19,1.24.26l.43.06.95.13.57,0,.89.07.64,0,.87,0,.64,0,.86,0,.65-.06.81-.06c.21,0,.41-.06.62-.09l.74-.1.57-.12c.21,0,.43-.08.62-.13l.1,0c.2,0,.39-.11.56-.17l.16,0,.32-.13.16-.07.24-.11.14-.08.18-.12s.05,0,.06,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M321,630.4c3.11.8,3.15,2.13.09,3a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.17-2.13-.1-3A25.85,25.85,0,0,1,321,630.4Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M323,627.22a1.14,1.14,0,0,1,.11-.11s0-.05.05-.08a.75.75,0,0,0,.07-.12l0-.06a.63.63,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12s0,0-.06.07l-.1.12-.08.07-.13.1-.19.12-.14.08-.24.11-.16.07a3,3,0,0,1-.32.12l-.16.06c-.17.06-.36.12-.56.17s-.48.11-.72.16l-.56.12-.75.1c-.21,0-.41.07-.62.09s-.54,0-.81.06l-.65,0c-.28,0-.57,0-.86,0h-.64l-.87,0h-.64l-.88-.07-.58,0-.94-.13-.44-.06c-.43-.07-.85-.16-1.24-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.81.19,1.25.26.13,0,.29,0,.43.06.31,0,.62.1.94.13l.58,0c.29,0,.58.06.88.07l.64,0,.87,0,.64,0,.86,0,.66-.05.8-.07.62-.08.75-.11c.2,0,.37-.08.56-.12l.63-.13.09,0,.56-.16.16-.06.32-.12.17-.08.24-.11.13-.08.19-.12s0,0,.05,0l.08-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M320.94,622.21c3.11.81,3.16,2.13.09,2.95a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-3A26,26,0,0,1,320.94,622.21Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M339.05,631.32l.1-.11.06-.07.07-.13,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.18.12-.14.08-.24.11-.16.07-.32.13-.16.05c-.17.06-.36.12-.56.17s-.48.11-.72.16l-.57.12-.74.1c-.21,0-.41.07-.62.09l-.81.06-.65.06-.86,0-.64,0-.87,0-.64,0-.88-.07-.58,0-.95-.13-.43-.06c-.44-.07-.85-.16-1.24-.26-1.58-.41-2.37-.95-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.8.19,1.24.26.14,0,.29,0,.44.06.31,0,.61.1.94.13l.58,0c.29,0,.58,0,.88.06l.64,0,.87,0,.64,0,.86,0,.65,0,.81-.07.62-.08.75-.11c.19,0,.37-.08.56-.12s.43-.08.62-.13l.1,0,.56-.16.16-.06.32-.12.16-.08a1.33,1.33,0,0,0,.24-.11l.14-.08.19-.12.05,0a.46.46,0,0,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M337,626.31c3.11.81,3.15,2.13.09,3a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-2.95A26,26,0,0,1,337,626.31Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="264.46 407.94 264.42 431.74 200.22 415.34 200.27 391.54 264.46 407.94" style="fill: #256eba"/>
                          <polygon points="296.35 399.79 264.46 407.94 200.27 391.54 232.16 383.38 296.35 399.79" style="fill: #a4d6ff"/>
                          <path d="M275,598.76l.11-.11a.35.35,0,0,0,.05-.08.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.05.07-.11.12-.08.07-.13.1a1.18,1.18,0,0,1-.19.11l-.13.08-.24.12-.17.07-.32.12-.16.06c-.17.06-.36.12-.56.17s-.48.11-.72.16l-.56.12-.75.1c-.21,0-.41.07-.62.09s-.54,0-.81.06l-.65,0c-.28,0-.57,0-.86,0h-.64l-.87,0h-.64l-.88-.07-.58,0-.94-.13L263,597c-.44-.08-.86-.16-1.25-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.81.19,1.25.26.13,0,.29,0,.43.06.31,0,.62.1.94.13l.58,0c.29,0,.58.06.88.07l.64,0,.87,0,.64,0,.86,0,.66,0,.8-.07.62-.08.75-.11c.2,0,.37-.08.56-.12l.63-.13.09,0c.2,0,.39-.11.56-.17l.16,0,.32-.12.17-.08.24-.11.14-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M272.9,593.75c3.12.81,3.16,2.13.09,3a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-3A25.8,25.8,0,0,1,272.9,593.75Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M291,602.86l.1-.11s.05,0,.06-.08a.75.75,0,0,0,.07-.12l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.18.12-.14.08-.24.11-.16.07a1.75,1.75,0,0,1-.32.12l-.16.06c-.17.06-.36.12-.56.17s-.48.11-.72.16l-.56.12-.75.1c-.21,0-.41.07-.62.09s-.54,0-.81.06l-.65,0c-.28,0-.57,0-.86,0h-.64l-.87,0h-.64l-.88-.07-.58,0-.94-.13-.44-.06c-.44-.07-.85-.16-1.24-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.81.19,1.24.26.14,0,.29,0,.44.06.31,0,.61.1.94.13l.58,0c.29,0,.58.06.88.07l.64,0,.87,0,.64,0,.86,0,.65-.05.81-.07.62-.08.75-.11c.2,0,.37-.08.56-.12l.63-.13.09,0,.56-.16.16-.06.32-.12.16-.08a1.33,1.33,0,0,0,.24-.11l.14-.08.19-.12.05,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M288.94,597.85c3.11.81,3.16,2.13.09,2.95a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-3A26,26,0,0,1,288.94,597.85Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M290.91,594.67a1.14,1.14,0,0,0,.11-.11l.06-.07.06-.13s0,0,0-.06a.65.65,0,0,0,0-.19v-3a.72.72,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12.35.35,0,0,1-.05.08l-.11.11-.08.07-.13.1-.18.12-.14.08-.24.11-.17.08-.32.12-.16.05c-.17.06-.36.12-.56.17s-.47.11-.72.16-.36.09-.56.12l-.75.11-.62.08-.8.07-.66.05-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.07l-.58,0c-.32,0-.63-.08-.94-.13-.14,0-.3,0-.43-.06-.44-.07-.86-.16-1.25-.26-1.57-.41-2.37-.95-2.37-1.49v3c0,.54.8,1.08,2.37,1.49.39.1.81.19,1.25.26.14,0,.29,0,.43.06.31.05.62.1.94.13l.58.05c.29,0,.58,0,.88.06l.64,0,.87,0,.65,0,.85,0,.66,0,.8-.07.62-.08.75-.11c.2,0,.38-.08.56-.12l.63-.13.1,0,.56-.17.16-.06.32-.12.16-.07.24-.12.14-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M288.84,589.66c3.12.81,3.16,2.13.09,3a25.8,25.8,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-2.95A26,26,0,0,1,288.84,589.66Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M307,598.77l.11-.11s0,0,.05-.07a.83.83,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,0-.06.08l-.1.11-.08.07-.13.1-.19.12-.14.08a1.33,1.33,0,0,1-.24.11l-.16.08-.32.12-.16.06-.56.16c-.22.06-.48.11-.72.16s-.37.09-.56.12l-.75.11-.62.08-.81.07-.65,0-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.07l-.58,0c-.33,0-.63-.08-.94-.13-.15,0-.3,0-.44-.06-.43-.07-.85-.16-1.24-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.07,2.37,1.48.39.1.81.19,1.25.27l.43,0c.31.05.62.1.94.13l.58.05c.29,0,.58,0,.88.06l.64,0,.87,0,.64,0,.86,0,.66,0,.8-.07.62-.08c.25,0,.51-.06.75-.11s.37-.08.56-.12l.63-.13.09,0,.56-.17.16-.06.32-.12.17-.07.24-.12.13-.08.19-.12.05,0,.08-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M304.88,593.76c3.11.81,3.16,2.13.09,3a25.8,25.8,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-3A26,26,0,0,1,304.88,593.76Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M307.07,607l.1-.11.06-.07a.83.83,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.08-.1.11-.09.07-.13.1-.18.12-.14.08a1.33,1.33,0,0,1-.24.11l-.16.08-.32.12-.16.06-.56.16c-.22.06-.48.11-.72.16s-.37.09-.56.12l-.75.11-.62.08-.81.07-.65,0-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.06l-.58-.05c-.33,0-.63-.08-.94-.13-.15,0-.3,0-.44-.06-.44-.07-.85-.16-1.24-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.07,2.37,1.48.39.1.81.19,1.24.27l.44.06c.31,0,.61.09.94.12l.58,0c.29,0,.58.05.88.06l.64,0,.87,0,.64,0c.29,0,.58,0,.86,0l.65-.05.81-.06.62-.09c.25,0,.51-.06.75-.11s.37-.08.56-.12l.63-.13.09,0,.56-.17.16-.06.32-.12.16-.07.24-.12.14-.08.19-.12.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M305,602c3.11.81,3.16,2.13.09,2.95a25.8,25.8,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-3A26.11,26.11,0,0,1,305,602Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <polygon points="296.35 399.79 296.31 423.59 264.42 431.74 264.46 407.94 296.35 399.79" style="fill: #8ac2fb"/>
                          <path d="M323.11,611.07a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06-.07.13-.06.07-.1.11-.09.07-.13.1-.18.12-.14.08a1.33,1.33,0,0,1-.24.11l-.16.08-.32.12-.16.06-.56.16c-.23.07-.48.11-.72.16s-.37.09-.57.12l-.74.11-.62.08-.81.07-.65.05-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.89-.06l-.57,0c-.33,0-.64-.08-.95-.13-.14,0-.29,0-.43-.06-.44-.07-.85-.16-1.24-.26-1.58-.41-2.37-.95-2.37-1.49v3c0,.54.79,1.08,2.37,1.48.39.1.8.19,1.24.27l.43.06c.31,0,.62.09.95.12l.57,0,.89.07h.64l.87,0,.64,0c.29,0,.58,0,.86,0l.65,0,.81-.06.62-.09.74-.1.57-.12L321,612l.1,0,.56-.17.16-.06.32-.12.16-.07.24-.12.14-.08.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M321,606.06c3.11.81,3.15,2.13.09,3a25.8,25.8,0,0,1-11.2,0c-3.12-.81-3.17-2.13-.1-2.95A26.16,26.16,0,0,1,321,606.06Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M323,602.88a1.14,1.14,0,0,0,.11-.11l.05-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.08.08-.13.09-.19.12-.14.08-.24.12-.16.07-.32.12-.16.06-.56.17c-.22.06-.48.1-.72.15s-.37.09-.56.12-.5.08-.75.11l-.62.09-.81.06-.65,0c-.28,0-.57,0-.86,0l-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.06l-.58-.05c-.33,0-.63-.08-.94-.12l-.44-.06c-.43-.08-.85-.17-1.24-.27-1.58-.41-2.37-.94-2.37-1.48v3c0,.54.79,1.08,2.37,1.49.39.1.81.18,1.25.26l.43.06.94.13.58,0,.88.07h.64l.87,0h.64c.29,0,.58,0,.86,0l.66,0c.26,0,.54,0,.8-.06l.62-.09.75-.1.56-.12c.21-.05.43-.08.63-.14l.09,0c.2,0,.39-.11.56-.17l.16-.06.32-.12.17-.07.24-.12a.57.57,0,0,0,.13-.08,1.18,1.18,0,0,0,.19-.11l.05,0,.08-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M320.94,597.88c3.11.8,3.16,2.13.09,3a26,26,0,0,1-11.2,0c-3.12-.8-3.16-2.12-.09-3A26,26,0,0,1,320.94,597.88Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M339.05,607l.1-.12.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.54.54,0,0,1-.07.13l-.06.07a.52.52,0,0,1-.1.11l-.09.08-.13.1-.18.11-.14.08-.24.12-.16.07-.32.12-.16.06-.56.17c-.22.06-.48.1-.72.16l-.57.12-.74.1-.62.09-.81.06-.65,0c-.28,0-.57,0-.86,0l-.64,0-.87,0H330l-.88-.07-.58-.05c-.33,0-.64-.08-.95-.12l-.43-.06c-.44-.08-.85-.17-1.24-.27-1.58-.4-2.37-.94-2.37-1.48v3c0,.54.79,1.08,2.37,1.49.39.1.8.18,1.24.26l.44.06.94.13.58,0,.88.07h.64l.87,0h.64c.29,0,.58,0,.86,0l.65-.05c.27,0,.55,0,.81-.06s.41-.06.62-.09l.75-.1.56-.12c.21,0,.43-.08.62-.13l.1,0c.2,0,.39-.11.56-.17l.16-.06.32-.12.16-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M337,602c3.11.8,3.15,2.13.09,2.95a26,26,0,0,1-11.2,0c-3.12-.8-3.16-2.12-.09-3A26,26,0,0,1,337,602Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="264.46 383.61 264.42 407.41 200.22 391 200.27 367.2 264.46 383.61" style="fill: #256eba"/>
                          <polygon points="296.35 375.46 264.46 383.61 200.27 367.2 232.16 359.05 296.35 375.46" style="fill: #a4d6ff"/>
                          <path d="M275,574.42l.11-.11.05-.07a.54.54,0,0,0,.07-.13l0-.06a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13s0,0-.05.07l-.11.11-.08.08-.13.09-.19.12-.13.08-.24.12L274,572l-.32.12-.16.06-.56.17c-.22.06-.48.1-.72.15s-.36.09-.56.12-.5.08-.75.11l-.62.08-.81.07-.65.05-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.06l-.58,0c-.32,0-.63-.08-.94-.13l-.43-.05c-.44-.08-.86-.17-1.25-.27-1.58-.41-2.37-.94-2.37-1.48v3c0,.54.79,1.08,2.37,1.49.39.1.81.18,1.25.26l.43.06.94.13.58,0,.88.07h.64l.87,0H268c.29,0,.58,0,.86,0l.66,0c.27,0,.54,0,.8-.06l.62-.09.75-.1.56-.12.63-.14.09,0,.56-.17.16-.06L274,575l.17-.07.24-.12.14-.08.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M272.9,569.42c3.12.8,3.16,2.12.09,3a26,26,0,0,1-11.2,0c-3.12-.81-3.16-2.12-.09-2.95A26,26,0,0,1,272.9,569.42Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M291,578.52a.52.52,0,0,0,.1-.11l.06-.07a.75.75,0,0,0,.07-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.1-.18.11-.14.08-.24.12-.16.07-.32.12-.16.06-.56.17c-.22.06-.48.1-.72.15s-.37.09-.56.12-.5.08-.75.11l-.62.09-.81.06-.65,0c-.28,0-.57,0-.86,0l-.64,0-.87,0c-.21,0-.43,0-.64,0s-.59,0-.88-.06l-.58,0c-.33,0-.63-.08-.94-.12l-.44-.06c-.44-.08-.85-.17-1.24-.27-1.58-.4-2.37-.94-2.37-1.48v3c0,.54.79,1.08,2.37,1.49.39.1.81.18,1.24.26l.44.06.94.13.58,0,.88.07h.64l.87,0h.64c.29,0,.58,0,.86,0l.65,0c.27,0,.55,0,.81-.06l.62-.09.75-.1.56-.12c.21-.05.43-.08.63-.14l.09,0c.2,0,.39-.11.56-.17l.16-.06.32-.12.16-.07.24-.12.14-.08a1.18,1.18,0,0,0,.19-.11l.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M288.94,573.52c3.11.8,3.16,2.13.09,3a26,26,0,0,1-11.2,0c-3.12-.8-3.16-2.12-.09-3A26,26,0,0,1,288.94,573.52Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M290.91,570.34l.11-.12.06-.07.06-.12s0,0,0-.06a.72.72,0,0,0,0-.19v-3a.58.58,0,0,1,0,.18l0,.06a.54.54,0,0,1-.07.13l-.05.07-.11.11-.08.08-.13.1-.18.11-.14.08-.24.12-.17.07-.32.12-.16.06-.56.17c-.22.06-.47.11-.72.16l-.56.12-.75.1-.62.09c-.26,0-.53,0-.8.06l-.66.05c-.28,0-.57,0-.86,0h-.64l-.87,0h-.64l-.88-.07-.58,0-.94-.13-.43-.06c-.44-.08-.86-.16-1.25-.26-1.57-.41-2.37-.95-2.37-1.49v3c0,.54.8,1.08,2.37,1.49.39.1.81.19,1.25.26l.43.06.94.13.58,0,.88.07h.64c.29,0,.58,0,.87,0H284c.28,0,.57,0,.85,0l.66-.05c.27,0,.54,0,.8-.06s.42-.06.62-.09l.75-.1.56-.12.63-.13.1,0c.2,0,.38-.11.56-.17l.16-.05.32-.13.16-.07.24-.11.14-.08.18-.12.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M288.84,565.33c3.12.8,3.16,2.13.09,2.95a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-3A26,26,0,0,1,288.84,565.33Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M307,574.44l.11-.12s0,0,.05-.07a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.08.07-.13.1a1.18,1.18,0,0,1-.19.11l-.14.08-.24.12L306,572l-.32.12-.16.06c-.17.06-.36.12-.56.17s-.48.11-.72.16l-.56.12-.75.1-.62.09c-.26,0-.54,0-.81.06l-.65.05c-.28,0-.57,0-.86,0h-.64l-.87,0h-.64l-.88-.07-.58,0-.94-.13-.44-.06c-.43-.08-.85-.16-1.24-.26-1.58-.41-2.37-.95-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.81.19,1.25.26.13,0,.29,0,.43.06l.94.13.58,0c.29,0,.58.06.88.07l.64,0,.87,0,.64,0,.86,0,.66,0,.8-.07.62-.08.75-.11c.2,0,.37-.08.56-.12l.63-.13.09,0c.2-.05.39-.11.56-.17l.16,0L306,575l.17-.08.24-.11.13-.08.19-.12s0,0,.05,0l.08-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M304.88,569.43c3.11.81,3.16,2.13.09,3a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-2.95A25.8,25.8,0,0,1,304.88,569.43Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M307.07,582.64l.1-.11s.05,0,.06-.08a.75.75,0,0,0,.07-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1a.78.78,0,0,1-.18.11l-.14.08-.24.12-.16.07-.32.12-.16.06c-.17.06-.36.12-.56.17s-.48.11-.72.16l-.56.12-.75.1c-.21,0-.41.07-.62.09s-.54,0-.81.06l-.65,0c-.28,0-.57,0-.86,0h-.64l-.87,0H298l-.88-.07-.58,0-.94-.13-.44-.06c-.44-.08-.85-.16-1.24-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.81.19,1.24.26.14,0,.29,0,.44.06.31.05.61.1.94.13l.58,0c.29,0,.58.06.88.07l.64,0,.87,0,.64,0,.86,0,.65,0,.81-.07.62-.08.75-.11c.2,0,.37-.08.56-.12l.63-.13.09,0c.2,0,.39-.11.56-.17l.16,0,.32-.12.16-.08.24-.11.14-.08.19-.12s0,0,.05,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M305,577.63c3.11.81,3.16,2.13.09,3a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-3A25.8,25.8,0,0,1,305,577.63Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <polygon points="296.35 375.46 296.31 399.26 264.42 407.41 264.46 383.61 296.35 375.46" style="fill: #8ac2fb"/>
                          <path d="M323.11,586.74l.1-.11.06-.08a.75.75,0,0,0,.07-.12l0-.06a.63.63,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.75.75,0,0,1-.07.12l-.06.07-.1.12-.09.07-.13.1-.18.12-.14.08-.24.11-.16.07a1.75,1.75,0,0,1-.32.12l-.16.06c-.17.06-.36.12-.56.17l-.72.16-.57.12-.74.1c-.21,0-.41.07-.62.09l-.81.06-.65,0-.86,0h-.64l-.87,0H314l-.89-.07-.57,0-.95-.13-.43-.06c-.44-.07-.85-.16-1.24-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.8.19,1.24.26.14,0,.29,0,.43.06.31,0,.62.1.95.13l.57,0c.3,0,.59.06.89.07l.64,0,.87,0,.64,0,.86,0,.65-.05.81-.07.62-.08.74-.11c.2,0,.38-.08.57-.12s.43-.08.62-.13l.1,0,.56-.16.16-.06.32-.12.16-.08a1.33,1.33,0,0,0,.24-.11l.14-.08.18-.12s.05,0,.06,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M321,581.73c3.11.81,3.15,2.13.09,2.95a26.11,26.11,0,0,1-11.2,0c-3.12-.81-3.17-2.13-.1-3A26,26,0,0,1,321,581.73Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M323,578.55l.11-.11s0-.05.05-.07l.07-.13,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12s0,.05-.06.07l-.1.12-.08.07-.13.1-.19.12-.14.08-.24.11-.16.08-.32.12-.16.05c-.17.06-.36.12-.56.17s-.48.11-.72.16-.37.09-.56.12l-.75.11-.62.08-.81.07-.65.05-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.07l-.58,0c-.33,0-.63-.08-.94-.13-.15,0-.3,0-.44-.06-.43-.07-.85-.16-1.24-.26-1.58-.41-2.37-.95-2.37-1.49v3c0,.54.79,1.08,2.37,1.49.39.1.81.19,1.25.26.13,0,.29,0,.43.06.31.05.62.1.94.13l.58.05c.29,0,.58,0,.88.06l.64,0,.87,0,.64,0,.86,0,.66,0,.8-.07.62-.08.75-.11c.2,0,.37-.08.56-.12l.63-.13.09,0,.56-.17.16-.06.32-.12.17-.07.24-.12.13-.08.19-.12.05,0,.08-.06A.6.6,0,0,0,323,578.55Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M320.94,573.54c3.11.81,3.16,2.13.09,3a25.8,25.8,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-2.95A26,26,0,0,1,320.94,573.54Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                        <g>
                          <path d="M339.05,582.65l.1-.11.06-.07a.83.83,0,0,0,.07-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.75.75,0,0,1-.07.12l-.06.08-.1.11-.09.07-.13.1-.18.12-.14.08a1.33,1.33,0,0,1-.24.11l-.16.08-.32.12-.16.06-.56.16c-.22.06-.48.11-.72.16s-.37.09-.57.12L335,581l-.62.08-.81.07-.65,0-.86,0-.64,0-.87,0-.64,0c-.3,0-.59,0-.88-.07l-.58,0c-.33,0-.64-.08-.95-.13-.14,0-.29,0-.43-.06-.44-.07-.85-.16-1.24-.26-1.58-.41-2.37-1-2.37-1.49v3c0,.54.79,1.07,2.37,1.48.39.1.8.19,1.24.27l.44,0c.31.05.61.1.94.13l.58.05c.29,0,.58,0,.88.06l.64,0,.87,0,.64,0,.86,0,.65,0,.81-.07.62-.08c.25,0,.51-.06.75-.11s.37-.08.56-.12.43-.08.62-.13l.1,0,.56-.17.16-.06.32-.12.16-.07.24-.12.14-.08.19-.12.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                          <path d="M337,577.64c3.11.81,3.15,2.13.09,3a25.8,25.8,0,0,1-11.2,0c-3.12-.81-3.16-2.13-.09-3A26,26,0,0,1,337,577.64Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon points="365.33 413.17 338.54 421.4 311.57 413.21 338.36 404.98 365.33 413.17" style="fill: #a4d6ff"/>
                        <path d="M395.89,616.26l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.53-.08-.8-.13l-.36,0c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.48v3c0,.54.66,1.08,2,1.48a9.25,9.25,0,0,0,1,.27l.37.06c.26,0,.51.09.79.12l.48.05.75.07h.53l.73,0h.54c.24,0,.49,0,.73,0l.54,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.15,611.25c2.62.81,2.65,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-3A18.52,18.52,0,0,1,394.15,611.25Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <polygon points="365.33 413.17 365.29 436.97 338.5 445.21 338.54 421.4 365.33 413.17" style="fill: #8ac2fb"/>
                        <path d="M409.37,620.36a.44.44,0,0,0,.08-.11l.05-.07a.67.67,0,0,0,.06-.12.25.25,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.76.76,0,0,1-.08.11l-.08.08-.11.09-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.6.15s-.31.09-.48.12a6.17,6.17,0,0,1-.62.11l-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.36,0q-.55-.12-1-.27c-1.32-.41-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49.33.1.68.18,1,.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.54l.72,0,.55-.05c.22,0,.45,0,.67-.06l.53-.09.62-.1.48-.12.52-.14.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.12.11-.08a.91.91,0,0,0,.16-.11l0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M407.62,615.36c2.62.8,2.66,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.62-.8-2.66-2.12-.08-3A18.41,18.41,0,0,1,407.62,615.36Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="338.54 421.4 338.5 445.21 311.53 437.02 311.57 413.21 338.54 421.4" style="fill: #256eba"/>
                        <path d="M382.5,620.44l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09-.68.06-.55.05c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06a9.25,9.25,0,0,1-1-.27c-1.33-.4-2-.94-2-1.48v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06.8.13.48,0,.75.07h.53l.73,0h.54c.24,0,.49,0,.72,0l.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06a1.11,1.11,0,0,0,.27-.12l.14-.07.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M380.76,615.43c2.62.8,2.65,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.63-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,380.76,615.43Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <path d="M396,624.54a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18s0,0,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17l-.6.16-.48.12-.63.1-.52.09c-.22,0-.45,0-.67.06l-.55,0c-.24,0-.48,0-.72,0h-.54l-.74,0h-.53l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07.54,0,.73,0,.54,0,.72,0,.55-.06c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.2-.11.12-.08.16-.12s0,0,0,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.23,619.53c2.62.81,2.66,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.3,18.3,0,0,1,394.23,619.53Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="365.33 388.93 338.54 397.16 311.57 388.97 338.36 380.74 365.33 388.93" style="fill: #a4d6ff"/>
                        <path d="M395.89,592l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11a.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.53-.08-.8-.13l-.36-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.48.32.1.67.19,1,.27l.37.06c.26,0,.51.09.79.12l.48.05.75.07h.53l.73,0h.54c.24,0,.49,0,.73,0l.54,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.15,587c2.62.81,2.65,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-3A18.52,18.52,0,0,1,394.15,587Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <polygon points="365.33 388.93 365.29 412.73 338.5 420.97 338.54 397.16 365.33 388.93" style="fill: #8ac2fb"/>
                        <path d="M409.37,596.12a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.76.76,0,0,1-.08.11l-.08.08-.11.09-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.6.15s-.31.09-.48.12a6.17,6.17,0,0,1-.62.11l-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.36,0q-.55-.12-1-.27c-1.32-.41-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49.33.1.68.18,1,.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.54l.72,0,.55-.05c.22,0,.45,0,.67-.06l.53-.09.62-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M407.62,591.12c2.62.8,2.66,2.12.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.12-.08-3A18.41,18.41,0,0,1,407.62,591.12Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="338.54 397.16 338.5 420.97 311.53 412.78 311.57 388.97 338.54 397.16" style="fill: #256eba"/>
                        <path d="M382.5,596.2l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.12-.63.1-.52.09-.68.06-.55.05c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06c-.37-.08-.72-.17-1-.27-1.33-.4-2-.94-2-1.48v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06.8.13.48,0,.75.07h.53l.73,0h.54c.24,0,.49,0,.72,0l.55,0c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.11.12-.09.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M380.76,591.19c2.62.8,2.65,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.63-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,380.76,591.19Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <path d="M396,600.3a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18s0,0,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17-.6.16-.48.12-.63.1-.52.09c-.22,0-.45,0-.67.06l-.55.05-.72,0h-.54l-.74,0h-.53l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07.54,0c.24,0,.48,0,.73,0h.54l.72,0,.55-.06c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.2-.11.12-.08.16-.12s0,0,0,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.23,595.29c2.62.8,2.66,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,394.23,595.29Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="365.33 364.69 338.54 372.92 311.57 364.73 338.36 356.5 365.33 364.69" style="fill: #a4d6ff"/>
                        <path d="M395.89,567.78l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.53-.08-.8-.13l-.36-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.48.32.1.67.19,1,.27.12,0,.25,0,.37.06s.51.09.79.12l.48.05.75.07h.53l.73,0,.54,0c.24,0,.49,0,.73,0l.54,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.15,562.77c2.62.81,2.65,2.13.08,3a18.3,18.3,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-3A18.52,18.52,0,0,1,394.15,562.77Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <polygon points="365.33 364.69 365.29 388.49 338.5 396.73 338.54 372.92 365.33 364.69" style="fill: #8ac2fb"/>
                        <path d="M409.37,571.88a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.76.76,0,0,1-.08.11l-.08.08-.11.09-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.6.15s-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.36,0q-.55-.12-1-.27c-1.32-.41-2-1-2-1.48v3c0,.54.67,1.08,2,1.49.33.1.68.18,1,.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.54l.72,0,.55,0,.67-.06.53-.09.62-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M407.62,566.88c2.62.8,2.66,2.12.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,407.62,566.88Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="338.54 372.92 338.5 396.73 311.53 388.54 311.57 364.73 338.54 372.92" style="fill: #256eba"/>
                        <path d="M382.5,572l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55.05c-.23,0-.48,0-.72,0l-.54,0-.73,0h-.54l-.74-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06c-.37-.08-.72-.17-1-.27-1.33-.4-2-.94-2-1.48v3c0,.54.66,1.08,2,1.49.32.1.67.18,1,.26l.36.06.8.13.48,0,.75.07h.53l.73,0h.54c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M380.76,567c2.62.8,2.65,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.63-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,380.76,567Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <path d="M396,576.06l.08-.12a.18.18,0,0,0,.05-.07l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17-.6.16-.48.12-.63.1-.52.09-.67.06-.55.05-.72,0h-.54l-.74,0h-.53l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12l-.36-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-.95-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.54l.72,0,.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.13.13-.07.2-.11.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.23,571.05c2.62.8,2.66,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,394.23,571.05Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="365.33 340.45 338.54 348.68 311.57 340.49 338.36 332.26 365.33 340.45" style="fill: #a4d6ff"/>
                        <path d="M395.89,543.54l.09-.11,0-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11L392,542l-.68.07-.55.05-.72,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.53-.08-.8-.13l-.36-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.48.32.1.67.19,1,.27.12,0,.25,0,.37.06s.51.09.79.12l.48.05c.25,0,.49,0,.75.06s.35,0,.53,0l.73,0,.54,0c.24,0,.49,0,.73,0l.54,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.15,538.53c2.62.81,2.65,2.13.08,3a18.3,18.3,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-3A18.52,18.52,0,0,1,394.15,538.53Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <polygon points="365.33 340.45 365.29 364.25 338.5 372.49 338.54 348.68 365.33 340.45" style="fill: #8ac2fb"/>
                        <path d="M409.37,547.64a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.76.76,0,0,1-.08.11l-.08.07a.52.52,0,0,1-.11.1L409,545l-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.6.15s-.31.09-.48.12L406,546l-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.32-.41-2-1-2-1.48v3c0,.54.67,1.08,2,1.48a10,10,0,0,0,1,.27l.36.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54l.72,0,.55,0,.67-.06.53-.09.62-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11s0,0,0,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M407.62,542.63c2.62.81,2.66,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.52,18.52,0,0,1,407.62,542.63Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="338.54 348.68 338.5 372.49 311.53 364.3 311.57 340.49 338.54 348.68" style="fill: #256eba"/>
                        <path d="M382.5,547.72l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.23,0-.48,0-.72,0l-.54,0-.73,0c-.18,0-.36,0-.54,0s-.49,0-.74-.06l-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06c-.37-.08-.72-.17-1-.27-1.33-.4-2-.94-2-1.48v3c0,.54.66,1.08,2,1.49.32.1.67.18,1,.26l.36.06.8.13.48,0,.75.07h.53l.73,0h.54c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M380.76,542.71c2.62.8,2.65,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.63-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,380.76,542.71Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <path d="M396,551.82l.08-.12a.18.18,0,0,0,.05-.07l.06-.12a.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17-.6.16-.48.12-.63.1-.52.09-.67.06-.55.05-.72,0h-.54l-.74,0h-.53l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1.05-.27c-1.32-.4-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49A10.07,10.07,0,0,0,386,553l.36.06.79.13.49,0,.74.07h.54l.73,0h.54c.24,0,.48,0,.72,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.11.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.23,546.81c2.62.8,2.66,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,394.23,546.81Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="365.33 316.21 338.54 324.44 311.57 316.25 338.36 308.02 365.33 316.21" style="fill: #a4d6ff"/>
                        <path d="M395.89,519.3l.09-.11,0-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a.61.61,0,0,1-.05.12.35.35,0,0,0,0,.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.53-.08-.8-.13l-.36-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.48.32.1.67.19,1,.27.12,0,.25,0,.37.06s.51.09.79.12l.48.05c.25,0,.49,0,.75.06l.53,0,.73,0,.54,0c.24,0,.49,0,.73,0l.54,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.15,514.29c2.62.81,2.65,2.13.08,3a18.3,18.3,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-2.95A18.52,18.52,0,0,1,394.15,514.29Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <polygon points="365.33 316.21 365.29 340.01 338.5 348.25 338.54 324.44 365.33 316.21" style="fill: #8ac2fb"/>
                        <path d="M409.37,523.4a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.07a.76.76,0,0,1-.08.11l-.08.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16a5.87,5.87,0,0,1-.6.16c-.16,0-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.48q.49.15,1,.27l.36.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0,.54,0c.24,0,.48,0,.72,0l.55,0,.67-.06.53-.09.62-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11s0,0,0,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M407.62,518.39c2.62.81,2.66,2.13.08,3a18.3,18.3,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.52,18.52,0,0,1,407.62,518.39Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="338.54 324.44 338.5 348.25 311.53 340.06 311.57 316.25 338.54 324.44" style="fill: #256eba"/>
                        <path d="M382.5,523.47l.09-.11.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.55,0c-.23,0-.48,0-.72,0l-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.48-.05c-.28,0-.54-.08-.8-.12l-.36-.06c-.37-.08-.72-.17-1-.27-1.33-.41-2-.94-2-1.48v3c0,.54.66,1.08,2,1.49.32.1.67.18,1,.26l.36.06.8.13.48,0,.75.07h.53l.73,0h.54c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12c.18,0,.36-.08.53-.14l.08,0c.17,0,.32-.11.47-.17l.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M380.76,518.47c2.62.8,2.65,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.63-.8-2.66-2.12-.08-3A18.41,18.41,0,0,1,380.76,518.47Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                      <g>
                        <path d="M396,527.58l.08-.12a.18.18,0,0,0,.05-.07l.06-.12a.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.31.31,0,0,1-.09.11l-.07.08-.11.1-.15.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17-.6.16-.48.12-.63.1-.52.09-.67.06-.55.05c-.24,0-.48,0-.72,0l-.54,0-.74,0h-.53l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1.05-.27c-1.32-.4-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.54c.24,0,.48,0,.72,0l.55,0c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08.91.91,0,0,0,.16-.11l0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                        <path d="M394.23,522.57c2.62.8,2.66,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.62-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,394.23,522.57Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                      </g>
                    </g>
                  </g>
                </g>
                <path d="M397.17,636.53" transform="translate(-51.09 -206.61)" style="fill: #abdf3b"/>
                <path d="M228,281a11.75,11.75,0,0,0-3,.4,13.51,13.51,0,0,0-14.89-8.08,13.56,13.56,0,0,0-23.69,2.43,13.77,13.77,0,0,0-4.09-.62,13.56,13.56,0,0,0-13.56,13.56h70.18A11.57,11.57,0,0,0,228,281Z" transform="translate(-51.09 -206.61)" style="fill: #ffd4e8"/>
                <path d="M289.26,340a9.31,9.31,0,0,1,2.43.32,10.78,10.78,0,0,1,11.85-6.44,10.8,10.8,0,0,1,18.86,1.94,10.8,10.8,0,0,1,14.05,10.3H280.58A9.21,9.21,0,0,1,289.26,340Z" transform="translate(-51.09 -206.61)" style="fill: #ffd4e8"/>
                <path d="M329.65,217.41a9.24,9.24,0,0,1,2.42.33,10.78,10.78,0,0,1,11.85-6.44,10.79,10.79,0,0,1,18.86,1.94,10.57,10.57,0,0,1,3.25-.5,10.8,10.8,0,0,1,10.8,10.79H321A9.23,9.23,0,0,1,329.65,217.41Z" transform="translate(-51.09 -206.61)" style="fill: #ffd4e8"/>
                <g>
                  <g>
                    <polygon points="432.83 427.3 430.45 450.96 422.11 430.1 424.49 406.44 432.83 427.3" style="fill: #256eba"/>
                    <polygon points="467.76 424.63 432.83 427.27 424.49 406.44 459.42 403.79 467.76 424.63" style="fill: #a4d6ff"/>
                    <path d="M490.81,618.26a9.83,9.83,0,0,1-8.88-.79,3.16,3.16,0,0,1-1.62-2.76l-.22,2.4a3.16,3.16,0,0,0,1.62,2.76,9.86,9.86,0,0,0,8.88.79,3.16,3.16,0,0,0,2.05-2.39l.22-2.4A3.13,3.13,0,0,1,490.81,618.26Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                    <path d="M491.24,613.12c2.33,1.64,2.14,3.94-.43,5.14a9.83,9.83,0,0,1-8.88-.79c-2.33-1.64-2.14-3.94.43-5.15A9.83,9.83,0,0,1,491.24,613.12Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                  </g>
                  <g>
                    <path d="M512.46,627.4a9.8,9.8,0,0,1-8.88-.79,3.16,3.16,0,0,1-1.62-2.76l-.22,2.4a3.16,3.16,0,0,0,1.62,2.76,9.86,9.86,0,0,0,8.88.79,3.14,3.14,0,0,0,2-2.39l.22-2.4A3.13,3.13,0,0,1,512.46,627.4Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                    <path d="M512.89,622.26c2.33,1.64,2.14,3.94-.43,5.14a9.8,9.8,0,0,1-8.88-.79c-2.33-1.64-2.14-3.94.43-5.15A9.83,9.83,0,0,1,512.89,622.26Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                  </g>
                  <g>
                    <polygon points="467.76 424.63 465.38 448.29 430.45 450.96 432.83 427.3 467.76 424.63" style="fill: #8ac2fb"/>
                    <path d="M495,628.69a9.8,9.8,0,0,1-8.88-.79,3.16,3.16,0,0,1-1.62-2.75l-.22,2.39a3.14,3.14,0,0,0,1.62,2.76,9.78,9.78,0,0,0,8.87.79,3.13,3.13,0,0,0,2.06-2.39l.22-2.39A3.13,3.13,0,0,1,495,628.69Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                    <path d="M495.43,623.55c2.33,1.64,2.13,4-.43,5.14a9.8,9.8,0,0,1-8.88-.79c-2.33-1.64-2.14-3.94.43-5.14A9.83,9.83,0,0,1,495.43,623.55Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                  </g>
                  <g>
                    <path d="M508.27,617a9.83,9.83,0,0,1-8.88-.8,3.15,3.15,0,0,1-1.62-2.75l-.22,2.4a3.14,3.14,0,0,0,1.62,2.75,9.78,9.78,0,0,0,8.87.79,3.09,3.09,0,0,0,2-2.39l.23-2.39A3.16,3.16,0,0,1,508.27,617Z" transform="translate(-51.09 -206.61)" style="fill: #256eba"/>
                    <path d="M508.7,611.82c2.33,1.64,2.13,3.95-.43,5.15a9.83,9.83,0,0,1-8.88-.8c-2.33-1.64-2.14-3.94.43-5.14A9.83,9.83,0,0,1,508.7,611.82Z" transform="translate(-51.09 -206.61)" style="fill: #b8dfff"/>
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="325.1 422.4 348.48 418.06 327 411.49 303.61 415.83 325.1 422.4" style="fill: #f8c778"/>
                    <polygon points="325.37 458.87 325.1 422.4 303.64 415.82 303.91 450.85 325.37 458.87" style="fill: #d69034"/>
                    <path d="M361.17,637.18a9.83,9.83,0,0,0-1.53-8.77,3.14,3.14,0,0,0-2.88-1.39l2.37-.42A3.13,3.13,0,0,1,362,628a9.83,9.83,0,0,1,1.53,8.77,3.17,3.17,0,0,1-2.21,2.25l-2.37.42A3.15,3.15,0,0,0,361.17,637.18Z" transform="translate(-51.09 -206.61)" style="fill: #eaaf52"/>
                    <path d="M356.08,638c1.82,2.19,4.11,1.8,5.09-.86a9.83,9.83,0,0,0-1.53-8.77c-1.83-2.19-4.11-1.81-5.09.85A9.8,9.8,0,0,0,356.08,638Z" transform="translate(-51.09 -206.61)" style="fill: #c47e2a"/>
                  </g>
                  <g>
                    <path d="M372.08,658a9.82,9.82,0,0,0-1.53-8.78,3.17,3.17,0,0,0-2.88-1.39l2.37-.42a3.16,3.16,0,0,1,2.88,1.39,9.79,9.79,0,0,1,1.53,8.78,3.12,3.12,0,0,1-2.21,2.24l-2.37.42A3.13,3.13,0,0,0,372.08,658Z" transform="translate(-51.09 -206.61)" style="fill: #eaaf52"/>
                    <path d="M367,658.86c1.83,2.18,4.11,1.8,5.09-.86a9.82,9.82,0,0,0-1.53-8.78c-1.83-2.19-4.11-1.81-5.09.86A9.83,9.83,0,0,0,367,658.86Z" transform="translate(-51.09 -206.61)" style="fill: #c47e2a"/>
                  </g>
                  <g>
                    <polygon points="325.35 458.88 348.73 453.1 348.48 418.06 325.1 422.4 325.35 458.88" style="fill: #eaaf52"/>
                    <path d="M371.91,640.49a9.8,9.8,0,0,0-1.53-8.78,3.16,3.16,0,0,0-2.88-1.39l2.37-.42a3.16,3.16,0,0,1,2.88,1.39,9.79,9.79,0,0,1,1.53,8.78,3.1,3.1,0,0,1-2.21,2.24l-2.37.42A3.1,3.1,0,0,0,371.91,640.49Z" transform="translate(-51.09 -206.61)" style="fill: #eaaf52"/>
                    <path d="M366.82,641.35c1.83,2.18,4.11,1.8,5.09-.86a9.8,9.8,0,0,0-1.53-8.78c-1.83-2.19-4.1-1.8-5.09.86A9.83,9.83,0,0,0,366.82,641.35Z" transform="translate(-51.09 -206.61)" style="fill: #c47e2a"/>
                  </g>
                  <g>
                    <path d="M361.33,654.69a9.83,9.83,0,0,0-1.53-8.78,3.17,3.17,0,0,0-2.88-1.39l2.37-.42a3.16,3.16,0,0,1,2.88,1.39,9.82,9.82,0,0,1,1.53,8.78,3.13,3.13,0,0,1-2.21,2.24l-2.37.42A3.13,3.13,0,0,0,361.33,654.69Z" transform="translate(-51.09 -206.61)" style="fill: #eaaf52"/>
                    <path d="M356.24,655.55c1.83,2.18,4.11,1.8,5.09-.86a9.83,9.83,0,0,0-1.53-8.78c-1.83-2.19-4.11-1.8-5.09.86A9.83,9.83,0,0,0,356.24,655.55Z" transform="translate(-51.09 -206.61)" style="fill: #c47e2a"/>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon points="185.44 438.56 185.4 464.06 124.73 446.49 124.77 421 185.44 438.56" style="fill: #d360ad"/>
                        <polygon points="215.57 429.84 185.44 438.56 124.77 421 154.91 412.27 215.57 429.84" style="fill: #fcaef1"/>
                        <path d="M198.18,628.26l.1-.12.05-.07a.42.42,0,0,0,.06-.14s0,0,0-.06a.75.75,0,0,0,0-.2v-3.18a.64.64,0,0,1,0,.2l0,.07a.38.38,0,0,1-.06.13l-.06.08a.94.94,0,0,1-.09.12l-.08.08-.13.11-.17.12-.13.09-.23.12-.15.08-.3.13-.15.06-.53.18-.69.17-.53.13-.7.11c-.2,0-.39.07-.59.09l-.76.07-.62,0c-.27,0-.54,0-.81,0l-.61,0-.82,0-.6,0-.84-.07-.54,0-.9-.14c-.13,0-.27,0-.4-.06a12,12,0,0,1-1.18-.28c-1.49-.44-2.24-1-2.24-1.59v3.17c0,.58.75,1.15,2.24,1.59a12,12,0,0,0,1.18.28l.41.07c.29,0,.58.1.89.13l.54,0c.28,0,.55.06.84.07l.6,0,.82,0,.61,0,.81,0c.21,0,.41,0,.62-.06l.76-.07c.2,0,.39-.06.59-.09l.7-.11.54-.13.59-.14.09,0,.53-.18L197,629a2.71,2.71,0,0,0,.3-.13.71.71,0,0,0,.15-.08l.23-.12.13-.09.18-.12.05,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                        <path d="M196.22,622.9c2.94.86,3,2.28.09,3.16a21.81,21.81,0,0,1-10.59,0c-2.95-.87-3-2.28-.09-3.16A21.69,21.69,0,0,1,196.22,622.9Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                      </g>
                      <g>
                        <path d="M213.33,632.66a.64.64,0,0,0,.1-.13l.06-.07.06-.14,0-.06a.75.75,0,0,0,0-.2v-3.18a.76.76,0,0,1,0,.21l0,.06-.06.14-.06.07-.1.12-.08.08-.12.11-.17.12-.13.09-.23.12-.15.08-.31.13-.15.06-.53.18a6.76,6.76,0,0,1-.68.17l-.53.13-.71.11c-.19,0-.38.07-.58.09l-.76.07-.62.06-.81,0-.61,0-.82,0-.61,0c-.28,0-.55,0-.83-.07l-.55,0c-.3,0-.6-.09-.89-.14-.13,0-.28,0-.41-.06a11.82,11.82,0,0,1-1.17-.28c-1.49-.44-2.24-1-2.24-1.59v3.17c0,.58.75,1.16,2.24,1.59.37.11.76.2,1.17.28l.41.07c.3,0,.59.1.89.13a5.11,5.11,0,0,0,.55,0c.28,0,.55.06.84.07l.6,0,.82,0,.61,0c.27,0,.54,0,.81,0l.62,0,.76-.07.59-.1c.23,0,.47-.06.7-.11l.53-.13a5.09,5.09,0,0,0,.59-.14l.09,0a3.66,3.66,0,0,0,.53-.18l.15-.06.31-.13.15-.08.23-.12L213,633l.17-.13.06,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                        <path d="M211.38,627.29c2.94.87,3,2.28.08,3.16a21.77,21.77,0,0,1-10.58,0c-2.95-.87-3-2.28-.09-3.16A21.81,21.81,0,0,1,211.38,627.29Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M213.24,623.89l.1-.12.06-.08.06-.13,0-.07a.69.69,0,0,0,0-.2v-3.17a.75.75,0,0,1,0,.2l0,.06-.07.14,0,.07-.1.12-.08.08-.12.11-.17.12-.14.09-.22.12-.15.08-.31.13-.15.06a5.46,5.46,0,0,1-.53.18,6.76,6.76,0,0,1-.68.17l-.53.13-.71.11c-.19,0-.38.07-.59.09l-.76.07c-.2,0-.4,0-.61.06l-.81,0-.61,0-.82,0-.61,0c-.28,0-.56,0-.83-.07l-.55,0c-.31,0-.6-.08-.89-.13L202,622a11.82,11.82,0,0,1-1.17-.28c-1.49-.44-2.24-1-2.24-1.59v3.17c0,.58.75,1.16,2.24,1.59a11.77,11.77,0,0,0,1.17.29l.41.06.89.14.55,0,.83.08h.61l.82,0h.61l.81,0,.62-.05.76-.07.58-.09c.24,0,.48-.07.71-.12a4.56,4.56,0,0,0,.53-.13,5.09,5.09,0,0,0,.59-.14l.09,0,.53-.18.15-.07.31-.13.15-.07.23-.13.13-.08.17-.13a.1.1,0,0,0,.05,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                        <path d="M211.28,618.52c2.95.87,3,2.28.09,3.16a21.65,21.65,0,0,1-10.58,0c-2.95-.86-3-2.28-.09-3.16A21.77,21.77,0,0,1,211.28,618.52Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                      </g>
                      <g>
                        <path d="M228.4,628.28l.1-.12a.35.35,0,0,0,.05-.08.83.83,0,0,0,.07-.13s0-.05,0-.07a.71.71,0,0,0,0-.2v-3.17a.75.75,0,0,1,0,.2s0,0,0,.06a.91.91,0,0,1-.07.14l-.05.07a.64.64,0,0,1-.1.13l-.08.08-.12.1-.18.13-.13.08-.22.12-.16.08a2.71,2.71,0,0,1-.3.13l-.15.06a4.12,4.12,0,0,1-.53.18,6.76,6.76,0,0,1-.68.17l-.54.13c-.22,0-.46.08-.7.11l-.59.1-.76.07-.62.05c-.26,0-.54,0-.81,0l-.61,0-.82,0c-.2,0-.4,0-.6,0s-.56,0-.84-.07a4.93,4.93,0,0,1-.54,0c-.31,0-.6-.08-.89-.13l-.41-.07a12,12,0,0,1-1.18-.28c-1.49-.43-2.24-1-2.24-1.59v3.17c0,.58.75,1.16,2.24,1.6.37.1.76.2,1.18.28l.41.06.89.14.54,0,.84.07h.6c.28,0,.55,0,.82,0h.61c.27,0,.55,0,.81,0l.62,0,.76-.07.59-.09c.24,0,.48-.07.7-.12s.36-.08.54-.12.4-.09.59-.15l.09,0,.53-.18.15-.06.3-.13.16-.08.22-.13a.57.57,0,0,0,.13-.08,1.18,1.18,0,0,0,.18-.13s0,0,.05,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                        <path d="M226.44,622.91c2.94.87,3,2.28.09,3.16a21.57,21.57,0,0,1-10.59,0c-2.95-.86-3-2.28-.09-3.16A21.81,21.81,0,0,1,226.44,622.91Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path d="M228.51,637.06l.1-.12a.35.35,0,0,0,0-.08.83.83,0,0,0,.07-.13l0-.07a.64.64,0,0,0,0-.2v-3.17a.69.69,0,0,1,0,.2l0,.07a.83.83,0,0,1-.07.13.35.35,0,0,1,0,.08l-.1.12-.08.08-.12.1-.17.13-.14.08-.22.13-.16.07a2.08,2.08,0,0,1-.3.13l-.15.07-.53.18L226,635c-.18,0-.35.09-.53.12s-.47.08-.71.12l-.59.09-.76.07-.61.05c-.27,0-.54,0-.82,0h-.6l-.83,0h-.6l-.83-.08-.55,0-.89-.14-.41-.06a11.77,11.77,0,0,1-1.17-.29c-1.5-.43-2.24-1-2.24-1.59v3.18c0,.57.75,1.15,2.24,1.59.36.1.76.2,1.17.28l.41.06.89.14.55.05.83.07.6,0,.83,0,.61,0,.81,0,.61,0,.76-.07c.21,0,.4-.06.59-.09l.71-.11.53-.13.59-.14.09,0,.53-.18.15-.06.31-.13.15-.08.22-.12.14-.09.17-.12.05,0,.07-.07A.6.6,0,0,0,228.51,637.06Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                        <path d="M226.55,631.7c2.95.86,3,2.28.09,3.16a21.65,21.65,0,0,1-10.58,0c-2.95-.86-3-2.27-.09-3.15A21.65,21.65,0,0,1,226.55,631.7Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                      </g>
                      <g>
                        <polygon points="215.57 429.84 215.54 455.33 185.4 464.06 185.44 438.56 215.57 429.84" style="fill: #ff86e2"/>
                        <path d="M243.67,641.45l.1-.12a.56.56,0,0,0,0-.08.31.31,0,0,0,.06-.13.15.15,0,0,0,0-.07.64.64,0,0,0,0-.2v-3.17a.69.69,0,0,1,0,.2.21.21,0,0,1,0,.07.38.38,0,0,1-.06.13.35.35,0,0,0,0,.08l-.1.12-.08.08-.13.1a1.09,1.09,0,0,1-.17.13.57.57,0,0,1-.13.08,1.71,1.71,0,0,1-.23.13l-.15.08-.3.13-.15.06-.53.18-.68.17c-.18,0-.35.09-.54.12s-.47.08-.7.12l-.59.09-.76.07-.62,0-.81,0-.61,0-.82,0h-.6l-.84-.07-.54,0-.89-.14-.41-.06c-.42-.08-.81-.18-1.18-.28-1.49-.44-2.24-1-2.24-1.6v3.18c0,.57.75,1.15,2.24,1.59a12,12,0,0,0,1.18.28c.13,0,.27,0,.41.06l.89.14.54.05.84.07.6,0,.82,0,.61,0,.81,0,.62-.06.76-.07c.2,0,.39-.06.59-.09l.7-.11.54-.13.59-.14.09,0,.53-.18.15-.06.3-.13.15-.08.23-.12.13-.09.18-.12a.1.1,0,0,1,.05,0l.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                        <path d="M241.71,636.09c2.94.86,3,2.28.09,3.16a21.81,21.81,0,0,1-10.59,0c-3-.87-3-2.28-.09-3.16A21.69,21.69,0,0,1,241.71,636.09Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M243.58,632.68a.94.94,0,0,0,.09-.12l.06-.07a.42.42,0,0,0,.06-.14l0-.06a.76.76,0,0,0,0-.21v-3.17a.64.64,0,0,1,0,.2l0,.07a.75.75,0,0,1-.06.13l-.06.08-.09.12a.6.6,0,0,0-.08.08l-.13.11-.17.12-.13.09-.23.12-.15.08-.3.13-.15.06-.53.18-.69.17-.53.13-.7.11c-.2,0-.39.07-.59.09l-.76.07-.62.05-.81,0-.61,0-.82,0-.6,0-.84-.07-.54,0-.9-.14-.4-.06a12,12,0,0,1-1.18-.28c-1.49-.44-2.24-1-2.24-1.59v3.17c0,.58.75,1.15,2.24,1.59a12,12,0,0,0,1.18.28l.4.07c.3,0,.59.1.9.13l.54.05c.28,0,.55.06.84.07l.6,0,.82,0,.61,0,.81,0,.62-.06.76-.07c.2,0,.39-.06.59-.09l.7-.11.54-.13.59-.14.09,0,.53-.18.15-.06.3-.13.15-.08.23-.12.13-.09.17-.12.06,0,.07-.07Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                        <path d="M241.62,627.32c2.94.86,3,2.28.09,3.16a21.81,21.81,0,0,1-10.59,0c-3-.87-3-2.28-.09-3.16A21.69,21.69,0,0,1,241.62,627.32Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                      </g>
                      <g>
                        <path d="M258.73,637.07l.1-.12.06-.07.06-.14,0-.06a.75.75,0,0,0,0-.2V633.3a.64.64,0,0,1,0,.2l0,.07-.06.13-.06.08-.1.12-.08.08-.12.11-.17.12-.13.09-.23.12-.15.08-.31.13-.15.06-.53.18c-.21.07-.45.11-.68.17l-.53.13-.71.11c-.19,0-.38.07-.59.09l-.76.07-.61.06-.81,0-.61,0-.82,0-.61,0-.83-.07-.55,0-.89-.14c-.13,0-.28,0-.41-.06a11.82,11.82,0,0,1-1.17-.28c-1.49-.44-2.24-1-2.24-1.59v3.17c0,.58.75,1.15,2.24,1.59a11.82,11.82,0,0,0,1.17.28l.41.07c.29.05.58.1.89.13l.55.05c.28,0,.55.06.83.07l.61,0,.82,0,.61,0,.81,0c.21,0,.41,0,.62-.06s.51,0,.76-.06l.58-.1c.24,0,.48-.06.71-.11l.53-.13.59-.14.09,0a3.66,3.66,0,0,0,.53-.18l.15-.06.31-.13.15-.08.23-.12.13-.09.17-.12.06,0,.06-.07Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                        <path d="M256.77,631.71c3,.86,3,2.28.09,3.16a21.77,21.77,0,0,1-10.58,0c-2.95-.87-3-2.28-.09-3.16A21.77,21.77,0,0,1,256.77,631.71Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon points="123.49 473.87 123.45 480 69.52 463.59 69.55 457.46 123.49 473.87" style="fill: #628c58"/>
                        <polygon points="150.28 465.71 123.49 473.87 69.55 457.46 96.34 449.31 150.28 465.71" style="fill: #a8d39e"/>
                        <path d="M140.48,664.68a.48.48,0,0,0,.09-.11l.05-.07a.5.5,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13l-.05.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12l-.63.11-.52.08-.68.07-.54.05-.73,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.48a9.85,9.85,0,0,0,1,.27l.37.06c.26,0,.52.09.79.12l.48.05.75.07h.53l.73,0h.54c.25,0,.49,0,.73,0l.54,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08s.11-.07.15-.11l0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M138.74,659.67c2.62.81,2.65,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-2.95A18.52,18.52,0,0,1,138.74,659.67Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M154,668.78a.44.44,0,0,0,.08-.11l.05-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.08.08-.1.09-.16.12-.11.08-.21.12-.13.07-.27.12-.14.06-.47.17c-.18.06-.4.1-.6.15s-.31.09-.48.12a6.17,6.17,0,0,1-.62.11l-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36-.05q-.56-.12-1.05-.27c-1.32-.41-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06l.52-.09.62-.1.48-.12.52-.14.08,0c.17-.05.33-.11.48-.17l.13-.06.27-.12.13-.07.21-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l0-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M152.22,663.78c2.61.8,2.65,2.12.07,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.12-.08-3A18.45,18.45,0,0,1,152.22,663.78Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M153.88,660.6l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13,0,.07a.31.31,0,0,1-.09.11l-.07.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17-.6.16-.48.12-.62.1L150,659l-.67.06-.55,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1-.27c-1.32-.4-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26l.36.06.79.13.49,0,.74.07h.54l.73,0H148l.72,0,.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12c.17,0,.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.11.12-.08.16-.12,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M152.13,655.59c2.62.8,2.66,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.62-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,152.13,655.59Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M167.35,664.7l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.5.5,0,0,1,0,.13l-.05.07-.09.11-.07.08-.11.1L167,662l-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55.05c-.23,0-.48,0-.72,0H161l-.73,0h-.54l-.74-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06.8.13.48,0,.75.07.53,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17-.05.32-.11.47-.17l.13,0a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11L167,665l.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M165.61,659.69c2.62.8,2.65,2.13.08,2.95a18.52,18.52,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-2.95A18.3,18.3,0,0,1,165.61,659.69Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path d="M167.45,672.9l.09-.12.05-.07a.61.61,0,0,0,0-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.07a.61.61,0,0,1,0,.12l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06.8.13.48,0,.75.07.53,0,.73,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13,0,.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M165.71,667.89c2.62.81,2.65,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-3A18.3,18.3,0,0,1,165.71,667.89Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <polygon points="150.28 465.71 150.25 471.85 123.45 480 123.49 473.87 150.28 465.71" style="fill: #82b378"/>
                        <path d="M180.93,677l.08-.11a.35.35,0,0,0,.05-.08l.06-.12a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18s0,.05,0,.07l-.06.12a.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.07-.11.1a.58.58,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17l-.6.16-.48.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.67.06l-.55,0-.72,0h-.54l-.74,0h-.53l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.72-.16-1.05-.26-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.72,0,.55,0,.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,0,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M179.18,672c2.62.81,2.66,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,179.18,672Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M180.84,668.81l.09-.11,0-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.12,0,.07a.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.26.12-.14.05a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.54.05-.73,0-.54,0-.73,0-.53,0-.74-.07-.49,0-.79-.13c-.12,0-.25,0-.37-.06a10.53,10.53,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06s.52.1.79.13l.48,0,.75.06.53,0,.73,0,.55,0,.72,0,.55,0,.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12.05,0,.06-.07A.23.23,0,0,0,180.84,668.81Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M179.1,663.8c2.62.81,2.66,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-2.95A18.41,18.41,0,0,1,179.1,663.8Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M194.32,672.91l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.67.67,0,0,1-.06.12.35.35,0,0,1,0,.08l-.08.11-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.13,0-.47.17c-.19.06-.41.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.48,0c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1-.26c-1.32-.41-2-1-2-1.49v3c0,.53.67,1.07,2,1.48.32.1.67.19,1,.27l.36,0c.26.05.52.1.8.13l.48.05c.25,0,.49,0,.74.06l.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.11-.08.16-.12.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M192.58,667.9c2.61.81,2.65,2.13.08,3a18.34,18.34,0,0,1-9.42,0c-2.62-.81-2.65-2.13-.07-3A18.41,18.41,0,0,1,192.58,667.9Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon points="177.96 490.44 177.92 496.58 123.98 480.17 124.02 474.04 177.96 490.44" style="fill: #628c58"/>
                        <polygon points="204.75 482.29 177.96 490.44 124.02 474.04 150.81 465.89 204.75 482.29" style="fill: #a8d39e"/>
                        <path d="M195,681.26l.09-.11a.18.18,0,0,1,.05-.07,1.12,1.12,0,0,0,0-.13l0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19l0,.06a.61.61,0,0,1,0,.12l-.05.07-.09.12-.07.07-.11.1-.15.12-.12.08-.2.11-.14.08-.27.12-.13,0c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.53,0-.75-.07-.48,0-.8-.13-.36-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06c.27,0,.52.1.8.13l.48.05c.25,0,.49,0,.75.06l.53,0,.73,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.17,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.07Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M193.21,676.25c2.62.81,2.65,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,193.21,676.25Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M208.43,685.36a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.35.35,0,0,1-.05.08l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16-.6.16c-.16,0-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.53.67,1.07,2,1.48q.5.15,1.05.27l.36.05c.26,0,.52.1.79.13l.49,0c.24,0,.49.05.74.06l.54,0,.73,0,.54,0,.72,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M206.68,680.35c2.62.81,2.66,2.13.08,2.95a18.3,18.3,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,206.68,680.35Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M208.34,677.17a.48.48,0,0,0,.09-.11l0-.07.06-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13,0,.07-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.13.08-.27.12-.14.06-.47.16c-.19.06-.4.11-.61.16s-.3.09-.47.12l-.63.11-.52.08-.67.07-.55.05-.72,0-.55,0-.73,0-.53,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.53,10.53,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.48q.49.15,1,.27l.36.06c.26,0,.52.09.79.12l.49.05.74.07h.53l.74,0,.54,0c.24,0,.48,0,.72,0l.55,0,.67-.06.53-.09a6.17,6.17,0,0,0,.62-.11l.48-.11.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08l.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M206.6,672.16c2.62.81,2.66,2.13.08,3a18.3,18.3,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-2.95A18.52,18.52,0,0,1,206.6,672.16Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M221.82,681.27l.09-.11,0-.07A.31.31,0,0,0,222,681l0-.06a1.11,1.11,0,0,0,0-.19v-3a1.23,1.23,0,0,1,0,.19l0,.06a.75.75,0,0,1-.06.13l0,.07a.76.76,0,0,1-.08.11l-.07.08-.11.09-.16.12-.11.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.48,0c-.28,0-.54-.08-.8-.13l-.36,0q-.56-.12-1-.27c-1.32-.41-2-1-2-1.48v3c0,.54.67,1.08,2,1.48a9.25,9.25,0,0,0,1,.27l.36.06c.26,0,.52.09.8.12l.48.05.74.07h.54l.73,0H216l.72,0,.55,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M220.08,676.27c2.61.8,2.65,2.12.08,3a18.45,18.45,0,0,1-9.42,0c-2.62-.81-2.65-2.13-.07-3A18.41,18.41,0,0,1,220.08,676.27Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path d="M221.92,689.47l.09-.11,0-.07a.31.31,0,0,0,.06-.13l0-.06a1.1,1.1,0,0,0,0-.18v-3a1.11,1.11,0,0,1,0,.19l0,.06a.75.75,0,0,1-.06.13l-.05.07a.76.76,0,0,1-.08.11l-.07.08-.11.09-.16.12-.11.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.48-.05c-.28,0-.54-.08-.8-.13l-.36,0q-.55-.12-1-.27c-1.32-.41-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49.32.1.67.18,1,.26l.36.06.8.13.48,0,.74.07h.54l.73,0h.54l.72,0,.55-.05c.23,0,.46,0,.68-.06l.52-.09.63-.1.47-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,0,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M220.18,684.47c2.61.8,2.65,2.12.08,2.95a18.45,18.45,0,0,1-9.42,0c-2.62-.81-2.65-2.12-.07-3A18.41,18.41,0,0,1,220.18,684.47Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <polygon points="204.75 482.29 204.71 488.43 177.92 496.58 177.96 490.44 204.75 482.29" style="fill: #82b378"/>
                        <path d="M235.39,693.58a.94.94,0,0,0,.09-.12l.05-.07.06-.12a.14.14,0,0,1,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.26.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a6.37,6.37,0,0,1-.63.11l-.52.09-.68.06-.54.05c-.24,0-.48,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.53,0L227,692l-.48,0c-.27,0-.53-.08-.79-.12s-.25,0-.37-.06c-.36-.08-.72-.17-1-.27-1.33-.4-2-.94-2-1.48v3c0,.54.66,1.08,2,1.49.33.1.68.18,1,.26l.37.06.79.13.48,0,.75.07h.53l.73,0h.55c.24,0,.48,0,.72,0l.55,0c.22,0,.45,0,.67-.06l.52-.09.63-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12.12-.08a.83.83,0,0,0,.15-.11l0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M233.65,688.57c2.62.8,2.65,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.62-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,233.65,688.57Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M235.31,685.39a.94.94,0,0,0,.09-.12l0-.07a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.5.5,0,0,1-.05.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.54,0c-.24,0-.49,0-.73,0h-.54l-.73,0h-.53l-.75-.07-.48,0-.79-.13-.37-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06.79.13.48,0,.75.07.53,0c.25,0,.49,0,.73,0h.54l.73,0,.54-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12c.18,0,.37-.08.53-.13l.08,0c.17,0,.32-.11.47-.17l.13,0a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M233.57,680.38c2.62.8,2.65,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,233.57,680.38Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M248.79,689.49l.08-.12a.18.18,0,0,0,0-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,1,0,.07.67.67,0,0,1-.06.12l0,.07a.83.83,0,0,1-.08.12l-.08.07-.1.1a.91.91,0,0,1-.16.11l-.11.08-.21.12-.13.07-.27.12-.14.06a4.24,4.24,0,0,1-.47.17c-.18.06-.4.11-.6.16l-.48.12-.62.1-.53.09c-.22,0-.45,0-.67.06l-.55,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49,0-.79-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.11,0,.24,0,.36.06l.79.13.49,0c.24,0,.49.06.74.07l.54,0,.73,0,.54,0,.72,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0c.17,0,.33-.11.48-.17l.13-.05.27-.12.13-.08.21-.11.11-.08.16-.12,0,0a.34.34,0,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M247.05,684.48c2.61.81,2.65,2.13.07,2.95a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.34,18.34,0,0,1,247.05,684.48Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon points="271.01 479.83 270.98 485.96 217.04 469.56 217.07 463.42 271.01 479.83" style="fill: #628c58"/>
                        <polygon points="297.8 471.68 271.01 479.83 217.07 463.42 243.87 455.27 297.8 471.68" style="fill: #a8d39e"/>
                        <path d="M288,670.65a.83.83,0,0,0,.08-.12.18.18,0,0,0,.05-.07.67.67,0,0,0,.06-.12.14.14,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08-.11.1a1,1,0,0,1-.15.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17-.6.16-.48.12-.62.1-.53.09-.67.06-.55,0c-.24,0-.48,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1.05-.27c-1.32-.4-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26l.36.06.79.13.49,0,.74.07h.54l.73,0h.54l.72,0,.55,0c.23,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12.52-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.21-.11.11-.09a.91.91,0,0,0,.16-.11l0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M286.26,665.64c2.62.8,2.66,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.8-2.66-2.12-.08-3A18.41,18.41,0,0,1,286.26,665.64Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M301.48,674.75l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.06a.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1-.52.09c-.22,0-.45,0-.68.06l-.55,0c-.23,0-.48,0-.72,0h-.54l-.73,0h-.53l-.75-.07-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.37.06.79.13.48,0,.75.07.53,0,.73,0,.54,0,.72,0,.55-.06c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.05a1.69,1.69,0,0,0,.27-.13l.14-.07.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M299.74,669.74c2.62.8,2.65,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-3A18.3,18.3,0,0,1,299.74,669.74Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M301.4,666.56l.09-.11a.35.35,0,0,1,.05-.08.61.61,0,0,0,0-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.1,1.1,0,0,1,0,.18l0,.07a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.15.11L301,664l-.2.11-.14.07-.27.12-.13.06c-.15.06-.3.12-.47.17s-.41.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.45,0-.68.06l-.55.05-.72,0H295l-.73,0h-.54L293,665l-.48,0-.8-.13-.36-.06c-.37-.08-.72-.16-1-.26-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06c.26.05.52.1.8.13l.48,0c.25,0,.49.06.74.07l.54,0,.73,0,.54,0,.72,0,.55,0,.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M299.66,661.55c2.62.81,2.65,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-2.95A18.41,18.41,0,0,1,299.66,661.55Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M314.87,670.66l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.13.07-.27.13-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.67.06l-.55.06-.72,0h-.55c-.24,0-.48,0-.73,0l-.53,0-.74-.07-.49,0-.79-.13-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.53,0,.74,0,.54,0,.72,0,.55,0L311,672l.53-.08.62-.11c.17,0,.32-.08.48-.12l.52-.13.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12.05,0s0,0,.06-.07Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M313.13,665.65c2.62.81,2.66,2.13.08,3a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,313.13,665.65Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path d="M315,678.86l.09-.11.05-.07.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12l-.07.07-.11.1-.15.12-.12.08-.2.11-.13.08-.27.12-.14,0a4.24,4.24,0,0,1-.47.17c-.19.06-.4.11-.61.16l-.47.12-.63.11-.52.08-.67.07-.55,0-.72,0-.55,0-.73,0-.53,0-.74-.07-.49,0-.79-.13c-.12,0-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.79.13l.49,0c.24,0,.49.05.74.06l.53,0,.74,0,.54,0,.72,0,.55-.05.67-.07.53-.08.62-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12,0,0s0,0,.06-.07Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M313.23,673.85c2.62.81,2.66,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,313.23,673.85Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <polygon points="297.8 471.68 297.77 477.81 270.98 485.96 271.01 479.83 297.8 471.68" style="fill: #82b378"/>
                        <path d="M328.45,683l.09-.11a.43.43,0,0,1,0-.07.49.49,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.67.67,0,0,1-.06.12s0,.05,0,.08l-.09.11-.07.07-.11.1-.16.12-.11.08-.2.11-.14.08-.27.12-.13.05-.47.17c-.19.06-.41.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.07l-.48,0c-.28,0-.54-.08-.8-.13l-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-.95-2-1.49v3c0,.53.66,1.07,2,1.48.32.1.67.19,1,.27l.36.05c.26,0,.52.1.8.13l.48,0c.25,0,.49.05.74.06l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M326.71,678c2.62.81,2.65,2.13.08,2.95a18.34,18.34,0,0,1-9.42,0c-2.62-.81-2.65-2.13-.07-2.95A18.41,18.41,0,0,1,326.71,678Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M328.37,674.77a.44.44,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13l0-.06a1.11,1.11,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l-.05.07-.08.11-.07.07-.11.1-.16.12-.11.08a1.39,1.39,0,0,1-.21.11l-.13.08-.27.12-.13.06-.48.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.48q.49.15,1,.27l.36.06c.26,0,.52.09.8.12l.48.05.74.07h.54l.73,0,.54,0c.24,0,.48,0,.72,0l.55,0,.68-.06.52-.09c.21,0,.42-.06.63-.11s.31-.08.47-.12l.52-.13.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.11-.08.16-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M326.63,669.76c2.61.81,2.65,2.13.07,3a18.3,18.3,0,0,1-9.41,0c-2.62-.81-2.65-2.13-.08-3A18.56,18.56,0,0,1,326.63,669.76Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M341.84,678.87a.48.48,0,0,0,.09-.11l.05-.07a.5.5,0,0,0,0-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1,0,.13l-.05.07-.09.11a.46.46,0,0,1-.07.07.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12l-.63.11-.52.08-.68.07-.54,0-.73,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.53-.08-.79-.13l-.37,0c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.48v3c0,.54.66,1.08,2,1.48a9.85,9.85,0,0,0,1,.27l.37.06c.26,0,.52.09.79.12l.48,0,.75.07h.53l.73,0H336c.25,0,.49,0,.73,0l.54-.05.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08c.05,0,.11-.07.15-.11l0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M340.1,673.86c2.62.81,2.65,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.52,18.52,0,0,1,340.1,673.86Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <path d="M151.89,612.69H100l5.55,24h0c0,1.65,2,3.31,6.09,4.56,8,2.47,20.94,2.44,28.84-.08,3.9-1.25,5.56-2.8,5.84-4.48h0Z" transform="translate(-51.09 -206.61)" style="fill: #f1a34f"/>
                  <path d="M144.15,606.89c10.2,3.14,10.33,8.29.3,11.49s-26.47,3.25-36.67.11-10.36-8.29-.31-11.5S133.93,603.74,144.15,606.89Z" transform="translate(-51.09 -206.61)" style="fill: #ffbd7b"/>
                  <path d="M148.66,612.85a4.85,4.85,0,0,0,.31-.4c.06-.09.12-.17.17-.26a1.93,1.93,0,0,0,.2-.44,2,2,0,0,0,.09-.22,2.16,2.16,0,0,0,.1-.66v-4.41a2.51,2.51,0,0,1-.1.66,2,2,0,0,1-.09.22,1.93,1.93,0,0,1-.2.44c0,.09-.11.17-.17.26s-.19.26-.31.4l-.25.26c-.12.12-.25.23-.39.35s-.35.27-.54.41l-.42.28c-.22.14-.46.28-.71.41s-.31.18-.48.26-.62.29-1,.43l-.47.21c-.52.2-1.07.4-1.67.59s-1.42.38-2.15.56c-.56.14-1.09.3-1.68.42s-1.47.26-2.22.38-1.21.22-1.85.3-1.59.16-2.39.23c-.65.06-1.28.14-1.94.18-.85,0-1.71.06-2.56.08-.64,0-1.28.06-1.92.06-.86,0-1.72,0-2.59-.07-.63,0-1.27,0-1.89-.06-.9-.05-1.76-.15-2.63-.23-.57-.06-1.16-.09-1.72-.16-1-.12-1.89-.29-2.81-.46-.42-.07-.87-.12-1.28-.2-1.31-.27-2.55-.58-3.71-.94-4.69-1.44-7.05-3.35-7.05-5.26v4.41c0,1.91,2.36,3.82,7.06,5.27,1.15.35,2.39.66,3.7.93.41.08.86.13,1.28.21.93.16,1.84.33,2.81.45.56.07,1.15.1,1.72.16.87.08,1.74.18,2.63.23.63,0,1.26,0,1.9.06.86,0,1.72.07,2.59.07.64,0,1.27,0,1.91-.06.86,0,1.71,0,2.56-.08.66,0,1.29-.12,1.94-.18.8-.07,1.62-.13,2.4-.23s1.23-.2,1.85-.3,1.51-.23,2.22-.38,1.12-.28,1.68-.42,1.28-.29,1.86-.47l.28-.09c.6-.19,1.15-.39,1.67-.59l.48-.21c.33-.14.66-.28,1-.43s.32-.17.48-.26.5-.27.72-.41.28-.18.41-.28.38-.27.54-.41l.18-.13c.08-.07.13-.15.21-.22Z" transform="translate(-51.09 -206.61)" style="fill: #593735"/>
                  <path d="M142.49,601.19c9.28,2.86,9.4,7.54.28,10.45s-24.06,3-33.34.09-9.41-7.53-.28-10.45S133.2,598.33,142.49,601.19Z" transform="translate(-51.09 -206.61)" style="fill: #725350"/>
                  <path d="M149.15,624.54l.23-1a19.28,19.28,0,0,1-5,2.3c-10,3.18-26.32,3.23-36.45.11a20.09,20.09,0,0,1-5.36-2.4l.23,1a22.25,22.25,0,0,0,4.91,2.09l1.48,10.77a10.63,10.63,0,0,1-3.93-2.22l.27,1.17a17.38,17.38,0,0,0,4.53,2.12h0l1,.31A53.37,53.37,0,0,0,126,640.71a53.07,53.07,0,0,0,15.35-2,14,14,0,0,0,5.08-2.58l.35-1.48a7.86,7.86,0,0,1-3.52,2.58l1.48-10.76A21.83,21.83,0,0,0,149.15,624.54ZM111.2,638.1l-1.29-.43-1.48-10.82a52.34,52.34,0,0,0,7.46,1.49l.67,11A41.31,41.31,0,0,1,111.2,638.1Zm6.08,1.31-.67-11a82.23,82.23,0,0,0,9,.54l.1,11A63.61,63.61,0,0,1,117.28,639.41Zm17.87,0a61.66,61.66,0,0,1-8.74.63l-.09-11a79.26,79.26,0,0,0,9.5-.61Zm7.38-1.83q-.63.26-1.35.48a37.08,37.08,0,0,1-5.31,1.23l.68-11a50,50,0,0,0,7.47-1.57Z" transform="translate(-51.09 -206.61)" style="fill: #ffbd7b"/>
                </g>
                <g>
                  <polygon points="176.35 206.53 172.34 242.07 146.45 230.92 150.46 195.38 176.35 206.53" style="fill: #dda2c8"/>
                  <path d="M223.43,448.69l2.61-.52c7.92-.7,14.2-8.93,15.09-16.84l.22-2c1-9.15-6.13-15.49-13.92-16.18Z" transform="translate(-51.09 -206.61)" style="fill: #ffd4e8"/>
                  <g>
                    <g>
                      <polygon points="89.93 245.84 88.57 257.67 62.68 246.51 64.05 234.69 89.93 245.84" style="fill: #dda2c8"/>
                      <polygon points="173.36 230.38 89.93 245.84 64.05 234.69 147.67 218.9 173.36 230.38" style="fill: #ffbde4"/>
                    </g>
                    <polygon points="173.36 230.38 172 242.21 88.57 257.67 89.93 245.84 173.36 230.38" style="fill: #ffd4e8"/>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="148.5 211.58 145.8 235.24 119.92 224.08 122.61 200.43 148.5 211.58" style="fill: #dda2c8"/>
                        <polygon points="176.03 206.48 148.5 211.58 122.61 200.43 150.15 195.33 176.03 206.48" style="fill: #ffbde4"/>
                        <path d="M193.35,409.87l.1-.1.06-.07a.75.75,0,0,0,.07-.12l0-.06a1.21,1.21,0,0,0,0-.18l.33-2.94a1.21,1.21,0,0,1-.05.18l0,.06a.41.41,0,0,1-.07.11l-.06.07-.1.1a.27.27,0,0,1-.08.07.36.36,0,0,1-.12.08l-.16.1-.13.07-.21.09-.14.06-.28.09-.14,0-.49.12-.62.09-.48.06-.64,0-.53,0c-.22,0-.45,0-.68,0h-.55l-.72-.06-.54,0-.72-.1-.53-.08c-.25,0-.49-.1-.73-.15l-.48-.1c-.27-.06-.52-.14-.77-.21l-.36-.1c-.35-.12-.69-.24-1-.38-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.95c-.06.53.54,1.14,1.81,1.7.32.13.66.26,1,.37l.36.1.77.22.48.1.73.15.53.07c.24,0,.48.08.72.1l.54.05.72,0,.55,0h.68l.53,0,.63,0,.49-.07.54-.07.08,0,.49-.11.14-.05c.09,0,.19-.05.28-.09l.14-.05a1.36,1.36,0,0,0,.21-.1l.13-.06.16-.1.06,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M192.18,404.7c2.51,1.09,2.4,2.41-.25,2.94a18.38,18.38,0,0,1-9.36-1c-2.51-1.09-2.4-2.41.26-2.94A18.34,18.34,0,0,1,192.18,404.7Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                      <g>
                        <polygon points="176.03 206.48 173.34 230.13 145.8 235.24 148.5 211.58 176.03 206.48" style="fill: #ffd4e8"/>
                        <path d="M206.28,415.45l.1-.1.06-.07a.75.75,0,0,0,.07-.12l0-.06a.61.61,0,0,0,.05-.18l.33-2.94a.61.61,0,0,1,0,.18l0,.06a.75.75,0,0,1-.07.12l-.06.06a.47.47,0,0,1-.1.1l-.07.07-.12.09-.17.09-.12.07-.22.09-.14.06-.28.09-.14,0-.49.12c-.19,0-.41.06-.62.09s-.31.05-.48.06l-.64,0-.53,0c-.22,0-.45,0-.68,0h-.55l-.72-.06-.53,0-.73-.1-.53-.08c-.25,0-.49-.1-.73-.15l-.48-.09-.77-.22-.35-.1a10.68,10.68,0,0,1-1-.38c-1.28-.55-1.88-1.16-1.82-1.7l-.33,2.95c-.06.53.54,1.14,1.82,1.7a10.72,10.72,0,0,0,1,.37l.35.1.77.22.48.1.73.15.53.07c.24,0,.48.08.72.1l.54.05.72.06h1.23l.53,0c.22,0,.43,0,.64,0l.48-.07.54-.07.08,0c.18,0,.34-.07.49-.11l.14-.05a1.31,1.31,0,0,0,.28-.09l.14-.05.22-.1.12-.06.17-.1,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M205.11,410.28c2.51,1.09,2.4,2.41-.25,2.94a18.34,18.34,0,0,1-9.35-1c-2.52-1.09-2.41-2.41.25-2.94A18.34,18.34,0,0,1,205.11,410.28Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M207.12,407.3l.1-.1a.18.18,0,0,0,.05-.07.41.41,0,0,0,.07-.11l0-.06a1.05,1.05,0,0,0,.05-.19l.33-2.94a1.21,1.21,0,0,1,0,.18l0,.06a.75.75,0,0,1-.07.12l0,.07-.1.1-.08.06-.12.09-.16.1-.13.06-.21.1-.15.05a1.84,1.84,0,0,1-.28.09l-.14,0-.48.11c-.2,0-.42.06-.62.09l-.49.07-.63,0-.53,0H202.2l-.72-.06-.54,0c-.24,0-.48-.06-.72-.1l-.53-.07-.74-.15-.47-.1-.78-.22-.35-.1a10.72,10.72,0,0,1-1-.37c-1.27-.56-1.87-1.17-1.81-1.7l-.33,2.94c-.06.54.54,1.15,1.81,1.7a10.68,10.68,0,0,0,1,.38l.35.1c.26.07.51.15.78.21l.47.1.73.15.53.08.73.1.54,0,.72.06h1.23l.53,0,.63,0,.49-.07.53-.07h.09l.48-.12.14,0,.28-.09.15-.06.21-.09.12-.07a1,1,0,0,0,.17-.1l.05,0,.07-.05Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M206,402.13c2.51,1.09,2.4,2.41-.25,2.94a18.47,18.47,0,0,1-9.36-1c-2.52-1.1-2.4-2.42.25-2.94A18.39,18.39,0,0,1,206,402.13Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                      <g>
                        <path d="M220.05,412.88a.47.47,0,0,0,.1-.1l.06-.07.07-.11,0-.06a1.05,1.05,0,0,0,.05-.19l.33-2.94a1.21,1.21,0,0,1-.05.18l0,.06a.75.75,0,0,1-.07.12l-.06.07a.94.94,0,0,1-.1.1l-.08.06-.12.09-.16.1-.13.07-.21.09-.14,0a1.4,1.4,0,0,1-.29.09l-.14.05-.48.11c-.2,0-.41.06-.62.09l-.49.07c-.2,0-.42,0-.63,0s-.35,0-.53,0h-1.23l-.72-.06-.54-.05c-.24,0-.48-.06-.72-.1l-.53-.07-.73-.15-.48-.1c-.27-.06-.52-.14-.77-.22l-.36-.1c-.36-.11-.69-.24-1-.37-1.27-.56-1.87-1.17-1.81-1.7l-.33,2.94c-.06.54.54,1.15,1.81,1.7a10.68,10.68,0,0,0,1,.38l.36.1c.25.07.5.15.77.21l.48.1c.24.05.48.11.73.15l.53.08.72.1.54,0,.72.06h.55c.23,0,.46,0,.68,0l.53,0,.63,0c.17,0,.33,0,.49-.06l.53-.08h.09l.48-.12.14,0,.29-.09.14-.06.21-.09.13-.07.16-.1.05,0A.18.18,0,0,0,220,413,.27.27,0,0,0,220.05,412.88Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M218.88,407.71c2.51,1.09,2.4,2.41-.25,2.94a18.47,18.47,0,0,1-9.36-1c-2.51-1.1-2.4-2.41.25-2.94A18.39,18.39,0,0,1,218.88,407.71Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="120.56 216.7 117.87 240.35 91.99 229.19 94.68 205.54 120.56 216.7" style="fill: #dda2c8"/>
                        <polygon points="148.1 211.59 120.56 216.7 94.68 205.54 122.22 200.44 148.1 211.59" style="fill: #ffbde4"/>
                        <path d="M165.42,415l.1-.1.06-.07a.75.75,0,0,1,.07-.12l0-.05a1.33,1.33,0,0,0,0-.19l.33-2.94a1.21,1.21,0,0,1-.05.18l0,.06-.07.12-.06.06-.1.11-.08.06-.12.09-.16.1-.13.06-.21.09-.15.06-.28.09-.14,0c-.15.05-.31.08-.48.12s-.41.06-.62.09-.32.05-.49.06l-.63,0-.53,0-.68,0h-.55l-.72-.05-.54-.05-.72-.1c-.18,0-.36,0-.53-.08l-.73-.14-.48-.1-.78-.22-.35-.1a8.5,8.5,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7l-.33,3c-.06.53.54,1.14,1.81,1.7.31.13.65.26,1,.38l.35.1c.26.07.51.15.78.21l.47.1c.25.05.49.11.74.15l.53.07.72.11.54,0,.72.06h1.23c.18,0,.35,0,.53,0s.43,0,.63,0l.49-.07.53-.07.09,0,.48-.11.14,0,.28-.09.15-.06.21-.09.13-.07.16-.1.05,0,.07-.05Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M164.25,409.81c2.51,1.09,2.4,2.41-.25,2.94a18.37,18.37,0,0,1-9.36-1c-2.51-1.09-2.4-2.41.25-2.94A18.5,18.5,0,0,1,164.25,409.81Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                      <g>
                        <polygon points="148.1 211.59 145.41 235.24 117.87 240.35 120.56 216.7 148.1 211.59" style="fill: #ffd4e8"/>
                        <path d="M178.35,420.56l.1-.1.06-.07.07-.11,0-.06a1.05,1.05,0,0,0,0-.19l.33-2.94a1.21,1.21,0,0,1-.05.18l0,.06a.75.75,0,0,1-.07.12l-.06.06-.1.11-.08.06-.12.09-.16.1-.13.06a1.36,1.36,0,0,1-.21.1l-.14.05-.28.09-.14.05-.49.11c-.2,0-.41.06-.62.09l-.49.07-.63,0-.53,0H174l-.55,0-.72,0-.54-.05c-.24,0-.48-.06-.72-.1l-.53-.07-.73-.15-.48-.1-.77-.22-.36-.1c-.36-.11-.69-.24-1-.37-1.27-.56-1.87-1.17-1.81-1.7l-.33,2.94c-.06.54.54,1.15,1.81,1.7.32.14.65.26,1,.38l.36.1c.25.07.5.15.77.21l.48.1c.24,0,.48.11.73.15l.53.08.72.1.54,0,.72.06h1.23c.18,0,.35,0,.53,0s.43,0,.63,0l.49-.07.54-.07.08,0,.49-.11.14,0,.28-.09.14-.06.21-.09.13-.07.16-.1.06,0,.06-.05Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M177.18,415.39c2.51,1.09,2.4,2.41-.25,2.94a18.47,18.47,0,0,1-9.36-1c-2.51-1.1-2.4-2.42.26-2.95A18.46,18.46,0,0,1,177.18,415.39Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M179.19,412.42l.09-.11.06-.06a.75.75,0,0,0,.07-.12s0,0,0-.06a.41.41,0,0,0,.05-.18l.33-2.95a.45.45,0,0,1,0,.19.09.09,0,0,1,0,.05.75.75,0,0,1-.07.12l0,.07a.94.94,0,0,0-.1.1.27.27,0,0,0-.08.07l-.12.08a1,1,0,0,1-.17.1l-.12.07-.22.09-.14.06-.28.09-.14,0c-.15,0-.31.08-.49.11s-.41.06-.62.09l-.48.07c-.21,0-.42,0-.64,0s-.34,0-.53,0h-1.22l-.72-.06-.54,0-.73-.1-.53-.08c-.25,0-.49-.1-.73-.15l-.48-.1c-.26-.06-.52-.14-.77-.21l-.35-.1c-.36-.12-.7-.25-1-.38-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.94c-.06.54.54,1.15,1.81,1.7a8.5,8.5,0,0,0,1,.38l.35.1.77.22.48.09c.24.06.48.11.73.15l.53.08.73.1.54,0,.72.05h.55c.22,0,.45,0,.67,0l.53,0,.64,0c.17,0,.32,0,.48-.06s.37,0,.54-.08h.08a5,5,0,0,0,.49-.12l.14,0,.28-.09.14-.06.22-.09.12-.07.17-.09,0,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M178,407.24c2.52,1.1,2.4,2.42-.25,2.94a18.44,18.44,0,0,1-9.35-1c-2.52-1.1-2.41-2.41.25-2.94A18.44,18.44,0,0,1,178,407.24Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                      <g>
                        <path d="M192.12,418l.1-.11.05-.06a.75.75,0,0,0,.07-.12l0-.06a.58.58,0,0,0,0-.18l.33-3a.58.58,0,0,1,0,.19l0,.06-.07.11a.18.18,0,0,1,0,.07l-.1.1-.08.07-.12.08a1,1,0,0,1-.17.1l-.12.07-.21.09-.15.06-.28.09-.14,0-.48.11c-.2,0-.42.07-.63.09l-.48.07c-.2,0-.42,0-.63,0s-.35,0-.53,0H187.2l-.72-.06-.54,0-.73-.1-.53-.08-.73-.15-.47-.1c-.27-.06-.52-.14-.78-.21l-.35-.1a10.68,10.68,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7L179.2,416c-.06.54.54,1.15,1.81,1.7a8.5,8.5,0,0,0,1,.38l.35.1.78.22.47.1.73.14.53.08.73.1.54.05.72,0h.55l.68,0,.53,0,.63,0c.17,0,.32,0,.49-.06l.53-.07.09,0c.17,0,.33-.07.48-.12l.14,0,.28-.09.15-.06.21-.09.12-.06.17-.1.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M191,412.82c2.51,1.1,2.4,2.42-.25,2.94a18.47,18.47,0,0,1-9.36-1c-2.52-1.1-2.4-2.41.25-2.94A18.49,18.49,0,0,1,191,412.82Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="92.63 221.86 89.93 245.51 64.05 234.36 66.74 210.71 92.63 221.86" style="fill: #dda2c8"/>
                        <polygon points="120.16 216.75 92.63 221.86 66.74 210.71 94.28 205.6 120.16 216.75" style="fill: #ffbde4"/>
                        <path d="M137.48,420.14a.94.94,0,0,0,.1-.1l.06-.07a.41.41,0,0,0,.07-.11l0-.06a1.05,1.05,0,0,0,0-.19l.33-2.94a1.21,1.21,0,0,1-.05.18l0,.06a.75.75,0,0,1-.07.12l-.06.06-.1.11-.08.06-.12.09-.16.1-.13.06a1.36,1.36,0,0,1-.21.1l-.14.05c-.09,0-.19.06-.28.09l-.14.05-.49.11c-.2,0-.41.06-.62.09l-.49.07-.63,0-.53,0h-.68l-.55,0-.72-.05-.54-.05c-.24,0-.48-.06-.72-.1l-.53-.07-.73-.15-.48-.1-.77-.22-.36-.1c-.36-.11-.69-.24-1-.37-1.27-.56-1.87-1.17-1.81-1.7l-.33,2.94c-.06.54.54,1.15,1.81,1.7.32.14.65.26,1,.38l.36.1c.25.07.5.15.77.21l.48.1c.24.05.48.11.73.15l.53.08.72.1.54,0,.72.06h1.23c.18,0,.35,0,.53,0s.43,0,.63,0l.49-.07.53-.07.09,0,.48-.11.14,0,.29-.09.14-.06.21-.09.13-.07.16-.1.06,0,.06-.05Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M136.31,415c2.51,1.09,2.4,2.41-.25,2.94a18.47,18.47,0,0,1-9.36-1c-2.51-1.1-2.4-2.42.25-2.95A18.5,18.5,0,0,1,136.31,415Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                      <g>
                        <polygon points="120.16 216.75 117.47 240.4 89.93 245.51 92.63 221.86 120.16 216.75" style="fill: #ffd4e8"/>
                        <path d="M150.41,425.72l.1-.1.06-.07a.41.41,0,0,0,.07-.11l0-.06a.59.59,0,0,0,.05-.19l.33-2.94a.61.61,0,0,1-.05.18l0,.06a.75.75,0,0,1-.07.12l-.06.07-.1.1-.08.06-.11.09-.17.1-.13.06a1,1,0,0,1-.21.1l-.14.05a1.52,1.52,0,0,1-.28.09l-.14.05-.49.11c-.19,0-.41.06-.62.09l-.48.07c-.21,0-.43,0-.64,0s-.35,0-.53,0h-1.23l-.72-.06-.54-.05c-.24,0-.48-.06-.72-.1l-.53-.07-.73-.15-.48-.1c-.27-.06-.52-.14-.77-.22l-.36-.1c-.35-.11-.69-.24-1-.37-1.28-.56-1.88-1.17-1.82-1.7l-.33,2.94c-.06.54.54,1.15,1.82,1.7.31.14.65.26,1,.38l.36.1c.25.07.5.15.77.21l.48.1c.24.05.48.11.73.15l.53.08.72.1.54,0,.72.06h.55c.23,0,.46,0,.68,0l.53,0,.64,0a3.7,3.7,0,0,0,.48-.07l.54-.07h.08l.49-.12.14,0,.28-.09.14-.06.21-.09.13-.07.17-.1.05,0,.06,0Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M149.24,420.55c2.51,1.09,2.4,2.41-.25,2.94a18.44,18.44,0,0,1-9.35-1c-2.52-1.1-2.41-2.41.25-2.94A18.34,18.34,0,0,1,149.24,420.55Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                    </g>
                    <g>
                      <path d="M164.18,423.16l.1-.11.06-.06.07-.12,0-.06a1.21,1.21,0,0,0,.05-.18l.33-2.94a1.21,1.21,0,0,1,0,.18l0,.06-.07.11-.06.07a.47.47,0,0,1-.1.1.27.27,0,0,1-.08.07l-.12.08-.16.1-.13.07-.21.09-.15.06-.28.09-.14,0-.48.12-.62.09c-.17,0-.32.05-.49.06l-.63,0-.53,0c-.22,0-.45,0-.68,0h-.55l-.72-.06-.54,0-.72-.1-.53-.08c-.25,0-.49-.1-.73-.15l-.48-.1c-.27-.06-.52-.14-.78-.21l-.35-.1a10.68,10.68,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.95c-.06.53.54,1.14,1.81,1.7a10.72,10.72,0,0,0,1,.37l.35.1.78.22.47.1.74.15.53.07c.24,0,.48.08.72.1l.54.05.72.05.55,0h.68l.53,0,.63,0,.49-.07.53-.07.09,0,.48-.11.14-.05a1.84,1.84,0,0,0,.28-.09l.15-.05.21-.1.13-.06.16-.1.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                      <path d="M163,418c2.51,1.09,2.4,2.41-.25,2.94a18.37,18.37,0,0,1-9.36-1c-2.51-1.09-2.4-2.41.25-2.94A18.39,18.39,0,0,1,163,418Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon points="151.22 187.38 148.53 211.03 122.64 199.88 125.33 176.23 151.22 187.38" style="fill: #dda2c8"/>
                      <polygon points="167.53 185.29 151.22 187.38 125.33 176.23 139.68 173.72 167.53 185.29" style="fill: #ffd7ef"/>
                      <path d="M196.07,385.67l.1-.1.06-.07a.75.75,0,0,0,.07-.12l0-.06a.61.61,0,0,0,0-.18l.33-2.94a.61.61,0,0,1-.05.18l0,.06a.41.41,0,0,1-.07.11l-.06.07-.1.1a.27.27,0,0,1-.08.07.36.36,0,0,1-.12.08l-.16.1-.13.07-.21.09-.14.06-.28.09-.14,0-.49.12-.62.09c-.16,0-.31.05-.48.06l-.64,0-.53,0c-.22,0-.45,0-.68,0h-.55l-.72-.06-.54,0-.72-.1-.53-.08c-.25,0-.49-.1-.73-.15l-.48-.1c-.27-.06-.52-.14-.77-.21l-.36-.1c-.35-.12-.69-.24-1-.38-1.28-.55-1.88-1.16-1.82-1.7l-.33,3c-.06.53.54,1.14,1.82,1.7.31.13.65.26,1,.37l.36.1.77.22.48.1.73.15.53.07c.24,0,.48.08.72.1l.54,0,.72.05.55,0h.68l.53,0,.64,0,.48-.07.54-.07.08,0,.49-.11.14,0c.09,0,.19-.05.28-.09l.14-.05a1.36,1.36,0,0,0,.21-.1l.13-.06.16-.1.06,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                      <path d="M194.9,380.5c2.51,1.09,2.4,2.41-.25,2.94a18.34,18.34,0,0,1-9.35-1c-2.52-1.09-2.41-2.41.25-2.94A18.34,18.34,0,0,1,194.9,380.5Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                    </g>
                    <g>
                      <path d="M228,405l-.86,7.56-27.53,5.11L202.31,394l11.62-2.16A12,12,0,0,1,228,405Z" transform="translate(-51.09 -206.61)" style="fill: #ffd4e8"/>
                      <path d="M209,391.25l.09-.1.06-.07a.75.75,0,0,0,.07-.12l0-.06a.61.61,0,0,0,.05-.18l.33-2.94a.41.41,0,0,1,0,.18s0,0,0,.06a.75.75,0,0,1-.07.12l-.06.06-.09.1-.08.07-.12.09-.17.09-.12.07-.22.09-.14.06-.28.09-.14,0-.49.12c-.19,0-.41.06-.62.09s-.31.05-.48.06l-.64,0-.53,0c-.22,0-.45,0-.67,0h-.55l-.72-.06-.54,0-.73-.1-.53-.08c-.25,0-.49-.1-.73-.15l-.48-.09-.77-.22-.35-.1a10.68,10.68,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.95c-.06.53.54,1.14,1.81,1.7a10.72,10.72,0,0,0,1,.37l.35.1.77.22.48.1.73.15.53.07c.24,0,.48.08.73.1l.54.05.72.06H205l.53,0c.22,0,.43,0,.64,0l.48-.07.54-.07.08,0c.18,0,.34-.07.49-.11l.14-.05a1.31,1.31,0,0,0,.28-.09l.14,0,.22-.1.12-.06.17-.1.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #ffbde4"/>
                      <path d="M207.83,386.08c2.52,1.09,2.4,2.41-.25,2.94a18.34,18.34,0,0,1-9.35-1c-2.52-1.09-2.41-2.41.25-2.94A18.34,18.34,0,0,1,207.83,386.08Z" transform="translate(-51.09 -206.61)" style="fill: #ffdcf3"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="123.28 192.5 120.59 216.15 94.71 205 97.4 181.34 123.28 192.5" style="fill: #dda2c8"/>
                        <polygon points="150.82 187.39 123.28 192.5 97.4 181.34 124.94 176.24 150.82 187.39" style="fill: #ffbde4"/>
                        <path d="M168.14,390.78a.94.94,0,0,0,.1-.1l.06-.07.07-.12,0-.05a1.33,1.33,0,0,0,0-.19l.33-2.94a1.21,1.21,0,0,1-.05.18l0,.06-.07.12-.06.06-.1.11-.08.06-.12.09-.16.1-.13.06-.21.09-.14.06-.29.09-.14,0c-.15.05-.31.08-.48.12s-.41.06-.62.09-.32.05-.49.06l-.63,0-.53,0-.68,0h-.55l-.72-.05-.54-.05-.72-.1c-.18,0-.36,0-.53-.08l-.73-.14-.48-.1-.77-.22-.36-.1a8.5,8.5,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.95c-.06.53.54,1.14,1.81,1.7.31.13.65.26,1,.38l.36.1c.25.07.5.15.77.21l.47.1c.25.05.49.11.74.15l.53.07.72.11.54,0,.72.06h1.23c.18,0,.35,0,.53,0s.43,0,.63,0l.49-.07.53-.07.09,0,.48-.11.14,0,.29-.09.14-.06.21-.09.13-.07.16-.1.05,0,.07,0Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M167,385.61c2.51,1.09,2.4,2.41-.25,2.94a18.37,18.37,0,0,1-9.36-1c-2.51-1.09-2.4-2.41.25-2.94A18.39,18.39,0,0,1,167,385.61Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                      <g>
                        <polygon points="150.82 187.39 148.13 211.04 120.59 216.15 123.28 192.5 150.82 187.39" style="fill: #ffd4e8"/>
                        <path d="M181.07,396.36l.1-.1.06-.07a.75.75,0,0,0,.07-.12l0-.05a1.05,1.05,0,0,0,0-.19l.33-2.94a.61.61,0,0,1-.05.18l0,.06a.75.75,0,0,1-.07.12l-.06.06-.1.11-.08.06-.12.09-.16.1-.13.06a1.36,1.36,0,0,1-.21.1l-.14.05-.28.09-.14,0-.49.11c-.19,0-.41.06-.62.09l-.48.07-.64,0-.53,0h-.68l-.55,0-.72-.05-.54,0c-.24,0-.48-.06-.72-.1l-.53-.07-.73-.15-.48-.1-.77-.22-.36-.1c-.35-.11-.69-.24-1-.37-1.27-.56-1.87-1.17-1.81-1.71l-.33,3c-.06.54.54,1.15,1.81,1.7.32.14.66.26,1,.38l.36.1c.25.07.5.15.77.21l.48.1c.24.05.48.11.73.15l.53.08.72.1.54,0,.72.06h1.23c.18,0,.35,0,.53,0s.43,0,.64,0l.48-.07.54-.07.08,0,.49-.11.14,0,.28-.09.14-.06.21-.09.13-.07.16-.1.06,0,.06-.05Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M179.9,391.19c2.51,1.09,2.4,2.41-.25,2.94a18.48,18.48,0,0,1-9.36-1c-2.51-1.1-2.4-2.42.26-2.95A18.45,18.45,0,0,1,179.9,391.19Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M181.91,388.21l.1-.1.05-.06a.75.75,0,0,0,.07-.12s0,0,0-.06a.41.41,0,0,0,.05-.18l.33-2.95a.45.45,0,0,1,0,.19.09.09,0,0,1,0,.05.75.75,0,0,1-.07.12l0,.07-.1.1a.27.27,0,0,0-.08.07l-.12.08a1,1,0,0,1-.17.1l-.12.07-.22.09-.14.06-.28.09-.14,0c-.15,0-.31.08-.49.11s-.41.06-.62.09l-.48.07c-.2,0-.42,0-.64,0s-.34,0-.52,0H177l-.72-.06-.54,0L175,386l-.53-.08c-.25,0-.49-.1-.73-.15l-.47-.1c-.27-.06-.52-.14-.78-.21l-.35-.1c-.36-.12-.7-.25-1-.38-1.27-.55-1.87-1.17-1.81-1.7L169,386.2c-.06.54.54,1.15,1.81,1.7a8.5,8.5,0,0,0,1,.38l.35.1.78.22.47.09c.24.06.48.11.73.15l.53.08.73.1.54.05.72.05h.55c.22,0,.45,0,.68,0l.53,0,.63,0c.17,0,.32,0,.48-.06s.37,0,.54-.08h.08l.49-.12.14,0,.28-.09.14-.06.22-.09.12-.07.17-.09,0,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M180.74,383c2.51,1.1,2.39,2.42-.26,2.94a18.44,18.44,0,0,1-9.35-1c-2.52-1.1-2.4-2.41.25-2.94A18.49,18.49,0,0,1,180.74,383Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                      <g>
                        <path d="M194.84,393.8l.1-.11.05-.06a.75.75,0,0,0,.07-.12l0-.06a1.21,1.21,0,0,0,.05-.18l.33-2.95a1.05,1.05,0,0,1,0,.19l0,.06-.08.11a.18.18,0,0,1,0,.07l-.1.1-.08.07L195,391l-.16.1-.13.07-.21.09-.15.06-.28.09-.14,0-.48.11c-.2,0-.42.07-.62.09l-.49.07c-.2,0-.42,0-.63,0s-.35,0-.53,0h-1.23l-.72-.06-.54,0-.72-.1-.53-.08c-.25,0-.49-.1-.74-.15l-.47-.1c-.27-.06-.52-.14-.78-.21l-.35-.1a10.68,10.68,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.94c-.06.54.54,1.15,1.81,1.7a8.5,8.5,0,0,0,1,.38l.35.1.78.22.47.1.74.14.52.08.73.1.54,0,.72.05h.55l.68,0,.53,0,.63,0c.17,0,.32,0,.49-.06l.53-.07.09,0c.17,0,.33-.07.48-.12l.14,0,.28-.09.15-.06.21-.09.13-.06.16-.1.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #b3b3b3"/>
                        <path d="M193.67,388.62c2.51,1.1,2.4,2.42-.25,2.94a18.47,18.47,0,0,1-9.36-1c-2.52-1.1-2.4-2.41.25-2.94A18.49,18.49,0,0,1,193.67,388.62Z" transform="translate(-51.09 -206.61)" style="fill: #ededed"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="124.76 179.38 123.29 192.12 97.41 180.97 98.88 168.23 124.76 179.38" style="fill: #ffbde4"/>
                        <polygon points="147.5 165.48 134.01 166.95 108.13 155.8 119.5 153.91 147.5 165.48" style="fill: #ffd7ef"/>
                        <path d="M179.41,365.06l.1-.1.05-.07.08-.12,0-.06a1.21,1.21,0,0,0,.05-.18l.33-2.94a1.21,1.21,0,0,1-.05.18l0,.06-.07.12-.06.06-.1.1a.27.27,0,0,1-.08.07l-.12.09-.16.09-.13.07-.21.09-.15.06-.28.09-.14,0-.48.12c-.2,0-.41.06-.62.09s-.32.05-.49.06l-.63,0-.53,0c-.22,0-.45,0-.68,0h-.55l-.72-.06-.54,0-.72-.1-.53-.08c-.25,0-.49-.09-.73-.15l-.48-.09-.78-.22-.35-.1a10.68,10.68,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.95c-.06.53.54,1.14,1.81,1.7a10.72,10.72,0,0,0,1,.37l.35.1c.26.08.51.16.78.22l.47.1.74.15.53.07c.24,0,.48.08.72.1l.54.05.72.06h1.23c.18,0,.35,0,.53,0s.43,0,.63,0l.49-.07.53-.07.09,0,.48-.11.14-.05a1.15,1.15,0,0,0,.28-.09l.15,0,.21-.09.13-.07.16-.1,0,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #ffbde4"/>
                        <path d="M178.24,359.89c2.51,1.09,2.4,2.41-.25,2.94a18.37,18.37,0,0,1-9.36-1c-2.51-1.09-2.4-2.41.25-2.94A18.39,18.39,0,0,1,178.24,359.89Z" transform="translate(-51.09 -206.61)" style="fill: #ffdcf3"/>
                      </g>
                      <g>
                        <path d="M192.34,370.64l.1-.1.06-.07a.75.75,0,0,0,.07-.12l0-.06a1.21,1.21,0,0,0,.05-.18l.33-2.94a1.21,1.21,0,0,1,0,.18l0,.06a.75.75,0,0,1-.07.12l-.06.06-.1.11-.08.06-.12.09-.16.09-.13.07-.21.09-.14.06-.28.09-.14,0c-.16,0-.32.08-.49.12s-.41.06-.62.09-.32.05-.49.06l-.63,0-.53,0c-.22,0-.45,0-.68,0h-.55l-.72-.05-.54-.05-.72-.1-.53-.08c-.25,0-.49-.09-.73-.15l-.48-.09-.77-.22-.36-.1a9.21,9.21,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.95c-.06.53.54,1.14,1.81,1.7.32.13.65.26,1,.38l.36.09c.25.08.5.16.77.22l.48.1c.24.05.48.11.73.15l.53.07.72.11.54,0,.72.06h1.23c.18,0,.35,0,.53,0s.43,0,.63,0l.49-.07.53-.07.09,0,.49-.11.13,0,.29-.09.14-.06.21-.09.13-.07.16-.1.06,0,.06-.05Z" transform="translate(-51.09 -206.61)" style="fill: #ffbde4"/>
                        <path d="M191.17,365.47c2.51,1.09,2.4,2.41-.25,2.94a18.37,18.37,0,0,1-9.36-1c-2.51-1.09-2.4-2.41.26-2.94A18.35,18.35,0,0,1,191.17,365.47Z" transform="translate(-51.09 -206.61)" style="fill: #ffdcf3"/>
                        <path d="M203.48,379.92l-1.56,13.71-27.54,5.1,1.6-14a13.14,13.14,0,0,1,10.67-11.45l8.13-1.51A7.4,7.4,0,0,1,203.48,379.92Z" transform="translate(-51.09 -206.61)" style="fill: #ffd4e8"/>
                      </g>
                    </g>
                    <path d="M160.8,362.19l-1.24.17s-8.27,1-9.6,12.58l25.88,11.15s.37-10.31,10.29-12.67Z" transform="translate(-51.09 -206.61)" style="fill: #ffc7ee"/>
                  </g>
                  <g>
                    <g>
                      <path d="M159.07,401.9,145,417.38l-27-.68,1.28-11.2a17.21,17.21,0,0,1,14-14.75Z" transform="translate(-51.09 -206.61)" style="fill: #ffc7ee"/>
                      <g>
                        <polygon points="93.87 210.77 92.65 221.31 66.77 210.16 67.99 199.62 93.87 210.77" style="fill: #ffbde4"/>
                        <polygon points="122.88 192.55 107.98 195.29 82.1 184.14 97 181.4 122.88 192.55" style="fill: #ffd7ef"/>
                      </g>
                      <path d="M174,399.17l-2.69,23.65-27.54,5.1,1.28-11.2A16.94,16.94,0,0,1,158.76,402Z" transform="translate(-51.09 -206.61)" style="fill: #ffd4e8"/>
                    </g>
                    <g>
                      <g>
                        <path d="M154,393.38l.1-.11.05-.06a.75.75,0,0,0,.07-.12l0-.06a.58.58,0,0,0,0-.18l.34-3a1.05,1.05,0,0,1-.05.19l0,.06a.41.41,0,0,1-.07.11.18.18,0,0,1,0,.07l-.1.1-.08.07-.12.08-.16.1-.13.07-.21.09-.15.06-.28.09-.14,0-.48.12-.62.08-.49.07-.63,0-.53,0c-.22,0-.45,0-.68,0h-.55l-.72-.06-.54,0-.72-.1-.53-.08c-.25,0-.49-.1-.74-.15l-.47-.1c-.27-.06-.52-.14-.78-.21l-.35-.1a10.68,10.68,0,0,1-1-.38c-1.27-.55-1.87-1.16-1.81-1.7l-.33,2.94c-.06.54.54,1.15,1.81,1.7a8.5,8.5,0,0,0,1,.38l.35.1.78.22.47.1.73.15.53.07c.25,0,.49.08.73.1l.54.05.72,0h.55l.68,0,.53,0,.63,0c.17,0,.32,0,.49-.06l.53-.07.09,0,.48-.11.14-.05.28-.09.15,0,.21-.1.12-.06.17-.1,0,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #ffbde4"/>
                        <path d="M152.8,388.2c2.51,1.1,2.4,2.42-.25,2.95a18.58,18.58,0,0,1-9.36-1c-2.52-1.1-2.4-2.41.25-2.94A18.49,18.49,0,0,1,152.8,388.2Z" transform="translate(-51.09 -206.61)" style="fill: #ffdcf3"/>
                      </g>
                      <g>
                        <path d="M166.9,399l.1-.11.06-.06.07-.12,0-.06a1.21,1.21,0,0,0,.05-.18l.33-2.94a1.21,1.21,0,0,1,0,.18l0,.06-.07.11-.06.07a.47.47,0,0,1-.1.1.27.27,0,0,1-.08.07l-.12.08-.16.1-.13.07-.21.09-.14.06-.29.09-.14,0-.48.12-.62.09c-.16,0-.32.05-.49.06l-.63,0-.53,0c-.22,0-.45,0-.68,0H162l-.72-.06-.54,0-.72-.1-.53-.08c-.25,0-.49-.1-.73-.15l-.48-.1c-.27-.06-.52-.14-.77-.21l-.36-.1c-.36-.12-.69-.24-1-.38-1.27-.55-1.87-1.16-1.81-1.7L154,397c-.06.53.54,1.14,1.81,1.7a10.72,10.72,0,0,0,1,.37l.36.1.77.22.47.1.74.15.53.07c.24,0,.48.08.72.1l.54.05.72.05.55,0h.68l.53,0,.63,0,.49-.07.53-.07.09,0,.48-.11.14-.05a2.46,2.46,0,0,0,.29-.09l.14-.05a1.36,1.36,0,0,0,.21-.1l.13-.06.16-.1.05,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #ffbde4"/>
                        <path d="M165.73,393.79c2.51,1.09,2.4,2.41-.25,2.94a18.37,18.37,0,0,1-9.36-1c-2.51-1.09-2.4-2.41.25-2.94A18.39,18.39,0,0,1,165.73,393.79Z" transform="translate(-51.09 -206.61)" style="fill: #ffdcf3"/>
                      </g>
                    </g>
                  </g>
                  <path d="M125.59,394.2a17.09,17.09,0,0,0-3.26,3.34" transform="translate(-51.09 -206.61)" style="fill: #ffdcf3"/>
                  <path d="M147.7,409.33a15.94,15.94,0,0,1,3.23-3.64" transform="translate(-51.09 -206.61)" style="fill: #ffdcf3"/>
                  <g>
                    <path d="M173.9,429.47a.41.41,0,0,1-.27-.1.43.43,0,0,1-.07-.6c.07-.09,1.64-2.07,3.48-1.95a3.15,3.15,0,0,1,2.45,1.77.43.43,0,1,1-.74.45,2.35,2.35,0,0,0-1.76-1.36c-1.39-.08-2.74,1.6-2.75,1.62A.42.42,0,0,1,173.9,429.47Z" transform="translate(-51.09 -206.61)"/>
                    <ellipse cx="176.41" cy="433.06" rx="2.38" ry="1.49" transform="translate(-327.27 343.99) rotate(-82.4)"/>
                    <path d="M195.77,426.6a.45.45,0,0,1-.37-.2,2.15,2.15,0,0,0-1.53-1.11,3.66,3.66,0,0,0-2.41,1.06.43.43,0,0,1-.61-.61,4.41,4.41,0,0,1,3.11-1.31,3,3,0,0,1,2.17,1.5.44.44,0,0,1-.13.6A.48.48,0,0,1,195.77,426.6Z" transform="translate(-51.09 -206.61)"/>
                    <ellipse cx="193.06" cy="430.4" rx="2.38" ry="1.49" transform="translate(-310.2 358.2) rotate(-82.4)"/>
                    <path d="M183,437.91a11.26,11.26,0,0,1-1.64-.1.44.44,0,0,1-.36-.49.43.43,0,0,1,.5-.36,20.52,20.52,0,0,0,9.33-1.44.43.43,0,0,1,.3.81A23.31,23.31,0,0,1,183,437.91Z" transform="translate(-51.09 -206.61)"/>
                    <path d="M180.3,439.6a.44.44,0,0,1-.35-.69,2.52,2.52,0,0,0,.2-3.25.44.44,0,0,1,0-.61.45.45,0,0,1,.61,0,3.38,3.38,0,0,1-.14,4.34A.44.44,0,0,1,180.3,439.6Z" transform="translate(-51.09 -206.61)"/>
                    <path d="M183.78,439.8a1,1,0,0,1-.69-.27,1.09,1.09,0,0,1-.29-.91l.16-.91a.44.44,0,0,1,.5-.35.42.42,0,0,1,.35.5l-.15.89a.28.28,0,0,0,0,.16.23.23,0,0,0,.16,0l4-.57c.21,0,.33-.36.35-.58v0l.14-.93a.43.43,0,1,1,.85.12l-.13.92a1.43,1.43,0,0,1-1.08,1.36l-4,.56Z" transform="translate(-51.09 -206.61)"/>
                  </g>
                  <path d="M109.13,451.9,134,462.76s-9.45-7.92-5.59-23.16l-27.21-12.34s-5.16,9.44-.47,17.67A16.2,16.2,0,0,0,109.13,451.9Z" transform="translate(-51.09 -206.61)" style="fill: #ffbde4"/>
                  <path d="M101.2,427.43a19.66,19.66,0,0,1,15.49-10.2l26.46,11.46s-11-.8-14.7,10.91Z" transform="translate(-51.09 -206.61)" style="fill: #ffc7ee"/>
                  <g>
                    <path d="M107.75,420.47l0,0a23.25,23.25,0,0,0-3.75,3.14l0,0A20.81,20.81,0,0,1,107.75,420.47Z" transform="translate(-51.09 -206.61)" style="fill: #d7d7d7"/>
                    <path d="M116.69,417.23a21.89,21.89,0,0,0-8.94,3.24,17.36,17.36,0,0,0-3.75,3.14c-2.69,3.2-2.8,3.82-2.8,3.82l14.92,1.78,10.46-.2,16.57-.32Z" transform="translate(-51.09 -206.61)" style="fill: #ffc7ee"/>
                  </g>
                  <path d="M143.32,428.68l-2.49.13c-7.93.71-14.51,8.42-15.4,16.33l-.23,2c-1,9.15,5.55,16,14.12,17.07Z" transform="translate(-51.09 -206.61)" style="fill: #ffd4e8"/>
                  <g>
                    <path d="M119,447.6a9.82,9.82,0,0,0,.76-8.88,3.17,3.17,0,0,0-2.44-2.08l2.4.2a3.17,3.17,0,0,1,2.44,2.08,9.8,9.8,0,0,1-.76,8.87,3.11,3.11,0,0,1-2.71,1.61l-2.4-.2A3.12,3.12,0,0,0,119,447.6Z" transform="translate(-51.09 -206.61)" style="fill: #ffcff5"/>
                    <path d="M113.82,447.13c1.21,2.58,3.51,2.79,5.14.47a9.82,9.82,0,0,0,.76-8.88c-1.22-2.59-3.52-2.79-5.14-.47A9.82,9.82,0,0,0,113.82,447.13Z" transform="translate(-51.09 -206.61)" style="fill: #ffb3e0"/>
                  </g>
                  <g>
                    <path d="M106.66,442.15a9.82,9.82,0,0,0,.76-8.88,3.17,3.17,0,0,0-2.43-2.08l2.4.2a3.16,3.16,0,0,1,2.43,2.07,9.82,9.82,0,0,1-.76,8.88,3.11,3.11,0,0,1-2.71,1.61l-2.4-.2A3.12,3.12,0,0,0,106.66,442.15Z" transform="translate(-51.09 -206.61)" style="fill: #ffcff5"/>
                    <path d="M101.52,441.68c1.21,2.58,3.52,2.79,5.14.47a9.82,9.82,0,0,0,.76-8.88c-1.21-2.59-3.51-2.79-5.14-.47A9.82,9.82,0,0,0,101.52,441.68Z" transform="translate(-51.09 -206.61)" style="fill: #ffb3e0"/>
                  </g>
                </g>
                <g>
                  <path d="M146.51,576.49a.66.66,0,0,1-.78-1c7.39-10.2,13.93-21.47,12.24-22.78s-11.05,7.75-19.17,17.39a.67.67,0,0,1-1.15-.61c3.39-12.07,5.6-24.76,3.58-25.41-1.85-.6-6.71,9.08-10.81,19.75a.66.66,0,0,1-1.28-.17c-.57-11.6-2.17-22.81-4.1-24.17a.58.58,0,0,0-.19-.1h0l-.18,0-.18,0h0a.54.54,0,0,0-.18.1c-1.93,1.36-3.53,12.57-4.1,24.17a.66.66,0,0,1-1.28.17c-4.1-10.67-9-20.35-10.81-19.75-2,.65.19,13.34,3.57,25.41a.67.67,0,0,1-1.15.61c-8.12-9.64-17.48-18.71-19.17-17.39s4.86,12.58,12.25,22.78a.67.67,0,0,1-.79,1c-5.3-2-9.67-3.08-10.3-1.92-1.08,2,9,9.57,17.93,14.42a51.59,51.59,0,0,0,6.6,3.11c1.08.41,2.26.79,3.45,1.11a1.78,1.78,0,0,1,1.3,1.72v2a1.37,1.37,0,0,1-.49,1.07,4.78,4.78,0,0,0-2,3.78c0,.59.43.93,1,1.06a1.3,1.3,0,0,0,1.58-1.35c0-.8.24-1,.9-1.47a2.81,2.81,0,0,1,4.61,2.08,1.34,1.34,0,0,0,1,1.31A1.31,1.31,0,0,0,130,602.1a5.37,5.37,0,0,0-2-4.15,1.37,1.37,0,0,1-.5-1.07V595a1.79,1.79,0,0,1,1.27-1.7c1.07-.32,2.12-.71,3.09-1.06a51.72,51.72,0,0,0,7-3.26c8.88-4.85,19-12.45,17.93-14.42C156.19,573.41,151.81,574.45,146.51,576.49Z" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                  <circle cx="75.17" cy="395.66" r="4.11" style="fill: #593735"/>
                  <polygon points="71.9 395.61 69.58 396.2 69.21 394.68 70.38 393.25 71.9 395.61" style="fill: #82b378"/>
                  <path d="M94.36,575.08" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                  <polygon points="43.48 366.82 41.6 367.72 43.48 369.51 43.48 366.82" style="fill: #82b378"/>
                  <polygon points="42.33 344.94 40.23 346.08 42.06 348.49 43.75 347.6 42.33 344.94" style="fill: #82b378"/>
                  <polygon points="56.98 337.45 59.07 336.31 58.65 339 56.98 337.45" style="fill: #82b378"/>
                  <polygon points="73.56 332.73 75.26 331.73 74.95 334.4 73.19 333.73 73.38 332.76 73.56 332.73" style="fill: #82b378"/>
                  <polygon points="89.84 337.44 91.65 336.4 90.19 339.14 89.84 337.44" style="fill: #82b378"/>
                  <path d="M148.61,575.36a.67.67,0,0,1-.79-1c7.39-10.2,13.93-21.46,12.25-22.78S149,559.31,140.9,569a.67.67,0,0,1-1.15-.61c3.38-12.06,5.6-24.76,3.57-25.41-1.85-.6-6.71,9.08-10.81,19.75a.66.66,0,0,1-1.28-.17c-.57-11.59-2.17-22.81-4.1-24.17l-.18-.1h0l-.18,0-.18,0h0l-.19.1c-1.93,1.36-3.53,12.58-4.1,24.17a.66.66,0,0,1-1.28.17c-4.1-10.67-9-20.35-10.81-19.75-2,.65.19,13.35,3.58,25.41a.67.67,0,0,1-1.15.61c-8.12-9.64-17.49-18.71-19.17-17.39s4.85,12.58,12.24,22.78a.67.67,0,0,1-.78,1c-5.3-2.05-9.68-3.09-10.31-1.93-1.08,2,9.05,9.58,17.93,14.42a51.59,51.59,0,0,0,6.6,3.11c1.08.41,2.26.8,3.45,1.12a1.77,1.77,0,0,1,1.31,1.71v2a1.38,1.38,0,0,1-.5,1.07,5.31,5.31,0,0,0-2,4.06,1.4,1.4,0,0,0,1,1.34,1.29,1.29,0,0,0,1.58-1.34,2.32,2.32,0,0,1,.9-2,2.82,2.82,0,0,1,4.62,2.09,1.34,1.34,0,0,0,1,1.3,1.29,1.29,0,0,0,1.6-1.26,5.33,5.33,0,0,0-2-4.14,1.37,1.37,0,0,1-.49-1.07v-1.87a1.78,1.78,0,0,1,1.27-1.71c1.07-.32,2.11-.71,3.09-1.06a51.6,51.6,0,0,0,7-3.26c8.89-4.84,19-12.44,17.93-14.42C158.28,572.27,153.91,573.31,148.61,575.36Z" transform="translate(-51.09 -206.61)" style="fill: #a8d39e"/>
                </g>
                <path d="M130.12,581.94H130a.28.28,0,0,1-.17-.37l9.54-26.84a.29.29,0,1,1,.54.19l-9.54,26.84A.28.28,0,0,1,130.12,581.94Z" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                <path d="M132.73,584.77a.3.3,0,0,1-.19-.07.28.28,0,0,1,0-.4l19.77-23a.29.29,0,0,1,.44.38l-19.77,23A.28.28,0,0,1,132.73,584.77Z" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                <path d="M134,587.71a.29.29,0,0,1-.25-.15.28.28,0,0,1,.11-.39l18.4-10.48a.29.29,0,0,1,.28.51l-18.39,10.47A.28.28,0,0,1,134,587.71Z" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                <path d="M126.74,581.94a.28.28,0,0,1-.28-.29V551.37a.28.28,0,0,1,.28-.29.29.29,0,0,1,.29.29v30.28A.29.29,0,0,1,126.74,581.94Z" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                <path d="M123.51,582.36a.28.28,0,0,1-.27-.19l-9.69-27.26a.29.29,0,0,1,.55-.19L123.79,582a.29.29,0,0,1-.18.37Z" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                <path d="M120.75,584.77a.28.28,0,0,1-.22-.1l-19.76-23a.29.29,0,0,1,0-.41.28.28,0,0,1,.4,0l19.77,23a.28.28,0,0,1,0,.4A.28.28,0,0,1,120.75,584.77Z" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                <path d="M119.47,587.71a.23.23,0,0,1-.14,0L100.64,577.2a.29.29,0,1,1,.29-.51l18.68,10.48a.28.28,0,0,1,.11.39A.27.27,0,0,1,119.47,587.71Z" transform="translate(-51.09 -206.61)" style="fill: #82b378"/>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon points="125.97 490.8 125.93 497.22 72 480.82 72.03 474.39 125.97 490.8" style="fill: #628c58"/>
                        <polygon points="152.76 482.65 125.97 490.8 72.03 474.39 98.82 466.25 152.76 482.65" style="fill: #a8d39e"/>
                        <path d="M143,681.62l.09-.11a.35.35,0,0,0,0-.08l.06-.12a.14.14,0,0,1,0-.06.68.68,0,0,0,0-.19v-3a.57.57,0,0,1,0,.18.25.25,0,0,0,0,.07l-.06.12,0,.07a.94.94,0,0,1-.09.12l-.07.07-.11.1a.83.83,0,0,1-.15.11l-.12.09-.2.11-.14.07-.26.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16l-.47.12-.63.1c-.17,0-.34.07-.52.09s-.45,0-.68.06l-.54,0c-.24,0-.48,0-.73,0h-.54l-.73,0h-.53l-.75-.07-.48,0-.79-.13-.37-.06a9.87,9.87,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.53,10.53,0,0,0,1,.26c.12,0,.25,0,.37.06s.52.1.79.13l.48,0c.25,0,.5.06.75.07l.53,0,.73,0,.55,0,.72,0,.55-.05.67-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.47-.16.14-.06.27-.12.13-.08.2-.11.12-.08.15-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M141.22,676.61c2.62.81,2.65,2.13.08,2.95a18.52,18.52,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.41,18.41,0,0,1,141.22,676.61Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M156.44,685.72l.08-.11,0-.07a1.39,1.39,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.18.18,0,0,1,0,.07l-.08.12-.07.07-.11.1-.16.12-.11.08-.2.11-.14.07a2.24,2.24,0,0,1-.27.13l-.13,0-.47.17c-.19.06-.41.11-.61.16l-.47.12-.63.1c-.18,0-.34.07-.52.09s-.45,0-.68.06l-.55.06-.72,0-.54,0-.73,0-.54,0-.74-.07-.49,0-.79-.13-.36-.06a10.07,10.07,0,0,1-1-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1,.26c.12,0,.24,0,.36.06s.52.1.8.13l.48,0c.25,0,.49.05.74.06l.54,0,.73,0,.54,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.16,0,.31-.08.47-.12s.36-.08.52-.13l.09,0,.47-.16.13-.06.27-.12.14-.08.2-.11.11-.08.16-.12.05,0,.06-.07Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M154.7,680.71c2.61.81,2.65,2.13.08,2.95a18.56,18.56,0,0,1-9.42,0c-2.62-.81-2.65-2.13-.07-3A18.41,18.41,0,0,1,154.7,680.71Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M156.36,677.53a.76.76,0,0,0,.08-.11l.05-.07a.75.75,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06.67.67,0,0,1-.06.12.35.35,0,0,1-.05.08l-.08.11-.08.07-.1.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.16c-.18.06-.4.11-.6.16s-.31.09-.48.12l-.62.11-.53.08-.67.07-.55,0-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.36-.06a10.07,10.07,0,0,1-1.05-.26c-1.32-.41-2-1-2-1.49v3c0,.54.67,1.07,2,1.48q.5.15,1.05.27l.36,0c.26.05.52.1.79.13l.49.05c.24,0,.49,0,.74.06l.54,0,.73,0,.54,0,.72,0,.55,0,.67-.07.53-.08c.21,0,.42-.06.62-.11s.32-.08.48-.12l.52-.13.08,0,.48-.17.13-.06.27-.12.13-.07.21-.12.11-.08.16-.12,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M154.62,672.52c2.61.81,2.65,2.13.07,3a18.3,18.3,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-3A18.45,18.45,0,0,1,154.62,672.52Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M169.83,681.63l.09-.11.05-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0-.05.07l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.72,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48-.05c-.28,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.48.32.1.67.19,1,.27.12,0,.25,0,.37.06s.51.09.79.12l.48,0c.25,0,.49.05.75.06s.35,0,.53,0l.73,0,.54,0c.25,0,.49,0,.73,0l.54,0,.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.32-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M168.09,676.62c2.62.81,2.65,2.13.08,3a18.3,18.3,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-3A18.52,18.52,0,0,1,168.09,676.62Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path d="M169.93,689.83l.09-.11,0-.07a.69.69,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16c-.19.06-.4.11-.61.16s-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.53,0c-.26,0-.5,0-.75-.06l-.48,0c-.28,0-.53-.08-.79-.13s-.25,0-.37-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.48.32.1.67.19,1,.27.12,0,.25,0,.37.06s.51.09.79.12l.48,0,.75.07h.53l.73,0,.54,0c.25,0,.49,0,.73,0l.54-.05.68-.06.52-.09a6.37,6.37,0,0,0,.63-.11c.17,0,.31-.08.47-.12l.53-.13.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M168.19,684.82c2.62.81,2.65,2.13.08,2.95a18.3,18.3,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-2.95A18.52,18.52,0,0,1,168.19,684.82Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <polygon points="152.76 482.65 152.73 489.07 125.93 497.22 125.97 490.8 152.76 482.65" style="fill: #82b378"/>
                        <path d="M183.41,693.93a.44.44,0,0,0,.08-.11l0-.07a.52.52,0,0,0,.06-.13.14.14,0,0,0,0-.06.58.58,0,0,0,0-.19v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06,1.39,1.39,0,0,1-.06.13l0,.07a.76.76,0,0,1-.08.11l-.08.08-.11.09-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.6.15s-.31.09-.48.12l-.62.11-.53.08-.67.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36-.05q-.56-.12-1-.27c-1.32-.41-2-.95-2-1.48v3c0,.54.67,1.08,2,1.48a10,10,0,0,0,1,.27l.36.06c.26,0,.52.09.79.12l.49.05.74.07h.54l.73,0h.54c.24,0,.48,0,.72,0l.55,0,.67-.06.53-.09.62-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.11,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M181.66,688.92c2.62.81,2.66,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.13-.08-2.95A18.52,18.52,0,0,1,181.66,688.92Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M183.32,685.75a.47.47,0,0,0,.09-.12l.05-.07.06-.12a.19.19,0,0,0,0-.07.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07a.48.48,0,0,1-.09.11l-.07.08-.11.1-.15.11a.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.16l-.47.11a5.89,5.89,0,0,1-.63.11l-.52.09-.67.06-.55,0c-.24,0-.48,0-.72,0l-.54,0-.74,0h-.53l-.74-.07-.49-.05c-.27,0-.53-.08-.79-.12l-.36-.06q-.56-.12-1.05-.27c-1.33-.4-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49.33.1.68.18,1.05.26l.36.06.79.13.49,0,.74.07h.53l.74,0h.54l.72,0,.55-.05c.22,0,.45,0,.67-.06s.35-.06.53-.09l.62-.1.48-.12c.17,0,.36-.08.52-.13l.08,0a4.24,4.24,0,0,0,.47-.17l.14-.06.27-.12.13-.07.2-.12A.5.5,0,0,0,183,686a.58.58,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M181.58,680.74c2.62.8,2.66,2.13.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.8-2.66-2.12-.08-3A18.41,18.41,0,0,1,181.58,680.74Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                      <g>
                        <path d="M196.8,689.85l.09-.12.05-.07a.61.61,0,0,0,.05-.12l0-.07a1.13,1.13,0,0,0,0-.18v-3a1.11,1.11,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13l0,.07-.09.11-.07.08-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06-.47.17c-.19.06-.41.1-.61.16l-.47.12-.63.1-.52.09-.68.06-.55,0-.72,0h-.54l-.73,0h-.54l-.74-.07-.48,0c-.28,0-.54-.08-.8-.12l-.36-.06a9.25,9.25,0,0,1-1-.27c-1.33-.4-2-.94-2-1.48v3c0,.54.66,1.08,2,1.49a9.29,9.29,0,0,0,1,.26l.36.06.8.13.48,0,.74.07h.54l.73,0H191c.24,0,.49,0,.72,0l.55-.05c.23,0,.46,0,.68-.06s.35-.06.52-.09l.63-.1.47-.12.53-.13.08,0c.17,0,.32-.11.47-.17l.13-.06a1.11,1.11,0,0,0,.27-.12l.14-.07.2-.11.12-.08.15-.12,0,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                        <path d="M195.06,684.84c2.62.8,2.65,2.13.08,2.95a18.41,18.41,0,0,1-9.41,0c-2.63-.8-2.66-2.12-.08-2.95A18.41,18.41,0,0,1,195.06,684.84Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="273.13 504.01 260.49 507.64 235.01 499.74 249.71 496.32 273.13 504.01" style="fill: #a8d39e"/>
                    <polygon points="260.49 507.64 260.45 513.49 234.97 505.58 235.01 499.74 260.49 507.64" style="fill: #628c58"/>
                  </g>
                  <g>
                    <g>
                      <path d="M304.45,706.67l.09-.11,0-.07a.5.5,0,0,0,.05-.13l0-.06a.58.58,0,0,0,0-.19v-3a1.25,1.25,0,0,1,0,.19l0,.06a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.11-.07.07-.11.1-.15.12-.12.08-.2.12-.14.07-.27.12-.13.06-.47.16a5.43,5.43,0,0,1-.61.16c-.16,0-.31.09-.47.12l-.63.11-.52.08-.68.07-.55.05-.72,0-.54,0-.73,0-.54,0c-.25,0-.49,0-.74-.06l-.48,0c-.28,0-.54-.08-.8-.13l-.36-.06a9.29,9.29,0,0,1-1-.26c-1.33-.41-2-.95-2-1.49v3c0,.54.66,1.08,2,1.48.32.1.67.19,1,.27l.36.06c.27,0,.52.09.8.12l.48.05.75.07h.53l.73,0h.54c.24,0,.49,0,.72,0l.55,0,.68-.06.52-.09.63-.1.47-.12.53-.14.08,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.11a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                      <path d="M302.71,701.66c2.62.81,2.65,2.13.08,3a18.3,18.3,0,0,1-9.41,0c-2.63-.81-2.66-2.13-.08-2.95A18.52,18.52,0,0,1,302.71,701.66Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                    </g>
                    <g>
                      <polygon points="273.13 504.01 273.1 509.86 260.45 513.49 260.49 507.64 273.13 504.01" style="fill: #82b378"/>
                      <path d="M317.93,710.77a.44.44,0,0,0,.08-.11l.05-.07.06-.13a.14.14,0,0,0,0-.06.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19s0,0,0,.06l-.06.13-.05.07-.09.11-.07.08-.11.09-.15.12-.12.08-.2.12-.13.07-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.3.09-.47.12a5.89,5.89,0,0,1-.63.11l-.52.08-.67.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.25,0-.5,0-.74-.06l-.49,0c-.27,0-.53-.08-.79-.13l-.36,0q-.56-.12-1-.27c-1.32-.41-2-.94-2-1.48v3c0,.54.67,1.08,2,1.49.33.1.68.18,1,.26l.36.06.79.13.49,0,.74.07h.53l.74,0h.54l.72,0,.55,0c.22,0,.45,0,.67-.06l.53-.09.62-.1.48-.12.52-.14.08,0,.47-.17.14-.06.27-.12.13-.07.2-.12a.5.5,0,0,0,.12-.08,1,1,0,0,0,.15-.11l.05,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #628c58"/>
                      <path d="M316.18,705.77c2.62.8,2.66,2.12.08,3a18.41,18.41,0,0,1-9.41,0c-2.62-.81-2.66-2.12-.08-3A18.41,18.41,0,0,1,316.18,705.77Z" transform="translate(-51.09 -206.61)" style="fill: #b6ddab"/>
                    </g>
                  </g>
                </g>
                <g>
                  <path d="M319.27,544.65l-4.7-18.47s-.56-8.72,8.6-9.74,14.61,12.66,8.21,19.64" transform="translate(-51.09 -206.61)" style="fill: #508b9b"/>
                  <path d="M311.1,566.44l-7.59-29.85s-3-8.78,6.12-9.8,15.48,9.47,9.56,17.55l4,15.84,2.49.38,2.37,9.29Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <polygon points="268.1 337.73 272.13 353.56 284.33 345.35 280.29 329.46 268.1 337.73" style="fill: #3b6a7a"/>
                  <polygon points="274.61 353.95 286.69 345.76 289.05 355.05 276.97 363.23 274.61 353.95" style="fill: #3b6a7a"/>
                  <polygon points="272.13 353.56 274.62 353.95 286.69 345.76 284.33 345.35 272.13 353.56" style="fill: #508b9b"/>
                  <polygon points="249.01 327.88 280.92 306.38 282.89 313.88 250.95 335.26 249.01 327.88" style="fill: #3b6a7a"/>
                  <g>
                    <path d="M332.12,482.35a8,8,0,0,1-8.38-1.79c-4.05-3.71-11.77-9.61-15.39-3.64s.32,13.43,2.69,16.42,11.75,8.23,15.51,7.88,11-5.32,11-5.32l-.85-2.11a6,6,0,0,1-6.67-4.45c-1.69-5.32,2.91-4.86,2.91-4.86Z" transform="translate(-51.09 -206.61)" style="fill: #8e8e8e"/>
                    <path d="M333,484.48s-4.4-.74-2.91,4.86a5.62,5.62,0,0,0,6.67,4.45Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                    <path d="M338.07,495.82c-5.23,1.07-8.92-1.3-10.11-6.48-.54-2.33-.27-4.15.81-5.41a5.38,5.38,0,0,1,4.17-1.64l-.15,2.22a3.14,3.14,0,0,0-2.33.87c-.59.7-.71,1.86-.34,3.46.93,4,3.46,5.63,7.51,4.8Z" transform="translate(-51.09 -206.61)" style="fill: #e6e6e8"/>
                    <path d="M350.11,486.78s-2.5-1.77-6.91,1l-5.91,2.51-4.7-10.92a9.25,9.25,0,0,1,5.88-5.16c4.57-1.47,7.43.48,8.72,2.39S350.11,486.78,350.11,486.78Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                    <path d="M331.38,481.2l6,14.87s5.81.9,6.79-5.87l-3-7.47S335.08,485.58,331.38,481.2Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                    <rect x="348.06" y="476.21" width="2.22" height="10.54" transform="translate(-206 -40.81) rotate(-21.99)" style="fill: #f7e1b9"/>
                    <path d="M338.92,483.9a6.86,6.86,0,0,1-4.31.46,5,5,0,0,1-3.23-3.16,4.39,4.39,0,0,1,.9-4.57,10.82,10.82,0,0,1,4.26-2.89c4.46-1.8,10.07-1.56,11.68,2.44l-2.06.83c-1-2.56-5.28-2.63-8.79-1.21a8.71,8.71,0,0,0-3.38,2.25,2.09,2.09,0,0,0-.55,2.31,2.83,2.83,0,0,0,1.77,1.86c1.7.48,4.09-.89,4.86-1.46l1.09,2A10.53,10.53,0,0,1,338.92,483.9Z" transform="translate(-51.09 -206.61)" style="fill: #f2ca8b"/>
                  </g>
                  <polygon points="232.43 324.7 234.07 330.92 250.95 335.26 249.01 327.88 232.43 324.7" style="fill: #162d33"/>
                  <polygon points="231.67 324.63 226.02 282.06 243.51 284.43 249.01 327.88 231.67 324.63" style="fill: #494947"/>
                  <polygon points="243.51 284.43 267.75 268.43 282.19 305.62 249.01 327.88 243.51 284.43" style="fill: #b2b2b1"/>
                  <polygon points="226.02 282.06 252.13 265.59 267.75 268.43 243.51 284.43 226.02 282.06" style="fill: #7c7c7c"/>
                  <path d="M282.23,484.27" transform="translate(-51.09 -206.61)" style="fill: #323232"/>
                  <path d="M286.26,482l.65,2.46s1.53,2.87,10.59.49,9.28-5.72,9.28-5.72l-1-4Z" transform="translate(-51.09 -206.61)" style="fill: #f2ca8b"/>
                  <path d="M276.7,472.19l1.72,6.55s3,6.66,18.15,2.63,14.44-9.39,14-11.1l-4.44-16.86-30.79,8.11Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                  <path d="M307.27,457.76s-3.54,5.49-12.19,6.28-4.25.41-2.91,5.68-.33,8.94.91,9.85,5.09,1.39,14-2.54c0,0-6.36,5.05-20.61,5.75-5,.25-8.23-2.24-9-6.23-1.23-6.11-1.68-11.27-1.68-11.27l7.47-11.07S305.24,447.78,307.27,457.76Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                  <g>
                    <circle cx="245.16" cy="260.62" r="2.07" style="fill: #162d33"/>
                    <ellipse cx="296.18" cy="466.63" rx="0.62" ry="0.7" transform="translate(-156.94 -119.24) rotate(-14.27)" style="fill: #fef8f5"/>
                    <path d="M306.43,462.86c.29,1-.13,2.35-1,2.59s-1.29-.4-1.58-1.4c-.4-1.37.15-2.39,1-2.63S306.13,461.86,306.43,462.86Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <ellipse cx="305.08" cy="462.94" rx="0.48" ry="0.63" transform="translate(-169.22 -101.84) rotate(-16.37)" style="fill: #fef8f5"/>
                  </g>
                  <g>
                    <path d="M256.82,507.27s-10.31.49-11.85,4.31l3.37,13.25a40.09,40.09,0,0,1,9-3.67c3.93-.85,6.93-.9,7.2.17l-2.3-9Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                    <path d="M256.21,509.59,254.28,511a4.84,4.84,0,0,0,1.14,4.5c1.13,1.23,2.65,2.4,4.57,1.74a10.57,10.57,0,0,0,2.34-1.76C265.07,512.6,256.21,509.59,256.21,509.59Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                    <path d="M283.28,494.61c5.16,3.42,9.45,10.91,9.05,14.75s-4.63,5.35-11.52,3.66S270,510.31,268,511.59s-5.68,3.92-5.68,3.92-3.6,1.27-6.3-3.91,1.18-8,1.18-8l7.94-8.66a11.66,11.66,0,0,1,7.57-1.93C276.79,493.55,281.84,493.66,283.28,494.61Z" transform="translate(-51.09 -206.61)" style="fill: #8e8e8e"/>
                    <path d="M264.51,521.33c-.27-1.06-2.69-1.35-6.89-.34-5.33,1.29-8.36,2.87-9.14,3.5-.24.19-.19.32-.06.49a1.85,1.85,0,0,0,1.56.53,11.51,11.51,0,0,0,3.73-.87s-4.92.31,2.64-1.85a14.85,14.85,0,0,1,4.51-.8c2.39,0-2.53,1.48-3.68,1.7l1,1C265.29,523.55,264.51,521.33,264.51,521.33Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                    <path d="M253.71,524.64l-.24-.94s-1.41.42-1.39.75S253.71,524.64,253.71,524.64Z" transform="translate(-51.09 -206.61)" style="fill: #ce995b"/>
                    <path d="M257.18,523.69l-.27-1.05s4.44-1.14,4.59-.54S257.18,523.69,257.18,523.69Z" transform="translate(-51.09 -206.61)" style="fill: #ce995b"/>
                  </g>
                  <path d="M272.87,459.79c-1.05,4.43,5.52,8.31,10,7.59,6.57-1,18.67-3.06,25.84-7.83,2.86-1.9,2.81-4.12,3.45-7,.46-2.11,2.25-4.05,1.4-5.51-2.13-3.69-7.68-2.15-11.64-.45-5.08,2.18-10,1.24-14.89,3.94-4.24,2.35-9.92,5.39-11.74,9.93C275.19,460.82,273,459.46,272.87,459.79Z" transform="translate(-51.09 -206.61)" style="fill: #3b6a7a"/>
                  <path d="M274.28,561l16.14-22.2s6.69-7.39,13.76,5.64c5.32,9.8-1.32,13.38-1.32,13.38s-3.93,2.23-7.1.64l-7,8,2.36,3.29-5.46,5.58Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <path d="M316.69,532.07l-14.57,9.23s7.54,11.58.74,16.55l16-10.59S325.7,539.37,316.69,532.07Z" transform="translate(-51.09 -206.61)" style="fill: #508b9b"/>
                  <polygon points="240.11 363.23 255.03 353.84 252.96 350.45 237.71 359.82 240.11 363.23" style="fill: #508b9b"/>
                  <polygon points="234.61 368.69 240.07 363.12 255.03 353.84 248.64 360.4 234.61 368.69" style="fill: #3b6a7a"/>
                  <path d="M288.8,566.44l7-8s3.84,1.18,6.89-.56Z" transform="translate(-51.09 -206.61)" style="fill: #3b6a7a"/>
                  <path d="M274,460.33c-1.06,4.43,3.39,6.61,7.9,5.9,6.58-1.05,18.67-3.07,25.85-7.84,2.85-1.9,2.8-4.11,3.44-7,.47-2.11,2.26-4.05,1.41-5.51-2.14-3.69-7.68-2.14-11.64-.45-5.09,2.19-10,1.24-14.9,3.94-4.23,2.35-9.92,5.39-11.74,9.93A6.69,6.69,0,0,0,274,460.33Z" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                  <path d="M291.54,463.78c3.21-1.38,7.63-3.18,9.48-5.23,3.18-3.54,2.45-5.35,3.49-7.91.86-2.16,2.69-4.18,1.54-5.88-4.59-6.8-16.48-7.89-24.77-3.87-6.26,3-10.06,9.51-9.41,15.8C272.46,462.48,273.86,471.36,291.54,463.78Z" transform="translate(-51.09 -206.61)" style="fill: #3b6a7a"/>
                  <path d="M290.14,463.09a.43.43,0,0,1-.17-.83l.63-.27c3.13-1.34,7-3,8.7-4.85a9.9,9.9,0,0,0,2.76-5.46,12.5,12.5,0,0,1,.65-2.33,18.56,18.56,0,0,1,.84-1.74,12.46,12.46,0,0,0,.88-1.88.42.42,0,0,1,.54-.28.43.43,0,0,1,.28.54,12.91,12.91,0,0,1-.93,2,15.88,15.88,0,0,0-.81,1.66,11.79,11.79,0,0,0-.6,2.17,10.8,10.8,0,0,1-3,5.87c-1.79,2-5.79,3.69-9,5.07l-.62.26A.38.38,0,0,1,290.14,463.09Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                </g>
                <g>
                  <g>
                    <path d="M389.81,470.35s-2.59,5.12,1.41,11.13l-.47,18.59-3.25-.94-1.23,10,14.08,5.18,2.17-33.66-1.83-9Z" transform="translate(-51.09 -206.61)" style="fill: #243962"/>
                    <polygon points="336.41 292.51 316.65 292.24 315.4 302 335.18 302.48 336.41 292.51" style="fill: #2b478b"/>
                    <polygon points="349.26 307.65 335.18 302.48 315.4 302 329.31 306.61 349.26 307.65" style="fill: #36599e"/>
                    <polygon points="342.06 306.19 334.85 303.86 324.36 303.56 330.18 305.44 342.06 306.19" style="fill: #243962"/>
                    <polygon points="340.13 274.87 324.39 274.75 323.45 292.33 336.41 292.51 339.66 293.45 340.13 274.87" style="fill: #4470b7"/>
                    <path d="M391.22,481.48s-3.77-5.74-1.41-11.13l-16.49.45a10.68,10.68,0,0,0,2.16,10.56Z" transform="translate(-51.09 -206.61)" style="fill: #36599e"/>
                  </g>
                  <g>
                    <polygon points="356.06 256.68 317.58 257.08 320.21 264.37 358.64 263.87 356.06 256.68" style="fill: #2b478b"/>
                    <g>
                      <path d="M351.21,438.51a8,8,0,0,0,5.88-6.23c1.23-5.35,4.25-14.59,10.61-11.72s7.36,11.24,7.1,15-5,13.44-8.31,15.29-12.11,1.88-12.11,1.88l-.49-2.22a6,6,0,0,0,3-7.44c-1.63-5.35-5.16-2.36-5.16-2.36Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                      <path d="M351.7,440.75s3.21-3.11,5.16,2.36a5.61,5.61,0,0,1-3,7.44Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                      <path d="M353.94,453c4.91-2.09,6.61-6.13,4.66-11.08-.88-2.22-2.14-3.56-3.74-4a5.41,5.41,0,0,0-4.36,1l1.38,1.75a3.13,3.13,0,0,1,2.41-.61c.89.24,1.64,1.14,2.24,2.66,1.51,3.83.35,6.6-3.46,8.21Z" transform="translate(-51.09 -206.61)" style="fill: #ffc0f0"/>
                      <path d="M338.9,452.36s1.05-2.88,6.25-3.1l6.29-1.29-2.32-11.66a9.25,9.25,0,0,0-7.76-.92c-4.61,1.39-5.85,4.62-5.84,6.92S338.9,452.36,338.9,452.36Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                      <path d="M351.16,437.14l3.48,15.65s-4.27,4-8.92-1L344,444S350.59,442.85,351.16,437.14Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                      <rect x="335.56" y="442.19" width="2.22" height="10.54" transform="translate(711.46 604.42) rotate(167.45)" style="fill: #f2ca8b"/>
                      <path d="M346.48,443.64a6.78,6.78,0,0,0,3.81-2.07,4.94,4.94,0,0,0,.87-4.43,4.4,4.4,0,0,0-3.33-3.25,10.92,10.92,0,0,0-5.15,0c-4.7,1.05-9.18,4.43-8.24,8.64l2.17-.49c-.6-2.69,2.86-5.16,6.55-6a8.74,8.74,0,0,1,4.06-.06c1.51.42,1.7,1.3,1.77,1.59a2.79,2.79,0,0,1-.41,2.53c-1.12,1.36-3.87,1.59-4.83,1.56L344,444A11,11,0,0,0,346.48,443.64Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                    </g>
                    <polygon points="367.91 244.66 370.09 250.72 358.64 263.87 356.06 256.68 367.91 244.66" style="fill: #243962"/>
                    <polygon points="368.5 244.18 349.01 205.91 335.95 217.77 356.06 256.68 368.5 244.18" style="fill: #d360ad"/>
                    <polygon points="335.95 217.77 306.91 218.35 316.1 257.17 356.06 256.68 335.95 217.77" style="fill: #ffc0f0"/>
                    <polygon points="349.01 205.91 318.17 207.15 306.91 218.35 335.95 217.77 349.01 205.91" style="fill: #ffdef6"/>
                    <path d="M393.38,411.8" transform="translate(-51.09 -206.61)" style="fill: #323232"/>
                    <path d="M388.75,412.19l.86,2.39s.38,3.23-8.44,6.4-10.89.56-10.89.56l-1.39-3.87Z" transform="translate(-51.09 -206.61)" style="fill: #f2ca8b"/>
                    <path d="M391.09,398.71l2.29,6.37s1.32,7.18-13.45,12.46-17.22.46-17.82-1.2l-5.91-16.42,30-10.77Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                    <path d="M368.94,415.79a4.33,4.33,0,0,1-2.36-.78.58.58,0,0,1-.18-.8.59.59,0,0,1,.8-.17,2.88,2.88,0,0,0,2.29.53,3.49,3.49,0,0,0,1.89-1.54.57.57,0,0,1,.78-.23.57.57,0,0,1,.23.78,4.53,4.53,0,0,1-2.6,2.1A3.48,3.48,0,0,1,368.94,415.79Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <path d="M357.73,404.16s6,2.52,13.6-1.74c4.8-2.69,6.88-.45,6.67,2.22-.13,1.55-1.73,4-1.13,5.87,1.13,3.57,5.42,2.12,4.91,3.57s-3.4,4-13,5.86c0,0,8.1.55,20.24-6.95,4.28-2.64,5.5-6.51,3.89-10.25-2.46-5.72-5-10.23-5-10.23l-12.43-4.88S353.74,394.8,357.73,404.16Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                    <g>
                      <circle cx="321.08" cy="199.1" r="2.07" style="fill: #162d33"/>
                      <ellipse cx="371.89" cy="405.18" rx="0.62" ry="0.7" transform="translate(-168.49 -52.57) rotate(-20.28)" style="fill: #fef8f5"/>
                      <path d="M361.31,407.88c.33,1,1.43,1.87,2.26,1.6s.83-1.07.51-2.06c-.45-1.35-1.48-1.88-2.3-1.61S361,406.89,361.31,407.88Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                      <ellipse cx="362.47" cy="407.19" rx="0.48" ry="0.63" transform="translate(-160.02 -73.24) rotate(-18.17)" style="fill: #fef8f5"/>
                    </g>
                    <g>
                      <path d="M427.36,416.33s8.77-5.45,12.2-3.17L444.3,426a40,40,0,0,0-9.47,2.07c-3.72,1.53-6.22,3.19-5.83,4.22l-3.22-8.72Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                      <path d="M429.17,417.9l2.41.08a4.83,4.83,0,0,1,1.61,4.36c-.23,1.66-.81,3.48-2.77,4a10.55,10.55,0,0,1-2.93-.13C423.58,425.4,429.17,417.9,429.17,417.9Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                      <path d="M398.39,420.91c-2.32,5.74-1.6,14.35.91,17.28s6.85,1.78,11.55-3.52,7.38-8.37,9.74-8.43,6.9,0,6.9,0,3.68-1,3-6.79-5.53-5.95-5.53-5.95l-11.45-2.64a11.7,11.7,0,0,0-7.33,2.71C403.13,416.35,399,419.31,398.39,420.91Z" transform="translate(-51.09 -206.61)" style="fill: #ffc0f0"/>
                      <path d="M429,432.27c-.38-1,1.44-2.64,5.48-4.19,5.12-2,8.51-2.37,9.52-2.29.29,0,.33.16.32.37,0,.5-.43.93-1,1.32a12,12,0,0,1-3.57,1.4s4.23-2.55-3.22,0a14.78,14.78,0,0,0-4.18,1.9c-2,1.35,2.93-.22,4-.69l-.27,1.43C429.61,434.55,429,432.27,429,432.27Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                      <path d="M439.77,428.88l-.34-.92s1.4-.45,1.57-.17S439.77,428.88,439.77,428.88Z" transform="translate(-51.09 -206.61)" style="fill: #bc8e5b"/>
                      <path d="M436.37,430.06l-.37-1s-4.3,1.58-4.09,2.16S436.37,430.06,436.37,430.06Z" transform="translate(-51.09 -206.61)" style="fill: #bc8e5b"/>
                    </g>
                    <path d="M442.22,464.88l-23.07-5.22s-12.27.14-9.23,13.84c2.41,10.88,8.2,10.6,8.2,10.6s4.5-.39,6.21-3.49l10.24,2.59-.15,7.09,7.34,1.68Z" transform="translate(-51.09 -206.61)" style="fill: #243962"/>
                    <path d="M391,470.73l18.58-.46s.7,12.48,8.56,13.83l-20.59.36S388.41,482.81,391,470.73Z" transform="translate(-51.09 -206.61)" style="fill: #36599e"/>
                    <polygon points="383.32 283.67 363.26 284.66 363.57 277.55 383.48 276.58 383.32 283.67" style="fill: #2b478b"/>
                    <polygon points="390.67 285.35 383.32 283.67 363.84 284.66 372.55 285.88 390.67 285.35" style="fill: #36599e"/>
                    <path d="M434.57,483.2l-10.24-2.59s-2.38,3.08-5.88,3.37Z" transform="translate(-51.09 -206.61)" style="fill: #4470b7"/>
                  </g>
                </g>
                <polygon points="335.95 217.73 306.92 218.31 323.09 235.07 331.21 256.94 337.92 256.86 330.06 234.24 335.95 217.73" style="fill: #c0a9ff"/>
                <g>
                  <polygon points="326.55 228.83 324.34 228.04 322.61 229.04 322.48 226.45 320.53 224.99 322.66 224.47 323.18 222.28 324.63 224.37 326.9 224.29 325.67 226.39 326.55 228.83" style="fill: #a37df4"/>
                  <path d="M373.3,436.39l-.15-3.1-2.53-1.88,2.77-.68.69-2.88,1.86,2.69,2.83-.1-1.54,2.61,1.13,3.11-2.89-1Zm-.69-4.58,1.38,1,.11,2.09,1.29-.75,1.53.54-.64-1.76.94-1.6-1.72.06-1-1.49-.36,1.51Z" transform="translate(-51.09 -206.61)" style="fill: #fff"/>
                </g>
                <path d="M389,462l17.5-.11-.45-.86-17.35.11-2.22-6.42h7.79a5.87,5.87,0,0,0,2.12,3.23,9.51,9.51,0,0,0,6,1.74,19.26,19.26,0,0,0,2.83-.22l-.42-.81c-3.49.44-6.15,0-7.89-1.37a4.9,4.9,0,0,1-1.88-3.05l0-.39h-8.84L381.66,441l8.19-11.21-.45-.85-6.81,9.32,4.75-13.31-.27-.53h-.46l-5.84,16.35,0,.14,7.86,22.64.91,0Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                <path d="M374.59,441.57l0-.08L358.6,425H358l.19.83,13.33,13.81-11.75-7.17.28,1.18,13.77,8.4,4.55,12.41h-7.16v.43c0,.16-.1,3.94-5.1,4.36l.2.85c4.62-.44,5.54-3.61,5.72-4.77h6.65l2.16,5.89H366.59l.2.87h14.38l.57,1.55h.92Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                <path d="M369.21,402.55a3.31,3.31,0,0,1,2.43-1.22,4.61,4.61,0,0,1,2.93,1.22S371.15,401.94,369.21,402.55Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                <path d="M364,403.44a3.29,3.29,0,0,0-2.71-.15,4.62,4.62,0,0,0-2.2,2.29S362,403.66,364,403.44Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                <path d="M362.11,416.34s-5.88,2.71-7.47,1c-3.75-4,0-6.86,0-6.86s-4.5-13.3-1.94-19.37,7.3-12.7,16.73-14.11,20.93-1.33,26.71,8.79c3.41,6,4,9.51,4,9.51a4.07,4.07,0,0,1,2.26.57,3.15,3.15,0,0,1,.95,4.17c-1.68,3-16.37,9.33-20.65,10.8s-6.89-1.76-5.72-5.42a7.28,7.28,0,0,1,1.73-2.36s-.74-4.87-3.25-5.77c-1.34-.47-2.41,3.24-9.33,4.53s-7.11-.28-7.59.78C357.46,404.92,362.11,416.34,362.11,416.34Z" transform="translate(-51.09 -206.61)" style="fill: #a9453a"/>
                <path d="M373.51,404.14a1.82,1.82,0,0,0,1.91,0s-.7,1.52-1.91,1S373.51,404.14,373.51,404.14Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                <path d="M361.18,407.19s.2.89-1.15.68c0,0,1.07,1,1.71.26C362.62,407.17,361.18,407.19,361.18,407.19Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                <path d="M359.79,385.24a17.3,17.3,0,0,0-3.19,14.1A81.88,81.88,0,0,1,359.79,385.24Z" transform="translate(-51.09 -206.61)" style="fill: #dd6862"/>
                <path d="M362.88,385.6a16,16,0,0,0-.5,13.37A75.92,75.92,0,0,1,362.88,385.6Z" transform="translate(-51.09 -206.61)" style="fill: #dd6862"/>
                <path d="M380.72,404.33s-2.3,1.65-1.67,3.31,1.53,1.68,3.61,1.3a2.85,2.85,0,0,1-2.17-1.3A3.94,3.94,0,0,1,380.72,404.33Z" transform="translate(-51.09 -206.61)" style="fill: #dd6862"/>
                <path d="M356.15,411.25s-2.3,1.65-1.67,3.31,1.53,1.68,3.61,1.3a2.85,2.85,0,0,1-2.17-1.3A3.94,3.94,0,0,1,356.15,411.25Z" transform="translate(-51.09 -206.61)" style="fill: #dd6862"/>
                <path d="M300.1,534.49s7.56-8.56,5.5-18.71-3.08-17.49-3.08-17.49L317,489l6.55,18.3a9.75,9.75,0,0,0,9.69,5Z" transform="translate(-51.09 -206.61)" style="fill: #3b6a7a"/>
                <g>
                  <path d="M300.1,534.49s4.13-5,5.19-10.79a30.55,30.55,0,0,0-.38-11.43l-2.39-14L317,488.1l6.18,18.27s1.41,3.58,4,4.63,4.29,1.54,6.06,1.23" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                  <path d="M332.29,512.92c-1.6.06-3-.61-5.24-1.52-2.72-1.11-4.17-4.72-4.23-4.87l-6-17.74L303,498.49l2.34,13.69a30.42,30.42,0,0,1,.38,11.6,24,24,0,0,1-4,9.67l-1.61,1a31.85,31.85,0,0,0,4.76-10.87,30.27,30.27,0,0,0-.37-11.25v0l-2.39-14a.41.41,0,0,1,.18-.42l14.52-10.19a.43.43,0,0,1,.38-.06.44.44,0,0,1,.28.27l6.18,18.27s1.37,3.4,3.75,4.37,4.22,1.92,5.9,1.63h0" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                </g>
                <g>
                  <path d="M309.11,510l9.35-6.3,2.14,6.42s1.64,4.42-3.14,7.82c-6.11,4.34-7-2.08-7-2.08Z" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                  <path d="M313.76,519.87a3.18,3.18,0,0,1-1.28-.26c-1.94-.84-2.37-3.39-2.41-3.68l-1.38-5.84a.44.44,0,0,1,.18-.46l9.35-6.3a.46.46,0,0,1,.38-.05.48.48,0,0,1,.27.27L321,510s1.69,4.76-3.3,8.31A7.14,7.14,0,0,1,313.76,519.87Zm-4.16-9.69,1.32,5.57a.08.08,0,0,0,0,0s.33,2.35,1.89,3c1.09.47,2.57,0,4.39-1.24,4.43-3.16,3-7.15,3-7.32l-2-5.89Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                </g>
                <g>
                  <line x1="258.63" y1="305.94" x2="268.1" y2="299.76" style="fill: #77a3bf"/>
                  <path d="M309.72,513a.43.43,0,0,1-.24-.79L319,506a.42.42,0,0,1,.59.12.43.43,0,0,1-.12.6L310,512.92A.44.44,0,0,1,309.72,513Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                </g>
                <path d="M297.66,489l-2.88,1.9,9.47,9.87s1.32.9,2.27,0c1.3-1.23-.1-2.68-.1-2.68Z" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                <path d="M318.36,475.4l-3.2,2.11c-.29,4.33-1,14.31-1.07,15-.13.84.05,2.37,1.35,2.38,2.24,0,2.12-2.38,2.12-2.38Z" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                <path d="M318.81,475l-.9.59-.78,16.84v0a2.3,2.3,0,0,1-.5,1.49,1.56,1.56,0,0,1-1.18.43h0a.77.77,0,0,1-.62-.26,2.25,2.25,0,0,1-.31-1.62c.11-.7.83-11.31,1.11-15.41l-.91.6c-.39,5.79-1,14.11-1,14.68a3,3,0,0,0,.51,2.31,1.6,1.6,0,0,0,1.27.57h0a2.43,2.43,0,0,0,1.81-.7,3.2,3.2,0,0,0,.74-2.11Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                <path d="M298,488.77l-.74.48,8.8,9.14a2,2,0,0,1,.54,1.22,1.16,1.16,0,0,1-.42.84c-.62.59-1.51.09-1.7,0l-9.36-9.76-.74.49,9.51,9.9a.19.19,0,0,0,.07.06,2.8,2.8,0,0,0,1.5.48,1.89,1.89,0,0,0,1.32-.52,2,2,0,0,0,.68-1.45,2.82,2.82,0,0,0-.77-1.84Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                <circle cx="254" cy="292.41" r="0.73" style="fill: #162d33"/>
                <circle cx="264.63" cy="285.89" r="0.73" style="fill: #162d33"/>
                <g>
                  <g>
                    <path d="M203.55,599.89v-21s1.78-9.5,11.93-8.1,13.45,15.65,4.86,22.7" transform="translate(-51.09 -206.61)" style="fill: #4d4d4d"/>
                    <path d="M190.94,622.33V585.39s0-9.81,10.24-9.06c13,1,14.09,13.74,5.5,20.79V615.2l4.71,2.29-2.2,10.38Z" transform="translate(-51.09 -206.61)" style="fill: #111"/>
                    <polygon points="155.59 390.51 155.59 409.22 169.21 405.35 169.21 386.99 155.59 390.51" style="fill: #333"/>
                    <polygon points="158.1 410.02 172.22 406.15 172.22 416.75 158.1 421.25 158.1 410.02" style="fill: #333"/>
                    <polygon points="155.59 409.22 158.1 410.02 172.22 406.15 169.21 405.35 155.59 409.22" style="fill: #4d4d4d"/>
                  </g>
                  <g>
                    <path d="M200.54,575.36c-10.15-1.4-11.93,8.11-11.93,8.11l.41,20.11,16.38-5.51C214,591,210.69,576.76,200.54,575.36Z" transform="translate(-51.09 -206.61)" style="fill: #4d4d4d"/>
                    <path d="M176,626.89V593.44c0-5.65,4.13-10.33,9.29-10.38h.27c7.64.05,12.22,9.85,5.77,18.77l.19,18.16,4.15,1.62-2.17,11.06Z" transform="translate(-51.09 -206.61)" style="fill: #111"/>
                    <polygon points="140.24 395.21 140.24 413.92 154.27 409.91 154.27 391.55 140.24 395.21" style="fill: #333"/>
                    <polygon points="142.41 414.81 157.29 410.71 157.29 421.31 142.41 426.04 142.41 414.81" style="fill: #333"/>
                    <polygon points="140.24 413.92 142.41 414.81 157.29 410.71 154.27 409.91 140.24 413.92" style="fill: #4d4d4d"/>
                  </g>
                  <polygon points="138.7 375.59 174.93 365.22 174.98 372.97 138.77 383.23 138.7 375.59" style="fill: #333"/>
                  <g>
                    <path d="M233.32,542.66a7.45,7.45,0,0,1-7.14-3.45c-2.83-4.21-8.47-11.21-13-6.63s-2.66,12.14-1.18,15.34,8.76,10,12.22,10.48S235.27,556,235.27,556L235,554a5.56,5.56,0,0,1-5-5.47c-.35-5.15,3.68-3.73,3.68-3.73Z" transform="translate(-51.09 -206.61)" style="fill: #d7d7d7"/>
                    <path d="M233.63,544.76s-3.79-1.63-3.68,3.73a5.21,5.21,0,0,0,5,5.47Z" transform="translate(-51.09 -206.61)" style="fill: #dba15d"/>
                    <path d="M235.73,556.07c-4.94-.19-7.74-3.12-7.68-8,0-2.21.67-3.79,1.92-4.69a5,5,0,0,1,4.1-.55l-.62,2a2.88,2.88,0,0,0-2.29.27c-.68.5-1,1.52-1,3-.05,3.81,1.87,5.82,5.69,6Z" transform="translate(-51.09 -206.61)" style="fill: #efede8"/>
                    <path d="M248.52,550.58s-1.85-2.14-6.42-.62l-5.86.95-1.84-10.84a8.57,8.57,0,0,1,6.41-3.35c4.43-.32,6.58,2.06,7.32,4.06S248.52,550.58,248.52,550.58Z" transform="translate(-51.09 -206.61)" style="fill: #dba15d"/>
                    <path d="M232.91,541.46l2.14,14.68s5,2.09,7.4-3.79L241.37,545S235.28,546.21,232.91,541.46Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                    <rect x="247.81" y="540.73" width="2.06" height="9.75" transform="translate(-127.3 -164.94) rotate(-8.31)" style="fill: #f7e1b9"/>
                    <path d="M239.1,545.54a6.31,6.31,0,0,1-4-.53,4.59,4.59,0,0,1-2.21-3.55,4.06,4.06,0,0,1,1.81-3.91,10,10,0,0,1,4.46-1.66c4.4-.65,9.39.8,10,4.75l-2,.29c-.37-2.53-4.17-3.52-7.63-3a7.93,7.93,0,0,0-3.53,1.29c-1.16.86-1,1.68-1,1.95a2.58,2.58,0,0,0,1.18,2.06c1.42.8,3.87.09,4.69-.25l.55,2A10.73,10.73,0,0,1,239.1,545.54Z" transform="translate(-51.09 -206.61)" style="fill: #f2ca8b"/>
                  </g>
                  <polygon points="123.41 371.02 123.47 377.46 138.77 383.23 138.7 375.59 123.41 371.02" style="fill: #111"/>
                  <polygon points="123.41 371.02 129.8 327.94 144.07 332.12 138.7 375.59 123.41 371.02" style="fill: #333"/>
                  <polygon points="144.07 332.12 171.51 325.19 174.93 365.22 138.7 375.59 144.07 332.12" style="fill: #4d4d4d"/>
                  <polygon points="129.8 327.94 157.07 321.19 171.51 325.19 144.07 332.12 129.8 327.94" style="fill: #686868"/>
                  <path d="M184.85,531.72" transform="translate(-51.09 -206.61)" style="fill: #323232"/>
                  <path d="M189.9,529.62l0,2.54s.76,3.16,10.14,3.08,10.4-3.25,10.4-3.25l0-4.11Z" transform="translate(-51.09 -206.61)" style="fill: #f2ca8b"/>
                  <path d="M183,517.79v5.4s0,8.37,17,8.39c15.69,0,16.32-5.54,16.31-7.3l-.15-17.45-32.67.28Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                  <path d="M207.41,527.4a3.74,3.74,0,0,1-2.53-.91.56.56,0,0,1,0-.81.57.57,0,0,1,.81,0c.08.08,1.81,1.61,4.86-.67a.58.58,0,1,1,.69.93A6.42,6.42,0,0,1,207.41,527.4Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <path d="M216.22,511.34s-4.77,4.44-13.36,3.08-4.21-.65-4.21,4.79-2.53,8.58-1.55,9.77,5.09,2.86,14.47,1a36.92,36.92,0,0,1-20.68.76C178,527.48,183,511,183,511l10.8-9.06S216.72,501.17,216.22,511.34Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                  <path d="M216.22,510.63c-1,1.12-4,1.55-5.33,1.81-4.56.89-8.6,0-13-1.06a2.22,2.22,0,0,0-1.86.11,2.08,2.08,0,0,0-.51,1.85c.12,1.63.36,3.71-.47,5.2-.72,1.28-2.84,1.4-4,.65s-1.12-1.84-1.12-3c0-2.22-1.72-.93-1.82,2.1-.13,4.08.33,7.57-1.17,7.77-2.61.35-5.1-3.69-5.73-9.52s-.93-13.09.57-16.41c1.62-3.57,5.42-10.11,18.62-10.11,5.76,0,10.32.67,14.16,4C219.69,498.45,219.53,506.85,216.22,510.63Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <g>
                    <circle cx="152.12" cy="311.77" r="2.07" style="fill: #162d33"/>
                    <ellipse cx="152.2" cy="311.16" rx="0.62" ry="0.7" style="fill: #fef8f5"/>
                    <path d="M214.15,516.65c0,1-.7,2.24-1.56,2.28s-1.15-.72-1.19-1.76c-.05-1.42.73-2.27,1.6-2.3S214.11,515.61,214.15,516.65Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <ellipse cx="212.83" cy="516.39" rx="0.48" ry="0.63" transform="matrix(1, -0.04, 0.04, 1, -69.94, -198.44)" style="fill: #fef8f5"/>
                  </g>
                  <path d="M204.77,527.39a.57.57,0,0,1-.55-.41,2.23,2.23,0,0,1,.15-2.09,1.62,1.62,0,0,1,1.3-.6.58.58,0,0,1,0,1.15h0a.52.52,0,0,0-.36.15c-.06.08-.18.35,0,1a.58.58,0,0,1-.38.72Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <path d="M193.65,503.87s3.12,6.86,15.52,6.05C209.17,509.92,199.44,508,193.65,503.87Z" transform="translate(-51.09 -206.61)" style="fill: #686868"/>
                  <path d="M190.43,501.54s2,5.48,5.31,7.27C195.74,508.81,190.63,507.82,190.43,501.54Z" transform="translate(-51.09 -206.61)" style="fill: #686868"/>
                  <polygon points="149.3 330.8 157.66 344.07 163.57 327.2 149.3 330.8" style="fill: #99abbc"/>
                  <polygon points="154.93 329.39 156.02 335.11 158.44 334.43 159.35 328.26 154.93 329.39" style="fill: #2f84d1"/>
                  <polygon points="155.52 340.46 156.02 335.11 158.44 334.43 159.56 338.48 157.66 344.07 155.52 340.46" style="fill: #2f84d1"/>
                  <path d="M194.53,573.75a27.32,27.32,0,0,0,9.8-2.94S200.6,576,194.53,573.75Z" transform="translate(-51.09 -206.61)" style="fill: #2f84d1"/>
                  <path d="M213.39,568.48a27.32,27.32,0,0,0,9.8-2.94S219.46,570.73,213.39,568.48Z" transform="translate(-51.09 -206.61)" style="fill: #2f84d1"/>
                  <polygon points="157.77 345.07 148.84 330.92 149.63 330.72 157.54 343.08 163.02 327.34 164.11 327.06 157.77 345.07" style="fill: #111"/>
                  <polygon points="158.2 370 157.34 370.25 157.34 343.87 158.2 343.87 158.2 370" style="fill: #111"/>
                  <g>
                    <path d="M201,553a7.43,7.43,0,0,1-6.6-4.39c-2.23-4.56-6.87-12.26-12-8.34s-4.28,11.66-3.26,15,7.33,11.06,10.68,12,11.31-.84,11.31-.84l0-2.1a5.54,5.54,0,0,1-4.23-6.09c.35-5.16,4.15-3.2,4.15-3.2Z" transform="translate(-51.09 -206.61)" style="fill: #d7d7d7"/>
                    <path d="M201.06,555.16s-3.53-2.13-4.15,3.2a5.2,5.2,0,0,0,4.23,6.09Z" transform="translate(-51.09 -206.61)" style="fill: #dba15d"/>
                    <path d="M201.6,566.65c-4.86-.86-7.24-4.14-6.51-9,.33-2.19,1.18-3.66,2.54-4.38a5,5,0,0,1,4.14,0l-.88,1.86a3,3,0,0,0-2.3,0c-.75.4-1.24,1.36-1.46,2.86-.57,3.77,1.06,6,4.83,6.68Z" transform="translate(-51.09 -206.61)" style="fill: #efede8"/>
                    <path d="M215,563s-1.54-2.37-6.28-1.49l-5.93.15-.35-11a8.58,8.58,0,0,1,6.81-2.45c4.43.29,6.23,2.94,6.7,5S215,563,215,563Z" transform="translate(-51.09 -206.61)" style="fill: #dba15d"/>
                    <path d="M200.8,551.79l.13,14.84s4.7,2.75,7.83-2.75l-.06-7.45S202.5,556.82,200.8,551.79Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                    <rect x="214.98" y="553.19" width="2.06" height="9.75" transform="translate(-55.86 -204.75) rotate(-0.49)" style="fill: #f7e1b9"/>
                    <path d="M206.38,556.68a6.3,6.3,0,0,1-3.87-1.07,4.57,4.57,0,0,1-1.71-3.82,4.06,4.06,0,0,1,2.32-3.62,10.17,10.17,0,0,1,4.65-1c4.45,0,9.19,2.08,9.23,6.06l-2.06,0c0-2.55-3.65-4.05-7.15-4a8,8,0,0,0-3.68.79c-1.26.7-1.26,1.53-1.25,1.81a2.57,2.57,0,0,0,.89,2.19c1.29,1,3.82.62,4.68.4l.27,2.06A10.94,10.94,0,0,1,206.38,556.68Z" transform="translate(-51.09 -206.61)" style="fill: #f2ca8b"/>
                  </g>
                  <polygon points="155.71 334.74 154.55 329.49 155.4 329.26 156.34 333.66 158.06 333.18 158.91 328.37 159.84 328.14 158.82 333.87 155.71 334.74" style="fill: #111"/>
                  <polygon points="155.96 340.5 155.09 340.42 155.61 333.74 156.47 333.82 155.96 340.5" style="fill: #111"/>
                  <polygon points="159.14 338.59 158.02 333.53 158.86 333.3 159.98 338.36 159.14 338.59" style="fill: #111"/>
                </g>
                <g>
                  <g>
                    <polygon points="386.87 428.33 386.87 463.67 390.38 463.67 390.38 427.66 386.87 428.33" style="fill: #538d7e"/>
                    <g>
                      <path d="M429.44,636.92c3.08-.11,5.64,1.56,5.72,3.72a2.86,2.86,0,0,1-.36,1.49,5.09,5.09,0,0,1,1.76,1.48,6.19,6.19,0,0,1,5.78,0,6,6,0,0,1,.68.43,4.37,4.37,0,0,1,1.53-1.58,3.22,3.22,0,0,1-.5-2.35c.43-2.14,3.22-3.38,6.24-2.77s5.12,2.82,4.7,4.95a3.36,3.36,0,0,1-1.78,2.23c1.25,1.65,1.62,2.27.73,4-1.32,2.5-4.84,3.22-7.86,1.62a6.45,6.45,0,0,1-.76-.47,6.49,6.49,0,0,1-.38.91,6.1,6.1,0,0,1-8.37,2.28,5.66,5.66,0,0,1-2.93-3.11,7.7,7.7,0,0,1-1.39.4c-3.36.64-6.52-.52-7-3.3-.25-1.3.12-1.74.94-2.82a3.63,3.63,0,0,1-2.14-3C423.94,638.88,426.37,637,429.44,636.92Z" transform="translate(-51.09 -206.61)" style="fill: #b0549d"/>
                      <path d="M429.47,635.38c3.08-.12,5.64,1.55,5.72,3.72a3,3,0,0,1-.36,1.48,5.31,5.31,0,0,1,1.76,1.48,6.2,6.2,0,0,1,5.77,0,8,8,0,0,1,.69.43,4.37,4.37,0,0,1,1.53-1.58,3.18,3.18,0,0,1-.5-2.35c.42-2.13,3.22-3.37,6.24-2.77s5.12,2.82,4.69,5a3.32,3.32,0,0,1-1.77,2.23c1.25,1.66,1.62,2.28.73,4-1.32,2.5-4.85,3.22-7.87,1.62a7.25,7.25,0,0,1-.75-.47,6.56,6.56,0,0,1-.39.91,6.09,6.09,0,0,1-8.36,2.28,5.6,5.6,0,0,1-2.93-3.11,8.94,8.94,0,0,1-1.39.41c-3.36.63-6.52-.53-7-3.31-.24-1.3.13-1.74.95-2.82a3.62,3.62,0,0,1-2.14-3C424,637.33,426.39,635.49,429.47,635.38Z" transform="translate(-51.09 -206.61)" style="fill: #f685de"/>
                      <g>
                        <path d="M429.44,639.84h0l.06-1h0l.24-4.44a1,1,0,0,0,0,.29l0,.09a1.07,1.07,0,0,0,.07.19l.07.11a1.88,1.88,0,0,0,.12.18l.1.12.16.15a1.37,1.37,0,0,0,.22.19l.16.13.3.19.19.12.4.2.19.1c.22.1.45.2.7.29s.59.19.89.29.45.15.7.21.62.15.93.21l.78.17,1,.16.82.12c.36,0,.72.06,1.08.09l.81.07,1.1,0,.8,0c.38,0,.75,0,1.12,0s.49,0,.73,0c.41,0,.8-.08,1.19-.13.18,0,.38,0,.55-.06q.84-.12,1.59-.3c2-.51,3.06-1.26,3.1-2.07l-.16,3h0l-.1,1.77h0v.16l0,.44h0l-.13,2.42c-.05.81-1.09,1.57-3.1,2.07-.5.12-1,.23-1.59.31l-.55.06c-.39,0-.78.1-1.2.13l-.73,0c-.37,0-.74,0-1.12,0l-.8,0c-.36,0-.73,0-1.09,0s-.54,0-.81-.07-.73,0-1.08-.09-.55-.08-.82-.12-.68-.09-1-.15l-.77-.17c-.32-.07-.64-.13-.93-.21l-.7-.22c-.26-.08-.54-.15-.78-.24l-.12,0-.69-.29-.2-.1-.39-.21-.2-.12a2.9,2.9,0,0,1-.29-.19l-.17-.12-.22-.19-.07-.06-.08-.1-.1-.11a.94.94,0,0,1-.12-.18l-.07-.11c0-.07-.06-.13-.08-.19l0-.1a1.33,1.33,0,0,1,0-.28l.1-1.76h0v-.16h0Z" transform="translate(-51.09 -206.61)" style="fill: #b0549d"/>
                        <path d="M432.83,632.34c-4,1-4.14,3-.35,4.41a27.7,27.7,0,0,0,14.09.81c4-1,4.15-3,.36-4.42A27.62,27.62,0,0,0,432.83,632.34Z" transform="translate(-51.09 -206.61)" style="fill: #f685de"/>
                      </g>
                      <path d="M439,635.78a1.79,1.79,0,0,0,.73.18,1.74,1.74,0,0,0,1.78-1.68l-.05-1.38a1.73,1.73,0,0,0-3.46-.1l.05,1.38A1.75,1.75,0,0,0,439,635.78Z" transform="translate(-51.09 -206.61)" style="fill: #336052"/>
                      <circle cx="388.08" cy="425.79" r="1" style="fill: #538d7e"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M449.86,685.72h0v-3h0v-4.44a1,1,0,0,1-.05.28.32.32,0,0,1,0,.09,1.63,1.63,0,0,1-.09.19l-.07.11-.13.17-.11.11a1.07,1.07,0,0,1-.16.14l-.23.18-.18.12a2.14,2.14,0,0,1-.3.17l-.2.11-.41.18-.2.09c-.22.09-.45.17-.7.25s-.61.16-.92.24-.46.13-.7.18l-.95.16c-.26,0-.51.09-.78.12s-.67.07-1,.1l-.83.08c-.35,0-.72,0-1.08,0l-.81,0c-.37,0-.73,0-1.1,0l-.8,0c-.38,0-.74-.06-1.11-.1s-.49,0-.73-.06c-.41,0-.8-.13-1.19-.2l-.54-.08c-.56-.12-1.08-.25-1.57-.4-2-.61-3-1.42-3-2.23v3h0v3.79h0v4h0v4.44c0,.81,1,1.62,3,2.23.49.15,1,.28,1.57.4l.54.09c.39.06.78.14,1.19.19.24,0,.49,0,.73.06s.73.08,1.11.1l.81,0c.36,0,.72,0,1.09,0l.81,0c.37,0,.73,0,1.09,0l.82-.08c.34,0,.68-.05,1-.09l.79-.13.94-.16c.25,0,.47-.12.71-.18s.54-.12.78-.2l.13,0c.25-.08.48-.16.7-.25l.2-.09.41-.18.2-.11a2.14,2.14,0,0,0,.3-.17l.18-.12.23-.18.07,0,.09-.09.11-.11.13-.17.07-.11a1.63,1.63,0,0,0,.09-.19l0-.09a1.35,1.35,0,0,0,0-.28v-3.78h0v-.16h0Z" transform="translate(-51.09 -206.61)" style="fill: #336052"/>
                      <path d="M446.88,676c3.92,1.21,4,3.19.12,4.42a27.58,27.58,0,0,1-14.12,0c-3.93-1.21-4-3.19-.12-4.42A27.47,27.47,0,0,1,446.88,676Z" transform="translate(-51.09 -206.61)" style="fill: #538d7e"/>
                    </g>
                    <circle cx="388.72" cy="466.61" r="5.91" style="fill: #336052"/>
                    <path d="M449.86,689.67h1.64a2,2,0,0,0,2-2v-8.39a4.84,4.84,0,0,1,.47-2.09l5.56-11.74L456.44,664l-6.58,14.22Z" transform="translate(-51.09 -206.61)" style="fill: #538d7e"/>
                    <path d="M429.89,689.67h-1.64a2,2,0,0,1-2-2v-8.39a4.84,4.84,0,0,0-.47-2.09l-5.56-11.74,3.13-1.36,6.58,14.22Z" transform="translate(-51.09 -206.61)" style="fill: #538d7e"/>
                  </g>
                  <g>
                    <path d="M470.31,663.21c-2.67-1.54-5.71-1.27-6.8.61a2.94,2.94,0,0,0-.38,1.48,5.24,5.24,0,0,0-2.24.48,6.21,6.21,0,0,0-5.11-2.69,5.52,5.52,0,0,0-.81.06,4.25,4.25,0,0,0-.61-2.12,3.21,3.21,0,0,0,1.55-1.84c.62-2.08-1.27-4.49-4.22-5.37s-5.85.09-6.47,2.18a3.34,3.34,0,0,0,.53,2.8,5,5,0,0,0-3.15,4.45c0,2.82,2.77,5.11,6.19,5.11a8.48,8.48,0,0,0,.88-.06,6.37,6.37,0,0,0-.08,1A6.19,6.19,0,0,0,460,673.84a7.66,7.66,0,0,0,1,1c2.67,2.13,6.26,2.07,8-.14a4.63,4.63,0,0,0,.88-3.7,3.65,3.65,0,0,0,3.27-1.61C474.26,667.52,473,664.75,470.31,663.21Z" transform="translate(-51.09 -206.61)" style="fill: #b0549d"/>
                    <path d="M470.31,661.55c-2.67-1.54-5.71-1.26-6.8.61a3,3,0,0,0-.38,1.48,5.24,5.24,0,0,0-2.24.48,6.21,6.21,0,0,0-5.11-2.69,7.36,7.36,0,0,0-.81.06,4.27,4.27,0,0,0-.61-2.12,3.18,3.18,0,0,0,1.55-1.84c.62-2.08-1.27-4.49-4.22-5.37s-5.85.1-6.47,2.18a3.34,3.34,0,0,0,.53,2.8,5,5,0,0,0-3.15,4.45c0,2.82,2.77,5.11,6.19,5.11a6.4,6.4,0,0,0,.88-.06,6.37,6.37,0,0,0-.08,1A6.19,6.19,0,0,0,460,672.18a7.66,7.66,0,0,0,1,1c2.67,2.13,6.26,2.07,8-.13a4.65,4.65,0,0,0,.88-3.71,3.63,3.63,0,0,0,3.27-1.61C474.26,665.86,473,663.09,470.31,661.55Z" transform="translate(-51.09 -206.61)" style="fill: #f685de"/>
                    <g>
                      <path d="M468.69,665.49h0l.39-.9h0l1.78-4.08a.78.78,0,0,1-.16.24l-.07.07a.67.67,0,0,1-.15.14.41.41,0,0,1-.11.07l-.19.1a.59.59,0,0,1-.14.06l-.21.07-.28.07-.21,0-.34,0-.24,0h-.66c-.23,0-.48,0-.74,0s-.62-.09-.93-.15-.48-.06-.73-.12-.61-.15-.92-.23l-.77-.19-1-.32c-.26-.09-.52-.16-.78-.26s-.67-.26-1-.4l-.75-.3c-.34-.15-.66-.31-1-.47l-.73-.34c-.34-.17-.66-.36-1-.54s-.44-.23-.64-.35c-.36-.21-.69-.43-1-.65l-.46-.3c-.46-.33-.89-.66-1.28-1-1.57-1.36-2.16-2.5-1.84-3.24l-1.21,2.77h0l-.7,1.63h0l-.07.15-.17.4h0l-1,2.23c-.32.74.27,1.88,1.84,3.24.39.33.82.66,1.28,1,.15.11.32.2.47.3.33.22.65.44,1,.65.2.13.43.24.64.36s.64.36,1,.53l.73.35c.33.15.65.32,1,.46l.75.31,1,.4.78.26c.33.1.65.22,1,.31s.51.13.77.2.63.17.93.23l.72.12c.27,0,.55.1.8.13l.13,0,.75.05h.66l.23,0,.34,0,.21,0,.28-.07.09,0,.12,0,.15-.06.18-.1.11-.07.16-.14.07-.07a1.2,1.2,0,0,0,.15-.24l.7-1.62h0l.06-.15h0Z" transform="translate(-51.09 -206.61)" style="fill: #b0549d"/>
                      <path d="M469,657.27c3.11,2.68,2.37,4.52-1.66,4.1a27.71,27.71,0,0,1-13-5.61c-3.12-2.68-2.37-4.52,1.66-4.1A27.51,27.51,0,0,1,469,657.27Z" transform="translate(-51.09 -206.61)" style="fill: #f685de"/>
                    </g>
                    <path d="M462,657.59a1.7,1.7,0,0,1-.73-.17,1.73,1.73,0,0,1-.84-2.3l.66-1.21a1.73,1.73,0,0,1,3.14,1.47l-.66,1.21A1.74,1.74,0,0,1,462,657.59Z" transform="translate(-51.09 -206.61)" style="fill: #336052"/>
                    <circle cx="412.25" cy="447.86" r="1" style="fill: #538d7e"/>
                  </g>
                  <g>
                    <path d="M409.06,662.35c2.67-1.54,5.71-1.27,6.79.61a2.91,2.91,0,0,1,.38,1.48,5,5,0,0,1,2.25.48,6.16,6.16,0,0,1,5.11-2.7,7,7,0,0,1,.8.06,4.49,4.49,0,0,1,.61-2.12,3.18,3.18,0,0,1-1.54-1.83c-.62-2.09,1.26-4.49,4.21-5.38s5.85.1,6.47,2.18a3.33,3.33,0,0,1-.52,2.8,5,5,0,0,1,3.15,4.45c0,2.82-2.77,5.11-6.19,5.11a6.54,6.54,0,0,1-.89-.06,6.38,6.38,0,0,1,.09,1A6.19,6.19,0,0,1,419.4,673a7,7,0,0,1-1,1c-2.67,2.14-6.27,2.07-8-.13a4.65,4.65,0,0,1-.88-3.71,3.61,3.61,0,0,1-3.27-1.61C405.11,666.66,406.39,663.89,409.06,662.35Z" transform="translate(-51.09 -206.61)" style="fill: #b0549d"/>
                    <path d="M409.06,660.69c2.67-1.54,5.71-1.27,6.79.61a2.91,2.91,0,0,1,.38,1.48,5.28,5.28,0,0,1,2.25.48,6.15,6.15,0,0,1,5.11-2.69,7,7,0,0,1,.8,0,4.45,4.45,0,0,1,.61-2.11,3.19,3.19,0,0,1-1.54-1.84c-.62-2.09,1.26-4.49,4.21-5.37s5.85.09,6.47,2.17a3.33,3.33,0,0,1-.52,2.8,5,5,0,0,1,3.15,4.45c0,2.83-2.77,5.11-6.19,5.11a8.69,8.69,0,0,1-.89-.05,6.26,6.26,0,0,1,.09,1,6.19,6.19,0,0,1-10.38,4.55,7.1,7.1,0,0,1-1,1c-2.67,2.13-6.27,2.07-8-.14a4.65,4.65,0,0,1-.88-3.71,3.61,3.61,0,0,1-3.27-1.61C405.11,665,406.39,662.23,409.06,660.69Z" transform="translate(-51.09 -206.61)" style="fill: #f685de"/>
                    <g>
                      <path d="M410.68,664.63h0l-.39-.9h0l-1.78-4.07a1.58,1.58,0,0,0,.15.24l.07.07.16.14.11.07.18.1.15.06.2.07.28.07.21,0,.35,0,.23,0h.66l.75,0,.93-.15.72-.12c.3-.06.62-.15.93-.23l.77-.2c.32-.09.64-.21,1-.32l.79-.25,1-.41c.26-.1.51-.19.76-.3s.66-.31,1-.46l.73-.35c.34-.17.65-.35,1-.53s.43-.23.64-.36.68-.43,1-.65c.15-.1.32-.19.46-.3a12.32,12.32,0,0,0,1.28-1c1.58-1.35,2.17-2.49,1.85-3.23l1.2,2.76h0l.71,1.62h0l.06.14.18.4h0l1,2.22c.33.74-.26,1.88-1.84,3.24-.39.33-.82.66-1.28,1l-.46.3c-.33.22-.66.44-1,.65l-.64.35c-.33.18-.65.37-1,.54s-.48.23-.72.34l-1,.47c-.25.11-.51.2-.76.3s-.67.28-1,.4-.53.17-.79.26l-1,.32-.77.19c-.31.08-.62.17-.92.23s-.48.08-.72.12-.55.11-.8.14h-.13c-.27,0-.51,0-.75.05h-.66l-.23,0-.35,0-.21,0-.28-.07-.09,0-.12,0-.14-.06-.19-.1-.11-.07-.15-.14-.07-.07a.75.75,0,0,1-.15-.24l-.71-1.61h0l-.06-.14h0Z" transform="translate(-51.09 -206.61)" style="fill: #b0549d"/>
                      <path d="M410.34,656.4c-3.12,2.68-2.37,4.52,1.66,4.11A27.64,27.64,0,0,0,425,654.89c3.12-2.68,2.38-4.51-1.66-4.1A27.72,27.72,0,0,0,410.34,656.4Z" transform="translate(-51.09 -206.61)" style="fill: #f685de"/>
                    </g>
                    <path d="M417.36,656.72a1.75,1.75,0,0,0,.73-.16,1.73,1.73,0,0,0,.83-2.3l-.66-1.22a1.73,1.73,0,1,0-3.13,1.47l.66,1.22A1.74,1.74,0,0,0,417.36,656.72Z" transform="translate(-51.09 -206.61)" style="fill: #336052"/>
                    <circle cx="364.94" cy="447" r="1" style="fill: #538d7e"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M427.84,366.77l0-19s-.77-8.25-10.56-7-14.55,14.69-6.25,21.06" transform="translate(-51.09 -206.61)" style="fill: #8c6867"/>
                    <path d="M441.19,387.29l0-30.8s.83-10.9-9-9.62-13.95,12.93-5.27,19.38l0,15.76-4.55,1.5-.05,9.27Z" transform="translate(-51.09 -206.61)" style="fill: #472b2a"/>
                    <polygon points="375.82 159.64 375.84 175.4 359.98 171.09 359.95 155.28 375.82 159.64" style="fill: #725350"/>
                    <polygon points="371.29 176.9 355.56 172.6 355.58 182.18 371.24 186.16 371.29 176.9" style="fill: #725350"/>
                    <polygon points="375.84 175.4 371.3 176.9 355.56 172.6 359.98 171.09 375.84 175.4" style="fill: #8c6867"/>
                  </g>
                  <g>
                    <path d="M444.59,371.44l0-19s-.76-8.26-10.55-7-14.55,14.7-6.25,21.07" transform="translate(-51.09 -206.61)" style="fill: #8c6867"/>
                    <path d="M455.22,392.61l0-30.8s.81-11.42-6.25-10.26c-9.74,1.59-13.95,12.93-5.27,19.38l0,15.76-4.79,1.48.26,8.87Z" transform="translate(-51.09 -206.61)" style="fill: #472b2a"/>
                    <polygon points="392.56 164.31 392.59 180.07 376.73 175.77 376.7 159.96 392.56 164.31" style="fill: #725350"/>
                    <polygon points="388.01 181.22 372.28 176.91 372.3 186.5 388.02 190.43 388.01 181.22" style="fill: #725350"/>
                    <polygon points="392.59 180.07 388.05 181.57 372.31 177.27 376.73 175.77 392.59 180.07" style="fill: #8c6867"/>
                  </g>
                  <polygon points="389.69 144.8 352.45 135.15 353.09 142.88 390.31 152.41 389.69 144.8" style="fill: #725350"/>
                  <g>
                    <path d="M393.4,314.59a8,8,0,0,0,7.35-4.41c2.64-4.81,8-12.89,13.39-8.41s4,12.81,2.77,16.4-8.47,11.59-12.14,12.47-12.17-1.47-12.17-1.47l.13-2.26a6,6,0,0,0,4.87-6.37c-.12-5.59-4.32-3.66-4.32-3.66Z" transform="translate(-51.09 -206.61)" style="fill: #cabeaf"/>
                    <path d="M393.28,316.88s3.92-2.13,4.32,3.66a5.62,5.62,0,0,1-4.87,6.37Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <path d="M392.12,329.26c5.29-.68,8-4.11,7.48-9.41-.24-2.37-1.09-4-2.52-4.85a5.33,5.33,0,0,0-4.47-.2l.85,2.05a3.17,3.17,0,0,1,2.49.06c.79.48,1.27,1.54,1.44,3.17.42,4.1-1.45,6.44-5.56,7Z" transform="translate(-51.09 -206.61)" style="fill: #e7e5df"/>
                    <path d="M377.81,324.59s1.79-2.48,6.86-1.29l6.4.46.92-11.85a9.27,9.27,0,0,0-7.23-3c-4.8.09-6.88,2.86-7.48,5.09S377.81,324.59,377.81,324.59Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <path d="M393.73,313.26l-.88,16s-5.21,2.74-8.33-3.36l.44-8S391.64,318.6,393.73,313.26Z" transform="translate(-51.09 -206.61)" style="fill: #254553"/>
                    <rect x="375.88" y="314" width="2.22" height="10.54" transform="translate(684.84 452.09) rotate(-176.86)" style="fill: #254553"/>
                    <path d="M387.46,318.25a6.85,6.85,0,0,0,4.23-1,4.93,4.93,0,0,0,2-4,4.38,4.38,0,0,0-2.33-4,10.86,10.86,0,0,0-5-1.37c-4.8-.26-10,1.78-10.26,6.09l2.22.12c.15-2.76,4.14-4.19,7.92-4a8.77,8.77,0,0,1,3.93,1c1.33.82,1.28,1.72,1.27,2a2.82,2.82,0,0,1-1.08,2.33c-1.45,1-4.15.48-5.07.19l-.4,2.22A11.16,11.16,0,0,0,387.46,318.25Z" transform="translate(-51.09 -206.61)" style="fill: #3b6a7a"/>
                  </g>
                  <polygon points="404.27 136.29 404.8 142.7 390.31 152.41 389.69 144.8 404.27 136.29" style="fill: #472b2a"/>
                  <polygon points="404.96 135.98 396.13 93.95 380.43 102 389.69 144.8 404.96 135.98" style="fill: #998f85"/>
                  <polygon points="380.43 102 352.24 94.98 350.99 134.85 389.69 144.8 380.43 102" style="fill: #e7e5df"/>
                  <polygon points="396.13 93.95 366.03 87.1 352.24 94.98 380.43 102 396.13 93.95" style="fill: #cabeaf"/>
                  <path d="M440.92,298.11" transform="translate(-51.09 -206.61)" style="fill: #323232"/>
                  <path d="M436.35,297.28l.21,2.53s-.48,3.22-9.82,4-10.66-2.3-10.66-2.3l-.33-4.09Z" transform="translate(-51.09 -206.61)" style="fill: #f2ca8b"/>
                  <path d="M441.25,285.6l-.47,5.71s.93,6.68-13.55,8.71c-15.53,2.18-17.54-3.92-17.68-5.68L408.13,277l32.55-2.66Z" transform="translate(-51.09 -206.61)" style="fill: #f7e1b9"/>
                  <path d="M408.49,281.44s2.06,4.08,10.64,2.65c5-.83,7.09-1.8,7.58,3.61s3.29,8.32,2.42,9.59-7.23,3.55-13.76,2.51c0,0,8.74,2.66,20.07-1.62,5.64-2.13,7.52-5.43,7-9.4-.75-5.78-1.77-10.71-1.77-10.71l-10.73-8S407.09,271.36,408.49,281.44Z" transform="translate(-51.09 -206.61)" style="fill: #e8b674"/>
                  <g>
                    <path d="M415.81,286.49Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <circle cx="370.95" cy="80.1" r="2.07" style="fill: #162d33"/>
                    <ellipse cx="421.9" cy="286.12" rx="0.62" ry="0.7" transform="translate(-75.15 -167.45) rotate(-5.17)" style="fill: #fef8f5"/>
                    <path d="M411,286c.06,1,.9,2.17,1.77,2.13s1.08-.81,1-1.86c-.07-1.41-.93-2.2-1.8-2.15S410.93,284.93,411,286Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <ellipse cx="412.28" cy="285.6" rx="0.48" ry="0.63" transform="translate(-65.75 -184.2) rotate(-3.06)" style="fill: #fef8f5"/>
                  </g>
                  <path d="M441.25,280a28.22,28.22,0,0,0-12.08,0c-7.09,1.4-23,4.55-31.55.51-10.37-4.91-.13-6.66,4.46-8.48,5.43-2.15,8.67-10.5,19.37-13.4,12-3.25,20.44,2.15,22.34,10.27C444.79,273.22,443.22,278.82,441.25,280Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <path d="M419.13,283.26a4,4,0,0,1,3-1.5,5.69,5.69,0,0,1,3.6,1.5S421.51,282.51,419.13,283.26Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <path d="M409.79,283.26a4,4,0,0,1,3-1.5,5.65,5.65,0,0,1,3.59,1.5S412.18,282.51,409.79,283.26Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <path d="M408.32,270.09s11.06-7.43,22.29-4.42A97.5,97.5,0,0,0,408.32,270.09Z" transform="translate(-51.09 -206.61)" style="fill: #686868"/>
                  <path d="M417.66,296.37a1.44,1.44,0,0,1-1.22-.79,1.47,1.47,0,0,1-1.23.79,1.3,1.3,0,0,1-.91-.39,4.32,4.32,0,0,0-.06.73c0,1.75,1.06,3.16,2.36,3.16s2.37-1.41,2.37-3.16a4.44,4.44,0,0,0-.13-1A1.43,1.43,0,0,1,417.66,296.37Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  <path d="M425.2,307a13.06,13.06,0,0,1-9.42,1.57c-3.33-.68-5.3-3.77-6.14-5.44l-4.05-1a25.42,25.42,0,0,0,3.57,6.45c2.45,2.91,5.71,3.95,11.4,3.95,4.48,0,7.23-3.26,8.19-4.64Z" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                  <g>
                    <path d="M410.36,307.09s1.3,2.59,5.45,3.36" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                    <path d="M415.81,310.88h-.08c-4.31-.8-5.7-3.47-5.75-3.59a.43.43,0,0,1,.19-.58.44.44,0,0,1,.58.19h0c.05.1,1.27,2.41,5.14,3.13a.44.44,0,0,1,.35.51A.43.43,0,0,1,415.81,310.88Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  </g>
                  <g>
                    <path d="M415.73,309a13.56,13.56,0,0,0,10.09-1.84l-1.18-.29a12.77,12.77,0,0,1-8.74,1.29c-2.93-.59-4.77-3.23-5.66-4.88l-1.12-.28C409.88,304.67,412,308.27,415.73,309Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <path d="M428.33,307.82c-1,1.38-3.61,4.31-7.74,4.31-5.66,0-8.76-1.07-11.07-3.8a24.52,24.52,0,0,1-3.38-6l-1-.26a26.47,26.47,0,0,0,3.76,6.86c2.49,2.95,5.78,4.1,11.73,4.1,4.87,0,7.73-3.59,8.64-4.95Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  </g>
                  <g>
                    <path d="M421.25,307.84s4.07-.39,4.68.77-.24,5.59,1,7.89c0,0-4.77,0-4.49-4.36l-1,.91" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                    <path d="M427,316.93a5.85,5.85,0,0,1-3.88-1.54,3.92,3.92,0,0,1-1-2.31l-.34.3a.43.43,0,1,1-.57-.65l1-.92a.43.43,0,0,1,.47-.06.43.43,0,0,1,.24.42,3.35,3.35,0,0,0,.81,2.62,4.43,4.43,0,0,0,2.55,1.21,15.54,15.54,0,0,1-.5-4.67,8.14,8.14,0,0,0-.21-2.52c-.31-.59-2.67-.69-4.26-.54a.42.42,0,0,1-.47-.39.43.43,0,0,1,.39-.47c.73-.07,4.39-.35,5.11,1a7.6,7.6,0,0,1,.31,2.91c0,1.65,0,3.71.7,5a.42.42,0,0,1,0,.43A.44.44,0,0,1,427,316.93Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  </g>
                  <g>
                    <polygon points="365.45 101.23 370.16 101.23 370.34 106.44 364.65 106.44 365.45 101.23" style="fill: #77a3bf"/>
                    <path d="M421.43,313.49h-5.68a.45.45,0,0,1-.33-.15.45.45,0,0,1-.1-.35l.79-5.21a.44.44,0,0,1,.43-.37h4.71a.42.42,0,0,1,.43.42l.19,5.21a.43.43,0,0,1-.12.31A.45.45,0,0,1,421.43,313.49Zm-5.18-.87H421l-.16-4.35h-3.92Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  </g>
                  <g>
                    <path d="M421.43,309.66s2.57-.65,2.67.79" transform="translate(-51.09 -206.61)" style="fill: #77a3bf"/>
                    <path d="M424.1,310.88a.43.43,0,0,1-.43-.4.41.41,0,0,0-.18-.35,3,3,0,0,0-1.95-.05.42.42,0,0,1-.52-.32.43.43,0,0,1,.31-.52,3.76,3.76,0,0,1,2.67.19,1.29,1.29,0,0,1,.54,1,.44.44,0,0,1-.41.46Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  </g>
                  <g>
                    <line x1="366.07" y1="106.44" x2="366.95" y2="138.91" style="fill: #77a3bf"/>
                    <path d="M418,346a.43.43,0,0,1-.43-.42l-.88-32.47a.45.45,0,0,1,.42-.45.44.44,0,0,1,.45.42l.87,32.47a.44.44,0,0,1-.42.45Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                  </g>
                  <g>
                    <path d="M429.17,322.62a8.06,8.06,0,0,0,6.68-5.37c1.95-5.12,6.2-13.86,12.12-10.15s5.74,12.14,5,15.87-6.81,12.63-10.33,14-12.25.19-12.25.19l-.19-2.25a6,6,0,0,0,4-7c-.88-5.52-4.78-3.05-4.78-3.05Z" transform="translate(-51.09 -206.61)" style="fill: #cabeaf"/>
                    <path d="M429.35,324.9s3.6-2.64,4.78,3.05a5.62,5.62,0,0,1-4,7Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <path d="M429.89,337.32c5.15-1.39,7.39-5.16,6.13-10.33-.56-2.32-1.62-3.82-3.16-4.47a5.43,5.43,0,0,0-4.45.41l1.12,1.92a3.17,3.17,0,0,1,2.48-.27c.84.36,1.47,1.35,1.85,2.94,1,4-.56,6.58-4.55,7.66Z" transform="translate(-51.09 -206.61)" style="fill: #e7e5df"/>
                    <path d="M415.08,334.65s1.43-2.71,6.62-2.21l6.4-.42-.7-11.87a9.29,9.29,0,0,0-7.56-2c-4.75.74-6.43,3.77-6.73,6.06S415.08,334.65,415.08,334.65Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                    <path d="M429.31,321.25l1.3,16s-4.79,3.42-8.7-2.2l-.66-8S428,326.83,429.31,321.25Z" transform="translate(-51.09 -206.61)" style="fill: #254553"/>
                    <rect x="412.43" y="324.22" width="2.22" height="10.54" transform="translate(801.48 417.54) rotate(175.32)" style="fill: #254553"/>
                    <path d="M423.78,327.05a6.82,6.82,0,0,0,4.06-1.52,5,5,0,0,0,1.47-4.28,4.4,4.4,0,0,0-2.86-3.67,11,11,0,0,0-5.1-.68c-4.8.4-9.7,3.13-9.35,7.43l2.22-.18c-.23-2.76,3.54-4.72,7.31-5a8.62,8.62,0,0,1,4,.5c1.43.62,1.51,1.52,1.53,1.82a2.8,2.8,0,0,1-.75,2.45c-1.3,1.19-4,1-5,.88l-.09,2.24A10.36,10.36,0,0,0,423.78,327.05Z" transform="translate(-51.09 -206.61)" style="fill: #3b6a7a"/>
                  </g>
                  <path d="M416.3,295.23a3.17,3.17,0,0,1-2.39-1.32.59.59,0,0,1,.07-.82.58.58,0,0,1,.81.07,2,2,0,0,0,1.58.91,2.44,2.44,0,0,0,1.55-1,.59.59,0,0,1,.8-.16.57.57,0,0,1,.16.8,3.54,3.54,0,0,1-2.43,1.54Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                </g>
                <path d="M303.39,475a3.49,3.49,0,0,1-2.59-1.21.56.56,0,0,1,.08-.81.57.57,0,0,1,.81.08,2.24,2.24,0,0,0,2.46.64c1.15-.43,1.1-2,1.09-2a.58.58,0,0,1,1.16-.06c0,.1.1,2.45-1.84,3.18A3.21,3.21,0,0,1,303.39,475Z" transform="translate(-51.09 -206.61)" style="fill: #162d33"/>
                <g>
                  <path d="M213.33,632.66a.64.64,0,0,0,.1-.13l.06-.07.06-.14,0-.06a.75.75,0,0,0,0-.2v-3.18a.76.76,0,0,1,0,.21l0,.06-.06.14-.06.07-.1.12-.08.08-.12.11-.17.12-.13.09-.23.12-.15.08-.31.13-.15.06-.53.18a6.76,6.76,0,0,1-.68.17l-.53.13-.71.11c-.19,0-.38.07-.58.09l-.76.07-.62.06-.81,0-.61,0-.82,0-.61,0c-.28,0-.55,0-.83-.07l-.55,0c-.3,0-.6-.09-.89-.14-.13,0-.28,0-.41-.06a11.82,11.82,0,0,1-1.17-.28c-1.49-.44-2.24-1-2.24-1.59v3.17c0,.58.75,1.16,2.24,1.59.37.11.76.2,1.17.28l.41.07c.3,0,.59.1.89.13a5.11,5.11,0,0,0,.55,0c.28,0,.55.06.84.07l.6,0,.82,0,.61,0c.27,0,.54,0,.81,0l.62,0,.76-.07.59-.1c.23,0,.47-.06.7-.11l.53-.13a5.09,5.09,0,0,0,.59-.14l.09,0a3.66,3.66,0,0,0,.53-.18l.15-.06.31-.13.15-.08.23-.12L213,633l.17-.13.06,0,.06-.06Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                  <path d="M211.38,627.29c2.94.87,3,2.28.08,3.16a21.77,21.77,0,0,1-10.58,0c-2.95-.87-3-2.28-.09-3.16A21.81,21.81,0,0,1,211.38,627.29Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                </g>
                <g>
                  <path d="M228.4,628.28l.1-.12a.35.35,0,0,0,.05-.08.83.83,0,0,0,.07-.13s0-.05,0-.07a.71.71,0,0,0,0-.2v-3.17a.75.75,0,0,1,0,.2s0,0,0,.06a.91.91,0,0,1-.07.14l-.05.07a.64.64,0,0,1-.1.13l-.08.08-.12.1-.18.13-.13.08-.22.12-.16.08a2.71,2.71,0,0,1-.3.13l-.15.06a4.12,4.12,0,0,1-.53.18,6.76,6.76,0,0,1-.68.17l-.54.13c-.22,0-.46.08-.7.11l-.59.1-.76.07-.62.05c-.26,0-.54,0-.81,0l-.61,0-.82,0c-.2,0-.4,0-.6,0s-.56,0-.84-.07a4.93,4.93,0,0,1-.54,0c-.31,0-.6-.08-.89-.13l-.41-.07a12,12,0,0,1-1.18-.28c-1.49-.43-2.24-1-2.24-1.59v3.17c0,.58.75,1.16,2.24,1.6.37.1.76.2,1.18.28l.41.06.89.14.54,0,.84.07h.6c.28,0,.55,0,.82,0h.61c.27,0,.55,0,.81,0l.62,0,.76-.07.59-.09c.24,0,.48-.07.7-.12s.36-.08.54-.12.4-.09.59-.15l.09,0,.53-.18.15-.06.3-.13.16-.08.22-.13a.57.57,0,0,0,.13-.08,1.18,1.18,0,0,0,.18-.13s0,0,.05,0l.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #d360ad"/>
                  <path d="M226.44,622.91c2.94.87,3,2.28.09,3.16a21.57,21.57,0,0,1-10.59,0c-2.95-.86-3-2.28-.09-3.16A21.81,21.81,0,0,1,226.44,622.91Z" transform="translate(-51.09 -206.61)" style="fill: #fcc0f6"/>
                </g>
                <g>
                  <g>
                    <path d="M462.84,398a.52.52,0,0,0,.1-.11l.06-.07a.54.54,0,0,0,.07-.13s0,0,0-.06a.58.58,0,0,0,0-.19v-3a.65.65,0,0,1,0,.19l0,.06a.83.83,0,0,1-.07.13l-.06.07-.1.11-.09.08-.13.09-.18.12-.15.08-.24.12-.16.07-.33.12-.16.06-.57.17c-.23.06-.49.1-.73.15s-.38.09-.58.12l-.76.11-.63.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.06l-.59-.05c-.33,0-.64-.08-1-.13l-.44-.05c-.44-.08-.87-.17-1.26-.27-1.6-.41-2.41-.95-2.41-1.48v3c0,.54.81,1.08,2.41,1.48.39.11.82.19,1.26.27l.44.06c.32,0,.63.09,1,.12l.59,0,.9.07h.64l.89,0h.65c.29,0,.59,0,.87,0l.67,0,.81-.06.64-.09.75-.1.58-.12.63-.14.1,0,.57-.17.16-.06.33-.12.16-.07.24-.12a.71.71,0,0,0,.15-.08l.18-.11.06,0,.07-.06Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                    <path d="M460.73,393c3.17.8,3.21,2.12.1,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.81-3.21-2.13-.09-2.95A26.74,26.74,0,0,1,460.73,393Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                  </g>
                  <g>
                    <path d="M479.13,402.07a1.14,1.14,0,0,0,.11-.11l.06-.07a.67.67,0,0,0,.06-.12s0,0,0-.07a.39.39,0,0,0,0-.18v-3a.41.41,0,0,1,0,.19s0,0,0,.06a.83.83,0,0,1-.07.13l-.05.07-.11.11-.09.08-.13.09-.18.12-.14.08-.25.12-.16.07-.33.12-.16.06-.57.17c-.23.06-.49.1-.73.15s-.37.09-.58.12-.5.08-.75.11l-.64.08-.81.07-.67.05-.87,0-.65,0-.89,0-.64,0c-.31,0-.6,0-.9-.06l-.59-.05c-.33,0-.64-.08-1-.12-.14,0-.29,0-.43-.06-.45-.08-.87-.17-1.27-.27-1.6-.41-2.4-.94-2.4-1.48v3c0,.54.8,1.08,2.4,1.49.4.1.82.18,1.27.26l.44.06.95.13.59,0,.9.07h.65l.88,0h.65c.3,0,.59,0,.88,0l.66-.05c.27,0,.55,0,.82-.06l.63-.09.76-.1.57-.12L477,403l.1,0c.21-.05.39-.11.57-.17l.16-.06.33-.12.16-.07.25-.12.14-.08.18-.11.06,0,.07-.06A.73.73,0,0,0,479.13,402.07Z" transform="translate(-51.09 -206.61)" style="fill: #82e0e0"/>
                    <path d="M477,397.07c3.16.8,3.2,2.13.09,2.95a26.79,26.79,0,0,1-11.38,0c-3.17-.8-3.21-2.12-.09-3A26.79,26.79,0,0,1,477,397.07Z" transform="translate(-51.09 -206.61)" style="fill: #c5fffb"/>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </v-col>
        <v-col class="col-community" cols="5">
          <h2 class="header-community">Mit der besten Community auf der Welt</h2>
          <p class="text-community">Wir bringen die Community zusammen! Teile Deine LEGO Collection mit anderen Usern,
            trete unserem Discord bei und tausche Dich mit anderen aus oder schau Dir einfach nur eines der vielen
            Videos von unseren Content Creator an. Und natürlich findest Du uns auch auf Telegram.</p>
          <a target="_blank" href="https://t.me/lego_angebote"><PrimaryButton id="button-telegram" faIconClass="fab fa-telegram-plane" /></a>
          <a target="_blank" href="https://discord.gg/ydQYy8tv63"><PrimaryButton id="button-discord" faIconClass="fab fa-discord" /></a>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="section-deals">
    <v-container>
      <v-row no-gutters class="row-deals scroll_fade scroll_fade_fromRight">
        <v-col class="col-deals" cols="5">
          <h2 class="header-deals">Jäger und Sammler sind hier genau richtig</h2>
          <p class="text-deals">Ein Ort für alle LEGO Preisvergleicher, Schnäppchenjäger und Sparfüchse.
            Hier findest Du die Deals zu Deinem neuen Lieblingsstück.</p>
          <PrimaryButton id="button-deal" @click="deals" buttonText="Zu den Deals" />
        </v-col>

        <v-col cols="7">
          <svg id="img-deals" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.19 522.67">
            <g style="isolation: isolate">
              <g>
                <g>
                  <polygon points="42.61 462.14 5.65 476.07 92.4 497.67 42.61 462.14" style="fill: #e5e5e5;mix-blend-mode: multiply"/>
                  <polygon points="175.71 430.87 138.75 444.79 227.88 466.93 175.71 430.87" style="fill: #e5e5e5;mix-blend-mode: multiply"/>
                  <polygon points="342.58 493.49 305.63 507.42 362.51 522.67 342.58 493.49" style="fill: #e5e5e5;mix-blend-mode: multiply"/>
                  <polygon points="295.73 450.6 259.43 461.43 304.32 471.1 295.73 450.6" style="fill: #e5e5e5;mix-blend-mode: multiply"/>
                  <polygon points="172.12 443.65 135.82 454.48 180.71 464.15 172.12 443.65" style="fill: #e5e5e5;mix-blend-mode: multiply"/>
                  <polygon points="109.66 410.43 73.35 421.26 118.24 430.93 109.66 410.43" style="fill: #bdbdbd;mix-blend-mode: multiply"/>
                  <polygon points="332.18 404.54 264.77 404.54 268.65 424.2 280.6 428.36 255.97 435.71 374 461.43 332.18 404.54" style="fill: #e5e5e5;mix-blend-mode: multiply"/>
                </g>
                <g>
                  <path d="M404.4,431.94a1.62,1.62,0,0,1-.88-.33,1.6,1.6,0,0,1-.29-2.23c47.1-61,56.15-103.22,55.46-127.9-.6-21.06-8.31-31.34-8.39-31.44a1.59,1.59,0,0,1,2.52-2c.34.44,8.42,11.1,9,33.3.71,25.2-8.44,68.2-56.11,129.93A1.57,1.57,0,0,1,404.4,431.94Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M470.6,282.89c-7.87,1.53-4.11,18.06-9.61,20.58,0,0,12.93.45,15.54-9.31C478.65,286.22,473.67,282.29,470.6,282.89Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M467.69,259.83c-7.87,1.54-4.11,18.07-9.61,20.59,0,0,12.93.45,15.54-9.31C475.74,263.17,470.76,259.23,467.69,259.83Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M475.26,309c-7.83-1.7-10.93,15-17,15.09,0,0,11.7,5.54,18-2.39C481.33,315.21,478.32,309.62,475.26,309Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M468.23,335.66c-7.84-1.71-10.93,15-17,15.09,0,0,11.69,5.54,17.95-2.39C474.3,341.91,471.29,336.33,468.23,335.66Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M446.2,314.17c7.94,1.14,5,17.84,10.62,20.08,0,0-12.89,1.09-16-8.53C438.32,317.9,443.1,313.72,446.2,314.17Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M436,342.8c7.93,1.15,5,17.84,10.62,20.08,0,0-12.89,1.1-16-8.52C428.1,346.54,432.88,342.36,436,342.8Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M438.28,282.72c7.11-3.7,14.46,11.58,20.34,10.12,0,0-9.84,8.41-18,2.4C434.06,290.35,435.5,284.17,438.28,282.72Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M431.07,259.18c7.11-3.7,14.47,11.58,20.34,10.12,0,0-9.84,8.41-18,2.4C426.85,266.81,428.29,260.63,431.07,259.18Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                  <path d="M453.49,246.5c-6.84,4.19,2.46,18.36-1.81,22.64,0,0,12.27-4.1,11.3-14.15C462.2,246.81,456.15,244.86,453.49,246.5Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                </g>
                <g>
                  <path d="M390.86,432.36a1.61,1.61,0,0,1-.44-.06,1.81,1.81,0,0,1-1.32-2.2c23.69-95.15,12.64-149-.8-177.43-11.49-24.3-25.77-32.35-25.92-32.43a1.81,1.81,0,0,1,1.74-3.18c.62.33,15.42,8.59,27.46,34.06,13.69,29,25,83.66,1,179.86A1.81,1.81,0,0,1,390.86,432.36Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M392.48,225c-8.38,5.81,4.45,23.13-.67,28.88,0,0,15.29-6.08,13.34-18.78C403.57,224.79,395.76,222.75,392.48,225Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M377.32,199.65c-8.38,5.81,4.44,23.14-.67,28.89,0,0,15.28-6.08,13.33-18.78C388.4,199.43,380.59,197.39,377.32,199.65Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M411.24,253c-10,2-5.09,23-12.06,26.25,0,0,16.44.48,19.69-12C421.5,257.17,415.14,252.21,411.24,253Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M416.69,287.69c-10,2-5.08,23-12.06,26.25,0,0,16.45.47,19.69-12C427,291.87,420.59,286.9,416.69,287.69Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M380.06,273.92c9.82-2.73,14.94,18.22,22.63,18,0,0-14.46,7.86-23-1.77C372.79,282.29,376.22,275,380.06,273.92Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M382.78,312.49c9.83-2.73,15,18.22,22.64,18,0,0-14.46,7.86-23-1.76C375.52,320.86,379,313.55,382.78,312.49Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M353.86,239.75c6.39-7.94,22.76,6.09,28.85,1.39,0,0-7.15,14.82-19.68,12C352.84,250.79,351.36,242.85,353.86,239.75Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M334.34,217.61c6.39-7.94,22.76,6.09,28.86,1.4,0,0-7.16,14.81-19.69,12C333.32,228.65,331.84,220.71,334.34,217.61Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                  <path d="M354,191.38c-5.82,8.38,12.25,20.13,9.47,27.3,0,0,12.19-11,5.93-22.26C364.26,187.3,356.23,188.12,354,191.38Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                </g>
                <path d="M163.43,560H76v-29.2S60,305.61,203.48,305.61c51.57,0,14.93-64.11,76.42-64.11C370.67,241.5,333.65,385,421.63,385,512,385,514,480.08,514,536.55v23.72Z" transform="translate(-48.8 -170.25)" style="fill: #eefff6"/>
                <rect y="389.07" width="493.19" height="1.45" style="fill: #005541"/>
                <path d="M419.54,206.08a9.33,9.33,0,0,1,2.44.33A10.81,10.81,0,0,1,433.86,200a10.83,10.83,0,0,1,18.92,2,10.69,10.69,0,0,1,3.26-.51,10.83,10.83,0,0,1,10.83,10.83h-56A9.26,9.26,0,0,1,419.54,206.08Z" transform="translate(-48.8 -170.25)" style="fill: #dee2ff"/>
                <path d="M207.5,184.24a12,12,0,0,0-3.14.42,14,14,0,0,0-12.91-8.56,13.32,13.32,0,0,0-2.46.22,14,14,0,0,0-24.44,2.51,14,14,0,0,0-18.21,13.35h72.41A11.94,11.94,0,0,0,207.5,184.24Z" transform="translate(-48.8 -170.25)" style="fill: #dee2ff"/>
                <g>
                  <g>
                    <path d="M110.05,543.81h53.51l-5.72,24.74h0c0,1.7-2.1,3.41-6.29,4.7-8.28,2.55-21.6,2.52-29.75-.08-4-1.29-5.74-2.89-6-4.62h0Z" transform="translate(-48.8 -170.25)" style="fill: #00a6a9"/>
                    <path d="M118,537.82c-10.53,3.24-10.67,8.56-.32,11.86s27.31,3.35,37.84.11,10.68-8.55.31-11.86S128.58,534.57,118,537.82Z" transform="translate(-48.8 -170.25)" style="fill: #00cbbf"/>
                    <path d="M113.38,544c-.12-.14-.22-.27-.32-.41l-.18-.26a3.68,3.68,0,0,1-.21-.46c0-.08-.07-.15-.09-.23a2.6,2.6,0,0,1-.1-.68v-4.55a2.3,2.3,0,0,0,.11.68,2.18,2.18,0,0,0,.09.23c.06.15.12.3.2.46l.18.26c.1.14.2.27.32.41l.26.27.4.36c.17.14.36.29.56.43l.43.29c.22.14.48.28.73.42l.5.27c.31.15.64.29,1,.44l.5.21c.53.21,1.1.42,1.72.62s1.47.39,2.21.57c.58.15,1.13.31,1.73.44s1.52.26,2.3.39c.63.1,1.25.22,1.91.31s1.64.16,2.47.23c.67.07,1.32.15,2,.19.87,0,1.76.06,2.64.09.66,0,1.31,0,2,.06.9,0,1.78,0,2.67-.08.66,0,1.31,0,2-.06.92,0,1.81-.15,2.72-.24.59-.06,1.19-.09,1.77-.16,1-.13,1.94-.3,2.9-.47.43-.08.9-.13,1.32-.22a38,38,0,0,0,3.82-1c4.85-1.49,7.28-3.46,7.28-5.43v4.55c0,2-2.43,3.94-7.27,5.43a38,38,0,0,1-3.82,1c-.42.09-.89.14-1.33.22-.95.17-1.89.34-2.89.47-.58.07-1.18.1-1.77.16-.91.09-1.8.19-2.72.24-.65,0-1.3,0-2,.06-.89,0-1.77.08-2.67.08l-2-.06c-.88,0-1.76,0-2.63-.09-.69,0-1.34-.12-2-.19-.83-.07-1.66-.13-2.47-.23-.66-.09-1.27-.21-1.91-.31s-1.56-.24-2.29-.39-1.15-.29-1.73-.44-1.32-.3-1.92-.48l-.3-.09c-.61-.2-1.18-.41-1.72-.62l-.49-.21c-.34-.14-.68-.29-1-.44l-.5-.27c-.25-.14-.51-.28-.73-.42l-.43-.29a6.34,6.34,0,0,1-.56-.43l-.18-.13-.22-.23Z" transform="translate(-48.8 -170.25)" style="fill: #72482b"/>
                    <path d="M119.74,531.94c-9.56,2.95-9.69,7.78-.28,10.79s24.82,3,34.39.09,9.72-7.77.29-10.78S129.33,529,119.74,531.94Z" transform="translate(-48.8 -170.25)" style="fill: #593924"/>
                    <path d="M117.39,558l1.52,11.1a8,8,0,0,1-3.63-2.67l.36,1.53a14.49,14.49,0,0,0,5.24,2.67,54.87,54.87,0,0,0,15.83,2.08,55.56,55.56,0,0,0,15.54-2l1-.33h0a17.68,17.68,0,0,0,4.67-2.18l.28-1.21a10.92,10.92,0,0,1-4.06,2.29l1.53-11.11a22.51,22.51,0,0,0,5.06-2.16l.24-1a20.67,20.67,0,0,1-5.52,2.48c-10.46,3.22-27.33,3.17-37.62-.11a19.78,19.78,0,0,1-5.19-2.38l.24,1A21.89,21.89,0,0,0,117.39,558Zm29.11,13.25.69-11.31a54.06,54.06,0,0,0,7.7-1.53l-1.53,11.17L152,570A39,39,0,0,1,146.5,571.26Zm-9.43.72.1-11.38a82.49,82.49,0,0,0,9.28-.55l-.7,11.32A65.69,65.69,0,0,1,137.07,572Zm-10.45-12a80.21,80.21,0,0,0,9.81.62l-.1,11.38a65.5,65.5,0,0,1-9-.66Zm-8.45-1.73a51.42,51.42,0,0,0,7.71,1.63l.7,11.32a37.8,37.8,0,0,1-5.48-1.27c-.49-.15-1-.32-1.4-.49Z" transform="translate(-48.8 -170.25)" style="fill: #00cbbf"/>
                  </g>
                  <g>
                    <polygon points="88.72 326.56 89.33 324.18 87.77 323.79 86.29 325 88.72 326.56" style="fill: #80b178"/>
                    <path d="M109.52,467.28" transform="translate(-48.8 -170.25)" style="fill: #80b178"/>
                  </g>
                  <rect x="134.04" y="475.86" width="5.53" height="61.72" rx="2.76" transform="translate(224.8 843.2) rotate(-180)" style="fill: #72482b"/>
                  <g>
                    <path d="M99.67,474.41c16.24,0,27,8.77,28.84,20.22a1.07,1.07,0,0,0,1.06.92h1.85a1.44,1.44,0,0,0,1.1-.5,5.47,5.47,0,0,1,4.2-2,1.44,1.44,0,0,1,1.38,1c.2.92-.57,3.61-1.48,3.5s-1.68-1.49-2.14-.76a2.89,2.89,0,0,0,2.27,4.58,1.38,1.38,0,0,1,1.35,1c.2.88-.26,3.56-1.11,3.56-1.72,0-3.46-2.7-4.47-3.95a1.44,1.44,0,0,0-1.1-.5h-1.94a1.08,1.08,0,0,0-1.06.9c-2.09,11.22-12.74,19.75-28.75,19.75-17.92,0-36.33-14.55-36.33-22.54C63.34,492.71,81.75,474.41,99.67,474.41Z" transform="translate(-48.8 -170.25)" style="fill: #4e5aad"/>
                    <path d="M99.1,476.57c16.23,0,27,8.77,28.83,20.22a1.08,1.08,0,0,0,1.06.92h1.85a1.41,1.41,0,0,0,1.1-.5,5.49,5.49,0,0,1,4.2-2,1.44,1.44,0,0,1,1.39,1,1.34,1.34,0,0,1-1.48,1.62,2.28,2.28,0,0,0-2.14,1.12,2.89,2.89,0,0,0,2.27,4.58,1.37,1.37,0,0,1,1.34,1,1.34,1.34,0,0,1-1.3,1.66,5.5,5.5,0,0,1-4.28-2,1.41,1.41,0,0,0-1.1-.5h-1.93a1.07,1.07,0,0,0-1.06.9c-2.09,11.22-12.74,19.75-28.75,19.75-17.92,0-35.76-15.88-35.76-23.87C63.34,493.54,81.18,476.57,99.1,476.57Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                    <path d="M78.72,500.94h48.07a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H78.72a.5.5,0,0,0-.5.5A.5.5,0,0,0,78.72,500.94Z" transform="translate(-48.8 -170.25)" style="fill: #4e5aad"/>
                    <path d="M112.64,500.94a.46.46,0,0,0,.29-.1.5.5,0,0,0,.12-.69l-6.74-9.46a.5.5,0,0,0-.7-.12.51.51,0,0,0-.11.7l6.73,9.46A.52.52,0,0,0,112.64,500.94Z" transform="translate(-48.8 -170.25)" style="fill: #4e5aad"/>
                    <path d="M95.34,510.65a.49.49,0,0,0,.41-.21l6.74-9.46a.5.5,0,0,0-.12-.69.51.51,0,0,0-.7.11l-6.73,9.46a.51.51,0,0,0,.11.7A.52.52,0,0,0,95.34,510.65Z" transform="translate(-48.8 -170.25)" style="fill: #4e5aad"/>
                  </g>
                  <rect x="134.04" y="489.18" width="4.58" height="9.77" transform="translate(223.85 817.89) rotate(-180)" style="fill: #72482b"/>
                  <g>
                    <path d="M162.79,442.16c0,16.24-8.77,27-20.22,28.84a1.08,1.08,0,0,0-.93,1.06v1.85a1.42,1.42,0,0,0,.51,1.1,5.54,5.54,0,0,1,2,4.2,1.45,1.45,0,0,1-1,1.38c-.93.2-3.73-.53-3.62-1.44s1.61-1.72.88-2.18a2.89,2.89,0,0,0-4.58,2.27,1.38,1.38,0,0,1-1,1.35c-.87.2-3.55-.41-3.55-1.25,0-1.73,2.7-3.32,3.94-4.33a1.41,1.41,0,0,0,.5-1.1V472a1.08,1.08,0,0,0-.9-1.06c-11.21-2.09-19.74-12.74-19.74-28.75,0-17.92,14.55-36.33,22.54-36.33C144.48,405.83,162.79,424.24,162.79,442.16Z" transform="translate(-48.8 -170.25)" style="fill: #346b59"/>
                    <path d="M160.63,441.59c0,16.23-8.77,27-20.22,28.83a1.09,1.09,0,0,0-.93,1.06v1.85a1.39,1.39,0,0,0,.51,1.1,5.55,5.55,0,0,1,2,4.2,1.45,1.45,0,0,1-1,1.39,1.35,1.35,0,0,1-1.63-1.48,2.26,2.26,0,0,0-1.11-2.14,2.89,2.89,0,0,0-4.58,2.27,1.37,1.37,0,0,1-1,1.34,1.33,1.33,0,0,1-1.65-1.3,5.51,5.51,0,0,1,2-4.28,1.38,1.38,0,0,0,.5-1.1v-1.94a1.07,1.07,0,0,0-.9-1.05c-11.21-2.09-19.74-12.74-19.74-28.75,0-17.92,15.88-35.76,23.87-35.76C143.65,405.83,160.63,423.67,160.63,441.59Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                    <path d="M136.26,421.2v48.08a.5.5,0,0,0,1,0V421.2a.5.5,0,0,0-1,0Z" transform="translate(-48.8 -170.25)" style="fill: #346b59"/>
                    <path d="M136.26,455.13a.52.52,0,0,0,.09.29.5.5,0,0,0,.7.11l9.46-6.73a.51.51,0,0,0,.11-.7.49.49,0,0,0-.69-.11l-9.46,6.73A.49.49,0,0,0,136.26,455.13Z" transform="translate(-48.8 -170.25)" style="fill: #346b59"/>
                    <path d="M126.54,437.83a.52.52,0,0,0,.21.41l9.46,6.74a.51.51,0,0,0,.7-.12.5.5,0,0,0-.12-.7l-9.46-6.73a.5.5,0,0,0-.69.11A.46.46,0,0,0,126.54,437.83Z" transform="translate(-48.8 -170.25)" style="fill: #346b59"/>
                  </g>
                  <path d="M134,482.55l0-3.86a2.58,2.58,0,0,1,2.79-2.83,2.44,2.44,0,0,1,2.57,2.56l-.75,2.21Z" transform="translate(-48.8 -170.25)" style="fill: #72482b"/>
                  <g>
                    <polygon points="404.74 240.12 406 238 407.37 238.84 407.56 240.73 404.74 240.12" style="fill: #80b178"/>
                    <path d="M494.22,409.29" transform="translate(-48.8 -170.25)" style="fill: #80b178"/>
                  </g>
                  <rect x="444.28" y="387.03" width="5.53" height="61.72" rx="2.76" transform="translate(377.62 -363.96) rotate(45)" style="fill: #72482b"/>
                  <g>
                    <path d="M496.15,421.29c-11.48-11.48-25.27-12.86-34.69-6.09a1.08,1.08,0,0,1-1.4-.1l-1.31-1.31a1.41,1.41,0,0,1-.42-1.13,5.51,5.51,0,0,0-1.53-4.41,1.43,1.43,0,0,0-1.71-.25c-.8.52-2.16,3-1.43,3.53s2.24.14,2,1a2.9,2.9,0,0,1-4.85,1.64,1.36,1.36,0,0,0-1.67-.23c-.76.47-2.34,2.7-1.74,3.3,1.22,1.22,4.36.53,6,.37a1.41,1.41,0,0,1,1.13.42l1.37,1.37a1.1,1.1,0,0,1,.12,1.39c-6.46,9.41-5,23,6.36,34.29,12.67,12.67,36,15.4,41.63,9.75C508.9,459.93,508.82,434,496.15,421.29Z" transform="translate(-48.8 -170.25)" style="fill: #346b59"/>
                    <path d="M495,423.22c-11.48-11.48-25.27-12.86-34.69-6.09a1.08,1.08,0,0,1-1.4-.09l-1.31-1.31a1.4,1.4,0,0,1-.42-1.14,5.51,5.51,0,0,0-1.53-4.41,1.44,1.44,0,0,0-1.71-.24,1.33,1.33,0,0,0-.11,2.19,2.29,2.29,0,0,1,.73,2.31,2.9,2.9,0,0,1-4.85,1.63,1.37,1.37,0,0,0-1.67-.23,1.34,1.34,0,0,0-.25,2.09,5.48,5.48,0,0,0,4.47,1.58,1.45,1.45,0,0,1,1.13.42l1.37,1.38a1.08,1.08,0,0,1,.11,1.38c-6.45,9.41-4.95,23,6.37,34.29,12.67,12.67,36.52,14.06,42.17,8.41C508.31,460.51,507.7,435.89,495,423.22Z" transform="translate(-48.8 -170.25)" style="fill: #5ca58e"/>
                    <path d="M492.21,454.87l-34-34a.5.5,0,1,1,.71-.71l34,34a.5.5,0,0,1-.71.71Z" transform="translate(-48.8 -170.25)" style="fill: #346b59"/>
                    <path d="M468.22,430.88a.5.5,0,0,1-.14-.27.51.51,0,0,1,.41-.58l11.45-1.92a.5.5,0,1,1,.17,1L468.66,431A.5.5,0,0,1,468.22,430.88Z" transform="translate(-48.8 -170.25)" style="fill: #346b59"/>
                    <path d="M473.58,450a.5.5,0,0,1-.14-.44l1.93-11.45a.5.5,0,0,1,.57-.41.51.51,0,0,1,.41.58l-1.92,11.45a.51.51,0,0,1-.58.41A.5.5,0,0,1,473.58,450Z" transform="translate(-48.8 -170.25)" style="fill: #346b59"/>
                  </g>
                  <rect x="454.04" y="404.39" width="4.58" height="9.77" transform="translate(374.25 -373.05) rotate(45)" style="fill: #72482b"/>
                  <g>
                    <path d="M474.32,353.86c-11.48,11.48-12.86,25.26-6.09,34.69a1.08,1.08,0,0,1-.1,1.4l-1.31,1.31a1.41,1.41,0,0,1-1.13.42,5.51,5.51,0,0,0-4.41,1.53,1.43,1.43,0,0,0-.25,1.71c.52.8,3,2.26,3.58,1.53s.09-2.35.92-2.15a2.89,2.89,0,0,1,1.64,4.84,1.37,1.37,0,0,0-.23,1.68c.47.76,2.8,2.23,3.39,1.63,1.22-1.22.44-4.26.28-5.85a1.4,1.4,0,0,1,.42-1.13l1.37-1.37a1.09,1.09,0,0,1,1.39-.12c9.41,6.46,23,5,34.29-6.36,12.67-12.67,15.4-36,9.75-41.63C513,341.11,487,341.19,474.32,353.86Z" transform="translate(-48.8 -170.25)" style="fill: #a04644"/>
                    <path d="M476.25,355c-11.48,11.48-12.86,25.27-6.09,34.69a1.07,1.07,0,0,1-.1,1.4l-1.3,1.31a1.43,1.43,0,0,1-1.14.42,5.51,5.51,0,0,0-4.41,1.53A1.44,1.44,0,0,0,463,396a1.34,1.34,0,0,0,2.19.11,2.29,2.29,0,0,1,2.31-.73,2.9,2.9,0,0,1,1.63,4.85,1.37,1.37,0,0,0-.23,1.67,1.34,1.34,0,0,0,2.09.25,5.48,5.48,0,0,0,1.58-4.47,1.45,1.45,0,0,1,.42-1.13l1.38-1.37a1.09,1.09,0,0,1,1.38-.12c9.41,6.46,23,5,34.29-6.36,12.67-12.67,14.06-36.52,8.41-42.17C513.54,341.7,488.92,342.31,476.25,355Z" transform="translate(-48.8 -170.25)" style="fill: #ff5e4f"/>
                    <path d="M507.9,357.8l-34,34a.5.5,0,1,1-.71-.71l34-34a.5.5,0,0,1,.71.71Z" transform="translate(-48.8 -170.25)" style="fill: #a04644"/>
                    <path d="M483.91,381.79a.5.5,0,0,1-.27.14.51.51,0,0,1-.58-.41l-1.92-11.45a.5.5,0,0,1,.41-.58.49.49,0,0,1,.57.41l1.93,11.45A.5.5,0,0,1,483.91,381.79Z" transform="translate(-48.8 -170.25)" style="fill: #a04644"/>
                    <path d="M503,376.43a.5.5,0,0,1-.44.14l-11.45-1.93a.5.5,0,0,1-.41-.57.51.51,0,0,1,.58-.41l11.45,1.92a.51.51,0,0,1,.41.58A.54.54,0,0,1,503,376.43Z" transform="translate(-48.8 -170.25)" style="fill: #a04644"/>
                  </g>
                  <path d="M466.09,402.75l2.75-2.72a2.57,2.57,0,0,0,0-4,2.43,2.43,0,0,0-3.62,0l-1,2.1Z" transform="translate(-48.8 -170.25)" style="fill: #72482b"/>
                  <g>
                    <polygon points="412.73 377 410.61 375.74 411.45 374.37 413.35 374.18 412.73 377" style="fill: #80b178"/>
                    <path d="M460.45,506.56" transform="translate(-48.8 -170.25)" style="fill: #80b178"/>
                  </g>
                  <g>
                    <path d="M471.64,501.55c-11.48,11.48-12.86,25.26-6.09,34.68a1.09,1.09,0,0,1-.1,1.41l-1.3,1.3a1.44,1.44,0,0,1-1.14.43,5.46,5.46,0,0,0-4.41,1.52,1.46,1.46,0,0,0-.25,1.72c.52.79,3,2.15,3.53,1.42s.14-2.24,1-2a2.9,2.9,0,0,1,1.64,4.85,1.37,1.37,0,0,0-.23,1.67c.48.77,2.7,2.34,3.3,1.74,1.22-1.22.54-4.36.37-6a1.41,1.41,0,0,1,.42-1.13l1.37-1.37a1.09,1.09,0,0,1,1.39-.11c9.41,6.45,23,5,34.29-6.36,12.67-12.68,15.4-36,9.75-41.64C510.28,488.8,484.31,488.87,471.64,501.55Z" transform="translate(-48.8 -170.25)" style="fill: #002d21"/>
                    <path d="M473.57,502.67c-11.48,11.48-12.86,25.26-6.08,34.68a1.1,1.1,0,0,1-.1,1.41l-1.31,1.31a1.47,1.47,0,0,1-1.14.42,5.51,5.51,0,0,0-4.41,1.52,1.46,1.46,0,0,0-.24,1.72,1.33,1.33,0,0,0,2.19.1,2.27,2.27,0,0,1,2.31-.72,2.88,2.88,0,0,1,1.63,4.84,1.38,1.38,0,0,0-.23,1.67,1.34,1.34,0,0,0,2.09.26,5.49,5.49,0,0,0,1.58-4.47,1.44,1.44,0,0,1,.43-1.14l1.37-1.37a1.08,1.08,0,0,1,1.38-.11c9.41,6.45,23,5,34.29-6.36,12.67-12.67,14.06-36.52,8.41-42.17C510.86,489.38,486.24,490,473.57,502.67Z" transform="translate(-48.8 -170.25)" style="fill: #005541"/>
                    <path d="M505.22,505.49l-34,34a.5.5,0,0,1-.71,0,.5.5,0,0,1,0-.7l34-34a.5.5,0,0,1,.71,0A.51.51,0,0,1,505.22,505.49Z" transform="translate(-48.8 -170.25)" style="fill: #002d21"/>
                    <path d="M481.23,529.47a.5.5,0,0,1-.27.14.49.49,0,0,1-.57-.41l-1.93-11.45a.5.5,0,0,1,1-.16L481.37,529A.49.49,0,0,1,481.23,529.47Z" transform="translate(-48.8 -170.25)" style="fill: #002d21"/>
                    <path d="M500.33,524.11a.5.5,0,0,1-.44.14l-11.45-1.92a.5.5,0,1,1,.17-1l11.45,1.93a.49.49,0,0,1,.41.57A.5.5,0,0,1,500.33,524.11Z" transform="translate(-48.8 -170.25)" style="fill: #002d21"/>
                  </g>
                  <rect x="458.15" y="539.57" width="4.58" height="9.77" transform="translate(-298.93 314.8) rotate(-45)" style="fill: #593735"/>
                  <path d="M131,573.46S78.22,575,48.8,560.77v-.93h73.35Z" transform="translate(-48.8 -170.25)" style="fill: #e5e5e5;mix-blend-mode: multiply"/>
                </g>
                <path d="M116.82,182.89H383.06a7.58,7.58,0,0,1,7.58,7.58v198.3a0,0,0,0,1,0,0H109.24a0,0,0,0,1,0,0V190.47A7.58,7.58,0,0,1,116.82,182.89Z" style="fill: #2f2f2f"/>
                <path d="M444.31,575H153.17a3.68,3.68,0,0,1-2.91-5.92L158,559h281.4l7.78,10.09A3.67,3.67,0,0,1,444.31,575Z" transform="translate(-48.8 -170.25)" style="fill: #161616"/>
                <rect x="124.65" y="196.76" width="250.97" height="176.54" style="fill: #ffead2"/>
                <path d="M148.34,216H351a5.49,5.49,0,0,1,5.49,5.49V373.3a0,0,0,0,1,0,0H142.85a0,0,0,0,1,0,0V221.52A5.49,5.49,0,0,1,148.34,216Z" style="fill: #fff"/>
                <g>
                  <g>
                    <path d="M274.82,294.86a8.06,8.06,0,0,1-7.17-4.76c-2.41-4.94-7.45-13.29-13-9s-4.64,12.65-3.53,16.31,7.94,12,11.58,13.05,12.26-.91,12.26-.91l0-2.27a6,6,0,0,1-4.59-6.61c.39-5.59,4.51-3.47,4.51-3.47Z" transform="translate(-48.8 -170.25)" style="fill: #335d68"/>
                    <path d="M274.84,297.16s-3.84-2.31-4.51,3.47a5.64,5.64,0,0,0,4.59,6.61Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                    <path d="M275.42,309.62c-5.27-.93-7.84-4.49-7.06-9.77.36-2.37,1.28-4,2.75-4.75a5.39,5.39,0,0,1,4.49,0l-1,2a3.22,3.22,0,0,0-2.5,0c-.81.43-1.34,1.48-1.58,3.1-.61,4.09,1.15,6.53,5.24,7.24Z" transform="translate(-48.8 -170.25)" style="fill: #6ba2af"/>
                    <path d="M290,305.61S288.3,303,283.17,304l-6.44.16-.37-11.92a9.29,9.29,0,0,1,7.38-2.65c4.81.31,6.76,3.18,7.26,5.44S290,305.61,290,305.61Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                    <path d="M274.55,293.51l.14,16.09s5.1,3,8.5-3l-.07-8.08S276.4,299,274.55,293.51Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                    <rect x="289.93" y="295.03" width="2.23" height="10.57" transform="matrix(1, -0.01, 0.01, 1, -51.36, -167.75)" style="fill: #f5dfb7"/>
                    <path d="M280.6,298.81a6.84,6.84,0,0,1-4.19-1.16,5,5,0,0,1-1.86-4.14,4.41,4.41,0,0,1,2.53-3.93,11,11,0,0,1,5-1.13c4.83,0,10,2.25,10,6.57l-2.23,0c0-2.77-4-4.39-7.76-4.36a8.63,8.63,0,0,0-4,.86c-1.38.75-1.37,1.66-1.37,1.95a2.82,2.82,0,0,0,1,2.38c1.41,1.08,4.14.68,5.08.43l.29,2.24A10.47,10.47,0,0,1,280.6,298.81Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                  </g>
                  <polygon points="218.52 193.5 236.8 186.74 236.58 160.75 218.3 168.03 218.52 193.5" style="fill: #8e7676"/>
                  <polygon points="233.65 183.3 221.8 177.17 221.75 171.28 233.5 166.6 233.65 183.3" style="fill: #725350"/>
                  <polygon points="221.89 187.65 221.8 177.17 233.65 183.3 221.89 187.65" style="fill: #472b2a"/>
                  <polygon points="212.23 165.07 218.3 168.03 236.58 160.75 230.85 157.98 212.23 165.07" style="fill: #00cbbf"/>
                  <polygon points="212.3 172.69 202.54 167.76 219.75 160.89 221.96 161.37 212.23 165.07 212.3 172.69" style="fill: #3b6a7a"/>
                  <path d="M251.35,338a15.7,15.7,0,0,0-.72-2.83L267,328.89a4.6,4.6,0,0,1,1.53,2.25Z" transform="translate(-48.8 -170.25)" style="fill: #508999"/>
                  <path d="M267.32,363.74l-.22-25.46-6.07-3,.07,7.61L251.35,338s-.9-8.13-7.86-9.69-7.12,8-7,10.52,2.39,10.05,11.06,14.29S267.32,363.74,267.32,363.74Z" transform="translate(-48.8 -170.25)" style="fill: #543e3e"/>
                  <polygon points="197.25 202.16 215.53 195.41 215.31 169.42 197.03 176.69 197.25 202.16" style="fill: #8e7676"/>
                  <polygon points="212.38 191.97 200.53 185.84 200.48 179.94 212.23 175.26 212.38 191.97" style="fill: #725350"/>
                  <polygon points="200.62 196.31 200.53 185.84 212.38 191.97 200.62 196.31" style="fill: #472b2a"/>
                  <polygon points="190.96 173.74 197.03 176.69 215.31 169.42 209.58 166.65 190.96 173.74" style="fill: #725350"/>
                  <polygon points="191.03 181.35 181.27 176.42 198.48 169.56 200.69 170.03 190.96 173.74 191.03 181.35" style="fill: #472b2a"/>
                  <path d="M230.08,346.67a15.93,15.93,0,0,0-.72-2.84l16.39-6.27a4.6,4.6,0,0,1,1.53,2.25Z" transform="translate(-48.8 -170.25)" style="fill: #543e3e"/>
                  <polygon points="166.59 159.75 166.65 166.21 181.99 174.6 181.92 166.94 166.59 159.75" style="fill: #8c6867"/>
                  <polygon points="181.92 166.94 218.26 153.93 218.32 160.55 181.99 174.6 181.92 166.94" style="fill: #725350"/>
                  <polygon points="165.87 159.5 170.9 116.71 187.31 123.33 181.92 166.94 165.87 159.5" style="fill: #3b6a7a"/>
                  <polygon points="187.31 123.33 214.84 113.78 219.69 153.5 181.92 166.94 187.31 123.33" style="fill: #508b9b"/>
                  <polygon points="170.9 116.71 200.35 107.16 214.84 113.78 187.31 123.33 170.9 116.71" style="fill: #284951"/>
                  <path d="M225.77,283.94" transform="translate(-48.8 -170.25)" style="fill: #323232"/>
                  <path d="M230.26,282.7l0,2.55s.77,3.17,10.17,3.09,10.44-3.27,10.44-3.27l0-4.12Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                  <path d="M223.38,270.83l.05,6.79s1.26,7.22,17,7,16.37-5.55,16.35-7.32l-.15-17.5-32.76.28Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                  <path d="M248,279.69a6.14,6.14,0,0,1-3.5-1.15.58.58,0,0,1-.12-.81.56.56,0,0,1,.8-.12c.14.1,3.4,2.42,6-.68a.58.58,0,0,1,.82-.07.58.58,0,0,1,.07.81A5.19,5.19,0,0,1,248,279.69Z" transform="translate(-48.8 -170.25)" style="fill: #162d33"/>
                  <path d="M246.05,372.41l-.22-25.47L239.76,344l.07,7.61-9.75-4.93s-.9-8.13-7.86-9.69c-6-1.35-6.95,5.37-7,9h0v1.76c0,1.61.92,7.3,8.86,12.68a13.25,13.25,0,0,0,2.23,1.4l.15.07a7.4,7.4,0,0,0,.8.4C235.77,366.48,246.05,372.41,246.05,372.41Z" transform="translate(-48.8 -170.25)" style="fill: #8c6867"/>
                  <path d="M239.73,307.36a8,8,0,0,1-7.16-4.75c-2.42-4.95-7.45-13.3-13-9.05s-4.64,12.65-3.53,16.31,7.94,12,11.59,13.05,12.25-.9,12.25-.9v-2.28a6,6,0,0,1-4.59-6.6c.38-5.6,4.5-3.48,4.5-3.48Z" transform="translate(-48.8 -170.25)" style="fill: #335d68"/>
                  <path d="M239.75,309.66s-3.83-2.31-4.5,3.48a5.63,5.63,0,0,0,4.59,6.6Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                  <path d="M240.34,322.12c-5.28-.92-7.85-4.49-7.06-9.77.35-2.36,1.27-4,2.75-4.75a5.41,5.41,0,0,1,4.49,0l-1,2a3.15,3.15,0,0,0-2.5-.05c-.8.43-1.34,1.48-1.58,3.1-.61,4.09,1.15,6.53,5.24,7.25Z" transform="translate(-48.8 -170.25)" style="fill: #6ba2af"/>
                  <path d="M254.89,318.11s-1.67-2.57-6.81-1.61l-6.44.16-.37-11.92a9.29,9.29,0,0,1,7.38-2.65c4.81.31,6.76,3.18,7.27,5.44S254.89,318.11,254.89,318.11Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                  <path d="M239.47,306l.13,16.09s5.1,3,8.5-3L248,311S241.31,311.46,239.47,306Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                  <rect x="254.85" y="307.53" width="2.23" height="10.57" transform="translate(-51.47 -168.05) rotate(-0.49)" style="fill: #f5dfb7"/>
                  <path d="M245.51,311.31a6.85,6.85,0,0,1-4.19-1.16,4.91,4.91,0,0,1-1.85-4.14,4.37,4.37,0,0,1,2.52-3.92,10.88,10.88,0,0,1,5-1.14c4.82,0,10,2.25,10,6.57l-2.23,0c0-2.77-4-4.39-7.76-4.36a8.66,8.66,0,0,0-4,.86c-1.37.76-1.37,1.66-1.36,2a2.81,2.81,0,0,0,1,2.38c1.4,1.07,4.13.67,5.07.42L248,311A10.47,10.47,0,0,1,245.51,311.31Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                  <path d="M261.18,362.22" transform="translate(-48.8 -170.25)"/>
                  <path d="M256.66,264.36s-4.79,4.46-13.41,3.1-4.22-.66-4.22,4.8-2.53,8.61-1.56,9.8,4.86,2.88,14.27,1a36.11,36.11,0,0,1-20.83.52c-11.36-3.15-6.7-19.67-6.7-19.67l10-8.92S257.16,254.16,256.66,264.36Z" transform="translate(-48.8 -170.25)" style="fill: #e6b474"/>
                  <path d="M256.66,263.65s-7.44,4.35-17.55.92c-7-2.38-2.76,3.3-2.5,5.84.31,3.14-4.2,1.88-4.19-.87,0-2.23-3.9-1.24-4,1.81-.13,4.09.33,10.48-1.17,10.69-2.62.35-5.12-3.71-5.75-9.56s-1.27-15.05.24-18.38c1.62-3.59,5.7-10.33,21.33-10.42,5.07,0,10.22,1.64,13.51,5.68S260.14,259.68,256.66,263.65Z" transform="translate(-48.8 -170.25)" style="fill: #2f2f2f"/>
                  <g>
                    <path d="M249.81,270.07Z" transform="translate(-48.8 -170.25)" style="fill: #162d33"/>
                    <circle cx="194.81" cy="100.6" r="2.08" style="fill: #162d33"/>
                    <ellipse cx="194.89" cy="99.99" rx="0.62" ry="0.71" style="fill: #fcf6f3"/>
                    <path d="M254.58,269.11c0,1-.7,2.25-1.57,2.28s-1.15-.71-1.19-1.75c0-1.43.74-2.29,1.61-2.32S254.54,268.06,254.58,269.11Z" transform="translate(-48.8 -170.25)" style="fill: #162d33"/>
                    <ellipse cx="253.25" cy="268.85" rx="0.48" ry="0.63" transform="translate(-58.52 -160.75) rotate(-2.11)" style="fill: #fcf6f3"/>
                  </g>
                </g>
                <path d="M404.8,404.11V392.44a6.16,6.16,0,0,0-6.16-6.16H197.33a6.16,6.16,0,0,0-6.16,6.16v11.67Z" transform="translate(-48.8 -170.25)" style="fill: #31144f"/>
                <circle cx="156.68" cy="224.53" r="3.25" style="fill: #fff"/>
                <circle cx="170.4" cy="224.53" r="3.25" style="fill: #fff"/>
                <circle cx="184.13" cy="224.53" r="3.25" style="fill: #fff"/>
                <rect x="251.46" y="220.89" width="86" height="7.28" rx="3.24" style="fill: #fff"/>
                <rect x="226.03" y="345.33" width="38.74" height="7.28" rx="3.24" style="fill: #8d94e9"/>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="401.98 428.36 401.95 452.92 346.3 436 346.33 411.44 401.98 428.36" style="fill: #256eb8"/>
                          <polygon points="429.63 419.95 401.98 428.36 346.33 411.44 373.98 403.03 429.63 419.95" style="fill: #195489"/>
                          <path d="M415.61,582.31l.09-.11a.35.35,0,0,1,.05-.08.5.5,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1-.05.13l-.05.07-.09.12-.08.07a.52.52,0,0,1-.11.1,1,1,0,0,1-.16.12l-.12.09-.21.12-.14.07-.28.13-.13.06-.49.17c-.19.06-.41.11-.63.16l-.48.13-.65.11-.54.08-.7.07-.56,0-.75,0H409l-.75,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28c.12,0,.25,0,.37.06l.82.13.5,0c.26,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56-.05.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.81,577.15c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3.05A19,19,0,0,1,413.81,577.15Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M429.51,586.54l.09-.11a.35.35,0,0,1,0-.08l.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3.06a.58.58,0,0,1,0,.19s0,0,0,.07l-.06.13,0,.07-.09.12-.08.07a1.14,1.14,0,0,1-.11.11l-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06a4.65,4.65,0,0,1-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56,0-.75,0h-.55l-.76,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.54V586c0,.56.69,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28l.38.06.81.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12.37-.08.54-.14l.08,0,.49-.18.14-.06.28-.12.14-.08.2-.11.12-.09.16-.12a.1.1,0,0,0,.05,0l.06-.06A.3.3,0,0,0,429.51,586.54Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.71,581.38c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,427.71,581.38Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M429.42,578.1a.47.47,0,0,0,.09-.12l.05-.07a.49.49,0,0,0,.06-.13l0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.38.38,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17l-.63.17-.49.12-.64.11-.54.09c-.23,0-.47,0-.7.06l-.56.05-.75,0-.56,0-.75,0-.55,0-.77-.07-.5,0c-.28,0-.55-.08-.82-.13-.12,0-.25,0-.37-.06-.38-.08-.75-.17-1.08-.27-1.37-.42-2.05-1-2.05-1.53v3c0,.56.68,1.12,2.05,1.54q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07h.55l.75,0h.56l.75,0,.56,0,.7-.07c.19,0,.36-.06.54-.09l.64-.1.49-.13.55-.13.08,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.09.16-.12.05,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.63,572.93c2.7.83,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A18.91,18.91,0,0,1,427.63,572.93Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M443.33,582.33l.09-.12.05-.07.06-.13a.25.25,0,0,1,0-.07.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.21.12-.14.08-.27.12-.14.06-.49.17c-.19.07-.41.11-.63.17l-.48.12-.65.11-.54.09c-.23,0-.46,0-.7.06l-.56.06-.75,0-.56,0-.75,0-.55,0-.77-.07-.5,0-.82-.14c-.12,0-.25,0-.37-.06a10.65,10.65,0,0,1-1.08-.27c-1.37-.42-2-1-2-1.53v3c0,.56.68,1.12,2,1.54q.51.15,1.08.27l.37.06c.27,0,.54.1.82.13l.5,0,.77.07H436l.76,0h.55l.75,0,.56-.05.7-.07.54-.08.65-.11.49-.13.54-.13.08,0,.49-.17.14-.06a2.24,2.24,0,0,0,.27-.13l.14-.07.21-.12.12-.08.16-.12.05,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.53,577.16c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,441.53,577.16Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M443.43,590.79l.09-.12.05-.07.06-.13a.14.14,0,0,0,0-.07.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.2.12-.14.08-.28.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17l-.49.12-.65.11-.54.09c-.22,0-.46,0-.69.06l-.57.06-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.81-.14c-.13,0-.26,0-.38-.06a8.52,8.52,0,0,1-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3.05c0,.56.69,1.12,2.05,1.54q.51.15,1.08.27l.38.06c.27,0,.53.1.82.13l.49,0,.77.07h.55l.76,0h.56c.25,0,.5,0,.74,0l.57-.05.69-.07.54-.08.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.14-.07.2-.12.13-.08.15-.12.05,0,.07-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.63,585.62c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,441.63,585.62Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <polygon points="429.63 419.95 429.59 444.51 401.95 452.92 401.98 428.36 429.63 419.95" style="fill: #256eba"/>
                          <path d="M457.33,595a.94.94,0,0,0,.09-.12l.05-.07a.75.75,0,0,0,.06-.13l0-.06a1.38,1.38,0,0,0,0-.2v-3.05a1.37,1.37,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13.35.35,0,0,1-.05.08l-.09.11-.07.08-.12.1-.15.12-.12.08-.21.12-.14.08-.28.12-.14.06-.48.18-.63.16c-.16,0-.32.09-.49.12l-.65.11-.53.09-.7.07-.57,0-.74,0-.56,0-.75,0-.56,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.14c-.12,0-.26,0-.38-.06a8,8,0,0,1-1.07-.27c-1.37-.42-2.06-1-2.06-1.53v3.06c0,.55.69,1.11,2.06,1.53q.49.15,1.08.27c.11,0,.25,0,.37.06s.53.1.82.13l.5,0,.76.07H450l.75,0,.56,0c.25,0,.5,0,.74,0l.57,0,.7-.06.54-.09.64-.11.49-.13.54-.13.09,0c.17-.05.33-.11.48-.17l.14-.06.28-.13.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,.05,0l.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M455.53,589.85c2.71.83,2.74,2.2.09,3a19.13,19.13,0,0,1-9.71,0c-2.71-.83-2.75-2.19-.08-3A19,19,0,0,1,455.53,589.85Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M457.25,586.57l.09-.12.05-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19s0,0,0,.06l-.06.13a.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12a.5.5,0,0,1-.12.08,1.05,1.05,0,0,1-.21.12l-.14.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56,0-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.13-.37-.06a10.62,10.62,0,0,1-1.08-.28c-1.37-.42-2.05-1-2.05-1.53V586c0,.55.69,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.38.06l.81.14.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.57-.06c.23,0,.47,0,.69-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M455.45,581.4c2.7.84,2.74,2.2.08,3.05a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,455.45,581.4Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M471.15,590.8l.09-.11a.35.35,0,0,0,.05-.08l.06-.13a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19.25.25,0,0,1,0,.07l-.06.12-.05.08-.09.12-.08.07a.52.52,0,0,1-.11.1l-.15.12-.13.09-.2.11-.14.08-.28.12-.14.07-.49.17c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.69.07-.57,0c-.25,0-.5,0-.74,0l-.56,0-.76,0a5.06,5.06,0,0,1-.55,0c-.26,0-.51,0-.76-.06l-.51-.05-.81-.13-.38-.06q-.57-.12-1.08-.27c-1.36-.43-2.05-1-2.05-1.54v3.06c0,.55.69,1.11,2.06,1.53a8,8,0,0,0,1.07.27c.12,0,.25,0,.38.06l.82.14.49,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57-.05.69-.07.54-.09.65-.11c.17,0,.33-.08.49-.12l.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.12.14-.08.2-.12a.57.57,0,0,0,.13-.08L471,591l.05,0,.07-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M469.35,585.63c2.7.84,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,469.35,585.63Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="401.98 403.26 401.95 427.82 346.3 410.89 346.33 386.33 401.98 403.26" style="fill: #0e4772"/>
                          <polygon points="429.63 394.85 401.98 403.26 346.33 386.33 373.98 377.92 429.63 394.85" style="fill: #195489"/>
                          <path d="M415.61,557.21l.09-.12.05-.07a1.12,1.12,0,0,0,.05-.13l0-.07a.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.13.06-.49.17c-.19.07-.41.11-.63.17l-.48.12-.65.11-.54.09c-.23,0-.46,0-.7.06l-.56.06-.75,0-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a10.65,10.65,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3c0,.56.68,1.12,2.05,1.54q.51.15,1.08.27l.37.06c.27,0,.54.1.82.13l.5,0,.77.07h.55l.75,0h.56l.75,0,.56,0,.7-.07.54-.08.65-.11.49-.13.54-.13.08,0,.49-.17.14-.06a2.24,2.24,0,0,0,.27-.13l.14-.07.21-.12.12-.08.16-.12.05,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.81,552c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,413.81,552Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M429.51,561.44l.09-.12,0-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2V557.8a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13,0,.07-.09.12-.08.08-.11.1-.16.12a.5.5,0,0,1-.12.08l-.21.12-.14.08-.27.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17s-.32.09-.49.12l-.65.11-.54.09c-.22,0-.46,0-.7.06s-.37,0-.56.06l-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.81-.14c-.13,0-.26,0-.38-.06a8.52,8.52,0,0,1-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3.05c0,.56.69,1.12,2.05,1.54q.51.15,1.08.27l.38.06c.26,0,.53.1.81.13l.5,0,.77.07h.55c.25,0,.5,0,.76,0h.56l.74,0,.57-.05.69-.07.54-.08.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.14-.07.2-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.71,556.27c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,427.71,556.27Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M429.42,553a.47.47,0,0,0,.09-.12l.05-.07a.38.38,0,0,0,.06-.13l0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13.35.35,0,0,1-.05.08.31.31,0,0,1-.09.11l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06-.48.18-.63.16c-.16,0-.31.09-.49.12s-.43.08-.64.11l-.54.09-.7.07-.56.05-.75,0-.56,0-.75,0-.55,0c-.26,0-.52,0-.77-.07l-.5,0-.82-.13c-.12,0-.25,0-.37-.06a10,10,0,0,1-1.08-.28c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.68,1.11,2.05,1.53q.51.15,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0,.77.07.55,0,.75,0,.56,0,.75,0,.56-.06c.24,0,.47,0,.7-.06l.54-.09.64-.11.49-.12.55-.14.08,0c.17,0,.33-.11.48-.17l.14-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.63,547.82c2.7.83,2.73,2.2.08,3a18.91,18.91,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,427.63,547.82Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M443.33,557.22l.09-.12.05-.07.06-.13a.14.14,0,0,1,0-.06.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.07a.61.61,0,0,1,0,.12.35.35,0,0,0-.05.08l-.09.11-.08.08a.52.52,0,0,1-.11.1l-.16.12-.12.09a1,1,0,0,1-.21.11l-.14.08-.27.12a.84.84,0,0,1-.14.06l-.49.18c-.19.06-.41.11-.63.16s-.31.09-.48.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56.05c-.25,0-.5,0-.75,0l-.56,0-.75,0c-.18,0-.37,0-.55,0s-.51,0-.77-.06l-.5,0-.82-.13-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2-1-2-1.54v3.06c0,.55.68,1.11,2,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.56-.06c.24,0,.47,0,.7-.06l.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.53,552.05c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3A19,19,0,0,1,441.53,552.05Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M443.43,565.68l.09-.11a.35.35,0,0,0,.05-.08l.06-.13a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19.25.25,0,0,1,0,.07l-.06.12-.05.08-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12L443,563l-.2.12-.14.07-.28.13a.84.84,0,0,0-.14.06l-.49.17c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.69.07-.57,0-.74,0h-.56l-.76,0a5.06,5.06,0,0,1-.55,0c-.26,0-.51,0-.77-.06l-.5-.05-.81-.13-.38-.06q-.57-.12-1.08-.27c-1.36-.43-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.38.06l.82.14.49,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57-.05.69-.07L440,567l.65-.11c.17,0,.33-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.28-.12.14-.08.2-.12a.57.57,0,0,0,.13-.08l.15-.12.05,0,.07-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.63,560.51c2.7.84,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,441.63,560.51Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <polygon points="429.63 394.85 429.59 419.41 401.95 427.82 401.98 403.26 429.63 394.85" style="fill: #256eba"/>
                          <path d="M457.33,569.91l.09-.11a.35.35,0,0,0,.05-.08.52.52,0,0,0,.06-.13l0-.06a1.25,1.25,0,0,0,0-.19v-3.06a1.23,1.23,0,0,1,0,.19l0,.07a1.39,1.39,0,0,1-.06.13l-.05.07-.09.12-.07.07-.12.1-.15.12-.12.09-.21.12-.14.07-.28.13-.14.06-.48.17L455,568l-.49.13-.65.1c-.18,0-.35.07-.53.09l-.7.07-.57,0c-.24,0-.49,0-.74,0h-.56l-.75,0h-.56l-.76-.07-.5-.05c-.29,0-.55-.08-.82-.13l-.38-.06c-.38-.08-.74-.17-1.07-.27-1.37-.42-2.06-1-2.06-1.54v3.06c0,.56.69,1.11,2.06,1.53a7.74,7.74,0,0,0,1.08.27l.37.07.82.13.5,0c.25,0,.5.06.76.07l.56,0,.75,0,.56,0,.74,0,.57-.05.7-.07.54-.09c.21,0,.44-.06.64-.11s.33-.08.49-.12l.54-.14.09,0,.48-.18.14-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M455.53,564.75c2.71.83,2.74,2.19.09,3a19,19,0,0,1-9.71,0c-2.71-.84-2.75-2.2-.08-3.05A19,19,0,0,1,455.53,564.75Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M457.25,561.46l.09-.11a.35.35,0,0,1,.05-.08l.06-.12s0,0,0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13-.05.07-.09.12-.08.07-.11.11-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06a4.65,4.65,0,0,1-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56.05-.75,0h-.55c-.26,0-.51,0-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.54q.51.15,1.08.27l.38.06.81.13.5.05c.26,0,.51,0,.77.06s.37,0,.55,0l.76,0,.55,0c.25,0,.5,0,.75,0l.57,0,.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.18a.84.84,0,0,0,.14-.06l.27-.12.14-.07.21-.12.12-.09.16-.12.05,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M455.45,556.3c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3A19,19,0,0,1,455.45,556.3Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M471.15,565.7l.09-.12.05-.07.06-.13a.19.19,0,0,0,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.15.12-.13.08-.2.12-.14.07-.28.13-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09-.69.06-.57,0-.74,0-.56,0-.76,0-.55,0-.76-.07-.51-.05c-.28,0-.54-.08-.81-.13s-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3c0,.56.69,1.12,2.06,1.54.33.1.69.19,1.07.27l.38.06c.27,0,.53.1.82.13l.49,0,.77.07h.55l.76,0h.56c.25,0,.5,0,.74,0l.57-.05.69-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.08,0,.49-.17.14-.06.28-.13.14-.07.2-.12.13-.09.15-.12.05,0s0-.05.07-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M469.35,560.53c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3A18.91,18.91,0,0,1,469.35,560.53Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="401.98 378.15 401.95 402.71 346.3 385.78 346.33 361.23 401.98 378.15" style="fill: #0e4772"/>
                          <polygon points="429.63 369.74 401.98 378.15 346.33 361.23 373.98 352.82 429.63 369.74" style="fill: #195489"/>
                          <path d="M415.61,532.1l.09-.11a.35.35,0,0,1,.05-.08.5.5,0,0,0,.05-.13l0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.07a.61.61,0,0,1-.05.12.35.35,0,0,0-.05.08l-.09.11a.6.6,0,0,1-.08.08.52.52,0,0,1-.11.1l-.16.12-.12.09a1,1,0,0,1-.21.11l-.14.08-.28.12a.52.52,0,0,1-.13.06l-.49.18c-.19.06-.41.11-.63.16s-.31.09-.48.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56.05c-.25,0-.5,0-.75,0l-.56,0-.75,0c-.18,0-.37,0-.55,0s-.51,0-.77-.06l-.5,0-.82-.13-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54v3.06c0,.55.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.26,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56-.06c.24,0,.47,0,.7-.06l.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.81,526.93c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3A19,19,0,0,1,413.81,526.93Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M429.51,536.33l.09-.11a.35.35,0,0,1,0-.08l.06-.13s0,0,0-.06a.68.68,0,0,0,0-.19V532.7a.63.63,0,0,1,0,.19s0,.05,0,.07l-.06.13a.18.18,0,0,0,0,.07l-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.27.13-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.1c-.18,0-.35.07-.54.09l-.7.07-.56,0c-.25,0-.5,0-.75,0h-.55l-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.38.07.81.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57,0,.69-.07.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.28-.12.14-.08.2-.12a.5.5,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.71,531.16c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3A19.13,19.13,0,0,1,427.71,531.16Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M429.42,527.88a.31.31,0,0,0,.09-.11.35.35,0,0,0,.05-.08.31.31,0,0,0,.06-.13l0-.06a.63.63,0,0,0,0-.19v-3.06a.58.58,0,0,1,0,.19l0,.07a.49.49,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.11-.16.12-.12.08-.21.12-.14.07-.28.13-.14.06c-.15.06-.31.12-.48.17l-.63.16-.49.13-.64.11-.54.08-.7.07-.56.05-.75,0h-.56l-.75,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06c-.38-.08-.75-.17-1.08-.27-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.54q.51.15,1.08.27l.37.06.82.13.5.05c.25,0,.51,0,.77.06l.55,0,.75,0,.56,0c.25,0,.5,0,.75,0l.56,0,.7-.07c.19,0,.36-.06.54-.09a6.09,6.09,0,0,0,.64-.11c.18,0,.33-.08.49-.12s.38-.08.55-.14l.08,0,.48-.18.14-.06.28-.12.14-.08.21-.11.12-.09.16-.12.05,0,.06-.06A.27.27,0,0,0,429.42,527.88Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.63,522.72c2.7.83,2.73,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3A19,19,0,0,1,427.63,522.72Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M443.33,532.12l.09-.12a.35.35,0,0,1,.05-.08l.06-.12a.14.14,0,0,1,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.69.69,0,0,1,0,.13l-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06-.49.17-.63.17-.48.12-.65.11-.54.09-.7.06-.56.05-.75,0-.56,0-.75,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13s-.25,0-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2-1-2-1.53v3c0,.56.68,1.11,2,1.54q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07H436l.76,0h.55l.75,0,.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.17.14-.06.27-.13.14-.07.21-.12.12-.09.16-.12.05,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.53,527c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3A18.91,18.91,0,0,1,441.53,527Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M443.43,540.58l.09-.12.05-.07.06-.13a.19.19,0,0,0,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.2.12-.14.07-.28.13-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09-.69.06-.57,0-.74,0-.56,0-.76,0-.55,0-.77-.07-.5,0c-.28,0-.54-.08-.81-.13s-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3c0,.56.69,1.12,2.05,1.54q.51.15,1.08.27l.38.06c.27,0,.53.1.82.13l.49,0,.77.07h.55l.76,0h.56c.25,0,.5,0,.74,0l.57-.05.69-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.08,0,.49-.17.14-.06.28-.13.14-.07.2-.12.13-.09.15-.12.05,0s0-.05.07-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.63,535.41c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3A18.91,18.91,0,0,1,441.63,535.41Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <polygon points="429.63 369.74 429.59 394.3 401.95 402.71 401.98 378.15 429.63 369.74" style="fill: #256eba"/>
                          <path d="M457.33,544.81a.94.94,0,0,0,.09-.12l.05-.07a1.39,1.39,0,0,0,.06-.13l0-.07a1.25,1.25,0,0,0,0-.19v-3.06a1.36,1.36,0,0,1,0,.2l0,.06a.75.75,0,0,1-.06.13l-.05.07-.09.12-.07.08-.12.1-.15.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17s-.42.11-.63.17l-.49.12-.65.11-.53.09c-.23,0-.47,0-.7.06l-.57.06-.74,0-.56,0-.75,0-.56,0-.76-.07-.5,0-.82-.14c-.12,0-.26,0-.38-.06-.38-.08-.74-.17-1.07-.27-1.37-.42-2.06-1-2.06-1.53v3.05c0,.56.69,1.12,2.06,1.54q.49.15,1.08.27l.37.06c.27,0,.53.1.82.13l.5,0,.76.07H450l.75,0h.56c.25,0,.5,0,.74,0l.57-.05.7-.07.54-.08.64-.11.49-.13.54-.13.09,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,.05,0l.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M455.53,539.64c2.71.83,2.74,2.2.09,3a19,19,0,0,1-9.71,0c-2.71-.83-2.75-2.19-.08-3A19,19,0,0,1,455.53,539.64Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M457.25,536.36l.09-.12.05-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.13a.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12a.5.5,0,0,1-.12.08l-.21.12-.14.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12l-.65.11-.54.09-.7.07-.56.05-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.13-.37-.07a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.69,1.11,2.05,1.53q.51.15,1.08.27c.12,0,.25,0,.38.06s.53.1.81.13l.5.05.77.07h.55c.25,0,.5,0,.76,0l.55,0,.75,0,.57,0,.69-.06.54-.09.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06a2.24,2.24,0,0,0,.27-.13l.14-.07.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M455.45,531.19c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,455.45,531.19Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M471.15,540.59l.09-.12.05-.07.06-.13a.14.14,0,0,0,0-.06.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13-.05.08-.09.11-.08.08-.11.1-.15.12a.57.57,0,0,1-.13.08l-.2.12-.14.08-.28.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.69.07-.57.05-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.76-.07l-.51,0-.81-.13-.38-.06a10.62,10.62,0,0,1-1.08-.28c-1.36-.42-2.05-1-2.05-1.53V540c0,.55.69,1.11,2.06,1.53.33.1.69.19,1.07.27.12,0,.25,0,.38.06s.53.1.82.13.33,0,.49,0l.77.07.55,0,.76,0,.56,0,.74,0,.57-.06c.23,0,.47,0,.69-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.12.14-.08.2-.12.13-.08.15-.12.05,0,.07-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M469.35,535.42c2.7.83,2.74,2.2.08,3.05a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,469.35,535.42Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="374.03 436.87 374 461.43 318.35 444.5 318.38 419.94 374.03 436.87" style="fill: #0e4772"/>
                          <polygon points="401.67 428.46 374.03 436.87 318.38 419.94 346.03 411.54 401.67 428.46" style="fill: #0d3954"/>
                          <path d="M387.66,590.82l.08-.12.06-.07a.69.69,0,0,0,.05-.13l0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.06a.31.31,0,0,1-.06.13.35.35,0,0,1-.05.08.44.44,0,0,1-.09.11l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.13.06-.49.18-.63.16c-.16,0-.31.09-.48.12s-.43.08-.65.11-.35.07-.54.09l-.7.07-.56,0-.75,0-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.13-.37-.07a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.68,1.11,2.05,1.53q.51.15,1.08.27c.12,0,.25,0,.37.06s.54.1.82.13l.5,0,.77.07.55,0,.75,0,.56,0,.75,0,.56-.05.7-.06.54-.09.65-.11.49-.12.54-.14.08,0,.49-.17.13-.06a1.82,1.82,0,0,0,.28-.13l.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,0,0l.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M385.86,585.65c2.7.83,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,385.86,585.65Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M401.56,595.05l.09-.12.05-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2v-3.05a.63.63,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13a.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12-.12.08a1.05,1.05,0,0,1-.21.12l-.14.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56,0-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.13c-.12,0-.25,0-.37-.06a10.62,10.62,0,0,1-1.08-.28c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.69,1.11,2.05,1.53q.51.15,1.08.27c.12,0,.25,0,.38.06l.81.14.5,0,.77.07.55,0,.76,0,.55,0,.75,0,.57-.06c.23,0,.47,0,.69-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M399.76,589.88c2.7.83,2.74,2.2.08,3a18.91,18.91,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,399.76,589.88Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M401.47,586.6a.44.44,0,0,0,.09-.11.35.35,0,0,0,.05-.08.31.31,0,0,0,.06-.13l0-.06a.68.68,0,0,0,0-.19V583a.63.63,0,0,1,0,.19l0,.07a1.39,1.39,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.07-.11.1-.16.12-.12.09-.21.12-.14.07-.28.13-.14.06-.48.17-.63.16-.49.13-.64.1-.54.09-.7.07-.57.05c-.24,0-.49,0-.74,0h-.56l-.75,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06c-.38-.08-.75-.17-1.08-.27-1.37-.42-2.06-1-2.06-1.54V586c0,.56.68,1.11,2.05,1.53a8.11,8.11,0,0,0,1.08.27l.37.07.82.13.5,0c.25,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56,0,.7-.07.54-.09.64-.11c.18,0,.33-.08.49-.12l.54-.14.09,0,.48-.18.14-.06.28-.12.14-.08L401,587l.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M399.68,581.43c2.7.84,2.73,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3A19.13,19.13,0,0,1,399.68,581.43Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M415.38,590.83l.09-.11a.35.35,0,0,1,0-.08.5.5,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19V587.2a.63.63,0,0,1,0,.19l0,.07a.49.49,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1.61.61,0,0,1-.16.12l-.12.09-.21.12-.14.07-.28.13-.13.06-.49.17c-.19.06-.41.11-.63.16l-.48.13-.65.11-.54.08-.7.07-.56,0c-.25,0-.5,0-.75,0h-.56l-.75,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13L405,589q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28c.12,0,.25,0,.37.06l.82.13.5,0c.26,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12s0,0,.05,0l.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.58,585.67c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3A19,19,0,0,1,413.58,585.67Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M415.48,599.29l.09-.11.05-.08.06-.13a.14.14,0,0,0,0-.06.63.63,0,0,0,0-.19v-3.06a.58.58,0,0,1,0,.19s0,0,0,.07l-.06.13-.05.07-.09.12-.08.07a1.14,1.14,0,0,1-.11.11l-.16.12-.12.08-.2.12-.15.07a2.24,2.24,0,0,1-.27.13l-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56,0-.75,0h-.55l-.76,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28l.38.06.81.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57-.05.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.33-.08.49-.12.37-.08.54-.14l.08,0,.49-.18.14-.06.28-.12.14-.08a1.32,1.32,0,0,0,.2-.12.36.36,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,0,0l.06-.06A.3.3,0,0,0,415.48,599.29Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.68,594.13c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,413.68,594.13Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <polygon points="401.67 428.46 401.64 453.02 374 461.43 374.03 436.87 401.67 428.46" style="fill: #88c0f9"/>
                          <path d="M429.38,603.52l.09-.11a.35.35,0,0,0,0-.08.67.67,0,0,0,.06-.12l0-.07a1.23,1.23,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13,0,.07-.09.12-.07.07-.12.11-.15.12-.13.08-.2.12-.14.07-.28.13-.14.06c-.15.06-.31.12-.48.17l-.63.16-.49.13-.65.11-.54.08-.69.07-.57,0c-.24,0-.49,0-.74,0h-.56c-.25,0-.5,0-.76,0h-.55l-.76-.07-.5-.05c-.29,0-.55-.08-.82-.13l-.38-.06c-.38-.08-.74-.17-1.07-.27-1.37-.42-2.06-1-2.06-1.54V603c0,.56.69,1.11,2.06,1.54.33.1.69.19,1.07.27l.38.06.82.13.5,0c.25,0,.5,0,.76.06s.37,0,.56,0l.75,0,.56,0c.25,0,.5,0,.74,0l.57,0,.7-.07.54-.09c.21,0,.43-.06.64-.11s.33-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.09,0,.48-.18.14,0,.28-.13.14-.07.21-.12.12-.09.15-.12.05,0,.07-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.58,598.36c2.7.83,2.74,2.19.09,3a19,19,0,0,1-9.71,0c-2.71-.83-2.75-2.2-.09-3A18.91,18.91,0,0,1,427.58,598.36Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M429.3,595.08l.09-.12.05-.07.06-.13s0,0,0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.21.12-.14.08-.27.12-.14.06-.49.17c-.19.07-.41.11-.63.17l-.48.12-.65.11-.54.09c-.23,0-.46,0-.7.06l-.56.06-.75,0-.56,0-.75,0-.55,0-.77-.07-.5,0-.82-.14c-.12,0-.25,0-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3c0,.56.68,1.12,2.05,1.54q.51.15,1.08.27l.37.06c.27,0,.54.1.82.13l.5,0,.77.07H422l.76,0h.55l.75,0,.56-.05.7-.07.54-.08.65-.11.49-.13.54-.13.08,0,.49-.17.14-.06.27-.13.14-.07.21-.12.12-.08.16-.12.05,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.5,589.91c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,427.5,589.91Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M443.2,599.31l.09-.12,0-.07.06-.13a.14.14,0,0,0,0-.07.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13,0,.07-.09.12-.08.08-.11.1-.16.12-.12.08-.2.12-.14.08-.28.12L442,597a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17s-.32.09-.49.12l-.65.11-.54.09c-.22,0-.46,0-.69.06l-.57.06-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.81-.14c-.13,0-.26,0-.38-.06a8.52,8.52,0,0,1-1.08-.27c-1.36-.42-2-1-2-1.53v3.05c0,.56.69,1.12,2,1.54q.51.15,1.08.27l.38.06c.27,0,.53.1.81.13l.5,0,.77.07h.55l.76,0h.56l.74,0,.57-.05.69-.07.54-.08.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.14-.07.2-.12.12-.08.16-.12.05,0a.2.2,0,0,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.4,594.14c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,441.4,594.14Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="374.03 411.76 374 436.32 318.35 419.4 318.38 394.84 374.03 411.76" style="fill: #0e4772"/>
                          <polygon points="401.67 403.35 374.03 411.76 318.38 394.84 346.03 386.43 401.67 403.35" style="fill: #0d3954"/>
                          <path d="M387.66,565.71a.76.76,0,0,0,.08-.11l.06-.08a.5.5,0,0,0,.05-.13l0-.06a.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a.49.49,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12.46.46,0,0,1-.07.07l-.11.11a.91.91,0,0,1-.16.11l-.12.09-.21.12-.14.07-.28.13-.13.06-.49.17-.63.16-.48.13-.65.11-.54.08-.7.07-.56.05-.75,0h-.56l-.75,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a10.62,10.62,0,0,0,1.08.28c.12,0,.25,0,.37.06l.82.13.5,0c.26,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0,.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M385.86,560.55c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3A19,19,0,0,1,385.86,560.55Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M401.56,569.94l.09-.11a.35.35,0,0,1,.05-.08l.06-.12s0,0,0-.07a.63.63,0,0,0,0-.19v-3.06a.58.58,0,0,1,0,.19.25.25,0,0,0,0,.07l-.06.13-.05.07-.09.12-.08.07-.11.11-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06a4.65,4.65,0,0,1-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.11-.54.08-.7.07-.56,0c-.25,0-.5,0-.75,0H395l-.76,0h-.55l-.77-.07-.5-.05c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.54q.51.15,1.08.27l.38.06.81.13.5,0c.26,0,.51.05.77.06l.55,0,.76,0,.55,0c.25,0,.5,0,.75,0l.57-.05.69-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.18.14-.06.27-.12.14-.08a1,1,0,0,0,.21-.11l.12-.09.16-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M399.76,564.78c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,399.76,564.78Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M401.47,561.5a.47.47,0,0,0,.09-.12l.05-.07a.49.49,0,0,0,.06-.13l0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.75.75,0,0,1-.06.13l-.05.07a.47.47,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06c-.15.06-.31.12-.48.17s-.42.11-.63.17l-.49.12-.64.11-.54.09c-.23,0-.47,0-.7.06l-.57.06-.74,0-.56,0-.75,0-.55,0-.77-.07c-.17,0-.34,0-.5,0s-.55-.08-.82-.13-.25,0-.37-.06c-.38-.08-.75-.17-1.08-.27-1.37-.42-2.06-1-2.06-1.53v3c0,.56.68,1.12,2.05,1.54.33.1.7.19,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07h.55l.75,0h.56l.75,0,.56,0,.7-.07.54-.09.64-.1.49-.13c.18,0,.38-.08.54-.13l.09,0,.48-.17.14-.06.28-.13.14-.07.21-.12.12-.09a1,1,0,0,0,.16-.12l0,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M399.68,556.33c2.7.83,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,399.68,556.33Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M415.38,565.73l.09-.12,0-.07a1.12,1.12,0,0,0,.05-.13l0-.07a.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.38.38,0,0,1-.06.13.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.08-.11.1L415,563l-.12.08-.21.12-.14.08-.28.12-.13.06-.49.17c-.19.07-.41.11-.63.17l-.48.12-.65.11-.54.09c-.23,0-.46,0-.7.06l-.56.06-.75,0-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3c0,.56.68,1.12,2.05,1.54q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07H408l.75,0h.56l.75,0,.56,0,.7-.07.54-.08.65-.11.49-.13.54-.13.08,0,.49-.17.13-.06a1.82,1.82,0,0,0,.28-.13l.14-.07.21-.12L415,566l.16-.12s0,0,.05,0l.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.58,560.56c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,413.58,560.56Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M415.48,574.19l.09-.12.05-.07.06-.13a.14.14,0,0,0,0-.07.58.58,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2s0,0,0,.06l-.06.13-.05.07-.09.12-.08.08-.11.1-.16.12-.12.08-.2.12-.15.08-.27.12-.14.06a4.65,4.65,0,0,1-.49.17c-.19.07-.41.11-.62.17s-.32.09-.49.12l-.65.11-.54.09c-.22,0-.46,0-.7.06l-.56.06-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.81-.14c-.13,0-.26,0-.38-.06a8.52,8.52,0,0,1-1.08-.27c-1.36-.42-2.05-1-2.05-1.53v3.05c0,.56.69,1.12,2.05,1.54q.51.15,1.08.27l.38.06c.27,0,.53.1.81.13l.5,0,.77.07h.55c.25,0,.5,0,.76,0h.56l.74,0,.57-.05.69-.07.54-.08.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.28-.13.14-.07.2-.12.12-.08.16-.12,0,0a.2.2,0,0,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.68,569c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,413.68,569Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <polygon points="401.67 403.35 401.64 427.91 374 436.32 374.03 411.76 401.67 403.35" style="fill: #256eba"/>
                          <path d="M429.38,578.42l.09-.12,0-.07a.75.75,0,0,0,.06-.13l0-.06a1.36,1.36,0,0,0,0-.2v-3.05a.68.68,0,0,1,0,.19.14.14,0,0,1,0,.06l-.06.13a.35.35,0,0,1,0,.08l-.09.11-.07.08-.12.1-.15.12a.57.57,0,0,1-.13.08l-.2.12-.14.08-.28.12-.14.06-.48.18-.63.16c-.16,0-.32.09-.49.12l-.65.11c-.18,0-.35.07-.54.09l-.69.07-.57,0-.74,0-.56,0-.76,0-.55,0c-.26,0-.51,0-.76-.07l-.5,0-.82-.13-.38-.07a8,8,0,0,1-1.07-.27c-1.37-.42-2.06-1-2.06-1.53v3.06c0,.55.69,1.11,2.06,1.53.33.1.69.19,1.07.27.12,0,.26,0,.38.06s.53.1.82.13l.5,0,.76.07.56,0,.75,0,.56,0c.25,0,.5,0,.74,0l.57-.05.7-.06.54-.09.64-.11.49-.13.54-.13.09,0c.17-.05.33-.11.48-.17l.14-.06.28-.13.14-.07.21-.12.12-.08.15-.12.05,0,.07-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.58,573.25c2.7.83,2.74,2.2.09,3a19,19,0,0,1-9.71,0c-2.71-.83-2.75-2.19-.09-3A19,19,0,0,1,427.58,573.25Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M429.3,570l.09-.12.05-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12-.12.09-.21.11-.14.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.63.16s-.31.09-.48.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56.05c-.25,0-.5,0-.75,0l-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.06l-.5,0-.82-.13-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54v3.06c0,.55.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.56-.06c.24,0,.47,0,.7-.06l.54-.09.65-.11.49-.12.54-.14.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06.27-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.5,564.8c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3A19,19,0,0,1,427.5,564.8Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M443.2,574.2l.09-.11,0-.08.06-.13a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19.25.25,0,0,1,0,.07l-.06.13,0,.07-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.2.12-.14.07-.28.13-.14.06-.49.17c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.69.07-.57,0c-.25,0-.5,0-.75,0h-.55l-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.43-2-1-2-1.54v3.06c0,.56.69,1.11,2,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.38.06l.81.14.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57,0,.69-.07.54-.09.65-.11c.17,0,.33-.08.49-.12l.54-.14.08,0L442,575l.14-.06.28-.12.14-.08.2-.12a.36.36,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,.05,0l.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.4,569c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3.05A19,19,0,0,1,441.4,569Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <polygon points="374.03 386.66 374 411.22 318.35 394.29 318.38 369.73 374.03 386.66" style="fill: #0e4772"/>
                          <polygon points="401.67 378.25 374.03 386.66 318.38 369.73 346.03 361.32 401.67 378.25" style="fill: #195489"/>
                          <path d="M387.66,540.61l.08-.12.06-.07a1.12,1.12,0,0,0,.05-.13l0-.07a.58.58,0,0,0,0-.19V537a.69.69,0,0,1,0,.2l0,.06a.38.38,0,0,1-.06.13.18.18,0,0,1-.05.07.47.47,0,0,1-.09.12l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.13.06-.49.17c-.2.07-.42.11-.63.17l-.48.12-.65.11-.54.09c-.23,0-.46,0-.7.06l-.56.06-.75,0-.56,0-.75,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3c0,.56.68,1.12,2.05,1.54q.51.15,1.08.27l.37.06c.27.05.54.1.82.13l.5.05.77.07h.55l.75,0h.56l.75,0,.56,0,.7-.07.54-.08.65-.11.49-.13.54-.13.08,0,.49-.17.13-.06a1.82,1.82,0,0,0,.28-.13l.14-.07.21-.12.12-.08.16-.12a.1.1,0,0,1,0,0l.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M385.86,535.44c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,385.86,535.44Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M401.56,544.84l.09-.12.05-.07.06-.13s0,0,0-.06a.69.69,0,0,0,0-.2v-3a.68.68,0,0,1,0,.19.14.14,0,0,0,0,.06l-.06.13a.35.35,0,0,0-.05.08l-.09.11-.08.08-.11.1-.16.12-.12.08-.21.12-.14.08-.27.12-.14.06-.49.18c-.19.06-.41.11-.62.16s-.32.09-.49.12l-.65.11-.54.09-.7.07-.56.05-.75,0-.55,0-.76,0-.55,0c-.26,0-.51,0-.77-.07l-.5,0-.82-.14c-.12,0-.25,0-.37-.06a8.52,8.52,0,0,1-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3.06c0,.55.69,1.11,2.05,1.53q.51.15,1.08.27l.38.06c.26,0,.53.1.81.13l.5,0,.77.07h.55c.25,0,.5,0,.76,0l.55,0,.75,0,.57,0,.69-.06.54-.09.65-.11.49-.13.54-.13.08,0a4.65,4.65,0,0,0,.49-.17l.14-.06a2.24,2.24,0,0,0,.27-.13l.14-.07.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M399.76,539.67c2.7.83,2.74,2.2.08,3.05a19.13,19.13,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A19,19,0,0,1,399.76,539.67Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M401.47,536.39a.47.47,0,0,0,.09-.12l.05-.07a.38.38,0,0,0,.06-.13l0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.06a.52.52,0,0,1-.06.13.35.35,0,0,1-.05.08.31.31,0,0,1-.09.11l-.07.08-.11.1-.16.12-.12.08-.21.12-.14.08-.28.12-.14.06-.48.18-.63.16c-.16,0-.31.09-.49.12s-.43.08-.64.11l-.54.09-.7.07-.57,0-.74,0-.56,0-.75,0-.55,0c-.27,0-.52,0-.77-.07l-.5,0-.82-.13c-.12,0-.25,0-.37-.06a10,10,0,0,1-1.08-.28c-1.37-.42-2.06-1-2.06-1.53v3.06c0,.55.68,1.11,2.05,1.53a10,10,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0,.77.07.55,0,.75,0,.56,0,.75,0,.56-.06c.23,0,.47,0,.7-.06l.54-.09.64-.11.49-.12.54-.14.09,0c.17-.05.33-.11.48-.17l.14-.06.28-.12.14-.08.21-.12.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M399.68,531.22c2.7.84,2.73,2.2.08,3a18.91,18.91,0,0,1-9.71,0c-2.71-.83-2.74-2.19-.08-3A19,19,0,0,1,399.68,531.22Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M415.38,540.62l.09-.11a.35.35,0,0,1,0-.08.5.5,0,0,0,.05-.13l0-.06a.69.69,0,0,0,0-.2v-3a.63.63,0,0,1,0,.19l0,.07a.34.34,0,0,1-.06.12.35.35,0,0,1-.05.08.47.47,0,0,1-.09.12l-.07.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.11-.14.08-.28.12-.13.06-.49.18c-.19.06-.41.11-.63.16s-.31.09-.48.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56.05c-.25,0-.5,0-.75,0l-.56,0-.75,0c-.18,0-.37,0-.55,0s-.51,0-.77-.06l-.5,0-.82-.13-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54v3.06c0,.55.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.26,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0c.19,0,.37,0,.56-.06s.47,0,.7-.06l.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0,.49-.17.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.58,535.45c2.7.84,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3A19,19,0,0,1,413.58,535.45Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M415.48,549.08l.09-.11.05-.08.06-.13a.14.14,0,0,0,0-.06.68.68,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19s0,0,0,.07l-.06.13a.18.18,0,0,0-.05.07l-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.2.12-.15.07-.27.13-.14.06-.49.17c-.19.06-.41.11-.62.16s-.32.09-.49.12-.43.08-.65.11-.35.07-.54.09l-.7.07-.56,0c-.25,0-.5,0-.75,0h-.55l-.76,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.81-.13l-.38-.06q-.57-.12-1.08-.27c-1.36-.43-2.05-1-2.05-1.54v3.06c0,.56.69,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.38.07.81.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.56,0,.74,0,.57,0,.69-.07.54-.09.65-.11c.17,0,.33-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.28-.12.14-.08.2-.12a.36.36,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,0,0,.34.34,0,0,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M413.68,543.91c2.7.84,2.74,2.2.08,3.05a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3A19,19,0,0,1,413.68,543.91Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <polygon points="401.67 378.25 401.64 402.81 374 411.22 374.03 386.66 401.67 378.25" style="fill: #256eba"/>
                          <path d="M429.38,553.31l.09-.11a.35.35,0,0,0,0-.08.52.52,0,0,0,.06-.13l0-.06a1.23,1.23,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19.19.19,0,0,1,0,.07l-.06.13,0,.07-.09.12-.07.07-.12.1a.88.88,0,0,1-.15.12l-.13.09-.2.12-.14.07-.28.13-.14.06-.48.17-.63.16-.49.13-.65.11-.54.08-.69.07-.57,0c-.24,0-.49,0-.74,0h-.56l-.76,0h-.55l-.76-.07-.5-.05c-.29,0-.55-.08-.82-.13l-.38-.06c-.38-.08-.74-.17-1.07-.27-1.37-.42-2.06-1-2.06-1.54v3.06c0,.56.69,1.11,2.06,1.53a9.78,9.78,0,0,0,1.07.28c.12,0,.26,0,.38.06l.82.13.5,0c.25,0,.5.06.76.07l.56,0,.75,0,.56,0,.74,0,.57-.05.7-.07.54-.09c.21,0,.43-.06.64-.11s.33-.08.49-.12l.54-.14.09,0,.48-.18.14-.06.28-.12.14-.08.21-.12.12-.08.15-.12a.1.1,0,0,0,.05,0l.07-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.58,548.15c2.7.83,2.74,2.19.09,3a19,19,0,0,1-9.71,0c-2.71-.84-2.75-2.2-.09-3.05A19,19,0,0,1,427.58,548.15Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M429.3,544.87l.09-.12a.35.35,0,0,1,.05-.08l.06-.12s0,0,0-.07a.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2l0,.06a.69.69,0,0,1-.05.13l-.05.07-.09.12-.08.07-.11.11-.16.12-.12.08-.21.12-.14.07a2.24,2.24,0,0,1-.27.13l-.14.06-.49.17c-.19.06-.41.11-.63.16l-.48.13-.65.11-.54.08-.7.07-.56.05-.75,0-.56,0-.75,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13L419,543q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.53v3c0,.56.68,1.11,2.05,1.54q.51.15,1.08.27l.37.06.82.13.5.05c.26,0,.51,0,.77.06a5.06,5.06,0,0,0,.55,0l.76,0,.55,0c.25,0,.5,0,.75,0l.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12a4.75,4.75,0,0,0,.54-.14l.08,0,.49-.17.14-.06.27-.13.14-.07.21-.12.12-.09.16-.12.05,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M427.5,539.7c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.2-.08-3A18.91,18.91,0,0,1,427.5,539.7Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                        <g>
                          <path d="M443.2,549.1l.09-.12,0-.07.06-.13a.19.19,0,0,0,0-.07.63.63,0,0,0,0-.19v-3.06a.69.69,0,0,1,0,.2.14.14,0,0,1,0,.06l-.06.13,0,.07-.09.12a.6.6,0,0,1-.08.08l-.11.1-.16.12-.12.08-.2.12-.14.07-.28.13-.14.06a4.65,4.65,0,0,1-.49.17l-.62.17-.49.12-.65.11-.54.09-.69.06-.57.05-.75,0-.55,0-.76,0-.55,0-.77-.07-.5,0c-.28,0-.55-.08-.81-.13s-.26,0-.38-.06q-.57-.12-1.08-.27c-1.36-.42-2-1-2-1.53v3c0,.56.69,1.12,2,1.54q.51.15,1.08.27l.38.06c.27.05.53.1.81.13l.5.05.77.07h.55l.76,0h.56l.74,0,.57,0,.69-.07c.19,0,.36-.06.54-.09l.65-.1.49-.13.54-.13.08,0,.49-.17.14-.06.28-.13.14-.07.2-.12.12-.09.16-.12.05,0s0,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                          <path d="M441.4,543.93c2.7.83,2.74,2.2.08,3a19,19,0,0,1-9.71,0c-2.7-.83-2.74-2.19-.08-3A18.91,18.91,0,0,1,441.4,543.93Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <rect x="202.54" y="245.86" width="85.12" height="69.18" style="fill: #ffead2"/>
                <rect x="202.54" y="320.14" width="85.12" height="2.24" style="fill: #ffead2"/>
                <rect x="202.54" y="324.7" width="85.12" height="2.24" style="fill: #ffead2"/>
                <rect x="202.54" y="329.25" width="85.12" height="2.24" style="fill: #ffead2"/>
                <rect x="202.54" y="333.81" width="85.12" height="2.24" style="fill: #ffead2"/>
                <rect x="202.54" y="338.38" width="85.12" height="2.24" style="fill: #ffead2"/>
                <g>
                  <polyline points="278.48 255.95 267.24 259.7 260.25 296.42 230.02 296.42" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                  <polyline points="261.23 290.42 227.52 290.42 215.53 265.69 265.26 265.69" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                  <line x1="220.2" y1="274.68" x2="263.79" y2="274.68" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                  <line x1="262.25" y1="282.93" x2="223.89" y2="282.93" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                  <circle cx="236.39" cy="301.04" r="4.62" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                  <circle cx="253.25" cy="301.04" r="4.62" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                  <line x1="230.52" y1="265.69" x2="236.77" y2="290.42" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                  <line x1="243.5" y1="265.69" x2="243.5" y2="290.42" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                  <line x1="255" y1="265.69" x2="250.63" y2="290.42" style="fill: none;stroke: #8d94e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px"/>
                </g>
                <g>
                  <path d="M281.85,517.5c1.07,0,1.25.61,1.25,1a.79.79,0,0,1-.49.79.82.82,0,0,1,.63.86c0,.58-.33,1-1.36,1h-1.44V517.5Zm-.69,1.47h.77c.39,0,.49-.22.49-.4s-.08-.39-.49-.39h-.77Zm0,1.52H282c.4,0,.53-.21.53-.45s-.15-.45-.53-.45h-.87Z" transform="translate(-48.8 -170.25)" style="fill: #fff"/>
                  <path d="M287.21,519.63a1.5,1.5,0,1,1-3,0V517.5H285v2.11c0,.69.38.92.76.92s.77-.23.77-.92V517.5h.73Z" transform="translate(-48.8 -170.25)" style="fill: #fff"/>
                  <path d="M289.34,521.17v-1.23L288,517.5h.83l.85,1.55.84-1.55h.83l-1.31,2.44v1.23Z" transform="translate(-48.8 -170.25)" style="fill: #fff"/>
                  <path d="M296.33,521.17l-1.74-2.31v2.31h-.72V517.5h.43l1.74,2.31V517.5h.72v3.67Z" transform="translate(-48.8 -170.25)" style="fill: #fff"/>
                  <path d="M301.49,519.34a1.88,1.88,0,1,1-1.88-1.88A1.87,1.87,0,0,1,301.49,519.34Zm-3,0a1.15,1.15,0,1,0,1.14-1.2A1.13,1.13,0,0,0,298.47,519.34Z" transform="translate(-48.8 -170.25)" style="fill: #fff"/>
                  <path d="M305.67,521.17l-.9-2.21-.89,2.21h-.43L302,517.5h.81l.89,2.34.9-2.34H305l.91,2.34.88-2.34h.81l-1.48,3.67Z" transform="translate(-48.8 -170.25)" style="fill: #fff"/>
                </g>
                <g>
                  <polygon points="400.5 358.6 374.08 366.63 347.49 358.56 373.91 350.52 400.5 358.6" style="fill: #ff5c80"/>
                  <polygon points="347.49 361.26 374.05 369.41 374.08 366.63 347.49 358.56 347.49 361.26" style="fill: #d4165b"/>
                  <polygon points="400.5 358.6 400.5 361.26 374.05 369.41 374.08 366.63 400.5 358.6" style="fill: #e3456f"/>
                </g>
                <g>
                  <polygon points="373.49 367.11 347.07 375.14 320.48 367.07 346.9 359.03 373.49 367.11" style="fill: #ff5e4f"/>
                  <polygon points="320.48 369.77 347.04 377.92 347.07 375.14 320.48 367.07 320.48 369.77" style="fill: #a04644"/>
                  <polygon points="373.49 367.11 373.49 369.77 347.04 377.92 347.07 375.14 373.49 367.11" style="fill: #e3456f"/>
                </g>
                <g>
                  <polygon points="427.44 366.99 401.02 375.03 374.43 366.95 400.85 358.92 427.44 366.99" style="fill: #ff5e4f"/>
                  <polygon points="374.43 369.65 400.99 377.81 401.02 375.03 374.43 366.95 374.43 369.65" style="fill: #d4165b"/>
                  <polygon points="427.44 366.99 427.44 369.65 400.99 377.81 401.02 375.03 427.44 366.99" style="fill: #a04644"/>
                </g>
                <g>
                  <polygon points="400.43 375.5 374.01 383.54 347.42 375.46 373.84 367.43 400.43 375.5" style="fill: #ff5c80"/>
                  <polygon points="347.42 378.16 373.98 386.31 374.01 383.54 347.42 375.46 347.42 378.16" style="fill: #d4165b"/>
                  <polygon points="400.43 375.5 400.43 378.16 373.98 386.31 374.01 383.54 400.43 375.5" style="fill: #e3456f"/>
                </g>
                <g>
                  <path d="M180.25,565.87V546.31s1.65-8.87,11.07-7.56,13.08,14.3,5.1,20.88" transform="translate(-48.8 -170.25)" style="fill: #4d4d4d"/>
                  <path d="M165.64,585V553.21s.09-8.86,9.51-7.56,13.07,13.41,5.1,20V582.5l4.37,2.13v9.89Z" transform="translate(-48.8 -170.25)" style="fill: #111"/>
                  <path d="M162.67,572.54V553s1.66-8.87,11.08-7.56,13.07,14.3,5.1,20.88" transform="translate(-48.8 -170.25)" style="fill: #4d4d4d"/>
                  <polygon points="131.45 395.39 131.45 412.25 147.59 406.3 147.59 389.39 131.45 395.39" style="fill: #333"/>
                  <polygon points="135.82 414.39 151.83 408.43 151.83 418.32 135.82 424.27 135.82 414.39" style="fill: #333"/>
                  <polygon points="131.45 412.25 135.82 414.39 151.83 408.43 147.59 406.3 131.45 412.25" style="fill: #4d4d4d"/>
                  <polygon points="114.86 380.69 152.24 367.31 152.29 375.31 114.93 388.57 114.86 380.69" style="fill: #333"/>
                  <polygon points="113.87 402.05 113.87 419.5 130.01 412.96 130.01 396.05 113.87 402.05" style="fill: #333"/>
                  <polygon points="118.24 421.05 134.25 415.1 134.25 424.98 118.24 430.93 118.24 421.05" style="fill: #333"/>
                  <polygon points="113.87 419.5 118.24 421.05 134.25 415.1 130.01 412.96 113.87 419.5" style="fill: #4d4d4d"/>
                  <g>
                    <path d="M208.92,508.69a8.29,8.29,0,0,1-8-3.85c-3.15-4.7-9.45-12.5-14.54-7.4s-3,13.54-1.31,17.12,9.77,11.11,13.63,11.68,12.36-2.64,12.36-2.64l-.34-2.31a6.19,6.19,0,0,1-5.6-6.09c-.39-5.76,4.11-4.17,4.11-4.17Z" transform="translate(-48.8 -170.25)" style="fill: #d7d7d7"/>
                    <path d="M209.27,511s-4.23-1.82-4.11,4.17a5.81,5.81,0,0,0,5.6,6.09Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                    <path d="M211.61,523.64c-5.51-.2-8.63-3.47-8.56-9,0-2.46.74-4.21,2.13-5.22a5.57,5.57,0,0,1,4.58-.62l-.69,2.19a3.2,3.2,0,0,0-2.55.3c-.76.56-1.16,1.69-1.18,3.38-.05,4.25,2.09,6.49,6.35,6.65Z" transform="translate(-48.8 -170.25)" style="fill: #edebe6"/>
                    <path d="M225.88,517.52s-2.07-2.39-7.17-.69l-6.54,1.07-2-12.1a9.55,9.55,0,0,1,7.15-3.73c4.94-.36,7.33,2.29,8.16,4.53S225.88,517.52,225.88,517.52Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                    <path d="M208.47,507.35l2.39,16.38s5.61,2.32,8.24-4.23l-1.2-8.22S211.11,512.65,208.47,507.35Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                    <rect x="225.08" y="506.54" width="2.29" height="10.87" transform="translate(-120.39 -132.19) rotate(-8.31)" style="fill: #f5dfb7"/>
                    <path d="M215.37,511.9a7.05,7.05,0,0,1-4.44-.59,5.13,5.13,0,0,1-2.46-4,4.5,4.5,0,0,1,2-4.36,11.19,11.19,0,0,1,5-1.86c4.91-.71,10.47.9,11.12,5.3l-2.27.33c-.42-2.82-4.66-3.92-8.52-3.36a9,9,0,0,0-3.94,1.44c-1.29,1-1.16,1.88-1.11,2.18a2.87,2.87,0,0,0,1.32,2.29c1.58.9,4.31.11,5.23-.27l.61,2.24A12.08,12.08,0,0,1,215.37,511.9Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                  </g>
                  <polygon points="99.09 373.29 99.14 379.94 114.93 388.57 114.86 380.69 99.09 373.29" style="fill: #111"/>
                  <polygon points="98.35 373.04 103.52 329.03 120.4 335.84 114.86 380.69 98.35 373.04" style="fill: #243962"/>
                  <polygon points="120.4 335.84 148.71 326.01 153.7 366.87 114.86 380.69 120.4 335.84" style="fill: #4470b7"/>
                  <polygon points="103.52 329.03 133.81 319.2 148.71 326.01 120.4 335.84 103.52 329.03" style="fill: #36599e"/>
                  <path d="M158.57,496.17" transform="translate(-48.8 -170.25)" style="fill: #323232"/>
                  <path d="M163.18,494.9l0,2.62s.8,3.26,10.47,3.17,10.73-3.35,10.73-3.35l0-4.24Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                  <path d="M156.1,482.69v5.57s0,8.64,17.53,8.66c16.18,0,16.84-5.71,16.82-7.54l-.15-18-33.7.29Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                  <path d="M190.34,476s-4.55,3.15-13.43,1.89c-2.76-.4-5.36,0-5.36,4,0,1.67-2.88,1.23-2.88,3.48,0,5.6.93,7.57,1.93,8.79s5.26,3,14.94,1a38.09,38.09,0,0,1-21.34.79c-13.27-3.34-8.1-20.3-8.1-20.3l11.14-9.35S190.85,465.54,190.34,476Z" transform="translate(-48.8 -170.25)" style="fill: #e6b474"/>
                  <g>
                    <circle cx="128.11" cy="313.05" r="2.14" style="fill: #111"/>
                    <ellipse cx="128.19" cy="312.43" rx="0.64" ry="0.73" style="fill: #fcf6f3"/>
                    <path d="M188.2,481.51c0,1.08-.72,2.32-1.62,2.35s-1.18-.73-1.22-1.81c-.06-1.46.75-2.34,1.65-2.38S188.16,480.44,188.2,481.51Z" transform="translate(-48.8 -170.25)" style="fill: #111"/>
                    <ellipse cx="186.83" cy="481.25" rx="0.49" ry="0.65" transform="translate(-66.38 -163.05) rotate(-2.11)" style="fill: #fcf6f3"/>
                  </g>
                  <path d="M148.06,594V559.87s.09-8.86,9.51-7.56,13.07,13.41,5.1,20v17.45L167,591.3v9.88Z" transform="translate(-48.8 -170.25)" style="fill: #111"/>
                  <path d="M167,468.32s3.22,7.09,16,6.25C183.06,474.57,173,472.59,167,468.32Z" transform="translate(-48.8 -170.25)" style="fill: #686868"/>
                  <path d="M163.73,465.93s2.1,5.65,5.48,7.49C169.21,473.42,163.93,472.4,163.73,465.93Z" transform="translate(-48.8 -170.25)" style="fill: #686868"/>
                  <path d="M168.55,540.72a28.12,28.12,0,0,0,10.11-3S174.81,543.05,168.55,540.72Z" transform="translate(-48.8 -170.25)" style="fill: #073e70"/>
                  <path d="M188,534.09a28.12,28.12,0,0,0,10.11-3S194.27,536.42,188,534.09Z" transform="translate(-48.8 -170.25)" style="fill: #073e70"/>
                  <path d="M179.66,489.87s2,1.34,6.07-.07a3.44,3.44,0,0,1-2.9,2.59C180.51,492.56,180.18,491.73,179.66,489.87Z" transform="translate(-48.8 -170.25)" style="fill: #fff;stroke: #111;stroke-linecap: round;stroke-linejoin: round;stroke-width: 0.75px"/>
                  <path d="M180,489.13s0,.7-1.08,1" transform="translate(-48.8 -170.25)" style="fill: #fff;stroke: #111;stroke-linecap: round;stroke-linejoin: round;stroke-width: 0.75px"/>
                  <path d="M190.72,475.28c1.16-.65.75-2.92.69-4a42.4,42.4,0,0,0-.72-5.61c-.28-1.41-.64-4-1.67-5a4.43,4.43,0,0,0-1-.73,10.59,10.59,0,0,1-2-1,2.71,2.71,0,0,1-.48-1c-.33-.87-.69-1.76-1.68-2s-1.89.42-2.95.34c-.76-.06-1.34-.63-2.06-.82a3.42,3.42,0,0,0-2.41.3c-.11-.13-.79-1.17-1-1.17a2.75,2.75,0,0,0-1.5-.7c-1.41-.15-2.47,1.51-3.9,1-.84-.27-1.11-1-2.07-1.11a4.89,4.89,0,0,0-2.25.52,16.29,16.29,0,0,1-2.56,1.39c-1.09.28-2.14-.69-3.2-.05-.64.39-.86,1.17-1.39,1.67a3.88,3.88,0,0,1-2.22.83,2.45,2.45,0,0,0-1,.29c-.63.4-.71,1.27-1.06,1.94a.73.73,0,0,1-.33.34,1.59,1.59,0,0,1-.44,0c-1.23.07-1.52,3.46-1.64,4.35a44.34,44.34,0,0,0-.13,6.52c.15,3.39.45,6.76.81,10.13s.09,7.89,2.6,10.48c2.23,2.3,5.8,2.4,8.07,2.18,1.62-.15-.35-3.21.55-4.7s-.9-3.72.84-4.24,1.26,1.63,2.47,1.28.69-3.6,2.17-4.19,1.52-.52,2.11-1.78-2-4.89,5.77-3.49a19.91,19.91,0,0,0,7.26,0A15.84,15.84,0,0,0,189,476.1C189.9,475.6,190.13,475.6,190.72,475.28Z" transform="translate(-48.8 -170.25)" style="fill: #72482b"/>
                  <g>
                    <path d="M167.9,462.89a10.29,10.29,0,0,1,2.43,6.31c0,5.46-1,7.72-1,7.72S170.33,467.15,167.9,462.89Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M170.55,459.79a14.2,14.2,0,0,1,2.07,6.39c0,4.67-.81,6.6-.81,6.6S172.62,463.43,170.55,459.79Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M187.16,460.83a14.16,14.16,0,0,1,2.08,6.39c0,4.67-.81,6.6-.81,6.6S189.24,464.47,187.16,460.83Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M184.4,462.65a8.8,8.8,0,0,1,2.08,5.39c0,4.67-.81,6.6-.81,6.6S186.48,466.29,184.4,462.65Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M181.34,463.47a6.38,6.38,0,0,1,1.51,3.92,14.08,14.08,0,0,1-.59,4.79S182.85,466.12,181.34,463.47Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M163.32,482s-2.84,1.91-.68,6.45C162.64,488.41,161.86,484.53,163.32,482Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M160.68,456.8s1.82-.83,3,1.36A7,7,0,0,0,160.68,456.8Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M167,454.78a2.27,2.27,0,0,1,2.58,1.36A12.35,12.35,0,0,0,167,454.78Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M174,454.73s1.74.14,2.35,2.67A9.07,9.07,0,0,0,174,454.73Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                    <path d="M183,456.62s1.68.47,1.78,3.08A9.09,9.09,0,0,0,183,456.62Z" transform="translate(-48.8 -170.25)" style="fill: #4f2d16"/>
                  </g>
                  <path d="M172.79,481.34a9.57,9.57,0,0,1,6.12-2.06S174.53,476.21,172.79,481.34Z" transform="translate(-48.8 -170.25)" style="fill: #111"/>
                  <path d="M184.67,477.88a6.34,6.34,0,0,1,4.64.49S187.68,474.73,184.67,477.88Z" transform="translate(-48.8 -170.25)" style="fill: #111"/>
                  <path d="M173.28,504.71s5,5.78,10.56,3.88,6.61-9.88,6.61-9.88Z" transform="translate(-48.8 -170.25)" style="fill: #073e70"/>
                  <path d="M175.33,504l1.35,1.28a6.57,6.57,0,0,0,10.65-2.46l1.3-3.44Z" transform="translate(-48.8 -170.25)" style="fill: #d7d7d7"/>
                  <polygon points="135.87 373.29 133.53 337.72 135.53 337.59 137.83 372.63 135.87 373.29" style="fill: #073e70"/>
                  <g>
                    <path d="M172.92,520.26a8.28,8.28,0,0,1-7.36-4.89c-2.49-5.08-7.67-13.67-13.4-9.31s-4.77,13-3.63,16.78,8.17,12.34,11.91,13.43,12.61-.94,12.61-.94l0-2.33a6.2,6.2,0,0,1-4.72-6.8c.4-5.75,4.63-3.57,4.63-3.57Z" transform="translate(-48.8 -170.25)" style="fill: #d7d7d7"/>
                    <path d="M172.94,522.63s-3.94-2.38-4.63,3.57A5.8,5.8,0,0,0,173,533Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                    <path d="M173.55,535.44c-5.43-1-8.08-4.61-7.27-10.05.37-2.43,1.32-4.07,2.83-4.88a5.58,5.58,0,0,1,4.62,0l-1,2.07a3.23,3.23,0,0,0-2.57-.05c-.83.45-1.38,1.52-1.63,3.19-.62,4.21,1.19,6.72,5.39,7.45Z" transform="translate(-48.8 -170.25)" style="fill: #edebe6"/>
                    <path d="M188.52,531.32s-1.73-2.65-7-1.66l-6.62.17-.38-12.26a9.53,9.53,0,0,1,7.59-2.73c4.94.32,7,3.27,7.47,5.6S188.52,531.32,188.52,531.32Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                    <path d="M172.65,518.88l.14,16.54s5.24,3.07,8.74-3.06l-.07-8.31S174.55,524.48,172.65,518.88Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                    <rect x="188.47" y="520.44" width="2.29" height="10.87" transform="translate(-53.29 -168.61) rotate(-0.49)" style="fill: #f5dfb7"/>
                    <path d="M178.87,524.32a7,7,0,0,1-4.31-1.19,5.1,5.1,0,0,1-1.91-4.25,4.55,4.55,0,0,1,2.59-4,11.27,11.27,0,0,1,5.19-1.16c5,0,10.25,2.31,10.29,6.76l-2.3,0c0-2.85-4.07-4.52-8-4.49a8.83,8.83,0,0,0-4.1.89c-1.42.77-1.41,1.7-1.41,2a2.88,2.88,0,0,0,1,2.44c1.45,1.11,4.26.7,5.22.44l.3,2.31A11.71,11.71,0,0,1,178.87,524.32Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path d="M396,482.23a8,8,0,0,0,7.16-4.76c2.42-4.94,7.46-13.29,13-9s4.64,12.65,3.53,16.31-7.94,12-11.59,13.05-12.25-.91-12.25-.91l0-2.27a6,6,0,0,0,4.58-6.6c-.38-5.6-4.5-3.48-4.5-3.48Z" transform="translate(-48.8 -170.25)" style="fill: #d7d7d7"/>
                        <path d="M396,484.53s3.83-2.31,4.5,3.48a5.63,5.63,0,0,1-4.58,6.6Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                        <path d="M395.42,497c5.28-.92,7.85-4.49,7.07-9.77-.36-2.37-1.28-4-2.76-4.75a5.41,5.41,0,0,0-4.49,0l1,2a3.15,3.15,0,0,1,2.5-.05c.81.43,1.34,1.48,1.58,3.1.61,4.09-1.15,6.53-5.24,7.25Z" transform="translate(-48.8 -170.25)" style="fill: #edebe6"/>
                        <path d="M380.87,493s1.68-2.57,6.81-1.61l6.44.16.37-11.92a9.29,9.29,0,0,0-7.38-2.65c-4.81.31-6.76,3.18-7.26,5.44S380.87,493,380.87,493Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                        <path d="M396.3,480.88,396.16,497s-5.1,3-8.5-3l.07-8.08S394.45,486.33,396.3,480.88Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                        <rect x="378.68" y="482.4" width="2.23" height="10.57" transform="translate(706.61 808.36) rotate(-179.51)" style="fill: #f5dfb7"/>
                        <path d="M390.25,486.18a6.85,6.85,0,0,0,4.19-1.16,5,5,0,0,0,1.86-4.14,4.4,4.4,0,0,0-2.53-3.92,10.86,10.86,0,0,0-5-1.14c-4.83,0-10,2.25-10,6.57l2.23,0c0-2.77,4-4.39,7.76-4.36a8.66,8.66,0,0,1,4,.86c1.38.75,1.37,1.66,1.37,2a2.85,2.85,0,0,1-1,2.38c-1.41,1.07-4.14.67-5.08.42l-.29,2.24A10.47,10.47,0,0,0,390.25,486.18Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                      </g>
                      <polygon points="355.91 383.23 337.63 377.47 337.85 350.48 356.13 356.76 355.91 383.23" style="fill: #808bd8"/>
                      <polygon points="340.79 374.04 352.63 368.42 352.68 360.01 340.93 356.33 340.79 374.04" style="fill: #616aa3"/>
                      <polygon points="352.54 377.38 352.63 368.42 340.79 374.04 352.54 377.38" style="fill: #485596"/>
                      <polygon points="362.2 353.81 356.13 356.76 337.85 350.48 344.41 347.98 362.2 353.81" style="fill: #ffbc71"/>
                      <polygon points="362.13 361.42 371.89 356.49 354.36 350.02 352.05 350.48 362.2 353.81 362.13 361.42" style="fill: #ffbc71"/>
                      <path d="M421.52,527a4.62,4.62,0,0,1-.28-2.84l-16.39-6.27a4.94,4.94,0,0,0-1.69,2.37Z" transform="translate(-48.8 -170.25)" style="fill: #915415"/>
                      <path d="M404.71,553.48l.22-26.47,6.07-3-.07,7.62,9.76-4.93s.89-8.13,7.85-9.69,7.13,8,7,10.51-2.39,10.05-11.06,14.3A211.06,211.06,0,0,0,404.71,553.48Z" transform="translate(-48.8 -170.25)" style="fill: #7580d3"/>
                      <polygon points="376 389.53 357.71 383.78 357.93 357.79 376.22 364.06 376 389.53" style="fill: #808bd8"/>
                      <polygon points="360.87 380.34 372.71 374.21 372.77 367.31 361.01 363.63 360.87 380.34" style="fill: #616aa3"/>
                      <polygon points="372.63 383.69 372.71 374.21 360.87 380.34 372.63 383.69" style="fill: #485596"/>
                      <polygon points="382.22 368.72 392.07 363.42 374.33 357.22 373.12 358.03 382.28 361.11 382.22 368.72" style="fill: #a3acef"/>
                      <path d="M440.87,533.66a11.44,11.44,0,0,1,.62-2.46l-17.65-6.27a4.51,4.51,0,0,0-.71,2.54Z" transform="translate(-48.8 -170.25)" style="fill: #616aa3"/>
                      <polygon points="406.66 347.12 406.6 353.58 391.26 361.97 391.32 354.31 406.66 347.12" style="fill: #8d94e9"/>
                      <polygon points="391.32 354.31 354.98 341.3 354.92 348.89 391.26 361.97 391.32 354.31" style="fill: #808bd8"/>
                      <polygon points="407.38 346.87 402.35 304.08 385.93 310.71 391.32 354.31 407.38 346.87" style="fill: #ffc0f0"/>
                      <polygon points="385.93 310.71 358.41 301.15 353.51 341.3 391.32 354.31 385.93 310.71" style="fill: #dd90c7"/>
                      <polygon points="402.35 304.08 372.89 294.53 358.41 301.15 385.93 310.71 402.35 304.08" style="fill: #d360ad"/>
                      <path d="M445.08,471.31" transform="translate(-48.8 -170.25)" style="fill: #323232"/>
                      <path d="M440.59,470.07l0,2.55s-.77,3.17-10.17,3.09S420,472.44,420,472.44l0-4.12Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                      <path d="M447.47,458.2l-.05,6.8s-1.26,7.21-17,7-16.37-5.56-16.35-7.33l.15-17.5,32.76.28Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                      <path d="M422.84,467.07a6.17,6.17,0,0,0,3.5-1.16.58.58,0,0,0-.68-.93c-.14.1-3.4,2.42-6-.68a.56.56,0,0,0-.81-.07.57.57,0,0,0-.07.81A5.2,5.2,0,0,0,422.84,467.07Z" transform="translate(-48.8 -170.25)" style="fill: #162d33"/>
                      <path d="M424.8,559.78l.22-25.47,6.07-2.95L431,539l9.85-5.31s.8-7.75,7.76-9.31c6-1.35,6.95,5.37,7,9h0l0,1.76c0,1.61-.91,9.3-8.85,14.68a13.25,13.25,0,0,1-2.23,1.4l-.15.07a5.92,5.92,0,0,1-.8.4C435.07,555.86,424.8,559.78,424.8,559.78Z" transform="translate(-48.8 -170.25)" style="fill: #8d94e9"/>
                      <path d="M431.12,494.73a8,8,0,0,0,7.16-4.75c2.42-5,7.45-13.29,13-9.05s4.64,12.65,3.53,16.31-7.94,12-11.59,13.06-12.26-.91-12.26-.91l0-2.28a6,6,0,0,0,4.59-6.6c-.38-5.6-4.5-3.47-4.5-3.47Z" transform="translate(-48.8 -170.25)" style="fill: #d7d7d7"/>
                      <path d="M431.1,497s3.83-2.31,4.5,3.47a5.63,5.63,0,0,1-4.59,6.6Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                      <path d="M430.51,509.49c5.28-.92,7.85-4.48,7.06-9.77-.35-2.36-1.28-4-2.75-4.75a5.41,5.41,0,0,0-4.49,0l.95,2a3.16,3.16,0,0,1,2.5-.05c.81.44,1.35,1.48,1.59,3.1.61,4.09-1.16,6.53-5.24,7.25Z" transform="translate(-48.8 -170.25)" style="fill: #edebe6"/>
                      <path d="M416,505.49s1.67-2.58,6.81-1.62l6.44.16.37-11.92a9.29,9.29,0,0,0-7.38-2.65c-4.81.31-6.76,3.18-7.27,5.44S416,505.49,416,505.49Z" transform="translate(-48.8 -170.25)" style="fill: #d99f5d"/>
                      <path d="M431.38,493.39l-.13,16.08s-5.1,3-8.5-3l.07-8.08S429.54,498.84,431.38,493.39Z" transform="translate(-48.8 -170.25)" style="fill: #f5dfb7"/>
                      <rect x="413.77" y="494.9" width="2.23" height="10.57" transform="translate(776.68 833.66) rotate(-179.51)" style="fill: #f5dfb7"/>
                      <path d="M425.34,498.68a6.79,6.79,0,0,0,4.19-1.16,4.93,4.93,0,0,0,1.85-4.13,4.37,4.37,0,0,0-2.52-3.93,10.88,10.88,0,0,0-5-1.14c-4.82,0-10,2.25-10,6.57l2.23,0c0-2.77,4-4.39,7.75-4.36a8.8,8.8,0,0,1,4,.86c1.37.76,1.37,1.66,1.36,2a2.81,2.81,0,0,1-1,2.38c-1.4,1.07-4.14.67-5.07.43l-.29,2.23A10.47,10.47,0,0,0,425.34,498.68Z" transform="translate(-48.8 -170.25)" style="fill: #f0c889"/>
                      <path d="M409.67,550.59" transform="translate(-48.8 -170.25)"/>
                      <path d="M414.19,451.73s4.87,4.91,13.4,3.1c6.2-1.32,4.94,1.11,4.94,6.56s-.54,6.68-1.52,7.87-2.49,3.05-11.9,1.16a37.12,37.12,0,0,0,21,.47c11.36-3.15,6.52-19.62,6.52-19.62l-10-8.92S413.69,441.53,414.19,451.73Z" transform="translate(-48.8 -170.25)" style="fill: #e6b474"/>
                      <g>
                        <circle cx="378.44" cy="287.97" r="2.08" style="fill: #162d33"/>
                        <ellipse cx="378.36" cy="287.36" rx="0.62" ry="0.71" style="fill: #fcf6f3"/>
                        <path d="M416.27,456.48c0,1,.7,2.25,1.57,2.29S419,458.05,419,457c.05-1.43-.74-2.29-1.61-2.32S416.31,455.43,416.27,456.48Z" transform="translate(-48.8 -170.25)" style="fill: #162d33"/>
                        <ellipse cx="417.59" cy="456.23" rx="0.63" ry="0.48" transform="translate(-102.49 686.51) rotate(-87.89)" style="fill: #fcf6f3"/>
                      </g>
                      <polygon points="382.28 361.11 376.22 364.06 357.93 357.79 364.67 355.02 382.28 361.11" style="fill: #a3acef"/>
                    </g>
                    <path d="M428.92,457s.89,1.67,1.86,1.4c0,0-1.11,1.34-2.06.37A1.12,1.12,0,0,1,428.92,457Z" transform="translate(-48.8 -170.25)" style="fill: #162d33"/>
                    <path d="M416.4,455.56s-.19,1-.94.84a1.4,1.4,0,0,0,1.3.58Z" transform="translate(-48.8 -170.25)" style="fill: #162d33"/>
                    <path d="M430.14,432c-11.51,0-18.1,7.17-17.79,17.23,0,0,1.35,2.49,7.62,4.15,4.68,1.23,10.17-.07,12.64-.81a.93.93,0,0,1,1.19,1,90.67,90.67,0,0,0-.45,18.43s2.56,2.9,10-.62,11-6,10.43-11S453.07,432,430.14,432Z" transform="translate(-48.8 -170.25)" style="fill: #a9453a"/>
                    <path d="M412.34,448.35s-1,18.48.55,20,2.57,0,2.57,0-1.13-.77-1.37-3.62.1-15.15.1-15.15Z" transform="translate(-48.8 -170.25)" style="fill: #a9453a"/>
                  </g>
                  <g>
                    <path d="M436.41,454.19s-1.62,9.86,0,18.19C436.41,472.38,435.94,460.11,436.41,454.19Z" transform="translate(-48.8 -170.25)" style="fill: #dd6862"/>
                    <path d="M419.38,438.88A13.7,13.7,0,0,0,415,449.42,34.1,34.1,0,0,1,419.38,438.88Z" transform="translate(-48.8 -170.25)" style="fill: #dd6862"/>
                    <path d="M419.78,443.4a9,9,0,0,0-1.43,6.73A60.35,60.35,0,0,1,419.78,443.4Z" transform="translate(-48.8 -170.25)" style="fill: #dd6862"/>
                  </g>
                </g>
                <path d="M425.77,477.84c-.77,1.29-3,4.34-6.33,3.64-3-.63-3.49-4.86-3.52-7.06l-1-.35c0,2.31.37,7.56,4.33,8.39a6.14,6.14,0,0,0,1.18.12c3.42,0,5.61-3.19,6.32-4.4Z" transform="translate(-48.8 -170.25)" style="fill: #005151"/>
                <ellipse cx="419.34" cy="481.62" rx="2.48" ry="2.26" transform="translate(-157.61 670.35) rotate(-83.16)" style="fill: #b50f43"/>
                <ellipse cx="418.62" cy="480.88" rx="0.9" ry="0.82" transform="translate(-157.51 668.99) rotate(-83.16)" style="fill: #ff5c7f"/>
                <g>
                  <polygon points="363.26 363.78 317.15 348.43 306.36 310.55 308 309.36 354.12 324.71 363.26 363.78" style="fill: #545454"/>
                  <polygon points="361.61 364.97 315.5 349.62 306.36 310.55 352.47 325.9 361.61 364.97" style="fill: #2f2f2f"/>
                  <polygon points="361.61 364.97 384.84 354.71 384.84 353.32 356.64 343.74 361.61 364.97" style="fill: #545454"/>
                  <polygon points="358.74 344.45 362.87 362.81 384.84 353.32 358.74 344.45" style="fill: gray"/>
                </g>
                <g>
                  <polygon points="206.74 176.11 252.86 160.76 263.65 122.88 262 121.69 215.88 137.04 206.74 176.11" style="fill: #545454"/>
                  <polygon points="208.39 177.3 254.51 161.95 263.65 122.88 217.53 138.23 208.39 177.3" style="fill: #2f2f2f"/>
                  <polygon points="208.39 177.3 185.17 167.05 185.17 165.66 213.36 156.07 208.39 177.3" style="fill: #545454"/>
                  <polygon points="211.26 156.78 207.14 175.14 185.17 165.66 211.26 156.78" style="fill: gray"/>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="304.46 447.22 304.43 471.1 277.37 462.89 277.41 439.01 304.46 447.22" style="fill: #538b7e"/>
                      <polygon points="331.34 439.05 304.46 447.22 277.41 439.01 304.28 430.83 331.34 439.05" style="fill: #7dcfb3"/>
                      <path d="M346.11,609.87a.94.94,0,0,0,.09-.12l.05-.07a.61.61,0,0,0,.05-.12l0-.07a.57.57,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19l0,.06a.5.5,0,0,1-.05.13l-.05.07-.09.11L346,607l-.11.1-.15.11-.12.08-.2.12-.14.07-.27.12-.13.06c-.15.06-.31.12-.48.17s-.4.11-.61.16-.3.09-.47.12l-.63.11-.52.08-.68.07-.55,0-.73,0-.54,0-.73,0-.54,0c-.25,0-.5,0-.74-.07l-.49,0c-.27,0-.53-.08-.79-.13s-.25,0-.37-.06a10.07,10.07,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.66,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.12,0,.25,0,.37.06s.52.1.79.13l.49,0c.24,0,.49.05.74.06l.54,0,.73,0,.55,0,.72,0,.55-.05.68-.07.52-.08.63-.11c.17,0,.32-.08.48-.12s.36-.08.52-.13l.08,0,.48-.16.13-.06.27-.12.14-.08.2-.11.12-.08.15-.12.05,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #538b7e"/>
                      <path d="M344.36,604.84c2.63.81,2.67,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.66-2.14-.08-3A18.42,18.42,0,0,1,344.36,604.84Z" transform="translate(-48.8 -170.25)" style="fill: #8bdcbf"/>
                    </g>
                    <g>
                      <polygon points="331.34 439.05 331.3 462.93 304.43 471.1 304.46 447.22 331.34 439.05" style="fill: #6ebaa3"/>
                      <path d="M359.63,614l.09-.11s0,0,0-.08a.34.34,0,0,0,.06-.12l0-.06a1.48,1.48,0,0,0,0-.19v-3a1.37,1.37,0,0,1,0,.19l0,.06a.34.34,0,0,1-.06.12.43.43,0,0,1,0,.07l-.09.12-.07.07-.11.1-.16.12-.11.08-.21.11-.13.08-.27.12-.14.06-.47.17c-.19.06-.4.1-.61.15s-.31.09-.47.12a6.37,6.37,0,0,1-.63.11l-.53.09-.67.06-.55,0c-.24,0-.49,0-.73,0l-.54,0-.73,0c-.18,0-.36,0-.54,0l-.75-.06-.48-.05c-.28,0-.54-.08-.8-.13l-.36,0q-.56-.12-1.05-.27c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49q.5.15,1.05.27l.36.06.8.13.48,0,.75.07h.54l.73,0h.54c.24,0,.49,0,.73,0l.55-.05c.22,0,.45,0,.67-.06l.53-.09.63-.1.47-.12.53-.14.08,0,.47-.17.14-.06.27-.12.13-.07.21-.12.11-.08.16-.12.05,0,.06-.07Z" transform="translate(-48.8 -170.25)" style="fill: #538b7e"/>
                      <path d="M357.88,609c2.63.8,2.66,2.13.08,3a18.53,18.53,0,0,1-9.44,0c-2.63-.81-2.67-2.13-.08-3A18.42,18.42,0,0,1,357.88,609Z" transform="translate(-48.8 -170.25)" style="fill: #8bdcbf"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M359.55,605.77a.83.83,0,0,0,.08-.12l.05-.07a.67.67,0,0,0,.06-.12l0-.07a1.1,1.1,0,0,0,0-.18v-3a.58.58,0,0,1,0,.19.14.14,0,0,1,0,.06.52.52,0,0,1-.06.13l-.05.07a.44.44,0,0,1-.08.11l-.08.08a.94.94,0,0,0-.1.1.91.91,0,0,1-.16.11.5.5,0,0,1-.12.08l-.2.12-.13.07-.27.12-.14.06c-.15.06-.3.12-.47.17s-.4.11-.61.16-.31.09-.47.12l-.63.11-.53.08-.68.07-.55,0-.72,0-.54,0-.74,0-.53,0c-.26,0-.5,0-.75-.07l-.48,0c-.28,0-.54-.08-.8-.13s-.25,0-.36-.06a9.48,9.48,0,0,1-1.05-.26c-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49a10.07,10.07,0,0,0,1.05.26c.11,0,.24,0,.36.06s.52.1.8.13l.48.05c.25,0,.49,0,.75.06l.53,0,.74,0,.54,0,.72,0,.56,0,.67-.07.53-.08.63-.11c.16,0,.31-.08.47-.12l.53-.13.08,0a4.24,4.24,0,0,0,.47-.17l.14,0,.27-.12.13-.08.2-.11.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #538b7e"/>
                      <path d="M357.8,600.74c2.63.81,2.66,2.14.08,3a18.42,18.42,0,0,1-9.44,0c-2.63-.81-2.67-2.14-.08-3A18.42,18.42,0,0,1,357.8,600.74Z" transform="translate(-48.8 -170.25)" style="fill: #8bdcbf"/>
                    </g>
                    <g>
                      <path d="M373.06,609.88l.09-.11a.35.35,0,0,0,.05-.08l.06-.12s0,0,0-.06a.68.68,0,0,0,0-.19v-3a.68.68,0,0,1,0,.19s0,0,0,.06l-.06.12a.18.18,0,0,1-.05.07.94.94,0,0,1-.09.12L373,607a.52.52,0,0,1-.11.1l-.15.12-.12.08-.2.11-.14.08-.27.12-.13.06-.47.16a6.06,6.06,0,0,1-.61.16c-.16,0-.31.09-.48.12a6.37,6.37,0,0,1-.63.11l-.52.08-.68.07-.55,0c-.24,0-.48,0-.72,0l-.55,0-.73,0c-.18,0-.36,0-.54,0s-.49,0-.74-.06l-.49-.05c-.27,0-.53-.08-.79-.13l-.37,0c-.37-.08-.72-.17-1-.27-1.33-.41-2-1-2-1.49v3c0,.54.67,1.08,2,1.49.32.1.67.19,1,.27l.37.06.79.13.49,0,.75.07h.53l.74,0h.54l.72,0,.55,0c.23,0,.46,0,.68-.06l.52-.09.63-.1.48-.12.52-.14.09,0,.47-.17.13-.06.27-.12.14-.07.2-.12.12-.08.15-.12.05,0L373,610Z" transform="translate(-48.8 -170.25)" style="fill: #538b7e"/>
                      <path d="M371.32,604.86c2.62.8,2.66,2.13.08,2.95a18.42,18.42,0,0,1-9.44,0c-2.64-.81-2.67-2.13-.08-3A18.42,18.42,0,0,1,371.32,604.86Z" transform="translate(-48.8 -170.25)" style="fill: #8bdcbf"/>
                    </g>
                  </g>
                </g>
                <path d="M252.63,250.71a11.2,11.2,0,0,1,2.45,10.82S255.16,254.42,252.63,250.71Z" transform="translate(-48.8 -170.25)" style="fill: #545454"/>
                <path d="M250.26,253.87a7.7,7.7,0,0,1,.63,8.21A19.33,19.33,0,0,0,250.26,253.87Z" transform="translate(-48.8 -170.25)" style="fill: #545454"/>
                <ellipse cx="285.61" cy="319.74" rx="3.61" ry="1.93" transform="translate(-143.61 347.2) rotate(-75.69)" style="fill: #545454"/>
                <ellipse cx="380.98" cy="507.11" rx="1.93" ry="3.61" transform="translate(-162.33 -60.34) rotate(-14.31)" style="fill: #545454"/>
                <g>
                  <g>
                    <polygon points="247.87 369.37 247.87 459.88 190.64 437.64 190.64 347.13 247.87 369.37" style="fill: #efd450"/>
                    <path d="M247.91,526.31v-8.12c0-8.09,6.41-10.44,13.87-7.29l1.84.67c6.92,3.22,6.92,7.86,6.92,14.9V535" transform="translate(-48.8 -170.25)" style="fill: none;stroke: #e5e5e5;stroke-miterlimit: 10"/>
                    <polygon points="247.87 459.92 235.6 459.67 227.35 466.63 227.35 376.14 235.3 368.71 247.87 369.43 247.87 459.92" style="fill: #d3b93d"/>
                    <polygon points="247.87 369.43 235.3 368.71 235.6 459.67 247.87 459.92 247.87 369.43" style="fill: #ffe861"/>
                    <polygon points="190.64 437.62 181.66 444.43 170.12 444.33 170.12 353.84 181.35 353.47 190.64 347.13 190.64 437.62" style="fill: #d3b93d"/>
                    <polygon points="190.64 347.13 181.35 353.47 181.66 444.43 190.64 437.62 190.64 347.13" style="fill: #f9dc5f"/>
                    <path d="M236.6,544.62v-22c0-8.1,6.41-10.45,13.87-7.3l1.84.67c6.92,3.22,6.92,7.87,6.92,14.9v17.68" transform="translate(-48.8 -170.25)" style="fill: none;stroke: #e5e5e5;stroke-miterlimit: 10"/>
                    <polygon points="227.35 376.14 227.35 466.65 170.12 444.41 170.12 353.9 227.35 376.14" style="fill: #ffe861"/>
                    <polygon points="184.45 419.8 212.86 430.83 212.86 394.95 184.45 383.92 184.45 419.8" style="fill: #ff5e4f"/>
                  </g>
                  <polygon points="195.04 353.11 203.19 356.28 203.19 361.88 195.04 358.96 195.04 353.11" style="fill: #d3b93d"/>
                  <polygon points="217.66 361.88 225.81 365.05 225.81 370.65 217.66 367.73 217.66 361.88" style="fill: #d3b93d"/>
                </g>
                <g>
                  <g>
                    <polygon points="341.4 425.39 341.4 515.9 398.63 493.66 398.63 403.15 341.4 425.39" style="fill: #efd450"/>
                    <path d="M439,582.33v-8.12c0-8.09-6.41-10.44-13.86-7.29l-1.85.66c-6.91,3.23-6.91,7.87-6.91,14.91V591" transform="translate(-48.8 -170.25)" style="fill: none;stroke: #e5e5e5;stroke-miterlimit: 10"/>
                    <polygon points="341.4 515.94 353.67 515.68 361.92 522.64 361.92 432.16 353.98 424.72 341.4 425.45 341.4 515.94" style="fill: #d3b93d"/>
                    <polygon points="341.4 425.45 353.98 424.72 353.67 515.68 341.4 515.94 341.4 425.45" style="fill: #ffe861"/>
                    <polygon points="398.63 493.64 407.62 500.45 419.16 500.35 419.16 409.86 407.92 409.49 398.63 403.15 398.63 493.64" style="fill: #d3b93d"/>
                    <polygon points="398.63 403.15 407.92 409.49 407.62 500.45 398.63 493.64 398.63 403.15" style="fill: #f9dc5f"/>
                    <path d="M450.27,600.64V578.58c0-8.09-6.4-10.44-13.86-7.29l-1.85.67c-6.91,3.22-6.91,7.86-6.91,14.9v17.67" transform="translate(-48.8 -170.25)" style="fill: none;stroke: #e5e5e5;stroke-miterlimit: 10"/>
                    <polygon points="361.92 432.16 361.92 522.67 419.16 500.43 419.16 409.92 361.92 432.16" style="fill: #ffe861"/>
                    <polygon points="404.82 475.82 376.41 486.85 376.41 450.97 404.82 439.94 404.82 475.82" style="fill: #ff5e4f"/>
                  </g>
                  <polygon points="394.24 409.13 386.09 412.3 386.09 417.9 394.24 414.98 394.24 409.13" style="fill: #d3b93d"/>
                  <polygon points="371.61 417.9 363.46 421.07 363.46 426.67 371.61 423.75 371.61 417.9" style="fill: #d3b93d"/>
                </g>
                <g>
                  <g>
                    <polygon points="112.45 400.39 112.45 490.9 55.21 468.66 55.21 378.15 112.45 400.39" style="fill: #efd450"/>
                    <path d="M112.49,557.33v-8.12c0-8.09,6.41-10.44,13.86-7.29l1.85.66c6.91,3.23,6.91,7.87,6.91,14.91V566" transform="translate(-48.8 -170.25)" style="fill: none;stroke: #e5e5e5;stroke-miterlimit: 10"/>
                    <polygon points="112.45 490.94 100.17 490.68 91.92 497.64 91.92 407.16 99.87 399.72 112.45 400.45 112.45 490.94" style="fill: #d3b93d"/>
                    <polygon points="112.45 400.45 99.87 399.72 100.17 490.68 112.45 490.94 112.45 400.45" style="fill: #ffe861"/>
                    <polygon points="55.21 468.64 46.23 475.45 34.69 475.35 34.69 384.86 45.93 384.49 55.21 378.15 55.21 468.64" style="fill: #d3b93d"/>
                    <polygon points="55.21 378.15 45.93 384.49 46.23 475.45 55.21 468.64 55.21 378.15" style="fill: #f9dc5f"/>
                    <path d="M101.18,575.64V553.58c0-8.09,6.41-10.44,13.86-7.29l1.85.67c6.91,3.22,6.91,7.86,6.91,14.9v17.67" transform="translate(-48.8 -170.25)" style="fill: none;stroke: #e5e5e5;stroke-miterlimit: 10"/>
                    <polygon points="91.92 407.16 91.92 497.67 34.69 475.43 34.69 384.92 91.92 407.16" style="fill: #ffe861"/>
                    <polygon points="49.02 450.82 77.44 461.85 77.44 425.97 49.02 414.94 49.02 450.82" style="fill: #ff5e4f"/>
                  </g>
                  <polygon points="59.61 384.13 67.76 387.3 67.76 392.9 59.61 389.97 59.61 384.13" style="fill: #d3b93d"/>
                  <polygon points="82.23 392.9 90.38 396.06 90.38 401.67 82.23 398.75 82.23 392.9" style="fill: #d3b93d"/>
                </g>
                <g>
                  <g>
                    <polygon points="180.67 439.6 180.63 464.15 152.81 455.7 152.84 431.15 180.67 439.6" style="fill: #256eb8"/>
                    <polygon points="194.49 435.4 180.67 439.6 152.84 431.15 166.67 426.95 194.49 435.4" style="fill: #a2d4ff"/>
                    <path d="M222.11,602l.09-.11a.35.35,0,0,0,.05-.08.31.31,0,0,0,.06-.13l0-.06a.68.68,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a.34.34,0,0,1-.06.12.35.35,0,0,1-.05.08.47.47,0,0,1-.09.12L222,599a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.11-.14.08-.28.12a.59.59,0,0,1-.14.06l-.48.18-.63.16c-.16,0-.31.09-.49.12a6.09,6.09,0,0,1-.64.11l-.54.09-.7.07-.56,0c-.25,0-.5,0-.75,0l-.56,0-.75,0c-.19,0-.37,0-.55,0s-.52,0-.77-.06l-.5-.05-.82-.13-.37-.06q-.57-.12-1.08-.27c-1.37-.43-2.05-1-2.05-1.54v3.06c0,.55.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27c.12,0,.25,0,.37.06l.82.14.5,0c.25,0,.51.06.77.07l.55,0,.75,0,.56,0,.75,0c.19,0,.37,0,.56-.06s.47,0,.7-.06l.54-.09.65-.11c.17,0,.32-.08.49-.12l.54-.14.08,0,.49-.17.13-.06.28-.12.14-.08.21-.12.12-.08.16-.12,0,0,.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                    <path d="M220.32,596.85c2.7.84,2.73,2.2.08,3a19,19,0,0,1-9.71,0c-2.71-.84-2.74-2.2-.08-3A19,19,0,0,1,220.32,596.85Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                  </g>
                  <g>
                    <polygon points="194.49 435.4 194.46 459.96 180.63 464.15 180.67 439.6 194.49 435.4" style="fill: #88c0f9"/>
                    <path d="M236,606.25l.09-.11a.35.35,0,0,1,0-.08l.06-.13s0,0,0-.06a.63.63,0,0,0,0-.19v-3.06a.63.63,0,0,1,0,.19l0,.07a1.12,1.12,0,0,1-.05.13.18.18,0,0,0,0,.07l-.09.12-.08.07a.52.52,0,0,1-.11.1l-.16.12-.12.09-.21.12-.14.07-.27.13-.14.06-.49.17c-.19.06-.41.11-.62.16l-.49.13-.65.1c-.18,0-.35.07-.54.09l-.7.07-.56.05-.75,0h-.56l-.75,0h-.55l-.77-.07-.5,0c-.28,0-.55-.08-.82-.13l-.37-.06q-.57-.12-1.08-.27c-1.37-.42-2.05-1-2.05-1.54v3.06c0,.56.68,1.11,2.05,1.53a8.52,8.52,0,0,0,1.08.27l.37.07.82.13.5,0c.26,0,.51.06.77.07l.55,0,.76,0,.55,0,.75,0,.56,0,.7-.07c.19,0,.36-.06.54-.09s.44-.06.65-.11.32-.08.49-.12l.54-.14.08,0,.49-.18.14-.06.27-.12.14-.08.21-.12a.5.5,0,0,0,.12-.08l.16-.12a.1.1,0,0,0,0,0l.06-.06Z" transform="translate(-48.8 -170.25)" style="fill: #256eb8"/>
                    <path d="M234.22,601.09c2.7.83,2.74,2.19.08,3a19,19,0,0,1-9.71,0c-2.7-.84-2.74-2.2-.08-3A19,19,0,0,1,234.22,601.09Z" transform="translate(-48.8 -170.25)" style="fill: #b6ddff"/>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Search from '../../components/Search.vue';
import PrimaryButton from '../../components/PrimaryButton.vue';
import SetCard from '../../components/SetCard.vue';
import AreaChartWithDatetime from '../../components/AreaChartWithDatetime.vue';
import Toggle from '../../components/Toggle.vue';
import DealCard from '../../components/DealCard.vue';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: 'LandingPageDesktop',
  components: {
    PrimaryButton,
    Search,
    SetCard,
    AreaChartWithDatetime,
    Toggle,
    DealCard
  },
  inject: [
    'api',
    'loggedIn',
    'localLanguage',
    'localCurrency'
  ],
  data() {
    return {
      loadingActiveCollection: false,
      loadingActiveMinifigures: false,
      isSmallScreen: false,
      windowHeight: '',
      windowWidth: '',
      closeIcon: false,
      inputValue: "",
      themes: [],
      starWarsThemeCount: '-',
      harryPotterThemeCount: '-',
      creatorThemeCount: '-',
      technicThemeCount: '-',
      lastAddedSets: [],
      main_image: [],
      allSetsCount: '',
      allMinifiguresCount: '',
      allCollectionSetCount: '',
      allPieceCount: '',
      collection: [],
      chartData: [],
      firstChartEntryDatetime: null,
      topMinifigures: [],
      tabTopMinifigures: 'all',
      minifigureConditionSwitch: false,
      topThemes: [],
      dealSets: [],
    }
  },
  watch: {
    minifigureConditionSwitch() {
      this.getTopMinifigures();
    },
    tabTopMinifigures() {
      this.getTopMinifigures();
    }
  },
  computed: {
    sumCollectionSets() {
      if (this.collection) {
        let result = 0;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (!(item.sales_date || item.sales_price)) { result++; }
          });
        });
        return result
      }
      return 0;
    },
    sumCollectionCurrentValue() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(set => {
          set.collection.forEach(item => {
            if (item.condition) {
              let currentSetValue = 0.00;
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('new')))) { currentSetValue =  item.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.avg_price ?? 0.00 }
              if (!(item.sales_date || item.sales_price) && (item.condition && (item.condition.includes('used')))) { currentSetValue =  item.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.avg_price ?? 0.00 }
              result = parseFloat(result + currentSetValue);
            }
          });
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    sumCollectionRetailValue() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(set => {
          if (set.set?.retail_price) {
            const localRetailPrice = this.mapRetailPriceToLocal(set.set.retail_price, this.localCurrency);
            set.collection.forEach(item => {
              if (!(item.sales_date || item.sales_price)) {
                result = parseFloat(result + localRetailPrice);
              }
            });
          }
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    sumCollectionPricePaidValue() {
      if (this.collection) {
        let result = 0.00;
        this.collection.forEach(item => {
          item.collection.forEach(collectionItem => {
            if (!(collectionItem.sales_date || collectionItem.sales_price)) {
              result += parseFloat((collectionItem?.price_payed || 0).toFixed(2));
            }
          });
        });
        return result.toFixed(2) || '';
      }
      return '';
    },
    diffPerformancePercent() {
      let result = 0;
      let diff = (this.sumCollectionCurrentValue - this.sumCollectionPricePaidValue).toFixed(2);
      let calc = ((diff / this.sumCollectionPricePaidValue) * 100).toFixed(2);
      if (isNaN(calc)) {
        return result.toFixed(2);
      }
      if (this.sumCollectionCurrentValue == 0.00 || this.sumCollectionCurrentValue == '') { return '-'; }
      return calc;
    },
    diffPerformance() {
      return (this.sumCollectionCurrentValue - this.sumCollectionPricePaidValue).toFixed(2) || 0.00;
    },
  },
  unmounted() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true });
  },
  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true });

    gsap.from('.fade_in', {
      opacity: 0,
      y: 100,
      duration: 1
    });

    function animateFrom(elem, direction) {
    direction = direction || 1;
    var x = 0,
        y = direction * 100;
    if(elem.classList.contains("scroll_fade_fromLeft")) {
      x = -200;
      y = 0;
    } else if (elem.classList.contains("scroll_fade_fromRight")) {
      x = 200;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
      duration: 2,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto"
    });
  }

  function hide(elem) {
    gsap.set(elem, {autoAlpha: 0});
  }

  document.addEventListener("DOMContentLoaded", function() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".scroll_fade").forEach(function(elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        toggleActions: 'restart pause reverse pause',
        markers: false,
        onEnter: function() { animateFrom(elem) },
        onEnterBack: function() { animateFrom(elem, -1) },
        onLeave: function() { hide(elem) }, // assure that the element is hidden when scrolled into view
      });
    });
  });

  },
  created() {
    this.windowHeight = window.screen.availHeight + 'px';
    this.windowWidth = window.screen.availWidth + 'px';
    this.getLatestCreatedSetsWithName();
    // this.getThemes();
    this.getDatabaseInfos();
    this.getCollectionData();
    this.getTopMinifigures();
    this.getTopThemes();
    this.getDeals();
  },
  methods: {
    onResize () {
      this.isSmallScreen = window.innerWidth < 970;
    },
    async getLatestCreatedSetsWithName() {
      this.lastAddedSets = [];
      const res = await this.api.items('sets?fields=*.*,images.directus_files_id.*&filter={"_and":[{"name":{"name":{"_nempty":true}}},{"images":{"set_set":{"_nempty":true}}}]}&sort=-date_created&limit=6').readByQuery();
      this.lastAddedSets = res.data;
    },
    async getDatabaseInfos() {
      this.allSetsCount = 0;
      this.allMinifiguresCount = 0;
      this.allCollectionSetCount = 0;
      this.allPieceCount = 0;
      const resSets = await this.api.items('sets?fields=*&meta=filter_count').readByQuery({
        filter: {}
      });
      this.allSetsCount = resSets.meta.filter_count;

      const resMinifigs = await this.api.items('minifigures?fields=*&meta=filter_count').readByQuery({
        filter: {}
      });
      this.allMinifiguresCount = resMinifigs.meta.filter_count;

      const resCollection = await this.api.items('sets?aggregate[sum]=in_collections').readByQuery();
      this.allCollectionSetCount = resCollection?.data[0]?.sum?.in_collections ?? '-';

      const resImages = await this.api.items('sets?aggregate[sum]=pieces').readByQuery();
      this.allPieceCount = resImages?.data[0]?.sum?.pieces ?? '-';
    },
    async getCollectionData() {
      if (this.loggedIn) {
        this.loadingActiveCollection = true;
        const response = await this.api.items('collections?filter[user_created][_eq]=$CURRENT_USER&fields=*.*,set.name.*,set.theme.name,set.retail_price.*,set.header_image.*,set.images.directus_files_id.*,set.bricklink_sales.*&limit=-1').readByQuery();
        if (response) {
          this.loadingActiveCollection = false;
          this.collection = response.data;
          if (this.collection.length > 0) { this.setChartData(); }
        }
      }
    },
    async getTopMinifigures() {
      this.loadingActiveMinifigures = true;
      this.topMinifigures = [];
      let minifiguresFilter = {}
      if (this.minifigureConditionSwitch === false) {
        minifiguresFilter['bricklink_types_id'] = {"id": {"_eq": "six_month_used" }}
      }
      else {
        minifiguresFilter['bricklink_types_id'] = {"id": {"_eq": "six_month_new" }}
      }
      if (this.tabTopMinifigures === "starwars") {
        minifiguresFilter['minifigures_number'] = {"_contains": "sw" }
      }
      if (this.tabTopMinifigures === "superheroes") {
        minifiguresFilter['minifigures_number'] = {"_contains": "sh" }
      }

      const minifiguresResponse = await this.api.items('minifigures_bricklink_sales?fields=*.*,images.directus_files_id.*,minifigures.*,minifigures.minifigures_number.*,minifigures_number.images.*'
      + '&sort=-avg_price'
      + '&limit=10').readByQuery({
        filter: minifiguresFilter
      });

      if (minifiguresResponse) {
        this.loadingActiveMinifigures = false;
        this.topMinifigures = minifiguresResponse.data;
      }
    },
    async getTopThemes() {
      this.topThemes = [];
      const topThemesResponse = await this.api.items('themes?fields=*.*&filter[_and][0][top_theme][_eq]=true'
      + '&limit=50').readByQuery();

      topThemesResponse.data.forEach(themeItem => {
        this.topThemes.push(themeItem.name);
      });
    },
    async getDeals() {
      let res = [];
      this.dealSets = [];

      res = await this.api.items('retailer_prices?fields=*.*,set.name.name,set.retail_price.*,set.theme.name,set.header_image.id,set.images.directus_files_id.*'
      + '&filter[_and][0][discount][_gt]=30&filter[_and][1][set][set][_ncontains]=-G&filter[_and][2][set][retail_price][price][_gt]=20'
      + '&sort=-discount'
      + '&meta=filter_count'
      + '&limit=200').readByQuery({
      });

      res.data.forEach(set => {
        let setData = {
          discount: set.discount || '',
          price: set.price || '',
          set: set.set || '',
        };
        if (setData.discount && setData.price && setData.set && setData.discount != null) {
          if (setData.discount > 0.00) {
            try {
              if (setData.set?.theme?.name) {
                if (this.topThemes.includes(setData.set.theme.name)) {
                  this.dealSets.push(setData);
                }
              }
            }
            catch(err) { console.warn(err, 'Theme or themename are missing!') }
          }
        }
      });

      const setsMapped = [];
      const map = new Map();
      for (const item of this.dealSets) {
        if (!map.has(item.set.set)) {
          map.set(item.set.set, true);
          setsMapped.push(item);
        }
      }
      this.dealSets = setsMapped;
    },
    checkInput() {
      if (this.inputValue !== "") {
        this.closeIcon = true;
        return;
      }
      this.closeIcon = false;
      return;
    },
    clearInput() {
      this.inputValue = "";
      this.closeIcon = false;
    },
    getSetOverviewImage(header_image, images) {
      if (header_image) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + header_image.id + '?height=200';
          return img;
        }
        catch(err) {
          if (images) {
            try {
              let img = process.env.VUE_APP_API_IMG_PATH + images
                          .find(img => img.directus_files_id.filename_download
                          .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
              return img;
            }
            catch(err) {
              for (let i = 0; i < images.length; i++) {
                if (images[i]?.directus_files_id) {
                  let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
                  return img; }
              }
            }
          }
        }
      }
      else if (images) {
        try {
          let img = process.env.VUE_APP_API_IMG_PATH + images
                      .find(img => img.directus_files_id.filename_download
                      .includes('_alt1' || 'Alt1')).directus_files_id.id + '?height=200';
          return img;
        }
        catch(err) {
          for (let i = 0; i < images.length; i++) {
            if (images[i]?.directus_files_id) {
              let img = process.env.VUE_APP_API_IMG_PATH + images[i]?.directus_files_id?.id + '?width=200' ?? '';
              return img; }
          }
        }
      }
      return '';
    },
    setChartData() {
      let newChartData = [];
      let currentValue = 0;
      this.collection.forEach(collectionItem => {
        if (collectionItem.collection.length > 1) {
          collectionItem.collection.forEach(entry => {
            let currentSetValue = 0.00;
            if (!(entry.sales_date || entry.sales_price) && (entry.condition && (entry.condition.includes('new')))) { currentSetValue =  entry.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.avg_price ?? 0.00 }
            if (!(entry.sales_date || entry.sales_price) && (entry.condition && (entry.condition.includes('used')))) { currentSetValue =  entry.set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.avg_price ?? 0.00 }
            let newData = [
              new Date(entry.purchase_date || entry.date_created).valueOf(),
              currentSetValue.toFixed(2)
            ];
            newChartData.push(newData)
          });
        }
        else {
          let currentSetValue = 0.00;
          if (!(collectionItem.collection[0].sales_date || collectionItem.collection[0].sales_price) && (collectionItem.collection[0].condition && (collectionItem.collection[0].condition.includes('new')))) { currentSetValue =  collectionItem.collection[0].set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_new")?.avg_price ?? 0.00 }
          if (!(collectionItem.collection[0].sales_date || collectionItem.collection[0].sales_price) && (collectionItem.collection[0].condition && (collectionItem.collection[0].condition.includes('used')))) { currentSetValue =  collectionItem.collection[0].set.bricklink_sales.find(el => el.bricklink_types_id === "six_month_used")?.avg_price ?? 0.00 }
          let newData = [
            new Date(collectionItem.collection[0].purchase_date || collectionItem.collection[0].date_created).valueOf(),
            currentSetValue.toFixed(2)
          ];
          newChartData.push(newData);
        }
      });
      newChartData.sort();
      newChartData.forEach(element => {
        let newValue = currentValue + parseFloat(element[1]);
        let newDataValue = [
          element[0], // date
          newValue.toFixed(2) // sum value
        ];
        currentValue = newValue;
        this.chartData.push(newDataValue);
      });
      let autoLastEntryToday = [
        Date.now(),
        currentValue.toFixed(2)
      ];
      this.chartData.push(autoLastEntryToday);
      this.firstChartEntryDatetime = newChartData[0][0];
    },
    pushRoute(set) {
      this.$router.push({ path: '/items/sets/' + set });
    },
    register() {
      this.$router.push({ path: '/registration' })
    },
    deals() {
      this.$router.push({ path: '/deals' })
    },
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
    mapRetailPriceToLocal(retailPriceObject, local) {
      const localRetailPriceObject = retailPriceObject.filter(el => el.price_code === local)[0]?.price ?? '';
      if (localRetailPriceObject) return localRetailPriceObject;
      else return '';
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        return (new Date(releaseDate)).toLocaleDateString(this.localLanguage, {year: 'numeric', month: 'numeric'});
      }
      return '';
    },
    clearName(string) {
      if (string) {
        if (string.includes('(')) { string = string.substring(0, string.indexOf('(')); }
        if (string.includes(',')) { string = string.substring(0, string.indexOf(',')); }
        if (string.includes(' - ')) { string = string.substring(0, string.indexOf(' - ')); }
        let arr = string.split(' ', 3);
        if (arr.length > 1) {
          let result = '';
          for (let i = 0; i < 3; i++) {
            if (arr[i]) { result = result.concat(arr[i] + ' '); }
          }
          return result;
        }
      }
      return string;
    },
    getMinifigureImage(minifigureImageId) {
      try {
        if (minifigureImageId) { return process.env.VUE_APP_API_IMG_PATH + minifigureImageId + '?height=100' ?? ''; }
        return '';
      }
      catch(err) { return ''; }
    },
    formatRetailPrice(retail_price) {
      if (retail_price) {
        let localRetailPrice = retail_price?.filter(el => el.price_code === this.localCurrency)?.[0].price ?? '-';
        return '(UVP ' + localRetailPrice + ' ' + this.mapCurrencyUnit(this.localCurrency) + ')'
      }
      return '-';
    },
  }
}
</script>

<style scoped>

.section-main {
  padding-bottom: 5rem;
  background: linear-gradient(105deg, rgba(138,170,255,1) 0%, rgba(120,152,239,1) 10%, rgba(90,84,225,1) 60%, rgba(149,78,181,1) 100%);
}

#arrow {
  position: absolute;
  top: 8rem;
  left: -1.5rem;
  width: 25%;
}

#arrow_mobile {
  position: absolute;
  top: 10.5rem;
  left: 0;
  width: 40%;
}

#background {
  position: fixed;
  height: 50%;
  width: 100%;
  top: 5rem;
  left: 0;
}

.row-search {
  width: 100%;
  margin-top: 100px;
}

.input-search {
  align-content: center;
  height: 44px;
  width: 60%;
  color: #ffffff;
  padding-left: 55px;
  background: linear-gradient(90deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.4) 100%);
  border-radius: 25px;
  letter-spacing: .5px;
  border: 1px solid rgba(255, 255, 255, 0.6);
}

::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

input:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.9);
  transition: 0.5s ease all;
}

.search-icon {
  margin-top: 10px;
  transform: translateX(40px);
}

.close-icon {
  width: 15px;
  margin-top: 10px;
  cursor: pointer;
  transform: translateX(-30px);
}

.search-icon i,
.close-icon i {
  color: #ffffff;
  font-size: 24px;
}

.row_last_added_sets {
  margin-bottom: 1rem;
}

.card_main {
  position: relative;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: blur( 12.0px );
  border: 1px solid rgba(255, 255, 255, 0.3);;
  border-radius: 25px;
  transition: 0.5s ease transform;
}

.row-search {
  justify-content: center;
}

.text-link.themes {
  z-index: 2;
}

.link {
  cursor: pointer;
}
.link:hover {
  color: #60ffe4;
}
.text-link {
  display: inline-block;
  font-size: 16px;
}
.hint_row {
  height: 250px;
  width: 400px;
  align-items: center;
}
.hint_text {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: rgb(255, 255, 255);
}

.section-collection {
  position: relative;
  height: 100%;
  background: #FFF7F5;
  z-index: 5;
}

.section-invest {
  position: relative;
  height: 100%;
  background: #e2efff;
  z-index: 5;
}

.section-community {
  position: relative;
  height: 100%;
  background: #f7f7f7;
  z-index: 5;
}

.section-deals {
  position: relative;
  height: 100%;
  background: #f0f2ff;
  z-index: 5;
}

.col-invest,
.col-deals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.row-collection,
.row-invest,
.row-community,
.row-deals {
  align-items: center;
  padding: 2rem 0;
}

#img-collection,
#img-invest,
#img-community,
#img-deals {
  display: block;
  width: 80%;
  margin: 0 auto;
}

.header-collection,
.text-collection,
.header-invest,
.text-invest,
.header-community,
.text-community,
.header-deals,
.text-deals {
  width: 150px;
}

.header-collection,
.header-invest,
.header-community,
.header-deals {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.1;
  color: #162d33;
}

.text-collection,
.text-invest,
.text-community,
.text-deals {
  margin: 20px 0;
  font-size: 14px;
  font-weight: 300;
  hyphens: auto;
	text-align: justify
}

.header-invest,
.text-invest {
  margin-left: 30px;
}

.text-link.themes {
  margin-top: 30px;
}

#button-register {
  color: #814277;
  background: linear-gradient(180deg, rgb(255, 206, 248) 25%, rgb(255, 149, 239) 130%);
}

#button-deal {
  color: #fffcf4;
  background: linear-gradient(180deg, rgb(161, 169, 255) 25%, rgb(95, 107, 248) 130%);
}

#button-telegram,
#button-discord {
  margin: 0 0.5rem;
}

.card_stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 1rem;
  height: 100px;
  width: 160px;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.6) 100%);
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: saturate(180%) blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  overflow: hidden;
}

.stats_header {
  color: #ffffff;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0.1rem;
  line-height: 0.8rem;
  text-transform: uppercase;
  z-index: 2;
}

.stats_value {
  display: flex;
  color: #60ffe4;
  font-weight: 700;
  font-size: 1.5rem;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.16);
  transform: translateY(-0.4rem);
}

.label_container {
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.label_container {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
}

.label {
  color: #ffffff;
  font-size: 14px;
  padding: 0 1.5rem 0 0.5rem;
  transition: 0.5s ease all;
}

.label.value {
  color: #ffffff;
  padding: 0 0 0 0.5rem;
  font-weight: 700;
}

.label.value.balance_positive {
  color: #60ffe4;
}

.label.value.balance_negative {
  color: rgb(252, 179, 236);
}

.card_body_loader {
  display: flex;
  height: 250px;
  width: 310px;
  align-items: center;
}

.loader {
  width: 50%;
}

#add_collection_button_container {
  display: flex;
  flex-direction: column;
  height: 250px;
  width: 400px;
  justify-content: center;
  align-items: center;
}

#add_collection_text {
  padding-bottom: 2rem;
  font-size: 14px;
  margin: 0 auto;
  color: rgb(255, 255, 255);
  transform: translateY(-1rem);
}

#collection_link {
  margin-top: 1rem;
}

.table {
  color: #ffffff;
  background: none;
  margin-bottom: 1rem;
}

#td_minifigure_name {
  line-height: 1;
}

#small_image_alt {
  height: 100%;
  width: 100%;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.2);
  text-shadow: none;
}

#chip_link {
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s ease all;
}

  #chip_link:hover {
    color: #60ffe4;
  }

#trademark_symbol {
  font-size: 18px;
}



::-webkit-scrollbar-track {
  margin: 1.5rem;
}

/* MEDIA SCREENS */
@media (min-width: 600px) {
  .header-collection,
  .text-collection,
  .header-invest,
  .text-invest,
  .header-community,
  .text-community,
  .header-deals,
  .text-deals {
    width: 200px;
  }

  .header-collection,
  .header-invest,
  .header-community,
  .header-deals {
    font-size: 38px;
    font-weight: 700;
    line-height: 1;
  }

  .text-collection,
  .text-invest,
  .text-community,
  .text-deals {
    font-size: 18px;
    line-height: 1.6;
  }

  .header-invest,
  .text-invest,
  .header-deals,
  .text-deals {
    margin-left: 80px;
  }
}

@media (min-width: 1280px) {
  .row-collection,
  .row-invest,
  .row-community,
  .row-deals {
    align-items: center;
    padding: 5rem 0;
  }

  .header-collection,
  .text-collection,
  .header-invest,
  .text-invest,
  .header-community,
  .text-community,
  .header-deals,
  .text-deals {
    width: 300px;
  }

  .header-collection,
  .header-invest,
  .header-community,
  .header-deals {
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
  }

  .text-collection,
  .text-invest,
  .text-community,
  .text-deals {
    font-size: 20px;
    line-height: 1.6;
  }

}

@media (min-width: 1920px) {
  .header-collection,
  .text-collection,
  .header-invest,
  .text-invest,
  .header-community,
  .text-community,
  .header-deals,
  .text-deals {
    width: 350px;
  }

  #img-collection,
  #img-invest,
  #img-community,
  #img-deals {
    width: 60%;
  }

  .header-invest,
  .text-invest,
  .header-deals,
  .text-deals {
    margin-left: 250px;
  }

}

</style>
