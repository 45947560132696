<template>
  <template v-if="isMobile">
    <div v-if="retailerList?.length > 0" class="retailer-list">
      <div v-for="retailer in retailerList" :key="retailer.name">
        <template v-if="retailer.currentPrice">
          <v-row class="retailer-list-item">
            <v-col class="pa-0" cols="auto">
              <a :href="`${retailer.affiliate_link || retailer.fallback_affiliate_url || retailer.product_link}`" target="_blank">
                <div class="retailer-price-container">
                  <template v-if="withShipping">
                    <span class="retailer-price">{{ retailer.priceWithShipping }}<span v-if="retailer.currentPrice"> <span>{{ ' ' + this.mapCurrencyUnit(localCurrency) }}</span></span></span>
                  </template>
                  <template v-else>
                    <span class="retailer-price">{{ retailer.currentPrice }}<span v-if="retailer.currentPrice"> <span>{{ ' ' + this.mapCurrencyUnit(localCurrency) }}</span></span></span>
                  </template>
                </div>
              </a>
            </v-col>
            <v-col class="pa-0 ml-4" cols="auto">
              <a :href="`${retailer.affiliate_link || retailer.fallback_affiliate_url || retailer.product_link}`" target="_blank">
                <template v-if="retailer.discount">
                  <template v-if="withShipping">
                    <div v-if="retailer.discountWithShpping >= 0.0" class="discount-container positive mobile">
                      <span class="discount-text positive mobile">{{ (retailer.discountWithShpping * -1).toFixed(1) }}<span v-if="retailer.discountWithShpping">%</span></span>
                    </div>
                    <div v-else class="discount-container negative mobile">
                      <span class="discount-text negative mobile">{{ (retailer.discountWithShpping * -1).toFixed(1) }}<span v-if="retailer.discountWithShpping">%</span></span>
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="retailer.discount >= 0.0" class="discount-container positive mobile">
                      <span class="discount-text positive mobile">{{ (retailer.discount * -1).toFixed(1) }}<span v-if="retailer.discount">%</span></span>
                    </div>
                    <div v-else class="discount-container negative mobile">
                      <span class="discount-text negative mobile">{{ (retailer.discount * -1).toFixed(1) }}<span v-if="retailer.discount">%</span></span>
                    </div>
                  </template>
                </template>
              </a>
            </v-col>
            <v-col class="pa-0">
              <a :href="`${retailer.affiliate_link || retailer.fallback_affiliate_url || retailer.product_link}`" target="_blank">
                <div class="retailer-image-container">
                  <v-img v-if="retailer.logo" :src="retailer.logo"></v-img>
                </div>
              </a>
            </v-col>
          </v-row>
        </template>
      </div>
    </div>
    <div v-else>
      <v-row no-gutters>
        <span class="retailer-hint-text">{{ retailerHint }}</span>
      </v-row>
    </div>
  </template>

  <template v-else>
    <div v-if="retailerList?.length > 0" class="retailer-list">
      <div v-for="retailer in retailerList" :key="retailer.name">
        <template v-if="retailer.currentPrice">
          <v-row class="retailer-list-item">
            <v-col class="pa-0" cols="auto">
              <a :href="`${retailer.affiliate_link || retailer.fallback_affiliate_url || retailer.product_link}`" target="_blank">
                <div class="retailer-price-container">
                  <template v-if="withShipping">
                    <span class="retailer-price">{{ retailer.priceWithShipping }}<span v-if="retailer.currentPrice"> <span>{{ ' ' + this.mapCurrencyUnit(localCurrency) }}</span></span></span>
                  </template>
                  <template v-else>
                    <span class="retailer-price">{{ retailer.currentPrice }}<span v-if="retailer.currentPrice"> <span>{{ ' ' +  this.mapCurrencyUnit(localCurrency) }}</span></span></span>
                  </template>
                  <span class="retailer-price-shipping">{{ retailer.priceWithShipping }} <span>{{ this.mapCurrencyUnit(localCurrency) }}</span> inkl. Versandkosten</span>
                </div>
              </a>
            </v-col>
            <v-col class="pa-0 ml-4" cols="auto">
              <a :href="`${retailer.affiliate_link || retailer.fallback_affiliate_url || retailer.product_link}`" target="_blank">
                <template v-if="retailer.discount">
                  <template v-if="withShipping">
                    <div v-if="retailer.discountWithShpping >= 0.0" class="discount-container positive">
                      <span class="discount-text positive">{{ (retailer.discountWithShpping * -1).toFixed(1) }}<span v-if="retailer.discountWithShpping">%</span></span>
                    </div>
                    <div v-else class="discount-container negative">
                      <span class="discount-text negative">{{ (retailer.discountWithShpping * -1).toFixed(1) }}<span v-if="retailer.discountWithShpping">%</span></span>
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="retailer.discount >= 0.0" class="discount-container positive">
                      <span class="discount-text positive">{{ (retailer.discount * -1).toFixed(1) }}<span v-if="retailer.discount">%</span></span>
                    </div>
                    <div v-else class="discount-container negative">
                      <span class="discount-text negative">{{ (retailer.discount * -1).toFixed(1) }}<span v-if="retailer.discount">%</span></span>
                    </div>
                  </template>
                </template>
              </a>
            </v-col>
            <v-col class="pa-0">
              <a :href="`${retailer.affiliate_link || retailer.fallback_affiliate_url || retailer.product_link}`" target="_blank">
                <div class="retailer-image-container">
                  <v-img v-if="retailer.logo" :src="retailer.logo"></v-img>
                </div>
              </a>
            </v-col>
          </v-row>
        </template>
      </div>
    </div>
    <div v-else>
      <v-row no-gutters>
        <span class="retailer-hint-text">{{ retailerHint }}</span>
      </v-row>
    </div>
  </template>
</template>

<script>
export default {
  name: 'RetailerList',
  props: [ 'set_data', 'retailerList', 'withShipping' ],
  inject: [
    'isMobile',
    'localCurrency'
  ],
  computed: {
    retailerHint() {
      return 'Leider steht uns aktuell kein Händlerpreis für das Set ' + this.set_data.set.split('-')[0] + ' zur Verfügung.';
    }
  },
  methods: {
    mapCurrencyUnit(localPriceCode) {
      if (localPriceCode === 'eur') return '€';
      else if (localPriceCode === 'usd') return '$';
      else if (localPriceCode === 'gbp') return '£';
      else if (localPriceCode === 'chf') return 'Fr';
      else return '';
    },
  }
}
</script>

<style scoped>

.retailer-price-container {
  display: flex;
  flex-direction: column;
}

.retailer-price-shipping,
.payments,
.shipping-return,
.shipping {
  color: #ffffff;
  font-size: 10px;
  font-weight: 300;
}

.retailer-price-shipping {
  line-height: 1;
}

.retailer-price-shipping.small-screen {
  font-size: 9px;
}

.retailer-list {
  max-height: 600px;
  overflow-y: auto;
}

.retailer-list-item {
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
  padding: 0.25rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.retailer-price {
  color: #60ffe4;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 1px 1px 0px #0e545a;
}

.discount-container {
  display: flex;
  margin: 0 auto;
  width: auto;
  height: 26px;
  padding: 0 0.5rem;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 3;
}

.discount-container.mobile {
  width: 50px;
  height: 26px;
}

.discount-container.positive {
  background: rgb(187, 255, 244);
}

.discount-container.negative {
  background: rgb(255, 190, 213);
}

.discount-text {
  font-size: 14px;
  font-weight: 700;
}

.discount-text.positive {
  color: #09c5a6;
}

.discount-text.negative {
  color: #dc4277;
}

.discount-text.positive.mobile,
.discount-text.negative.mobile {
  font-size: 12px;
}

.retailer-image-container {
  display: flex;
  padding-left: 1rem;
  height: 1.5rem;
  width: 75%;
  overflow: hidden;
}

.retailer-hint-text {
  font-size: 14px;
  margin: 0 1.5rem;
  padding: 1rem 0;
  color: rgb(255, 255, 255);
}

@media (min-width: 1280px) {
  .info-list-header {
    min-width: 140px;
  }
  .retailer-price {
    font-size: 22px;
  }
  .discount-container {
    height: 35px;
  }
  .retailer-image-container {
    height: 2.5rem;
  }

}

@media (min-width: 1920px) {
  .retailer-price {
    font-size: 26px;
  }
  .retailer-image-container {
    padding-left: 0;
  }
}

</style>
